import React, { ElementType } from "react";
import ClockRequestDetail from "./ClockRequestDetail";
import { DetailsBaseProps } from "./DetailsBase";
import LeaveRequestDetail from "./LeaveRequestDetail";
import OvertimeRequestDetail from "./OvertimeRequestDetail";

export interface DetailsContentProps {
    formName: string;
    formValue: Record<string, any>;
}

function DetailsContent({ formName, formValue }: DetailsContentProps) {
    const formMap: Record<string, ElementType<DetailsBaseProps>> = {
        leaveRequest: LeaveRequestDetail,
        overtimeRequest: OvertimeRequestDetail,
        clockRequest: ClockRequestDetail,
    };
    let DetailsFormComponent: ElementType<DetailsBaseProps> | null = null;
    if (formName in formMap) {
        DetailsFormComponent = formMap[formName];
    }

    return <>{DetailsFormComponent && <DetailsFormComponent value={formValue} />}</>;
}

export default DetailsContent;
