/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Action } from "@material-table/core";
import { Stack } from "@mui/material";
import { PageHeader2 as PageHeader } from "layout";
import AttendanceSearchTable from "./AttendanceSearchTable";
import ColumnsFactory from "./ColumnsFactory";
import AuditDialog from "components/audit/AuditDialog";
import DateRangeFilter from "components/grid/DateRangeFilter";
import GridTabs from "components/grid/GridTabs";
import Toolbar from "components/grid/Toolbar";
import ClockRequestSearchTable, { ClockRequestRowData } from "./ClockRequestSearchTable";
import ClockRequestColumnsFactory from "./ClockRequestColumnsFactory";
import moment from "moment";
import ExportButton from "components/buttons/ExportButton";
import EmployeeFilter from "components/leave/EmployeeFilter";
import { TabPageProps } from "layout/types";
import { DateFilter } from "../../helpers/dateFilter";
import { download, generateFilename } from "../../helpers/fileHelper";
import { useParams } from "react-router-dom";
import LeaveDetailsWrapper from "components/leave/LeaveDetailsWrapper";

enum LeftToolEnum {
    EmployeeFilter,
    DateRangeFilter,
}

function AttendanceList({ match }: TabPageProps) {
    const tableRef = useRef<any>(null);
    const { t } = useTranslation();
    const [auditFormOpen, setAuditFormOpen] = useState<boolean>(false);
    const [activeRowId, setActiveRowId] = useState<string | null>(null);
    const [employeeId, setEmployeeId] = useState<number | undefined>();
    const { tabName } = useParams<{ tabName?: string }>();
    const [currentTab, setCurrentTab] = useState<string>(tabName ?? "");

    const [dateFilter, setDateFilter] = useState<DateFilter>({
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month"),
    });
    const onAuditDialogOpen = (rowId: string) => {
        setActiveRowId(rowId);
        setAuditFormOpen(true);
    };

    const allEntriesSearchTable = (
        <AttendanceSearchTable
            tableRef={tableRef}
            dateFilter={dateFilter}
            columns={ColumnsFactory(
                {
                    type: "main",
                    formAction: onAuditDialogOpen,
                },
                t
            )}
            header={
                <Toolbar justifyContent="flex-end">
                    <ExportButton
                        title={t("c.export")}
                        actions={[
                            {
                                title: t("c.exportReport"),
                                onClick: () => {
                                    const fileName = generateFilename("attendance_employees_", "xlsx");
                                    const params = {
                                        startDate: dateFilter.startDate.format("YYYY-MM-DD"),
                                        endDate: dateFilter.endDate.format("YYYY-MM-DD"),
                                    };
                                    download("/api/attendance/employees/export", fileName, params);
                                },
                            },
                        ]}
                    />
                </Toolbar>
            }
        />
    );
    const onPreview = (rowId: string) => {
        setActiveRowId(rowId);
        setAuditFormOpen(true);
    };
    const cardReplacementActions: Action<ClockRequestRowData>[] = [
        {
            icon: "assignment",
            iconProps: {
                color: "primary",
            },
            tooltip: t("approval.preview"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    console.log(rowData);
                    onPreview(rowData.id);
                }
            },
        },
    ];

    const onLeaveInfoPreview = (rowId: string) => {
        // TODO: get leaveInfo from the api
        alert("Not implemented");
    };
    const cardReplacementSearchTable = (
        <ClockRequestSearchTable
            tableRef={tableRef}
            actions={cardReplacementActions}
            dateFilter={dateFilter}
            columns={ClockRequestColumnsFactory(
                {
                    type: "main",
                    formAction: onLeaveInfoPreview,
                },
                t
            )}
        />
    );
    const leaveDetails = <LeaveDetailsWrapper employeeId={employeeId} />;

    const refreshTable = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };
    const handleFormClose = () => {
        setAuditFormOpen(false);
    };

    const [currentLeftTool, setCurrentLeftTool] = useState<LeftToolEnum>(LeftToolEnum.EmployeeFilter);
    useEffect(() => {
        if (currentTab === "leave") {
            setCurrentLeftTool(LeftToolEnum.EmployeeFilter);
        } else {
            setCurrentLeftTool(LeftToolEnum.DateRangeFilter);
        }
    }, [currentTab]);
    const leftTools: { [key in LeftToolEnum]: React.ReactNode } = {
        [LeftToolEnum.EmployeeFilter]: <EmployeeFilter onApply={setEmployeeId} />,
        [LeftToolEnum.DateRangeFilter]: (
            <DateRangeFilter
                initStartDate={dateFilter.startDate.toDate()}
                initEndDate={dateFilter.endDate.toDate()}
                onApply={(startDate, endDate) => {
                    setDateFilter((prevState) => {
                        return { ...prevState, startDate: moment(startDate), endDate: moment(endDate) };
                    });
                }}
            />
        ),
    };

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    <Stack spacing={1} direction="row">
                        {leftTools[currentLeftTool]}
                    </Stack>
                }
            />
            <GridTabs
                match={match}
                tabs={[
                    { index: 0, label: t("employee.attendance"), children: allEntriesSearchTable, url: "" },
                    {
                        index: 1,
                        label: t("attendance.clockReqRecord"),
                        children: cardReplacementSearchTable,
                        url: "card",
                    },
                    { index: 2, label: t("attendance.leaveRemain"), children: leaveDetails, url: "leave" },
                ]}
                onTabChange={(tabId) => setCurrentTab(tabId)}
            />
            <AuditDialog
                onClose={handleFormClose}
                onChange={refreshTable}
                open={auditFormOpen}
                rowId={activeRowId}
                readonly={true}
            />
        </>
    );
}

export default AttendanceList;
