import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { Divider, Grid, Paper, Tab } from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    label: string;
}

interface TabDesc {
    children?: React.ReactNode;
    index: number;
    label: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`grid-tabpanel-${index}`}
            aria-labelledby={`grid-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `grid-tab-${index}`,
        "aria-controls": `grid-tabpanel-${index}`,
    };
}

export interface ImportPreviewTabsProps {
    tabs: Array<TabDesc>;
}

export default function ImportPreviewTabs({ tabs }: ImportPreviewTabsProps) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Paper variant="outlined">
                <Tabs
                    value={value}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                    aria-label="import preview tabs"
                >
                    {tabs.map(({ index, label }) => {
                        return (
                            <Tab
                                key={index}
                                value={index}
                                label={
                                    <Grid container>
                                        <Grid item>{label}</Grid>
                                    </Grid>
                                }
                                {...a11yProps(index)}
                            />
                        );
                    })}
                </Tabs>
                <Divider />
                {tabs.map(({ index, label, children }) => {
                    return (
                        <TabPanel key={index} value={value} index={index} label={label}>
                            {children}
                        </TabPanel>
                    );
                })}
            </Paper>
        </Box>
    );
}
