import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CardReplacementStatusEnum from "./CardReplacementStatusEnum";
import CardReplacementStatusChip from "./CardReplacementStatusChip";

export interface CardReplacementStatusProps {
    statusId: CardReplacementStatusEnum;
}

export default function CardReplacementStatus({ statusId }: CardReplacementStatusProps) {
    const { t } = useTranslation();

    const statuses: { [key in CardReplacementStatusEnum]: ReactNode } = {
        [CardReplacementStatusEnum.PASS]: (
            <CardReplacementStatusChip type={CardReplacementStatusEnum.PASS} label={t("通過")} />
        ),
        [CardReplacementStatusEnum.REJECTED]: (
            <CardReplacementStatusChip type={CardReplacementStatusEnum.REJECTED} label={t("駁回")} />
        ),
    };

    const [status, setStatus] = useState<ReactNode | null>(null);
    useEffect(() => {
        setStatus(statuses[statusId] ?? null);
    }, [statusId]);

    return <>{status || status}</>;
}
