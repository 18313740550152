import React, { useState, useRef, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import MdiIcon from "@mdi/react";
import { mdiFileSearch } from "@mdi/js";
import { PageHeader2 as PageHeader } from "layout";
import MaterialTable from "components/customMaterialTable";
import SearchDialog from "components/customMaterialTable/SearchDialog";
import { useEmployeeData, useFormTable, useUserPermission } from "hooks";
import { createLookup } from "helpers/mTableHelper";
import { loadControl as LC } from "helpers";
import FormDialog from "./AnnounceFormDialog";

function Announce() {
    //const [errors, setErrors] = useState({});
    const tableRef = useRef(null);
    const formTable = useFormTable("/api/announce");
    const { t } = useTranslation();
    const { isWrite } = useUserPermission();
    const writable = useMemo(() => isWrite("newsManage"), [isWrite]);
    const [searchOpen, setSearchOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [formDataId, setFormDataId] = useState(null);
    const [empData, empFetch] = useEmployeeData((data) => createLookup(data, "name"), {});

    const actions = [
        {
            icon: writable ? "edit" : () => <MdiIcon path={mdiFileSearch} size={1} />,
            tooltip: writable ? t("c.edit") : t("c.view"),
            onClick: (e, row) => {
                doEdit(row);
            },
        },
    ];
    const extraActions = writable
        ? [
              {
                  icon: "delete",
                  tooltip: t("c.del"),
                  disabled: true,
                  onClick: (e, rowData) => {
                      //TODO: delete
                      /*const action = () => {
                          if (!Array.isArray(rowData)) {
                              crud.handleDel({}, `/api/announce/${rowData.id}`).then(() => {
                                  tableRef.current.onPageChange(e, 0);
                              });
                          }
                      };
                      dialogRef.current && dialogRef.current.open(action);*/
                  },
              },
          ]
        : [];

    const handleSearchClose = () => {
        setSearchOpen(false);
    };
    const handleSearchBtn = () => {
        setSearchOpen(true);
    };
    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };

    const doEdit = async (row) => {
        setFormDataId(row.id);
        setFormOpen(true);
    };

    const handleAddBtnClick = () => {
        setFormDataId(null);
        setFormOpen(true);
    };

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormChange = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };

    const formatTime = (time) => new moment(time).format("LLL");

    const fetchData = useCallback(async () => {
        LC();
        await empFetch();
        LC(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: "#",
            field: "id",
            type: "numeric",
            editable: "never",
            width: 80,
        },
        {
            title: t("c.fd.title"),
            field: "title",
        },
        {
            title: t("announce.fd.visible"),
            field: "show",
            type: "boolean",
        },
        {
            title: t("announce.fd.fixedTop"),
            field: "fixedTop",
            type: "boolean",
        },
        {
            title: t("announce.fd.createUser"),
            field: "createUser",
            lookup: empData,
        },
        {
            title: t("c.fd.date"),
            field: "createAt",
            render: (rowData) => formatTime(rowData.createAt),
        },
    ];

    return (
        <>
            <PageHeader
                //title={t("m.newsManage")}
                title=""
                leftToolView={
                    <Grid container spacing={1}>
                        {writable && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddBtnClick}
                                    endIcon={<AddIcon />}
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
                rightToolView={
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="contained" onClick={handleSearchBtn} endIcon={<SearchIcon />}>
                                {t("c.search")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} endIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={formTable.fetch()}
                actions={actions}
                extraActions={extraActions}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    pageSize: 10,
                    toolbar: false,
                    filtering: showFilter,
                }}
            />
            <FormDialog
                open={formOpen}
                id={formDataId}
                onClose={handleFormClose}
                onChange={handleFormChange}
                writable={writable}
            />
            <SearchDialog open={searchOpen} onClose={handleSearchClose} tableRef={tableRef} />
        </>
    );
}

export default Announce;
