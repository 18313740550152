import React, { MouseEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider } from "@mui/material";
import { FormBaseRefProps } from "components/final-form/FormBase";
import ApplicationForm, { ApplicationSelectFormValues } from "./ApplicationSelectForm";

export interface ApplicationSelectDialogProps extends DialogProps {
    onStepChoice: (formName: string) => void;
}

function ApplicationSelectDialog({ onStepChoice, onClose, ...props }: ApplicationSelectDialogProps) {
    const formRef = useRef<FormBaseRefProps<ApplicationSelectFormValues>>(null);
    const { t } = useTranslation();

    const handleNextStep = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };

    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };

    const handleSubmit = async ({ formName }: ApplicationSelectFormValues) => {
        onStepChoice(formName);
        handleClose();
    };

    return (
        <Dialog {...props} aria-labelledby="form-application-dialog-title">
            <DialogTitle id="user-dialog-title">{t("audit.newApply")}</DialogTitle>
            <Divider />
            <DialogContent>
                <ApplicationForm onSubmit={handleSubmit} formRef={formRef} />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleNextStep}>
                    {t("c.nextStep")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ApplicationSelectDialog;
