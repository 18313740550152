import React, { ReactNode } from "react";
import { Grid, GridSpacing } from "@mui/material";

export interface DlListProps {
    spacing?: GridSpacing;
    children: ReactNode;
}

function DlList(props: DlListProps) {
    return (
        <Grid
            container
            component="dl" // mount a Definition List
            {...props}
        />
    );
}

export default DlList;
