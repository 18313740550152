import { TFunction } from "react-i18next";

const sourceI18nMapping: Record<string, string> = {
    manual: "accountVoucher.source.manual",
    voucherCancel: "accountVoucher.source.voucherCancel",
    voucherAdjust: "accountVoucher.source.voucherAdjust",
    consolidation: "accountVoucher.source.consolidation",
};

export const getSourceI18n = (t: TFunction) => {
    const map: Record<string, string> = {};
    Object.keys(sourceI18nMapping).forEach((key: string) => {
        map[key] = t(sourceI18nMapping[key]);
    });
    return map;
};

export default sourceI18nMapping;
