import { useAppContent } from "components/AppContext";
function PageList() {
    const appContext = useAppContent();
    const { pageData } = appContext;
    if (!pageData) {
        return [];
    }
    const rootItems = pageData.filter((item) => item.type === "item");
    const catItems = pageData.filter((item) => item.type === "cat");
    const subItems = catItems.flatMap((cat) => cat.items);
    return [...rootItems, ...subItems];
}
const getPageList = PageList;
export { getPageList };
