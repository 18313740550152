import React from "react";
import { useTranslation } from "react-i18next";
import { SortableDialog } from "components";
import { ListItemText } from "@mui/material";

function SalaryItemsSortableDialog({ ...props }) {
    const { t } = useTranslation();
    return (
        <SortableDialog
            title={t("salary.order_editor")}
            dataFetchPath="/api/salaryitem/all"
            dataUpdatePath="/api/salaryitem/changeOrder"
            orderField="order"
            sortableItemTexts={(row) => <ListItemText primary={row.name} />}
            {...props}
        />
    );
}

export default SalaryItemsSortableDialog;
