import React, { MouseEvent, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Action } from "@material-table/core";
import { PageHeader2 as PageHeader } from "layout";
import { useDataCUD, useUserPermission } from "hooks";
import { SearchTextField, StandaloneConfirmDialog, StandaloneConfirmDialogRef } from "components";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import DepartmentMemberTable, { DepartmentMemberRowData } from "./DepartmentMemberTable";
import DepartmentMemberFormDialog from "./DepartmentMemberFormDialog";

export interface DepartmentMemberDialog extends DialogProps {
    dataId: string | null;
    onChange?: () => void;
}

function DepartmentMemberDialog({ dataId, onChange, onClose, ...props }: DepartmentMemberDialog) {
    const { t } = useTranslation();
    const tableRef = useRef<any>(null);
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("leaveType"), [isWrite]);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
        onChange && onChange();
    };
    const handleAdd = async () => {
        setFormOpen(true);
    };
    const handleFormClose = () => {
        setFormOpen(false);
    };
    const refreshTable = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };
    const crud = useDataCUD();
    const actions: ActionType<DepartmentMemberRowData>[] = writable
        ? [
              (rowData: DepartmentMemberRowData): Action<DepartmentMemberRowData> => {
                  return {
                      icon: "delete",
                      tooltip: t("c.del"),
                      onClick: (e: any, rowData) => {
                          const action = () => {
                              if (!Array.isArray(rowData)) {
                                  crud.handleDel({}, `/api/department/member/${dataId}/${rowData.employee.id}`).then(
                                      () => {
                                          refreshTable();
                                      }
                                  );
                              }
                          };
                          dialogRef.current && dialogRef.current.open(action);
                      },
                  };
              },
          ]
        : [];

    return (
        <Dialog {...props} aria-labelledby="department-member-dialog-title" maxWidth="lg" fullWidth>
            <DialogTitle id="department-member-dialog-title">{t("department.memberManage")}</DialogTitle>
            <DialogContent>
                <PageHeader
                    title=""
                    leftToolView={
                        writable ? (
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            handleAdd();
                                        }}
                                    >
                                        {t("c.add")}
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )
                    }
                    rightToolView={
                        <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                            <Grid item>
                                <SearchTextField tableRef={tableRef} />
                            </Grid>
                        </Grid>
                    }
                />
                <DepartmentMemberTable dataId={dataId} tableRef={tableRef} actions={actions} writable={writable} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t("c.close")}</Button>
            </DialogActions>
            <DepartmentMemberFormDialog
                onClose={handleFormClose}
                onChange={refreshTable}
                dataId={dataId}
                open={formOpen}
            />
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
        </Dialog>
    );
}

export default DepartmentMemberDialog;
