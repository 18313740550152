import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Field, useField, useForm } from "react-final-form";
import { FormApi } from "final-form";
import TextField from "@mui/material/TextField";
import { Autocomplete, AutocompleteProps } from "@mui/material";
import { useReduxTempData } from "hooks";
import { AccountTitleRow } from "pages/accountSetting";

export interface AccountTitlePickerFieldProps
    extends Omit<AutocompleteProps<any, false, undefined, undefined>, "options" | "renderInput"> {
    field: string;
    filter?: (item: AccountTitleRow, i: number) => boolean;
    forceReload?: boolean;
    label?: string;
    onChange?: (value: any, form: FormApi<any>) => void;
}

function AccountTitlePickerField({
    field,
    filter,
    forceReload,
    label,
    onChange,
    ...props
}: AccountTitlePickerFieldProps): React.ReactElement<AccountTitlePickerFieldProps> {
    const { t } = useTranslation();
    const form = useForm();
    const accountTitles = useReduxTempData<AccountTitleRow[]>(
        `/api/accountTitle/data`,
        t("e.accountTitle"),
        forceReload || false
    );
    const [mAccountTitles, setMAccountTitles] = useState(accountTitles);
    const fieldTag = useField(field);
    //console.log(accountTitles);
    useEffect(() => {
        if (accountTitles) {
            if (filter) {
                const filteredAccountTitles = accountTitles.filter(filter);
                setMAccountTitles([...filteredAccountTitles]);
                if (fieldTag.input.value) {
                    const check = filteredAccountTitles.find((a) => a.id === fieldTag.input.value);
                    if (!check) {
                        form.change(field, null);
                    }
                }
            } else {
                setMAccountTitles([...accountTitles]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, accountTitles]);
    if (!accountTitles) {
        return <></>;
    }
    return (
        <>
            <Field name={field}>
                {({ meta, input }) => {
                    // input.value;
                    let initVal: any = null;
                    if (mAccountTitles && input.value) {
                        initVal = mAccountTitles && mAccountTitles.find((a) => a.id === input.value);
                    }
                    const error = meta.error;
                    //const initVal: any = mAccountTitles && mAccountTitles.find((a) => a.id === input.value);
                    return (
                        <>
                            <Autocomplete
                                {...props}
                                value={initVal}
                                onChange={(event: any, value: any) => {
                                    if (value) {
                                        form.change(field, value.id);
                                    } else {
                                        form.change(field, null);
                                    }
                                    if (onChange) {
                                        onChange(value, form);
                                    }
                                }}
                                options={mAccountTitles || []}
                                /*sx={{
                                    fontSize: 15,
                                    "& > span": {
                                        marginRight: 10,
                                        fontSize: 18,
                                    },
                                }}*/
                                autoHighlight
                                getOptionLabel={(option: AccountTitleRow) => {
                                    if (!option) {
                                        return "";
                                    }
                                    return `${option.code}-${option.name}`.trim();
                                }}
                                isOptionEqualToValue={(option?: AccountTitleRow, value?: AccountTitleRow) => {
                                    if (option && value) {
                                        return option.id === value.id;
                                    } else {
                                        return false;
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={label || t("e.accountTitle")}
                                        variant="outlined"
                                        fullWidth
                                        error={!!error}
                                        helperText={error}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "disabled", // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </>
                    );
                }}
            </Field>
        </>
    );
}

export default AccountTitlePickerField;
