import React, { useState } from "react";
import { useForm } from "react-final-form";
import { FormApi } from "final-form";
import { BaseTextFieldProps } from "@mui/material";
import { EmployeePickRow, useEmployeeData } from "hooks";
import { createLookup } from "helpers/mTableHelper";
import { EmployeePickerDialog } from "pages/employee";
import PickerTextFieldBase, { PickerTextFieldBaseProps } from "./PickerTextFieldBase";
export interface EmployeePickerFieldProps extends Partial<PickerTextFieldBaseProps>, BaseTextFieldProps {
    name: string;
    clearable?: boolean;
    filter?: (row: EmployeePickRow) => boolean;
}

function EmployeePickerField({ name, clearable, filter, ...props }: EmployeePickerFieldProps) {
    const form = useForm();
    const [empAnchorEl, setEmpAnchorEl] = useState<any>(false);
    const [empData] = useEmployeeData((data) => createLookup(data, "name"), {});

    const handleEmpDialogClose = () => {
        setEmpAnchorEl(false);
    };

    const handleEmpPickerOpen = (name: string, form: FormApi) => () => {
        setEmpAnchorEl({ form: form, name: name });
    };

    return (
        <>
            <EmployeePickerDialog
                anchorEl={empAnchorEl}
                open={Boolean(empAnchorEl)}
                onClose={handleEmpDialogClose}
                filter={filter}
                clearable={clearable}
            />
            <PickerTextFieldBase
                name={name}
                lookup={empData}
                onClick={props.disabled ? () => {} : handleEmpPickerOpen(name, form)}
                {...props}
            />
        </>
    );
}

export default EmployeePickerField;
