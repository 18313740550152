export { default as checkLogin } from "./checkLogin";
export { default as loadControl } from "./loadControl";
export { default as loadClear } from "./loadClear";
export { default as logout } from "./logout";
export { default as addToast } from "./addToast";
export { default as popToastMessage } from "./popToastMessage";
export { default as checkMessage } from "./checkMessage";
export { default as getUser } from "./getUser";

export { formatTime as dateFormat } from "./dateHelper";
export { getErrorByCode } from "./formHelper";
export { yupLocaleInit } from "./yups-i18n";
export { createLookup } from "./mTableHelper";
export { i18nToMenuItem, i18nToRadio } from "./i18nComponentHelper";
export { isCommonResponseError, commonErrorHandler } from "./ajaxErrorHandler";

export const deepCopy = (value: any) => {
    return JSON.parse(JSON.stringify(value));
};
