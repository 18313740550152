import { styled } from "@mui/material";

const LoadingWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    top: "50%",
    left: "50%",
    marginLeft: -32,
}));

export default LoadingWrapper;
