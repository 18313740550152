import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Grid, Card, CardContent, Container } from "@mui/material";
import { getUser } from "helpers";
import { useFetchData2 as useFetchData } from "hooks";
import { EmployeeNotSetAlert } from "components";
import { ClockInContent, ClockInRow } from "components/clockIn";
import { ResponseHandler } from "common";
import RecordArea from "./RecordArea";

function ClockIn() {
    const { t } = useTranslation();
    const user = useMemo(() => getUser(), []);
    const [dataClockIn, dataClockInFetch, dcCleanup] = useFetchData<ResponseHandler<ClockInRow[]>>(
        "/api/attendance",
        t("attendance.fd.clockInData"),
        undefined,
        null
    );
    const fetchData = useCallback(async () => {
        if (user.employee) {
            await dataClockInFetch().catch((err) => {
                console.log(err);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        fetchData();
        return () => {
            dcCleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);

    const handleClockInFinished = () => {
        fetchData();
    };
    return (
        <Container maxWidth="md" sx={{ marginTop: (theme) => theme.spacing(2) }}>
            <Stack spacing={2}>
                {!user.employee && <EmployeeNotSetAlert />}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <ClockInContent onClockInFinished={handleClockInFinished} />
                        </CardContent>
                    </Card>
                </Grid>
                <RecordArea data={dataClockIn || { status: "ok", result: [] }} />
            </Stack>
        </Container>
    );
}

export default ClockIn;
