import React from "react";
import { Avatar } from "@mui/material";

interface IconAvatarProps {
    background: string;
    children: React.ReactNode;
}

function IconAvatar({ background, children }: IconAvatarProps) {
    return <Avatar sx={{ background: background, width: "2.8em", height: "2.8em" }}>{children}</Avatar>;
}

export default IconAvatar;
