import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, useForm } from "react-final-form";
import { Select, TextField, makeValidate } from "mui-rff";
import * as yup from "yup";
import { SchemaOf, StringSchema } from "yup";
import { Box, Grid, IconButton, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormBase, { FormBaseProps } from "components/final-form/FormBase";
import { AccountTitlePickerField } from "components/final-form";
import { modeDef } from "hooks/useFormData";
import { ResponseHandler } from "common";
import { useReduxTempDataBase } from "hooks";
import { AccountTitleRow } from "../accountTitle";
import { BankAccountRowData } from "./BankAccountTable";
import { useBankAccountFormContext } from "./BankAccountList";
import CreateAccountTitleDialog from "./CreateAccountTitleDialog";

export type BankAccountFormValues = Partial<BankAccountRowData>;

export const defaultInitialValues: Partial<BankAccountFormValues> = {
    type: 0,
    currency: "NTD",
    accountName: "",
};

export interface BankAccountFormBaseProps extends FormBaseProps<BankAccountFormValues> {
    initialValues: Partial<BankAccountFormValues>;
}

function BankAccountFormBase({
    initialValues = defaultInitialValues,
    onSubmit,
    formMode = modeDef.ADD,
    ...props
}: BankAccountFormBaseProps) {
    const { t } = useTranslation();
    const schema: SchemaOf<BankAccountFormValues> = yup
        .object()
        .shape({
            type: yup.number().label(t("c.fd.type")).required(),
            accountTitleId: yup.number().label(t("e.accountTitle")).required(),
            bankCode: yup
                .string()
                .when("type", (type: number | null, schema: StringSchema) =>
                    type === 0
                        ? schema.matches(/\d{3}/, t("bankAccount.msg.bankCodeInvalid")).required()
                        : schema.nullable()
                ),
            accountNumber: yup
                .string()
                .when("type", (type: number | null, schema: StringSchema) =>
                    type === 0
                        ? schema.matches(/\d{8,16}/, t("bankAccount.msg.accountNumberInvalid")).required()
                        : schema.nullable()
                ),
            accountName: yup
                .string()
                .when("type", (type: number | null, schema: StringSchema) =>
                    type === 0 ? schema.required() : schema.nullable()
                ),
        })
        .defined();
    const validate = makeValidate(schema);
    return (
        <FormBase<BankAccountFormValues>
            validate={validate}
            initialValues={initialValues}
            onSubmit={onSubmit}
            {...props}
        >
            <FormContent />
        </FormBase>
    );
}
function FormContent() {
    const { t } = useTranslation();
    const form = useForm();
    const typeData = t("bankAccount.type", { returnObjects: true }) as Record<string, string>;
    const accountTitleTempData = useReduxTempDataBase<AccountTitleRow[]>({
        apiPath: `/api/accountTitle/data`,
        name: t("e.accountTitle"),
    });
    const { accountTitleSettings } = useBankAccountFormContext();
    const [accountTitleDialogOpen, setAccountTitleDialogOpen] = useState<boolean>(false);
    const [newAccountTitleToSet, setNewAccountTitleToSet] = useState<number | null>(null);
    const handleAccountTitleDialogOpen = () => {
        setAccountTitleDialogOpen(true);
    };
    const handleAccountTitleDialogClose = () => {
        setAccountTitleDialogOpen(false);
    };

    const handleAccountTitleCreateFinished = (resp: void | ResponseHandler<AccountTitleRow>) => {
        console.log(resp);
        if (resp && resp.status === "ok") {
            accountTitleTempData
                .reload()
                .then(() => {
                    console.log(resp.result?.id);
                    setNewAccountTitleToSet(resp.result?.id || null);
                })
                .catch(() => {});
        }
    };

    useEffect(() => {
        if (newAccountTitleToSet && accountTitleTempData.data) {
            const rowToFind = accountTitleTempData.data.find((row) => row.id === newAccountTitleToSet);
            if (rowToFind) {
                form.change("accountTitleId", newAccountTitleToSet);
                setNewAccountTitleToSet(null);
            }
        }
    }, [newAccountTitleToSet, accountTitleTempData.data]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Select name="type" label={t("c.fd.type")} variant="outlined">
                        {Object.keys(typeData).map((a, i) => {
                            return (
                                <MenuItem key={i} value={Number(a)}>
                                    {typeData[a]}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                <Field name="type">
                    {(typeInputProps) => {
                        const typeVal = typeInputProps.input.value;
                        if (typeVal !== 0) {
                            form.change("accountNumber", "");
                            form.change("bankCode", "");
                        }
                        const parentRow = accountTitleSettings[typeVal === 0 ? "bank" : "cash"];
                        return (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                        }}
                                    >
                                        <Box sx={{ flexGrow: 1 }}>
                                            <AccountTitlePickerField
                                                field="accountTitleId"
                                                label={t("e.accountTitle")}
                                                filter={(item) => {
                                                    return (
                                                        !!parentRow &&
                                                        item.l3 === parentRow.id &&
                                                        item.catalog === 1 &&
                                                        item.type === 3
                                                    );
                                                }}
                                            />
                                        </Box>
                                        <IconButton onClick={handleAccountTitleDialogOpen}>
                                            <AddIcon />
                                        </IconButton>
                                    </Box>
                                </Grid>
                                {typeVal === 0 && (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label={t("bankAccount.fd.bankCode")}
                                                name="bankCode"
                                                variant="outlined"
                                                inputProps={{ maxLength: 3 }}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label={t("bankAccount.fd.accountNumber")}
                                                name="accountNumber"
                                                variant="outlined"
                                                inputProps={{ maxLength: 16 }}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t("bankAccount.fd.accountName")}
                                                name="accountName"
                                                inputProps={{ maxLength: 50 }}
                                                required
                                            />
                                        </Grid>
                                    </>
                                )}
                            </>
                        );
                    }}
                </Field>
                <Grid item xs={12}>
                    <TextField label={t("c.fd.rmk")} inputProps={{ maxLength: 200 }} name="remark" />
                </Grid>
            </Grid>
            <Field name="type">
                {(typeInputProps) => {
                    const typeVal = typeInputProps.input.value;
                    return (
                        <CreateAccountTitleDialog
                            type={typeVal}
                            open={accountTitleDialogOpen}
                            onClose={handleAccountTitleDialogClose}
                            onFinished={handleAccountTitleCreateFinished}
                        />
                    );
                }}
            </Field>
        </>
    );
}

export default BankAccountFormBase;
