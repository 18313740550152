import React from "react";
import { Grid } from "@mui/material";
import LeaveBlock, { LeaveBlockProps } from "./LeaveBlock";

interface LeaveBlockGridProps {
    leaveBlocks: Array<LeaveBlockProps>;
}

function LeaveBlockGrid({ leaveBlocks }: LeaveBlockGridProps) {
    return (
        <Grid container spacing={4} justifyContent="center">
            {leaveBlocks.map((leaveBlockProps) => {
                const { id: key } = leaveBlockProps;
                return (
                    <Grid key={key} item xs={12} sm={6} lg={3}>
                        <LeaveBlock {...leaveBlockProps} />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default LeaveBlockGrid;
