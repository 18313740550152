import React from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import mockFilterStatus from "components/mock/initialLeaveListMock";
import { useTheme } from "@mui/material/styles";

export interface InitialLeaveRowData {
    employeeId: string;
    employeeName: string;
    hireDate: Date;
    department: string;
    mainPosition: string;
    updatedAt?: Date;
    initialState: string;
    isLocked: boolean;
}

export interface InitialLeaveSearchTableProps extends Partial<CustomMaterialTableProps<InitialLeaveRowData>> {
    options?: Options<InitialLeaveRowData>;
    columns: Column<InitialLeaveRowData>[];
    header?: React.ReactNode;
}

function InitialLeaveSearchTable({ options = {}, columns, header, ...props }: InitialLeaveSearchTableProps) {
    const theme = useTheme();
    // TODO: retrieve real data from api
    const formTable = {
        fetch: () => mockFilterStatus(),
    };

    return (
        <>
            {header && header}
            <MaterialTable<InitialLeaveRowData>
                {...props}
                columns={columns}
                data={formTable.fetch()}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: false,
                    rowStyle: (rowData: InitialLeaveRowData) => ({
                        color: rowData.isLocked ? theme.palette.text.disabled : theme.palette.text.primary,
                    }),
                    ...options,
                }}
            />
        </>
    );
}

export default InitialLeaveSearchTable;
