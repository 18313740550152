import React, { useRef, useEffect, useMemo, MouseEvent, RefObject } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { SchemaOf, StringSchema } from "yup";
import { makeValidate } from "mui-rff";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogProps, Button, Typography } from "@mui/material";
import { ResponseHandler } from "common";
import { FormBase } from "components";
import { FormBaseRefProps } from "components/final-form/FormBase";
import { useDataCUD, useFetchData2 as useFetchData } from "hooks";
import PasswordField from "./PasswordField";
import { PasswordViewerDialogRef } from "./PasswordViewerDialog";
import { UserFormValues } from "./UserFormBase";

export interface PasswordResetFormValues {
    autoGenerate: boolean;
    resetPassword: boolean;
    newPassword: string;
    confirmPassword: string;
}

export interface PasswordResetResult {
    newPassword: string;
}

export interface PasswordResetDialogProps extends DialogProps {
    dataId: string | null;
    passwordViewerDialogRef: RefObject<PasswordViewerDialogRef>;
}

const initialValues: PasswordResetFormValues = {
    autoGenerate: false,
    resetPassword: false,
    newPassword: "",
    confirmPassword: "",
};

function PasswordResetDialog({ open, dataId, onClose, passwordViewerDialogRef, ...props }: PasswordResetDialogProps) {
    const { t } = useTranslation();
    const formRef = useRef<FormBaseRefProps<PasswordResetFormValues>>(null);
    const [rowData, rowDataFetch, rowDataCleanup] = useFetchData<UserFormValues>(`/api/user/${dataId}`, t("e.user"));
    const { handleEdit } = useDataCUD();
    const handleClose = (event?: MouseEvent<HTMLButtonElement>) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const userInfo = useMemo(() => {
        if (rowData) {
            return `#${rowData.id} ${rowData.name}`;
        }
        return "";
    }, [rowData]);
    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };
    const handleSubmit = async (values: PasswordResetFormValues) => {
        await handleEdit({ ...values, id: dataId }, "/api/user/enforceChangePassword")
            .then((resp) => {
                const mResp = resp as ResponseHandler<PasswordResetResult>;
                if (values.autoGenerate && mResp.result) {
                    const newPassword = mResp.result.newPassword;
                    passwordViewerDialogRef.current && passwordViewerDialogRef.current.show(newPassword, userInfo);
                }
                handleClose();
            })
            .catch((err) => {
                console.log(err);
            });

        //Check if data has been changed or not
    };
    const schema: SchemaOf<PasswordResetFormValues> = yup
        .object()
        .shape({
            autoGenerate: yup.bool().label(t("user.func.generatePassword")).required(),
            resetPassword: yup.bool().label(t("c.enabledStatus")).required(),
            newPassword: yup
                .string()
                .label(t("user.fd.newPass"))
                .when("autoGenerate", (val: string, schema: StringSchema) =>
                    !val ? schema.required() : schema.nullable()
                ),
            confirmPassword: yup
                .string()
                .label(t("user.fd.confirmPass"))
                .when("autoGenerate", (val: string, schema: StringSchema) =>
                    !val
                        ? schema.oneOf([yup.ref("newPassword"), null], t("user.msg.confirmNotMatch")).required()
                        : schema.nullable()
                ),
        })
        .defined();
    const validate = makeValidate(schema);
    useEffect(() => {
        if (dataId) {
            rowDataFetch();
        }
        return () => {
            rowDataCleanup();
        };
    }, [dataId]);
    return (
        <Dialog {...props} open={open} onClose={onClose} aria-labelledby="reset-password-dialog-title">
            <DialogTitle id="reset-password-dialog-title">
                {t("user.func.resetPassword")}
                <Typography variant="body1">{userInfo}</Typography>
            </DialogTitle>
            <DialogContent>
                <FormBase<PasswordResetFormValues>
                    validate={validate}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    formRef={formRef}
                >
                    <PasswordField passwordFieldName="newPassword" confirmPasswordFieldName="confirmPassword" />
                </FormBase>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PasswordResetDialog;
