import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Block = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: "12px",
    height: "100%",
}));

export default Block;
