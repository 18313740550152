import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AttendanceStatusChip from "./AttendanceStatusChip";
import AttendanceStatusEnum from "./AttendanceStatusEnum";

export interface AttendanceStatusProps {
    statusId: AttendanceStatusEnum;
}

export default function AttendanceStatus({ statusId }: AttendanceStatusProps) {
    const { t } = useTranslation();

    const statuses: { [key in AttendanceStatusEnum]: ReactNode } = {
        [AttendanceStatusEnum.NORMAL]: <AttendanceStatusChip type={AttendanceStatusEnum.NORMAL} label={t("正常")} />,
        [AttendanceStatusEnum.ABNORMAL]: (
            <AttendanceStatusChip type={AttendanceStatusEnum.ABNORMAL} label={t("異常")} />
        ),
    };

    const [status, setStatus] = useState<ReactNode | null>(null);
    useEffect(() => {
        setStatus(statuses[statusId] ?? null);
    }, [statusId]);

    return <>{status || status}</>;
}
