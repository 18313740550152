import { store, DEF } from "Action";
//import getFormList from "./getFormList";
import axios from "axios";
import loadClear from "./loadClear";

async function checkLogin() {
    const dispatch = store.dispatch;
    console.log(DEF);
    return await axios.get("/auth/login").then(function (response) {
        loadClear();
        console.debug("get /auth/login", response);
        const data = response.data;
        // dispatch({ type: DEF.GET_TEMPDATA, url:'api/123456' });
        const action = { type: DEF.USER_UPDATE, value: data };
        console.debug(action);
        dispatch(action);
        if (data.employeeId) {
            //getFormList();
        }
    });
}
export default checkLogin;
