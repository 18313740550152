import React, { useEffect, useContext, createContext } from "react";
import { useTranslation } from "react-i18next";
import { Select, TextField, makeValidate } from "mui-rff";
import * as yup from "yup";
import { SchemaOf, StringSchema } from "yup";
import { Box, Grid, MenuItem } from "@mui/material";
import FormBase, { FormBaseProps } from "components/final-form/FormBase";
import { StatusSwitch } from "components/final-form";
import { useFetchData2 as useFetchData } from "hooks";
import { modeDef, ModeDefineType } from "hooks/useFormData";
import { addToast } from "helpers";
import { EnabledStatusChip } from "components";

export interface DepartmentFormValues {
    id?: number;
    name: string;
    parentDepartmentId: number | null;
    status: boolean;
    remark: string;
}

export const defaultInitialValues: Partial<DepartmentFormValues> = {
    status: true,
    name: "",
    parentDepartmentId: 0,
    remark: "",
};

export interface DepartmentFormBaseProps extends FormBaseProps<DepartmentFormValues> {
    initialValues: Partial<DepartmentFormValues>;
}

interface ParentsDepartmentRow {
    id: number | null;
    name: string;
    status: number;
}

interface FormContextValue {
    formMode: ModeDefineType;
    availableParentsData: ParentsDepartmentRow[];
}

const FormContext = createContext<FormContextValue>({
    formMode: modeDef.ADD,
    availableParentsData: [],
});

const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

function DepartmentFormBase({
    initialValues = defaultInitialValues,
    onSubmit,
    formMode = modeDef.ADD,
    ...props
}: DepartmentFormBaseProps) {
    const { t } = useTranslation();
    const [availableParentsData, availableParentsDataFetch, availableParentsDataCleanup] = useFetchData<
        ParentsDepartmentRow[]
    >("/api/department/availableParents/" + (initialValues.id || 0), t("e.depart"), undefined, []);
    const mAvailableParentsData: ParentsDepartmentRow[] =
        availableParentsData && Array.isArray(availableParentsData) ? availableParentsData : [];
    const schema: SchemaOf<DepartmentFormValues> = yup
        .object()
        .shape({
            parentDepartmentId: yup.number().nullable(),
            name: yup
                .string()
                .label(t("department.fd.name"))
                .required()
                .when("parentDepartmentId", (val: number | null, schema: StringSchema) => {
                    if (val) {
                        const parentDepartmentNames = mAvailableParentsData.map((row) => row.name);
                        return schema.notOneOf(parentDepartmentNames, t("department.err.noParentNodeName"));
                    }
                    return schema;
                }),
            status: yup.bool().label(t("c.enabledStatus")).required(),
        })
        .defined();

    const validate = makeValidate(schema);

    useEffect(() => {
        availableParentsDataFetch().catch((err) => {
            console.log(err);
            if ("code" in err && err.code === "202") {
                addToast(t("department.err.mag"), { appearance: "error" });
            }
        });
        return () => {
            availableParentsDataCleanup();
        };
    }, [initialValues.id]);

    return (
        <FormBase<DepartmentFormValues>
            validate={validate}
            initialValues={initialValues}
            onSubmit={onSubmit}
            {...props}
        >
            <FormContext.Provider value={{ formMode, availableParentsData: mAvailableParentsData }}>
                <FormContent />
            </FormContext.Provider>
        </FormBase>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const { availableParentsData } = useFormContext();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <StatusSwitch name="status" />
            </Grid>
            <Grid item xs={12}>
                <Select
                    name="parentDepartmentId"
                    label={t("department.fd.parentDepartment")}
                    formControlProps={{ margin: "normal" }}
                    required
                >
                    {availableParentsData &&
                        availableParentsData.map((item, index) => (
                            <MenuItem key={index} value={item.id || 0}>
                                <Box display="flex" alignItems="center">
                                    {item.name}
                                    {item.id && (
                                        <Box ml={1}>
                                            <EnabledStatusChip value={item.status} />
                                        </Box>
                                    )}
                                </Box>
                            </MenuItem>
                        ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <TextField label={t("department.fd.name")} name="name" required />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t("department.fd.remark")} defaultValue="" name="remark" />
            </Grid>
        </Grid>
    );
}

export default DepartmentFormBase;
