import React, { useRef, useEffect, useCallback, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps, Box, Divider } from "@mui/material";
import { SimpleLoading, FormBaseRefProps, EmployeeInfoView } from "components";
import { useFormData } from "hooks";
import BasicApprovalFormBase, { BasicApprovalFormValues, defaultInitialValues } from "./BasicApprovalFormBase";

export interface BasicApprovalDialogProps extends DialogProps {
    dataId: null | number;
    onChange?: () => void;
}

function BasicApprovalDialog({ open, dataId, onChange, onClose, ...props }: BasicApprovalDialogProps) {
    const formRef = useRef<FormBaseRefProps<BasicApprovalFormValues>>(null);
    const { t } = useTranslation();
    const [formDataLoading, setFormDataLoading] = useState(false);

    const formData = useFormData<BasicApprovalFormValues>(
        "/api/employee/flow",
        defaultInitialValues,
        dataId,
        (data) => {
            if (data) {
                if (data.type) {
                    data.type = data.type.toString() as any;
                } else {
                    data.type = "0";
                }
            }
        }
    );
    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const handleSubmit = async (values: BasicApprovalFormValues) => {
        //Check if data has been changed or not
        const mValue = { ...values, employeeId: dataId };
        await formData
            .save(mValue)
            .then(async (resp) => {
                onChange && onChange();
                fetchData();
                handleClose();
            })
            .catch(() => {});
    };
    const fetchData = useCallback(async () => {
        setFormDataLoading(true);
        await formData
            .fetch()
            .catch((error) => {
                console.log(error);
                handleClose();
            })
            .finally(() => {
                setFormDataLoading(false);
            });
    }, [dataId, open]);
    useEffect(() => {
        if (open) {
            fetchData();
        }
        return () => {
            formData.cleanup();
        };
    }, [fetchData, dataId, open]);
    console.log(formData.data);
    return (
        <>
            <Dialog aria-labelledby="basic-approval-dialog-title" open={open} {...props}>
                <DialogTitle id="basic-approval-dialog-title">{t("approval.form")}</DialogTitle>
                <Divider />
                <DialogContent>
                    <EmployeeInfoView employeeId={dataId} employeeLabel={t("audit.fd.user")} />
                    <Box my={1} />
                    {formDataLoading ? (
                        <SimpleLoading />
                    ) : (
                        <BasicApprovalFormBase
                            dataId={dataId}
                            onSubmit={handleSubmit}
                            initialValues={formData.data}
                            formRef={formRef}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        {t("c.cancel")}
                    </Button>
                    <Button color="primary" onClick={handleSave}>
                        {t("c.save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BasicApprovalDialog;
