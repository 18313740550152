import React from "react";
import Block from "./Block";
import { Grid, Typography } from "@mui/material";
import FestivalIcon from "@mui/icons-material/Festival";
import { blue } from "@mui/material/colors";
import IconAvatar from "./IconAvatar";

export interface RemainingLeaveBlockProps {
    title: string;
    days: string;
}

function RemainingLeaveBlock({ title, days }: RemainingLeaveBlockProps) {
    return (
        <Block>
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                height="100%"
            >
                <Grid item xs={2}>
                    <IconAvatar background={blue[50]}>
                        <FestivalIcon fontSize="small" sx={{ color: blue[300], background: blue[50] }} />
                    </IconAvatar>
                </Grid>
                <Grid item>
                    <Typography
                        fontSize="48px"
                        fontWeight="700"
                        textAlign="start"
                        lineHeight="56px"
                        sx={{ color: "#003689" }}
                    >
                        {days}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h6"
                        fontSize="20px"
                        fontWeight="500"
                        textAlign="start"
                        lineHeight="32px"
                        sx={{ color: "#003689" }}
                    >
                        {title}
                    </Typography>
                </Grid>
            </Grid>
        </Block>
    );
}

export default RemainingLeaveBlock;
