import React, { useRef, useContext, createContext } from "react";
import moment, { Moment } from "moment";
import * as yup from "yup";
//import { Field, useForm } from "react-final-form";
import { TextField, makeValidate } from "mui-rff";
import { useTranslation } from "react-i18next";
import { Alert, Grid, Card, CardHeader, CardContent, Divider, Box, Stack } from "@mui/material";

import { AlertWithAuthLink } from "components";
import FormBase, { FormBaseProps } from "components/final-form/FormBase";
import { formatNumberInput } from "components/CommonNumberFormat";
import { modeDef, ModeDefineType } from "hooks/useFormData";
//import { useReduxTempData } from "hooks";
import { AccountVoucherRowData } from "./AccountVoucherSearchTable";
import { FormApi } from "final-form";
import FSBasic from "./formSection/FSBasic";
import FSDetail from "./formSection/FSDetail";
import { useAccountTitleSetting } from "hooks";
import { AccountTitleRow } from "pages/accountSetting";
import { UniformInvoiceRow } from "./formSection/UniformInvoiceFormContent";

export type AccountVoucherFormValues = Partial<AccountVoucherRowData> & {
    accountVoucherDetails: AccountVoucherDetail[];
};

export interface AccountVoucherDetail {
    accountTitleId: number;
    particulars: string;
    invoiceId: number;
    debit: number;
    credit: number;
    tag: string;
    uniformInvoice?: UniformInvoiceRow | null;
}

export const initVal: AccountVoucherFormValues = {
    date: moment.utc("00:00:00", "HH:mm:ss"),
    totalDebit: 0,
    totalCredit: 0,
    source: "manual",
    status: 0,
    remark: "",
    accountVoucherDetails: [],
};

export interface AccountVoucherFormContext {
    dtlTableRef?: React.MutableRefObject<any> | React.RefObject<any>;
    formMode: ModeDefineType;
    consoDate?: Date | Moment;
    editable: boolean;
    inTaxAccountTitle?: AccountTitleRow;
    exTaxAccountTitle?: AccountTitleRow;
}

const FormContext = createContext<AccountVoucherFormContext>({
    formMode: modeDef.ADD,
    editable: true,
});

export const useAccountVoucherFormContext = () => useContext(FormContext);

export interface AccountVoucherFormBaseProps extends FormBaseProps<AccountVoucherFormValues> {
    initialValues: Partial<AccountVoucherFormValues>;
    editable?: boolean;
}

function AccountVoucherFormBase({
    initialValues,
    //enableSection = [],
    onSubmit,
    formMode = modeDef.ADD,
    recordId = "",
    auditActionType = null,
    editable = true,
    ...props
}: AccountVoucherFormBaseProps) {
    const dtlTableRef = useRef(null);
    const { t } = useTranslation();
    const { accountTitleSettingData, checkAccountTitleIsSet } = useAccountTitleSetting();
    const accountTitleIsSet = checkAccountTitleIsSet(["in_tax", "ex_tax"]);
    const inTaxAccountTitle = accountTitleSettingData?.in_tax;
    const exTaxAccountTitle = accountTitleSettingData?.ex_tax;
    //const { dataId, formInitialValues } = useMFFormBase(initVal, initialValues, auditActionType, "attachment");
    const dataLocked = false;
    /*
    READY: 鎖帳會用到
    const consoDate = useReduxTempData("/api/accountConsolidation/dateCheck/", "", false, (data) => {
        if (data && data.date) {
            return moment.utc(data.date);
        } else return null;
    });
    if (consoDate && formInitialValues) {
        dataLocked = moment.utc(formInitialValues.date).isBefore(consoDate);
        if (dataLocked) {
            formMode = modeDef.VIEW;
            enableSection = [];
        }
    }*/
    const schema = yup
        .object()
        .shape({
            date: yup.date().label(t("c.fd.date")).required(),
            totalDebit: yup.number().required(),
            totalCredit: yup
                .number()
                .equals([yup.ref("totalDebit")], t("accountVoucher.err.cdUnbalanced"))
                .required(),
            accountVoucherDetails: yup.array().label(t("raData.fd.secBody")).min(1).required(),
        })
        .defined();
    const validate = makeValidate(schema);
    const handleSubmit = (values: AccountVoucherFormValues, form: FormApi<AccountVoucherFormValues>) => {
        onSubmit(values, form);
    };

    return (
        <>
            {accountTitleSettingData && !accountTitleIsSet && (
                <Box mb={2}>
                    <AlertWithAuthLink
                        severity="warning"
                        authLinkProps={{
                            permissionPage: "accountSetting",
                            to: "/accountSetting/setting",
                        }}
                        linkLabel={t("c.goToSet")}
                    >
                        {t("accountVoucher.msg.accountTitleNotSet")}
                    </AlertWithAuthLink>
                </Box>
            )}
            {dataLocked && (
                <Box mb={2}>
                    <Alert severity="warning">{t("accountConsolidation.msg.dataLocked")}</Alert>
                </Box>
            )}
            <FormBase<AccountVoucherFormValues>
                validate={validate}
                mutators={{
                    setCustomer: (args, state, utils) => {
                        utils.changeValue(state, "customer", () => args[0]);
                    },
                }}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                {...props}
            >
                <FormContext.Provider
                    value={{
                        dtlTableRef,
                        formMode,
                        editable,
                        inTaxAccountTitle,
                        exTaxAccountTitle,
                    }}
                >
                    <FormContent />
                </FormContext.Provider>
            </FormBase>
        </>
    );
}

function FormContent() {
    return (
        <Stack spacing={3}>
            <FSBasic />
            <FSDetail />
            <FSTotal />
        </Stack>
    );
}

function FSTotal() {
    const { t } = useTranslation();
    return (
        <Card>
            <CardHeader title={t("c.total")} titleTypographyProps={{ variant: "h6" }} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("accountVoucher.fd.debit")}
                            name="totalDebit"
                            InputProps={formatNumberInput()}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("accountVoucher.fd.credit")}
                            name="totalCredit"
                            InputProps={formatNumberInput()}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default AccountVoucherFormBase;
