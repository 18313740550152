import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { Button, ButtonProps } from "@mui/material";
import { useUserPermission } from "hooks";

export type AuthLinkProps = Pick<NavLinkProps, "to"> &
    Pick<ButtonProps, "children" | "color" | "size"> & {
        permissionType?: "read" | "write";
        permissionPage: string;
    };

function AuthLink({ permissionType = "write", permissionPage, children, to, color, size }: AuthLinkProps) {
    const { isRead, isWrite } = useUserPermission();
    const auth = permissionType === "read" ? isRead(permissionPage) : isWrite(permissionPage);
    if (!auth) {
        return <></>;
    }
    return (
        <Button component={NavLink} color={color} size={size} to={to}>
            {children}
        </Button>
    );
}

export default AuthLink;
