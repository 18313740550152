import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TextField, Switches, makeValidate } from "mui-rff";
import * as yup from "yup";
import { Grid } from "@mui/material";

import { FormBase } from "components";
import QuillEditor from "components/final-form/QuillEditor";

function AnnounceForm({ onSubmit, initialValues, formRef, writable = false }) {
    const { t } = useTranslation();
    const schema = yup.object().shape({
        title: yup.string().label(t("c.fd.title")).trim().required(),
        content: yup.string().label(t("c.fd.content")).trim().required(),
        fixedTop: yup.boolean().label(t("announce.fd.fixedTop")).required(),
        visible: yup.boolean().label(t("announce.fd.visible")).required(),
    });
    const validate = makeValidate(schema);
    return (
        <FormBase onSubmit={onSubmit} validate={validate} initialValues={initialValues} formRef={formRef}>
            <FormContent writable={writable} />
        </FormBase>
    );
}

function FormContent({ writable }) {
    const { t } = useTranslation();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    required={true}
                    label={t("c.fd.title")}
                    name="title"
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                    disabled={!writable}
                />
            </Grid>
            <Grid item xs={12}>
                <QuillEditor
                    required={true}
                    label={t("c.fd.content")}
                    name="content"
                    readOnly={!writable}
                    //variant="outlined"
                    //fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Switches
                    label={t("announce.fd.fixedTop")}
                    name="fixedTop"
                    color="primary"
                    data={{ label: "", value: true }}
                    disabled={!writable}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Switches
                    label={t("announce.fd.visible")}
                    name="visible"
                    color="primary"
                    data={{ label: "", value: true }}
                    disabled={!writable}
                />
            </Grid>
        </Grid>
    );
}

FormContent.propTypes = {
    writable: PropTypes.bool,
};

AnnounceForm.propTypes = {
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    formRef: PropTypes.shape({
        current: PropTypes.object,
    }),
    writable: PropTypes.bool,
};
export default AnnounceForm;
