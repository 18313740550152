import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageHeader2 as PageHeader } from "layout";
import { Grid } from "@mui/material";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import { SearchTextField } from "components";
import BasicApprovalTable, { ApprovalRowData } from "./BasicApprovalTable";
import BasicApprovalDialog from "./BasicApprovalDialog";
import BasicApprovalPreviewDialog from "./BasicApprovalPreviewDialog";
import { useUserPermission } from "hooks";

function BasicApprovalList() {
    const { t } = useTranslation();
    const tableRef = useRef<any>(null);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("approval"), [isWrite]);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState<boolean>(false);
    const [dataId, setDataId] = useState<null | number>(null);

    const handleEdit = async (row: ApprovalRowData) => {
        setDataId(row.id);
        setIsDialogOpen(true);
    };

    const handlePreview = async (row: ApprovalRowData) => {
        setDataId(row.id);
        setIsPreviewDialogOpen(true);
    };

    const refreshTable = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };
    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };
    const handlePreviewDialogClose = () => {
        setIsPreviewDialogOpen(false);
    };
    const actions: ActionType<ApprovalRowData>[] = writable
        ? [
              {
                  icon: "edit",
                  tooltip: t("c.edit"),
                  onClick: (e: any, rowData) => {
                      if (!Array.isArray(rowData)) {
                          handleEdit(rowData);
                      }
                  },
              },
              (rowData) => ({
                  icon: "visibilityIcon",
                  tooltip: t("approval.preview"),
                  onClick: (e: any, rowData) => {
                      if (!Array.isArray(rowData)) {
                          handlePreview(rowData);
                      }
                  },
              }),
          ]
        : [
              (rowData) => ({
                  icon: "visibilityIcon",
                  tooltip: t("approval.preview"),
                  onClick: (e: any, rowData) => {
                      if (!Array.isArray(rowData)) {
                          handlePreview(rowData);
                      }
                  },
              }),
          ];

    return (
        <>
            <PageHeader
                title=""
                leftToolView={<Grid container spacing={1}></Grid>}
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                    </Grid>
                }
            />
            <BasicApprovalTable actions={actions} tableRef={tableRef} />
            <BasicApprovalDialog
                dataId={dataId}
                onClose={handleDialogClose}
                open={isDialogOpen}
                onChange={refreshTable}
            />
            <BasicApprovalPreviewDialog dataId={dataId} onClose={handlePreviewDialogClose} open={isPreviewDialogOpen} />
        </>
    );
}

export default BasicApprovalList;
