/* eslint-disable react/prop-types */
import React from "react";
import { InputProps } from "@mui/material";
import NumberFormat, { NumberFormatProps } from "react-number-format";

export type CommonNumberFormatProps = NumberFormatProps<any> & {
    number: number | string;
    isPercentage?: boolean;
    toFixed?: number;
};

export default function CommonNumberFormat({
    number,
    toFixed,
    isPercentage = false,
    ...props
}: CommonNumberFormatProps) {
    if (number !== undefined && !isNaN(Number(number))) {
        let mNumber: number | string = Number(number);
        if (isPercentage) {
            mNumber *= 100;
        }
        if (toFixed !== null) {
            mNumber = mNumber.toFixed(toFixed);
        }
        return (
            <NumberFormat
                thousandSeparator={true}
                displayType={"text"}
                value={mNumber}
                suffix={isPercentage ? "%" : ""}
                {...props}
            />
        );
    } else {
        return <></>;
    }
}

export function mtFormatNumber<RowData = any>(field: string, toFixed?: number, percent: boolean = false) {
    return (rowData: RowData) =>
        field in rowData ? (
            <CommonNumberFormat
                number={rowData[field as keyof RowData] as any}
                toFixed={toFixed}
                isPercentage={percent}
            />
        ) : (
            ""
        );
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
});

export const formatNumberInput = (props = {}): Partial<InputProps> => ({
    inputComponent: NumberFormatCustom as any,
    inputProps: {
        ...props,
    },
});
