/* eslint-disable no-template-curly-in-string */
const ja = {
    mixed: {
        default: "${path}が正しくありません。",
        required: "${path}を入力してください。",
        oneOf: "${path}には${values}のうちいずれかの値を指定してください。",
        notOneOf: "${path}には${values}のうちいずれとも異なる値を指定してください。",
        notType: "正しくありません。",
        /*notType: ({ path, type, value, originalValue }) => {
          let isCast = originalValue != null && originalValue !== value;
          let msg =
            `${path}必須是\`${type}\`格式, ` +
            `但目前的值為: \`${printValue(value, true)}\`` +
            (isCast
              ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
              : '.');
      
          if (value === null) {
            msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
          }
      
          return msg;
        },*/
        defined: "${path}を定義してください。",
    },
    string: {
        length: "${path}には${length}文字で入力してください。",
        min: "${path}には${min}文字以上で入力してください。",
        max: "${path}には${max}文字以下で入力してください。",
        matches: "${path}には「${regex}」の形式を指定してください",
        email: "${path}が有效なメールアドレスではありません。",
        url: "${path}が有效なURLではありません。",
        trim: "${path}前後不能有任何空白",
        lowercase: "${path}には大文字で入力してください。",
        uppercase: "${path}には小文字で入力してください。",
    },
    number: {
        min: "${path}には${min}以上の数値を指定してください。",
        max: "${path}には${max}以下の数値を指定してください。",
        lessThan: "${path}には${less}未満の数値を指定してください。",
        moreThan: "${path}には${more}を超える数値を指定してください。",
        notEqual: "${path}には${notEqual}以外の数値を指定してください。",
        positive: "${path}には負数を指定してください。",
        negative: "${path}には正数を指定してください。",
        integer: "${path}には整数を指定してください。",
    },
    boolean: {},
    date: {
        min: "${path}には${min}以降の日付を指定してください。",
        max: "${path}には${max}以前の日付を指定してください。",
    },
    array: {
        min: "${path}には${min}個以上の要素を持つ配列を指定してください。",
        max: "${path}には${max}個以下の要素を持つ配列を指定してください。",
    },
    object: {
        noUnknown: "${path}には未定義のキーがあります: ${unknown}",
    },
};
export default ja;
