import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Action } from "@material-table/core";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import MdiIcon from "@mdi/react";
import { mdiFileSearch } from "@mdi/js";
import { useHistory } from "react-router-dom";
import { StandaloneConfirmDialog, StandaloneConfirmDialogRef } from "components";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import { useDataCUD, useUserPermission } from "hooks";
import { PageHeader2 as PageHeader } from "layout";
import ShiftSearchTable, { ShiftRowData } from "../shift/ShiftSearchTable";

function ShiftList() {
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const { t } = useTranslation();
    const crud = useDataCUD();
    const history = useHistory();
    const tableRef = useRef<any>(null);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("shiftSetting"), [isWrite]);

    const handleAdd = async () => {
        //setFormDataId(null);
        //setFormOpen(true);
        history.push({ pathname: `/shift/add` }); //
    };
    const handleEdit = async (row: ShiftRowData) => {
        //setFormDataId(row.id.toString());
        history.push({ pathname: `/shift/${row.id}` }); //
    };

    const actions: ActionType<ShiftRowData>[] = [
        (rowData: ShiftRowData): Action<ShiftRowData> => {
            return {
                icon: writable ? "edit" : () => <MdiIcon path={mdiFileSearch} size={1} />,
                tooltip: writable ? t("c.edit") : t("c.view"),
                onClick: (e: any, rowData) => {
                    if (!Array.isArray(rowData)) {
                        handleEdit(rowData);
                    }
                },
            };
        },
    ];

    const extraActions: ActionType<ShiftRowData>[] = [
        (rowData: ShiftRowData): Action<ShiftRowData> => {
            return {
                icon: "delete",
                tooltip: t("c.del"),
                onClick: (e: any, rowData) => {
                    const action = () => {
                        if (!Array.isArray(rowData)) {
                            crud.handleDel({}, `/api/shift/${rowData.id}`).then(() => {
                                tableRef.current.onPageChange(e, 0);
                            });
                        }
                    };
                    dialogRef.current?.open(action);
                },
            };
        },
    ];

    return (
        <Box mt={-2}>
            {/* <Tabs
                value={`basic`}
                //onChange={handleTabsChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
            >
                <Tab value="basic" label={t("shift.tab.basic")} component={Link} to={`${``}`} />
            </Tabs>
            <Divider /> */}
            <PageHeader
                title=""
                leftToolView={
                    <Grid container spacing={1}>
                        {writable && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        handleAdd();
                                    }}
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
            />

            <ShiftSearchTable tableRef={tableRef} actions={actions} extraActions={writable ? extraActions : []} />
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
        </Box>
    );
}

export default ShiftList;
