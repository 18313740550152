import React, { useRef, useState, useMemo, useEffect } from "react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { Action } from "@material-table/core";
import { Box, Stack } from "@mui/material";
import { PageHeader2 as PageHeader } from "layout";
import { useFetchData2 as useFetchData, useReduxTempData } from "hooks";
import ColumnsFactory from "./ColumnsFactory";
import { EmployeeNotSetAlert, StandaloneConfirmDialog, StandaloneConfirmDialogRef } from "components";
import { getUser } from "helpers";
import AuditDialog from "components/audit/AuditDialog";
import AuditStatusEnum from "components/audit/AuditStatusEnum";
import DateRangeFilter from "components/grid/DateRangeFilter";
import GridTabs from "components/grid/GridTabs";
import QuickSearch from "components/grid/QuickSearch";
import Toolbar from "components/grid/Toolbar";
import AddButton from "components/buttons/AddButton";
import FormRow from "components/audit/FormRow";
import AuditRowData from "components/audit/AuditRowData";
import FormRequestSearchTable, { FormRequestRowData } from "./FormRequestSearchTable";
import ApplicationSelectDialog from "./ApplicationSelectDialog";
import ApplicationFormDialog from "./ApplicationFormDialog";
import { TabPageProps } from "layout/types";
import useAuditAction from "components/audit/useAuditAction";

export const acceptedShortCutFormNames = ["leaveRequest", "overtimeRequest", "clockRequest"];
export const acceptedTabNames = ["signOff", "withdrawn", "rejected", "accepted"];

function FormRequestList({ match }: TabPageProps) {
    const user = useMemo(() => getUser(), []);
    const tableRef = useRef<any>(null);
    const dialogRef = useRef<StandaloneConfirmDialogRef>(null);
    const { t } = useTranslation();
    const { formName, tabName } = useParams<{ formName?: string; tabName?: string }>();
    const history = useHistory();
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [formSelectOpen, setFormSelectOpen] = useState<boolean>(false);
    const [activeForm, setActiveForm] = useState<string | null>(null);
    const [activeRowId, setActiveRowId] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<Moment>(moment().startOf("month"));
    const [endDate, setEndDate] = useState<Moment>(moment().endOf("month"));
    const [dataListResult, dataListFetch, dataListCleanup] = useFetchData<AuditRowData[]>(
        `/api/form/request?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`,
        t("e.auditData")
    );
    const formRowData = useReduxTempData<FormRow[]>(`api/form`, t("e.form"));

    const formLookupMap = useMemo<Record<string, any>>(() => {
        const map: Record<string, any> = {};
        if (formRowData && Array.isArray(formRowData)) {
            formRowData.forEach((row) => {
                map[row.id.toString()] = t(`form.title.${row.name}`);
            });
        }
        return map;
    }, [formRowData]);

    useEffect(() => {
        if (user.employee) {
            dataListFetch();
        }
        return () => {
            dataListCleanup();
        };
    }, [startDate, endDate, user]);

    //use for dashboard shortcuts links
    useEffect(() => {
        //const initActiveForm = params.get("form");
        if (formName && acceptedShortCutFormNames.indexOf(formName) > -1) {
            setActiveForm(formName);
        } else if (tabName && acceptedTabNames.indexOf(tabName) > -1) {
            //do nothing
        } else {
            history.replace({ pathname: "/formRequest" });
        }
    }, [formName, tabName]);

    const dataList = useMemo<AuditRowData[]>(() => {
        return dataListResult || [];
    }, [dataListResult]);

    const [pendingCount, acceptCount, withdrawnCount, rejectCount] = useMemo<number[]>(() => {
        return dataList.reduce(
            (previousValue, currentValue) => {
                if (currentValue.status >= 0 && currentValue.status < 4) {
                    previousValue[currentValue.status]++;
                }
                return previousValue;
            },
            [0, 0, 0, 0]
        );
    }, [dataList]);

    const onAuditDialogOpen = (rowId: string) => {
        setActiveRowId(rowId);
    };

    const { withdrawAudit } = useAuditAction();

    const onWithdraw = (rowId: string) => {
        const action = () => {
            if (rowId) {
                withdrawAudit(Number(rowId))
                    .then(() => {
                        refreshData();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        };
        dialogRef.current?.open(action);
    };

    //dynamic enabled/disabled for the reject action
    const assignmentAction: Action<FormRequestRowData> = {
        icon: "assignment",
        iconProps: {
            color: "primary",
        },
        tooltip: t("c.detail"),
        onClick: (event: any, rowData: FormRequestRowData | FormRequestRowData[]) => {
            if (!Array.isArray(rowData)) {
                onAuditDialogOpen(rowData.id);
            }
        },
    };
    const withdrawAction = ({ status }: FormRequestRowData): Action<FormRequestRowData> => ({
        icon: "close",
        iconProps: {
            color: status === AuditStatusEnum.Pending ? "primary" : "disabled",
        },
        tooltip: t("audit.withdrawn"),
        onClick: (event: any, rowData: FormRequestRowData | FormRequestRowData[]) => {
            if (!Array.isArray(rowData)) {
                onWithdraw(rowData.id);
            }
        },
        disabled: status !== AuditStatusEnum.Pending,
    });

    const onRowClick = (rowId: string) => {
        setActiveRowId(rowId);
    };

    const handleStepChoice = (formName: string) => {
        setActiveForm(formName);
    };
    const handleApplicationFormClose = () => {
        if (formName) {
            history.replace({ pathname: "/formRequest" });
        }
        setActiveForm(null);
    };

    const handleFormSelectOpen = () => {
        setFormSelectOpen(true);
    };

    const handleFormSelectClose = () => {
        setFormSelectOpen(false);
    };

    const refreshData = () => {
        dataListFetch();
    };

    const allEntriesSearchTable = (
        <FormRequestSearchTable
            tableRef={tableRef}
            actions={[assignmentAction, withdrawAction]}
            options={{
                filtering: showFilter,
            }}
            columns={ColumnsFactory(
                {
                    type: "main",
                    formAction: onRowClick,
                },
                t,
                { formLookupMap }
            )}
            header={
                <Toolbar>
                    <AddButton variant="contained" onClick={handleFormSelectOpen} disabled={!user.employee}>
                        {t("c.add")}
                    </AddButton>
                    <QuickSearch tableRef={tableRef} onFilter={() => setShowFilter(!showFilter)} />
                </Toolbar>
            }
            data={dataList}
        />
    );

    const filteredEntriesSearchTable = (statusFilter?: AuditStatusEnum) => {
        return (
            <FormRequestSearchTable
                tableRef={tableRef}
                actions={[assignmentAction]}
                options={{
                    filtering: showFilter,
                }}
                columns={ColumnsFactory({ type: "filtered", formAction: onRowClick }, t, { formLookupMap })}
                statusFilter={statusFilter}
                data={dataList}
            />
        );
    };

    const handleDetailClose = () => {
        setActiveRowId(null);
    };

    return (
        <>
            {!user.employee && (
                <Box mt={2}>
                    <EmployeeNotSetAlert />
                </Box>
            )}
            <PageHeader
                title=""
                leftToolView={
                    <Stack spacing={1} direction="row">
                        <DateRangeFilter
                            initStartDate={startDate.toDate()}
                            initEndDate={endDate.toDate()}
                            onApply={(startDate, endDate) => {
                                setStartDate(moment(startDate));
                                setEndDate(moment(endDate));
                            }}
                            disabled={!user.employee}
                        />
                    </Stack>
                }
            />
            <GridTabs
                match={match}
                tabs={[
                    { index: 0, label: t("audit.tab.all"), children: allEntriesSearchTable, url: "" },
                    {
                        index: 1,
                        label: t("audit.tab.signOff"),
                        children: filteredEntriesSearchTable(AuditStatusEnum.Pending),
                        counter: pendingCount,
                        url: "signOff",
                    },
                    {
                        index: 2,
                        label: t("audit.tab.withdrawn"),
                        children: filteredEntriesSearchTable(AuditStatusEnum.Withdrawn),
                        counter: withdrawnCount,
                        url: "withdrawn",
                    },
                    {
                        index: 3,
                        label: t("audit.tab.rejected"),
                        children: filteredEntriesSearchTable(AuditStatusEnum.Rejected),
                        counter: rejectCount,
                        url: "rejected",
                    },
                    {
                        index: 4,
                        label: t("audit.tab.accepted"),
                        children: filteredEntriesSearchTable(AuditStatusEnum.Accepted),
                        counter: acceptCount,
                        url: "accepted",
                    },
                ]}
            />
            <AuditDialog
                onClose={handleDetailClose}
                open={!!activeRowId}
                //onAudit={() => {}}
                //onReject={onWithdraw} // withdraw function is on the table list command
                rowId={activeRowId}
                readonly={true}
            />
            <ApplicationSelectDialog
                open={formSelectOpen}
                onClose={handleFormSelectClose}
                onStepChoice={handleStepChoice}
            />
            <ApplicationFormDialog
                open={!!activeForm}
                formName={activeForm}
                onClose={handleApplicationFormClose}
                onFinished={refreshData}
            />
            <StandaloneConfirmDialog title={t("audit.withdrawn")} content={t("c.msg.confirmScrap")} ref={dialogRef} />
        </>
    );
}

export default FormRequestList;
