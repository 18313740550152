import { styled, Typography, TypographyProps } from "@mui/material";
import React from "react";

// TODO: secondary color for dot in the list element
const StyledTypography = styled((props: TypographyProps) => <Typography {...props} />)(({ theme }) => ({
    "&": {
        color: theme.palette.text.secondary,
    },
}));

function InfoSecondary({ content }: { content: string | React.ReactElement | Array<React.ReactElement> }) {
    return <StyledTypography variant="body2">{content}</StyledTypography>;
}

export default InfoSecondary;
