import React from "react";
import { DialogContentText, Grid, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
export default function ProtectionPolicy() {
    return (
        <>
            <Grid container spacing={2} style={{ padding: 30 }}>
                <Grid item>
                    <DialogContentText id="alert-dialog-description">
                        未来人材日本株式会社（以下「当社」）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進致します。
                        <br />
                        <p style={{ fontSize: "17px", fontWeight: "bold", textDecoration: "underline" }}>
                            個人情報の管理
                        </p>
                        当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。
                        <br />
                        <p style={{ fontSize: "17px", fontWeight: "bold", textDecoration: "underline" }}>
                            個人情報の利用目的
                        </p>
                        お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。
                        <br />
                        <p style={{ fontSize: "17px", fontWeight: "bold", textDecoration: "underline" }}>
                            個人情報の第三者への開示・提供の禁止
                        </p>
                        当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
                        ・お客さまの同意がある場合
                        ・お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合
                        ・法令に基づき開示することが必要である場合
                        <br />
                        <p style={{ fontSize: "17px", fontWeight: "bold", textDecoration: "underline" }}>
                            個人情報の安全対策
                        </p>
                        当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
                        <br />{" "}
                        <p style={{ fontSize: "17px", fontWeight: "bold", textDecoration: "underline" }}>
                            ご本人の照会
                        </p>
                        お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。
                        <br />
                        <p style={{ fontSize: "17px", fontWeight: "bold", textDecoration: "underline" }}>
                            法令、規範の遵守と見直し
                        </p>
                        当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
                        <br />
                        <p style={{ fontSize: "20px", fontWeight: "bold", textDecoration: "underline" }}>お問い合せ</p>
                        こちらに示した個人情報についての考え方についてご不明な点がございましたら
                        下記アドレスまで電子メールでお問い合わせください。
                        <br />
                        hp_contact@mirai-network.com
                    </DialogContentText>
                </Grid>
                <Grid item>
                    <NavLink to="/">
                        <Button color="primary" variant="contained">
                            TOP Page
                        </Button>
                    </NavLink>
                </Grid>
            </Grid>
        </>
    );
}
