import React, { useRef, MouseEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
} from "@mui/material";
import { FormBaseRefProps } from "components";
import { AuditSubmission } from "components/audit/useAuditAction";
import AuditMessageForm, { AuditMessageFormValues } from "components/audit/AuditMessageForm";
import AuditSubmissionStatusEnum from "components/audit/AuditSubmissionStatusEnum";
import FormRow from "components/audit/FormRow";
import { useReduxTempData } from "hooks";
import { RowForAudit } from "./AuditList";

export type MessageFormDialog = Pick<DialogProps, "open" | "onClose"> & {
    onAudit?: (data: AuditSubmission) => void;
    data: RowForAudit | null;
};

const statusKeyMap: Record<AuditSubmissionStatusEnum, string> = {
    [AuditSubmissionStatusEnum.Accepted]: "accept",
    [AuditSubmissionStatusEnum.Rejected]: "reject",
};

function MessageFormDialog({ data, onAudit, onClose, ...props }: MessageFormDialog) {
    const { t } = useTranslation();
    const formRef = useRef<FormBaseRefProps<AuditMessageFormValues>>(null);
    const formRowData = useReduxTempData<FormRow[]>(`api/form`, t("e.form"));

    const formName = useMemo<string>(() => {
        if (data && formRowData) {
            const formData = formRowData.find((row) => row.id === data.formId);
            return formData?.name || "";
        }
        return "";
    }, [data]);

    const handleAuditMessageFormSubmit = (formValue: AuditMessageFormValues) => {
        if (data) {
            onAudit &&
                onAudit({
                    ...formValue,
                    id: data.id,
                    status: data.status,
                });
            handleClose();
        }
    };
    const handleSubmit = () => {
        formRef.current?.onSubmit();
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const statusString = data ? t(`audit.${statusKeyMap[data.status]}`) : "";
    const formNameString = formName !== "" ? t(`form.title.${formName}`) : "";
    return (
        <Dialog
            {...props}
            onClose={onClose}
            aria-labelledby="audit-message-dialog-title"
            aria-describedby="audit-message-dialog-slide-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="audit-message-dialog-title">{`${statusString} ${formNameString} ${
                data?.no || ""
            }`}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="audit-message-dialog-slide-description"
                    sx={{ marginBottom: (theme) => theme.spacing(2) }}
                >
                    {t("audit.msg.reasonInput", { action: statusString })}
                </DialogContentText>
                <AuditMessageForm formRef={formRef} onSubmit={handleAuditMessageFormSubmit} />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                    color="secondary"
                >
                    {t("c.cancel")}
                </Button>
                <Button
                    onClick={() => {
                        handleSubmit();
                    }}
                    color="primary"
                >
                    {t("c.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default MessageFormDialog;
