const initValue = "";
export default function reducer(state = initValue, action: MainTitleAction) {
    switch (action.type) {
        case "SET_MAIN_TITLE":
            if (action.value) {
                return action.value;
            }
            return "";
        default:
            return state;
    }
}

export type MainTitleActionType = "SET_MAIN_TITLE";

export interface MainTitleAction {
    type: MainTitleActionType;
    value?: string;
}
