import DEF from "../def";
const initialState = {};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.UPDATE_TEMPDATA:
            return {
                ...state,
                [action.apiPath]: action.value,
            };
        case DEF.CLEAR_ALL:
        case DEF.CLEAR_TEMPDATA:
            return {};
        default:
            return state;
    }
}
