import React from "react";
import { Chip, ChipProps, styled } from "@mui/material";
import { blueGrey, green, orange, red } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AuditStatusEnum from "./AuditStatusEnum";

const colorMap = {
    [AuditStatusEnum.Rejected]: red[700],
    [AuditStatusEnum.Accepted]: green[600],
    [AuditStatusEnum.Pending]: orange[600],
    [AuditStatusEnum.SigningOff]: orange[600],
    [AuditStatusEnum.Withdrawn]: blueGrey[700],
};

const backgroundMap = {
    [AuditStatusEnum.Rejected]: red[50],
    [AuditStatusEnum.Accepted]: green[50],
    [AuditStatusEnum.Pending]: orange[50],
    [AuditStatusEnum.SigningOff]: orange[50],
    [AuditStatusEnum.Withdrawn]: blueGrey[50],
};

const iconMap = {
    [AuditStatusEnum.Rejected]: <CloseIcon fontSize="inherit" sx={{ color: red[700] }} />,
    [AuditStatusEnum.Accepted]: <CheckIcon fontSize="inherit" sx={{ color: green[600] }} />,
    [AuditStatusEnum.Pending]: undefined,
    [AuditStatusEnum.SigningOff]: undefined,
    [AuditStatusEnum.Withdrawn]: <CloseIcon fontSize="inherit" sx={{ color: blueGrey[700] }} />,
};

interface StyledChipProps extends ChipProps {
    type: AuditStatusEnum;
}

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== "type",
})<StyledChipProps>(({ type, theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: backgroundMap[type],
    color: colorMap[type],
    fontWeight: 700,
    size: "small",
    "& .MuiChip-icon": {
        color: colorMap[type],
    },
}));

export interface AuditStatusChipProps extends ChipProps {
    type: AuditStatusEnum;
}

export default function AuditStatusChip({ type, ...props }: AuditStatusChipProps) {
    return <StyledChip type={type} icon={iconMap[type]} {...props} />;
}
