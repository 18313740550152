import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { OvertimeRequestFormValues } from "components/audit/formType";
import DetailsBase, { DetailsBaseProps } from "./DetailsBase";
import { TextDateItem, TextItem, TextLookupItem } from "components/final-form";

function OvertimeRequestDetail({ value }: DetailsBaseProps<OvertimeRequestFormValues>) {
    const { t } = useTranslation();
    const typeData = t("overtime.type", { returnObjects: true }) as Record<string, string>;
    return (
        <DetailsBase<OvertimeRequestFormValues> value={value}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextDateItem name="startTime" format="YYYY-MM-DD HH:mm" label={t("c.fd.dateStart")} />
                    <TextDateItem name="endTime" format="YYYY-MM-DD HH:mm" label={t("c.fd.dateEnd")} />
                    <TextItem
                        name="hours"
                        label={t("overtime.fd.hours")}
                        render={(value) => (value !== undefined && value !== null ? t("c.units.vh", { value }) : "")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextLookupItem name="type" label={t("overtime.fd.type")} lookup={typeData} />
                    <TextItem name="reason" label={t("c.fd.applyReason")} />
                    <TextItem name="remark" label={t("c.fd.rmk")} />
                </Grid>
            </Grid>
        </DetailsBase>
    );
}

export default OvertimeRequestDetail;
