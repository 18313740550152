import React, { useEffect, useState, useCallback, useContext, createContext, ChangeEvent } from "react";
import { TreeView, TreeViewProps } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import AccountTitleTreeItem, { AccountTitleRow } from "./AccountTitleTreeItem";
import { findChildren } from "./utils";

export interface AccountTitleTreeContext {
    editable?: boolean;
    showToolbar?: boolean;
    onAdd?: (row: AccountTitleRow) => void;
    onEdit?: (row: AccountTitleRow) => void;
    onDelete?: (row: AccountTitleRow) => void;
    onI18nEdit?: (id: number) => void;
    onItemSelected?: (item: AccountTitleRow, event: ChangeEvent<HTMLInputElement>, childrenId: number[]) => void;
    selectedItems: number[];
    selectable?: boolean;
}

const Context = createContext<AccountTitleTreeContext>({
    editable: false,
    selectedItems: [],
});
export const useAccountTitleTreeContext = () => useContext(Context);

export type AccountTitleTreeViewProps = TreeViewProps & {
    data: AccountTitleRow[];
    editable?: boolean;
    showToolbar?: boolean;
    onAdd?: (row: AccountTitleRow) => void;
    onEdit?: (row: AccountTitleRow) => void;
    onDelete?: (row: AccountTitleRow) => void;
    onI18nEdit?: (id: number) => void;
    selectable?: boolean;
    selectedItems?: number[];
    onItemSelected?: (item: AccountTitleRow, event: ChangeEvent<HTMLInputElement>, childrenId: number[]) => void;
};

function AccountTitleTreeView({
    data,
    editable = false,
    showToolbar = true,
    onAdd,
    onEdit,
    onDelete,
    onI18nEdit,
    selectable,
    selectedItems,
    onItemSelected,
    ...props
}: AccountTitleTreeViewProps) {
    const [formattedData, setFormattedData] = useState<AccountTitleRow[]>([]);
    const formatData = useCallback(() => {
        const getData = (row: AccountTitleRow) => ({
            ...row,
        });
        const getSubs = (id: number, nLayer = 0 /*, upper = []*/) => {
            if (nLayer >= 4) {
                return [];
            } else {
                return data
                    .filter((row) => {
                        const nLayerId: number = row[`l${nLayer}` as keyof AccountTitleRow] as number;
                        const hasNextLayer: boolean =
                            nLayer + 1 < 4 ? !row[`l${nLayer + 1}` as keyof AccountTitleRow] : true;
                        return row.type !== 0 && nLayerId && nLayerId === id && hasNextLayer;
                    })
                    .map((row) => {
                        const children: AccountTitleRow[] = getSubs(row.id, nLayer + 1 /*, [...upper]*/);
                        return {
                            ...getData(row),
                            children,
                        };
                    });
            }
        };
        const mData: AccountTitleRow[] = data
            .filter((a) => a.type === 0)
            .map((a) => {
                const children = getSubs(a.id, 0);
                return {
                    ...getData(a),
                    children: [...children],
                };
            });
        setFormattedData(mData);
    }, [data]);

    useEffect(() => {
        formatData();
    }, [data, formatData]);

    const renderNode = (row: AccountTitleRow) => {
        if ("children" in row && row.children && row.children.length > 0) {
            if (selectable && !editable) {
                const allChildren = findChildren(row).filter((child) => [3, 4].indexOf(child.type) > -1);
                if (allChildren.length === 0) {
                    return <React.Fragment key={row.id}></React.Fragment>;
                }
            }
            return (
                <AccountTitleTreeItem row={row} key={row.id}>
                    {row.children.map((sub) => renderNode(sub))}
                </AccountTitleTreeItem>
            );
        } else {
            if (
                selectable &&
                !editable &&
                [0, 1, 2].indexOf(row.type) > -1 &&
                (!row.children || row.children.length === 0)
            ) {
                return <React.Fragment key={row.id}></React.Fragment>;
            }
            return <AccountTitleTreeItem row={row} key={row.id} />;
        }
    };

    const renderMap = () => formattedData.map((row) => renderNode(row));

    return (
        <Context.Provider
            value={{
                editable,
                showToolbar,
                onAdd,
                onEdit,
                onDelete,
                onI18nEdit,
                selectable,
                selectedItems: selectedItems || [],
                onItemSelected,
            }}
        >
            <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} {...props}>
                {renderMap()}
            </TreeView>
        </Context.Provider>
    );
}

export default AccountTitleTreeView;
