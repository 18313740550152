import React from "react";
import { Column } from "@material-table/core";
import { AbsenceRowData } from "./AbsenceSearchTable";
import { TFunction } from "react-i18next";
import DateRenderer from "components/grid/DateRenderer";
import { IconButton } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

export interface ColumnsDesc {
    type: string;
    formAction: (rowId: string) => void;
}

function AbsenceColumnsFactory(columnsDesc: ColumnsDesc, t: TFunction) {
    const { formAction } = columnsDesc;
    const defaultColumns: Column<AbsenceRowData>[] = [
        {
            title: t("日期"),
            field: "date",
            type: "date",
            render: ({ date }) => DateRenderer(date),
        },
        {
            title: t("上班時間"),
            field: "startWorkTime",
            type: "string",
        },
        {
            title: t("下班時間"),
            field: "endWorkTime",
            type: "string",
        },
        {
            title: t("補卡"),
            field: "supplementaryCard",
            filtering: false,
            width: "40px",
            // TODO: add condition when this is available
            render: (row: AbsenceRowData) => (
                <IconButton edge="end" aria-label={t("c.detail")} onClick={() => formAction(row.id)}>
                    <AddBoxIcon color="primary" />
                </IconButton>
            ),
        },
    ];

    return defaultColumns;
}

export default AbsenceColumnsFactory;
