import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        //color: "white",
        //textAlign: "center",
        fontFamily: [
            "Roboto",
            "Teko",
            "Noto Sans TC",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Microsoft JhengHei"',
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: false,
            },
            styleOverrides: {
                root: {
                    backgroundColor: "#006699",
                },
            },
        },
        MuiStack: {
            defaultProps: {
                spacing: 1,
                alignItems: "center",
                justifyContent: "center",
                sx: { textAlign: "center" },
            },
        },
        MuiTypography: {
            defaultProps: {
                color: "white",
            },
        },
    },
});

export default theme;
