import React, { MouseEvent, useRef, useMemo } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { FormBaseRefProps } from "components/final-form/FormBase";
import {
    RequestContent,
    RequiredFlagEnum,
    requiredFlagSettings,
    FlagErrorAlerts,
    useCheckFlags,
} from "components/audit/requestContent";
import { addToast, loadControl as LC } from "helpers";

export interface ApplicationFormDialogProps extends DialogProps {
    formName: string | null;
    onFinished?: () => void;
}

function ApplicationFormDialog({ formName, open, onClose, onFinished, ...props }: ApplicationFormDialogProps) {
    const formRef = useRef<FormBaseRefProps<any>>(null);
    const { t } = useTranslation();

    const requiredFlags = useMemo<RequiredFlagEnum[]>(
        () => (formName && formName in requiredFlagSettings ? requiredFlagSettings[formName] : []),
        [formName, requiredFlagSettings]
    );

    const checkFlags = useCheckFlags();

    const errorFlags = useMemo<RequiredFlagEnum[]>(() => {
        return checkFlags(requiredFlags);
    }, [requiredFlags]);

    const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };

    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };

    const handleSubmit = async (values: any) => {
        const mValue = values as Record<string, any>;
        LC();
        await axios
            .post("/api/form/request", { ...mValue, formName })
            .then(function (response) {
                const data = response.data;
                if ("status" in data) {
                    if (data.status === "ok") {
                        addToast(t("c.msg.saveSuccess"), { appearance: "success" });
                        onFinished && onFinished();
                        handleClose();
                    } else {
                        const extraMsg = "error" in data ? data.error : "";
                        addToast(t("c.msg.saveFailed") + extraMsg, { appearance: "error" });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                addToast(t("c.msg.severError"), { appearance: "error" });
                throw err;
            })
            .finally(() => {
                LC(false);
            });
    };

    return (
        <Dialog
            {...props}
            open={open && !!formName}
            fullWidth
            maxWidth="md"
            onClose={onClose}
            aria-labelledby="form-application-dialog-title"
        >
            <DialogTitle id="form-application-dialog-title">{formName && t(`form.title.${formName}`)}</DialogTitle>
            <DialogContent>
                {errorFlags.length > 0 && <FlagErrorAlerts errors={errorFlags} />}
                {formName && errorFlags.length === 0 && (
                    <RequestContent formName={formName} onSubmit={handleSubmit} formRef={formRef} />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" variant="contained" onClick={handleConfirm} disabled={errorFlags.length > 0}>
                    {t("c.submit")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ApplicationFormDialog;
