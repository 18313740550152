import React, { useState } from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
//import getMockedList from "components/mock/newsListMock";
import { useFormTable } from "hooks";
import AnnounceDialog from "./AnnounceDialog";

export interface NewsRowData {
    id: string;
    title: string;
    fixedTop: boolean;
    createdAt: Date;
}

export interface NewsSearchTableProps extends Partial<CustomMaterialTableProps<NewsRowData>> {
    options?: Options<NewsRowData>;
    columns: Column<NewsRowData>[];
}

function NewsSearchTable({ options = {}, columns, ...props }: NewsSearchTableProps) {
    const newsTable = useFormTable<NewsRowData>("/api/dashboard/announce/list");
    const [currentAnnounceId, setCurrentAnnounceId] = useState<string | null>(null);
    const handleDialogClose = () => {
        setCurrentAnnounceId(null);
    };
    return (
        <>
            <MaterialTable<NewsRowData>
                {...props}
                onRowClick={(event, rowData) => {
                    rowData && setCurrentAnnounceId(rowData.id);
                }}
                columns={columns}
                data={newsTable.fetch()}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: true,
                    ...options,
                }}
            />
            <AnnounceDialog open={!!currentAnnounceId} onClose={handleDialogClose} dataId={currentAnnounceId} />
        </>
    );
}

export default NewsSearchTable;
