import { useState, useRef } from "react";
import axios from "axios";
import { SchemaOf } from "yup";
import { Filter, Query, QueryResult } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { getErrorByCode } from "helpers/formHelper";
import { createSimpleValidate } from "helpers/mTableHelper";
import { addToast, isCommonResponseError, commonErrorHandler, loadControl as LC } from "helpers";
import useDataCUD from "./useDataCUD";

export interface formTableOption<RowData extends object> {
    onAdd?: (data: RowData) => RowData;
    onEdit?: (data: RowData) => RowData;
    onSaveSuccess?: (data: RowData, operation: "add" | "edit" | "delete") => void;
    allDataMode?: boolean;
}

interface processedFilter<RowData = any> {
    f?: keyof RowData | string | Array<keyof RowData | string>;
    v?: string;
}

/**
 * hook for handle CRUD method of material-table.
 *
 * @param {String} urlPath api for creating, reading and updating.
 * @param {Schema<any>} schema yup schema used for validation. @see https://github.com/jquense/yup
 * @param {formTableOption} option additional option.
 */

function useFormTable<RowData extends object>(
    urlPath: string,
    schema?: SchemaOf<any>,
    option?: formTableOption<RowData>
) {
    const mountedRef = useRef(true);
    const source = axios.CancelToken.source();
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const [data, setData] = useState<RowData[]>([]);
    const dataCUD = useDataCUD();
    const allDataMode = !!option?.allDataMode;

    const validate = !schema ? async () => {} : createSimpleValidate(schema, setErrors);

    const processFilters = (filters: Filter<RowData>[]) => {
        if (filters && filters.length > 0) {
            const res: processedFilter<RowData>[] = [];
            filters.forEach((f) => {
                res.push({
                    f: f.column.field,
                    v: typeof f.value === "string" ? f.value.trim() : f.value,
                    //op: f.operator
                });
            });
            return res;
        }
        return null;
    };

    /**
     * add method for material-table.
     * @param {Object} data data which will be add to database.
     */
    const mHandleAdd = async (data: RowData) => {
        if (option && option.onAdd) {
            data = option.onAdd(data);
        }
        return await validate(data)
            .then(async () => {
                return await dataCUD
                    .handleAdd(data, urlPath)
                    .then((res) => {
                        if (allDataMode) {
                            fetchOnly().catch(() => {});
                        }
                        option?.onSaveSuccess && option.onSaveSuccess(data, "add");
                        return res;
                    })
                    .catch((err) => {
                        throw err;
                    });
            })
            .catch((err) => {
                addToast(t("c.msg.formDataError"), { appearance: "error" });
                throw err;
            });
    };

    /**
     * edit method for material-table.
     * @param {Object} data data which will be updated and saved to database.
     */
    const mHandleEdit = async (data: RowData) => {
        if (option && option.onEdit) {
            data = option.onEdit(data);
            console.log(data);
        }
        return await validate(data)
            .then(async () => {
                return await dataCUD
                    .handleEdit(data, urlPath)
                    .then((res) => {
                        if (allDataMode) {
                            fetchOnly().catch(() => {});
                        }
                        option?.onSaveSuccess && option.onSaveSuccess(data, "edit");
                        return res;
                    })
                    .catch((err) => {
                        throw err;
                    });
            })
            .catch((err) => {
                addToast(t("c.msg.formDataError"), { appearance: "error" });
                throw err;
            });
    };

    /**
     * delete method for material-table.
     * @param {Object} data  data which will be deleted from database
     */
    const mHandleDel = async (data: RowData) => {
        return await dataCUD
            .handleDel(data, urlPath)
            .then(async () => {
                if (allDataMode) {
                    fetchOnly().catch(() => {});
                }
                option?.onSaveSuccess && option.onSaveSuccess(data, "delete");
            })
            .catch((err) => {
                throw err;
            });
    };

    /**
     * fetch data from database for material-table
     * @param {Object} params additional params of request which sent to the backend.
     * @param {Object} foreignKeyFields (deprecated param)
     */
    const mFetchData =
        (params?: Record<string, unknown>, foreignKeyFields?: Record<string, unknown>) =>
        async (query: Query<RowData>): Promise<QueryResult<RowData>> => {
            let orderBy = null;
            if (query.orderBy) {
                //handle sorted field with the foreign key
                orderBy = query.orderBy.field;
                if (foreignKeyFields && typeof orderBy === "string") {
                    const split = orderBy.split(".");
                    if (split.length === 2 && split[0] in foreignKeyFields) {
                        orderBy = foreignKeyFields[split[0]];
                    }
                }
            }
            return await axios
                .get(urlPath, {
                    params: {
                        limit: query.pageSize,
                        page: query.page + 1,
                        order: orderBy,
                        orderDir: query.orderDirection || null,
                        str: query.search || null,
                        filter: processFilters(query.filters),
                        ...params,
                    },
                    cancelToken: source.token,
                })
                .then((result) => {
                    if (!mountedRef.current) {
                        throw new Error();
                    }
                    const data = result.data;
                    if (isCommonResponseError(data)) {
                        const extraMsg = commonErrorHandler(data);
                        throw new Error(extraMsg);
                    } else {
                        //setData(data.data);
                        return {
                            data: data.data,
                            page: query.page,
                            totalCount: data.count,
                        };
                    }
                })
                .catch((err) => {
                    if (!mountedRef.current) {
                        throw new Error();
                    }
                    console.log(err);
                    addToast(t("c.msg.loadFailed"), { appearance: "error" });
                    throw err;
                });
        };

    const fetchOnly = async () => {
        LC();
        return await axios
            .get(urlPath, {
                cancelToken: source.token,
            })
            .then((result) => {
                if (!mountedRef.current) {
                    throw new Error();
                }
                const data = result.data;
                if (isCommonResponseError(data)) {
                    const extraMsg = commonErrorHandler(data);
                    throw new Error(extraMsg);
                } else {
                    setData(data.data);
                    return {
                        data: data.data,
                        page: 1,
                        totalCount: data.count,
                    };
                }
            })
            .catch((err) => {
                if (!mountedRef.current) {
                    throw new Error();
                }
                console.log(err);
                addToast(t("c.msg.loadFailed"), { appearance: "error" });
                throw err;
            })
            .finally(() => {
                LC(false);
            });
    };

    const cleanup = () => {
        mountedRef.current = false;
        source.cancel();
        LC(false);
    };

    /**
     * Clear errors.
     */
    const clearErrors = () => {
        if (Object.keys(errors).length > 0) {
            setErrors({});
        }
    };
    return {
        fetch: mFetchData,
        add: mHandleAdd,
        edit: mHandleEdit,
        del: mHandleDel,
        clearErrors: clearErrors,
        fetchOnly,
        cleanup,
        data,
        errors: errors,
        mountedRef,
        source,
    };
}
export default useFormTable;
