import React, { createContext, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TextField, makeValidate, Select } from "mui-rff";
import { Card, CardContent, Divider, Grid, Box, Typography, Button, MenuItem } from "@mui/material";
import { modeDef } from "hooks/useFormData";
import { useFetchData2 as useFetchData } from "hooks";
import FormBase, { FormBaseProps } from "components/final-form/FormBase";
import { StatusSwitch } from "components/final-form";
import PagePermissionView, { convertAuthorizeValuesToUI, PermissionRoleAuthorizeValues } from "./PagePermissionView";
import PickerDialog, { PermissionRolePickRow } from "./PermissionRolePickerDialog";
import { useForm } from "react-final-form";

export interface PermissionRoleFormValues {
    id?: number;
    name: string;
    dashboard: number | string;
    description: string;
    status: boolean;
    permissionRoleAuthorizes: PermissionRoleAuthorizeValues[];
}

interface FormContextValue {
    writable: boolean;
}

const FormContext = createContext<FormContextValue>({
    writable: false,
});
const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

export interface PermissionRoleFormBaseProps extends FormBaseProps<PermissionRoleFormValues> {
    initialValues: Partial<PermissionRoleFormValues>;
    writable?: boolean;
}

export const defaultInitialValues: Partial<PermissionRoleFormValues> = {
    name: "",
    description: "",
    dashboard: "",
    status: true,
    permissionRoleAuthorizes: [],
};

function PermissionRoleFormBase({
    initialValues = defaultInitialValues,
    onSubmit,
    formMode = modeDef.ADD,
    writable = false,
    ...props
}: PermissionRoleFormBaseProps) {
    const { t } = useTranslation();
    const schema: SchemaOf<PermissionRoleFormValues> = yup
        .object()
        .shape({
            name: yup.string().label(t("permissionRole.fd.name")).required(),
            description: yup.string().label(t("permissionRole.fd.description")),
            status: yup.bool().label(t("c.fd.status")).required(),
            dashboard: yup.number().label(t("permissionRole.fd.dashboard")).min(0).max(1).required(),
            permissionRoleAuthorizes: yup
                .array<PermissionRoleAuthorizeValues>()
                .label(t("c.detail"))
                .of(
                    yup.object().shape({
                        page: yup.string().required(),
                        read: yup.bool(),
                        write: yup.bool(),
                    }) as any
                )
                .required(),
        })
        .defined();
    const validate = makeValidate(schema);
    return (
        <FormBase<PermissionRoleFormValues>
            validate={validate}
            initialValues={initialValues}
            onSubmit={onSubmit}
            {...props}
        >
            <FormContext.Provider value={{ writable }}>
                <FormContent />
            </FormContext.Provider>
        </FormBase>
    );
}

function FormContent() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FSBasic />
            </Grid>
            <Grid item xs={12}>
                <FSDetail />
            </Grid>
        </Grid>
    );
}

function FSBasic() {
    const { t } = useTranslation();
    const { writable } = useFormContext();
    const dashboardData = t("permissionRole.dashboard", { returnObjects: true }) as Record<string, string>;
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">{t("c.fd.secBase")}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField required={true} label={t("permissionRole.fd.name")} name="name" disabled={!writable} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StatusSwitch name="status" disabled={!writable} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Select name="dashboard" label={t("permissionRole.fd.dashboard")}>
                        {Object.keys(dashboardData).map((key) => (
                            <MenuItem value={key} key={key} selected={true}>
                                {dashboardData[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <TextField label={t("permissionRole.fd.description")} name="description" disabled={!writable} />
                </Grid>
            </Grid>
            <Box p={2} />
            <Divider />
        </>
    );
}

function FSDetail() {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [applyId, setApplyId] = useState<number | null>(null);
    const form = useForm();
    const { writable } = useFormContext();
    const [applyData, applyDataFetch, applyCleanup] = useFetchData<PermissionRoleFormValues>(
        `/api/permissionRole/${applyId}`,
        t("e.permissionRole"),
        undefined,
        null
    );
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleApplyButtonClick = () => {
        setDialogOpen(true);
    };
    const handleDialogSelect = (item: PermissionRolePickRow) => {
        setApplyId(item.id);
    };

    //load data to apply
    useEffect(() => {
        if (applyId) {
            applyDataFetch();
        }
        return () => {
            applyCleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyId]);

    //after data loaded, start applying
    useEffect(() => {
        if (applyData) {
            const mAuthorizes = applyData.permissionRoleAuthorizes.map((row) => {
                return {
                    page: row.page,
                    alterValue: convertAuthorizeValuesToUI(row),
                };
            });
            form.change("permissionRoleAuthorizes", mAuthorizes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyData]);
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">{t("permissionRole.fd.authorize")}</Typography>
                </Grid>
                {writable && (
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary" onClick={handleApplyButtonClick}>
                            {t("permissionRole.applyOtherAuth")}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Box p={3}>
                                <PagePermissionView name="permissionRoleAuthorizes" disabled={!writable} />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <PickerDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                onSelect={handleDialogSelect}
                filter={id ? (row) => row.id !== Number(id) : undefined}
            />
        </>
    );
}

export default PermissionRoleFormBase;
