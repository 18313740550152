import React from "react";
import { Box, Paper, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FaPlus, FaEquals } from "react-icons/fa";
import { Select, TextField } from "mui-rff";
import moment from "moment";
import { i18nToMenuItem } from "helpers";
import { formatNumberInput } from "components/CommonNumberFormat";
import { useFormBaseContext } from "components/final-form";
import { MonthPicker } from "components/final-form/Picks";
import { useInitialFormContext } from "./InitialFormBase";

function InitialHeaderSection() {
    const { disabled, maxDate } = useInitialFormContext();
    const { formRef } = useFormBaseContext();
    const { t } = useTranslation();
    return (
        <Paper sx={{ padding: (theme) => theme.spacing(2) }} variant="outlined">
            <Stack spacing={2}>
                <Stack spacing={2} direction="row">
                    <Box>
                        <Select
                            name="consolidationMethodType"
                            label={t("accountSetting.fd.consolidationMethodType")}
                            disabled={true}
                        >
                            {i18nToMenuItem(t("accountSetting.consolidationMethodType", { returnObjects: true }))}
                        </Select>
                    </Box>
                    <Box>
                        <MonthPicker
                            name="yearMonth"
                            formRef={formRef}
                            label={t("accountConsolidation.fd.initYearMonth")}
                            required={true}
                            disabled={disabled}
                            maxDate={moment(maxDate).toDate()}
                        />
                    </Box>
                </Stack>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <TextField
                            label={t("accountTitle.settingData.pl")}
                            name="currentPl"
                            InputProps={formatNumberInput()}
                            required={true}
                            disabled={disabled}
                        />
                    </Box>
                    <Box mx={1}>
                        <FaEquals />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <TextField
                            label={t("accountConsolidation.fd.distributed")}
                            name="distributed"
                            InputProps={formatNumberInput()}
                            required={true}
                            disabled={disabled}
                        />
                    </Box>
                    <Box mx={1}>
                        <FaPlus />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <TextField
                            label={t("accountConsolidation.fd.undistributed")}
                            name="undistributed"
                            InputProps={formatNumberInput()}
                            disabled={disabled}
                        />
                    </Box>
                </Box>
            </Stack>
        </Paper>
    );
}

export default InitialHeaderSection;
