import React, { createContext, useContext, useState, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { Field, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Grid, Button, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { TimePicker } from "components/final-form";
import { FormBaseRefProps } from "components/final-form/FormBase";
import { ShiftFormValues } from "./ShiftForm";

export interface PeriodData {
    restStart: string;
    restEnd: string;
}

export interface RestPeriodData {
    period: PeriodData[];
}

export interface BasicShiftFormValues {
    employeeId: number | null;
    type: number | string;
    restPeriod: RestPeriodData;
}

interface FormContextValue {
    supervisorId: number;
}

export const FormContext = createContext<FormContextValue>({
    supervisorId: -1,
});

export const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

interface RestPeriodFieldsProps {
    name: keyof BasicShiftFormValues | string;
    formRef?: RefObject<FormBaseRefProps<ShiftFormValues>>;
    disabled?: boolean;
}

interface RestPeriodPeriodFieldsProps {
    parentName?: string;
    name: keyof RestPeriodData | string;
    formRef?: RefObject<FormBaseRefProps<ShiftFormValues>>;
    disabled?: boolean;
}

function RestPeriodFields({ name, disabled = false }: RestPeriodFieldsProps) {
    return (
        <Field name={name}>
            {(typeInputProps) => {
                return (
                    <RestPeriodPeriodFields parentName={typeInputProps.input.name} name="period" disabled={disabled} />
                );
            }}
        </Field>
    );
}

function RestPeriodPeriodFields({ parentName, name, disabled }: RestPeriodPeriodFieldsProps) {
    return <Period name={`${parentName}.${name}`} disabled={disabled} />;
}

function Period({ name, disabled }: RestPeriodPeriodFieldsProps) {
    const { t } = useTranslation();
    const form = useForm<RestPeriodData>();
    const [restPeriodCount, setRestPeriodCount] = useState<number>(0);

    return (
        <>
            <FieldArray<PeriodData> name={name}>
                {({ fields }) => {
                    setRestPeriodCount(fields.length ?? 0);
                    return (
                        <>
                            {fields.length ? (
                                fields.map((name, index) => {
                                    return (
                                        <Grid container key={index}>
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}
                                                sx={{ display: "flex" }}
                                            >
                                                <Grid item sx={{ flexGrow: 1 }}>
                                                    <TimePicker
                                                        label={t("shift.fd.restPeriod")}
                                                        name={`${name}.restStart`}
                                                        required
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                                <Grid item sx={{ flexGrow: 1 }}>
                                                    <TimePicker
                                                        label={t("shift.fd.restPeriod")}
                                                        name={`${name}.restEnd`}
                                                        disabled={disabled}
                                                        required
                                                    />
                                                </Grid>
                                                {index > 0 && !disabled && (
                                                    <>
                                                        <Grid item>
                                                            <IconButton
                                                                color="inherit"
                                                                onClick={() => {
                                                                    fields.remove(index);
                                                                    if (setRestPeriodCount)
                                                                        setRestPeriodCount((prev) => prev - 1);
                                                                }}
                                                                size="medium"
                                                            >
                                                                <CancelIcon color="error" />
                                                            </IconButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                            {index < 1 && (
                                                <Grid container direction="row" alignItems="center" spacing={1}>
                                                    <Grid item sx={{ flexGrow: 1 }}>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={() => {
                                                                form.mutators.push("restPeriod.period", undefined);
                                                                setRestPeriodCount((prev) => prev + 1);
                                                            }}
                                                            disabled={restPeriodCount >= 2 || disabled}
                                                            fullWidth
                                                        >
                                                            {restPeriodCount >= 2
                                                                ? t("shift.fd.firstRestPeriod")
                                                                : t("shift.fd.addRestPeriod")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    );
                                })
                            ) : (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={disabled}
                                    onClick={() => {
                                        form.mutators.push("restPeriod.period", undefined);
                                        setRestPeriodCount((prev) => prev + 1);
                                    }}
                                    sx={{ flexGrow: 1 }}
                                >
                                    {t("shift.fd.addRestPeriod")}
                                </Button>
                            )}
                        </>
                    );
                }}
            </FieldArray>
        </>
    );
}
export default RestPeriodFields;
