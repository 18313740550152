import axios from "axios";
// import { checkLogin } from "helpers";
const utilProcess = (response) => {
    const data = response.data;
    if ("status" in data) {
        if (data.status === "error") {
            console.error(data);
            if (data.code === 101) {
                //
            }
            //not logined
            if (data.code === 102) {
                window.location.reload();
                // eslint-disable-next-line
                throw "not_login";
            }
            //console.error(response);
        }
    }
    return response;
};
const request = (config) => {
    return axios.request(config).then(utilProcess);
};
const get = (url, config) => {
    return axios.get(url, config).then(utilProcess);
};
const _delete = (url, config) => {
    return axios.delete(url, config).then(utilProcess);
};
const head = (url, config) => {
    return axios.head(url, config).then(utilProcess);
};
const post = (url, data, config) => {
    return axios.post(url, data, config).then(utilProcess);
};
const put = (url, data, config) => {
    return axios.put(url, data, config).then(utilProcess);
};
const patch = (url, data, config) => {
    return axios.patch(url, data, config).then(utilProcess);
};
export default {
    request,
    get,
    delete: _delete,
    head,
    post,
    put,
    patch,
    CancelToken: axios.CancelToken,
};
