import React from "react";
import { Column } from "@material-table/core";
import { TFunction } from "react-i18next";
import DateRenderer from "components/grid/DateRenderer";
import { ClockRequestRowData } from "./ClockRequestSearchTable";
import WorkType from "components/employee/WorkType";
import CardReplacementStatus from "components/attendance/CardReplacementStatus";

export interface ColumnsDesc {
    type: string;
    formAction: (rowId: string) => void;
}

function ClockRequestColumnsFactory(columnsDesc: ColumnsDesc, t: TFunction) {
    function renderDirection(direction: WorkType) {
        if (direction === WorkType.GO_TO_THE_WORK) {
            return t("attendance.fd.clockIn");
        }
        return t("attendance.fd.clockIn");
    }

    const defaultColumns: Column<ClockRequestRowData>[] = [
        {
            title: t("employee.fd.employeeNo"),
            field: "employeeNo",
            type: "string",
        },
        {
            title: t("employee.fd.name"),
            field: "name",
            type: "string",
        },
        {
            title: t("c.fd.date"),
            field: "date",
            type: "date",
            render: ({ date }) => DateRenderer(date),
        },
        {
            title: t("attendance.fd.fixedClockInType"),
            field: "direction",
            type: "string",
            render: ({ direction }) => renderDirection(direction),
        },
        {
            title: t("attendance.fd.clockTime"),
            field: "checkInTime",
            type: "string",
        },
        {
            title: t("audit.fd.createdAt"),
            field: "applicationTime",
            type: "date",
            render: ({ date }) => DateRenderer(date),
        },
        {
            title: t("c.fd.status"),
            field: "status",
            type: "string",
            render: ({ status }) => <CardReplacementStatus statusId={status} />,
        },
    ];

    return defaultColumns;
}

export default ClockRequestColumnsFactory;
