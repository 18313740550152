import React from "react";
import { TFunction } from "react-i18next";
import { Column } from "@material-table/core";
import { IconButton } from "@mui/material";
import { Assignment } from "@mui/icons-material";
import AuditStatus from "components/audit/AuditStatus";
import DateRenderer from "components/grid/DateRenderer";
import AuditRowData from "components/audit/AuditRowData";
import { useEmployeeData } from "hooks";
import { createLookup } from "helpers";

export interface ColumnsDesc {
    type: string;
    formAction: (rowId: string) => void;
}

export interface LookupMaps {
    formLookupMap: Record<string, any>;
}

function ColumnsFactory(columnsDesc: ColumnsDesc, t: TFunction, map: LookupMaps) {
    const { type, formAction } = columnsDesc;
    const { formLookupMap } = map;
    const formTypeData = t("audit.formType", { returnObjects: true }) as Record<string, string>;
    const [empData] = useEmployeeData((data) => createLookup(data, "name"), {});
    const defaultColumns: Column<AuditRowData>[] = [
        {
            title: t("audit.fd.no"),
            field: "no",
            type: "string",
        },
        {
            title: t("audit.fd.user"),
            field: "employeeId",
            type: "string",
            lookup: empData,
        },
        {
            title: t("audit.fd.type"),
            field: "formType",
            type: "string",
            lookup: formTypeData,
        },
        {
            title: t("e.form"),
            field: "formId",
            type: "string",
            lookup: formLookupMap,
        },
        {
            title: t("audit.fd.createdAt"),
            field: "createdAt",
            type: "date",
            filtering: false,
            render: (row) => DateRenderer(row.createdAt),
        },
        {
            title: t("audit.fd.status"),
            field: "status",
            type: "string",
            render: (row) => <AuditStatus statusId={row.status} />,
        },
    ];

    const formActionColumn = {
        title: "",
        field: "auditActions",
        filtering: false,
        width: "40px",
        render: (row: AuditRowData) => (
            <IconButton edge="end" aria-label={t("c.detail")} onClick={() => formAction(row.id)}>
                <Assignment sx={{ color: "#FFB13A" }} />
            </IconButton>
        ),
    };

    const auditSearchColumns = [formActionColumn, ...defaultColumns];
    const filteredSearchColumns = [...defaultColumns, formActionColumn];

    // eslint-disable-next-line no-unused-vars
    const columns: { [key in string]: Column<AuditRowData>[] } = {
        filtered: filteredSearchColumns,
        main: auditSearchColumns,
    };

    return columns[type];
}

export default ColumnsFactory;
