import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "@material-table/core";
import { EnabledStatusChip } from "components";

function useEnabledStatusColumn<RowData extends object>(columnName: string, title?: string | React.ReactElement<any>) {
    const { t } = useTranslation();
    const column: Column<RowData> = {
        title: title || t("c.enabledStatus"),
        field: columnName,
        type: "boolean",
        render: (row) => {
            const value = row[columnName as keyof RowData];
            return <EnabledStatusChip value={!!value} />;
        },
    };

    return column;
}

export default useEnabledStatusColumn;
