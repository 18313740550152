import React from "react";
import { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { Radios, Select, TextField, makeValidate } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid, MenuItem } from "@mui/material";
import FormBase, { FormBaseProps, useFormBaseContext } from "components/final-form/FormBase";
import { DatePicker } from "components/final-form/Picks";
import { modeDef } from "hooks/useFormData";

export interface NewEmployeeFormValues {
    name: string;
    jpName?: string;
    enName?: string;
    employeeNo?: string;
    gender: "0" | "1" | "2";
    hireDate?: Date | Moment;
    nationality?: string;
    personId?: string;
}

export const defaultInitialValues: Partial<NewEmployeeFormValues> = {
    name: "",
    gender: "0",
};

function NewEmployeeFormBase({ onSubmit, formMode = modeDef.ADD, ...props }: FormBaseProps<NewEmployeeFormValues>) {
    const { t } = useTranslation();
    const schema: SchemaOf<NewEmployeeFormValues> = yup
        .object()
        .shape({
            name: yup.string().label(t("employee.fd.name")).trim().max(50).required(),
            jpName: yup.string().label(t("employee.fd.jpName")).trim().max(50).nullable(),
            enName: yup.string().label(t("employee.fd.enName")).trim().max(50).nullable(),
            employeeNo: yup.string().label(t("employee.fd.employeeNo")).trim().max(10).required(),
            birthday: yup.date().label(t("employee.fd.birthday")).required(),
            gender: yup
                .string()
                .label(t("employee.fd.gender"))
                .oneOf(["1", "2"], t("employee.msg.genderError"))
                .required(),
            hireDate: yup.date().nullable(),
            nationality: yup.string().label(t("employee.fd.nationality")).oneOf(["TW", "JP", "O", ""]),
            personId: yup.string().label(t("employee.fd.personId")).max(10).required(),
        })
        .defined();
    const validate = makeValidate(schema);

    return (
        <FormBase<NewEmployeeFormValues>
            validate={validate}
            initialValues={defaultInitialValues}
            onSubmit={onSubmit}
            {...props}
        >
            <FormContent />
        </FormBase>
    );
}

function FormContent() {
    const { formRef } = useFormBaseContext();
    const { t } = useTranslation();
    const genderData = t("employee.gender", { returnObjects: true }) as Record<string, string>;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    required
                    label={t("employee.fd.name")}
                    name="name"
                    inputProps={{ maxLength: 50 }}
                    autoComplete="name"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField label={t("employee.fd.enName")} name="enName" inputProps={{ maxLength: 50 }} />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField label={t("employee.fd.jpName")} name="jpName" inputProps={{ maxLength: 50 }} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="employeeNo"
                    label={t("employee.fd.employeeNo")}
                    required
                    inputProps={{ maxLength: 10 }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker label={t("employee.fd.birthday")} name="birthday" formRef={formRef} required />
            </Grid>
            <Grid item xs={12} md={6}>
                <Radios
                    radioGroupProps={{ row: true }}
                    label={t("employee.fd.gender")}
                    name="gender"
                    required
                    data={Object.keys(genderData).map((key) => ({
                        label: genderData[key as keyof typeof genderData],
                        value: key,
                    }))}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker label={t("employee.fd.hireDate")} name="hireDate" formRef={formRef} />
            </Grid>
            <Grid item xs={12} md={6}>
                <Select name="nationality" label={t("employee.fd.nationality")}>
                    <MenuItem value="">{t("c.pleaseSelect")}</MenuItem>
                    <MenuItem value="TW">{t("employee.nationality.TW")}</MenuItem>
                    <MenuItem value="JP">{t("employee.nationality.JP")}</MenuItem>
                    <MenuItem value="O">{t("employee.nationality.O")}</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12}>
                <TextField name="personId" label={t("employee.fd.personId")} required inputProps={{ maxLength: 20 }} />
            </Grid>
        </Grid>
    );
}

export default NewEmployeeFormBase;
