import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider } from "@mui/material";
import { I18nRow, I18nTable } from "components";

export interface AccountTitleI18nRow extends I18nRow {
    description?: string;
}

export interface AccountTitleI18nDialogProps extends DialogProps {
    dataId?: number | null;
    onChange?: () => void;
    editable?: boolean;
}

function AccountTitleI18nDialog({
    dataId,
    onClose,
    onChange = () => {},
    editable = true,
    ...props
}: AccountTitleI18nDialogProps) {
    const { t } = useTranslation();
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };

    return (
        <Dialog onClose={onClose} maxWidth="lg" fullWidth {...props} aria-labelledby="account-title-i18n-dialog-title">
            <DialogTitle id="account-title-i18n-dialog-title">{t("c.fd.i18n")}</DialogTitle>
            <Divider />
            <DialogContent>
                <I18nTable dataId={dataId} apiPath="/api/accountTitle/i18n" editable={editable} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AccountTitleI18nDialog;
