import React, { useRef, useState, useContext, createContext, RefObject, useEffect, createRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import nl2br from "react-nl2br";
import { TextField, makeValidate } from "mui-rff";
import moment, { Moment } from "moment";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { PageHeader2 as PageHeader } from "layout";
import { ExpendableCard, ExpandableContent, ExpendableCardRef } from "components";
import FormBase, { FormBaseRefProps } from "components/final-form/FormBase";
import {
    TextItem,
    DatePicker,
    TextDateItem,
    TextNumberItem,
    TextLookupItem,
    LookupRadios,
} from "components/final-form";
import { formatNumberInput } from "components/CommonNumberFormat";
import { useFormData, useUserPermission } from "hooks";

interface CorporateFormValue {
    name: string;
    jpName?: string;
    enName?: string;
    companyStatus?: string;
    equityStatus?: string;
    contactName?: string;
    contactEmail?: string;
    email?: string;
    representName?: string;
    site?: string;
    taxIdNumber?: string;
    logicSerialNumber?: string;
    fax?: string;
    tel?: string;
    address?: string;
    contactAddress?: string;
    businessScope?: string;
    specialShareholderRight?: string;
    specificIssuesVeto?: string;
    specialVotingRights?: string;
    lastModificationDate?: string | Date | Moment;
    registrationDate?: string | Date | Moment;
    registrationAuthority?: string;
    equityAmount?: number;
    shareValue?: number;
    totalPaidinCapital?: number;
    capitalAmount?: number;
}

interface FormContextValue {
    isEditMode: boolean;
    formRef: RefObject<FormBaseRefProps<CorporateFormValue>>;
    secRefs: RefObject<ExpendableCardRef>[];
}

const FormContext = createContext<FormContextValue>({
    isEditMode: false,
    formRef: {
        current: null,
    },
    secRefs: [],
});

const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

function CorporateForm() {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const formRef = useRef<FormBaseRefProps<CorporateFormValue>>(null);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("corporate"), [isWrite]);
    const { t } = useTranslation();
    const secRefs = useRef<RefObject<ExpendableCardRef>[]>([]);
    for (let i = 0; i < 3; i++) {
        secRefs.current[i] = secRefs.current[i] ?? createRef<ExpendableCardRef>();
    }
    const formData = useFormData<CorporateFormValue>("/api/corporate", {}, 1, (data) => {
        if (data) {
            [
                "specialVotingRights",
                "specificIssuesVeto",
                "specialShareholderRight",
                "companyStatus",
                "equityStatus",
            ].forEach((field) => {
                const mField = field as keyof typeof data;
                if (mField in data && !Number.isNaN(Number(data[mField]))) {
                    const fieldValue = data[mField] as number;
                    data[mField] = fieldValue.toString() as never;
                }
            });
            console.log(data);
            ["registrationDate", "lastModificationDate"].forEach((field) => {
                const mField = field as keyof typeof data;
                if (data[mField]) {
                    data[mField] = moment.utc(data[mField]) as never;
                }
            });
        }
    });
    const expendAllSection = () => {
        secRefs.current.forEach((refObject) => {
            refObject.current && refObject.current.setExpanded(true);
        });
    };
    const handleSubmit = async (values: CorporateFormValue) => {
        await formData
            .save(values)
            .then((resp) => {
                handleCancel();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleEdit = async () => {
        if (isEditMode) {
            formRef.current && formRef.current.onSubmit();
        } else {
            setIsEditMode(true);
        }
    };

    const handleCancel = async () => {
        formData.fetch();
        setIsEditMode(false);
    };

    useEffect(() => {
        formData.fetch();
        return () => {
            formData.cleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isEditMode) {
            expendAllSection();
        }
    }, [isEditMode]);

    const schema: SchemaOf<CorporateFormValue> = yup
        .object()
        .shape({
            name: yup.string().label(t("corporate.fd.name")).trim().required(),
            jpName: yup.string().trim().nullable(),
            enName: yup.string().trim().nullable(),
            companyStatus: yup.string().label(t("corporate.fd.companyStatus")).oneOf(["0", "1", "2"]).nullable(),
            equityStatus: yup.string().label(t("corporate.fd.equityStatus")).oneOf(["0", "1"]).nullable(),
            contactName: yup.string().label(t("corporate.fd.contactName")).required(),
            contactEmail: yup.string().label(t("corporate.fd.contactEmail")).email().required(),
            email: yup.string().label(t("corporate.fd.email")).email().nullable(),
            representName: yup.string().label(t("corporate.fd.representName")).required(),
            site: yup.string().label(t("corporate.fd.site")).url().nullable(),
            taxIdNumber: yup.string().label(t("corporate.fd.email")).matches(/\d{8}/g).required(),
            logicSerialNumber: yup.string().nullable(),
            fax: yup.string().label(t("corporate.fd.fax")).required(),
            tel: yup.string().label(t("corporate.fd.tel")).required(),
            address: yup.string().label(t("corporate.fd.address")).required(),
            contactAddress: yup.string().label(t("corporate.fd.contactAddress")).nullable(),
            businessScope: yup.string().label(t("corporate.fd.businessScope")).required(),
            specialShareholderRight: yup.string().oneOf(["0", "1", "2"]).nullable(),
            specificIssuesVeto: yup
                .string()
                .label(t("corporate.fd.specificIssuesVeto"))
                .oneOf(["0", "1", "2"])
                .nullable(),
            specialVotingRights: yup
                .string()
                .label(t("corporate.fd.specialVotingRights"))
                .oneOf(["0", "1", "2"])
                .nullable(),
            lastModificationDate: yup.date().label(t("corporate.fd.lastModificationDate")).nullable(),
            registrationDate: yup.date().label(t("corporate.fd.registrationDate")).required(),
            registrationAuthority: yup.string().nullable(),
            equityAmount: yup.number().label(t("corporate.fd.equityAmount")).integer().nullable(),
            shareValue: yup.number().label(t("corporate.fd.equityAmount")).integer().nullable(),
            totalPaidinCapital: yup.number().label(t("corporate.fd.totalPaidinCapital")).integer().required(),
            capitalAmount: yup.number().label(t("corporate.fd.capitalAmount")).integer().nullable(),
        })
        .defined();
    const validate = makeValidate(schema);

    return (
        <>
            {writable && (
                <PageHeader
                    title=""
                    rightToolView={
                        <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                            {isEditMode && (
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleCancel}
                                        startIcon={<ClearIcon />}
                                    >
                                        {t("c.cancel")}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleEdit}
                                    startIcon={isEditMode ? <SaveIcon /> : <EditIcon />}
                                >
                                    {isEditMode ? t("c.save") : t("c.edit")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
            )}
            <FormBase<CorporateFormValue>
                validate={validate}
                onSubmit={handleSubmit}
                initialValues={formData.data}
                formRef={formRef}
            >
                <FormContext.Provider value={{ isEditMode, formRef, secRefs: secRefs.current ?? secRefs.current }}>
                    <FormContent />
                </FormContext.Provider>
            </FormBase>
        </>
    );

    function FormContent() {
        const { t } = useTranslation();
        const { secRefs } = useFormContext();
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ExpendableCard title={t("corporate.fd.secBasic")} initialExpended={true} ref={secRefs[0]}>
                        <SecBasic />
                    </ExpendableCard>
                </Grid>
                <Grid item xs={12}>
                    <ExpendableCard title={t("corporate.fd.secAdvance")} ref={secRefs[1]}>
                        <SecAdvance />
                    </ExpendableCard>
                </Grid>
                <Grid item xs={12}>
                    <ExpendableCard title={t("corporate.fd.businessScope")} ref={secRefs[2]}>
                        <SecBusiness />
                    </ExpendableCard>
                </Grid>
            </Grid>
        );
    }
}

function SecBasic() {
    const { isEditMode, formRef } = useFormContext();
    const { t } = useTranslation();
    return (
        <ExpandableContent>
            {isEditMode ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            required
                            label={t("corporate.fd.name")}
                            name="name"
                            inputProps={{ maxLength: 50 }}
                            autoComplete="name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            required
                            label={t("corporate.fd.contactName")}
                            name="contactName"
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            required
                            label={t("corporate.fd.contactEmail")}
                            name="contactEmail"
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label={t("corporate.fd.email")}
                            name="email"
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            required
                            label={t("corporate.fd.representName")}
                            name="representName"
                            inputProps={{ maxLength: 20 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label={t("corporate.fd.site")}
                            name="site"
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            required
                            label={t("corporate.fd.taxIdNumber")}
                            name="taxIdNumber"
                            inputProps={{ maxLength: 8 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            required
                            type="tel"
                            label={t("corporate.fd.tel")}
                            name="tel"
                            inputProps={{ maxLength: 12 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            required
                            type="tel"
                            label={t("corporate.fd.fax")}
                            name="fax"
                            inputProps={{ maxLength: 12 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            variant="standard"
                            label={t("corporate.fd.registrationDate")}
                            name="registrationDate"
                            required
                            formRef={formRef}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            required
                            label={t("corporate.fd.address")}
                            name="address"
                            inputProps={{ maxLength: 200 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label={t("corporate.fd.contactAddress")}
                            name="contactAddress"
                            inputProps={{ maxLength: 200 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            required
                            label={t("corporate.fd.totalPaidinCapital")}
                            name="totalPaidinCapital"
                            InputProps={formatNumberInput()}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <TextItem name="name" label={t("corporate.fd.name")} />
                    <TextItem name="contactName" label={t("corporate.fd.contactName")} />
                    <TextItem name="contactEmail" label={t("corporate.fd.contactEmail")} />
                    <TextItem name="email" label={t("corporate.fd.email")} />
                    <TextItem name="representName" label={t("corporate.fd.representName")} />
                    <TextItem name="site" label={t("corporate.fd.site")} />
                    <TextItem name="taxIdNumber" label={t("corporate.fd.taxIdNumber")} />
                    <TextItem name="tel" label={t("corporate.fd.tel")} />
                    <TextItem name="fax" label={t("corporate.fd.fax")} />
                    <TextItem name="address" label={t("corporate.fd.address")} />
                    <TextItem name="contactAddress" label={t("corporate.fd.contactAddress")} />
                    <TextNumberItem name="totalPaidinCapital" label={t("corporate.fd.totalPaidinCapital")} />
                    <TextDateItem name="registrationDate" label={t("corporate.fd.registrationDate")} />
                </>
            )}
        </ExpandableContent>
    );
}

function SecAdvance() {
    const { isEditMode, formRef } = useFormContext();
    const { t } = useTranslation();
    const boolData = t("corporate.bool", { returnObjects: true }) as Record<string, string>;
    const companyStatusData = t("corporate.companyStatus", { returnObjects: true }) as Record<string, string>;
    const equityStatusData = t("corporate.equityStatus", { returnObjects: true }) as Record<string, string>;
    return (
        <ExpandableContent>
            {isEditMode ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label={t("corporate.fd.enName")}
                            name="enName"
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label={t("corporate.fd.jpName")}
                            name="jpName"
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            type="tel"
                            label={t("corporate.fd.logicSerialNumber")}
                            name="logicSerialNumber"
                            inputProps={{ maxLength: 20 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LookupRadios
                            label={t("corporate.fd.companyStatus")}
                            name="companyStatus"
                            lookup={companyStatusData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LookupRadios
                            label={t("corporate.fd.equityStatus")}
                            name="equityStatus"
                            lookup={equityStatusData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LookupRadios
                            label={t("corporate.fd.specialShareholderRight")}
                            name="specialShareholderRight"
                            lookup={boolData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LookupRadios
                            label={t("corporate.fd.specificIssuesVeto")}
                            name="specificIssuesVeto"
                            lookup={boolData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LookupRadios
                            label={t("corporate.fd.specialVotingRights")}
                            name="specialVotingRights"
                            lookup={boolData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            variant="standard"
                            label={t("corporate.fd.lastModificationDate")}
                            name="lastModificationDate"
                            formRef={formRef}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label={t("corporate.fd.registrationAuthority")}
                            name="registrationAuthority"
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label={t("corporate.fd.shareValue")}
                            name="shareValue"
                            InputProps={formatNumberInput()}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label={t("corporate.fd.capitalAmount")}
                            name="capitalAmount"
                            InputProps={formatNumberInput()}
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <TextItem name="enName" label={t("corporate.fd.enName")} />
                    <TextItem name="jpName" label={t("corporate.fd.jpName")} />
                    <TextLookupItem
                        name="companyStatus"
                        label={t("corporate.fd.companyStatus")}
                        lookup={companyStatusData}
                    />
                    <TextLookupItem
                        name="equityStatus"
                        label={t("corporate.fd.equityStatus")}
                        lookup={equityStatusData}
                    />
                    <TextLookupItem
                        name="specialShareholderRight"
                        label={t("corporate.fd.specialShareholderRight")}
                        lookup={boolData}
                    />
                    <TextLookupItem
                        name="specificIssuesVeto"
                        label={t("corporate.fd.specificIssuesVeto")}
                        lookup={boolData}
                    />
                    <TextLookupItem
                        name="specialVotingRights"
                        label={t("corporate.fd.specialVotingRights")}
                        lookup={boolData}
                    />
                    <TextDateItem name="lastModificationDate" label={t("corporate.fd.lastModificationDate")} />
                    <TextItem name="registrationAuthority" label={t("corporate.fd.registrationAuthority")} />
                    <TextNumberItem name="shareValue" label={t("corporate.fd.shareValue")} />
                    <TextNumberItem name="capitalAmount" label={t("corporate.fd.capitalAmount")} />
                </>
            )}
        </ExpandableContent>
    );
}

function SecBusiness() {
    const { isEditMode } = useFormContext();
    const { t } = useTranslation();
    return (
        <ExpandableContent>
            {isEditMode ? (
                <TextField
                    variant="standard"
                    required
                    name="businessScope"
                    label={t("corporate.fd.businessScope")}
                    multiline
                    minRows={4}
                    inputProps={{ maxLength: 512 }}
                />
            ) : (
                <>
                    <TextItem name="businessScope" render={(value) => (value ? nl2br(value) : t("c.na"))} />
                </>
            )}
        </ExpandableContent>
    );
}

export default CorporateForm;
