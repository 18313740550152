import React from "react";
import AccountingBlock, { AccountingBlockProps } from "./AccountingBlock";
import { SvgIcon } from "@mui/material";
import bg from "./svg/orange-account.svg";
import { ReactComponent as ArrowIcon } from "./svg/arrow-fall.svg";
import { useTranslation } from "react-i18next";

//AP = Accounts Payable
export type ARGraphProps = Pick<AccountingBlockProps, "amount">;

function ARGraph({ amount }: ARGraphProps) {
    const { t } = useTranslation();
    return (
        <AccountingBlock
            amount={amount}
            title={t("accountTitle.settingData.ap")}
            background={bg}
            color={"#754700"}
            icon={<SvgIcon component={ArrowIcon} viewBox="0 0 20 12" />}
        />
    );
}

export default ARGraph;
