import React, { useRef, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from "@mui/material";
import { useParams } from "react-router-dom";
import { FormBaseRefProps } from "components/final-form/FormBase";
import { useEmployeeDataReloadFlag, useFormData } from "hooks";
import PositionDialogBase, { PositionFormValues } from "./PositionDialogBase";
export interface NewEmployeeDialog extends DialogProps {
    onChange?: () => void;
}

function PositionDialog({ onChange, onClose, ...props }: NewEmployeeDialog) {
    const { id } = useParams<{ id: string }>();
    const formRef = useRef<FormBaseRefProps<PositionFormValues>>(null);
    const { t } = useTranslation();
    const formData = useFormData<PositionFormValues>("/api/employee/department/" + id, {}, null);
    const { addFlag } = useEmployeeDataReloadFlag();
    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };

    const handleSubmit = async (values: PositionFormValues) => {
        await formData
            .save(values)
            .then(async (resp) => {
                onChange && onChange();
                handleClose();
                addFlag();
            })
            .catch(() => {
                console.log("handleSubmit catch");
            });
    };

    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    return (
        <Dialog {...props} aria-labelledby="employee-dialog-title" maxWidth="xs" fullWidth>
            <DialogTitle id="user-dialog-title">{t("c.add")}</DialogTitle>
            <DialogContent>
                <PositionDialogBase onSubmit={handleSubmit} formRef={formRef} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PositionDialog;
