import { ResponseHandler } from "common";
import i18n from "i18next";
import addToast from "./addToast";
import { getErrorByCode } from "./formHelper";

function isCommonResponseError(data: ResponseHandler) {
    return "status" in data && data.status === "error";
}

function commonErrorHandler(data: ResponseHandler) {
    if (isCommonResponseError(data) && data.code) {
        if (Number(data.code) === 102) {
            //user not login
            window.location.reload();
        }
        const extraMsg = getErrorByCode(data);
        addToast(i18n.t("c.msg.loadFailed") + extraMsg, { appearance: "error" });
        return extraMsg;
    }
}

export { isCommonResponseError, commonErrorHandler };
