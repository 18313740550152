import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import { EmployeePickRow, useEmployeeData, useFetchData2 as useFetchData } from "hooks";
import { BasicApprovalFormValues } from "./BasicApprovalFormBase";
import { ResponseHandler } from "common";

export interface BasicApprovalPreviewProps {
    dataId: null | number;
}

export interface StepRowData {
    step: number;
    singerId: number;
    label: string;
    employee: EmployeePickRow;
}

function BasicApprovalPreview({ dataId }: BasicApprovalPreviewProps) {
    const { t } = useTranslation();
    const [approvalData, approvalDataFetch, approvalDataCleanup] = useFetchData<BasicApprovalFormValues>(
        `/api/employee/flow/${dataId}`
    );
    const [supervisorIdData, SupervisorIdDataFetch, fetchSupervisorCleanup] = useFetchData<ResponseHandler<number>>(
        `/api/employee/supervisor/${dataId}`,
        t("department.fd.supervisor")
    );
    const supervisorId = supervisorIdData?.result || -1;
    const [employees] = useEmployeeData<EmployeePickRow[]>();
    console.log(approvalData);
    useEffect(() => {
        //setIsSupervisorLoad(false);
        approvalDataFetch().catch((error) => {
            console.log(error);
        });
        SupervisorIdDataFetch().catch((error) => {
            console.log(error);
        });
        return () => {
            approvalDataCleanup();
            fetchSupervisorCleanup();
        };
    }, [dataId]);

    const stepsData = useMemo<StepRowData[]>(() => {
        const employee = employees.find((row) => row.id === dataId);
        const stepsResult: StepRowData[] = [];
        const stepNames = t("c.orders", { returnObjects: true }) as Record<string, string>;
        //const label = t("approval.fd.signerWithStep", { step: stepNames[(index + 1).toString()] });
        if (dataId && employee && approvalData) {
            const type = Number(approvalData.type) || 0;
            stepsResult.push({
                step: 0,
                singerId: dataId,
                label: t("audit.fd.user"),
                employee: employee,
            });
            if ([1, 2].indexOf(type) > -1 && supervisorId > 0) {
                const supervisor = employees.find((row) => row.id === supervisorId);
                console.log(supervisor);
                if (supervisor) {
                    stepsResult.push({
                        step: 1,
                        singerId: supervisorId,
                        label: `${t("approval.fd.signerWithStep", { step: stepNames["1"] })}(${t(
                            "department.fd.supervisor"
                        )})`,
                        employee: supervisor,
                    });
                }
            }
            if ([2, 3].indexOf(type) > -1) {
                approvalData.steps.forEach((stepRow, index) => {
                    if (type === 2 && index === 0) {
                        return;
                    }
                    const signer = employees.find((row) => row.id === stepRow.signerId);
                    if (stepRow.signerId && signer) {
                        stepsResult.push({
                            step: stepRow.step,
                            singerId: stepRow.signerId,
                            label: t("approval.fd.signerWithStep", {
                                step: stepNames[stepRow.step.toString()],
                            }),
                            employee: signer,
                        });
                    }
                });
            }
        }
        return stepsResult;
    }, [dataId, supervisorId, employees, approvalData]);

    if (!approvalData) {
        return <></>;
    }
    return (
        <Timeline position="alternate">
            {stepsData.map((row, index) => (
                <ApprovalUnit key={index} data={row} last={index === stepsData.length - 1} />
            ))}
        </Timeline>
    );
}

interface ApprovalUnitProps {
    data: StepRowData;
    last?: boolean;
}

function ApprovalUnit({ data, last = false }: ApprovalUnitProps) {
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot color="primary" />
                {!last && <TimelineConnector sx={{ bgcolor: "primary.main", width: "3px" }} />}
            </TimelineSeparator>
            <TimelineContent>
                <Typography variant="h6" component="span">
                    {data.employee.name}
                </Typography>
                <Typography color="text.secondary">{data.label}</Typography>
            </TimelineContent>
        </TimelineItem>
    );
}

export default BasicApprovalPreview;
