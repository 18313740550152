import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import { Route, Switch, Link } from "react-router-dom";
import { BasicList, BasicLeaveTypeForm } from "./basic";
import { TabPageProps } from "layout/types";
import { AnnualLeavePage } from "./annual";
import { InitialLeavePage } from "./initial";

function LeaveTypeForm({ match }: TabPageProps) {
    const { t } = useTranslation();
    const { path } = match;
    const hash = document.location.hash;
    const pagesNames = ["basic", "annual", "holiday", "adjustment", "initial"];
    const hushPathSplit = hash.split("/");
    const innerPath =
        hushPathSplit.length >= 3 && pagesNames.indexOf(hushPathSplit[2]) > -1 ? hushPathSplit[2] : "basic";
    return (
        <Box mx={-5}>
            <Tabs
                value={innerPath}
                //onChange={handleTabsChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
            >
                <Tab value="basic" label={t("leaveType.tab.basicLeave")} component={Link} to={`${path}`} />
                <Tab value="annual" label={t("leaveType.tab.annualLeave")} component={Link} to={`${path}/annual`} />
                <Tab value="holiday" label={t("leaveType.tab.vacation")} disabled={true} />
                <Tab value="adjustment" label={t("leaveType.tab.adjustment")} disabled={true} />
                <Tab value="initial" label={t("休假初始設定")} component={Link} to={`${path}/initial`} />
            </Tabs>
            <Divider />
            <Box mx={5} mt={2}>
                <Switch>
                    <Route exact path={`${path}`} component={BasicList} />
                    <Route path={`${path}/basic/add`} component={BasicLeaveTypeForm} />
                    <Route path={`${path}/basic/:id`} component={BasicLeaveTypeForm} />
                    <Route path={`${path}/annual`} component={AnnualLeavePage} />
                    <Route path={`${path}/initial`} component={InitialLeavePage} />
                    {/* <Route path={`${path}`} component={BasicList} /> */}
                </Switch>
            </Box>
        </Box>
    );
}

export default LeaveTypeForm;
