const basePath = "absence";
// Absence tabs' names which can be used for external routing
const AbsenceTabs = {
    main: "",
    leaveSearch: "leaveSearch",
    card: "card",
    leaveDetails: "leaveDetails",

    getPath: function (path: string) {
        return `${basePath}/${path}`;
    },
};

export default AbsenceTabs;
