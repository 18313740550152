import React, { useRef, useContext, createContext, RefObject, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { Field } from "react-final-form";
import { Select, TextField, makeValidate } from "mui-rff";
import moment from "moment";
import * as yup from "yup";
import { DateSchema, SchemaOf } from "yup";
import { Grid, Button, MenuItem, Card, Box, CardContent, CardHeader, Divider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { PageHeader2 as PageHeader } from "layout";
import ToggleButtons from "components/buttons/ToggleButtons";
import FormBase, { FormBaseRefProps } from "components/final-form/FormBase";
import { StatusSwitch } from "components/final-form";
import { useFormData, useUserPermission } from "hooks";
import { modeDef } from "hooks/useFormData";
import { dateFormat, getUser } from "helpers";
import { addDataKey } from "helpers/formHelper";
import { removeSeconds } from "helpers/dateHelper";
import ShiftTransferListField from "./ShiftTransferListField";
import SecMember from "./SecMember";
import SecFlexibility from "./SecFlexibility";
import SecTime from "./SecTime";
import shiftFormCalculator from "./shiftFormCalculator";

export interface PeriodData {
    restStart?: string;
    restEnd?: string;
}

export interface RestPeriodData {
    period: (PeriodData | null)[];
}
export interface ShiftFormValues {
    id?: string;
    name: string;
    shiftType: number | string;
    customCalendarId: number;
    clockIn: string | moment.MomentInput;
    clockOut: string | moment.MomentInput;
    restPeriod: RestPeriodData;
    restEnd?: string;
    restStart?: string;
    overtimeStart: string | moment.MomentInput;
    overtimeEnd: string | moment.MomentInput;
    allowClockTime: string;
    flexibilityType: number | string;
    flexTime1: number;
    flexTime2: number;
    remark: string;
    status: number | boolean;
    createdUser: number;
    updatedUser: number;
    createdAt: string;
    updatedAt: string;
    leaveType: number[];
    employee: number[];
    timeValidWarning?: string[];
    allowClockTimeValidation?: AllowClockTimeValidation;
}

export interface AllowClockTimeValidation {
    isValid: boolean;
    maxTime?: moment.MomentInput;
}

export const initialValues: Partial<ShiftFormValues> = {
    status: true,
    shiftType: "0",
    flexibilityType: "0",
    customCalendarId: 0,
    leaveType: [],
    flexTime1: 30,
    flexTime2: 30,
    restPeriod: {
        period: [{}],
    },
};

interface FormContextValue {
    isEditMode: boolean;
    editable: boolean;
    formRef: RefObject<FormBaseRefProps<ShiftFormValues>>;
    id?: string;
}

const FormContext = createContext<FormContextValue>({
    isEditMode: false,
    editable: true,
    formRef: {
        current: null,
    },
});

export const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

const removeSecondsTransform = (value: any) =>
    value !== null && value !== undefined ? removeSeconds(moment(value)).toDate() : value;

function ShiftForm() {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const history = useHistory();
    const formRef = useRef<FormBaseRefProps<ShiftFormValues>>(null);
    const timeLT: string = "HH:mm";
    const timeLTS: string = "HH:mm:ss";
    const timeKeysLT: string[] = ["restStart", "restEnd"];
    const timeKeysLTS: string[] = ["clockIn", "clockOut", "overtimeEnd", "overtimeStart", "allowClockTime"];
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("shiftSetting"), [isWrite]);
    const user = getUser();
    const formData = useFormData<ShiftFormValues>("/api/shift", initialValues, id, (data) => {
        if (data) {
            timeKeysLTS.forEach((field) => {
                const mField = field as keyof typeof data;
                if (data[mField]) {
                    data[mField] = moment(data[mField] as moment.MomentInput, timeLTS) as never;
                }
            });

            data["restPeriod"]["period"].forEach((item, index) => {
                timeKeysLT.forEach((field) => {
                    const mField = field as keyof typeof item;
                    if (item && item[mField]) {
                        item[mField] = moment(item[mField] as moment.MomentInput, timeLT) as never;
                    }
                });
            });

            data.shiftType = data.shiftType.toString();
            data.status = !!data.status;
            data.flexibilityType = data.flexibilityType.toString();
        }
    });

    const handleSubmit = async (values: ShiftFormValues) => {
        addDataKey(values, "remark");
        timeKeysLTS.forEach((field) => {
            const mField = field as keyof typeof values;
            if (values[mField]) {
                (values[mField] as string) = dateFormat(values[mField] as moment.MomentInput, timeLTS);
            }
        });

        const periodValue: PeriodData[] = [];
        values["restPeriod"]["period"].forEach((item) => {
            let value: PeriodData = {};
            timeKeysLT.forEach((field) => {
                const mField = field as keyof typeof item;
                if (item && item[mField]) {
                    value = { ...value, [field]: dateFormat(item[mField] as moment.MomentInput, timeLT) };
                }
            });
            periodValue.push(value);
        });
        const mValues = {
            ...values,
            shiftType: Number(values.shiftType),
            status: Number(values.status),
            flexibilityType: Number(values.flexibilityType),
            restPeriod: { period: periodValue },
        };
        await formData
            .save(mValues)
            .then((resp) => {
                handleCancel();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleSave = () => {
        formRef.current && formRef.current.onSubmit();
    };

    const handleCancel = async () => {
        goBack();
    };
    const goBack = () => {
        history.replace({ pathname: "/shift" }); //
    };

    const fetchData = useCallback(async () => {
        await formData
            .fetch()
            //.then(() => {})
            .catch((err) => {
                if (!formData.isCancel) {
                    goBack(); //if data not found
                    console.log(err);
                }
            });
    }, [id]);

    useEffect(() => {
        fetchData();
        return () => {
            formData.cleanup();
        };
    }, [fetchData, id]);

    const schema: SchemaOf<ShiftFormValues> = yup
        .object()
        .shape({
            name: yup.string().label(t("shift.fd.name")).trim().max(50).required(),
            allowClockTime: yup
                .date()
                .label(t("shift.fd.allowClockTime"))
                .transform(removeSecondsTransform)
                .when("shiftType", (type: string, schema: DateSchema) =>
                    Number(type) === 3 || Number(type) === 4
                        ? schema.nullable()
                        : schema
                              .required()
                              .when("allowClockTimeValidation", (value: any, schema: DateSchema) =>
                                  value
                                      ? schema.max(
                                            moment(value.maxTime).toDate(),
                                            t("shift.err.allowClockTimeAfterClockIn")
                                        )
                                      : schema
                              )
                ),
            shiftType: yup
                .number()
                .label(t("shift.fd.shiftType"))
                .min(0)
                .max(user.company.vip ? 4 : 3)
                .required(),
            clockIn: yup
                .date()
                .label(t("shift.fd.clockIn"))
                .transform(removeSecondsTransform)
                .when("clockOut", (value: any, schema: DateSchema) =>
                    value ? schema.max(moment(value).toDate(), t("shift.err.clockInMax")) : schema
                )
                .required(),
            clockOut: yup.date().label(t("shift.fd.clockOut")).required(),
            overtimeStart: yup
                .date()
                .label(t("shift.fd.overtimeStart"))
                .transform(removeSecondsTransform)
                .when("overtimeEnd", (value: any, schema: DateSchema) =>
                    value ? schema.max(moment(value).millisecond(0).toDate(), t("shift.err.overtimeMax")) : schema
                )
                .when("clockOut", (value: any, schema: DateSchema) =>
                    value
                        ? schema.min(moment(value).millisecond(0).toDate(), t("shift.err.overtimeBeforeClockOut"))
                        : schema
                )
                .required(),
            overtimeEnd: yup.date().label(t("shift.fd.overtimeEnd")).required(),
            restPeriod: yup
                .object()
                .shape({
                    period: yup.array().of(
                        yup
                            .object()
                            .shape({
                                restStart: yup
                                    .date()
                                    .label(t("shift.fd.restPeriod"))
                                    .transform(removeSecondsTransform)
                                    .when("restEnd", (value: any, schema: DateSchema) =>
                                        value ? schema.max(moment(value).toDate(), t("shift.err.restTimeMax")) : schema
                                    )
                                    .required(),
                                restEnd: yup.date().label(t("shift.fd.restPeriod")).required(),
                            })
                            .defined()
                    ),
                })
                .defined(),
            timeValidWarning: yup.array().max(0),
        })
        .defined();

    const validate = makeValidate(schema);

    const mShiftFormCalculator = useMemo(() => shiftFormCalculator, []);

    return (
        <>
            <PageHeader
                title=""
                rightToolView={
                    <Grid container spacing={2} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <Button component={NavLink} to={"/shift"} startIcon={<ArrowBackIcon />}>
                                {t("c.backpage")}
                            </Button>
                        </Grid>
                        {writable && (
                            <Grid item>
                                <Button color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
                                    {t("c.save")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
            />
            <FormBase<ShiftFormValues>
                validate={validate}
                onSubmit={handleSubmit}
                initialValues={formData.data}
                formRef={formRef}
                decorators={[mShiftFormCalculator]}
            >
                <FormContext.Provider
                    value={{ isEditMode: formData.mode === modeDef.EDIT, formRef, editable: writable, id }}
                >
                    <FormContent />
                </FormContext.Provider>
            </FormBase>
        </>
    );
}

function FormContent() {
    const { editable } = useFormContext();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <SecBasic />
            </Grid>
            <Grid item xs={12} md={6}>
                <ShiftTypeFieldWrapper component={SecTime} />
            </Grid>
            <Grid item xs={12} md={6}>
                <ShiftTypeFieldWrapper component={SecFlexibility} />
            </Grid>
            <Grid item xs={12} md={6}>
                <SecLeaveType />
            </Grid>
            <Grid item xs={12} md={6}>
                <SecMember disabled={!editable} />
            </Grid>
        </Grid>
    );
}

export interface WrappedComponentProps {
    shiftType: number;
    disabled: boolean;
}

interface ShiftTypeFieldWrapperProps {
    component: React.ComponentType<WrappedComponentProps>;
}
function ShiftTypeFieldWrapper({ component }: ShiftTypeFieldWrapperProps) {
    const Component = component;
    const { editable } = useFormContext();
    return (
        <Field name="shiftType">
            {({ input }) => {
                const value = input.value;
                return (
                    <>
                        <Component shiftType={Number(value)} disabled={!editable} />
                    </>
                );
            }}
        </Field>
    );
}

function SecBasic() {
    const { t } = useTranslation();
    const user = getUser();
    const shiftTypesData = t("shift.shiftTypes", { returnObjects: true }) as Record<string, string>;
    if (!user.company.vip) {
        delete shiftTypesData["4"];
    }
    const typeData = t("shift.customCalendarTypes", { returnObjects: true }) as Record<string, string>;
    const { editable } = useFormContext();
    return (
        <>
            <Card>
                <CardHeader title={t("c.fd.secBase")} titleTypographyProps={{ variant: "h6" }} />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <StatusSwitch name="status" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required={true}
                                label={t("shift.fd.name")}
                                name="name"
                                fullWidth
                                disabled={!editable}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={!editable}
                                label={t("shift.fd.holiday")}
                                name="holiday"
                                value={t("shift.fd.holidayType")}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box component="div" sx={{ fontSize: 14, mt: 2 }}>
                                {t("shift.fd.shiftType")}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ToggleButtons items={shiftTypesData} name="shiftType" disabled={!editable} />
                        </Grid>
                        <Grid item xs={12}>
                            <Select name="customCalendarId" label={t("shift.fd.customCalendar")} disabled={!editable}>
                                {Object.keys(typeData).map((a, i) => {
                                    return (
                                        <MenuItem key={i} value={Number(a)}>
                                            {typeData[a]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editable} label={t("c.fd.rmk")} name="remark" fullWidth />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}

function SecLeaveType() {
    //const { isEditMode } = useFormContext();
    const { t } = useTranslation();
    return (
        <Card>
            <CardHeader title={t("shift.fd.leaveType")} titleTypographyProps={{ variant: "h6" }} />
            <Divider />
            <CardContent>
                <Box p={2}>
                    <ShiftTransferListField name="leaveType" />
                </Box>
            </CardContent>
        </Card>
    );
}

export default ShiftForm;
