import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, useForm } from "react-final-form";
import { Card, CardContent, CardHeader, Divider, Button, Chip } from "@mui/material";
import { useFetchData2 as useFetchData, useEmployeeData } from "hooks";
import { createLookup } from "helpers";
import { EmployeeMultiSelectorDialog } from "pages/employee";
import { useFormContext } from "./ShiftForm";

export interface SecMemberProps {
    name?: string;
    disabled?: boolean;
}

function SecMember({ name = "employee", disabled = false }: SecMemberProps) {
    const { t } = useTranslation();
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
    const form = useForm();
    const { id } = useFormContext();
    const [employeeLookup] = useEmployeeData((data) => createLookup(data, "name"), {});
    const [usedEmployeeList, usedEmployeeListFetch, usedEmployeeListCleanup] = useFetchData<number[]>(
        `/api/shift/set${id ? "?id=" + id : ""}`,
        undefined,
        undefined,
        []
    );
    const handleSelectorConfirm = (selectItems: number[]) => {
        const formState = form.getState();
        const values = formState.values;
        if (values[name] && Array.isArray(values[name]) && selectItems.length > 0) {
            const fieldValue: number[] = values[name];
            selectItems.forEach((item) => {
                if (fieldValue.indexOf(item) === -1) {
                    fieldValue.push(item);
                }
            });
            form.change(name, [...fieldValue]);
        }
        //
    };
    const handleDelete = (id: number) => {
        const formState = form.getState();
        const values = formState.values;
        if (values[name] && Array.isArray(values[name])) {
            const fieldValue: number[] = values[name];
            const index = fieldValue.indexOf(id);
            if (index > -1) {
                fieldValue.splice(index, 1);
                form.change(name, [...fieldValue]);
            }
        }
    };
    const handleItemAdd = () => {
        setAddDialogOpen(true);
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
    };

    const mUsedEmployeeList: number[] = usedEmployeeList || [];

    useEffect(() => {
        usedEmployeeListFetch();
        return () => {
            usedEmployeeListCleanup();
        };
    }, [id]);

    return (
        <>
            <Card>
                <CardHeader
                    title={t("shift.fd.member")}
                    titleTypographyProps={{ variant: "h6" }}
                    action={
                        !disabled ? (
                            <Button variant="text" color="primary" onClick={handleItemAdd} size="large">
                                {t("shift.fd.addMember")}
                            </Button>
                        ) : undefined
                    }
                />
                <Divider />
                <CardContent>
                    <Field name={name}>
                        {({ input }) => {
                            let chips: JSX.Element[] = [];
                            const value = input.value;
                            if (!value || !Array.isArray(value)) {
                                form.change(name, []);
                            } else {
                                chips = value.map((item) => {
                                    return (
                                        <Chip
                                            key={item}
                                            label={employeeLookup[item as keyof typeof employeeLookup]}
                                            color="primary"
                                            size="small"
                                            onDelete={
                                                !disabled
                                                    ? () => {
                                                          handleDelete(item);
                                                      }
                                                    : undefined
                                            }
                                            sx={{ m: "6px", px: "6px", py: "2px" }}
                                        />
                                    );
                                });
                            }
                            return (
                                <>
                                    {chips}
                                    <EmployeeMultiSelectorDialog
                                        open={addDialogOpen}
                                        onClose={handleAddDialogClose}
                                        onConfirm={handleSelectorConfirm}
                                        existedItems={[...value, ...mUsedEmployeeList] || []}
                                        title={t("shift.fd.addMember")}
                                    />
                                </>
                            );
                        }}
                    </Field>
                </CardContent>
            </Card>
        </>
    );
}

export default SecMember;
