import React from "react";
import { useTranslation } from "react-i18next";
import { TimestampRowData, useFormTable, useEnabledStatusColumn } from "hooks";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { Column } from "@material-table/core";

export interface LeaveRowData extends TimestampRowData {
    id: number;
    name: string; //假別名稱
    description?: string; //說明
    assignHours: number; //系統時數
    percentage: number; //給薪比例
    status?: boolean; //給薪比例
    type?: number;
}

function BasicLeaveTable({
    options = {},
    tableRef,
    ...props
}: Omit<CustomMaterialTableProps<LeaveRowData>, "columns" | "data">) {
    const formTable = useFormTable<LeaveRowData>("/api/leaveType/");
    const enabledStatusColumn = useEnabledStatusColumn<LeaveRowData>("status");
    const { t } = useTranslation();
    const columns: Column<LeaveRowData>[] = [
        {
            title: "#",
            field: "id",
            type: "string",
        },
        {
            title: t("leaveType.fd.name"),
            field: "name",
            type: "string",
        },
        {
            title: t("leaveType.fd.description"),
            field: "description",
            type: "string",
        },
        {
            title: t("leaveType.fd.systemHours"),
            field: "assignHours",
            type: "string",
            render: (row) => {
                if (row.assignHours === 0) {
                    return t("leaveType.units.unlimited");
                } else {
                    return row.assignHours + t("c.units.h");
                }
            },
        },
        {
            title: t("leaveType.fd.percentage"),
            field: "percentage",
            type: "string",
            render: (row) => {
                if (row) {
                    return row.percentage + "%";
                }
                return "";
            },
        },
        enabledStatusColumn,
    ];
    return (
        <MaterialTable<LeaveRowData>
            {...props}
            tableRef={tableRef}
            columns={columns}
            data={formTable.fetch()}
            options={{
                pageSizeOptions: [5, 10, 20],
                toolbar: false,
                sorting: true,
                ...options,
            }}
        />
    );
}

export default BasicLeaveTable;
