import { getUser } from "helpers";
import RequiredFlagEnum from "./RequiredFlagEnum";

function useCheckFlags() {
    const user = getUser();
    const check = (flags: RequiredFlagEnum[]): RequiredFlagEnum[] => {
        const checkResult: RequiredFlagEnum[] = [];
        if (
            flags.indexOf(RequiredFlagEnum.ShiftRequired) > -1 &&
            (!user.employee?.shifts || user.employee.shifts.length === 0)
        ) {
            checkResult.push(RequiredFlagEnum.ShiftRequired);
        }
        return checkResult;
    };

    return check;
}

export default useCheckFlags;
