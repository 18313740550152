import React, { useMemo } from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import AuditStatusEnum from "components/audit/AuditStatusEnum";
import AuditRowData from "components/audit/AuditRowData";

// @deprecated change to AuditRowData
export interface FormRequestRowData {
    id: string;
    formType?: number;
    type?: number;
    applicationDate?: Date;
    status: AuditStatusEnum;
}

export interface FormRequestSearchTableProps extends Partial<CustomMaterialTableProps<AuditRowData>> {
    options?: Options<AuditRowData>;
    columns: Column<AuditRowData>[];
    statusFilter?: AuditStatusEnum;
    header?: React.ReactNode;
    data?: AuditRowData[];
}

function FormRequestSearchTable({
    options = {},
    columns,
    header,
    statusFilter,
    data = [],
    ...props
}: FormRequestSearchTableProps) {
    const mData = useMemo(() => {
        if (statusFilter === null || statusFilter === undefined) {
            return data;
        }
        return data.filter(({ status }) => status === statusFilter);
    }, [data, statusFilter]);
    return (
        <>
            {header && header}
            <MaterialTable<AuditRowData>
                {...props}
                columns={columns}
                data={mData}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: false,
                    ...options,
                }}
            />
        </>
    );
}

export default FormRequestSearchTable;
