import React from "react";
import { useTranslation } from "react-i18next";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { useFormTable, TimestampRowData } from "hooks";
import { AccountTitleRow } from "../accountTitle";

export interface BankAccountRowData extends TimestampRowData {
    id: string;
    type: number;
    currency: string;
    bankCode: string;
    accountNumber: string;
    accountName: string;
    accountTitleId: number;
    remark: string;
    accountTitle?: AccountTitleRow;
    createdAt?: string;
    updatedAt?: string;
}

export interface BankAccountTableProps extends Partial<CustomMaterialTableProps<BankAccountRowData>> {
    options?: Options<BankAccountRowData>;
}

function BankAccountTable({ options = {}, ...props }: BankAccountTableProps) {
    const { t } = useTranslation();
    const formTable = useFormTable<BankAccountRowData>("/api/bankAccount");
    const columns: Column<BankAccountRowData>[] = [
        {
            title: "#",
            field: "id",
            type: "numeric",
            editable: "never",
            width: 80,
            filtering: false,
        },
        {
            title: t("c.fd.type"),
            field: "type",
            lookup: t("bankAccount.type", { returnObjects: true }),
        },
        {
            title: t("accountTitle.fd.code"),
            field: "accountTitle.code",
        },
        {
            title: t("accountTitle.fd.name"),
            field: "accountTitle.name",
        },
        {
            title: t("bankAccount.fd.accountNumber"),
            field: "accountNumber",
            type: "string",
            render: (rowData) => (rowData.type === 0 ? `${rowData.bankCode}-${rowData.accountNumber}` : ""),
        },
        {
            title: t("bankAccount.fd.accountName"),
            field: "accountName",
        },
    ];

    return (
        <MaterialTable<BankAccountRowData>
            {...props}
            columns={columns}
            data={formTable.fetch()}
            options={{
                pageSizeOptions: [5, 10, 20],
                toolbar: false,
                ...options,
            }}
        />
    );
}

export default BankAccountTable;
