import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { Action } from "@material-table/core";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MdiIcon from "@mdi/react";
import { mdiFileSearch } from "@mdi/js";
import { StandaloneConfirmDialog, StandaloneConfirmDialogRef, SearchTextField } from "components";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import { useDataCUD, useUserPermission } from "hooks";
import { PageHeader2 as PageHeader } from "layout";
import PermissionRoleSearchTable, { PermissionRoleRowData } from "./PermissionRoleSearchTable";

function PermissionRoleList() {
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const history = useHistory();
    const { t } = useTranslation();
    const crud = useDataCUD();
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("permissionRole"), [isWrite]);
    const [showFilter, setShowFilter] = useState(false);
    const tableRef = useRef<any>(null);

    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };

    const actions: ActionType<PermissionRoleRowData>[] = [
        {
            icon: writable ? "edit" : () => <MdiIcon path={mdiFileSearch} size={1} />,
            tooltip: writable ? t("c.edit") : t("c.view"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    history.push({ pathname: "/permissionRole/" + rowData.id });
                }
            },
        },
    ];

    const extraActions: ActionType<PermissionRoleRowData>[] = writable
        ? [
              {
                  icon: () => <FileCopyIcon />,
                  tooltip: t("c.copy"),
                  onClick: (e: any, rowData) => {
                      if (!Array.isArray(rowData)) {
                          history.push({ pathname: `/permissionRole/copy/${rowData.id}` });
                      }
                  },
              },
              (rowData: PermissionRoleRowData): Action<PermissionRoleRowData> => {
                  return {
                      icon: "delete",
                      tooltip: t("c.del"),
                      disabled: rowData.userCount > 0,
                      onClick: (e: any, rowData) => {
                          const action = () => {
                              if (!Array.isArray(rowData)) {
                                  crud.handleDel({}, `/api/permissionRole/${rowData.id}`).then(() => {
                                      tableRef.current.onPageChange(e, 0);
                                  });
                              }
                          };
                          dialogRef.current && dialogRef.current.open(action);
                      },
                  };
              },
          ]
        : [];

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    <Grid container spacing={1}>
                        {writable && (
                            <Grid item>
                                <Button
                                    component={NavLink}
                                    to="/permissionRole/add"
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} startIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />

            <PermissionRoleSearchTable
                tableRef={tableRef}
                actions={actions}
                extraActions={extraActions}
                options={{
                    filtering: showFilter,
                }}
            />
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
        </>
    );
}
export default PermissionRoleList;
