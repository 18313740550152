import React from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import mockFilterStatus from "components/mock/leaveListMock";
import AuditStatusEnum from "components/audit/AuditStatusEnum";

export interface LeaveRowData {
    id: string;
    applicationDate: Date;
    startDate: Date;
    endDate: Date;
    hoursOfLeave: number;
    reason: string;
    appendix?: string;
    status: AuditStatusEnum;
}

export interface LeaveSearchTableProps extends Partial<CustomMaterialTableProps<LeaveRowData>> {
    options?: Options<LeaveRowData>;
    columns: Column<LeaveRowData>[];
    header?: React.ReactNode;
}

function LeaveSearchTable({ options = {}, columns, header, ...props }: LeaveSearchTableProps) {
    // TODO: retrieve real data from api
    const formTable = {
        fetch: () => mockFilterStatus(),
    };

    return (
        <>
            {header && header}
            <MaterialTable<LeaveRowData>
                {...props}
                columns={columns}
                data={formTable.fetch()}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: false,
                    ...options,
                }}
            />
        </>
    );
}

export default LeaveSearchTable;
