import DEF from "../def";

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.POP_TOAST_MESSAGE:
            //console.log({ ...action.value });
            return { ...action.value };
        case DEF.CLEAR_ALL:
            return {};
        default:
            return state;
    }
}
