import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import { TabPanel } from "components";
import { MainForm } from "./form";
import { PositionForm } from "./position";

function EmployeeForm() {
    const { t } = useTranslation();
    const [tabsValue, setTabsValue] = useState<string>("basic");
    const handleTabsChange = (event: ChangeEvent<{}>, newValue: string) => {
        setTabsValue(newValue);
    };
    const isAddMode = true;
    return (
        <Box mx={-5}>
            <Tabs
                value={tabsValue}
                onChange={handleTabsChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
            >
                <Tab value="basic" label={t("c.fd.secBase")} />
                <Tab value="position" label={t("employee.positionSetting")} />
                <Tab value="salary" label={t("employee.salarySetting")} disabled={isAddMode} />
                <Tab value="attendance" label={t("employee.attendance")} disabled={isAddMode} />
                <Tab value="leave" label={t("employee.leave")} disabled={isAddMode} />
                <Tab value="bt" label={t("employee.businessTrip")} disabled={isAddMode} />
            </Tabs>
            <Divider />
            <Box mx={5} mt={2}>
                <TabPanel value={tabsValue} index="basic">
                    <MainForm />
                </TabPanel>
                <TabPanel value={tabsValue} index="position">
                    <PositionForm />
                </TabPanel>
                <TabPanel value={tabsValue} index="salary">
                    {t("employee.salarySetting")}
                </TabPanel>
                <TabPanel value={tabsValue} index="attendance">
                    {t("employee.attendance")}
                </TabPanel>
                <TabPanel value={tabsValue} index="leave">
                    {t("employee.leave")}
                </TabPanel>
                <TabPanel value={tabsValue} index="bt">
                    {t("employee.businessTrip")}
                </TabPanel>
            </Box>
        </Box>
    );
}

export default EmployeeForm;
