import React from "react";
import { ButtonProps } from "@mui/material/Button/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@mui/material";

function AddButton(props: ButtonProps) {
    return <Button color="primary" {...props} startIcon={<AddCircleOutlineIcon />} />;
}

export default AddButton;
