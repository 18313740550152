import React, { ElementType, ReactNode, MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, ListItem, ListItemIcon, ListItemText, Tooltip, ListItemProps } from "@mui/material";
import { DEF } from "Action";
export interface MenuListItemBaseProps extends ListItemProps {
    title: string;
    icon: ReactNode;
    menuOpen?: boolean;
    onClick?: MouseEventHandler;
}

export type MenuListItemProps<C extends ElementType> = ListItemProps<C, { component?: C }> & MenuListItemBaseProps;

export default function MenuListItem<C extends ElementType>({
    title,
    icon,
    children,
    menuOpen,
    onClick,
    ...rest
}: MenuListItemProps<C>) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("md"));
    const handleCloseMenu = () => {
        if (isMobileSize && rest.component) {
            dispatch({ type: DEF.LEFT_MENU_CLOSE });
        }
    };
    const contents = (
        <ListItem
            onClick={(event) => {
                if (onClick) {
                    onClick(event);
                }
                handleCloseMenu();
            }}
            button
            {...rest}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
            {children}
        </ListItem>
    );
    if (menuOpen) {
        return contents;
    }
    return (
        <Tooltip title={title} placement="right">
            {contents}
        </Tooltip>
    );
}
