import React, { useEffect, useCallback, useRef, useState, useMemo } from "react";
import { useParams, useHistory, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { Box, Button, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { PageHeader2 as PageHeader } from "layout";
import { TabPageProps } from "layout/types";
import { addToast, loadControl as LC } from "helpers";
import useFormData, { modeDef } from "hooks/useFormData";
import { useUserPermission } from "hooks";
import { FormBaseRefProps } from "components";
import { useConsolidationCheck } from "components/accounting";
import AccountVoucherBase, { AccountVoucherFormValues, initVal } from "./AccountVoucherFormBase";

function AccountVoucherForm({ match }: TabPageProps) {
    //console.log("match" + JSON.stringify(match));
    const [isFormDataLoaded, setIsFormDataLoaded] = useState<boolean>(false);
    const isAdjustMode = match.path.includes("/adjust/");
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const formRef = useRef<FormBaseRefProps<AccountVoucherFormValues>>(null);
    const { t } = useTranslation();
    const formData = useFormData<AccountVoucherFormValues>("/api/accountVoucher", initVal, id);
    const [formEditable, setFormEditable] = useState<boolean>(false);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("accountVoucher"), [isWrite]);
    const { consolidationDate } = useConsolidationCheck();
    const goBack = () => {
        history.replace({ pathname: "/accountVoucher" });
    };
    useEffect(() => {
        if (isAdjustMode) {
            if (formData.data.status === 0 && writable) {
                formData.setCopyMode("id");
            } else {
                goBack();
            }
        }
        if (match.path.includes("/add") && !writable) {
            goBack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormDataLoaded]);
    const fetchData = useCallback(async () => {
        await formData
            .fetch()
            .then(() => {
                setIsFormDataLoaded(true);
            })
            .catch((err) => {
                if (!formData.isCancel) {
                    goBack(); //if data not found
                    console.log(err);
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        fetchData();
        return () => {
            formData.cleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, id]);

    useEffect(() => {
        if (formData.data && writable) {
            const locked = !!consolidationDate && moment(formData.data.date).isBefore(consolidationDate);
            setFormEditable(formData.data.status === 0 && !locked);
        }
    }, [formData.data, writable]);

    const handleSubmit = async (values: AccountVoucherFormValues) => {
        if (!isAdjustMode) {
            // for normal save
            LC();
            await formData
                .save(values)
                .then((resp) => {
                    console.log(values);
                    if (formData.mode === modeDef.ADD) {
                        goBack();
                    } else {
                        formData.fetch();
                    }
                })
                .finally(() => {
                    LC(false);
                });
        } else {
            //for adjust
            LC();
            axios
                .post("/api/accountVoucher/adjust", { ...values, id })
                .then(function (response) {
                    const data = response.data;
                    if ("status" in data && data.status === "ok") {
                        addToast(t("c.msg.saveSuccess"), { appearance: "success" });
                        goBack();
                    } else {
                        addToast(t("c.msg.saveFailed"), { appearance: "error" });
                    }
                })
                .catch((err) => {
                    addToast(t("c.msg.saveFailed"), { appearance: "error" });
                    console.log(err);
                })
                .finally(() => {
                    LC(false);
                });
            console.log(values);
        }
    };

    const handleSave = async () => {
        formRef.current && formRef.current.onSubmit();
    };

    const title = useMemo(() => {
        if (isAdjustMode) {
            return t("c.adjust");
        }
        if (formData.mode === modeDef.ADD) {
            return t("c.add");
        }
        if (writable && formData.data.status === 0) {
            return t("c.edit");
        }
        return t("c.view");
    }, [formData.data]);

    return (
        <Box mt={-3}>
            <PageHeader
                title={title}
                rightToolView={
                    <Grid container spacing={3} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <Button
                                variant="outlined"
                                component={NavLink}
                                to={"/accountVoucher"}
                                endIcon={<ArrowBackIcon />}
                            >
                                {t("c.backpage")}
                            </Button>
                        </Grid>
                        {writable && formEditable && (
                            <Grid item>
                                <Button variant="outlined" onClick={handleSave} endIcon={<SaveIcon />}>
                                    {t("c.save")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
            />
            <AccountVoucherBase
                onSubmit={handleSubmit}
                initialValues={formData.data}
                formMode={formData.mode}
                formRef={formRef}
                editable={formEditable}
            />
        </Box>
    );
}

export default AccountVoucherForm;
