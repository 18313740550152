import React, { useRef, useEffect, useCallback, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from "@mui/material";
import { SimpleLoading } from "components";
import { FormBaseRefProps } from "components/final-form/FormBase";
import { employeeDataFlagName, useReduxTempDataReloadFlag, useFormData, formModeDef } from "hooks";
import { addDataKey } from "helpers/formHelper";
import WorkPositionFormBase, { defaultInitialValues, WorkPositionFormValues } from "./WorkPositionFormBase";

export interface WorkPositionFormDialogProps extends DialogProps {
    dataId: string | null;
    onChange?: () => void;
}

function WorkPositionFormDialog({ dataId, onChange, onClose, ...props }: WorkPositionFormDialogProps) {
    const formRef = useRef<FormBaseRefProps<WorkPositionFormValues>>(null);
    const { t } = useTranslation();
    const [formDataLoading, setFormDataLoading] = useState(false);
    const formData = useFormData<WorkPositionFormValues>("/api/workPosition", defaultInitialValues, dataId);
    const { addFlag } = useReduxTempDataReloadFlag([`/api/employee/picker`, employeeDataFlagName]);

    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const handleSubmit = async (values: WorkPositionFormValues) => {
        //Check if data has been changed or not
        const mValue = { ...values };
        addDataKey(mValue, "remark");
        await formData
            .save(mValue)
            .then(async (resp) => {
                onChange && onChange();
                addFlag();
                fetchData();
                handleClose();
            })
            .catch(() => {});
    };

    const fetchData = useCallback(async () => {
        setFormDataLoading(true);
        await formData
            .fetch()
            .catch((error) => {
                console.log(error);
                handleClose();
            })
            .finally(() => {
                setFormDataLoading(false);
            });
    }, [dataId]);

    useEffect(() => {
        fetchData();
        return () => {
            formData.cleanup();
        };
    }, [fetchData, dataId]);
    return (
        <Dialog {...props} aria-labelledby="workPosition-dialog-title">
            <DialogTitle id="workPosition-dialog-title">
                {formData.mode === formModeDef.ADD ? t("c.add") : t("c.edit")}
            </DialogTitle>
            <DialogContent>
                {formDataLoading ? (
                    <SimpleLoading />
                ) : (
                    <WorkPositionFormBase
                        onSubmit={handleSubmit}
                        initialValues={formData.data}
                        formMode={formData.mode}
                        formRef={formRef}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default WorkPositionFormDialog;
