import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tabs, Tab } from "@mui/material";
import { TabPanel } from "components";
import AccountTitleGroupList, { AccountTitleGroupListProps } from "./AccountTitleGroupList";

const staticGroupData: AccountTitleGroupListProps[] = [
    { name: "in", usableCatalog: [4, 7] },
    { name: "cost", usableCatalog: [5, 6, 8] },
    { name: "expense", usableCatalog: [5, 6, 8] },
];

function AccountTitleGroupMain() {
    const { t } = useTranslation();
    const [tabsValue, setTabsValue] = useState<string>(staticGroupData[0].name);
    const handleTabsChange = (event: ChangeEvent<{}>, newValue: string) => {
        setTabsValue(newValue);
    };
    return (
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Tabs
                orientation="vertical"
                value={tabsValue}
                onChange={handleTabsChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
            >
                {staticGroupData.map((item) => (
                    <Tab
                        sx={{ fontSize: 16 }}
                        key={item.name}
                        value={item.name}
                        label={t("accountTitle.group." + item.name)}
                    />
                ))}
            </Tabs>
            <Box pl={2} sx={{ flexGrow: 1, borderLeft: 1, borderColor: "divider" }}>
                {staticGroupData.map((item) => (
                    <TabPanel value={tabsValue} index={item.name} key={item.name}>
                        <AccountTitleGroupList {...item} />
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
}

export default AccountTitleGroupMain;
