import React, { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, Divider } from "@mui/material";
import { useFetchData2 as useFetchData, useReduxTempData } from "hooks";
import { getUser } from "helpers";
import { FormBaseRefProps } from "components";
import AuditDialogTitle from "./AuditDialogTitle";
import AuditStatusEnum from "./AuditStatusEnum";
import AuditPath from "./AuditPath";
import { AuditPathItemProps, AuditPathStatusEnum } from "./auditPath/AuditPathItem";
import AuditRowData from "./AuditRowData";
import FormRow from "./FormRow";
import AuditFormButtons from "./AuditFormButtons";
import DetailsContent from "./detailsContent/DetailsContent";
import AuditMessageForm, { AuditMessageFormValues } from "./AuditMessageForm";
import { AuditSubmission } from "./useAuditAction";
import AuditSubmissionStatusEnum from "./AuditSubmissionStatusEnum";
export interface AuditDialogProps extends DialogProps {
    onAudit?: (data: AuditSubmission) => void;
    rowId: string | null;
    readonly: boolean;
}

export interface RequestAuditRecordDetail {
    requestAuditRecordId: number;
    step: number;
    signerId: number;
    signedTime: string | Date;
    message: string;
    status: number;
}

export interface RequestAuditData extends AuditRowData {
    auditFlowData: {
        steps?: number[];
    };
    formData: Record<string, any>;
    requestAuditRecordDetail: RequestAuditRecordDetail[];
    employeeId: number;
    currentSignerId: number;
    currentStep: number;
    rowId: string;
    readonly?: boolean;
}

function AuditDialog({ onClose, onAudit, readonly = true, rowId, ...props }: AuditDialogProps) {
    const { t } = useTranslation();
    const formRef = useRef<FormBaseRefProps<AuditMessageFormValues>>(null);
    const currentAuditSubmissionStatusRef = useRef<AuditSubmissionStatusEnum | null>(null);
    const user = useMemo(() => getUser(), []);
    const currentEmployeeId: number | null = user.employee?.id || null;
    const [status, setStatus] = useState<AuditStatusEnum>(AuditStatusEnum.Rejected);
    const [formValue, setFormValue] = useState<Record<string, any>>({});
    const [dataResult, dataFetch, dataCleanup] = useFetchData<RequestAuditData>(`/api/form/request/${rowId}`);
    const formRowData = useReduxTempData<FormRow[]>(`api/form`, t("e.form"));
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };

    const formName = useMemo<string>(() => {
        if (dataResult && formRowData) {
            const formData = formRowData.find((row) => row.id === dataResult.formId);
            return formData?.name || "";
        }
        return "";
    }, [formRowData, dataResult]);

    useEffect(() => {
        if (rowId) {
            dataFetch().catch((err) => {
                console.log(err);
                handleClose();
            });
        }
        return () => {
            dataCleanup();
        };
    }, [rowId]);

    useEffect(() => {
        if (dataResult) {
            const { status, formData } = dataResult;
            if (status !== undefined) {
                setStatus(status);
                setFormValue(formData);
            }
        }
    }, [dataResult]);

    const isCurrentSigner = useMemo<boolean>(() => {
        if (dataResult && currentEmployeeId) {
            const { currentSignerId } = dataResult;
            return currentSignerId === currentEmployeeId;
        }
        return false;
    }, [dataResult, currentEmployeeId]);

    const auditPathItems = useMemo<AuditPathItemProps[]>(() => {
        if (dataResult) {
            const { status, createdAt, requestAuditRecordDetail, employeeId, currentSignerId, auditFlowData } =
                dataResult;
            const startRow: AuditPathItemProps = {
                position: 0,
                status: AuditPathStatusEnum.Neutral,
                date: createdAt,
                signerId: employeeId,
            };
            const midRows = requestAuditRecordDetail.map<AuditPathItemProps>((item: RequestAuditRecordDetail) => {
                return {
                    position: item.step === 0 ? 1 : item.step,
                    status: item.status, //Accept:1; Rejected:2;
                    date: item.signedTime,
                    signerId: item.signerId,
                    message: item.message,
                };
            });
            const stepsData: number[] = auditFlowData?.steps || [];
            const currentStep = dataResult.currentStep;
            const pendingRows: AuditPathItemProps[] = [];
            if ([AuditStatusEnum.Pending, AuditStatusEnum.Withdrawn].indexOf(status) > -1) {
                const pendingRow: AuditPathItemProps = {
                    position: currentStep <= 0 ? 1 : currentStep + 1,
                    status:
                        status === AuditStatusEnum.Withdrawn
                            ? AuditPathStatusEnum.Withdrawn
                            : AuditPathStatusEnum.Pending,
                    signerId: currentSignerId,
                };
                pendingRows.push(pendingRow);
            }
            let standByRows: AuditPathItemProps[] = [];
            if (status !== AuditStatusEnum.Accepted && stepsData.length > 0) {
                standByRows = [...stepsData].splice(currentStep).map<AuditPathItemProps>((item, index) => {
                    return {
                        position: currentStep + index + 2,
                        status:
                            status === AuditStatusEnum.Pending
                                ? AuditPathStatusEnum.StandBy
                                : AuditPathStatusEnum.Cancelled,
                        signerId: item,
                    };
                });
            }
            return [startRow, ...midRows, ...pendingRows, ...standByRows];
        }
        return [];
    }, [dataResult]);

    const auditable: boolean = status === AuditStatusEnum.Pending && !!rowId && !readonly && isCurrentSigner;
    const handleAuditMessageFormSubmit = (formValue: AuditMessageFormValues) => {
        if (currentAuditSubmissionStatusRef.current !== null && auditable) {
            onAudit &&
                onAudit({
                    ...formValue,
                    id: Number(rowId),
                    status: currentAuditSubmissionStatusRef.current,
                });
        }
    };

    const handleAccept = () => {
        currentAuditSubmissionStatusRef.current = AuditSubmissionStatusEnum.Accepted;
        formRef.current?.onSubmit();
    };

    const handleReject = () => {
        currentAuditSubmissionStatusRef.current = AuditSubmissionStatusEnum.Rejected;
        formRef.current?.onSubmit();
    };

    return (
        <Dialog {...props} fullWidth aria-labelledby="audit-dialog-title">
            <AuditDialogTitle status={status} formName={formName} auditNo={dataResult?.no} />
            <Divider />
            <DialogContent>
                <AuditPath items={auditPathItems} />
            </DialogContent>
            <Divider />
            <DialogContent>
                <DetailsContent formName={formName} formValue={formValue} />
                {auditable && (
                    <Box mt={2}>
                        <AuditMessageForm formRef={formRef} onSubmit={handleAuditMessageFormSubmit} />
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between", flexDirection: "row-reverse" }}>
                <Button color="primary" onClick={handleClose} sx={{ alignSelf: "flex-start" }}>
                    {t("c.close")}
                </Button>
                {auditable && rowId && <AuditFormButtons onAccept={handleAccept} onReject={handleReject} />}
            </DialogActions>
        </Dialog>
    );
}

export default AuditDialog;
