import React, { useState } from "react";
import { Field, useForm } from "react-final-form";
import { Box, Button, Collapse } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { modeDef } from "hooks/useFormData";
import { useAccountVoucherFormContext } from "../AccountVoucherFormBase";
import UniformInvoiceFormContent, { invoiceInitialData } from "./UniformInvoiceFormContent";
import { useTranslation } from "react-i18next";

function UniformInvoiceFormField() {
    const { editable, formMode, inTaxAccountTitle, exTaxAccountTitle } = useAccountVoucherFormContext();
    const [isExpanded, setExpanded] = useState<boolean>(true);
    const { t } = useTranslation();
    const form = useForm();
    if (!inTaxAccountTitle || !exTaxAccountTitle) {
        return <></>;
    }
    const handleExpendButtonClick = () => {
        setExpanded(!isExpanded);
    };
    if (inTaxAccountTitle && exTaxAccountTitle) {
        const taxAccountTitleIds = [inTaxAccountTitle.id, exTaxAccountTitle.id];
        return (
            <Field name="accountTitleId">
                {({ input }) => {
                    const titleIdIndex = taxAccountTitleIds.indexOf(Number(input.value));
                    if (titleIdIndex > -1) {
                        if (editable && formMode === modeDef.ADD) {
                            const formState = form.getState();
                            const values = formState.values;
                            if (!values.uniformInvoice) {
                                form.change("uniformInvoice", invoiceInitialData);
                            }
                        }
                        return (
                            <>
                                <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        onClick={handleExpendButtonClick}
                                        aria-label="expend"
                                        endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    >
                                        {t("accountVoucher.uniformInvoiceInfo")}
                                    </Button>
                                </Box>
                                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                    <UniformInvoiceFormContent
                                        taxType={titleIdIndex}
                                        editable={editable}
                                        formMode={formMode}
                                    />
                                </Collapse>
                            </>
                        );
                    } else {
                        if (editable && formMode === modeDef.ADD) {
                            form.change("uniformInvoice", null);
                        }
                    }
                    return <></>;
                }}
            </Field>
        );
    }
    return <></>;
}

export default UniformInvoiceFormField;
