import React, { useState } from "react";
import { CalendarPicker } from "@mui/lab";

// TODO: select current year
// fix week names
// shorten height
// <LocalizationProvider> has been already wrapped in layout.tsx, so no need to wrap it again.
function CalendarBlock() {
    const [date, setDate] = useState<Date>(new Date()); //date-fns here
    const [view, setView] = useState<"day" | "month" | "year">("day");
    return (
        <CalendarPicker
            date={date}
            onChange={(date) => {
                if (date !== null) {
                    setDate(date);
                    setView("day");
                }
            }}
            onViewChange={setView}
            view={view}
        />
    );
}

export default CalendarBlock;
