import { useTranslation } from "react-i18next";
import { Column } from "@material-table/core";
import { dateFormat } from "helpers";

export interface TimestampRowData {
    createdAt?: string;
    updatedAt?: string;
}

export interface TimestampColumnsOptions {
    createdAt?: boolean;
    updatedAt?: boolean;
}

function useTimestampColumns<RowData extends TimestampRowData>({
    createdAt = true,
    updatedAt = true,
}: TimestampColumnsOptions) {
    const columns: Column<RowData>[] = [];
    const { t } = useTranslation();
    if (createdAt) {
        columns.push({
            title: t("c.fd.createdAt"),
            field: "createdAt",
            type: "string",
            filtering: false,
            render: (row) => {
                if (row.createdAt) {
                    return dateFormat(row.createdAt, "YYYY/MM/DD");
                }
                return "";
            },
        });
    }
    if (updatedAt) {
        columns.push({
            title: t("c.fd.updatedAt"),
            field: "updatedAt",
            type: "string",
            filtering: false,
            render: (row) => {
                if (row.updatedAt) {
                    return dateFormat(row.updatedAt, "YYYY/MM/DD");
                }
                return "";
            },
        });
    }
    return columns;
}

export default useTimestampColumns;
