import { store, DEF } from "../Action";
import axios from "axios";
const doLogout = () => {
    const dispatch = store.dispatch;
    axios.get("/auth/logout").then(function (response) {
        const data = response.data;
        if ("status" in data) {
            if (data.status === "ok") {
                dispatch({ type: DEF.CLEAR_ALL });
                //dispatch({ type: DEF.USER_UPDATE, value: {} });
            }
        }
    });
};
export default doLogout;
