import { useState, useCallback, useRef, MutableRefObject } from "react";
import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { commonErrorHandler, isCommonResponseError, loadControl as LC } from "helpers";
import { addToast } from "helpers";
/**
 * fetch readonly data from backend (database).
 *
 * @param apiPath api for data reading.
 * @param name table name. when loading failed, it will show "load [table name] data failed".
 * @param process function for data processing after data loaded.
 * @param initVal initial data before data loaded.
 * @param auto auto data loading.
 */
function useFetchData2<DataValues = any>(
    apiPath: string,
    name: string = "",
    process?: (values: DataValues) => void,
    initVal: DataValues | null = null
): [
    DataValues | null,
    (act?: boolean, config?: AxiosRequestConfig) => Promise<DataValues | null | undefined>,
    () => void,
    CancelTokenSource,
    MutableRefObject<boolean>
] {
    const mountedRef = useRef(true);
    const [data, setData] = useState<DataValues | null>(initVal || null);
    const { t } = useTranslation();
    const source = axios.CancelToken.source();

    const fetchData = useCallback(
        async (act: boolean = true, config?: AxiosRequestConfig) => {
            if (act) {
                LC();
                mountedRef.current = true;
                await axios
                    .get(apiPath, { cancelToken: source.token, ...config })
                    .then((result) => {
                        if (mountedRef.current) {
                            if (!Array.isArray(result.data) && isCommonResponseError(result.data)) {
                                const extraMsg = commonErrorHandler(result.data);
                                //const msg = t("c.msg.loadSthFailed", { sth: name });
                                //addToast(`${msg}${extraMsg}`, { appearance: "error" });
                                setData(initVal);
                                // eslint-disable-next-line no-throw-literal
                                throw { message: extraMsg, code: result.data.code, raw: result.data };
                            } else {
                                let data = initVal;
                                if (process) {
                                    process(result.data);
                                }
                                data = result.data;
                                setData(data);
                                return data;
                            }
                        }
                        return null;
                    })
                    .catch((err?: Error) => {
                        if (!mountedRef.current) {
                            return null;
                        }
                        if (axios.isCancel(err)) {
                            return null;
                        }
                        const msg = t("c.msg.loadSthFailed", { sth: name });
                        const extraMsg = err && "message" in err ? err.message : "";
                        addToast(`${msg}${extraMsg}`, { appearance: "error" });
                        setData(initVal);
                        throw err;
                    })
                    .finally(() => {
                        LC(false);
                    });
            } else {
                setData(initVal);
                return initVal;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [apiPath]
    );

    const cleanup = () => {
        mountedRef.current = false;
        source.cancel();
        LC(false);
    };

    return [data, fetchData, cleanup, source, mountedRef];
}
export default useFetchData2;
