import React from "react";
import PropTypes from "prop-types";
import * as CustomSvgs from "components/Svg";
import { SvgIcon } from "@mui/material";
export default function CustomIcon({ name, ...props }) {
    let icon = null;
    if (name in CustomSvgs) {
        icon = React.createElement(CustomSvgs[name]);
    }
    if (icon === null) {
        console.error(`icon not found. (name:${name})`);
        return <></>;
    }
    return <SvgIcon {...props}>{icon}</SvgIcon>;
}
CustomIcon.propTypes = {
    name: PropTypes.string.isRequired,
};
