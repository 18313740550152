import React from "react";

import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack, TextField as MuiTextField } from "@mui/material";
import { TimePicker } from "components/final-form/Picks";
import RestPeriodFields from "./RestPeriodPeriodFields";
import { WrappedComponentProps } from "./ShiftForm";

const convertWorkingTimeString = (minute: number, unitHr: string = "hr", unitMinute: string = "min"): string => {
    return `${Math.floor(minute / 60)}${unitHr}${minute % 60}${unitMinute}`;
};

function SecTime({ shiftType, disabled = false }: WrappedComponentProps) {
    const { t } = useTranslation();
    return (
        <Card>
            <CardHeader title={t("shift.fd.time")} titleTypographyProps={{ variant: "h6" }} />
            <Divider />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TimePicker label={t("shift.fd.clockIn")} name="clockIn" disabled={disabled} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TimePicker label={t("shift.fd.clockOut")} name="clockOut" disabled={disabled} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: shiftType === 3 || shiftType === 4 ? "none" : undefined }}>
                        <Stack spacing={2}>
                            <RestPeriodFields name="restPeriod" disabled={disabled} />
                            <Field name="timeValidWarning">
                                {({ input }) => {
                                    const value = input.value as string[];
                                    if (!value || value.length === 0) {
                                        return <></>;
                                    }
                                    return (
                                        <Stack sx={{ color: "#E31B0C", fontSize: 12 }} spacing={1}>
                                            {value.map((msg) => (
                                                <Box key={msg}>{t(`shift.err.${msg}`)}</Box>
                                            ))}
                                        </Stack>
                                    );
                                }}
                            </Field>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TimePicker label={t("shift.fd.overtimePeriod")} name="overtimeStart" disabled={disabled} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TimePicker label={t("shift.fd.overtimePeriod")} name="overtimeEnd" disabled={disabled} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="div" sx={{ color: "#E31B0C", fontSize: 12, mb: 2 }}>
                            {t("shift.alertMsg.LaborStandardsActRule35")}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Field name="totalWorkingTime">
                            {({ input }) => {
                                const value = input.value;
                                const result = convertWorkingTimeString(value, t("c.units.h"), t("c.units.mm"));
                                return (
                                    <MuiTextField
                                        value={result}
                                        label={t("shift.fd.time")}
                                        type="text"
                                        disabled={true}
                                        fullWidth
                                    />
                                );
                            }}
                        </Field>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default SecTime;
