import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import BankSavingsBlock from "components/dashboard/BankSavingsBlock";
import BalanceBlock from "components/dashboard/BalanceBlock";
import { Stack } from "@mui/material";
import AuditAction, { AuditType } from "components/dashboard/AuditAction";
import RequestAction, { RequestType } from "components/dashboard/RequestAction";
import APGraph from "components/dashboard/APGraph";
import ARGraph from "components/dashboard/ARGraph";
import { ClockInBlock } from "components/clockIn";
import NewsList from "components/dashboard/NewsList";
import { useFetchData2 as useFetchData } from "hooks";
import { useHistory } from "react-router-dom";
import AuditTabs from "../audit/AuditTabs";
import FormPath from "../formRequest/FormPath";

export interface DashboardInfo {
    hr: {
        unsigned: number;
        myApply: {
            pending: number;
            rejected: number;
            accepted: number;
        };
        annualLeave: number;
    };
    accounting?: {
        ar: number;
        ap: number;
        bank: number;
        cash: number;
    };
}

function ManagerDashboard() {
    const { t } = useTranslation();
    const history = useHistory();
    const [dashboardInfo, dashboardInfoFetch, dashboardInfoCleanup] =
        useFetchData<DashboardInfo>(`/api/dashboard/info`);

    useEffect(() => {
        dashboardInfoFetch().catch((error) => {
            console.log(error);
        });
        return () => {
            dashboardInfoCleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <ClockInBlock />
            </Grid>
            <Grid item xs={12} md={6}>
                <Stack spacing={3} height="100%">
                    <BankSavingsBlock amount={dashboardInfo?.accounting?.bank || 0} />
                    <BalanceBlock amount={dashboardInfo?.accounting?.cash || 0} />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                    <Grid item xs={12} md={3}>
                        <AuditAction
                            count={dashboardInfo?.hr.unsigned || 0}
                            onClick={() => history.push(AuditTabs.getPath(AuditTabs.pending))}
                            title={t("audit.tab.pending")}
                            type={AuditType.Pending}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <AuditAction
                            count={dashboardInfo?.hr.myApply.pending || 0}
                            onClick={() => history.push(AuditTabs.getPath(AuditTabs.signOff))}
                            title={t("audit.tab.signOff")}
                            type={AuditType.SigningOff}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <AuditAction
                            count={dashboardInfo?.hr.myApply.rejected || 0}
                            onClick={() => history.push(AuditTabs.getPath(AuditTabs.rejected))}
                            title={t("audit.tab.rejected")}
                            type={AuditType.Rejected}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <AuditAction
                            count={dashboardInfo?.hr.myApply.accepted || 0}
                            onClick={() => history.push(AuditTabs.getPath(AuditTabs.accepted))}
                            title={t("audit.tab.accepted")}
                            type={AuditType.Accepted}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                    <Grid item xs={12} md={4}>
                        <RequestAction
                            onClick={() => history.push(FormPath.getPath(FormPath.leaveRequest))}
                            title={t("form.dashboard.leaveRequest")}
                            type={RequestType.Leave}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <RequestAction
                            onClick={() => history.push(FormPath.getPath(FormPath.overtimeRequest))}
                            title={t("form.dashboard.overtimeRequest")}
                            type={RequestType.Overtime}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <RequestAction
                            onClick={() => history.push(FormPath.getPath(FormPath.clockRequest))}
                            title={t("form.dashboard.clockRequest")}
                            type={RequestType.RefillCard}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                    <Grid item xs={12} md={6}>
                        <ARGraph amount={dashboardInfo?.accounting?.ar || 0} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <APGraph amount={dashboardInfo?.accounting?.ap || 0} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <NewsList />
            </Grid>
        </Grid>
    );
}

export default ManagerDashboard;
