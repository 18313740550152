import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import RoundedButton from "components/buttons/RoundedButton";

export interface AuditFormButtonsProps {
    onAccept: () => void;
    onReject: () => void;
}

function AuditFormButtons({ onAccept, onReject }: AuditFormButtonsProps) {
    const { t } = useTranslation();

    const handleAccept = (event?: MouseEvent) => {
        onAccept();
    };

    const handleReject = (event?: MouseEvent) => {
        onReject();
    };

    return (
        <Grid container sx={{ justifyContent: "space-around" }}>
            <RoundedButton color="error" variant="outlined" size="medium" onClick={handleReject}>
                {t("audit.reject")}
            </RoundedButton>
            <RoundedButton color="success" variant="outlined" size="medium" onClick={handleAccept}>
                {t("audit.accept")}
            </RoundedButton>
        </Grid>
    );
}

export default AuditFormButtons;
