import { LeaveRowData } from "../../pages/absence/LeaveSearchTable";
import AuditStatusEnum from "../audit/AuditStatusEnum";

const attendanceListMock: Array<LeaveRowData> = [
    {
        id: "41",
        applicationDate: new Date("2021-10-25 09:00"),
        startDate: new Date("2021-10-25 09:00"),
        endDate: new Date("2021-10-25 09:00"),
        hoursOfLeave: 8,
        reason: "生理痛",
        appendix: "https://google.com",
        status: AuditStatusEnum.Pending,
    },
    {
        id: "42",
        applicationDate: new Date("2021-10-25 09:00"),
        startDate: new Date("2021-10-25 09:00"),
        endDate: new Date("2021-10-25 09:00"),
        hoursOfLeave: 8,
        reason: "生理痛",
        appendix: "https://google.com",
        status: AuditStatusEnum.Pending,
    },
    {
        id: "43",
        applicationDate: new Date("2021-10-25 09:00"),
        startDate: new Date("2021-10-25 09:00"),
        endDate: new Date("2021-10-25 09:00"),
        hoursOfLeave: 8,
        reason: "生理痛",
        appendix: "https://google.com",
        status: AuditStatusEnum.Pending,
    },
    {
        id: "44",
        applicationDate: new Date("2021-10-25 09:00"),
        startDate: new Date("2021-10-25 09:00"),
        endDate: new Date("2021-10-25 09:00"),
        hoursOfLeave: 8,
        reason: "生理痛",
        appendix: "https://google.com",
        status: AuditStatusEnum.Rejected,
    },
];

export default function mockFilterStatus(filterStatus?: AuditStatusEnum) {
    if (filterStatus === undefined) {
        return attendanceListMock;
    }

    return attendanceListMock.filter(({ status }) => status === filterStatus);
}

export function getById(entryId: string): LeaveRowData | undefined {
    return attendanceListMock.find(({ id }) => id === entryId);
}
