import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Divider,
    Stack,
} from "@mui/material";
import ImportPreview from "./ImportPreview";

export interface ImportPreviewDialogProps extends DialogProps {
    onChange?: () => void;
}

function ImportPreviewDialog({ onChange, onClose, ...props }: ImportPreviewDialogProps) {
    const { t } = useTranslation();

    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const handleSave = () => {};

    return (
        <Dialog fullWidth={true} maxWidth={"md"} {...props} aria-labelledby="import-preview-dialog">
            <DialogTitle id="import-preview-dialog-title">{t("匯入")}</DialogTitle>
            <Divider />
            <DialogContent>
                <Box
                    sx={{
                        display: "block",
                        m: "auto",
                        width: "100%",
                    }}
                >
                    <Stack spacing={2}>
                        <Alert severity="info">{t("匯入預覽僅顯示前三筆資料。")}</Alert>
                        <ImportPreview />
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" variant="contained" onClick={handleSave}>
                    {t("匯入")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ImportPreviewDialog;
