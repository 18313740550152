import React from "react";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import Backdrop, { BackdropProps } from "@mui/material/Backdrop";
import { RootState } from "./types";

export default function LoadingBackdrop({ ...props }: Partial<BackdropProps>) {
    const loading = useSelector((state: RootState) => state.loading.length > 0);
    return (
        <Backdrop
            {...props}
            open={loading}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1000,
                color: "#fff",
            }}
        >
            <ReactLoading type="spinningBubbles" color="white" />
        </Backdrop>
    );
}
