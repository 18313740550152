import { getUser } from "helpers";
import React, { useMemo } from "react";
import EmployeeDashboard from "./EmployeeDashboard";
import ManagerDashboard from "./ManagerDashboard";

function DashboardEntry() {
    const user = getUser();
    const dashboardType = useMemo<number>(
        () => user.roles.reduce((previousValue, role) => Math.max(role.dashboard, previousValue), 0),
        [user]
    );
    if (dashboardType === 1 || user.superUser) {
        return <ManagerDashboard />;
    }
    return <EmployeeDashboard />;
}

export default DashboardEntry;
