import DEF from "../def";

const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.LOADING_SHOW:
            initialState.push(true);
            return [...initialState];
        case DEF.LOADING_DISMISS:
            initialState.pop();
            return [...initialState];
        //return false;
        case DEF.LOADING_CLEAR:
            return [];
        //return false;
        default:
            return state;
    }
}
