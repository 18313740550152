import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { ClockRequestFormValues } from "components/audit/formType";
import DetailsBase, { DetailsBaseProps } from "./DetailsBase";
import { TextItem, TextLookupItem } from "components/final-form";
import WorkType from "components/employee/WorkType";

type ClockRequestFormValuesExtend = ClockRequestFormValues & { timeResult?: string };

function ClockRequestDetail({ value }: DetailsBaseProps<ClockRequestFormValues>) {
    const { t } = useTranslation();
    const dutyTypeData = {
        [WorkType.GO_TO_THE_WORK.toString()]: t("attendance.fd.clockIn"),
        [WorkType.GET_OF_THE_WORK.toString()]: t("attendance.fd.clockOff"),
    };
    const { date, time } = value;
    const mValue = {
        ...value,
        timeResult: `${date} ${time}`,
    };
    return (
        <DetailsBase<ClockRequestFormValuesExtend> value={mValue}>
            <Stack spacing={2} sx={{ width: "100%" }}>
                <TextLookupItem name="dutyType" label={t("attendance.fd.fixedClockInType")} lookup={dutyTypeData} />
                <TextItem name="timeResult" label={t("attendance.fd.fixedClockInTime")} />
                <TextItem name="reason" label={t("c.fd.applyReason")} />
            </Stack>
        </DetailsBase>
    );
}

export default ClockRequestDetail;
