import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MomentInput } from "moment";
import { Alert, Box, Button, Stack } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useDataCUD, useReduxTempData, useUserPermission, useFetchData2 as useFetchData } from "hooks";
import { FormBaseRefProps } from "components";
import { AccountConsolidationAlert, useConsolidationCheck } from "components/accounting";
import { PreProcessCheckStatus } from "common/accounting";
import InitialFormBase, { InitialFormValues } from "./InitialFormBase";
import {
    ConsolidationData,
    consolidationDataToFromValue,
    createReportTableFormValues,
    fromValueToInitialDetails,
} from "./helpers";
import { AccountTitleRow } from "../accountTitle";
import moment from "moment";

export const initialValues: Partial<InitialFormValues> = {
    currentPl: 0,
    accumulated: 0,
    undistributed: 0,
    distributed: 0,
    consolidationMethodType: 0,
};

function AccountInitialForm() {
    const { t } = useTranslation();
    const { handleAdd } = useDataCUD<InitialFormValues>();
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("accountSetting"), [isWrite]);
    const [mInitialValues, setMInitialValues] = useState<Partial<InitialFormValues>>(initialValues);
    const [maxDate, setMaxDate] = useState<MomentInput | undefined>(undefined);
    const formRef = useRef<FormBaseRefProps<InitialFormValues>>(null);
    const accountTitles = useReduxTempData<AccountTitleRow[]>(`/api/accountTitle/data`, t("e.accountTitle"), false);
    const { checkResult, checkResultFetch } = useConsolidationCheck();
    const [currentInitialData, currentInitialDataFetch] = useFetchData<ConsolidationData>(
        "/api/accountConsolidation/init"
    );
    const isDataReady = useMemo<boolean>(() => {
        return !!accountTitles && !!checkResult;
    }, [checkResult, accountTitles]);
    const useableAccountTitles = useMemo<AccountTitleRow[]>(() => {
        if (accountTitles) {
            return accountTitles.filter(
                (title) => [1, 2, 3].indexOf(title.catalog) > -1 && [1, 2, 3, 4].indexOf(title.type) > -1
            );
        }
        return [];
    }, [accountTitles]);
    const formDisabled = useMemo<boolean>(() => {
        return (
            !isWrite ||
            !checkResult ||
            checkResult.status === PreProcessCheckStatus.ok ||
            checkResult.status === PreProcessCheckStatus.at_settings_error
        );
    }, [writable, checkResult]);
    const handleSubmit = async (values: InitialFormValues) => {
        const { yearMonth, currentPl, accumulated, undistributed, distributed, type } = values;
        const details = fromValueToInitialDetails(values);
        const mValue = {
            year: moment(yearMonth).year(),
            month: moment(yearMonth).month() + 1,
            currentPl,
            accumulated,
            undistributed,
            distributed,
            type,
            details,
        };
        handleAdd(mValue, "api/accountConsolidation/init")
            .then(() => {
                checkResultFetch();
            })
            .catch((error) => {
                console.log(currentInitialData);
            });
    };
    const handleSave = async () => {
        formRef.current && formRef.current.onSubmit();
    };

    useEffect(() => {
        if (checkResult) {
            const { status } = checkResult;
            if (status === PreProcessCheckStatus.ok) {
                currentInitialDataFetch().catch((error) => {
                    console.log(error);
                });
            } else if (status === PreProcessCheckStatus.no_data || status === PreProcessCheckStatus.no_initial_data) {
                const newData = createReportTableFormValues(useableAccountTitles);
                setMInitialValues({ ...initialValues, ...newData });
                if (checkResult.date) {
                    const mMaxDate = moment(checkResult.date).date(1).add(-1, "month");
                    setMaxDate(mMaxDate);
                }
            }
        }
    }, [checkResult]);

    useEffect(() => {
        if (checkResult && checkResult.status === PreProcessCheckStatus.ok && currentInitialData) {
            const mFormValues = consolidationDataToFromValue(currentInitialData, useableAccountTitles);
            setMInitialValues(mFormValues);
        }
    }, [checkResult, currentInitialData]);

    const alertSettings: Record<PreProcessCheckStatus, ReactNode> = {
        [PreProcessCheckStatus.no_data]: undefined,
        [PreProcessCheckStatus.ok]: <Alert severity="info">{t("accountSetting.msg.initialized")}</Alert>,
        [PreProcessCheckStatus.at_settings_error]: undefined,
        [PreProcessCheckStatus.no_initial_data]: undefined,
    };

    return (
        <Stack spacing={2} sx={{ mt: 2 }}>
            <AccountConsolidationAlert checkResult={checkResult} alertSettings={alertSettings} />
            {isDataReady ? (
                <>
                    <InitialFormBase
                        onSubmit={handleSubmit}
                        disabled={formDisabled}
                        initialValues={mInitialValues}
                        maxDate={maxDate}
                        formRef={formRef}
                    />
                    {!formDisabled && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
                                {t("c.save")}
                            </Button>
                        </Box>
                    )}
                </>
            ) : (
                <></>
            )}
        </Stack>
    );
}

export default AccountInitialForm;
