import React from "react";
import PropTypes from "prop-types";
import { MTableEditField } from "@material-table/core";
import { useCMTContext } from "./../CustomMaterialTable";
export default function MTableValidateEditField({ ...props }) {
    const { errors } = useCMTContext();
    const mErrors = errors || {};
    const name = props.columnDef.field;

    let mValue = props.value;
    if (props.columnDef.type === "numeric" && mValue !== "" && Number.isNaN(props.value)) {
        mValue = 0;
    }

    return (
        <MTableEditField
            error={name in mErrors}
            helperText={name in mErrors && mErrors[name]}
            {...props}
            value={mValue}
        />
    );
}

MTableValidateEditField.propTypes = {
    columnDef: PropTypes.object.isRequired,
    value: PropTypes.any,
};
