import { TempReloadFlagAction } from "Action/Reducers/tempReloadFlag";
import { useDispatch } from "react-redux";

function useReduxTempDataReloadFlag(apiPath: string | string[]) {
    const dispatch = useDispatch();
    const doDispatch = (flag: boolean) => {
        if (Array.isArray(apiPath)) {
            apiPath.forEach((item) => {
                dispatch<TempReloadFlagAction>({ type: "UPDATE_TEMP_RELOAD_FLAG", apiPath: item, flag });
            });
        } else {
            dispatch<TempReloadFlagAction>({ type: "UPDATE_TEMP_RELOAD_FLAG", apiPath, flag });
        }
    };

    const addFlag = () => {
        doDispatch(true);
    };
    const removeFlag = () => {
        doDispatch(false);
    };
    return { addFlag, removeFlag };
}

export default useReduxTempDataReloadFlag;
