import React from "react";
import moment, { Moment } from "moment";
import TextItem, { TextItemProps } from "./TextItem";

export interface TextDateItemProps extends Omit<TextItemProps<Moment>, "render"> {
    format?: string;
}

function TextDateItem({ format = "YYYY-MM-DD", ...props }: TextDateItemProps) {
    return (
        <TextItem<Moment>
            {...props}
            render={(value) => {
                if (value) {
                    return moment(value).format(format);
                }
                return "";
            }}
        />
    );
}

export default TextDateItem;
