import React from "react";
import { useTranslation } from "react-i18next";
import { Options } from "@material-table/core";
import MaterialTable, { Column, CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { useFormTable, useTimestampColumns, TimestampRowData } from "hooks";
export interface WorkPositionRowData extends TimestampRowData {
    id: string;
    order: number;
    name: string;
    jpName?: string;
    enName?: string;
    remark: string;
    workPositionId: number;
    departmentId: number;
}

export interface WorkPositionSearchTable extends Partial<CustomMaterialTableProps<WorkPositionRowData>> {
    options?: Options<WorkPositionRowData>;
}

function WorkPositionSearchTable({ options = {}, ...props }: WorkPositionSearchTable) {
    const formTable = useFormTable<WorkPositionRowData>("/api/workPosition");
    const { t } = useTranslation();
    const timestampColumns = useTimestampColumns<WorkPositionRowData>({});
    const columns: Column<WorkPositionRowData>[] = [
        {
            title: "#",
            field: "id",
            type: "string",
        },
        {
            title: t("workPosition.fd.name"),
            field: "name",
            //editComponent: (props) => <MTableValidateEditField {...props} />,
        },
        {
            title: t("workPosition.fd.order"),
            field: "order",
            type: "numeric",
            defaultSort: "asc",
            //editComponent: (props) => <MTableValidateEditField {...props} />,
        },

        ...timestampColumns,
        {
            title: t("c.fd.rmk"),
            field: "remark",
            initialEditValue: "",
            headerStyle: { width: "40%" },
            sorting: false,
            //editComponent: (props) => <MTableEditField {...props} fullWidth />,
            cellStyle: { width: "40%", wordBreak: "break-all" },
            long: true,
        },
    ];

    return (
        <MaterialTable<WorkPositionRowData>
            {...props}
            columns={columns}
            data={formTable.fetch()}
            options={{
                pageSizeOptions: [5, 10, 20],
                toolbar: false,
                ...options,
            }}
        />
    );
}

export default WorkPositionSearchTable;
