import React, { RefObject, MutableRefObject } from "react";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { localeDateFns } from "layout/commonValue";
import { useAppContent } from "components/AppContext";
import { FormBaseRefProps } from "components";
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from "./DatePicker";
import { TimePicker as MuiTimePicker, TimePickerProps as MuiTimePickerProps } from "./TimePicker";
import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps as MuiDateTimePickerProps } from "./DateTimePicker";

export interface DatePickerProps extends MuiDatePickerProps {
    formRef?: RefObject<any> | MutableRefObject<any>;
}

export function YearPicker({ formRef, ...props }: DatePickerProps) {
    const { t } = useTranslation();
    const { locale } = useAppContent();
    const onAccept = (value: any) => {
        if (formRef) {
            if (value) {
                const mValue = moment.utc(value);
                const newDate = moment.utc(`${mValue.format("YYYY")}-01-01 00:00:00`, "YYYY-MM-DD HH:mm:ss");
                formRef.current?.change && formRef.current.change(props.name, newDate);
            }
        }
    };
    return (
        <MuiDatePicker
            {...props}
            locale={locale && localeDateFns[locale]}
            onAccept={onAccept}
            cancelText={t("c.cancel")}
            clearText={t("c.clear")}
            okText={t("c.confirm")}
            todayText={t("c.today")}
            inputFormat="yyyy"
            views={["year"]}
        />
    );
}

export function DatePicker({ formRef, ...props }: DatePickerProps) {
    const { t } = useTranslation();
    const { locale } = useAppContent();
    const onAccept = (value: any) => {
        if (formRef) {
            if (value) {
                const mValue = moment.utc(value);
                const newDate = moment.utc(`${mValue.format("YYYY-MM-DD")} 00:00:00`, "YYYY-MM-DD HH:mm:ss");
                formRef.current?.change && formRef.current.change(props.name, newDate);
            }
        }
    };
    return (
        <MuiDatePicker
            {...props}
            locale={locale && localeDateFns[locale]}
            onAccept={onAccept}
            cancelText={t("c.cancel")}
            clearText={t("c.clear")}
            okText={t("c.confirm")}
            todayText={t("c.today")}
            mask="____-__-__"
            inputFormat={props.inputFormat || "yyyy-MM-dd"}
        />
    );
}

export function MonthPicker({ formRef, ...props }: DatePickerProps) {
    const { t } = useTranslation();
    const { locale } = useAppContent();
    const onAccept = (value: any) => {
        if (formRef) {
            if (value) {
                const mValue = moment.utc(value);
                const newDate = moment.utc(`${mValue.format("YYYY-MM")}-01 00:00:00`, "YYYY-MM-DD HH:mm:ss");
                formRef.current?.change && formRef.current.change(props.name, newDate);
            }
        }
    };
    return (
        <MuiDatePicker
            {...props}
            locale={locale && localeDateFns[locale]}
            onAccept={onAccept}
            views={["year", "month"]}
            cancelText={t("c.cancel")}
            clearText={t("c.clear")}
            okText={t("c.confirm")}
            todayText={t("c.today")}
            inputFormat={t("c.f.yymmFns")}
        />
    );
}

export interface TimePickerProps extends MuiTimePickerProps {
    formRef?: RefObject<FormBaseRefProps<any>>;
}

/**
 * https://material-ui-pickers.dev/api/TimePicker
 * @param {*} param0
 */
export function TimePicker({ formRef, ...props }: TimePickerProps) {
    const { t } = useTranslation();
    const { locale } = useAppContent();
    const onAccept = (value: any) => {
        if (formRef) {
            if (value) {
                const mValue = moment.utc(value);
                const newDate = moment.utc(mValue.format("HH:mm:ss"), "HH:mm:ss");
                formRef.current?.change && formRef.current.change(props.name, newDate);
            }
        }
    };
    return (
        <MuiTimePicker
            ampm={false}
            ampmInClock={false}
            {...props}
            locale={locale && localeDateFns[locale]}
            onAccept={onAccept}
            cancelText={t("c.cancel")}
            clearText={t("c.clear")}
            okText={t("c.confirm")}
            todayText={t("c.today")}
            //format={props.format || "LT"}
        />
    );
}

export interface DateTimePickerProps extends MuiDateTimePickerProps {
    formRef?: RefObject<FormBaseRefProps<any>>;
}

export function DateTimePicker({ formRef, ...props }: DateTimePickerProps) {
    const { t } = useTranslation();
    const { locale } = useAppContent();
    const onAccept = (value: any) => {
        if (formRef) {
            if (value) {
                const mValue = moment.utc(value);
                const newDate = moment.utc(mValue.format("YYYY-MM-DD HH:mm:ss"), "YYYY-MM-DD HH:mm:ss");
                formRef.current?.change && formRef.current.change(props.name, newDate);
            }
        }
    };
    return (
        <MuiDateTimePicker
            {...props}
            locale={locale && localeDateFns[locale]}
            onAccept={onAccept}
            cancelText={t("c.cancel")}
            clearText={t("c.clear")}
            okText={t("c.confirm")}
            todayText={t("c.today")}
            inputFormat={props.inputFormat || "yyyy-MM-dd HH:mm:ss"}
        />
    );
}
