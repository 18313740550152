import React from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "mui-rff";
import { Grid, Stack } from "@mui/material";
import { AccountTitlePickerField } from "components/final-form";
import { formatNumberInput } from "components/CommonNumberFormat";
import { modeDef } from "hooks/useFormData";
import { useAccountVoucherFormContext } from "../AccountVoucherFormBase";
import UniformInvoiceFormField from "./UniformInvoiceFormField";

function VoucherDetailFormContent() {
    const { t } = useTranslation();
    const { editable, formMode } = useAccountVoucherFormContext();
    const disabledForAddOnly = formMode !== modeDef.ADD || !editable;
    return (
        <Stack>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <AccountTitlePickerField
                        field="accountTitleId"
                        label={t("e.accountTitle")}
                        filter={(item) => item.type === 3 || item.type === 4}
                        disabled={disabledForAddOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t("accountVoucher.fd.particulars")}
                        inputProps={{ maxLength: 255 }}
                        name="particulars"
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t("accountVoucher.fd.debit")}
                        name="debit"
                        variant="outlined"
                        InputProps={formatNumberInput()}
                        inputProps={{ maxLength: 8 }}
                        disabled={disabledForAddOnly}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        label={t("accountVoucher.fd.credit")}
                        name="credit"
                        variant="outlined"
                        InputProps={formatNumberInput()}
                        inputProps={{ maxLength: 8 }}
                        disabled={disabledForAddOnly}
                        required
                    />
                </Grid>
            </Grid>
            <UniformInvoiceFormField />
        </Stack>
    );
}

export default VoucherDetailFormContent;
