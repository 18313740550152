import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import MaterialTable, { MTableValidateEditField } from "components/customMaterialTable";
import { mtFormatNumber as mfn } from "components/CommonNumberFormat";
import { useFormTable } from "hooks";
import { useFetchData } from "hooks";
import { createLookup } from "helpers/mTableHelper";
import { loadControl as LC } from "helpers";

function SalaryItemsPickerDialog({ id, onClose = () => {}, ...props }) {
    const tableRef = useRef(null);
    const { t } = useTranslation();
    const [lvData, itemFetch] = useFetchData(
        "/api/worklevel/all",
        t("e.worklevel"),
        (data) => createLookup(data, "name"),
        null,
        {}
    );
    const fetchMetaData = useCallback(async () => {
        LC();
        await itemFetch();
        LC(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        fetchMetaData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Validate rules
    const schema = yup.object().shape({
        level: yup.number().min(0).integer().required(),
        amount: yup.number().min(0).integer().positive().required(),
    });
    //Make validate
    const formTable = useFormTable(`/api/salarylv/si/${id}`, schema);

    const columns = [
        {
            title: t("worklv.fd.name"),
            field: "level",
            long: true,
            editable: "never",
            lookup: lvData,
            editComponent: (props) => <MTableValidateEditField {...props} />,
        },
        {
            title: t("c.fd.amount"),
            field: "amount",
            type: "numeric",
            editComponent: (props) => <MTableValidateEditField {...props} />,
            render: mfn("amount"),
        },
        {
            title: t("c.fd.rmk"),
            field: "rmk",
            long: true,
        },
    ];

    return (
        <Dialog {...props} aria-labelledby="salary-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="salary-dialog-title">{t("salary.rank_dtl")}</DialogTitle>
            <DialogContent>
                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={formTable.fetch()}
                    onCanceled={formTable.clearErrors}
                    formTableErrors={formTable.errors}
                    editable={{
                        onRowAdd: formTable.add,
                        onRowUpdate: formTable.edit,
                        //onRowDelete: formTable.del,
                    }}
                    options={{
                        paging: false,
                        toolbar: false,
                        sorting: false,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {t("c.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
SalaryItemsPickerDialog.propTypes = {
    id: PropTypes.number,
    onClose: PropTypes.func,
};

export default SalaryItemsPickerDialog;
