import "date-fns";
import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";
import {} from "components/hideAnnoyingWarnings";
import * as serviceWorker from "./serviceWorker";
import ProviderLevel from "providerLevel";
if (process.env.NODE_ENV === "production") {
    window.oconsole = {
        ...console,
    };
    console.log = () => {};
    console.debug = () => {};
    console.info = () => {};
    console.warn = () => {};
}

ReactDOM.render(
    <React.StrictMode>
        <ProviderLevel>
            <App />
        </ProviderLevel>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
