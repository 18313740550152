/* eslint-disable no-template-curly-in-string */
const zh = {
    mixed: {
        default: "${path}格式不正確",
        required: "${path}為必填欄位",
        oneOf: "${path}必須是下列值中的其中之一: ${values}",
        notOneOf: "${path}不能是下列值中的其中之一: ${values}",
        notType: "格式不正確",
        /*notType: ({ path, type, value, originalValue }) => {
          let isCast = originalValue != null && originalValue !== value;
          let msg =
            `${path}必須是\`${type}\`格式, ` +
            `但目前的值為: \`${printValue(value, true)}\`` +
            (isCast
              ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
              : '.');
      
          if (value === null) {
            msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
          }
      
          return msg;
        },*/
        defined: "${path}必須被定義",
    },
    string: {
        length: "${path}長度必須是${length}字元",
        min: "${path}長度至少大於${min}字元",
        max: "${path}長度最多不超過${max}字元",
        matches: '${path}必須符合以下格式: "${regex}"',
        email: "${path}必須是合法E-mail",
        url: "${path}必須是合法網址",
        trim: "${path}前後不能有任何空白",
        lowercase: "${path}必須全部都是小寫字母",
        uppercase: "${path}必須全部都是大寫字母",
    },
    number: {
        min: "${path}必須大於或等於${min}",
        max: "${path}必須小於或等於${max}",
        lessThan: "${path}必須小於${less}",
        moreThan: "${path}必須大於${more}",
        notEqual: "${path}不可為${notEqual}",
        positive: "${path}必須是正數",
        negative: "${path}必須是負數",
        integer: "${path}必須是整數",
    },
    boolean: {},
    date: {
        min: "${path}必須在${min}之後",
        max: "${path}必須在${max}之前",
    },
    array: {
        min: "${path}至少要有${min}個項目",
        max: "${path}必須低於或等於${max}個項目",
    },
    object: {
        noUnknown: "${path}含有未指定的鍵值: ${unknown}",
    },
};

export default zh;
