/**
 * Create query arguments from object key: value.
 *
 * @param params
 */
export function glueQueryParams(params: object) {
    const queryString = [];
    for (const [key, value] of Object.entries(params)) {
        queryString.push(`${key}=${value}`);
    }

    return queryString.join("&");
}
