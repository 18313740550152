import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import MaterialTable from "components/customMaterialTable";
import { Column } from "components/customMaterialTable/CustomMaterialTable";
import { useFormTable } from "hooks";
import { getUser } from "helpers";

export interface I18nRow {
    language: string;
    name: string;
}

export interface I18nTableProps<RowData extends I18nRow> {
    dataId?: string | number | null;
    apiPath: string;
    nameTitle?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    columns?: Column<RowData>[];
    extraFields?: string[];
    defaultLangEditable?: boolean;
    editable?: boolean;
}

function I18nTable<RowData extends I18nRow>({
    dataId,
    apiPath,
    nameTitle,
    columns = [],
    extraFields,
    defaultLangEditable = false,
    editable = true,
}: I18nTableProps<RowData>) {
    const user = useMemo(() => getUser(), []);
    const defaultLang = user.lang?.default || "zh-TW";
    const langList = user.lang?.list || ["zh-TW"];
    const tableRef = useRef<any>(null);
    const { t } = useTranslation();
    const formTable = useFormTable<RowData>(`${apiPath}/${dataId}`, undefined, {
        allDataMode: true,
        //onEdit: preSubmitData,
    });
    const handleDataChange = useCallback(async () => {
        await formTable.fetchOnly().catch(() => {});
    }, []);
    useEffect(() => {
        if (dataId) {
            handleDataChange();
        }
        return () => {
            formTable.cleanup();
        };
    }, [dataId]);
    const processedData = useMemo<RowData[]>(() => {
        if (!dataId) {
            return [];
        }
        const data = formTable.data;
        return langList.map<RowData>((lang) => {
            const origRow = data.find((mRow) => mRow.language === lang);
            if (origRow) {
                return origRow;
            }
            const extraData: Record<string, string> = {};
            if (extraFields) {
                extraFields.forEach((field) => {
                    extraData[field] = "";
                });
            }
            return {
                ...extraData,
                language: lang,
                name: "",
            } as RowData;
        });
    }, [formTable.data]);
    const mColumns: Column<RowData>[] = [
        {
            title: t("c.fd.language"),
            field: "language",
            editable: "never",
            render: (rowData) =>
                t("lang." + rowData.language) + (rowData.language === defaultLang ? `(${t("c.defaultLang")})` : ""),
        },
        {
            title: nameTitle || t("c.fd.title"),
            field: "name",
        },
        ...columns,
    ];

    return (
        <MaterialTable
            tableRef={tableRef}
            columns={mColumns}
            data={processedData}
            options={{
                pageSizeOptions: [5, 10, 20],
                pageSize: 10,
                toolbar: false,
            }}
            onCanceled={formTable.clearErrors}
            formTableErrors={formTable.errors}
            editable={
                editable
                    ? {
                          isEditable: (rowData) => defaultLangEditable || rowData.language !== defaultLang,
                          onRowUpdate: formTable.edit,
                      }
                    : undefined
            }
        />
    );
}

export default I18nTable;
