import React, { useEffect, useContext, createContext } from "react";
import { useTranslation } from "react-i18next";
import { Radios, Select, TextField, makeValidate } from "mui-rff";
import { Field } from "react-final-form";
import * as yup from "yup";
import { SchemaOf, StringSchema } from "yup";
import { Box, Checkbox, Chip, Grid, ListItemText, MenuItem } from "@mui/material";
import FormBase, { FormBaseProps } from "components/final-form/FormBase";
import { EmployeePickerField, StatusSwitch } from "components/final-form";
import { useFetchData2 as useFetchData } from "hooks";
import { modeDef, ModeDefineType } from "hooks/useFormData";
import { StdQueryListResult } from "common";
import { PermissionRolePickRow } from "pages/permissionRole/PermissionRolePickerDialog";
import PasswordField from "./PasswordField";

export interface UserFormValues {
    id?: number;
    name: string;
    email?: string;
    account: string;
    password?: string;
    confirmPassword?: string;
    autoGenerate?: boolean;
    resetPassword?: boolean;
    loginType: number | string;
    status?: boolean;
    permissionRoleIds: any[];
    employeeId?: number;
}

export const defaultInitialValues: Partial<UserFormValues> = {
    loginType: "1",
    name: "",
    account: "",
    status: true,
    permissionRoleIds: [],
    autoGenerate: false,
    resetPassword: false,
};

export interface UserFormBaseProps extends FormBaseProps<UserFormValues> {
    initialValues: Partial<UserFormValues>;
}

const LOGIN_TYPE = {
    ACCOUNT: "0",
    GOOGLE: "1",
    LINE: "2",
};

interface FormContextValue {
    formMode: ModeDefineType;
}

const FormContext = createContext<FormContextValue>({
    formMode: modeDef.ADD,
});

const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

function UserFormBase({
    initialValues = defaultInitialValues,
    onSubmit,
    formMode = modeDef.ADD,
    ...props
}: UserFormBaseProps) {
    const { t } = useTranslation();
    const schema: SchemaOf<UserFormValues> = yup
        .object()
        .shape({
            loginType: yup.string().label(t("c.fd.loginType")).required(),
            name: yup.string().label(t("user.fd.name")).required(),
            status: yup.bool().label(t("c.enabledStatus")).required(),
            account: yup
                .string()
                .label(t("login.fd.user"))
                .when("loginType", (val: string, schema: StringSchema) =>
                    val === "0" ? schema.required() : schema.nullable()
                ),
            password: yup
                .string()
                .label(t("login.fd.pass"))
                .when("loginType", (val: string, schema: StringSchema) => {
                    if (formMode === modeDef.ADD && val === "0") {
                        return schema.when("autoGenerate", (agVal: string, schema: StringSchema) =>
                            !agVal ? schema.required() : schema.nullable()
                        );
                    } else {
                        return schema.nullable();
                    }
                }),
            confirmPassword: yup
                .string()
                .label(t("user.fd.confirmPass"))
                .when("loginType", (val: string, schema: StringSchema) => {
                    if (formMode === modeDef.ADD && val === "0") {
                        return schema.when("autoGenerate", (agVal: string, schema: StringSchema) =>
                            !agVal
                                ? schema.oneOf([yup.ref("password"), null], t("user.msg.confirmNotMatch")).required()
                                : schema.nullable()
                        );
                    } else {
                        return schema.nullable();
                    }
                }),
            email: yup
                .string()
                .label(t("c.fd.email"))
                .when("loginType", (val: string, schema: StringSchema) =>
                    val === "1" ? schema.email().required() : schema.nullable()
                ),
            permissionRoleIds: yup.array().label(t("e.permissionRole")).of(yup.number()).required(),
        })
        .defined();
    const validate = makeValidate(schema);
    return (
        <FormBase<UserFormValues> validate={validate} initialValues={initialValues} onSubmit={onSubmit} {...props}>
            <FormContext.Provider value={{ formMode }}>
                <FormContent />
            </FormContext.Provider>
        </FormBase>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const [roleData, roleFetch, roleCleanup] = useFetchData<StdQueryListResult<PermissionRolePickRow>>(
        "/api/permissionRole/picker",
        t("e.permissionRole"),
        undefined,
        { count: 0, data: [] }
    );
    useEffect(() => {
        roleFetch();
        return () => {
            roleCleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <StatusSwitch name="status" />
            </Grid>
            <Grid item xs={12}>
                <Radios
                    label={t("user.fd.loginType")}
                    name="loginType"
                    radioGroupProps={{
                        row: true,
                    }}
                    required={true}
                    data={[
                        { label: t("user.loginType.1"), value: "1" },
                        { label: t("user.loginType.0"), value: "0" },
                    ]}
                />
            </Grid>
            <Field name="loginType">
                {(typeInputProps) => {
                    const typeValue = typeInputProps.input.value;
                    return <SecWithLoginType loginType={typeValue} />;
                }}
            </Field>
            <Grid item xs={12}>
                <EmployeePickerField fullWidth name="employeeId" clearable label={t("e.emp")} />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t("user.fd.name")} name="name" required={true} />
            </Grid>
            <Grid item xs={12}>
                <Field name="permissionRoleIds">
                    {(prInputProps) => {
                        const prValue = prInputProps.input.value;
                        return (
                            <Select
                                multiple
                                name="permissionRoleIds"
                                label={t("e.permissionRole")}
                                formControlProps={{ margin: "normal" }}
                                renderValue={(selected) => (
                                    <Box>
                                        {roleData &&
                                            roleData.data.length > 0 &&
                                            (selected as string[]).map((value) => {
                                                const roleValue = roleData.data.find((row) => row.id === Number(value));
                                                if (roleValue) {
                                                    return (
                                                        <Box m={0.5} component="span" key={roleValue.name}>
                                                            <Chip label={roleValue.name} />
                                                        </Box>
                                                    );
                                                }
                                                return <React.Fragment key={value}></React.Fragment>;
                                            })}
                                    </Box>
                                )}
                            >
                                {roleData &&
                                    roleData.data.map((role, index) => (
                                        <MenuItem key={role.id} value={role.id}>
                                            <Checkbox
                                                checked={
                                                    prValue && Array.isArray(prValue) && prValue.indexOf(role.id) > -1
                                                }
                                            />
                                            <ListItemText primary={role.name} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        );
                    }}
                </Field>
            </Grid>
        </Grid>
    );
}

interface SecWithLoginTypeProps {
    loginType: keyof typeof LOGIN_TYPE;
}

function SecWithLoginType({ loginType }: SecWithLoginTypeProps) {
    const { t } = useTranslation();
    const { formMode } = useFormContext();
    return (
        <>
            {loginType === LOGIN_TYPE.ACCOUNT && (
                <>
                    <Grid item xs={12}>
                        <TextField label={t("login.fd.user")} name="account" required={true} />
                    </Grid>
                    {formMode === modeDef.ADD && (
                        <Grid item xs={12}>
                            <PasswordField
                                passwordFieldName="password"
                                confirmPasswordFieldName="confirmPassword"
                                label={t("login.fd.pass")}
                            />
                        </Grid>
                    )}
                </>
            )}
            {loginType === LOGIN_TYPE.GOOGLE && (
                <Grid item xs={12}>
                    <TextField label={t("c.fd.email")} name="email" required={true} />
                </Grid>
            )}
        </>
    );
}

export default UserFormBase;
