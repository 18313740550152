import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuditStatusChip from "./AuditStatusChip";
import AuditStatusEnum from "./AuditStatusEnum";

export interface AuditStatusProps {
    statusId: AuditStatusEnum;
}

export default function AuditStatus({ statusId }: AuditStatusProps) {
    const { t } = useTranslation();

    // eslint-disable-next-line no-unused-vars
    const statuses: { [key in AuditStatusEnum]: ReactNode } = {
        [AuditStatusEnum.Rejected]: (
            <AuditStatusChip type={AuditStatusEnum.Rejected} label={t("audit.status.rejected")} />
        ),
        [AuditStatusEnum.Accepted]: (
            <AuditStatusChip type={AuditStatusEnum.Accepted} label={t("audit.status.accepted")} />
        ),
        [AuditStatusEnum.Pending]: <AuditStatusChip type={AuditStatusEnum.Pending} label={t("audit.status.pending")} />,
        [AuditStatusEnum.SigningOff]: (
            <AuditStatusChip type={AuditStatusEnum.SigningOff} label={t("audit.status.pending")} />
        ),
        [AuditStatusEnum.Withdrawn]: (
            <AuditStatusChip type={AuditStatusEnum.Withdrawn} label={t("audit.status.withdrawn")} />
        ),
    };

    const [status, setStatus] = useState<ReactNode | null>(null);
    useEffect(() => {
        setStatus(statuses[statusId] ?? null);
    }, [statusId]);

    return <>{status || status}</>;
}
