import { LeaveBlockProps } from "../leave/LeaveBlock";

function getRandomLeaveBlocks() {
    const size = 12;
    const hours = [...Array(size).keys()];

    return hours.map((hour) => {
        return {
            hours: Math.ceil(240 * Math.random()) + 1,
            name: `家庭照顧假-${hour}`,
        } as unknown as LeaveBlockProps;
    });
}

export default getRandomLeaveBlocks;
