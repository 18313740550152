import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropzoneArea } from "react-mui-dropzone";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogProps } from "@mui/material";
import { addToast, loadControl as LC } from "helpers";
import axios from "faxios";
import { getErrorDescByCode } from "common";

export interface DataImportDialog extends DialogProps {
    onFinished?: () => void;
}

function DataImportDialog({ onFinished, onClose, ...props }: DataImportDialog) {
    //const dropzoneRef = createRef();
    const { t } = useTranslation();
    const [uploadFiles, setUploadFiles] = useState<File[]>([]);

    const handleDropzoneChange = (files: File[]) => {
        setUploadFiles([...files]);
    };

    const handleConfirm = async () => {
        const formData = new FormData();
        if (uploadFiles.length > 0) {
            formData.append("file", uploadFiles[0]); //required
            LC();
            const response = await axios
                .post(`/api/accountTitle/upload`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .finally(() => {
                    LC(false);
                });
            const { data } = response;
            if (data.status === "ok") {
                addToast(t("c.msg.fileUploadSuccess"), { appearance: "success" });
                onFinished && onFinished();
                handleClose();
            } else {
                addToast(getErrorDescByCode(data.code), { appearance: "error" });
            }
        } else {
            addToast(t("c.msg.fileRequire"), { appearance: "error" });
        }
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    return (
        <Dialog onClose={onClose} {...props}>
            <DialogTitle id="data-import-dialog-title">{t("c.import")}</DialogTitle>
            <DialogContent>
                <Box p={1} />
                <DropzoneArea
                    //ref={dropzoneRef}
                    onChange={handleDropzoneChange}
                    dropzoneText={t("ui.dzText")}
                    previewText={t("ui.dzPreview")}
                    acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                    filesLimit={1}
                    showPreviewsInDropzone={false}
                    showPreviews
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} color="primary">
                    {t("c.confirm")}
                </Button>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                    color="primary"
                >
                    {t("c.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DataImportDialog;
