/** @format */
import React, { useRef, useState } from "react";
import moment, { MomentInput } from "moment";
import { useTranslation } from "react-i18next";
import { Button, Grid, Chip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { PageHeader2 as PageHeader } from "layout";
import MaterialTable, { Column } from "components/customMaterialTable";
import SearchDialog from "components/customMaterialTable/SearchDialog";
import { useFormTable, useMessageUtil } from "hooks";
import { MessageData } from "layout/types";
//import { loadControl as LC } from "helpers";

function MessageList() {
    //const [errors, setErrors] = useState({});
    const tableRef = useRef<any>(null);
    const formTable = useFormTable<MessageData>("/api/message");
    const [searchOpen, setSearchOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const messageUtil = useMessageUtil();

    const handleSearchClose = () => {
        setSearchOpen(false);
    };
    const handleSearchBtn = () => {
        setSearchOpen(true);
    };
    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };

    const { t } = useTranslation();
    /*const actions = [
        {
            icon: "edit",
            tooltip: t("c.edit"),
            onClick: (e, row) => {
                doEdit(row);
            },
        },
        {
            icon: "delete_outline",
            tooltip: t("c.del"),
            disabled: true,
            onClick: (e, row) => {
                console.log("delete");
            },
        },
    ];*/

    const formatTime = (time: MomentInput) => moment(time).format("LLL");

    const typeData = t("message.type", { returnObjects: true }) as Record<string, string>;
    const columns: Column<MessageData>[] = [
        {
            title: t("c.fd.type"),
            field: "type",
            lookup: typeData,
        },
        {
            title: t("c.fd.content"),
            field: "content",
            long: true,
            render: (rowData) => messageUtil.getMsg(rowData.content, rowData.type, rowData.msgKey),
        },
        {
            title: t("c.fd.date"),
            field: "createAt",
            render: (rowData) => formatTime(rowData.createdAt),
        },
        {
            title: t("message.isRead"),
            field: "isRead",
            type: "boolean",
            render: (rowData) =>
                rowData.isRead ? "" : <Chip size="small" color="secondary" label={t("message.unread")} />,
        },
    ];

    return (
        <>
            <PageHeader
                title=""
                rightToolView={
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="contained" onClick={handleSearchBtn} endIcon={<SearchIcon />}>
                                {t("c.search")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} endIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
            <MaterialTable<MessageData>
                tableRef={tableRef}
                columns={columns}
                data={formTable.fetch()}
                //actions={actions}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    pageSize: 10,
                    toolbar: false,
                    filtering: showFilter,
                }}
            />
            <SearchDialog open={searchOpen} onClose={handleSearchClose} tableRef={tableRef} />
        </>
    );
}

export default MessageList;
