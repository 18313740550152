import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

function useMainTitle(initValue: string) {
    const dispatch = useDispatch();
    const setTitle = useCallback(
        (value: string) => {
            dispatch({ type: "SET_MAIN_TITLE", value });
        },
        [dispatch]
    );
    useEffect(() => {
        setTitle(initValue);
    }, [initValue, setTitle]);
    return { setTitle };
}

export default useMainTitle;
