import React from "react";
import { useTranslation } from "react-i18next";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { useFormTable, useTimestampColumns, TimestampRowData, useEnabledStatusColumn } from "hooks";
import { Box, Chip } from "@mui/material";

interface PermissionRoleData {
    id: number;
    name: string;
}

export interface UserRowData extends TimestampRowData {
    id: string;
    name: string;
    email?: string;
    account: string;
    loginType: number | string;
    loginAccount: string;
    status?: boolean;
    permissionRoles?: PermissionRoleData[];
    createdAt?: string;
    updatedAt?: string;
    flag: number;
}

export interface UserSearchTableProps extends Partial<CustomMaterialTableProps<UserRowData>> {
    options?: Options<UserRowData>;
}

function UserSearchTable({ options = {}, ...props }: UserSearchTableProps) {
    const formTable = useFormTable<UserRowData>("/api/user/list");
    const { t } = useTranslation();
    const timestampColumns = useTimestampColumns<UserRowData>({});
    const enabledStatusColumn = useEnabledStatusColumn<UserRowData>("status");

    const columns: Column<UserRowData>[] = [
        {
            title: "#",
            field: "id",
            type: "string",
        },
        enabledStatusColumn,
        {
            title: t("user.fd.loginType"),
            field: "loginType",
            lookup: t("user.loginType", { returnObjects: true }),
        },
        {
            title: t("login.fd.user"),
            field: "loginAccount",
            type: "string",
        },
        {
            title: t("user.fd.name"),
            field: "name",
            type: "string",
        },
        {
            title: t("e.permissionRole"),
            field: "permissionRolesData",
            sorting: false,
            filtering: false,
            render: (row) => {
                if (row.flag === 1) {
                    return <Chip label={t("user.superuser")} size="small" color="primary" />;
                }
                if (row.permissionRoles) {
                    const chips = row.permissionRoles.map((role, idx) => {
                        return (
                            <Box mr={0.5} mb={0.5} component="span" key={idx}>
                                <Chip label={role.name} size="small" />
                            </Box>
                        );
                    });
                    return (
                        <Box display="flex" flexWrap="wrap">
                            {chips}
                        </Box>
                    );
                }
                return "";
            },
        },
        ...timestampColumns,
    ];
    return (
        <MaterialTable<UserRowData>
            {...props}
            columns={columns}
            data={formTable.fetch()}
            options={{
                pageSizeOptions: [5, 10, 20],
                toolbar: false,
                ...options,
            }}
        />
    );
}

export default UserSearchTable;
