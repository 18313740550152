import React from "react";
import * as MaterialDesign from "react-icons/md";
import * as FontAwesome from "react-icons/fa";
import * as Remix from "react-icons/ri";
import { SvgIcon, SvgIconProps } from "@mui/material";
import * as CustomIcon from "components/Svg";
import { IconLibrary } from "common";
export interface MenuIconsProp extends SvgIconProps {
    lib: IconLibrary;
    name: string;
}
export default function MenuIcons({ lib, name, ...props }: MenuIconsProp) {
    let icon = null;
    if (lib === "md" && name in MaterialDesign) {
        icon = React.createElement(MaterialDesign[name as keyof typeof MaterialDesign]);
    }
    if (lib === "fa" && name in FontAwesome) {
        icon = React.createElement(FontAwesome[name as keyof typeof FontAwesome]);
    }
    if (lib === "ri" && name in Remix) {
        icon = React.createElement(Remix[name as keyof typeof Remix]);
    }
    if (lib === "custom" && name in CustomIcon) {
        icon = React.createElement(CustomIcon[name as keyof typeof CustomIcon]);
    }
    if (icon === null) {
        console.error(`icon not found. (lib:${lib} name:${name})`);
        return <></>;
    }
    return <SvgIcon {...props}>{icon}</SvgIcon>;
}
