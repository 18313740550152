import React, { useEffect, useCallback, useRef, MouseEvent, useState } from "react";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { useTranslation } from "react-i18next";
import { TextField, Select, makeValidate } from "mui-rff";
import {
    Grid,
    Divider,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    DialogProps,
    InputAdornment,
    IconButton,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import useFormData from "hooks/useFormData";
import { loadControl as LC } from "helpers";
import { FormBase, AccountTitlePickerField } from "components/final-form/";
import { AccountTitleRow } from "./AccountTitleTreeItem";
import { FormBaseRefProps } from "components";
import useItemCheck from "./useItemCheck";
export interface AccountTitleFormValues extends Omit<AccountTitleRow, "children"> {
    remark: string;
}

export const initVal: Partial<AccountTitleFormValues> = {
    type: 3,
    dc: 0,
    code: "",
    catalog: 0,
    remark: "",
};

export interface EditorDialogData {
    id?: number | null;
    parent?: AccountTitleRow;
}

export interface ItemEditorDialogProps extends DialogProps {
    data: EditorDialogData;
    editable?: boolean;
    onChange?: () => void;
    onI18nEdit?: (id: number) => void;
}

function ItemEditorDialog({ data, editable = true, onClose, onChange, onI18nEdit, ...props }: ItemEditorDialogProps) {
    const { t } = useTranslation();
    const [locked, setLocked] = useState<boolean>(false);
    const formRef = useRef<FormBaseRefProps<AccountTitleFormValues>>(null);
    const { id, parent } = data;
    const formData = useFormData<AccountTitleFormValues>("/api/accountTitle/data", initVal, id);
    const { isItemLocked } = useItemCheck();
    //const isLocked: boolean = isItemLocked(row);
    const fetchData = useCallback(async () => {
        LC();
        if (data) {
            await formData.fetch().catch((err) => {
                if (!formData.isCancel) {
                    console.log(err);
                }
            });
        }
        LC(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        fetchData();
        if (!id) {
            let mData: Partial<AccountTitleFormValues> = { ...initVal };
            if (parent) {
                const layerData: Partial<AccountTitleRow> = {};
                let lastLayer = false;
                for (let i = 0; i < 4; i++) {
                    const key = `l${i}` as keyof AccountTitleRow & keyof typeof lastLayer;
                    if (!parent[key] && !lastLayer) {
                        layerData[key] = parent.id as never;
                        lastLayer = true;
                    } else {
                        layerData[key] = parent[key];
                    }
                }
                const { catalog } = parent;
                mData = {
                    ...mData,
                    ...layerData,
                    catalog,
                };
                setLocked(false);
            }
            formData.setData(mData);
        }
        return () => {
            formData.mountedRef.current = false;
            formData.source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, data]);

    useEffect(() => {
        if (id) {
            const bool = isItemLocked(formData.data as AccountTitleRow);
            setLocked(bool);
        }
    }, [formData.data]);

    const handleSave = (event: MouseEvent) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };
    const handleSubmit = (values: AccountTitleFormValues) => {
        LC();
        formData
            .save(values)
            .then((/*resp*/) => {
                onChange && onChange();
                handleClose();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                LC(false);
            });
    };

    const schema: SchemaOf<AccountTitleFormValues> = yup
        .object()
        .shape({
            dc: yup.number().label(t("accountTitle.fd.dc")).oneOf([0, 1]).required(),
            code: yup.string().label(t("accountTitle.fd.code")).required(),
        })
        .defined();

    const validate = makeValidate(schema);

    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    return (
        <Dialog onClose={onClose} maxWidth="lg" fullWidth {...props} aria-labelledby="account-title-dialog-title">
            <DialogTitle id="account-title-dialog-title">
                {id ? (locked ? t("c.view") : t("c.edit")) : t("c.add")}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <FormBase
                    onSubmit={handleSubmit}
                    subscription={{ submitting: true, pristine: true }}
                    validate={validate}
                    initialValues={{
                        ...initVal,
                        ...formData.data,
                    }}
                    formRef={formRef}
                >
                    <FormContent viewOnly={!editable || locked} onI18nEdit={onI18nEdit} id={id} />
                </FormBase>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                {editable && !locked && (
                    <Button color="primary" onClick={handleSave}>
                        {t("c.save")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

interface FormContentProps {
    id?: number | null;
    viewOnly: boolean;
    onI18nEdit?: (id: number) => void;
}

function FormContent({ viewOnly, onI18nEdit, id }: FormContentProps) {
    const { t } = useTranslation();
    const dcTypeData = t("accountTitle.dc", { returnObjects: true }) as Record<string, string>;
    const catData = t("accountTitle.cats", { returnObjects: true }) as Record<string, string>;
    const typeData = t("accountTitle.type", { returnObjects: true }) as Record<string, string>;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    label={t("accountTitle.fd.name")}
                    name="name"
                    disabled={viewOnly}
                    required
                    InputProps={{
                        endAdornment: id && onI18nEdit && (
                            <InputAdornment position="end">
                                <IconButton
                                    size="large"
                                    onClick={() => {
                                        onI18nEdit(id);
                                    }}
                                >
                                    <LanguageIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <TextField label={t("accountTitle.fd.code")} name="code" disabled={viewOnly} required />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <Select name="type" label={t("c.fd.type")} disabled={true}>
                    <MenuItem value={""}>{t("c.pleaseSelect")}</MenuItem>
                    {Object.keys(typeData).map((a, i) => {
                        return (
                            <MenuItem key={i} value={Number(a)}>
                                {typeData[a]}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Select name="dc" label={t("accountTitle.fd.dc")} disabled={viewOnly} required>
                    {Object.keys(dcTypeData).map((a, i) => {
                        return (
                            <MenuItem key={i} value={Number(a)}>
                                {dcTypeData[a]}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Select name="catalog" label={t("accountTitle.fd.catalog")} disabled={true}>
                    {Object.keys(catData).map((a, i) => {
                        return (
                            <MenuItem key={i} value={Number(a)}>
                                {catData[a]}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AccountTitlePickerField field="l0" label={t("accountTitle.fd.l0")} disabled={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AccountTitlePickerField field="l1" label={t("accountTitle.fd.l1")} disabled={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AccountTitlePickerField field="l2" label={t("accountTitle.fd.l2")} disabled={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AccountTitlePickerField field="l3" label={t("accountTitle.fd.l3")} disabled={true} />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t("c.fd.rmk")} disabled={viewOnly} name="remark" />
            </Grid>
        </Grid>
    );
}

export default ItemEditorDialog;
