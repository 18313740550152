import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Paper, SxProps, TableCell, Theme } from "@mui/material";
import { ReportTable, TotalInfoFormBlack } from "components/accounting";
import { TextNumberItem } from "components/final-form";
import { useInitialFormContext } from "./InitialFormBase";

function InitialDetailTable() {
    const { t } = useTranslation();
    const { disabled, assetTitles, liabilityTitles, equityTitles } = useInitialFormContext();
    return (
        <Paper sx={{ padding: (theme) => theme.spacing(2) }} variant="outlined">
            <Grid spacing={2} sx={{ justifyContent: "space-around" }} container>
                <Grid item xs={12} md={6}>
                    <ReportTable isEditMode={!disabled} data={assetTitles} />
                    <TotalInfoFormBlack title={t("accReport.totalAssets")} name="assetTotal" />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <ReportTable
                            isEditMode={!disabled}
                            data={liabilityTitles}
                            summaryCells={
                                <SummaryRow title={t("accReport.totalLiabilityAmount")} name="liabilityTotal" />
                            }
                        />
                        <ReportTable
                            isEditMode={!disabled}
                            data={equityTitles}
                            showHeader={false}
                            summaryCells={<SummaryRow title={t("accReport.totalEquityAmount")} name="equityTotal" />}
                        />
                    </Box>
                    <TotalInfoFormBlack title={t("accReport.totalLE")} name="liabilityEquityTotal" />
                </Grid>
            </Grid>
        </Paper>
    );
}

interface SummaryRowProps {
    title: string;
    name: string;
}

function SummaryRow({ title, name }: SummaryRowProps) {
    const cellStyle: SxProps<Theme> = {
        fontWeight: "bold",
        borderBottom: 0,
        borderTop: (theme) => `1px solid ${theme.palette.text.secondary}`,
    };
    return (
        <>
            <TableCell colSpan={2} sx={cellStyle}>
                {title}
            </TableCell>
            <TableCell sx={cellStyle}>
                <TextNumberItem name={name} align="right" />
            </TableCell>
        </>
    );
}

export default InitialDetailTable;
