import { useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import { addToast, loadControl as LC } from "helpers";
import { ResponseHandler } from "common";
import useDataCUD from "./useDataCUD";

export type ModeDefineType = "add" | "edit" | "view";
export const modeDef: { [key: string]: ModeDefineType } = {
    ADD: "add",
    EDIT: "edit",
    VIEW: "view",
};

export interface FormData<FormValues = Record<string, any>> {
    data: Partial<FormValues>;
    setData: React.Dispatch<React.SetStateAction<Partial<FormValues>>>;
    fetch: () => Promise<FormValues | Partial<FormValues>>;
    save: (values: Partial<FormValues>) => Promise<void | ResponseHandler<FormValues>>;
    setReadonly: (bool: boolean) => void;
    setCopyMode: (idKey: string | string[]) => void;
    mountedRef: React.MutableRefObject<boolean>;
    source: CancelTokenSource;
    isCancel: (value: any) => boolean;
    cleanup: () => void;
    mode: ModeDefineType;
}
/**
 * Hooks for CRUD function of form.
 *
 * Read: GET [apiPath]/[id]
 * create: POST [apiPath] @see useDataCUD handleAdd
 * update: PUT [apiPath] @see useDataCUD handleEdit
 *
 * Example:
 * const formdata = useFormData("api/tablename", {}, id);
 * formdata.fetch() //to fetch data from backend
 * formdata.data //data which has been fetched from backend
 * formdata.save() //save data
 * formdata.mode //get mode of form, there are two mode: add and edit (defined by modeDef)
 *
 * @param {string} apiPath api for creating, reading and updating.
 * @param {FormValues} initVal inital data of form.
 * @param {number | string | null} id  PK of the data (Not support for multiple primary key), if id is null the form will turn into "add" mode.
 * @param {Function} process function for process data that fetched from backend.
 */
function useFormData<FormValues = Record<string, any>, InitFormValues = Partial<FormValues>>(
    apiPath: string,
    initVal: InitFormValues,
    id?: number | string | null,
    process?: (data: FormValues | null) => void
): FormData<FormValues> {
    const [formData, setFormData] = useState<Partial<FormValues>>(initVal);
    const [mode, setMode] = useState<ModeDefineType>(modeDef.ADD);
    const { t } = useTranslation();
    const { handleAdd, handleEdit, onCanceled, isCancel, mountedRef, source } = useDataCUD<FormValues>();

    /**
     * fetch data from backend (database).
     */
    const fetchData = async (): Promise<FormValues | Partial<FormValues>> => {
        mountedRef.current = true;
        if (id) {
            LC();
            return await axios
                .get(`${apiPath}/${id}`, { cancelToken: source.token })
                .then((result) => {
                    if (mountedRef.current) {
                        const data = result.data;
                        if (result.status < 400 && data !== null && Object.keys(data).length !== 0) {
                            if (process) {
                                process(data);
                            }
                            //resolve(data);
                            setMode(modeDef.EDIT);
                            setFormData(data);
                            return data;
                        } else {
                            addToast(t("c.msg.nodata"), {
                                appearance: "error",
                            });
                        }
                    }
                    throw new Error();
                })
                .catch((err) => {
                    if (mountedRef.current) {
                        if (axios.isCancel(err)) {
                            onCanceled(err);
                        } else {
                            console.log(err);
                            addToast(t("c.msg.nodataOrLoadFailed"), {
                                appearance: "error",
                            });
                        }
                    }
                    throw err;
                })
                .finally(() => {
                    LC(false);
                });
        } else {
            setMode(modeDef.ADD);
            setFormData(initVal);
            return initVal;
        }
    };

    const setReadonly = (bool: boolean) => {
        if (mode !== modeDef.ADD) {
            if (bool) {
                setMode(modeDef.VIEW);
            } else {
                setMode(modeDef.EDIT);
            }
        }
    };

    const setCopyMode = (idKey: string | string[]) => {
        const mFormData = { ...formData };
        if (Array.isArray(idKey)) {
            idKey.forEach((key) => {
                delete mFormData[key as keyof typeof mFormData];
            });
        } else {
            delete mFormData[idKey as keyof typeof mFormData];
        }
        setFormData(mFormData);
        setMode(modeDef.ADD);
    };

    /**
     * save data to database
     * @param {*} values data which will be saved.
     */
    const saveData = async (values: Partial<FormValues>): Promise<void | ResponseHandler<FormValues>> => {
        if (mode === modeDef.VIEW) {
            return new Promise((resolve, reject) => {
                addToast(t("c.msg.readonlyError"), {
                    appearance: "error",
                });
                reject();
            });
        }
        if (id && mode === modeDef.EDIT) {
            const editResponse = await handleEdit(values, apiPath);
            if (editResponse) {
                return editResponse;
            }
        } else {
            const addResponse = await handleAdd(values, apiPath);
            if (addResponse) {
                return addResponse;
            }
        }
    };

    const cleanup = () => {
        mountedRef.current = false;
        source.cancel();
        LC(false);
    };

    return {
        /**
         * data for show in the form ui
         */
        data: formData,
        setData: setFormData,
        fetch: fetchData,
        save: saveData,
        setReadonly: setReadonly,
        setCopyMode,
        mountedRef,
        source,
        isCancel,
        cleanup,
        /**
         * form mode, there are two mode: "add" and "edit". if data id is null, the mode would be "add".
         */
        mode: mode,
    };
}

export default useFormData;
