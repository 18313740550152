import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSitemap } from "react-icons/fa";
import { Action } from "@material-table/core";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import { StandaloneConfirmDialog, StandaloneConfirmDialogRef, SearchTextField } from "components";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import { useDataCUD, useUserPermission } from "hooks";
import { PageHeader2 as PageHeader } from "layout";
import DepartmentSearchTable, { DepartmentRowData } from "./DepartmentSearchTable";
import DepartmentFormDialog from "./DepartmentFormDialog";
import DepartmentOrgDialog from "./DepartmentOrgDialog";
import DepartmentMemberDialog from "./DepartmentMemberDialog";

function DepartmentList() {
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const { t } = useTranslation();
    const crud = useDataCUD();
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("leaveType"), [isWrite]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [formDataId, setFormDataId] = useState<string | null>(null);
    const [memberDataId, setMemberDataId] = useState<string | null>(null);
    const [memberManageOpen, setMemberManageOpen] = useState<boolean>(false);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [orgDialogOpen, setOrgDialogOpen] = useState<boolean>(false);
    const tableRef = useRef<any>(null);

    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };
    const handleAdd = async () => {
        setFormDataId(null);
        setFormOpen(true);
    };
    const handleEdit = async (row: DepartmentRowData) => {
        setFormDataId(row.id);
        setFormOpen(true);
    };
    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleMemberManageOpen = async (row: DepartmentRowData) => {
        setMemberDataId(row.id);
        setMemberManageOpen(true);
    };
    const handleMemberManageClose = () => {
        setMemberManageOpen(false);
    };

    const handleOrgDialogOpen = () => {
        setOrgDialogOpen(true);
    };

    const handleOrgDialogClose = () => {
        setOrgDialogOpen(false);
    };

    const refreshTable = () => {
        tableRef.current?.onQueryChange();
    };

    const actions: ActionType<DepartmentRowData>[] = writable
        ? [
              (rowData: DepartmentRowData): Action<DepartmentRowData> => {
                  return {
                      icon: "edit",
                      tooltip: t("c.edit"),
                      onClick: (e: any, rowData) => {
                          if (!Array.isArray(rowData)) {
                              handleEdit(rowData);
                          }
                      },
                  };
              },
          ]
        : [
              (rowData: DepartmentRowData): Action<DepartmentRowData> => {
                  return {
                      icon: "people",
                      tooltip: t("department.memberManage"),
                      onClick: (e: any, rowData) => {
                          if (!Array.isArray(rowData)) {
                              handleMemberManageOpen(rowData);
                          }
                      },
                  };
              },
          ];

    const extraActions = writable
        ? [
              (rowData: DepartmentRowData): Action<DepartmentRowData> => {
                  return {
                      icon: "people",
                      tooltip: t("department.memberManage"),
                      onClick: (e: any, rowData) => {
                          if (!Array.isArray(rowData)) {
                              handleMemberManageOpen(rowData);
                          }
                      },
                  };
              },
              (rowData: DepartmentRowData): Action<DepartmentRowData> => {
                  return {
                      icon: "delete",
                      tooltip: t("c.del"),
                      onClick: (e: any, rowData) => {
                          const action = () => {
                              if (!Array.isArray(rowData)) {
                                  crud.handleDel({}, `/api/department/${rowData.id}`).then(() => {
                                      tableRef.current.onPageChange(e, 0);
                                  });
                              }
                          };
                          dialogRef.current?.open(action);
                      },
                  };
              },
          ]
        : [];

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    writable ? (
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        handleAdd();
                                    }}
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} startIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleOrgDialogOpen}
                                startIcon={<FaSitemap />}
                            >
                                {t("department.org.title")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />

            <DepartmentSearchTable
                tableRef={tableRef}
                actions={actions}
                extraActions={extraActions}
                options={{
                    filtering: showFilter,
                }}
            />
            <DepartmentFormDialog
                dataId={formDataId}
                onClose={handleFormClose}
                onChange={refreshTable}
                open={formOpen}
            />
            <DepartmentMemberDialog
                dataId={memberDataId}
                onClose={handleMemberManageClose}
                onChange={refreshTable}
                open={memberManageOpen}
            />
            <DepartmentOrgDialog onClose={handleOrgDialogClose} open={orgDialogOpen} />
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
        </>
    );
}

export default DepartmentList;
