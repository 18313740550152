import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import EmployeeDetails, { EmployeeDetailsProps } from "./EmployeeDetails";
import LeaveBlockGrid from "./LeaveBlockGrid";
import { LeaveBlockProps } from "./LeaveBlock";
import { useFetchData2 as useFetchData } from "hooks";
import { EmployeeFormValues } from "pages/employee/form";
import moment from "moment";
import { useTranslation } from "react-i18next";
import LeaveInfoDialog from "./LeaveInfoDialog";
import { LeaveInfoProps } from "./LeaveInfo";

interface LeaveDetailsProps {
    employeeId: number;
}

interface EmployeeLeave {
    id: number;
    leaveTypeId: number;
    remain: number;
    hours: number;
    usedHours: number;
    expiration: string | null;
    "leaveType.name": string;
}

interface LeaveResponse {
    name: string;
    employeeNo: string;
    hireDate: string;
    employeeLeave: Array<EmployeeLeave>;
}

function employeeLeaveToLeaveBlockProps(data: EmployeeLeave, setLeaveInfoProps: React.Dispatch<LeaveInfoProps>) {
    const { hours, usedHours, remain } = data;
    /*const getAvailableHours = () => {
        return hours + remain - usedHours;
    };*/

    return {
        id: data.id,
        hours: remain,
        name: data["leaveType.name"],
        onClick: () => {
            setLeaveInfoProps({
                systemHours: hours,
                remainHours: remain,
                usedHours: usedHours,
                adjustHours: remain - hours + usedHours,
            });
        },
    } as LeaveBlockProps;
}

function LeaveDetails({ employeeId }: LeaveDetailsProps) {
    const { t } = useTranslation();
    const [dataResult, dataFetch, dataCleanup] = useFetchData<EmployeeFormValues>(
        `/api/employee/${employeeId}`,
        t("e.emp")
    );
    const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetailsProps>();

    useEffect(() => {
        dataFetch().catch(console.error);
    }, [employeeId]);

    useEffect(() => {
        if (dataResult) {
            setEmployeeDetails({
                employeeNo: dataResult.employeeNo ?? "",
                name: dataResult.name ?? "",
                date: moment(dataResult.hireDate),
            });
        }

        return () => {
            dataCleanup();
        };
    }, [dataResult]);

    const [leaveResult, leaveFetch, leaveCleanup] = useFetchData<LeaveResponse>(
        `/api/attendance/employeeLeave/${employeeId}`,
        t("employee.leave")
    );
    const [leaveBlocks, setLeaveBlocks] = useState<Array<LeaveBlockProps>>([]);
    useEffect(() => {
        leaveFetch().catch(console.error);
    }, [employeeId]);

    useEffect(() => {
        if (leaveResult) {
            const leaveBlocksList = leaveResult.employeeLeave.map((data) =>
                employeeLeaveToLeaveBlockProps(data, setLeaveInfoProps)
            );

            setLeaveBlocks(leaveBlocksList);
        }

        return () => {
            leaveCleanup();
        };
    }, [leaveResult]);

    const [leaveInfoOpen, setLeaveInfoOpen] = useState<boolean>(false);
    const [leaveInfoProps, setLeaveInfoProps] = useState<LeaveInfoProps>();
    useEffect(() => {
        setLeaveInfoOpen(!!leaveInfoProps);
    }, [leaveInfoProps]);

    return (
        <Box sx={{ p: 4 }}>
            {/* TODO: wrap into components that fetch data on their own*/}
            {employeeDetails && <EmployeeDetails {...employeeDetails} />}
            <LeaveBlockGrid leaveBlocks={leaveBlocks} />
            {leaveInfoProps && (
                <LeaveInfoDialog
                    open={leaveInfoOpen}
                    onClose={() => setLeaveInfoProps(undefined)}
                    leaveInfoProps={leaveInfoProps}
                />
            )}
        </Box>
    );
}

export default LeaveDetails;
