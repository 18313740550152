import React from "react";
import { useSelector } from "react-redux";
import { Box, Divider, Typography } from "@mui/material";
import { RootState } from "./types";

function MainTitle() {
    const mainTitle = useSelector((state: RootState) => state.mainTitle);
    if (!mainTitle && mainTitle === "") {
        return <></>;
    }
    return (
        <>
            <Box p={2.5}>
                <Typography variant="h6" component="h1" color="textSecondary">
                    {mainTitle}
                </Typography>
            </Box>
            <Divider />
        </>
    );
}

export default MainTitle;
