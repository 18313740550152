import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import MaterialTable, { Column, CustomMaterialTableProps } from "components/customMaterialTable";
import { mtFormatNumber as mfn } from "components/CommonNumberFormat";
import { useFormTable } from "hooks";
import { Options } from "@material-table/core";

export interface AccountConsolidationRow {
    id: number;
    yearMonth: string;
    currentPl: number;
    accumulated: number;
    distributed: number | null;
    undistributed: number | null;
}

export interface AccountConsolidationSearchTableProps
    extends Partial<CustomMaterialTableProps<AccountConsolidationRow>> {
    options?: Options<AccountConsolidationRow>;
}

function AccountConsolidationSearchTable({ options = {}, ...props }: AccountConsolidationSearchTableProps) {
    const { t } = useTranslation();
    const formTable = useFormTable<AccountConsolidationRow>("/api/accountConsolidation");
    const ymFmt = t("c.f.yymm");
    const formatYearMonth = (yearMonth: string) => moment(yearMonth, "YYYYMM").format(ymFmt);
    const columns: Column<AccountConsolidationRow>[] = [
        {
            title: t("c.fd.yearMonth"),
            field: "yearMonth",
            long: true,
            render: (rowData) => {
                if (rowData && rowData.yearMonth) {
                    return formatYearMonth(rowData.yearMonth);
                }
                return "";
            },
        },
        {
            title: t("accountTitle.settingData.pl"),
            field: "currentPl",
            type: "numeric",
            render: mfn("currentPl"),
        },
        {
            title: t("accountConsolidation.fd.distributed"),
            field: "distributed",
            type: "numeric",
            render: mfn("distributed"),
        },
        {
            title: t("accountConsolidation.fd.undistributed"),
            field: "undistributed",
            type: "numeric",
            render: mfn("undistributed"),
        },
        {
            title: t("accountConsolidation.fd.accumulated"),
            field: "accumulated",
            type: "numeric",
            render: mfn("accumulated"),
        },
        {
            title: t("c.fd.status"),
            field: "type",
            lookup: t("accountConsolidation.type", { returnObjects: true }),
        },
    ];
    return (
        <>
            <MaterialTable<AccountConsolidationRow>
                columns={columns}
                data={formTable.fetch()}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    pageSize: 10,
                    toolbar: false,
                    ...options,
                }}
                {...props}
            />
        </>
    );
}

export default AccountConsolidationSearchTable;
