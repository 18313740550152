import React from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import mockFilterStatus from "components/mock/attendanceListMock";
import AttendanceStatusEnum from "components/attendance/AttendanceStatusEnum";

export interface AbsenceRowData {
    id: string;
    date: Date;
    workHours: string;
    endWorkTime: string;
}

export interface AbsenceSearchTableProps extends Partial<CustomMaterialTableProps<AbsenceRowData>> {
    options?: Options<AbsenceRowData>;
    columns: Column<AbsenceRowData>[];
    statusFilter?: AttendanceStatusEnum;
    header?: React.ReactNode;
}

function AbsenceSearchTable({ options = {}, columns, header, statusFilter, ...props }: AbsenceSearchTableProps) {
    // TODO: retrieve real data from api
    const formTable = {
        fetch: () => mockFilterStatus(statusFilter),
    };

    return (
        <>
            {header && header}
            <MaterialTable<AbsenceRowData>
                {...props}
                columns={columns}
                data={formTable.fetch() as any}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: false,
                    ...options,
                }}
            />
        </>
    );
}

export default AbsenceSearchTable;
