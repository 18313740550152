import React from "react";
import { Card, CardContent, CardHeader, Divider, Grid, styled, TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MomentInput } from "moment";
import { dateFormat } from "helpers";

export interface EmployeeDetailsProps {
    employeeNo: string;
    name: string;
    date: MomentInput;
}

interface ItemProps {
    id: string;
    label: string;
    value: string;
}

function formatDate(date: MomentInput) {
    return dateFormat(date, "YYYY-MM-DD");
}

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
    "& .MuiInputLabel-formControl": {
        color: "#263238 !important",
    },
}));

function EmployeeDetails({ employeeNo, name, date }: EmployeeDetailsProps) {
    const { t } = useTranslation();
    const Item = ({ id, label, value }: ItemProps) => (
        <Grid item xs={12} md={4}>
            <StyledTextField
                id={id}
                label={label}
                variant="outlined"
                fullWidth={true}
                defaultValue={value}
                color="primary"
                inputProps={{ readOnly: true }}
            />
        </Grid>
    );
    return (
        <Card>
            <CardHeader
                titleTypographyProps={{ variant: "h6", component: "h6" }}
                title={t("c.fd.secBase")}
                sx={{ p: 2, pb: 1 }}
            />
            <Divider light />
            <CardContent sx={{ pt: 0 }}>
                <Grid container justifyContent="space-between" alignContent="center" spacing={3}>
                    <Item id="employee-id" label={t("employee.fd.employeeNo")} value={employeeNo} />
                    <Item id="name" label={t("employee.fd.name")} value={name} />
                    <Item id="date" label={t("employee.fd.hireDate")} value={formatDate(date)} />
                </Grid>
            </CardContent>
        </Card>
    );
}

export default EmployeeDetails;
