import React, { ReactNode } from "react";
import { Collapse, styled } from "@mui/material";
import { useExpendableCard } from "./ExpendableCard";

export interface ExpandableContentProps {
    children: ReactNode;
}

const StyledCollapse = styled(Collapse)(() => ({
    "& .MuiCollapse-wrapper": {
        display: "block",
    },
}));

/**
 *  ExpandableContent must be put in the ExpendableCard tag
 */
function ExpandableContent({ children }: ExpandableContentProps) {
    const { isExpanded } = useExpendableCard();
    return (
        <StyledCollapse in={isExpanded} timeout="auto" unmountOnExit>
            {children}
        </StyledCollapse>
    );
}

export default ExpandableContent;
