import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import useformData, { modeDef } from "hooks/useFormData";
import { loadControl as LC } from "helpers";
import SalaryItemsForm from "./SalaryItemsForm";

function SalaryItemsFormDialog({ id, onClose = () => {}, onChange = () => {}, ...props }) {
    const formRef = useRef(null);
    const { t } = useTranslation();
    const formData = useformData("/api/salaryitem", {}, id);
    const fetchData = useCallback(async () => {
        LC();
        await formData.fetch().catch(() => {
            onClose();
        });
        LC(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const handleSave = (event) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };

    const handleSubmit = async (values) => {
        //Check if data has been changed or not
        LC();
        await formData
            .save(values)
            .then(async () => {
                if (formData.mode === modeDef.EDIT) {
                    await formData.fetch();
                }
                await onChange();
                onClose();
            })
            .catch(() => {});
        LC(false);
    };

    useEffect(() => {
        fetchData();
    }, [fetchData, id]);

    return (
        <Dialog {...props} aria-labelledby="depart-dialog-title">
            <DialogTitle id="depart-dialog-title">
                {formData.mode === modeDef.ADD ? t("c.add") : t("c.edit")}
            </DialogTitle>
            <DialogContent>
                <SalaryItemsForm initialValues={formData.data} onSubmit={handleSubmit} formRef={formRef} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SalaryItemsFormDialog.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClose: PropTypes.func,
    onChange: PropTypes.func,
};

export default SalaryItemsFormDialog;
