import { Radios, RadiosProps } from "mui-rff";
import React from "react";

export interface LookupRadiosProps extends Omit<RadiosProps, "data"> {
    lookup: Record<string, String>;
}

function LookupRadios({ lookup, radioGroupProps = { row: true }, required = true, ...props }: LookupRadiosProps) {
    return (
        <Radios
            {...props}
            radioGroupProps={radioGroupProps}
            required={required}
            data={Object.keys(lookup).map((key) => ({
                label: lookup[key as keyof typeof lookup],
                value: key,
            }))}
        />
    );
}

export default LookupRadios;
