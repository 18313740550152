import React, { ReactNode, MouseEvent } from "react";
import { Action } from "@material-table/core";
import { IconButton, Tooltip } from "@mui/material";
import ActionIcon from "./ActionIcon";

export interface CustomExAction<RowData extends object> extends Action<RowData> {
    render?: (rowData: RowData) => ReactNode;
}

export interface CustomActionProps<RowData extends object> {
    action:
        | Action<RowData>
        | ((rowData: RowData) => Action<RowData>)
        | CustomExAction<RowData>
        | ((rowData: RowData) => CustomExAction<RowData>);

    data: RowData;
    disabled: boolean;
    size: string;
}

type ButtonSize = "small" | "medium";

function CustomAction<RowData extends object>({ action, data, disabled, size }: CustomActionProps<RowData>) {
    let mAction: CustomExAction<RowData> | null = null;
    const actionAny = action as Record<string, any>;
    if ("action" in actionAny) {
        action = actionAny.action;
    }
    if (typeof action === "function") {
        mAction = action(data);
    } else {
        mAction = action;
    }
    if (mAction) {
        if (mAction.hidden) {
            return <></>;
        }

        if (mAction.render) {
            const renderView = mAction.render(data);
            return <>{renderView}</>;
        }

        const mDisabled = mAction.disabled || disabled;

        const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
            if (mAction && mAction.onClick) {
                mAction.onClick(event, data);
                event.stopPropagation();
            }
        };

        const button = (
            <IconButton size={size as ButtonSize} color="inherit" disabled={mDisabled} onClick={handleOnClick}>
                <ActionIcon icon={mAction.icon} iconProps={mAction.iconProps} disabled={mDisabled} />
            </IconButton>
        );
        if (mAction.tooltip) {
            return mDisabled ? (
                <Tooltip title={mAction.tooltip}>
                    <span>{button}</span>
                </Tooltip>
            ) : (
                <Tooltip title={mAction.tooltip}>{button}</Tooltip>
            );
        } else {
            return button;
        }
    }
    return <></>;
}

export default CustomAction;
