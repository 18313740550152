import React from "react";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import { Container, Stack, Typography, ThemeProvider, Box, styled } from "@mui/material";
import theme from "./splashTheme";

const logoWSpacing = 28;
//const loadingWidth = 64;

const LogoImg = styled("img")(({ theme }) => ({
    width: theme.spacing(logoWSpacing),
}));

export interface SplashProps {
    messages: string[];
}

function Splash({ messages = [] }: SplashProps) {
    const { t } = useTranslation();
    return (
        <ThemeProvider theme={theme}>
            <Container component="main">
                <Stack sx={{ minHeight: "100vh" }}>
                    <LogoImg alt="logo" src="/images/logo/logo-mirai-white.png" />
                    <Stack spacing={0}>
                        {(messages.length === 0 && <Typography>{t("ui.loading")}...</Typography>) ||
                            messages.map((msg, idx) => <Typography key={idx}>{msg}</Typography>)}
                    </Stack>
                    <Box my={2} />
                    <ReactLoading type="spinningBubbles" color="white" />
                </Stack>
            </Container>
        </ThemeProvider>
    );
}

export default Splash;
