import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { TextField, makeValidate, Radios } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid } from "@mui/material";
import FormBase, { FormBaseProps, FormBaseRefProps, useFormBaseContext } from "components/final-form/FormBase";

import { DatePicker } from "components/final-form/Picks";
import { LeaveTypeFormValue } from "../basic";

export interface BaseDataFormBaseProps extends FormBaseProps<LeaveTypeFormValue> {
    initialValues: Partial<LeaveTypeFormValue>;
}

function BaseDataFormBase({ initialValues, onSubmit, ...props }: BaseDataFormBaseProps) {
    const { t } = useTranslation();
    const formRef = useRef<FormBaseRefProps<LeaveTypeFormValue>>(null);
    const schema: SchemaOf<LeaveTypeFormValue> = yup
        .object()
        .shape({
            name: yup.string().label(t("leaveType.fd.name")).required(),
            assignType: yup.string().label(t("leaveType.fd.assignType")).oneOf(["0", "1"]).required(),
        })
        .defined();
    const validate = makeValidate(schema);

    return (
        <FormBase<LeaveTypeFormValue>
            validate={validate}
            initialValues={initialValues}
            onSubmit={onSubmit}
            formRef={formRef}
            {...props}
        >
            <FormContent />
        </FormBase>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const { formRef } = useFormBaseContext();
    const assignTypeData = t("leaveType.assignType", { returnObjects: true }) as Record<string, string>;
    delete assignTypeData["2"];
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    disabled={true}
                    required={true}
                    label={t("leaveType.fd.name")}
                    name="name"
                    variant="outlined"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField label={t("leaveType.fd.description")} name="description" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                    <Radios
                        name="assignType"
                        required
                        label={t("leaveType.fd.assignType")}
                        data={Object.keys(assignTypeData).map((key) => ({
                            label: assignTypeData[key as keyof typeof assignTypeData],
                            value: key,
                        }))}
                    />
                </Grid>
                <Field name="assignType">
                    {(prInputProps) => {
                        const prValue = prInputProps.input.value;
                        return (
                            <>
                                {prValue !== "0" && (
                                    <Grid container spacing={3}>
                                        {prValue === "1" && (
                                            <Grid item xs={12}>
                                                <DatePicker
                                                    label={t("leaveType.fd.startDate")}
                                                    name="annualStartDate"
                                                    inputFormat="MM/dd"
                                                    required
                                                    formRef={formRef}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </>
                        );
                    }}
                </Field>
            </Grid>
        </Grid>
    );
}

export default BaseDataFormBase;
