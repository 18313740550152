import React from "react";
import Block from "./Block";
import { Stack, Typography } from "@mui/material";

export interface RequestBlockProps {
    icon: React.ReactNode;
    title: string;
    onClick: () => void;
}

function RequestBlock({ icon, title, onClick }: RequestBlockProps) {
    return (
        <Block onClick={onClick} sx={{ cursor: "pointer" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                {icon}
                <Typography fontSize="20px" fontWeight="500" textAlign="start" sx={{ color: "#000000DE" }}>
                    {title}
                </Typography>
            </Stack>
        </Block>
    );
}

export default RequestBlock;
