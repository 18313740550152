import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LeaveHoursUnitEnum from "./LeaveHoursUnitEnum";

export interface LeaveInfoProps {
    systemHours: number;
    usedHours: number;
    adjustHours: number;
    remainHours: number;
    unit?: LeaveHoursUnitEnum;
}

interface ItemProps {
    label: string;
    value: number;
    unit: LeaveHoursUnitEnum;
}

function Item({ label, value, unit }: ItemProps) {
    const { t } = useTranslation();
    const unitsI18n = unit === LeaveHoursUnitEnum.DAYS ? "c.units.vd" : "c.units.vh";
    const mValue = unit === LeaveHoursUnitEnum.DAYS ? value / 8 : value;
    return (
        <Typography variant="subtitle1" component="p" fontWeight="400">
            {label}: {t(unitsI18n, { value: mValue })}
        </Typography>
    );
}

function LeaveInfo({
    systemHours,
    usedHours,
    remainHours,
    adjustHours,
    unit = LeaveHoursUnitEnum.HOURS,
}: LeaveInfoProps) {
    const { t } = useTranslation();
    //const value = unit === LeaveHoursUnitEnum.DAYS ? hours / 8 : hours;
    return (
        <Stack minWidth="290px">
            <Item label={t("leave.fd.systemHours")} unit={unit} value={systemHours} />
            <Item label={t("leave.fd.usedHours")} unit={unit} value={usedHours} />
            <Item label={t("leave.fd.adjustHours")} unit={unit} value={adjustHours} />
            <Item label={t("leave.fd.availableHours")} unit={unit} value={remainHours} />
        </Stack>
    );
}

export default LeaveInfo;
