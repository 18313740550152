import React, { useState, useEffect /*, useRef*/ } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from "@mui/material";
//import OrganizationChart from "@mr_kh13/react-orgchart";
import OrgTree from "react-org-tree";
//import OpenWithIcon from "@mui/icons-material/OpenWith";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { PageHeader2 as PageHeader } from "layout";
import { useFetchData2 as useFetchData } from "hooks";
import NodeTemplate from "./NodeTemplate";
import "../../css/orgchart.css";

function DepartmentOrgDialog({ ...props }) {
    const { t } = useTranslation();
    //const orgchart = useRef();
    const [showAll, setShowAll] = useState(false);
    const [dataSource, dataSourceFetch] = useFetchData(`/api/department/org`, t("department.org.title"), undefined, {});

    const handleClose = (event) => {
        props.onClose && props.onClose(event || {}, "backdropClick");
    };
    const handleShowAllBtn = () => {
        setShowAll(!showAll);
    };
    /*const handleExpandAllClick = () => {
        orgchart.current.expandAllNodes();
    };*/

    useEffect(() => {
        const params = showAll && { showAll: 1 };
        console.log(showAll);
        dataSourceFetch(true, {
            params: params,
        }).catch((error) => {
            console.log(error);
        });
    }, [showAll]);

    return (
        <Dialog {...props} fullWidth maxWidth="lg" aria-labelledby="org-dialog-title">
            <DialogTitle id="org-dialog-title">{t("department.org.title")}</DialogTitle>
            <DialogContent>
                <PageHeader
                    title=""
                    rightToolView={
                        <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                            <Grid item>
                                <Button
                                    variant={showAll ? "contained" : "outlined"}
                                    onClick={handleShowAllBtn}
                                    startIcon={showAll ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                >
                                    {showAll ? t("department.org.hideDisable") : t("department.org.showAll")}
                                </Button>
                            </Grid>
                            {/* <Grid item>
                                <Button
                                    variant="outlined"
                                    onClick={handleExpandAllClick}
                                    color="primary"
                                    startIcon={<OpenWithIcon />}
                                >
                                    {t("department.org.expandAll")}
                                </Button>
                            </Grid> */}
                        </Grid>
                    }
                />
                {/* <OrganizationChart
                    dataSource={dataSource}
                    NodeTemplate={({ nodeData }) => <NodeTemplate nodeData={nodeData} />}
                    chartClass="myChart"
                    draggable={true}
                    ref={orgchart}
                /> */}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <OrgTree
                        data={dataSource}
                        //horizontal={horizontal}
                        collapsable={true}
                        expandAll={true}
                        renderContent={(data) => <NodeTemplate nodeData={data} />}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DepartmentOrgDialog.propTypes = {
    onClose: PropTypes.func,
};

export default DepartmentOrgDialog;
