import React, {
    useImperativeHandle,
    forwardRef,
    useContext,
    Ref,
    ReactNode,
    RefObject,
    MutableRefObject,
    createContext,
    SyntheticEvent,
} from "react";
import arrayMutators from "final-form-arrays";
import { useTranslation } from "react-i18next";
import { FormApi, AnyObject, MutableState, Tools } from "final-form";
import { Form, FormProps } from "react-final-form";
import DebugJson from "./DebugJson";
import { addToast } from "helpers";
import { ModeDefineType } from "hooks/useFormData";
const develop = process.env.NODE_ENV === "development";

export interface FormBaseRefProps<FormValues = AnyObject, InitialFormValues = Partial<FormValues>> {
    onSubmit: (
        event?: Partial<Pick<SyntheticEvent, "preventDefault" | "stopPropagation">>
    ) => Promise<AnyObject | undefined> | undefined;
    change?: <F extends keyof FormValues>(name: F, value?: FormValues[F]) => void;
    form?: FormApi<FormValues, InitialFormValues>;
}

export interface FormBaseProps<FormValues = AnyObject, InitialFormValues = Partial<FormValues>>
    extends FormProps<FormValues, InitialFormValues> {
    children?: ReactNode;
    formRef?: Ref<FormBaseRefProps<FormValues, InitialFormValues>>;
    checkPristine?: boolean;
    enableSection?: string[];
    formMode?: ModeDefineType;
}

interface FormBaseDefProps<FormValues = AnyObject, InitialFormValues = Partial<FormValues>> {
    children?: ReactNode;
    onSubmit: (
        event?: Partial<Pick<SyntheticEvent, "preventDefault" | "stopPropagation">>
    ) => Promise<AnyObject | undefined> | undefined;
    form?: FormApi<FormValues, InitialFormValues>;
    ref?: Ref<FormBaseRefProps<FormValues, InitialFormValues>>;
}

export interface BaseContextValues<FormValues = AnyObject, InitialFormValues = Partial<FormValues>> {
    enableSection?: string[];
    form?: FormApi<FormValues, InitialFormValues>;
    formRef?:
        | RefObject<FormBaseRefProps<FormValues, InitialFormValues>>
        | MutableRefObject<RefObject<FormBaseRefProps<FormValues, InitialFormValues>>>;
}

function FormBaseDef<FormValues = AnyObject, InitialFormValues = Partial<FormValues>>(
    { form, onSubmit, children }: FormBaseDefProps<FormValues, InitialFormValues>,
    ref: Ref<FormBaseRefProps<FormValues, InitialFormValues>>
) {
    useImperativeHandle(
        ref,
        () => ({
            onSubmit: onSubmit,
            change: form && form.change,
            form,
        }),
        [form, onSubmit]
    );
    // not decide copy form to every child components
    // const childrenWithExtraProp = React.Children.map(children, child =>
    //     React.cloneElement(child, { form: form })
    //   );
    const childrenWithExtraProp = children;
    return (
        <form noValidate onSubmit={onSubmit} id="react-final-form-base">
            {childrenWithExtraProp || <></>}
            {develop && <DebugJson />}
        </form>
    );
}

const removeTableData = (values: AnyObject, field: string) => {
    if (Array.isArray(values[field])) {
        return values[field].map((row: AnyObject) => {
            if (typeof row === "object") {
                const mRow = { ...row };
                delete mRow.tableData;
                return mRow;
            } else {
                return row;
            }
        });
    }
};
const removeMaterialTableTableData = (values: AnyObject) => {
    for (const key in values) {
        if (Array.isArray(values[key])) {
            values[key] = removeTableData(values, key);
        }
    }
};

const BaseContext = createContext<BaseContextValues<any>>({});

const useFormBaseContext = () => {
    return useContext(BaseContext);
};

const BlockDisabled = (key: string) => {
    const context = useFormBaseContext();
    return context.enableSection && !context.enableSection.includes(key);
};

function SimpleForm<FormValues = AnyObject, InitialFormValues = Partial<FormValues>>({
    children,
    formRef,
    onSubmit,
    checkPristine = true,
    mutators,
    enableSection,
    ...props
}: FormBaseProps<FormValues, InitialFormValues>) {
    const { t } = useTranslation();

    const handleSubmit = (values: FormValues, form: FormApi<FormValues, InitialFormValues>) => {
        const formState = form.getState();
        if (!checkPristine || !formState.pristine) {
            if (onSubmit) {
                values = { ...values };
                removeMaterialTableTableData(values);
                onSubmit(values, form);
            }
        } else {
            addToast(t("c.msg.dataUnchanged"), { appearance: "warning" });
        }
    };
    const updateAll = (
        args: any,
        state: MutableState<FormValues, InitialFormValues>,
        utils: Tools<FormValues, InitialFormValues>
    ) => {
        //console.log(args, state, utils);
        utils.changeValue(state, args[0], () => args[1]);
    };
    const FormBase = forwardRef<
        FormBaseRefProps<FormValues, InitialFormValues>,
        FormBaseDefProps<FormValues, InitialFormValues>
    >((props, ref) => FormBaseDef(props, ref));
    return (
        <Form<FormValues, InitialFormValues>
            subscription={{ submitting: true, pristine: true }}
            mutators={{
                ...(arrayMutators as any),
                ...mutators,
                updateAll,
            }}
            onSubmit={handleSubmit}
            {...props}
            render={({ form, handleSubmit }) => (
                <FormBase onSubmit={handleSubmit} form={form as any} ref={formRef}>
                    <BaseContext.Provider value={{ enableSection, form, formRef: formRef as any }}>
                        {children}
                    </BaseContext.Provider>
                </FormBase>
            )}
        />
    );
}

export { useFormBaseContext };
export { BlockDisabled };
export default SimpleForm;
