import { CardReplacementRowData } from "../../pages/absence/CardReplacementSearchTable";
import WorkType from "../employee/WorkType";
import CardReplacementStatusEnum from "../attendance/CardReplacementStatusEnum";

const cardReplacementListMock: Array<CardReplacementRowData> = [
    {
        id: "41",
        date: new Date("2021-04-24"),
        direction: WorkType.GET_OF_THE_WORK,
        checkInTime: "18:00",
        applicationTime: new Date("2021-04-24"),
        status: CardReplacementStatusEnum.PASS,
    },
    {
        id: "42",
        date: new Date("2021-04-24"),
        direction: WorkType.GET_OF_THE_WORK,
        checkInTime: "18:00",
        applicationTime: new Date("2021-04-24"),
        status: CardReplacementStatusEnum.PASS,
    },
    {
        id: "43",
        date: new Date("2021-04-24"),
        direction: WorkType.GO_TO_THE_WORK,
        checkInTime: "09:00",
        applicationTime: new Date("2021-10-27 10:11"),
        status: CardReplacementStatusEnum.PASS,
    },
];

export default function mockFilterStatus(filterStatus?: CardReplacementStatusEnum) {
    if (filterStatus === undefined) {
        return cardReplacementListMock;
    }

    return cardReplacementListMock.filter(({ status }) => status === filterStatus);
}

export function getById(entryId: string): CardReplacementRowData | undefined {
    return cardReplacementListMock.find(({ id }) => id === entryId);
}
