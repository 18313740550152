import React from "react";
import { TextField } from "mui-rff";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { Grid, Card, CardHeader, CardContent, Divider, TextField as MuiTextField } from "@mui/material";
import { useFormBaseContext } from "components/final-form/";
import { DatePicker } from "components/final-form/Picks";
import { modeDef } from "hooks/useFormData";

import { useAccountVoucherFormContext } from "../AccountVoucherFormBase";
import sourceI18nMapping from "../sourceI18nMapping";

function FSBasic() {
    const { t } = useTranslation();
    const { formRef } = useFormBaseContext();
    const { consoDate, formMode, editable } = useAccountVoucherFormContext();
    const disabledForAddOnly = formMode !== modeDef.ADD || !editable;
    return (
        <Card>
            <CardHeader title={t("c.fd.secBase")} titleTypographyProps={{ variant: "h6" }} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Field name="noPrefix">
                            {({ input }) => {
                                const prefix = input.value;
                                return (
                                    <Field name="no">
                                        {({ input }) => {
                                            const no = input.value;
                                            return (
                                                <MuiTextField
                                                    label={t("accountVoucher.fd.no")}
                                                    value={prefix && no ? `${prefix}${no}` : t("c.autoGenerate")}
                                                    disabled
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    </Field>
                                );
                            }}
                        </Field>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label={t("c.fd.date")}
                            name="date"
                            disabled={disabledForAddOnly}
                            minDate={consoDate}
                            formRef={formRef}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field name="source">
                            {({ input }) => {
                                const value = input.value;
                                let stringToShow = "";
                                if (value in sourceI18nMapping) {
                                    stringToShow = t(sourceI18nMapping[value]);
                                }
                                return (
                                    <MuiTextField
                                        label={t("accountVoucher.fd.source")}
                                        value={stringToShow}
                                        disabled
                                        fullWidth
                                    />
                                );
                            }}
                        </Field>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label={t("c.fd.rmk")} name="remark" disabled={!editable} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default FSBasic;
