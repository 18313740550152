import React from "react";
import { Box } from "@mui/material";
import AuditPathItem, { AuditPathItemProps } from "./auditPath/AuditPathItem";

export interface AuditPathProps {
    items: Array<AuditPathItemProps>;
}

function AuditPath({ items }: AuditPathProps) {
    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: `repeat(${items.length * 2 - 1}, 1fr)`,
                whiteSpace: "nowrap",
            }}
        >
            {items.map((itemProps, index) => {
                return <AuditPathItem key={index} {...itemProps} />;
            })}
        </Box>
    );
}

export default AuditPath;
