import { Decorator } from "final-form";
import createDecorator from "final-form-calculate";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useMemo } from "react";
import moment from "moment";
import { MomentInput } from "moment";
import { LeaveRemain } from "components/attendance/useLeaveRemainData";
import { loadControl as LC, addToast, getUser, isCommonResponseError, commonErrorHandler } from "helpers";
import { isValidPeriod } from "helpers/dateHelper";
import { LeaveRequestFormValues } from "../formType";
import { isVVIP } from "./LeaveRequestForm";

interface LeaveRequestFormValuesExtend extends Partial<LeaveRequestFormValues> {
    remain?: number;
    hoursLimit?: number;
}

const formatTime = (date: MomentInput) => {
    return moment(date).format("YYYY-MM-DD HH:mm:") + "00";
};

function useLeaveRequestCalculator(leaveRemains: LeaveRemain[] | null) {
    const { t } = useTranslation();
    const mLeaveRemains = leaveRemains ?? [];
    const user = useMemo(() => getUser(), []);
    const getLeaveHours = async (startTime: MomentInput, endTime: MomentInput): Promise<number> => {
        const employeeId = user.employee?.id;
        if (!employeeId) {
            return 0;
        }
        LC();
        return await axios
            .get("/api/form/getHours", {
                params: {
                    start: formatTime(startTime),
                    end: formatTime(endTime),
                    id: employeeId,
                },
            })
            .then(function (response) {
                const data = response.data;
                if (!Number.isNaN(Number(data))) {
                    return Number(data);
                } else if (isCommonResponseError(data)) {
                    const extraMsg = commonErrorHandler(data);
                    console.log(extraMsg);
                }
                return 0;
            })
            .catch((err) => {
                console.log(err);
                addToast(t("c.msg.loadFailed"), { appearance: "error" });
                return 0;
            })
            .finally(() => {
                LC(false);
            });
    };
    const getRemain = (leaveTypeId: number) => {
        const selectedLeaveType = mLeaveRemains.find((item) => item.leaveTypeId === leaveTypeId);
        return selectedLeaveType?.remain || 0;
    };
    const calculator = createDecorator(
        {
            field: /leaveTypeId/,
            updates: {
                remain: (itemValue, allValues) => {
                    if (itemValue) {
                        return getRemain(itemValue);
                    }
                    return 0;
                },
            },
        },
        {
            field: /((start|end)Time)/,
            updates: {
                hours: async (itemValue, allValues) => {
                    if (isVVIP(user)) {
                        return;
                    }
                    if (allValues) {
                        const values = allValues as LeaveRequestFormValuesExtend;
                        if (values.startTime && values.endTime && isValidPeriod(values.startTime, values.endTime)) {
                            return getLeaveHours(values.startTime, values.endTime);
                        }
                    }
                    return 0;
                },
            },
        },
        {
            field: /((start|end)Time|leaveTypeId)/,
            updates: {
                hoursLimit: async (itemValue, allValues) => {
                    if (allValues) {
                        const values = allValues as LeaveRequestFormValuesExtend;
                        const remain = getRemain(values.leaveTypeId || 0);
                        if (!isVVIP(user)) {
                            return remain;
                        }
                        if (values.startTime && values.endTime && isValidPeriod(values.startTime, values.endTime)) {
                            const diff = moment(values.endTime).diff(values.startTime, "hour");
                            return Math.min(diff, remain);
                        }
                    }
                    return 0;
                },
            },
        }
    ) as Decorator<LeaveRequestFormValues>;

    return calculator;
}

export default useLeaveRequestCalculator;
