import React, { MouseEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { FormBaseRefProps } from "components";
import { useFormData } from "hooks";
import DepartmentMemberFormBase, { DepartmentMemberFormValues } from "./DepartmentMemberFormBase";

export interface DepartmentMemberFormDialogDialog extends DialogProps {
    dataId: string | null;
    onChange?: () => void;
}

function DepartmentMemberFormDialog({ onChange, onClose, dataId, ...props }: DepartmentMemberFormDialogDialog) {
    const { t } = useTranslation();
    const formRef = useRef<FormBaseRefProps<DepartmentMemberFormValues>>(null);
    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        formRef.current?.onSubmit(event);
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const formData = useFormData<DepartmentMemberFormValues>(`/api/department/member/${dataId}`, {}, null);
    const handleSubmit = async (values: DepartmentMemberFormValues) => {
        await formData
            .save(values)
            .then(async (resp) => {
                onChange && onChange();
                handleClose();
            })
            .catch(() => {
                console.log("handleSubmit catch");
            });
    };
    return (
        <Dialog {...props} aria-labelledby="department-member-form-dialog-title">
            <DialogTitle id="department-member-form-dialog-title">{t("c.add")}</DialogTitle>
            <DialogContent>
                <DepartmentMemberFormBase onSubmit={handleSubmit} formRef={formRef} dataId={dataId} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DepartmentMemberFormDialog;
