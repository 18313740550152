import { PageData } from "common";
import React, { useContext } from "react";
import { UrlPageMapping } from "layout/types";
export interface AppContextValues {
    user?: any;
    pageData?: PageData[];
    urlPageMapping?: UrlPageMapping[];
    locale?: string;
}
const AppContext = React.createContext<AppContextValues>({});
const useAppContent = () => {
    return useContext(AppContext);
};
export { AppContext, useAppContent };
export default {
    AppContext,
    useAppContent,
};
