import React, { MouseEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    Drawer,
    List,
    Box,
    Divider,
    ListItem,
    ListItemText,
    CardHeader,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    DrawerProps,
    DialogProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getUserName } from "helpers/userHelper";
import onLogout from "helpers/logout";
import { getUser } from "helpers";
import { MobileViewWrapper, AppBarSpacer, AvatarImg } from "./layoutComponents";
import langList from "./LangList";
import PageFinder from "./PageFinder";
import MessageView from "./MessageView";

function LangMenu({ onClose, ...props }: DialogProps) {
    const { t } = useTranslation();
    return (
        <Dialog onClose={onClose} {...props}>
            <DialogTitle>{t("ui.selectLang")}</DialogTitle>
            <List>
                {langList({
                    onParentClose: (e) => {
                        onClose && onClose(e, "backdropClick");
                    },
                    listMode: true,
                })}
            </List>
        </Dialog>
    );
}

function PageSearchDialog({ onClose, ...props }: DialogProps) {
    const { t } = useTranslation();
    return (
        <Dialog onClose={onClose} {...props}>
            <DialogTitle>{t("ui.search")}</DialogTitle>
            <DialogContent>
                <PageFinder
                    onClose={(e) => {
                        onClose && onClose(e, "backdropClick");
                    }}
                    hideTitle={true}
                />
            </DialogContent>
        </Dialog>
    );
}

function MsgDialog({ onClose, ...props }: DialogProps) {
    const { t } = useTranslation();
    return (
        <Dialog onClose={onClose} fullScreen {...props}>
            <DialogTitle sx={{ margin: 0, padding: (theme) => theme.spacing(2) }}>
                <Typography variant="h5">{t("ui.msg")}</Typography>
                <IconButton
                    aria-label="close"
                    onClick={(e) => {
                        onClose && onClose(e, "backdropClick");
                    }}
                    size="large"
                    sx={{
                        position: "absolute",
                        right: (theme) => theme.spacing(1),
                        top: (theme) => theme.spacing(1),
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <MessageView />
        </Dialog>
    );
}

export default function TopBarDrawer({ onClose, ...props }: DrawerProps) {
    const [langMenuOpen, setLangMenuOpen] = React.useState(false);
    const [pageSearchOpen, setPageSearchOpen] = React.useState(false);
    const [msgOpen, setMsgOpen] = React.useState(false);
    const { t } = useTranslation();
    const user = useMemo(() => getUser(), []);
    const handleLangMenuOpen = (e: MouseEvent<HTMLDivElement>) => {
        setLangMenuOpen(true);
        onClose && onClose(e, "backdropClick");
    };
    const handleLangMenuClose = () => {
        setLangMenuOpen(false);
    };
    const handlePageSearchOpen = (e: MouseEvent<HTMLDivElement>) => {
        setPageSearchOpen(true);
        onClose && onClose(e, "backdropClick");
    };
    const handlePageSearchClose = () => {
        setPageSearchOpen(false);
    };
    const handleMsgOpen = (e: MouseEvent<HTMLDivElement>) => {
        setMsgOpen(true);
        onClose && onClose(e, "backdropClick");
    };
    const handleMsgClose = () => {
        setMsgOpen(false);
    };
    return (
        <MobileViewWrapper>
            <Drawer onClose={onClose} anchor="right" {...props}>
                <AppBarSpacer />
                <Box p={1}>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <AvatarImg src={user.picture} alt="" />
                            </Avatar>
                        }
                        title={getUserName(user)}
                        subheader="Group"
                    />
                </Box>
                <Divider />
                <List component="nav" sx={{ width: 250 }}>
                    {/* <ListItem button>
                        <ListItemText primary={t("ui.accountManage")} />
                    </ListItem> */}
                    <ListItem button onClick={handlePageSearchOpen}>
                        <ListItemText primary={t("ui.search")} />
                    </ListItem>
                    <ListItem button onClick={handleMsgOpen}>
                        <ListItemText primary={t("ui.msg")} />
                    </ListItem>
                    <ListItem button onClick={handleLangMenuOpen}>
                        <ListItemText primary={t("ui.lang")} />
                    </ListItem>
                    <ListItem button onClick={onLogout}>
                        <ListItemText primary={t("c.logout")} />
                    </ListItem>
                </List>
            </Drawer>
            <LangMenu open={langMenuOpen} onClose={handleLangMenuClose} />
            <PageSearchDialog open={pageSearchOpen} onClose={handlePageSearchClose} />
            <MsgDialog open={msgOpen} onClose={handleMsgClose} />
        </MobileViewWrapper>
    );
}
