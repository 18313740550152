import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ResponseHandler } from "common";
import { useFetchData2 as useFetchData } from "hooks";

export interface LeaveRemain {
    leaveTypeId: number;
    ["leaveType.name"]: string;
    remain: number;
}

export interface LeaveRemainDataHookProps {
    autoLoad?: boolean;
    onError?: (error?: string) => void;
}

function useLeaveRemainData(props?: LeaveRemainDataHookProps) {
    const mProp = props ?? {};
    const { autoLoad = true, onError } = mProp;
    const { t } = useTranslation();
    const [leaveRemain, leaveRemainFetch, leaveRemainCleanup] = useFetchData<LeaveRemain[]>(
        `/api/employee/employeeLeave`,
        t("leave.fd.remainLeaveHours"),
        undefined,
        []
    );
    console.log(autoLoad);
    useEffect(() => {
        if (autoLoad) {
            leaveRemainFetch().catch((err) => {
                console.log(err);
                if ("raw" in err) {
                    const errorResult = err.raw as ResponseHandler;
                    onError && onError(errorResult.error);
                }
            });
            return () => {
                leaveRemainCleanup();
            };
        }
    }, [autoLoad]);
    return { leaveRemain, leaveRemainFetch, leaveRemainCleanup };
}

export default useLeaveRemainData;
