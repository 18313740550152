import React, { ChangeEvent, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, ListItem, ListItemText, Typography, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useReduxTempData, EmployeePickRow } from "hooks";
import PickerDialog, { PickerDialogProps } from "components/PickerDialog";

export interface EmployeePickerDialogProps extends Partial<PickerDialogProps<EmployeePickRow, number>> {
    onClose: () => void;
    title?: string;
    filter?: (row: EmployeePickRow) => boolean;
}

function EmployeePickerDialog({ title, filter, ...props }: EmployeePickerDialogProps) {
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState<string | null>(null);
    const listData = useReduxTempData<EmployeePickRow[]>("/api/employee/picker", t("e.emp"));
    const renderItem = (row: EmployeePickRow, onItemSelect: (item: EmployeePickRow) => void, idx: number) => {
        return (
            <ListItem
                button
                key={idx}
                onClick={() => {
                    onItemSelect(row);
                }}
            >
                <ListItemText
                    primary={`${row.employeeNo} ${row.name}`}
                    secondary={
                        <Typography component="span" variant="body2">
                            {row.mainEmployeeDepartment
                                ? `${row.mainEmployeeDepartment.department.name} ${row.mainEmployeeDepartment.workPosition.name}`
                                : ""}
                        </Typography>
                    }
                />
            </ListItem>
        );
    };

    const listToShow = useMemo<EmployeePickRow[]>(() => {
        if (listData) {
            const mList = filter ? listData.filter(filter) : listData;
            if (searchString && searchString !== "") {
                return mList.filter((row) => row.name.includes(searchString));
            } else {
                return mList;
            }
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, searchString, listData]);

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };
    return (
        <PickerDialog<EmployeePickRow>
            {...props}
            title={title || t("employee.pickerTitle").toString()}
            data={listToShow}
            renderItem={renderItem}
            idKey="id"
            formView={
                <>
                    <TextField
                        label={t("c.search")}
                        onChange={handleTextChange}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                </>
            }
        />
    );
}

export default EmployeePickerDialog;
