import { Column } from "@material-table/core";
import { InitialLeaveRowData } from "./InitialLeaveSearchTable";
import { TFunction } from "react-i18next";
import DateRenderer from "components/grid/DateRenderer";

export interface ColumnsDesc {
    type: string;
}

function InitialLeaveColumnsFactory(columnsDesc: ColumnsDesc, t: TFunction) {
    const defaultColumns: Column<InitialLeaveRowData>[] = [
        {
            title: t("員工編號"),
            field: "employeeId",
            type: "string",
        },
        {
            title: t("員工姓名"),
            field: "employeeName",
            type: "string",
        },
        {
            title: t("到職日"),
            field: "hireDate",
            type: "date",
            render: ({ hireDate }) => DateRenderer(hireDate),
        },
        {
            title: t("部門"),
            field: "department",
            type: "string",
        },
        {
            title: t("主要職位"),
            field: "mainPosition",
            type: "string",
        },
        {
            title: t("更新日期"),
            field: "updatedAt",
            type: "date",
            align: "center",
            width: "120px",
            render: ({ updatedAt }) => (updatedAt ? DateRenderer(updatedAt) : "-"),
        },
        {
            title: t("初始狀態"),
            field: "initialState",
            type: "string",
        },
    ];

    return defaultColumns;
}

export default InitialLeaveColumnsFactory;
