import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReplayIcon from "@mui/icons-material/Replay";
import { PageHeader2 as PageHeader } from "layout";
import MaterialTable, { MAccountTitleEditField } from "components/customMaterialTable";
import { useFormTable, useReduxTempData, useReduxTempDataReloadFlag, useUserPermission } from "hooks";
import { loadControl as LC, addToast } from "helpers";
import { SearchTextField } from "components";
import { Column } from "components/customMaterialTable/CustomMaterialTable";
import { AccountTitleRow } from "../accountTitle";
export interface AccountTitleSettingRow {
    settingKey: string;
    accountTitleId: number;
    conditions?: AccountTitleSettingConditions;
    remark: string;
}

export interface AccountTitleExtendedRow extends AccountTitleSettingRow {
    accountTitleName?: string;
    settingName?: string;
}

export interface AccountTitleSettingConditions {
    type?: number[];
    catalog?: number[];
}

function AccountTitleSettingList() {
    const { t } = useTranslation();
    const tableRef = useRef<any>(null);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("accountSetting"), [isWrite]);
    const { addFlag } = useReduxTempDataReloadFlag(`/api/accountTitle/setting/all/`);
    const preSubmitData = (row: AccountTitleSettingRow) => {
        //To remove unnecessary values
        const { settingKey, accountTitleId, remark } = row;
        return {
            settingKey,
            accountTitleId,
            remark,
        };
    };

    const formTable = useFormTable<AccountTitleSettingRow>("/api/accountTitle/setting", undefined, {
        allDataMode: true,
        onEdit: preSubmitData,
        onSaveSuccess: () => {
            addFlag();
        },
    });
    const accountTitles = useReduxTempData<AccountTitleRow[]>(`/api/accountTitle/data`, t("e.accountTitle"), false);
    const [showFilter, setShowFilter] = useState(false);
    //const langId = getLangId();

    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };

    const handleInitBtn = async () => {
        LC();
        await axios
            .post("/api/accountTitle/setting/init", {})
            .then(async (results) => {
                if (results.data.status === "ok") {
                    await handleDataChange();
                    addToast(t("c.msg.initSuss"), { appearance: "success" });
                }
            })
            .finally(() => {
                LC(false);
            });
    };

    const handleDataChange = useCallback(async () => {
        await formTable.fetchOnly().catch(() => {});
    }, []);

    const processedData = useMemo<AccountTitleExtendedRow[]>(() => {
        return formTable.data.map((row) => {
            let accountTitleName = "";
            const value = row.accountTitleId;
            if (accountTitles) {
                const item = accountTitles.find((row) => row.id === value);
                if (item) {
                    accountTitleName = `${item.code}-${item.name}`.trim();
                }
            }
            return {
                ...row,
                settingName: t("accountTitle.settingData." + row.settingKey),
                accountTitleName,
            };
        });
    }, [formTable.data, t]);

    useEffect(() => {
        handleDataChange();
        return () => {
            formTable.cleanup();
        };
    }, []);

    const columns: Column<AccountTitleExtendedRow>[] = [
        {
            title: "#",
            field: "settingKey",
            editable: "never",
            width: 80,
            filtering: false,
        },
        {
            title: t("c.fd.title"),
            field: "settingName",
            editable: "never",
        },
        {
            title: t("e.accountTitle"),
            field: "accountTitleId",
            editComponent: (compProps) => {
                const conditions = compProps.rowData.conditions;
                return (
                    <MAccountTitleEditField
                        {...compProps}
                        value={compProps.rowData.accountTitleId}
                        filter={(item) => {
                            if (conditions) {
                                return (
                                    conditions.catalog?.indexOf(item.catalog) !== -1 &&
                                    conditions.type?.indexOf(item.type) !== -1
                                );
                            } else {
                                return true;
                            }
                        }}
                    />
                );
            },
            render: (rowData) => {
                return rowData.accountTitleName;
            },
            filtering: false,
            searchable: false,
            long: true,
        },
        {
            title: t("e.accountTitle"),
            field: "accountTitleName",
            editable: "never",
            searchable: true,
            hidden: true,
        },
        {
            title: t("c.fd.rmk"),
            field: "remark",
            long: true,
        },
    ];

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} endIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                    </Grid>
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                        {writable && (
                            <Grid item>
                                <Button variant="outlined" onClick={handleInitBtn} endIcon={<ReplayIcon />}>
                                    {t("c.initData")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
            />
            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={processedData}
                //actions={actions}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    pageSize: 10,
                    toolbar: false,
                    filtering: showFilter,
                }}
                onCanceled={formTable.clearErrors}
                formTableErrors={formTable.errors}
                editable={
                    writable
                        ? {
                              onRowUpdate: formTable.edit,
                          }
                        : undefined
                }
            />
        </>
    );
}

export default AccountTitleSettingList;
