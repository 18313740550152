import React, { MouseEvent, useState, useMemo, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from "@mui/material";
import { useReduxTempData, useDataCUD } from "hooks";
import { AccountTitleRow } from "../accountTitle";
import AccountTitleTreePicker from "./AccountTitleTreePicker";

export interface AccountTitleAddDialog extends DialogProps {
    groupKey: string;
    usableCatalog: number[];
    existedItems: number[];
    onFinished?: () => void;
}

function AccountTitleAddDialog({
    groupKey,
    usableCatalog,
    onFinished,
    onClose,
    existedItems,
    ...props
}: AccountTitleAddDialog) {
    const { t } = useTranslation();
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const accountTitles = useReduxTempData<AccountTitleRow[]>(`/api/accountTitle/data`, t("e.accountTitle"));
    const { handleAdd } = useDataCUD();
    const handleClose = (event?: MouseEvent) => {
        setSelectedItems([]);
        onClose && onClose(event || {}, "backdropClick");
    };
    const selectableData = useMemo<AccountTitleRow[]>(() => {
        if (accountTitles) {
            return accountTitles.filter(
                (row) => usableCatalog.indexOf(row.catalog) > -1 && existedItems.indexOf(row.id) === -1
            );
        }
        return [];
    }, [accountTitles, usableCatalog, existedItems]);
    const handleItemSelect = (item: AccountTitleRow, event: ChangeEvent<HTMLInputElement>, childrenId: number[]) => {
        const check = event.target.checked;
        if (childrenId.length <= 0) {
            if (check) {
                setSelectedItems([...selectedItems, item.id]);
            } else {
                const index = selectedItems.indexOf(item.id);
                if (index > -1) {
                    selectedItems.splice(index, 1);
                }
                setSelectedItems([...selectedItems]);
            }
        } else {
            if (check) {
                childrenId.forEach((itemId) => {
                    if (selectedItems.indexOf(itemId) === -1) {
                        selectedItems.push(itemId);
                    }
                });
            } else {
                childrenId.forEach((itemId) => {
                    const index = selectedItems.indexOf(itemId);
                    if (index > -1) {
                        selectedItems.splice(index, 1);
                    }
                });
            }
            setSelectedItems([...selectedItems]);
        }
    };
    const handleSubmit = () => {
        if (selectedItems.length > 0) {
            handleAdd({ data: selectedItems }, `/api/accountTitle/group/${groupKey}`)
                .then(() => {
                    onFinished && onFinished();
                })
                .catch(() => {});
        }
        handleClose();
        //console.log(selectedItems);
    };
    return (
        <Dialog onClose={onClose} maxWidth="lg" fullWidth {...props} aria-labelledby="account-title-add-dialog-title">
            <DialogTitle id="account-title-addDataKey-dialog-title">{t("c.add")}</DialogTitle>
            <Divider />
            <DialogContent>
                <AccountTitleTreePicker
                    data={selectableData}
                    onItemSelected={handleItemSelect}
                    selectedItems={selectedItems}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    {t("c.add")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AccountTitleAddDialog;
