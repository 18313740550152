const zh = {
    body: {
        emptyDataSourceMessage: "查無資料",
        addTooltip: "新增",
        deleteTooltip: "刪除",
        editTooltip: "修改",
        filterRow: {
            filterTooltip: "過濾",
        },
        editRow: {
            deleteText: "確定要刪除?",
            cancelTooltip: "取消",
            saveTooltip: "儲存",
        },
    },
    grouping: {
        placeholder: "拖曳欄位標題至此",
        groupedBy: "分組依照：",
    },
    header: {
        actions: "功能",
        selectAll: "全選",
    },
    pagination: {
        labelDisplayedRows: "第{from}～{to}筆資料，共{count}筆",
        labelRowsSelect: "筆",
        labelRowsPerPage: "每頁資料筆數",
        firstAriaLabel: "第一頁",
        firstTooltip: "第一頁",
        previousAriaLabel: "上一頁",
        previousTooltip: "上一頁",
        nextAriaLabel: "下一頁",
        nextTooltip: "下一頁",
        lastAriaLabel: "最末頁",
        lastTooltip: "最末頁",
    },
    toolbar: {
        addRemoveColumns: "新增移除欄位",
        nRowsSelected: "已選擇{0}筆資料",
        showColumnsTitle: "顯示欄位",
        showColumnsAriaLabel: "顯示欄位",
        exportTitle: "匯出",
        exportAriaLabel: "匯出",
        exportName: "匯出為CVS檔",
        searchTooltip: "搜尋",
        searchPlaceholder: "搜尋",
    },
};

export default zh;
