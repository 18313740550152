import React, { useState, useContext, useEffect, useMemo, useRef, createContext, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { TextField, makeValidate } from "mui-rff";
import { FieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    TableContainer,
    Table,
    Paper,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { ResponseHandler } from "common";
import { TimestampRowData, useFetchData2 as useFetchData, useDataCUD, useUserPermission } from "hooks";
import { FormBase, FormBaseRefProps } from "components";
import { TextItem } from "components/final-form";

export interface RuleTableRowData extends TimestampRowData {
    assignDays: number;
    duration: number;
    unit: number;
}

export interface RuleTableFormValues {
    data: RuleTableRowData[];
}

interface FormContextValue {
    isEditMode: boolean;
}

const FormContext = createContext<FormContextValue>({
    isEditMode: false,
});

const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

function RuleTable() {
    const { t } = useTranslation();
    const formRef = useRef<FormBaseRefProps<RuleTableFormValues>>(null);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const { handleEdit } = useDataCUD();
    const [rulesData, rulesDataFetch, rulesDataCleanup] = useFetchData<ResponseHandler<RuleTableRowData[]>>(
        "/api/leaveType/annualLeaveRules",
        "",
        undefined,
        { status: "ok", result: [] }
    );
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("leaveType"), [isWrite]);
    const handleEditButton = () => {
        setIsEditMode(true);
    };
    const handleSaveButton = () => {
        formRef.current && formRef.current.onSubmit();
    };
    const handleCancelButton = () => {
        rulesDataFetch();
        setIsEditMode(false);
    };
    const handleSubmit = async (values: RuleTableFormValues) => {
        handleEdit(values, "/api/leaveType/annualLeaveRules")
            .then(() => {
                handleCancelButton();
            })
            .catch(() => {});
    };
    const schema: SchemaOf<RuleTableFormValues> = yup
        .object()
        .shape({
            data: yup
                .array<RuleTableRowData>()
                .of(
                    yup.object().shape({
                        duration: yup.number().required(),
                        unit: yup.number().required(),
                        assignDays: yup.number().label(t("leaveType.fd.assignDays")).min(0).required(),
                    }) as any
                )
                .required(),
        })
        .defined();
    const validate = makeValidate(schema);
    useEffect(() => {
        rulesDataFetch().catch((err) => {
            console.log(err);
        });
        return () => {
            rulesDataCleanup();
        };
    }, []);
    const actionComponents = useMemo<ReactNode>(() => {
        if (!writable) return <></>;
        return isEditMode ? (
            <Grid container>
                <Grid item>
                    <IconButton aria-label="save" onClick={handleSaveButton} size="large">
                        <DoneIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton aria-label="save" onClick={handleCancelButton} size="large">
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
        ) : (
            <Grid container>
                <Grid item>
                    <IconButton aria-label="edit" onClick={handleEditButton} size="large">
                        <EditIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }, [isEditMode, writable]);
    return (
        <Card>
            <CardHeader
                title={t("leaveType.rule")}
                titleTypographyProps={{ variant: "h6" }}
                action={actionComponents}
            />
            <Divider />
            <CardContent>
                <FormBase<RuleTableFormValues>
                    validate={validate}
                    initialValues={{ data: rulesData?.result || [] }}
                    onSubmit={handleSubmit}
                    formRef={formRef}
                >
                    <FormContext.Provider value={{ isEditMode }}>
                        <FormContent />
                    </FormContext.Provider>
                </FormBase>
            </CardContent>
        </Card>
    );
}

function FormContent() {
    return (
        <FieldArray<RuleTableRowData> name="data">
            {(fieldArrayProps) => {
                return <FieldArrayInner fieldArrayProps={fieldArrayProps} />;
                //return <Grid container spacing={3}></Grid>;
            }}
        </FieldArray>
    );
}

type RTFieldArrayRenderProps = FieldArrayRenderProps<RuleTableRowData, HTMLElement>;
interface FieldArrayInnerProps {
    fieldArrayProps: RTFieldArrayRenderProps;
}

interface FieldProps {
    name: string;
    index: number;
}

function FieldArrayInner({ fieldArrayProps }: FieldArrayInnerProps) {
    const fields = fieldArrayProps.fields;
    const fieldNameArrays = useMemo<FieldProps[][]>(() => {
        const mData: FieldProps[][] = [[], []];
        if (fields && fields.length && fields.length > 0) {
            const dividedDataSize = Math.ceil(fields.length / 2);
            fields.forEach((name, index) => {
                mData[Math.floor(index / dividedDataSize)].push({
                    name,
                    index,
                });
            });
            return mData;
        }
        return mData;
    }, [fields]);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TableBase fields={fieldNameArrays[0]} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TableBase fields={fieldNameArrays[1]} />
            </Grid>
        </Grid>
    );
}

interface TableBaseProps {
    fields: FieldProps[];
}

function TableBase({ fields }: TableBaseProps) {
    const { t } = useTranslation();
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="right">{t("leaveType.fd.duration")}</TableCell>
                        <TableCell>{t("leaveType.fd.unit")}</TableCell>
                        <TableCell align="right" style={{ width: "200px" }}>
                            {t("leaveType.fd.assignDays")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field) => (
                        <DataItemRow {...field} key={field.name} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function DataItemRow({ name }: FieldProps) {
    const { t } = useTranslation();
    const unitData = t("leaveType.aUnit", { returnObjects: true });
    const { isEditMode } = useFormContext();
    return (
        <TableRow>
            <TableCell>
                <TextItem name={`${name}.duration`} align="right" />
            </TableCell>
            <TableCell>
                <TextItem
                    name={`${name}.unit`}
                    render={(value) => <Typography component="span">{unitData[value.toString()]}</Typography>}
                />
            </TableCell>
            <TableCell>
                {isEditMode ? (
                    <TextField
                        variant="standard"
                        name={`${name}.assignDays`}
                        inputProps={{ min: 0, max: 99, style: { textAlign: "right" } }}
                    />
                ) : (
                    <TextItem name={`${name}.assignDays`} align="right" />
                )}
            </TableCell>
        </TableRow>
    );
}

//function

export default RuleTable;
