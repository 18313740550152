import { MomentInput } from "moment";
import { dateFormat } from "helpers";

export default function DateRenderer(date?: MomentInput) {
    if (date) {
        return dateFormat(date, "YYYY-MM-DD");
    }

    return "";
}

export function DateTimeRenderer(date?: MomentInput) {
    if (date) {
        return dateFormat(date, "YYYY-MM-DD HH:mm:ss");
    }

    return "";
}

export function DateTimeRendererShort(date?: MomentInput) {
    if (date) {
        return dateFormat(date, "YYYY-MM-DD HH:mm");
    }

    return "";
}
