import React, { useCallback, useEffect, useContext, useRef, useState, createContext, MouseEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import FormBase, { FormBaseProps, FormBaseRefProps, useFormBaseContext } from "components/final-form/FormBase";
import { Card, CardContent, CardHeader, Divider, Grid, InputAdornment, Button } from "@mui/material";
import * as yup from "yup";
import axios from "axios";
import { SchemaOf } from "yup";
import { StatusSwitch } from "components/final-form";
import { Radios, TextField, makeValidate } from "mui-rff";
import { DatePicker } from "components/final-form/Picks";
import { formatNumberInput } from "components/CommonNumberFormat";
import { Field } from "react-final-form";
import { useFormData, useReduxTempDataReloadFlag, useUserPermission } from "hooks";
import { PageHeader2 as PageHeader } from "layout";
import moment, { Moment } from "moment";
import { addDataKey } from "helpers/formHelper";
import { addToast } from "helpers";

export interface LeaveTypeFormValue {
    id: string;
    status?: boolean;
    name: string;
    percentage: number;
    description?: string;
    applicable: number | string; //適用對象
    assignHours: number; //給假時數
    minUnit: number; //請假最小時數
    usedLimitHours: number; //使用限制
    assignType: number | string; //給假方式 (0:到職日到職前一日 1:年度 2: 申請制)
    annualStartDate: string | Moment;
    expirationDays?: number; //申請後之使用期限
    createdAt?: string;
    updatedAt?: string;
    type: number;
}

export const defaultInitialValues: Partial<LeaveTypeFormValue> = {
    annualStartDate: moment.utc().month(0).date(1).hour(0).minute(0).second(0).millisecond(0),
    status: true,
    type: 0,
    applicable: "0",
    assignType: "0",
};

interface FormContextValue {
    isEditMode: number;
    writable: boolean;
}

const FormContext = createContext<FormContextValue>({
    isEditMode: 1,
    writable: false,
});
const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

function BasicLeaveTypeForm({ onSubmit, ...props }: FormBaseProps<LeaveTypeFormValue>) {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [isEditMode, setIsEditMode] = useState<number>(1);
    const { addFlag } = useReduxTempDataReloadFlag(`api/leaveType/custom`);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("leaveType"), [isWrite]);

    const goBack = (event?: MouseEvent) => {
        history.push({ pathname: "/leaveType/" });
    };
    const formData = useFormData<LeaveTypeFormValue>("/api/leaveType", defaultInitialValues, id, (data) => {
        if (data) {
            console.log("data", data);
            data.applicable = data.applicable.toString();
            data.assignType = data.assignType.toString();
            data.annualStartDate = moment.utc(data.annualStartDate);
            console.log("data.annualStartDate 2: ", data.annualStartDate.toString());
            console.log("moment.utc: ", moment.utc("1970-01-01").toString());

            if (data.type === 0) {
                setIsEditMode(0);
            }
        }
    });
    const fetchData = useCallback(async () => {
        if (Number(id)) {
            await formData
                .fetch()
                //.then(() => {})
                .catch((err) => {
                    if (!formData.isCancel(err)) {
                        goBack(); //if data not found
                        console.log(err);
                    }
                });
        } else if (id === undefined) {
            setIsEditMode(0);
        } else {
            goBack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        fetchData();
        return () => {
            formData.cleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, id]);

    const validateDuplicate = async (name: string, id?: number) => {
        return axios
            .post(`/api/leaveType/checkDuplicate`, { name, leaveTypeId: id || undefined })
            .then((response) => {
                if (response.data.isExisted) {
                    addToast(t("leaveType.msg.duplicationName"), { appearance: "error" });
                    return false;
                } else {
                    return true;
                }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
    };

    const handleSubmit = async (values: LeaveTypeFormValue) => {
        if (values.type === 0) {
            const nameValidation = await validateDuplicate(values.name, values.id ? Number(values.id) : undefined);
            if (!nameValidation) {
                return;
            }
        }
        const mValues = {
            ...values,
            applicable: Number(values.applicable),
            assignType: Number(values.assignType),
        };
        addDataKey(mValues, "description");
        await formData
            .save(mValues)
            .then((resp) => {
                if (values.type === 0) {
                    addFlag();
                }
                goBack();
                handleCancel();
            })
            .catch((err) => {
                console.log(err.name);
            });
    };
    const handleCancel = async () => {
        formData.fetch();
    };
    const handleSave = async () => {
        formRef.current && formRef.current.onSubmit();
    };
    const formRef = useRef<FormBaseRefProps<LeaveTypeFormValue>>(null);
    const schema: SchemaOf<LeaveTypeFormValue> = yup
        .object()
        .shape({
            name: yup.string().label(t("leaveType.fd.name")).required(),
            percentage: yup.number().label(t("leaveType.fd.percentage")).min(0).max(9999).required().integer(),
            assignHours: yup.number().label(t("leaveType.fd.assignHours")).min(0).max(2920).required().integer(),
            applicable: yup.string().label(t("leaveType.fd.applicable")).oneOf(["0", "1", "2"]).required(),
            minUnit: yup
                .number()
                .label(t("leaveType.fd.minUnit"))
                .when("assignHours", (val: string | number, schema: yup.NumberSchema) => {
                    if (!val || val === "" || val <= 0) {
                        return schema.required().min(0.5).max(2920);
                    } else {
                        return schema.required().min(0.5).max(Number(val));
                    }
                }),
            usedLimitHours: yup.number().label(t("leaveType.fd.usedLimitHours")).min(0).max(248).required(),
            assignType: yup.string().label(t("leaveType.fd.assignType")).oneOf(["0", "1", "2"]).required(),
            expirationDays: yup
                .number()
                .label(t("leaveType.fd.expirationDays"))
                .when("assignType", (val: string | number, schema: yup.NumberSchema) => {
                    if ([1, 2].indexOf(Number(val)) > -1) {
                        return schema.required().min(0).max(365);
                    }
                    return schema.nullable();
                }),
        })
        .defined();
    const validate = makeValidate(schema);
    return (
        <>
            <PageHeader
                title=""
                rightToolView={
                    <Grid container spacing={3} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <Button variant="outlined" onClick={goBack}>
                                {t("c.backpage")}
                            </Button>
                        </Grid>
                        {writable && (
                            <Grid item>
                                <Button variant="outlined" onClick={handleSave}>
                                    {t("c.save")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
            />
            <FormBase<LeaveTypeFormValue>
                validate={validate}
                initialValues={formData.data}
                onSubmit={handleSubmit}
                formRef={formRef}
                {...props}
            >
                <FormContext.Provider value={{ isEditMode, writable }}>
                    <FormContent />
                </FormContext.Provider>
            </FormBase>
        </>
    );
}
function FormContent() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <LeaveNameForm />
            </Grid>
            <Grid item xs={12} md={6}>
                <Applicable />
            </Grid>
            <Grid item xs={12} md={6}>
                <UnitLimitForm />
            </Grid>
            <Grid item xs={12} md={6}>
                <AnnualStartDateFrom />
            </Grid>
        </Grid>
    );
}

function LeaveNameForm() {
    const { isEditMode, writable } = useFormContext();
    const { t } = useTranslation();
    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <StatusSwitch disabled={!writable} name="status" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled={isEditMode !== 0 || !writable}
                            required={true}
                            label={t("leaveType.fd.name")}
                            name="name"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required={true}
                            label={t("leaveType.fd.percentage")}
                            name="percentage"
                            variant="outlined"
                            fullWidth
                            disabled={!writable}
                            InputProps={{
                                ...formatNumberInput(),
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t("leaveType.fd.description")}
                            name="description"
                            variant="outlined"
                            fullWidth
                            disabled={!writable}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

function Applicable() {
    const { t } = useTranslation();
    const { writable } = useFormContext();
    const applicableData = t("leaveType.applicable", { returnObjects: true }) as Record<string, string>;
    return (
        <Card>
            <CardHeader title={t("leaveType.fd.applicable")} titleTypographyProps={{ variant: "h6" }} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Radios
                            name="applicable"
                            required
                            disabled={!writable}
                            data={Object.keys(applicableData).map((key) => ({
                                label: applicableData[key as keyof typeof applicableData],
                                value: key,
                            }))}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

function UnitLimitForm() {
    const { t } = useTranslation();
    const { writable } = useFormContext();
    return (
        <Card>
            <CardHeader title={t("leaveType.fd.limitHours")} titleTypographyProps={{ variant: "h6" }} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <TextField
                            required={true}
                            label={t("leaveType.fd.assignHours2")}
                            name="assignHours"
                            variant="outlined"
                            fullWidth
                            disabled={!writable}
                            InputProps={{
                                ...formatNumberInput(),
                                endAdornment: <InputAdornment position="end">{t("c.units.h")}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            required={true}
                            label={t("leaveType.fd.minUnit")}
                            name="minUnit"
                            variant="outlined"
                            fullWidth
                            disabled={!writable}
                            InputProps={{
                                ...formatNumberInput(),
                                endAdornment: <InputAdornment position="end">{t("c.units.h")}</InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            required={true}
                            label={t("leaveType.fd.usedLimitHours2")}
                            name="usedLimitHours"
                            variant="outlined"
                            fullWidth
                            disabled={!writable}
                            InputProps={{
                                ...formatNumberInput(),
                                endAdornment: (
                                    <InputAdornment position="end">{t("leaveType.units.h/m")}</InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

function AnnualStartDateFrom() {
    const { t } = useTranslation();
    const { formRef } = useFormBaseContext();
    const { writable } = useFormContext();
    const assignTypeData = t("leaveType.assignType", { returnObjects: true }) as Record<string, string>;
    return (
        <Card>
            <CardHeader title={t("leaveType.fd.assignType")} titleTypographyProps={{ variant: "h6" }} />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid item xs={12} md={6}>
                            <Radios
                                name="assignType"
                                required
                                disabled={!writable}
                                data={Object.keys(assignTypeData).map((key) => ({
                                    label: assignTypeData[key as keyof typeof assignTypeData],
                                    value: key,
                                }))}
                            />
                        </Grid>
                        <Field name="assignType">
                            {(prInputProps) => {
                                const prValue = prInputProps.input.value;
                                return (
                                    <>
                                        {prValue !== "0" && (
                                            <Grid container spacing={3}>
                                                {prValue === "1" && (
                                                    <Grid item xs={12} md={6}>
                                                        <DatePicker
                                                            label={t("leaveType.fd.startDate")}
                                                            name="annualStartDate"
                                                            inputFormat="MM/dd"
                                                            required
                                                            disabled={!writable}
                                                            formRef={formRef}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} md={prValue === "2" ? 12 : 6}>
                                                    {(prValue === "2" || prValue === "1") && (
                                                        <TextField
                                                            required={true}
                                                            label={t(
                                                                "leaveType.fd.expirationDays" +
                                                                    ((prValue === "2" && "2") || "")
                                                            )}
                                                            name="expirationDays"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled={!writable}
                                                            InputProps={{
                                                                ...formatNumberInput(),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {t("c.units.d")}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )}
                                    </>
                                );
                            }}
                        </Field>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default BasicLeaveTypeForm;
