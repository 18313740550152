import React from "react";
import { useTranslation } from "react-i18next";
import { FaUserTie } from "react-icons/fa";
import MaterialTable, { Column, CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { TimestampRowData, useDataCUD, useFormTable, useTimestampColumns } from "hooks";
import { IconButton } from "@mui/material";

export interface DepartmentMemberRowData extends TimestampRowData {
    flag: boolean;
    isSupervisor: boolean;
    employee: {
        id: number;
        employeeNo: string;
        name: string;
    };
    workPosition: {
        id: number;
        name: string;
    };
}

export interface DepartmentMemberTableProps
    extends Omit<CustomMaterialTableProps<DepartmentMemberRowData>, "data" | "columns"> {
    dataId: string | null;
    writable?: boolean;
}

function DepartmentMemberTable({
    dataId,
    tableRef,
    writable = false,
    options = {},
    ...props
}: DepartmentMemberTableProps) {
    const formTable = useFormTable<DepartmentMemberRowData>(`/api/department/member/${dataId}`);
    const { t } = useTranslation();
    const timestampColumns = useTimestampColumns<DepartmentMemberRowData>({ updatedAt: false });
    const crud = useDataCUD();
    const handlePost = (employeeId: number, isSupervisor: boolean) => {
        crud.handleEdit(
            {
                employeeId,
                isSupervisor,
            },
            `/api/department/member/${dataId}`
        )
            .then(() => {
                tableRef?.current?.onQueryChange();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const columns: Column<DepartmentMemberRowData>[] = [
        //部門主管
        {
            title: t("department.fd.supervisor"),
            field: "isSupervisor",
            render: (row) => {
                const iconColor = row.isSupervisor ? "primary" : "default";
                return (
                    <IconButton
                        color={iconColor}
                        size="medium"
                        onClick={() => {
                            writable && handlePost(row.employee.id, !row.isSupervisor);
                        }}
                    >
                        <FaUserTie />
                    </IconButton>
                );
            },
        },
        {
            title: t("employee.fd.employeeNo"),
            field: "employee.employeeNo",
            type: "string",
        },
        {
            title: t("employee.fd.name"),
            field: "employee.name",
            type: "string",
        },
        {
            title: t("e.workPosition"),
            field: "workPosition.name",
            type: "string",
        },
        ...timestampColumns,
    ];

    return (
        <MaterialTable<DepartmentMemberRowData>
            {...props}
            columns={columns}
            data={formTable.fetch()}
            tableRef={tableRef}
            options={{
                //pageSizeOptions: [5, 10, 20],
                paging: false,
                toolbar: false,
                ...options,
            }}
        />
    );
}

export default DepartmentMemberTable;
