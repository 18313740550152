import { AppearanceTypes } from "react-toast-notifications";
import DEF from "../def";
export const GetTempDataAction = (url: string, name: string, useLC: boolean = true) => {
    return {
        type: DEF.GET_TEMPDATA,
        name,
        url,
        useLC,
    };
};
export const UpdateTempDataAction = (apiPath: string, value: any) => {
    return {
        type: DEF.UPDATE_TEMPDATA,
        apiPath,
        value,
    };
};
export interface ToastInfoType {
    appearance: AppearanceTypes;
}
export interface PopToastMessageBody {
    e: any;
    name: string;
    type?: ToastInfoType;
}
export const PopToastMessage = (value: PopToastMessageBody) => {
    return {
        type: DEF.POP_TOAST_MESSAGE,
        value,
    };
};
export interface MessageReadBody {
    id: number;
}
export const ReadMessage = (value: MessageReadBody) => {
    return {
        type: DEF.READ_MESSAGE,
        value,
    };
};

export default { GetTempDataAction, UpdateTempDataAction, PopToastMessage, ReadMessage };
