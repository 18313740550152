import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { EmployeePickRow, useEmployeeData, useFetchData2 as useFetchData } from "hooks";
import { StdQueryListResult } from "common";
import { DepartmentRowData } from "pages/department/DepartmentSearchTable";
import { addToast } from "helpers";

export interface EmployeeFilterProps {
    onApply: (employee: number | undefined) => void;
}

export interface SelectControlEntry {
    id: number;
    label: string;
}

interface SelectControlProps<T> {
    id: string;
    label: string;
    onChange: (entryId: T) => void;
    entries: Array<SelectControlEntry>;
    selected?: T;
}

function SelectControl({ id, label, onChange, entries, selected }: SelectControlProps<number>) {
    const isSelected = (value: number) => {
        return selected !== undefined && value === selected;
    };

    return (
        <FormControl fullWidth>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                id={id}
                labelId={`${id}-label`}
                label={label}
                onChange={(event: SelectChangeEvent) => onChange(parseInt(event.target.value))}
                sx={{ minWidth: "200px" }}
                defaultValue=""
            >
                <MenuItem key="" value="" />
                {_.map(entries, ({ id, label }) => {
                    return (
                        <MenuItem key={id} value={id} selected={isSelected(id)}>
                            {label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

function EmployeeFilter({ onApply }: EmployeeFilterProps) {
    const { t } = useTranslation();
    // TODO: move state up to keep departments and employees selected between tabs
    const [department, setDepartment] = useState<number>();
    const [employee, setEmployee] = useState<number>();

    const handleButtonClick = () => {
        onApply(employee);
    };

    const [departments, setDepartments] = useState<Array<SelectControlEntry>>([]);
    const [departmentData, departmentDataFetch, departmentDataCleanup] = useFetchData<
        StdQueryListResult<DepartmentRowData>
    >("/api/department/list?limit=100", t("e.depart"), undefined, {
        count: 0,
        data: [],
    });
    useEffect(() => {
        departmentDataFetch().catch((err) => {
            console.error(err);
            if ("code" in err && err.code === "202") {
                addToast(t("department.err.mag"), { appearance: "error" });
            }
        });
        return () => {
            departmentDataCleanup();
        };
    }, []);

    useEffect(() => {
        if (departmentData?.data) {
            const departmentList = departmentData.data.map(({ id, name }) => {
                return {
                    id: parseInt(id),
                    label: name,
                } as SelectControlEntry;
            });
            setDepartments(departmentList);
        }
    }, [departmentData?.data]);

    const [fullEmployeeList] = useEmployeeData<EmployeePickRow[]>();
    const filteredEmployeeList = useMemo<SelectControlEntry[]>(() => {
        if (department) {
            return fullEmployeeList
                .filter((employee) => employee.mainEmployeeDepartment?.departmentId === department)
                .map(({ id, name }) => {
                    return {
                        id,
                        label: name,
                    } as SelectControlEntry;
                });
        }
        return [];
    }, [department, departments]);

    return (
        <Grid
            container
            direction={{ xs: "column", md: "row" }}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <SelectControl
                    id="department"
                    label={t("e.depart")}
                    onChange={setDepartment}
                    entries={departments}
                    selected={department}
                />
            </Grid>
            <Grid item>
                <SelectControl
                    id="employee"
                    label={t("e.emp")}
                    onChange={setEmployee}
                    entries={filteredEmployeeList}
                    selected={employee}
                />
            </Grid>
            <Grid item>
                <Button type="submit" color="primary" variant="contained" size="medium" onClick={handleButtonClick}>
                    {t("c.search")}
                </Button>
            </Grid>
        </Grid>
    );
}

export default EmployeeFilter;
