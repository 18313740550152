import React, { ChangeEvent, useMemo } from "react";
import { Checkbox } from "@mui/material";
import { AccountTitleRow } from "./AccountTitleTreeItem";
import { useAccountTitleTreeContext } from "./AccountTitleTreeView";
import { findChildren } from "./utils";

export interface ItemCheckBoxProps {
    row: AccountTitleRow;
}

function ItemCheckBox({ row }: ItemCheckBoxProps) {
    const { selectedItems, onItemSelected } = useAccountTitleTreeContext();
    const allSelectableChildrenId: number[] = useMemo<number[]>(() => {
        if ([0, 1, 2].indexOf(row.type) > -1 && row.children && row.children.length > 0) {
            const childrenRows = findChildren(row);
            return childrenRows.filter((child) => [3, 4].indexOf(child.type) > -1).map((child) => child.id);
        }
        return [];
    }, [row]);
    const [checked, indeterminate] = useMemo<[boolean, boolean]>(() => {
        if ([3, 4].indexOf(row.type) > -1) {
            return [selectedItems.indexOf(row.id) > -1, false];
        } else if (row.children && row.children.length > 0) {
            const [mChecked, mIndeterminate] = allSelectableChildrenId.reduce<[boolean, boolean]>(
                (previousData, currentChildrenId) => {
                    const [pChecked, pIndeterminate] = previousData;
                    const isCurrentChildrenSelected = selectedItems.indexOf(currentChildrenId) > -1;
                    return [pChecked && isCurrentChildrenSelected, pIndeterminate || isCurrentChildrenSelected];
                },
                [true, false]
            );
            return [mChecked, mChecked ? false : mIndeterminate];
        }
        return [false, false];
    }, [allSelectableChildrenId, selectedItems, row]);
    const handleChanged = (event: ChangeEvent<HTMLInputElement>) => {
        onItemSelected && onItemSelected(row, event, allSelectableChildrenId);
    };
    return <Checkbox checked={checked} indeterminate={indeterminate} onChange={handleChanged} />;
}

export default ItemCheckBox;
