import React from "react";
import { Column } from "@material-table/core";
import { TFunction } from "react-i18next";
import DateRenderer, { DateTimeRenderer } from "components/grid/DateRenderer";
import { CardReplacementRowData } from "./CardReplacementSearchTable";
import WorkType from "components/employee/WorkType";
import CardReplacementStatus from "components/attendance/CardReplacementStatus";

export interface ColumnsDesc {
    type: string;
    formAction: (rowId: string) => void;
}

function CardReplacementColumnsFactory(columnsDesc: ColumnsDesc, t: TFunction) {
    function renderDirection(direction: WorkType) {
        if (direction === WorkType.GO_TO_THE_WORK) {
            return t("上班");
        }
        return t("下班");
    }

    const defaultColumns: Column<CardReplacementRowData>[] = [
        {
            title: t("日期"),
            field: "date",
            type: "date",
            render: ({ date }) => DateRenderer(date),
        },
        {
            title: t("補卡時段"),
            field: "direction",
            type: "string",
            render: ({ direction }) => renderDirection(direction),
        },
        {
            title: t("打卡時間"),
            field: "checkInTime",
            type: "string",
        },
        {
            title: t("申請時間"),
            field: "applicationTime",
            type: "date",
            render: ({ date }) => DateTimeRenderer(date),
        },
        {
            title: t("狀態"),
            field: "status",
            type: "string",
            render: ({ status }) => <CardReplacementStatus statusId={status} />,
        },
    ];

    return defaultColumns;
}

export default CardReplacementColumnsFactory;
