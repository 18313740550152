import React, { MouseEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { FormBaseRefProps } from "components/final-form/FormBase";
import ClockRequestForm from "components/audit/requestContent/ClockRequestForm";

export interface ClockRequestFormDialogProps extends DialogProps {}

function ClockRequestFormDialog({ open, onClose, ...props }: ClockRequestFormDialogProps) {
    const formRef = useRef<FormBaseRefProps<any>>(null);
    const { t } = useTranslation();

    const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };

    const handleSubmit = async (values: any) => {
        // TODO: to submit application
    };

    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    return (
        <Dialog {...props} open={open} onClose={onClose} aria-labelledby="form-application-dialog-title">
            <DialogTitle id="form-application-dialog-title">{t(`form.title.clockRequest`)}</DialogTitle>
            <DialogContent>
                <ClockRequestForm onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" variant="contained" onClick={handleConfirm}>
                    {t("c.submit")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ClockRequestFormDialog;
