import { Breakpoint } from "@mui/material";
import { zhTW as zhTW_orig, ja } from "date-fns/locale";

export const drawerWidth = 260;
export const langGrp = { zh: "zh-TW", ja: "ja-JP" };
export default {
    drawerWidth,
    langGrp,
};

export const menuBreakPoint: Breakpoint = "sm";

export const pmBreakPoint: Breakpoint = "md";

//Overwrite the zhTW Locale to set weekStartsOn
const zhTW: Locale = {
    ...zhTW_orig,
    options: {
        ...zhTW_orig.options,
        weekStartsOn: 0,
    },
};

export const localeDateFns: Record<string, any> = {
    "zh-TW": zhTW,
    ja: ja,
};
