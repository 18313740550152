import * as yup from "yup";
import { SchemaOf } from "yup";
import { AccountTitleSettingData } from "hooks";
import { TFunction } from "react-i18next";
import { AccountTitleRow } from "../accountTitle";
import { InitialFormValues } from "./InitialFormBase";

function YupSchemaFactory(
    data: AccountTitleRow[],
    t: TFunction,
    accountTitleSettings: AccountTitleSettingData
): SchemaOf<InitialFormValues> {
    const titleFieldShapes: Record<string, any> = {};
    data.filter((row) => [3, 4].indexOf(row.type) > -1).forEach((row) => {
        const fieldName = `title${row.id}`;
        const label = row.name;
        if (row.negative) {
            titleFieldShapes[fieldName] = yup.number().label(label).max(0);
        } else {
            titleFieldShapes[fieldName] = yup.number().label(label).min(0);
        }
    });
    const plTitle = accountTitleSettings.pl;
    if (plTitle) {
        const fieldName = `title${plTitle.id}`;
        titleFieldShapes[fieldName] = titleFieldShapes[fieldName].equals([yup.ref("currentPl")]);
        console.log(fieldName);
    }
    return yup
        .object()
        .shape({
            yearMonth: yup.date().label(t("accountConsolidation.fd.initYearMonth")).required(),
            consolidationMethodType: yup.number().label(t("accountSetting.fd.consolidationMethodType")).required(),
            undistributed: yup.number().label(t("accountConsolidation.fd.undistributed")).required(),
            distributed: yup.number().label(t("accountConsolidation.fd.distributed")).required(),
            currentPl: yup
                .number()
                .label(t("accountTitle.settingData.pl"))
                .required()
                .when("distributed", (distributed, schema0) => {
                    return schema0.when("undistributed", (undistributed: any, schema1: any) => {
                        return schema1.test({
                            test: (value: number) => {
                                return value === distributed + undistributed;
                            },
                            message: t("accountSetting.msg.distributedSumError"),
                        });
                    });
                }),
            accumulated: yup.number().label(t("accountConsolidation.fd.accumulated")).required(),
            liabilityEquityTotal: yup.number(),
            assetTotal: yup
                .number()
                .equals([yup.ref("liabilityEquityTotal")], t("accountSetting.msg.initialDataNotBalance")),
            ...titleFieldShapes,
        })
        .defined();
}

export default YupSchemaFactory;
