import React from "react";
import { Box, Grid, MenuItem } from "@mui/material";
import { TextField, Select } from "mui-rff";
import { Moment } from "moment";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormBaseContext } from "components/final-form/";
import { DatePicker } from "components/final-form/Picks";
import { formatNumberInput } from "components/CommonNumberFormat";
import { modeDef, ModeDefineType } from "hooks/useFormData";

export interface UniformInvoiceRow {
    code: string;
    formatType: number;
    date: Date | Moment;
    amount: number;
    tax: number;
    sellerTaxId: string;
    buyerTaxId: string;
    taxType: number;
    allowance: boolean;
}

export const invoiceInitialData: Partial<UniformInvoiceRow> = {
    code: "",
    formatType: 1,
    allowance: false,
};

export const useUniformInvoiceSchema = () => {
    const { t } = useTranslation();
    return {
        code: yup
            .string()
            .label(t("uniformInvoice.fd.code"))
            .matches(/[A-Z]{2}[0-9]{8}/, t("uniformInvoice.err.codeInvalid"))
            .required(),
        date: yup.date().label(t("uniformInvoice.fd.date")).required(),
        amount: yup.number().label(t("uniformInvoice.fd.amount")).required(),
        tax: yup.number().label(t("uniformInvoice.fd.tax")).required(),
        //sellerTaxId: yup.string().label(t("uniformInvoice.fd.sellerTaxId")).required(),
        //buyerTaxId: yup.string().label(t("uniformInvoice.fd.buyerTaxId")).required(),
    };
};
export interface UniformInvoiceFormContentProps {
    taxType: number; // 0:in 1:ex
    editable: boolean;
    formMode: ModeDefineType;
}

function UniformInvoiceFormContent({ taxType, editable, formMode }: UniformInvoiceFormContentProps) {
    const { t } = useTranslation();
    const { formRef } = useFormBaseContext();
    //const { editable, formMode } = useAccountVoucherFormContext();
    const disabledForAddOnly = formMode !== modeDef.ADD || !editable;
    const typeData = t("uniformInvoice.formatType", { returnObjects: true }) as Record<string, string>;
    return (
        <Box>
            <Grid item xs={12}>
                <Select
                    name="uniformInvoice.formatType"
                    label={t("uniformInvoice.fd.formatType")}
                    variant="outlined"
                    disabled={disabledForAddOnly}
                    required
                >
                    {Object.keys(typeData).map((a, i) => {
                        return (
                            <MenuItem key={i} value={Number(a)}>
                                {typeData[a]}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label={t("uniformInvoice.fd.code")}
                        name="uniformInvoice.code"
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        disabled={!editable}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={t("uniformInvoice.fd.amount")}
                        name="uniformInvoice.amount"
                        variant="outlined"
                        InputProps={formatNumberInput()}
                        inputProps={{ maxLength: 8 }}
                        disabled={disabledForAddOnly}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label={t("uniformInvoice.fd.tax")}
                        name="uniformInvoice.tax"
                        variant="outlined"
                        InputProps={formatNumberInput()}
                        inputProps={{ maxLength: 8 }}
                        disabled={disabledForAddOnly}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label={t("uniformInvoice.fd.date")}
                        name="uniformInvoice.date"
                        disabled={disabledForAddOnly}
                        formRef={formRef}
                        required
                    />
                </Grid>
                {taxType === 1 && (
                    <Grid item xs={12}>
                        <TextField
                            label={t("uniformInvoice.fd.sellerTaxId")}
                            name="uniformInvoice.sellerTaxId"
                            variant="outlined"
                            inputProps={{ maxLength: 8 }}
                            disabled={!editable}
                            required
                        />
                    </Grid>
                )}
                {taxType === 0 && (
                    <Grid item xs={12}>
                        <TextField
                            label={t("uniformInvoice.fd.buyerTaxId")}
                            name="uniformInvoice.buyerTaxId"
                            variant="outlined"
                            inputProps={{ maxLength: 8 }}
                            disabled={!editable}
                            required
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export default UniformInvoiceFormContent;
