import React, { MouseEvent, useMemo } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { MenuItem, ListItem, ListItemText } from "@mui/material";
import { getUser } from "helpers";
//import { useReduxTempData } from "hooks";

export interface LangListProps {
    onParentClose?: (e: MouseEvent) => void;
    listMode?: boolean;
}

export interface LangData {
    id: number;
    lang: string;
    name: string;
}

const langLabels: Record<string, string> = {
    "zh-TW": "繁體中文",
    "ja-JP": "日本語",
    "en-US": "English",
};

export default function LangList({ listMode = false, onParentClose }: LangListProps) {
    const { i18n } = useTranslation();
    //const langs = useReduxTempData<LangData[]>(`/api/language`);
    const user = useMemo(() => getUser(), []);
    const getLangLabel = (lang: string) => (lang in langLabels ? langLabels[lang] : "");

    const handleLangChange = (lang: string) => (e: MouseEvent) => {
        Cookies.set("lang", lang);
        if (i18n.language !== lang) {
            i18n.changeLanguage(lang);
        }
        onParentClose && onParentClose(e);
    };

    if (!user.lang) {
        return [];
    } else {
        if (!listMode) {
            return user.lang.list.map((lang) => (
                <MenuItem onClick={handleLangChange(lang)} key={lang} disabled={i18n.language === lang}>
                    {getLangLabel(lang)}
                </MenuItem>
            ));
        }
        return user.lang.list.map((lang) => (
            <ListItem button onClick={handleLangChange(lang)} key={lang} disabled={i18n.language === lang}>
                <ListItemText primary={getLangLabel(lang)} />
            </ListItem>
        ));
    }
}
