import md5 from "crypto-js/md5";
import * as colors from "@mui/material/colors";
import { StandardUser } from "common";

const colorSet = [
    colors.red,
    colors.deepOrange,
    colors.orange,
    colors.yellow,
    colors.amber,
    colors.lime,
    colors.green,
    colors.teal,
    colors.cyan,
    colors.blue,
    colors.indigo,
    colors.deepPurple,
    colors.purple,
    colors.pink,
    colors.brown,
    colors.grey,
];

export const emptyUser: StandardUser = {
    userId: -1,
    email: "anonymous@example.com",
    name: "anonymous",
    account: "anonymous",
    taxIdNumber: "00000000",
    company: {
        id: -1,
        name: "no name",
        expiration: new Date(),
    },
    strategy: "",
    resetPassword: false,
    roles: [],
    permissions: [],
    employeeId: -1,
};

export const getUserName = (user: StandardUser) => {
    return (user && user.name) || emptyUser.name;
};

export const getUserId = (user: StandardUser) => {
    return (user && user.userId) || emptyUser.userId;
};

export const getAbbr = (user: StandardUser) => {
    const userName = getUserName(user);
    return userName
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
};

export const getAvatarColor = (user: StandardUser) => {
    const hash = md5(getUserId(user) + getUserName(user)).toString();
    return colorSet[parseInt(hash.substring(0, 1), 16)][800];
};
