import React from "react";
import { ToastProps } from "react-toast-notifications";
import { Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AlertToast = ({ children, onDismiss, appearance }: ToastProps) => {
    return (
        <Alert
            elevation={6}
            variant="filled"
            sx={{ marginTop: (theme) => theme.spacing(1), zIndex: 2000, whiteSpace: "pre" }}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        onDismiss();
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            severity={appearance}
        >
            {children}
        </Alert>
    );
};

export default AlertToast;
