import React, { forwardRef, ReactNode, Ref, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    SlideProps,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface StandaloneConfirmDialogProps {
    title: string;
    content: ReactNode;
}

export interface StandaloneConfirmDialogRef {
    open: (agreeAction?: () => void, disagreeAction?: () => void) => void;
}

interface Actions {
    agreeAction?: () => void;
    disagreeAction?: () => void;
}

function StandaloneConfirmDialog(
    { title, content }: StandaloneConfirmDialogProps,
    ref: Ref<StandaloneConfirmDialogRef>
) {
    const { t } = useTranslation();
    useImperativeHandle(
        ref,
        () => ({
            open: (agreeAction, disagreeAction) => {
                handleOpen(agreeAction, disagreeAction);
            },
        }),
        []
    );
    const [open, setOpen] = React.useState(false);
    const [action, setAction] = React.useState<Actions>({});
    // const [disagreeAction, setDisAgreeAction] = React.useState(() => {});
    const handleOpen = (agreeAction?: () => void, disagreeAction?: () => void) => {
        setAction({ agreeAction, disagreeAction });
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                {typeof content === "string" ? (
                    <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>
                ) : (
                    content
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        action.disagreeAction && action.disagreeAction();
                        handleClose();
                    }}
                    color="secondary"
                >
                    {t("c.cancel")}
                </Button>
                <Button
                    onClick={() => {
                        action.agreeAction && action.agreeAction();
                        handleClose();
                    }}
                    color="primary"
                >
                    {t("c.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const StandaloneConfirmDialogBase = forwardRef(StandaloneConfirmDialog);

export default StandaloneConfirmDialogBase;
