import React from "react";
import { Chip, ChipProps, IconButton, InputAdornment, styled } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const StyledChip = styled(Chip)<ChipProps>(({ theme }) => ({
    "&": {
        color: "#000000DE",
        borderColor: "#005DEB80",
    },
    "& .MuiChip-label": {
        paddingLeft: theme.spacing(2),
        paddingRight: 0,
    },
    "& .MuiIconButton-edgeEnd": {
        marginRight: 0,
        color: "#005DEB80",
    },
}));

export interface CompanyAvatarProps {
    name: string;
    onClose: () => void;
}

export default function CompanyAvatar({ name, onClose }: CompanyAvatarProps) {
    const { t } = useTranslation();

    return (
        <StyledChip
            variant="outlined"
            label={name}
            onDelete={onClose}
            deleteIcon={
                <InputAdornment position="end">
                    <IconButton
                        aria-label={t("change company")}
                        edge="end"
                        sx={{ paddingRight: "6px" }}
                        onClick={() => onClose()}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
}
