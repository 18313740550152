import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { ClockInRow } from "components/clockIn";

interface AttendanceRowTextProps {
    row: ClockInRow;
    latest?: boolean;
}

function AttendanceRowText({ row, latest = false }: AttendanceRowTextProps) {
    const date = moment.utc(`${row.date} ${row.time}`).local().format("YYYY/MM/DD(dd) HH:mm:ss"); //handle timezone problem
    const { t } = useTranslation();
    const itemKey = latest ? "newestClockInTime" : row.dutyType === 1 ? "clockInTime" : "clockOffTime";
    return (
        <>
            {t(`attendance.fd.${itemKey}`)}
            {t("c.colon")}
            {date}
            <Box component="span" ml={2}>
                {t("attendance.fd.ip")}
                {t("c.colon")}
                {row.ip}
            </Box>
        </>
    );
}

export default AttendanceRowText;
