import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Action } from "@material-table/core";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import { StandaloneConfirmDialog, StandaloneConfirmDialogRef, SearchTextField } from "components";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import { useDataCUD, useUserPermission } from "hooks";
import { PageHeader2 as PageHeader } from "layout";
import UserSearchTable, { UserRowData } from "./UserSearchTable";
import UserFormDialog from "./UserFormDialog";
import PasswordResetDialog from "./PasswordResetDialog";
import PasswordViewerDialog, { PasswordViewerDialogRef } from "./PasswordViewerDialog";

function UserList() {
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const { t } = useTranslation();
    const crud = useDataCUD();
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("user"), [isWrite]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [formDataId, setFormDataId] = useState<string | null>(null);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [passwordResetOpen, setPasswordResetOpen] = useState<boolean>(false);
    const tableRef = useRef<any>(null);
    const passwordViewerDialogRef = useRef<PasswordViewerDialogRef>(null);

    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };
    const handleAdd = async () => {
        setFormDataId(null);
        setFormOpen(true);
    };
    const handleEdit = async (row: UserRowData) => {
        setFormDataId(row.id);
        setFormOpen(true);
    };
    const handleFormClose = () => {
        setFormOpen(false);
    };
    const refreshTable = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };

    const handlePasswordReset = async (row: UserRowData) => {
        setFormDataId(row.id);
        setPasswordResetOpen(true);
    };

    const handlePasswordResetClose = () => {
        setPasswordResetOpen(false);
    };

    const actions: ActionType<UserRowData>[] = writable
        ? [
              (rowData: UserRowData): Action<UserRowData> => {
                  return {
                      icon: "edit",
                      tooltip: t("c.edit"),
                      disabled: rowData.flag === 1,
                      onClick: (e: any, rowData) => {
                          if (!Array.isArray(rowData)) {
                              handleEdit(rowData);
                          }
                      },
                  };
              },
          ]
        : [];

    const extraActions: ActionType<UserRowData>[] = writable
        ? [
              (rowData: UserRowData): Action<UserRowData> => {
                  return {
                      icon: "build",
                      tooltip: t("user.func.resetPassword"),
                      disabled: rowData.flag === 1 || rowData.loginType !== 0,
                      onClick: (e: any, rowData) => {
                          if (!Array.isArray(rowData)) {
                              handlePasswordReset(rowData);
                          }
                      },
                  };
              },
              (rowData: UserRowData): Action<UserRowData> => {
                  return {
                      icon: "delete",
                      tooltip: t("c.del"),
                      disabled: rowData.flag === 1,
                      onClick: (e: any, rowData) => {
                          const action = () => {
                              if (!Array.isArray(rowData)) {
                                  crud.handleDel({}, `/api/user/${rowData.id}`).then(() => {
                                      tableRef.current.onPageChange(e, 0);
                                  });
                              }
                          };
                          dialogRef.current && dialogRef.current.open(action);
                      },
                  };
              },
          ]
        : [];

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    <Grid container spacing={1}>
                        {writable && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        handleAdd();
                                    }}
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} startIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />

            <UserSearchTable
                tableRef={tableRef}
                actions={actions}
                extraActions={extraActions}
                options={{
                    filtering: showFilter,
                }}
            />
            <UserFormDialog
                dataId={formDataId}
                onClose={handleFormClose}
                onChange={refreshTable}
                open={formOpen}
                passwordViewerDialogRef={passwordViewerDialogRef}
            />
            <PasswordResetDialog
                dataId={formDataId}
                onClose={handlePasswordResetClose}
                open={passwordResetOpen}
                passwordViewerDialogRef={passwordViewerDialogRef}
            />
            <PasswordViewerDialog ref={passwordViewerDialogRef} />
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
        </>
    );
}

export default UserList;
