import React from "react";
import TextItem, { TextItemProps } from "./TextItem";

export interface TextLookupItemProps extends Omit<TextItemProps, "render"> {
    lookup: Record<string, String>;
}

function TextLookupItem({ lookup, ...props }: TextLookupItemProps) {
    return (
        <TextItem
            {...props}
            render={(value) => (value !== undefined && value !== null && value in lookup ? lookup[value] : "")}
        />
    );
}

export default TextLookupItem;
