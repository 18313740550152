import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, match, NavLink } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MdiIcon from "@mdi/react";
import { mdiFileSearch } from "@mdi/js";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import BasicLeaveTable, { LeaveRowData } from "./BasicLeaveTable";
import { PageHeader2 as PageHeader } from "layout";
import { SearchTextField, StandaloneConfirmDialog, StandaloneConfirmDialogRef } from "components";
import { useDataCUD, useUserPermission } from "hooks";

interface LeaveTypeFormProps {
    match: match;
}

function BasicList({ match }: LeaveTypeFormProps) {
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const { t } = useTranslation();
    const history = useHistory();
    const crud = useDataCUD();
    const tableRef = useRef<any>(null);
    const { path } = match;
    const handleEdit = async (row: LeaveRowData) => {
        history.push({ pathname: `/leaveType/basic/${row.id}` });
    };
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("leaveType"), [isWrite]);
    const actions: ActionType<LeaveRowData>[] = [
        {
            icon: writable ? "edit" : () => <MdiIcon path={mdiFileSearch} size={1} />,
            tooltip: writable ? t("c.edit") : t("c.view"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    handleEdit(rowData);
                }
            },
        },
    ];

    const extraActions: ActionType<LeaveRowData>[] = writable
        ? [
              (rowData: LeaveRowData) => ({
                  icon: "delete",
                  tooltip: t("c.del"),
                  onClick: (e: any) => {
                      const action = () => {
                          if (!Array.isArray(rowData)) {
                              crud.handleDel({}, `/api/leaveType/${rowData.id}`)
                                  .then(() => {
                                      tableRef.current.onPageChange(e, 0);
                                  })
                                  .catch((err) => {
                                      console.log(err);
                                  });
                          }
                      };
                      dialogRef.current && dialogRef.current.open(action);
                  },
                  disabled: rowData.type !== 0,
              }),
          ]
        : [];

    return (
        <>
            {writable && (
                <PageHeader
                    title=""
                    leftToolView={
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    component={NavLink}
                                    to={`${path}/basic/add`}
                                    startIcon={<AddIcon />}
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    rightToolView={
                        <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                            <Grid item>
                                <SearchTextField tableRef={tableRef} />
                            </Grid>
                        </Grid>
                    }
                />
            )}
            <BasicLeaveTable actions={actions} extraActions={extraActions} tableRef={tableRef} />
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
        </>
    );
}

export default BasicList;
