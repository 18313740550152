import React, { ReactNode } from "react";
import { Action, Components } from "@material-table/core";
import { Box } from "@mui/material";
import ExtraActions from "./ExtraActions";
import { ActionType } from "./CustomMaterialTable";

interface FunctionAction<RowData extends object> {
    position: string;
    action: (rowData: RowData) => Action<RowData>;
}
export interface CustomActionsProps<RowData extends object> {
    actions: (Action<RowData> | FunctionAction<RowData>)[];
    extraActions?: (Action<RowData> | ((rowData: RowData) => Action<RowData>))[];
    data: RowData | RowData[];
    disabled: boolean;
    size: string;
    components: Components;
}

function CustomActions<RowData extends object>({
    actions = [],
    extraActions = [],
    data,
    disabled,
    size,
    components,
}: CustomActionsProps<RowData>) {
    let resultActions: ReactNode[] = [];
    if (components.Action) {
        const ActionTag = components.Action;
        if (actions && actions.length > 0) {
            const mainActions = actions.map((action, index) => {
                let mAction: ActionType<RowData> | null = null;
                if ("position" in action && action.position === "row" && "action" in action) {
                    mAction = (action as FunctionAction<RowData>).action;
                } else if ("icon" in action || typeof action === "function") {
                    mAction = action;
                }
                if (mAction) {
                    return (
                        <ActionTag
                            key={`action-${index}`}
                            action={mAction}
                            data={data}
                            size={size}
                            disabled={disabled}
                        />
                    );
                }
                return <React.Fragment key={`action-${index}`}></React.Fragment>;
            });
            resultActions = [...resultActions, ...mainActions];
        }
    }
    if (extraActions && extraActions.length > 0) {
        const mExtraActions = (
            <ExtraActions key="action-extra" extraActions={extraActions} data={data} size={size} disabled={disabled} />
        );
        resultActions = [...resultActions, mExtraActions];
    }
    return (
        <Box display="flex" color="primary.main">
            {resultActions}
        </Box>
    );
}

export default CustomActions;
