import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { getErrorByCode } from "helpers";
import { RootState } from "./types";

export default function ReduxToast() {
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const toast = useSelector((state: RootState) => state.toast);
    useEffect(() => {
        // console.log("redux toast", toast);
        if (Object.keys(toast).length > 0) {
            try {
                const extraMsg = getErrorByCode(toast.e);
                const msg = toast.name;
                addToast(`${msg} ${extraMsg}`, toast.type);
            } catch (e) {
                addToast(`${toast.name} `, toast.type);
            }
        }
    }, [toast, t, addToast]);
    return <></>;
}
