import { FormBase } from "components";
import React, { ReactNode } from "react";
import { FormApi } from "final-form";

export interface DetailsBaseProps<FormValues = any> {
    value: Partial<FormValues>;
    children?: ReactNode;
}

function DetailsBase<FormValues = any>({ value, children }: DetailsBaseProps<FormValues>) {
    return (
        <FormBase<FormValues>
            initialValues={value}
            onSubmit={function (values: FormValues, form: FormApi<FormValues>) {
                throw new Error("Function not implemented.");
            }}
        >
            {children}
        </FormBase>
    );
}

export default DetailsBase;
