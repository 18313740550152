import React from "react";
import { Field } from "react-final-form";
import { TextField } from "mui-rff";
import { FaExternalLinkAlt } from "react-icons/fa";
import { InputAdornment, IconButton } from "@mui/material";
const expression =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const regex = new RegExp(expression);
function SiteField(props) {
    return (
        <>
            <Field {...props}>
                {({ input }) => {
                    const showUrlBtn = input.value !== "" && input.value.match(regex);
                    const handleUrlOpen = () => {
                        window.open(input.value);
                    };
                    return (
                        <TextField
                            {...props}
                            variant="outlined"
                            InputProps={{
                                endAdornment: showUrlBtn && (
                                    <InputAdornment position="end" onClick={handleUrlOpen}>
                                        <IconButton size="large">
                                            <FaExternalLinkAlt />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    );
                }}
            </Field>
        </>
    );
}
export default SiteField;
