import React, { useEffect, useState } from "react";
import Block from "./Block";
import { Stack, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";

export interface AuditBlockProps {
    icon: React.ReactNode;
    title: string;
    count: number;
    onClick: () => void;
}

function AuditBlock({ icon, title, count, onClick }: AuditBlockProps) {
    const [badgeColor, setBadgeColor] = useState<string>("error");

    useEffect(() => {
        if (count > 0) {
            setBadgeColor("#F44336");
        } else {
            setBadgeColor("#00000042");
        }
    }, [count]);

    return (
        <Block onClick={onClick} sx={{ cursor: "pointer" }}>
            <Stack justifyContent="space-between" alignItems="center" direction="row" spacing={2}>
                <Badge
                    badgeContent={count}
                    showZero={true}
                    max={99}
                    sx={{
                        "& .MuiBadge-badge": {
                            fontSize: 16,
                            lineHeight: 3,
                            height: "24px",
                            width: "24px",
                            bgcolor: badgeColor,
                            color: "#fff",
                            borderRadius: "50%",
                            transform: "initial",
                            position: "initial",
                            marginLeft: "0.5em",
                        },
                    }}
                >
                    <Typography fontSize="20px" fontWeight="500" textAlign="start" sx={{ color: "#000000DE" }}>
                        {title}
                    </Typography>
                </Badge>
                {icon}
            </Stack>
        </Block>
    );
}

export default AuditBlock;
