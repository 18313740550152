import React, { useEffect, createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Box } from "@mui/material";
import { Radios, makeValidate } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { ResponseHandler } from "common";
import { useFetchData2 as useFetchData } from "hooks";
import { AlertWithAuthLink } from "components";
import FormBase, { FormBaseProps } from "components/final-form/FormBase";
import FlowStepsFields from "./FlowStepsFields";

export interface StepsData {
    step: number;
    signerId?: number;
}

export interface BasicApprovalFormValues {
    employeeId: number | null;
    type: number | string;
    steps: StepsData[];
}

export const defaultInitialValues: Partial<BasicApprovalFormValues> = {
    type: "0",
    steps: [],
};

interface FormContextValue {
    supervisorId: number;
}

export const FormContext = createContext<FormContextValue>({
    supervisorId: -1,
});

export const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};
export interface BasicApprovalFormBaseProps extends FormBaseProps<BasicApprovalFormValues> {
    dataId: null | number;
    initialValues: Partial<BasicApprovalFormValues>;
}

function BasicApprovalFormBase({
    dataId,
    initialValues = defaultInitialValues,
    onSubmit,
    ...props
}: BasicApprovalFormBaseProps) {
    const { t } = useTranslation();
    const [isSupervisorLoad, setIsSupervisorLoad] = useState<boolean>(false);
    const [supervisorIdData, SupervisorIdDataFetch, fetchSupervisorCleanup] = useFetchData<ResponseHandler<number>>(
        `/api/employee/supervisor/${dataId}`,
        t("department.fd.supervisor")
    );
    const supervisorId = supervisorIdData?.result;
    const schema: SchemaOf<BasicApprovalFormValues> = yup
        .object()
        .shape({
            type: yup.string().label(t("approval.fd.type")).oneOf(["0", "1", "2", "3"]).required(),
            steps: yup
                .array<StepsData>()
                .of(yup.object().shape({ signerId: yup.number().label(t("approval.fd.signer")).required() }) as any)
                .required(),
        })
        .defined();
    const validate = makeValidate(schema);
    useEffect(() => {
        setIsSupervisorLoad(false);
        SupervisorIdDataFetch()
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsSupervisorLoad(true);
            });
        return () => {
            fetchSupervisorCleanup();
        };
    }, [dataId]);
    return (
        <FormBase<BasicApprovalFormValues>
            validate={validate}
            initialValues={initialValues}
            onSubmit={onSubmit}
            {...props}
        >
            {(!supervisorId || supervisorId === -1) && isSupervisorLoad && (
                <Box mb={1}>
                    <AlertWithAuthLink
                        severity="warning"
                        authLinkProps={{
                            permissionPage: "employee",
                            to: `/employee/${dataId}`,
                        }}
                        linkLabel={t("c.goToSet")}
                    >
                        {t("approval.msg.noSupervisor")}
                    </AlertWithAuthLink>
                </Box>
            )}
            <FormContext.Provider value={{ supervisorId: supervisorId || -1 }}>
                <FormContent />
            </FormContext.Provider>
        </FormBase>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const flowType = t("approval.type", { returnObjects: true }) as Record<string, string>;
    const { supervisorId } = useFormContext();
    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Radios
                        radioGroupProps={{ row: true }}
                        label={t("approval.fd.type")}
                        name="type"
                        required
                        data={Object.keys(flowType).map((key, index) => ({
                            label: flowType[key as keyof typeof flowType],
                            value: key,
                            disabled: supervisorId === -1 && [1, 2].indexOf(index) > -1,
                        }))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FlowStepsFields name="steps" />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BasicApprovalFormBase;
