import axios, { AxiosResponse } from "axios";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { getErrorByCode } from "helpers/formHelper";
import { addToast, loadControl as LC } from "helpers";
import { ResponseHandler } from "common";

/**
 * Hooks for data creating, updating and delete.
 *
 * method:
 * handleAdd() create
 * handleEdit() update
 * handleDel() delete
 */
function useDataCUD<FormValues = Record<string, any>>() {
    const { t } = useTranslation();
    const mountedRef = useRef(true);
    const source = axios.CancelToken.source();
    const onCanceled = (err?: any) => {
        const msg = t("c.msg.loadFailed");
        err && console.log(err);
        addToast(msg, { appearance: "error" });
    };
    /**
     * create data
     * @param {Object} data data which will be added to database
     * @param {String} urlpath api url path
     */
    const handleAdd = async (data: Partial<FormValues>, urlpath: string): Promise<ResponseHandler<FormValues>> => {
        mountedRef.current = true;
        LC();
        return await axios
            .post(urlpath, data, { cancelToken: source.token })
            .then((result: AxiosResponse<ResponseHandler<FormValues>>) => {
                if (mountedRef.current) {
                    if ("status" in result.data && result.data.status === "ok") {
                        addToast(t("c.msg.saveSuccess"), { appearance: "success" });
                        return result.data;
                    } else {
                        const extraMsg = getErrorByCode(result.data as any);
                        addToast(t("c.msg.saveFailed") + extraMsg, { appearance: "error" });
                    }
                }
                throw new Error();
            })
            .catch((err) => {
                console.log(err);
                if (mountedRef.current) {
                    if (axios.isCancel(err)) {
                        onCanceled();
                    } else {
                        addToast(t("c.msg.severError"), { appearance: "error" });
                    }
                }
                throw err;
            })
            .finally(() => {
                LC(false);
            });
    };

    /**
     * update data
     * @param {Object} data data which will be updated and saved to database
     * @param {String} urlpath api url path
     */
    const handleEdit = async (data: Partial<FormValues>, urlpath: string): Promise<ResponseHandler<FormValues>> => {
        mountedRef.current = true;
        LC();
        return await axios
            .put(urlpath, data, { cancelToken: source.token })
            .then((result: AxiosResponse<ResponseHandler<FormValues>>) => {
                if (mountedRef.current) {
                    if ("status" in result.data && result.data.status === "ok") {
                        addToast(t("c.msg.editSuccess"), { appearance: "success" });
                        return result.data;
                    } else {
                        const extraMsg = getErrorByCode(result.data as any);
                        addToast(t("c.msg.editFailed") + extraMsg, { appearance: "error" });
                        throw new Error(extraMsg);
                    }
                }
                throw new Error();
            })
            .catch((err) => {
                if (mountedRef.current) {
                    if (axios.isCancel(err)) {
                        onCanceled();
                    } else {
                        console.log(err);
                        addToast(t("c.msg.severError"), { appearance: "error" });
                    }
                }
                throw err;
            })
            .finally(() => {
                LC(false);
            });
    };

    /**
     * delete data
     * @param {Object} data data which will be deleted from database
     * @param {String} urlpath api url path
     */
    const handleDel = async (data: Partial<FormValues>, urlpath: string): Promise<ResponseHandler<FormValues>> => {
        mountedRef.current = true;
        LC();
        return await axios
            .delete(urlpath, { data: { data }, cancelToken: source.token })
            .then((result: AxiosResponse<ResponseHandler<FormValues>>) => {
                if (mountedRef.current) {
                    if ("status" in result.data && result.data.status === "ok") {
                        addToast(t("c.msg.delSuccess"), { appearance: "success" });
                        return result.data;
                    } else {
                        const extraMsg = getErrorByCode(result.data as any);
                        addToast(t("c.msg.delFailed") + extraMsg, { appearance: "error" });
                        throw new Error(extraMsg);
                    }
                } else {
                    throw new Error();
                }
            })
            .catch((err) => {
                if (mountedRef.current) {
                    if (axios.isCancel(err)) {
                        onCanceled();
                    } else {
                        console.log(err);
                        addToast(t("c.msg.delFailed"), { appearance: "error" });
                    }
                }
                throw err;
            })
            .finally(() => {
                LC(false);
            });
    };

    return {
        handleAdd: handleAdd,
        handleEdit: handleEdit,
        handleDel: handleDel,
        onCanceled,
        mountedRef,
        source,
        isCancel: axios.isCancel,
    };
}

export default useDataCUD;
