import { AccountTitleRow } from "./AccountTitleTreeItem";

export function findParentItems(data: AccountTitleRow[]): number[] {
    const nodeIdSet = new Set<number>();
    if (data) {
        data.forEach((row) => {
            row.l0 && nodeIdSet.add(row.l0);
            row.l1 && nodeIdSet.add(row.l1);
            row.l2 && nodeIdSet.add(row.l2);
            row.l3 && nodeIdSet.add(row.l3);
        });
    }
    return [...nodeIdSet];
}

export function findChildren(row: AccountTitleRow): AccountTitleRow[] {
    //const childrenList = [];
    if (row.children && row.children.length > 0) {
        const subChildrenList = row.children.reduce<AccountTitleRow[]>(
            (previousData, currentRow) => [...previousData, ...findChildren(currentRow)],
            []
        );
        return [...row.children, ...subChildrenList];
    }
    return [];
}
