import React, { useState, forwardRef, Ref, useImperativeHandle, useRef, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { makeValidate, TextField } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormBase, FormBaseRefProps } from "components";
import { addToast, loadControl as LC } from "helpers";

export interface AccountVoucherCancelDialogRef {
    open: (id: number) => void;
}

export interface AccountVoucherCancelDialogProps {
    onFinished?: () => void;
}

interface AccountVoucherFormValues {
    reason: string;
}

function AccountVoucherCancelDialogBase(
    { onFinished }: AccountVoucherCancelDialogProps,
    ref: Ref<AccountVoucherCancelDialogRef>
) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [dataId, setDataId] = useState<number | null>(null);
    const formRef = useRef<FormBaseRefProps<AccountVoucherFormValues>>(null);
    const handleOpen = (id: number) => {
        setOpen(true);
        setDataId(id);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useImperativeHandle(
        ref,
        () => ({
            open: (id: number) => {
                handleOpen(id);
            },
        }),
        []
    );
    const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
        formRef.current?.onSubmit(event);
    };
    const handleSubmit = async (values: AccountVoucherFormValues) => {
        LC();
        axios
            .post("/api/accountVoucher/cancel", { ...values, id: dataId })
            .then(function (response) {
                const data = response.data;
                if ("status" in data && data.status === "ok") {
                    addToast(t("c.msg.cancelSuccess"), { appearance: "success" });
                    onFinished && onFinished();
                    handleClose();
                } else {
                    addToast(t("c.msg.cancelFailed"), { appearance: "error" });
                }
            })
            .catch((err) => {
                addToast(t("c.msg.cancelFailed"), { appearance: "error" });
                console.log(err);
            })
            .finally(() => {
                LC(false);
            });
    };

    const schema: SchemaOf<AccountVoucherFormValues> = yup
        .object()
        .shape({
            reason: yup.string().label(t("c.fd.cancelReason")).max(255).required(),
        })
        .defined();
    const validate = makeValidate(schema);
    return (
        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{t("c.doCancel")}</DialogTitle>
            <DialogContent>
                <Stack>
                    <FormBase<AccountVoucherFormValues>
                        validate={validate}
                        initialValues={{ reason: "" }}
                        onSubmit={handleSubmit}
                        formRef={formRef}
                    >
                        <TextField
                            name="reason"
                            inputProps={{ maxLength: 255 }}
                            label={t("accountVoucher.msg.inputReason")}
                        />
                    </FormBase>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    {t("c.cancel")}
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    {t("c.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const AccountVoucherCancelDialog = forwardRef(AccountVoucherCancelDialogBase);

export default AccountVoucherCancelDialog;
