import React, { useState, ReactNode, createContext, useContext, forwardRef, Ref, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardContent, CardHeader, Box } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface ExpendableCardProps {
    title?: string & ReactNode;
    children?: ReactNode;
    initialExpended?: boolean;
    hideHeaderAction?: boolean;
}

export interface ExpendableCardContextValues {
    isExpanded: boolean;
}

export interface ExpendableCardRef {
    setExpanded: (isExpanded: boolean) => void;
}

const initialContextValues: ExpendableCardContextValues = {
    isExpanded: false,
};

const ExpendableCardContext = createContext<ExpendableCardContextValues>(initialContextValues);

export function useExpendableCard() {
    return useContext(ExpendableCardContext);
}

function ExpendableCardDef(
    { title, children, initialExpended, hideHeaderAction = false }: ExpendableCardProps,
    ref: Ref<ExpendableCardRef>
) {
    const [isExpanded, setExpanded] = useState<boolean>(initialExpended || false);
    const { t } = useTranslation();
    const handleExpendButtonClick = () => {
        setExpanded(!isExpanded);
    };
    useImperativeHandle(
        ref,
        () => ({
            setExpanded: (mIsExpanded) => {
                //console.log(mIsExpanded);
                setExpanded(mIsExpanded);
            },
        }),
        []
    );
    return (
        <Card variant="outlined" sx={{ padding: (theme) => theme.spacing(2) }}>
            <CardHeader
                sx={{ margin: 0 }}
                action={
                    hideHeaderAction ? (
                        <></>
                    ) : (
                        <Box color="primary.main">
                            <Button
                                sx={{ color: "inherit" }}
                                variant="outlined"
                                onClick={handleExpendButtonClick}
                                aria-label="expend"
                                endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            >
                                {isExpanded ? t("c.expendClose") : t("c.expendOpen")}
                            </Button>
                        </Box>
                    )
                }
                title={title}
            />
            <CardContent>
                <ExpendableCardContext.Provider value={{ isExpanded }}>{children}</ExpendableCardContext.Provider>
            </CardContent>
        </Card>
    );
}

const ExpendableCard = forwardRef(ExpendableCardDef);

export default ExpendableCard;
