import React, { useEffect, useMemo } from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import AttendanceStatusEnum from "components/attendance/AttendanceStatusEnum";
import { EmployeePickRow, useFetchData2 as useFetchData } from "hooks";
import { useTranslation } from "react-i18next";
import { glueQueryParams } from "helpers/urlHelper";
import { DateFilter, getDateParams } from "helpers/dateFilter";
import { StdQueryListResult } from "common";

export interface AttendanceRowData {
    id: number;
    employeeNo: string;
    name: string;
    date: Date;
    workHours: string;
    startWorkTime?: string;
    endWorkTime?: string;
    status: AttendanceStatusEnum;
}
interface ClockTime {
    date: string;
    time: string;
    dutyType: number;
    employeeId: number;
}

interface AttendanceRecordStatus {
    auditRecordId: number;
}

interface AttendanceStatus {
    id: number;
    name: string;
    key: string;
    AttendanceRecordStatus: AttendanceRecordStatus;
}

interface AttendanceRow {
    date: string;
    employeeId: string;
    isWeekend: boolean;
    employee: Pick<EmployeePickRow, "id" | "name" | "employeeNo">;
    clockIn: string;
    clockInTime?: ClockTime;
    clockOut: string;
    clockOutTime?: ClockTime;
    attendanceStatus: Array<AttendanceStatus>;
}

export interface AttendanceSearchTableProps extends Partial<CustomMaterialTableProps<AttendanceRowData>> {
    options?: Options<AttendanceRowData>;
    columns: Column<AttendanceRowData>[];
    statusFilter?: AttendanceStatusEnum;
    dateFilter: DateFilter;
    header?: React.ReactNode;
}

function AttendanceRowToAttendanceRowData(row: AttendanceRow): AttendanceRowData {
    const { employee, clockIn, clockOut, clockInTime, clockOutTime } = row;
    const { attendanceStatus } = row;
    return {
        id: employee.id,
        employeeNo: employee.employeeNo,
        name: employee.name,
        date: new Date(row.date),
        workHours: `${clockIn}-${clockOut}`,
        startWorkTime: clockInTime?.time,
        endWorkTime: clockOutTime?.time,
        status: attendanceStatus.length ? AttendanceStatusEnum.ABNORMAL : AttendanceStatusEnum.NORMAL,
    };
}

function AttendanceSearchTable({
    options = {},
    columns,
    header,
    statusFilter,
    dateFilter,
    ...props
}: AttendanceSearchTableProps) {
    const { t } = useTranslation();
    const [dataListResult, dataListFetch, dataListCleanup] = useFetchData<StdQueryListResult<AttendanceRow>>(
        `/api/attendance/employees?${glueQueryParams(getDateParams(dateFilter))}`,
        t("e.attendanceData")
    );
    const processedData = useMemo<AttendanceRowData[]>(() => {
        if (!dataListResult?.count) {
            return [];
        }

        return dataListResult.data.map(AttendanceRowToAttendanceRowData);
    }, [dataListResult]);

    useEffect(() => {
        dataListFetch().catch((error) => {
            console.error(error);
        });

        return () => {
            dataListCleanup();
        };
    }, [dateFilter]);

    return (
        <>
            {header && header}
            <MaterialTable<AttendanceRowData>
                {...props}
                columns={columns}
                data={processedData}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: false,
                    ...options,
                }}
            />
        </>
    );
}

export default AttendanceSearchTable;
