import React from "react";
import { Column } from "@material-table/core";
import { TFunction } from "react-i18next";
import DateRenderer, { DateTimeRenderer } from "components/grid/DateRenderer";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { LeaveRowData } from "./LeaveSearchTable";
import AuditStatus from "components/audit/AuditStatus";

export interface ColumnsDesc {
    type: string;
    downloadAction: (rowId: string) => void;
}

function LeaveColumnsFactory(columnsDesc: ColumnsDesc, t: TFunction) {
    const { downloadAction } = columnsDesc;
    const defaultColumns: Column<LeaveRowData>[] = [
        {
            title: t("申請日期"),
            field: "applicationDate",
            type: "date",
            render: ({ applicationDate }) => DateRenderer(applicationDate),
        },
        {
            title: t("假別名稱"),
            field: "name",
            type: "string",
        },
        {
            title: t("開始日期"),
            field: "startDate",
            type: "date",
            render: ({ startDate }) => DateTimeRenderer(startDate),
        },
        {
            title: t("結束日期"),
            field: "endDate",
            type: "date",
            render: ({ endDate }) => DateTimeRenderer(endDate),
        },
        {
            title: t("請假時數"),
            field: "hoursOfLeave",
            type: "numeric",
        },
        {
            title: t("申請理由"),
            field: "reason",
            type: "string",
        },
        {
            title: t("附件"),
            field: "appendix",
            filtering: false,
            width: "40px",
            // TODO: add condition when this is available
            render: (row: LeaveRowData) => (
                <IconButton edge="end" aria-label={t("c.detail")} onClick={() => downloadAction(row.id)}>
                    <DownloadIcon color="primary" />
                </IconButton>
            ),
        },
        {
            title: t("狀態"),
            field: "status",
            type: "string",
            render: ({ status }) => <AuditStatus statusId={status} />,
        },
    ];

    return defaultColumns;
}

export default LeaveColumnsFactory;
