import React, { useState, useRef, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Action } from "@material-table/core";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MdiIcon from "@mdi/react";
import { mdiFileSearch, mdiFileCancel } from "@mdi/js";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchDialog from "components/customMaterialTable/SearchDialog";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import { PageHeader2 as PageHeader, MenuIcons } from "layout";
import { useUserPermission } from "hooks";
import AccountVoucherSearchTable, { AccountVoucherRowData } from "./AccountVoucherSearchTable";
import { SearchTextField } from "components";
import AccountVoucherCancelDialog, { AccountVoucherCancelDialogRef } from "./AccountVoucherCancelDialog";
import { useConsolidationCheck } from "components/accounting";

function AccountVoucherList() {
    const history = useHistory();
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("accountVoucher"), [isWrite]);
    const { t } = useTranslation();
    const dialogRef = React.useRef<AccountVoucherCancelDialogRef>(null);
    const [searchOpen, setSearchOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const { consolidationDate } = useConsolidationCheck();

    const isLocked = (rowData: AccountVoucherRowData) => {
        return !!consolidationDate && moment(rowData.date).isBefore(consolidationDate);
    };

    const tableRef = useRef<any>(null);
    const actions: ActionType<AccountVoucherRowData>[] = [
        (rowData) => {
            const locked = isLocked(rowData);
            const editable = rowData.status === 0 && !locked && writable;
            const handleClick = (e: any, rowData: AccountVoucherRowData) => {
                history.replace({ pathname: "/accountVoucher/" + rowData.id });
            };
            return {
                icon: editable ? "edit" : () => <MdiIcon path={mdiFileSearch} size={1} />,
                tooltip: editable ? t("c.edit") : t("c.view"),
                onClick: handleClick,
            } as Action<AccountVoucherRowData>;
        },
    ];
    if (writable) {
        actions.push((rowData) => {
            const handleClick = (e: any, rowData: AccountVoucherRowData) => {
                history.replace({ pathname: "/accountVoucher/adjust/" + rowData.id });
            };
            const locked = isLocked(rowData);
            return {
                icon: () => <MenuIcons lib="ri" name="RiIncreaseDecreaseFill" />,
                tooltip: t("c.adjust"),
                onClick: handleClick,
                disabled: rowData.status !== 0 || locked,
            } as Action<AccountVoucherRowData>;
        });
        //RiIncreaseDecreaseFill;
    }
    const refreshTable = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };

    const extraActions: ActionType<AccountVoucherRowData>[] = [
        (rowData: AccountVoucherRowData): Action<AccountVoucherRowData> => {
            const locked = isLocked(rowData);
            return {
                icon: () => <MdiIcon path={mdiFileCancel} size={1} />,
                tooltip: t("c.doCancel"),
                onClick: (e: any, rowData) => {
                    if (!Array.isArray(rowData)) {
                        dialogRef.current?.open(Number(rowData.id));
                        //handleMemberManageOpen(rowData);
                    }
                },
                disabled: rowData.status !== 0 || locked,
            };
        },
    ];

    const handleSearchClose = () => {
        setSearchOpen(false);
    };
    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    writable && (
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    component={NavLink}
                                    to="/accountVoucher/add"
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        </Grid>
                    )
                }
                rightToolView={
                    <Grid container spacing={1}>
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} endIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
            <AccountVoucherSearchTable
                tableRef={tableRef}
                actions={actions}
                extraActions={writable ? extraActions : []}
                options={{
                    filtering: showFilter,
                }}
            />
            <SearchDialog open={searchOpen} onClose={handleSearchClose} tableRef={tableRef} />
            <AccountVoucherCancelDialog
                onFinished={() => {
                    refreshTable();
                }}
                ref={dialogRef}
            />
        </>
    );
}

export default AccountVoucherList;
