import React from "react";
import { useTranslation } from "react-i18next";
//import Home from "pages/dashboard";
import Construction from "pages/construction";
import { useAppContent } from "components/AppContext";
import { useUserPermission } from "hooks";
import PageRoute, { PageRouteProps } from "./PageRoute";

export interface AuthPageRouteProps extends PageRouteProps {
    path: string;
    pageKey?: string;
}

function AuthPageRoute({ pageKey, path, ...props }: AuthPageRouteProps) {
    const { t } = useTranslation();
    const { isRead } = useUserPermission();
    const { urlPageMapping } = useAppContent();
    let mPageKey = pageKey;
    if (!mPageKey && urlPageMapping) {
        const mappingData = urlPageMapping.find((item) => path.indexOf(item.url) >= 0);
        if (mappingData) {
            mPageKey = mappingData.page;
        }
    }
    if (mPageKey && isRead(mPageKey)) {
        return <PageRoute path={path} {...props} />;
    }
    return <PageRoute path={path} {...props} component={Construction} title={t("m.dashboard")} />;
}

export default AuthPageRoute;
