import React from "react";
import { Alert, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import LeaveDetails from "./LeaveDetails";

interface LeaveDetailsWrapperProps {
    employeeId?: number;
}

function LeaveDetailsWrapper({ employeeId }: LeaveDetailsWrapperProps) {
    const { t } = useTranslation();
    return (
        <Box sx={{ p: 4 }}>
            {employeeId ? (
                <LeaveDetails employeeId={employeeId} />
            ) : (
                <Alert severity="warning">{t("c.msg.selectEmployee")}</Alert>
            )}
        </Box>
    );
}

export default LeaveDetailsWrapper;
