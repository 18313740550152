import React from "react";
import PropTypes from "prop-types";

import { Field } from "react-final-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function QuillEditor({ name, readOnly, ...props }) {
    const modules = readOnly
        ? { toolbar: [] }
        : {
              toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ color: [] }, { background: [] }],
                  [{ align: [] }],
                  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                  ["link", "image"],
                  ["clean"],
              ],
          };

    return (
        <Field name={name}>
            {({ input }) => (
                <ReactQuill
                    theme="snow"
                    modules={modules}
                    readOnly={readOnly}
                    value={input.value}
                    onChange={input.onChange}
                    {...props}
                />
            )}
        </Field>
    );
}

QuillEditor.propTypes = {
    name: PropTypes.string,
    readOnly: PropTypes.bool,
};

export default QuillEditor;
