import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "Action/Actions";
import { of, Subscription } from "rxjs";
import { RootState } from "layout/types";
import useReduxTempDataReloadFlag from "./useReduxTempDataReloadFlag";

export interface ReduxTempDataBaseParams<DataValues = any> {
    apiPath: string;
    name: string;
    force?: boolean;
    process?: (values: DataValues) => void;
}

function useReduxTempDataBase<DataValues = any>({ apiPath, name, force = false, process }: ReduxTempDataBaseParams) {
    // const { addToast } = useToasts();
    // const { t } = useTranslation();
    const dispatch = useDispatch();
    const [result, setResult] = useState<DataValues | null>(null);
    const tempData = useSelector<any>((state) => state.temp[apiPath]);
    const { removeFlag } = useReduxTempDataReloadFlag(apiPath);

    const loadData = useCallback(async () => {
        const useLC = tempData === undefined;
        const source = of(Actions.GetTempDataAction(apiPath, name, useLC));
        let subscribe: Subscription | null = null;
        subscribe = source.subscribe((val) => dispatch(val));
        return () => {
            subscribe && subscribe.unsubscribe();
        };
    }, [apiPath, name, force]);

    const flagData = useSelector((state: RootState) => state.tempReloadFlag);

    useEffect(() => {
        const flag = !!flagData[apiPath];
        const useLC = tempData === undefined;
        const source = of(Actions.GetTempDataAction(apiPath, name, useLC));
        let subscribe: Subscription | null = null;
        // useLC && LC();
        if (force || tempData === undefined || flag) {
            // console.log(Actions.GetTempDataAction(apiPath, name, useLC));
            subscribe = source.subscribe((val) => dispatch(val));
            if (flag) {
                //avoid from infinite render loop. Only if flag is true, set it false.
                removeFlag();
            }
            // dispatch(Actions.GetTempDataAction(apiPath, name, useLC));
        }
        return () => {
            subscribe && subscribe.unsubscribe();
        };
        // eslint-disable-next-line
    }, []);

    // 2020/11/13 發生異常 Kevin修正
    useEffect(() => {
        if (tempData && JSON.stringify(result) !== JSON.stringify(tempData)) {
            // console.error(tempData)
            const mTempData = tempData as DataValues;
            setResult(mTempData);
        }
        // eslint-disable-next-line
    }, [tempData]);
    return {
        data: process && result ? process(result) : result || null,
        reload: loadData,
    };
}
export default useReduxTempDataBase;
