import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { useMainTitle } from "hooks";

export interface PageRouteProps extends RouteProps {
    title?: string;
}

function PageRoute({ title, ...props }: PageRouteProps) {
    useMainTitle(title || "");
    return <Route {...props} />;
}

export default PageRoute;
