import React, { ElementType } from "react";
import { FormBaseProps } from "components";
import LeaveRequestForm from "./LeaveRequestForm";
import OvertimeRequestForm from "./OvertimeRequestForm";
import ClockRequestForm from "./ClockRequestForm";

export type RequestFormBaseProps = Pick<FormBaseProps<any>, "onSubmit" | "formRef">;

export type RequestContentProps = RequestFormBaseProps & {
    formName: string;
};

function RequestContent({ formName, onSubmit, formRef }: RequestContentProps) {
    const formMap: Record<string, ElementType<RequestFormBaseProps>> = {
        leaveRequest: LeaveRequestForm,
        overtimeRequest: OvertimeRequestForm,
        clockRequest: ClockRequestForm,
    };
    let RequestFormComponent: ElementType<RequestFormBaseProps> | null = null;
    if (formName in formMap) {
        RequestFormComponent = formMap[formName];
    }

    return <>{RequestFormComponent && <RequestFormComponent onSubmit={onSubmit} formRef={formRef} />}</>;
}

export default RequestContent;
