import { Decorator } from "final-form";
import createDecorator from "final-form-calculate";
import moment, { Moment } from "moment";
import { removeSeconds, isAfterEqual, isBeforeEqual } from "helpers/dateHelper";
import { ShiftFormValues } from "./ShiftForm";

export type WorkingTimeContextValue = Pick<
    ShiftFormValues,
    "clockIn" | "clockOut" | "restPeriod" | "overtimeStart" | "overtimeEnd"
>;
const calculateWorkingTime = (time: WorkingTimeContextValue): number => {
    const moment_clockOut = removeSeconds(moment(time.clockOut));
    const moment_clockIn = removeSeconds(moment(time.clockIn));
    let restMinute = 0;
    if (time.restPeriod) {
        if (time.restPeriod.period.length) {
            time.restPeriod.period.forEach((period) => {
                if (period && !!period.restEnd && !!period.restStart) {
                    const moment_restEnd = removeSeconds(moment(period.restEnd));
                    const moment_restStart = removeSeconds(moment(period.restStart));
                    restMinute += moment_restEnd.diff(moment_restStart, "minutes");
                }
            });
        }
    }
    const clockInOutMinute = time.clockOut && time.clockIn ? moment_clockOut.diff(moment_clockIn, "minutes") : 0;
    const minute = clockInOutMinute - restMinute;
    return minute;
};

const calculator = createDecorator(
    {
        field: /(clock(In|Out)|restPeriod)/,
        updates: {
            totalWorkingTime: (itemValue, allValues) => {
                return calculateWorkingTime(allValues as any);
            },
        },
    },
    {
        field: /(clock(In|Out)|restPeriod|overtime(Start|End))/,
        updates: {
            timeValidWarning: (itemValue, allValues) => {
                const errorSet = new Set<string>();
                if (allValues) {
                    const values = allValues as ShiftFormValues;
                    const moment_clockOut = removeSeconds(moment(values.clockOut));
                    const moment_clockIn = removeSeconds(moment(values.clockIn));
                    const tempPeriod: { start: Moment; end: Moment }[] = [];
                    values.restPeriod.period.forEach((period, index) => {
                        if (period && period.restStart && period.restEnd) {
                            const moment_restStart = removeSeconds(moment(period.restStart));
                            const moment_restEnd = removeSeconds(moment(period.restEnd));
                            let restPeriodOverlap = false;
                            if (
                                isBeforeEqual(moment_restStart, moment_clockIn) ||
                                isAfterEqual(moment_restStart, moment_clockOut) ||
                                isBeforeEqual(moment_restEnd, moment_clockIn) ||
                                isAfterEqual(moment_restEnd, moment_clockOut)
                            ) {
                                errorSet.add("restTimeBeyondOfficeTime");
                            }
                            if (index > 0 && tempPeriod.length > 0) {
                                restPeriodOverlap = tempPeriod.reduce<boolean>((previousValue, currentData) => {
                                    const mCurrentDataStart = removeSeconds(currentData.start);
                                    const mCurrentDataEnd = removeSeconds(currentData.end);
                                    return (
                                        (isAfterEqual(moment_restStart, mCurrentDataStart) &&
                                            isBeforeEqual(moment_restStart, mCurrentDataEnd)) ||
                                        (isAfterEqual(moment_restEnd, mCurrentDataStart) &&
                                            isBeforeEqual(moment_restEnd, mCurrentDataEnd)) ||
                                        (isAfterEqual(mCurrentDataStart, moment_restStart) &&
                                            isBeforeEqual(mCurrentDataStart, moment_restEnd)) ||
                                        (isAfterEqual(mCurrentDataEnd, moment_restStart) &&
                                            isBeforeEqual(mCurrentDataEnd, moment_restEnd)) ||
                                        previousValue
                                    );
                                }, false);
                                if (restPeriodOverlap) {
                                    errorSet.add("restPeriodOverlap");
                                }
                            }
                            if (moment_restStart.isBefore(moment_restEnd) && !restPeriodOverlap) {
                                tempPeriod.push({ start: moment_restStart, end: moment_restEnd });
                            }
                        }
                    });
                }
                return Array.from(errorSet);
            },
        },
    },
    {
        field: "shiftType",
        updates: {
            restPeriod: (itemValue, allValues) => {
                const values = allValues as ShiftFormValues;
                if (Number(itemValue) === 3 || Number(itemValue) === 4) {
                    return { period: [] };
                } else {
                    if (values.restPeriod.period.length === 0) {
                        return { period: [{}] };
                    }
                }
                return values.restPeriod;
            },
        },
    },
    {
        field: /(flexibilityType|flexTime1|clockIn|allowClockTime)/,
        updates: {
            allowClockTimeValidation: (itemValue, allValues) => {
                const values = allValues as ShiftFormValues;
                const moment_clockIn = moment(values.clockIn).second(0).millisecond(0);
                const moment_allowClockTime = moment(values.allowClockTime).second(0).millisecond(0);
                if (Number(values.flexibilityType) === 2) {
                    const maxTime = moment_clockIn.subtract(Number(values.flexTime1), "m");
                    return { isValid: moment_allowClockTime.diff(maxTime) <= 0, maxTime };
                } else {
                    return { isValid: moment_allowClockTime.diff(moment_clockIn) <= 0, maxTime: moment_clockIn };
                }
            },
        },
    }
) as Decorator<ShiftFormValues>;

export default calculator;
