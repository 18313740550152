import React, { MouseEventHandler, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { List, Box } from "@mui/material";
import { Collapse } from "react-collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useAppContent } from "components/AppContext";
import { PageData } from "common";
import MListItem, { MenuListItemBaseProps } from "./MenuListItem";
import MenuIcons from "./MenuIcons";
import "../css/menu.css";
export interface MainListItemsProps {
    menuOpen: boolean;
}
export default function MainListItems({ menuOpen }: MainListItemsProps) {
    const appContext = useAppContent();
    const { user, pageData } = appContext;
    // const pageData = useSelector((state) => state.temp["/auth/access"]);
    const [open, setOpen] = useState<boolean | string>(false);
    const { t } = useTranslation();
    if (user === null) {
        return <></>;
    }

    const handleParentClick = (name: string) => {
        return () => {
            if (open === name) {
                setOpen(false);
            } else {
                setOpen(name);
            }
        };
    };

    const createMListItem = (data: PageData, checked = false) => {
        if (!checked) {
            return <React.Fragment key={data.i18n}></React.Fragment>;
        }
        const disabled = data.url === "/under/construction/";
        return (
            <MListItem
                key={data.i18n}
                component={NavLink}
                to={() => {
                    return data.url || "#";
                }}
                icon={<MenuIcons {...data.icon} color={disabled ? "disabled" : "inherit"} />}
                title={t(data.i18n)}
                menuOpen={menuOpen}
            />
        );
    };

    const createParentListItem = (data: PageData) => {
        const subItems = (data.items || [])
            .filter((item) => item.url !== "#")
            .map((item) => createMListItem(item, true));
        if (subItems.length === 0) {
            return <></>;
        }
        return (
            <ParentListItem
                key={data.i18n}
                open={open === data.i18n}
                onOpen={handleParentClick(data.i18n)}
                menuOpen={menuOpen}
                icon={<MenuIcons {...data.icon} />}
                title={t(data.i18n)}
            >
                {subItems}
            </ParentListItem>
        );
    };

    const menuListItems = pageData
        ? pageData.map((rootItem) => {
              if (rootItem.type === "cat") {
                  return createParentListItem(rootItem);
              } else {
                  return createMListItem(rootItem);
              }
          })
        : [];
    return <List>{menuListItems}</List>;
}

interface ParentListItemProps extends MenuListItemBaseProps {
    open: boolean;
    onOpen: MouseEventHandler;
}

function ParentListItem({ open, menuOpen, icon, children, onOpen, title }: ParentListItemProps) {
    return (
        <>
            <MListItem button icon={icon} title={title} onClick={onOpen} menuOpen={menuOpen}>
                <Box position="absolute" right={5}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </Box>
            </MListItem>
            <Collapse isOpened={open}>
                <List component="div" disablePadding sx={{ backgroundColor: (theme) => theme.palette.action.hover }}>
                    {children}
                </List>
            </Collapse>
        </>
    );
}
