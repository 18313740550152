import { default as BaseDownloadButton } from "components/buttons/DownloadButton";
import { download, generateFilename } from "helpers/fileHelper";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";

function DownloadButton() {
    const { t } = useTranslation();

    return (
        <BaseDownloadButton
            actions={[
                {
                    title: t("下載預設值"),
                    startIcon: <FaFileExcel fontSize="small" />,
                    onClick: () => {
                        //TODO: implement valid export endpoint
                        const fileName = generateFilename("attendance_employees_", "xlsx");
                        download("/api/attendance/employees/export", fileName);
                    },
                },
                {
                    title: t("下載說明檔"),
                    startIcon: <FaFilePdf fontSize="small" />,
                    onClick: () => {
                        //TODO: implement valid export endpoint
                        const fileName = generateFilename("attendance_employees_", "xlsx");
                        download("/api/attendance/employees/export", fileName);
                    },
                },
            ]}
        >
            {t("下載")}
        </BaseDownloadButton>
    );
}

export default DownloadButton;
