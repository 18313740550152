import React from "react";
import { MenuItem } from "@mui/material";

function i18nToMenuItem(obj: Record<string, any>) {
    return Object.keys(obj).map((key) => (
        <MenuItem key={key} value={parseInt(key)}>
            {obj[key]}
        </MenuItem>
    ));
}
function i18nToRadio(obj: Record<string, any>) {
    return Object.keys(obj).map((key) => ({ label: obj[key], value: key }));
}

export { i18nToMenuItem, i18nToRadio };
