import React from "react";
import { Stack, Typography } from "@mui/material";
import CommonNumberFormat, { CommonNumberFormatProps } from "components/CommonNumberFormat";
import Block from "./Block";
export interface AccountingBlockProps {
    title: string;
    amount?: number | string | null;
    color: string;
    background: string;
    icon: React.ReactElement;
    formatProps?: Omit<CommonNumberFormatProps, "number">;
}
// TODO: cash block made for each type
function AccountingBlock({ title, amount, color, background, icon, formatProps }: AccountingBlockProps) {
    return (
        <Block
            sx={{
                background: `url(${background})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                minHeight: "210px",
            }}
        >
            <Stack spacing={1}>
                <Stack direction="row" spacing={1}>
                    {icon}
                    <Typography
                        variant="h6"
                        fontSize="20px"
                        fontWeight="500"
                        textAlign="start"
                        lineHeight="32px"
                        sx={{ color: color }}
                    >
                        {title}
                    </Typography>
                </Stack>
                <Typography fontSize="48px" fontWeight="700" textAlign="start" lineHeight="56px" sx={{ color: color }}>
                    {"$"}
                    {amount ? <CommonNumberFormat {...formatProps} number={amount} /> : "0"}
                </Typography>
            </Stack>
        </Block>
    );
}

export default AccountingBlock;
