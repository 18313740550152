import { useState, useCallback } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getErrorByCode } from "helpers/formHelper";
import { addToast } from "helpers";
/**
 * fetch readonly data from backend (database).
 *
 * @param apiPath api for data reading.
 * @param name table name. when loading failed, it will show "load [table name] data failed".
 * @param process function for data processing after data loaded.
 * @param initVal inital data before data loaded.
 */
function useFetchData(apiPath, name = "", process = null, initVal = []) {
    const [data, setData] = useState(initVal);

    const { t } = useTranslation();
    const fetchData = useCallback(
        async (act = true, config = {}) =>
            new Promise((resolve, reject) => {
                if (act) {
                    axios
                        .get(apiPath, config)
                        .then((result) => {
                            if (
                                !Array.isArray(result.data) &&
                                "status" in result.data &&
                                result.data.status === "error"
                            ) {
                                const extraMsg = getErrorByCode(result.data);
                                const msg = t("c.msg.loadSthFailed", { sth: name });
                                addToast(`${msg}${extraMsg}`, { appearance: "error" });
                                setData(initVal);
                                reject();
                            } else {
                                let data = initVal;
                                if (process) {
                                    data = process(result.data);
                                } else {
                                    data = result.data;
                                }
                                setData(data);
                                resolve(data);
                            }
                        })
                        .catch(() => {
                            const msg = t("c.msg.loadSthFailed", { sth: name });
                            addToast(msg, { appearance: "error" });
                            setData(initVal);
                            reject();
                        });
                } else {
                    setData(initVal);
                    resolve(initVal);
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [apiPath]
    );
    return [data, fetchData];
}
export default useFetchData;
