import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { PreProcessCheckResult, PreProcessCheckStatus } from "common/accounting";
import { AlertWithAuthLink } from "components";

export interface AccountConsolidationAlertProps {
    alertSettings: Record<PreProcessCheckStatus, ReactNode>;
    checkResult?: PreProcessCheckResult | null;
}

function AccountConsolidationAlert({ checkResult, alertSettings }: AccountConsolidationAlertProps) {
    const { t } = useTranslation();
    if (checkResult) {
        const { status } = checkResult;
        if (status === PreProcessCheckStatus.at_settings_error) {
            const settingKeys = checkResult.settingKeys || [];
            const itemListString = settingKeys
                .map((settingKey) => t(`accountTitle.settingData.${settingKey}`))
                .join(t("c.comma"));
            return (
                <AlertWithAuthLink
                    severity="warning"
                    authLinkProps={{
                        permissionPage: "accountSetting",
                        to: "/accountSetting/setting",
                    }}
                    linkLabel={t("c.goToSet")}
                >
                    {t("accountSetting.msg.accTitleSettingRequire", { itemList: itemListString })}
                </AlertWithAuthLink>
            );
        } else {
            const alertNode = alertSettings[status];
            if (alertNode) {
                return <>{alertNode}</>;
            }
        }
    }
    return <></>;
}

export default AccountConsolidationAlert;
