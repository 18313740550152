import { Box } from "@mui/material";
import React from "react";
import BaseDataView from "./BaseDataView";
import RuleTable from "./RuleTable";

function AnnualLeavePage() {
    return (
        <>
            <BaseDataView />
            <Box my={2} />
            <RuleTable />
        </>
    );
}

export default AnnualLeavePage;
