import { Box, AppBar, AppBarProps, Container, IconButton, IconButtonProps, styled, Toolbar } from "@mui/material";
import { drawerWidth, pmBreakPoint, menuBreakPoint } from "./commonValue";

export const MobileViewWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up(pmBreakPoint)]: {
        display: "none",
    },
}));

export const PcViewWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down(pmBreakPoint)]: {
        display: "none",
    },
}));

export const AppBarSpacer = styled("div")(({ theme }) => theme.mixins.toolbar);

export const AvatarImg = styled("img")(() => ({
    width: "100%",
}));

export const LayoutContainer = styled(Container)(({ theme }) => ({
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    margin: 0,
}));

export const LayoutContent = styled("main")(() => ({
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
}));

export const TopToolbar = styled(Toolbar)(({ theme }) => ({
    paddingRight: 24, // keep right padding when drawer closed
    [theme.breakpoints.down(pmBreakPoint)]: {
        paddingRight: 0,
    },
}));

interface MenuRelatedComponent {
    open: boolean;
}

export type MenuButtonProps = IconButtonProps & MenuRelatedComponent;

export const MenuButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== "open" })<MenuButtonProps>(
    ({ open }) => ({
        marginRight: 36,
        ...(open && {
            display: "none",
        }),
    })
);

export type TopAppBarProps = AppBarProps & MenuRelatedComponent;

export const TopAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== "open" })<TopAppBarProps>(
    ({ theme, open }) => ({
        backgroundColor: "#006699",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down(menuBreakPoint)]: {
                width: 0,
            },
        }),
    })
);
