/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { NavLink, useParams, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { PageHeader2 as PageHeader } from "layout";
import useFormData, { modeDef } from "hooks/useFormData";
import { useReduxTempData, useUserPermission } from "hooks";
import { FormBaseRefProps } from "components/final-form/FormBase";
import PermissionRoleFormBase, { PermissionRoleFormValues, defaultInitialValues } from "./PermissionRoleFormBase";
import {
    PageData,
    PermissionRoleAuthorizeValues,
    convertAuthorizeValuesToUI,
    convertAuthorizeValuesToRowData,
} from "./PagePermissionView";

function createInitialRoleAuthorizesValues(page: string): PermissionRoleAuthorizeValues {
    return { page, alterValue: "0" };
}

function PermissionRoleForm() {
    const { id } = useParams<{ id: string }>();
    const { pathname } = useLocation();
    const [isFormDataLoaded, setIsFormDataLoaded] = useState<boolean>(false);
    const [isRoleAuthorizesChecked, setIsRoleAuthorizesChecked] = useState<boolean>(false);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("permissionRole"), [isWrite]);
    const { t } = useTranslation();
    const history = useHistory();
    const pagesData = useReduxTempData<PageData[]>("/api/page/", t("c.page"), false);
    const formRef = useRef<FormBaseRefProps<PermissionRoleFormValues>>(null);
    const formData = useFormData<PermissionRoleFormValues>("/api/PermissionRole", defaultInitialValues, id, (data) => {
        if (data && data.permissionRoleAuthorizes.length > 0) {
            const mAuthorizes = data.permissionRoleAuthorizes.map((row) => {
                return {
                    page: row.page,
                    alterValue: convertAuthorizeValuesToUI(row),
                };
            });
            data.permissionRoleAuthorizes = mAuthorizes;
        }
    });
    const checkRoleAuthorizes = useCallback(() => {
        const values: PermissionRoleAuthorizeValues[] = formData.data.permissionRoleAuthorizes || [];
        if (pagesData && !isRoleAuthorizesChecked) {
            pagesData.forEach((row) => {
                if ("items" in row && row.items && row.items.length > 0) {
                    row.items.forEach((sub) => {
                        const itemData = values.find((item) => item.page === sub.name);
                        if (!itemData) {
                            values.push(createInitialRoleAuthorizesValues(sub.name));
                        }
                    });
                }
            });
            setIsRoleAuthorizesChecked(true);
        }
        formData.setData({ ...formData.data, permissionRoleAuthorizes: [...values] });
    }, [formData, pagesData]);
    const fetchData = useCallback(async () => {
        if (id && pagesData) {
            await formData
                .fetch()
                .then(() => {
                    setIsFormDataLoaded(true);
                })
                .catch((err) => {
                    if (!formData.isCancel) {
                        history.replace({ pathname: "/permissionRole" }); //if data not found
                        console.log(err);
                    }
                });
        } else if (pagesData) {
            setIsFormDataLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, pagesData]);

    useEffect(() => {
        if (isFormDataLoaded) {
            checkRoleAuthorizes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormDataLoaded]);

    useEffect(() => {
        if (pathname.includes("/copy/")) {
            formData.setCopyMode("id");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormDataLoaded]);

    useEffect(() => {
        fetchData();
        return () => {
            formData.cleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, id]);

    const handleSubmit = async (values: PermissionRoleFormValues) => {
        //檢查資料是否有無修改
        const mAuthorizes = values.permissionRoleAuthorizes.map((row) => {
            const authorizesSettings = convertAuthorizeValuesToRowData(row.alterValue || "0");
            return {
                page: row.page,
                ...authorizesSettings,
            };
        });
        const mValues = { ...values, permissionRoleAuthorizes: mAuthorizes };
        await formData.save(mValues).then((resp) => {
            if (resp && formData.mode === modeDef.ADD) {
                history.replace("/permissionRole/" + resp.result?.id);
            } else {
                formData.fetch();
            }
        });
    };
    const handleSubmitBtn = () => {
        formRef.current && formRef.current.onSubmit();
    };
    return (
        <>
            <PageHeader
                title={formData.mode === modeDef.ADD ? t("c.data_add") : t("c.data_edit")}
                rightToolView={
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                component={NavLink}
                                to={"/permissionRole"}
                                endIcon={<ArrowBackIcon />}
                            >
                                {t("c.backpage")}
                            </Button>
                        </Grid>
                        {writable && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleSubmitBtn}
                                    endIcon={<SaveIcon />}
                                >
                                    {t("c.save")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
            />
            {pagesData && isFormDataLoaded && isRoleAuthorizesChecked && (
                <PermissionRoleFormBase
                    onSubmit={handleSubmit}
                    initialValues={formData.data}
                    formMode={formData.mode}
                    formRef={formRef}
                    writable={writable}
                />
            )}
        </>
    );
}

export default PermissionRoleForm;
