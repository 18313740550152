import React, { ReactNode } from "react";
import { Box, Typography, Grid, Divider } from "@mui/material";

export interface PageHeader2Props {
    title: ReactNode;
    subtitle?: ReactNode;
    leftToolView?: ReactNode;
    rightToolView?: ReactNode;
}
function PageHeader2({ title, subtitle, leftToolView, rightToolView }: PageHeader2Props) {
    return (
        <Box mt={-2} mb={2}>
            <Grid container justifyContent="space-between" alignItems="flex-end">
                {/* <Grid item xs={12}>
                    <Typography variant="h4" component="h1">
                        {title || ""}
                    </Typography>
                    {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
                </Grid> */}
                <Grid item xs={12}>
                    <Grid container spacing={3} justifyContent="space-between" alignItems="flex-end">
                        <Grid item>
                            <Box display="flex" alignItems="center">
                                {title && (
                                    <Box flexDirection="row" mr={2}>
                                        <Typography variant="h6" component="h2" color="primary">
                                            {title}
                                        </Typography>
                                    </Box>
                                )}
                                {leftToolView && <Box flexDirection="row">{leftToolView}</Box>}
                            </Box>
                        </Grid>
                        <Grid item>{rightToolView || ""}</Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}
export default PageHeader2;
