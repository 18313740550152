import { Decorator } from "final-form";
import createDecorator, { Calculation } from "final-form-calculate";
import { AccountTitleRow } from "../accountTitle";
import { InitialFormValues } from "./InitialFormBase";

function sumFields(values: InitialFormValues, ids: number[]): number {
    return ids.reduce<number>((previousValue, currentValue) => {
        const fieldName = `title${currentValue}`;
        const fieldValue = Number(values[fieldName]);
        if (isNaN(fieldValue)) {
            return previousValue;
        }
        return previousValue + fieldValue;
    }, 0);
}

function createCalculationByTitleIds(targetFieldName: string, observedFieldIds: number[]): Calculation {
    const regExpString = observedFieldIds.join("|");
    return {
        field: new RegExp(`^title(${regExpString})$`),
        updates: {
            [targetFieldName]: (itemValue, allValues) => {
                if (allValues) {
                    const values = allValues as InitialFormValues;
                    return sumFields(values, observedFieldIds);
                }
                return 0;
            },
        },
    };
}

function InitialFormCalculatorFactory(
    data: AccountTitleRow[],
    otherCalculation?: Calculation[]
): Decorator<InitialFormValues> {
    const itemCalculations: Calculation[] = data
        .filter((row) => [1, 2].indexOf(row.type) > -1)
        .map<Calculation | null>((row) => {
            const targetFieldName = `title${row.id}`;
            const observedFieldIds = data
                .filter((mRow) => {
                    return (
                        [3, 4].indexOf(mRow.type) > -1 &&
                        [0, 1, 2, 3].reduce<boolean>((previousValue, currentValue) => {
                            const paramName = `l${currentValue}` as keyof AccountTitleRow;
                            return previousValue || mRow[paramName] === row.id;
                        }, false)
                    );
                })
                .map((mRow) => mRow.id);
            if (observedFieldIds.length === 0) {
                return null;
            }
            return createCalculationByTitleIds(targetFieldName, observedFieldIds);
        })
        .filter((mCalculation) => mCalculation !== null) as Calculation[];
    const catalogName = ["assetTotal", "liabilityTotal", "equityTotal", "liabilityEquityTotal"];
    const catalogCalculations = [1, 2, 3, 4]
        .map<Calculation | null>((catalogId, index) => {
            const observedFieldIds = data
                .filter((row) => {
                    const isItemTitle = [3, 4].indexOf(row.type) > -1;
                    if (catalogId === 4) {
                        return isItemTitle && [2, 3].indexOf(row.catalog) > -1;
                    } else {
                        return isItemTitle && row.catalog === catalogId;
                    }
                })
                .map((mRow) => mRow.id);
            if (observedFieldIds.length === 0) {
                return null;
            }
            return createCalculationByTitleIds(catalogName[index], observedFieldIds);
        })
        .filter((mCalculation) => mCalculation !== null) as Calculation[];
    return createDecorator(
        ...itemCalculations,
        ...catalogCalculations,
        ...(otherCalculation || [])
    ) as Decorator<InitialFormValues>;
}
export default InitialFormCalculatorFactory;
