import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Drawer, DrawerProps, Divider, List, IconButton, styled } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { DEF } from "Action";
import { getUser } from "helpers";
import MainListItems from "./MainListItems";
import MListItem from "./MenuListItem";
import onLogout from "helpers/logout";
import { RootState } from "./types";
import { drawerWidth, menuBreakPoint } from "./commonValue";

const ToolbarIconWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
}));

const StyledDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== "open",
})<DrawerProps>(({ open, theme }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        height: "calc( 100vh )",
        overflow: "auto",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down(menuBreakPoint)]: {
            width: window.innerWidth,
            ...(!open && {
                width: 0,
            }),
        },
        "&::-webkit-scrollbar": {
            width: "10px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            outline: "1px solid slategrey",
        },
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(9),
        }),
    },
}));

function MainDrawer() {
    const open = useSelector((state: RootState) => state.leftmenu);
    const user = useMemo(() => getUser(), []);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleDrawerClose = () => {
        //setOpen(false);
        dispatch({ type: DEF.LEFT_MENU_CLOSE });
    };
    if (!user.userId) {
        return <></>;
    }
    return (
        <StyledDrawer variant="permanent" open={open}>
            <ToolbarIconWrapper>
                <IconButton onClick={handleDrawerClose} size="large">
                    <ChevronLeftIcon />
                </IconButton>
            </ToolbarIconWrapper>
            <Divider />
            <MainListItems menuOpen={open} />
            <Divider />
            <List>
                <MListItem title={t("c.logout")} icon={<ExitToAppIcon />} button onClick={onLogout} menuOpen={open} />
            </List>
        </StyledDrawer>
    );
}

export default MainDrawer;
