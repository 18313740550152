import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List } from "@mui/material";
import { FormApi } from "final-form";

export interface AnchorElInterface {
    form: FormApi;
    name: string;
}

export interface PickerDialogProps<RowType = Record<string, any>, FieldType = any> {
    title: string;
    open?: boolean;
    anchorEl?: AnchorElInterface | null;
    onClose: () => void;
    onSelect?: (item: RowType) => void;
    onClear?: () => void;
    clearable?: boolean;
    data?: RowType[];
    clearedValue?: FieldType;
    renderItem: (row: RowType, onItemSelect: (item: RowType) => void, index: number) => ReactNode;
    formView?: ReactNode;
    idKey?: keyof RowType;
}

function PickerDialog<RowType = Record<string, any>, FieldType = any>({
    title,
    open = false,
    anchorEl = null,
    onClose,
    onSelect,
    onClear,
    clearable = false,
    data = [],
    clearedValue,
    renderItem,
    formView,
    idKey,
}: PickerDialogProps<RowType, FieldType>) {
    const { t } = useTranslation();
    const handleSelect = (item: RowType) => () => {
        if (anchorEl) {
            const itemKey = idKey || (anchorEl.name as keyof typeof item);
            anchorEl.form.change(anchorEl.name, item[itemKey]);
        }
        if (onSelect) {
            onSelect(item);
        }
        onClose();
    };

    const handleClear = () => {
        if (anchorEl) {
            anchorEl.form.change(anchorEl.name, clearedValue);
        }
        if (onClear) {
            onClear();
        }
        onClose();
    };
    const dataList = data.map((row, index) => renderItem(row, handleSelect(row), index));
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle id="picker-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                {formView || <></>}
                <List>{dataList}</List>
            </DialogContent>
            <DialogActions>
                {clearable && (
                    <Button autoFocus onClick={handleClear}>
                        {t("c.clear")}
                    </Button>
                )}
                <Button autoFocus onClick={onClose}>
                    {t("c.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PickerDialog;
