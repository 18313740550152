import React, { useMemo } from "react";
import { SxProps, TableCell, TableRow, Theme } from "@mui/material";
import { TextNumberItem } from "components/final-form";
import { TextField } from "mui-rff";
import { AccountTitleRow } from "pages/accountSetting";
import { formatNumberInput } from "components/CommonNumberFormat";

export interface ReportDataRowProps {
    rowData: AccountTitleRow;
    isEditMode?: boolean;
}

function ReportDataRow({ rowData, isEditMode = false }: ReportDataRowProps) {
    const fieldName = `title${rowData.id}`;
    const inputRules = rowData.negative ? { max: 0 } : { min: 0 };
    const isItemRow = [3, 4].indexOf(rowData.type) > -1;
    const leftSpacing = useMemo<number>(() => {
        const result = [1, 2, 3].reduce((previousValue, currentValue) => {
            const paramName = `l${currentValue}` as keyof AccountTitleRow;
            if (rowData[paramName] && rowData[paramName] !== null) {
                return previousValue + 1;
            }
            return previousValue;
        }, 0);
        return result;
    }, [rowData]);
    const nameCellStyle: SxProps<Theme> = { paddingLeft: (theme) => theme.spacing(leftSpacing) };
    const cellStyle: SxProps<Theme> = isItemRow ? { borderBottom: 0 } : {};
    return (
        <TableRow>
            <TableCell sx={{ ...cellStyle, ...nameCellStyle }}>{rowData.code}</TableCell>
            <TableCell sx={{ ...cellStyle, ...nameCellStyle }}>{rowData.name}</TableCell>
            <TableCell sx={cellStyle}>
                {isEditMode && isItemRow ? (
                    <TextField
                        variant="standard"
                        name={fieldName}
                        InputProps={formatNumberInput({
                            ...inputRules,
                            style: { textAlign: "right" },
                        })}
                    />
                ) : (
                    <TextNumberItem name={fieldName} align="right" />
                )}
            </TableCell>
        </TableRow>
    );
}

export default ReportDataRow;
