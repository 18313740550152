import React from "react";

/**
 * @see https://icooon-mono.com/14955/
 */
export function Promotion() {
    return (
        <svg x={0} y={0} viewBox="0 0 512 512" xmlSpace="preserve" opacity={1}>
            <path d="M228.31 77.506c21.358-1.961 37.07-20.901 35.093-42.259C261.409 13.905 242.486-1.816 221.135.17c-21.358 1.986-37.054 20.893-35.076 42.259 1.986 21.349 20.909 37.069 42.251 35.077zM368.479 388.205c7.133 6.194 17.918 5.605 24.341-1.332l.458-.482c6.406-6.928 6.185-17.682-.499-24.341l-45.045-50.028-16.48-55.888c-.752 1.34-1.536 2.663-2.402 3.922-8.481 12.191-21.841 19.994-36.646 21.359l-6.822.474 17.387 41.997a81.953 81.953 0 0015.14 18.409l50.568 45.91z" />
            <path d="M214.419 351.568c9.168-2.255 14.977-11.275 13.253-20.541l-11.128-59.76 73.455-5.099a34.93 34.93 0 0026.228-14.904 34.866 34.866 0 004.804-29.766l-2.754-9.355-22.968-83.668 39.374 2.132 31.474 30.322c-1.52 1.846-2.28 4.281-1.668 6.798l2.566 10.401-18.294 4.51a8.075 8.075 0 00-5.908 9.78l12.322 50.005c1.078 4.322 5.449 6.97 9.772 5.915l77.671-19.152c4.339-1.078 6.962-5.458 5.9-9.789l-12.338-49.988c-1.063-4.33-5.434-6.986-9.764-5.915l-18.302 4.51-2.574-10.41c-1.046-4.232-5.311-6.798-9.543-5.76l-1.92.474a13.51 13.51 0 00-2.395-5.025l-30.779-40.363a23.6 23.6 0 00-13.384-8.662l-50.626-18.122a56.613 56.613 0 00-53.6 8.4l-70.244 53.894-45.56-16.431c-7.142-3.178-15.484-.123-18.891 6.879l-.474.956a14.561 14.561 0 00-.662 11.12 14.522 14.522 0 007.436 8.309l54.916 26.213a24.841 24.841 0 0021.595-.082l29.268-19.463 20.091 57.587-48.591 4.249a27.519 27.519 0 00-20.247 11.881 27.507 27.507 0 00-3.693 23.189l24.152 82.606c2.68 9.143 12.084 14.568 21.342 12.289l.688-.164zm183.4-192.356l.351.204 2.574 10.402-26.318 6.488-2.483-10.083c4.885 4.004 11.856 4.306 16.929.392l.295-.238a13.47 13.47 0 004.461-6.136l4.191-1.029zM239.266 387.895l-27.021-16.309V512h87.509V364.07l-10.287-5.459zM316.488 512h87.5v-92.623l-87.5-46.426zM420.722 428.258V512h73.733v-44.62zM108.011 512h87.501V361.479l-87.501-52.832zM17.545 512h73.733V298.54l-73.733-44.514z" />
        </svg>
    );
}

/**
 * @see https://icooon-mono.com/15712/
 */
export function WorkTime() {
    return (
        <svg x={0} y={0} viewBox="0 0 512 512" xmlSpace="preserve" opacity={1}>
            <path d="M382.249 186.492c44.544 0 80.654-36.106 80.654-80.654s-36.11-80.654-80.654-80.654c-44.548 0-80.658 36.106-80.658 80.654s36.109 80.654 80.658 80.654zM0 446.003h512v53.3H0zM357.7 431.976h111.517V294.509c0-48.026-38.938-86.973-86.968-86.973-48.034 0-86.972 38.947-86.972 86.973v81.353H357.7v56.114z" />
            <path d="M127.419 431.976H343.67v-42.088H242.498l-42.606-115.724H1.173l61.95 157.812zM60.637 104.537l50.938 39.3c.216.168.473.257.708.408.115.053.213.123.319.177 4.402 2.451 10.239 1.159 14.124-3.124.11-.133.23-.204.345-.336l62.424-73.84c3.553-3.991 3.611-9.725.155-12.805-3.469-3.088-9.15-2.336-12.698 1.646l-58.34 62.336-45.064-31.372c-4.212-3.106-10.531-1.673-14.093 3.212-3.566 4.849-3.044 11.309 1.182 14.398zM107.203 213.005c0 6.867 5.562 12.416 12.42 12.416s12.42-5.549 12.42-12.416c0-6.868-5.562-12.425-12.42-12.425s-12.42 5.557-12.42 12.425zM119.623 37.537c6.858 0 12.42-5.558 12.42-12.424 0-6.867-5.562-12.416-12.42-12.416s-12.42 5.549-12.42 12.416 5.562 12.424 12.42 12.424zM177.264 176.704c-4.849 4.85-4.849 12.716 0 17.566 4.855 4.849 12.721 4.849 17.571 0 4.845-4.841 4.849-12.716 0-17.566-4.855-4.85-12.717-4.85-17.571 0zM61.978 61.422c4.854-4.849 4.854-12.716 0-17.574-4.854-4.85-12.717-4.841-17.566 0-4.845 4.858-4.85 12.726 0 17.574 4.853 4.85 12.716 4.841 17.566 0zM213.565 131.484c6.858 0 12.425-5.558 12.42-12.425.005-6.85-5.558-12.408-12.42-12.408-6.863 0-12.42 5.558-12.42 12.416-.001 6.859 5.557 12.417 12.42 12.417zM25.686 131.484c6.862 0 12.416-5.558 12.42-12.417 0-6.858-5.562-12.433-12.425-12.416-6.862 0-12.416 5.558-12.42 12.408.005 6.867 5.562 12.425 12.425 12.425zM44.416 176.713c-4.85 4.849-4.846 12.716-.005 17.557 4.858 4.849 12.721 4.858 17.579.009 4.85-4.85 4.841-12.726 0-17.566-4.858-4.859-12.725-4.859-17.574 0z" />
        </svg>
    );
}

/**
 * @see https://icooon-mono.com/15006/
 */
export function DocumentSearch() {
    return (
        <svg x={0} y={0} viewBox="0 0 512 512" xmlSpace="preserve" opacity={1}>
            <path d="M105.764 464.839c-28.914 0-52.374-23.371-52.374-52.373V155.473h81.045c24.121 0 43.612-19.498 43.612-43.62V30.818h164.726c28.913 0 52.292 23.371 52.292 52.372v124.487a150.037 150.037 0 0128.09 22.057c.904.911 1.896 1.903 2.72 2.896V83.19C425.875 37.255 388.62 0 342.774 0H165.333l-9.092 9.003L31.585 133.66l-9.004 9.092v269.715c0 45.855 37.335 83.19 83.183 83.19h177.448s-30.293-26.154-38.658-30.817h-138.79z" />
            <path d="M212.502 229.734c9.246-9.246 19.491-17.095 30.479-23.46H109.733v29.414h97.147c1.823-1.986 3.638-3.97 5.622-5.954zM217.125 127.787h119.784v29.43H217.125zM109.733 288.883v29.414h60.142c1.153-9.996 3.218-19.83 6.438-29.414h-66.58zM109.733 400.906h74.51c-4.704-9.504-8.261-19.33-10.818-29.414h-63.692v29.414zM480.483 464.727s-36.634-32.044-45.403-40.788c-12.859-12.803-17.998-18.966-13.464-29.365 19.862-40.498 12.989-90.774-20.693-124.447-42.353-42.361-111.03-42.361-153.384 0-42.354 42.362-42.354 111.023 0 153.384 33.665 33.665 83.972 40.538 124.438 20.684 10.407-4.534 16.579.606 29.374 13.473 8.753 8.761 40.788 45.395 40.788 45.395 16.61 16.61 29.389 6.397 37.077-1.275 7.656-7.672 17.877-20.451 1.267-37.061zm-111.846-73.51c-24.524 24.517-64.28 24.517-88.812 0-24.517-24.517-24.517-64.288 0-88.797 24.532-24.517 64.288-24.517 88.812 0 24.517 24.508 24.517 64.28 0 88.797z" />
        </svg>
    );
}

/**
 * @see https://icooon-mono.com/12033/
 */
export function Agreement() {
    return (
        <svg x={0} y={0} viewBox="0 0 512 512" xmlSpace="preserve" opacity={1}>
            <path d="M56.007 114.35c-5.535-5.539-14.51-5.539-20.045 0L4.148 146.159c-5.531 5.539-5.531 14.506 0 20.046l20.622 20.621 51.859-51.855-20.622-20.621zM286.422 396.623l-17.68-69.546-51.858 51.863zM258.136 316.475L86.058 144.397 34.2 196.26l172.073 172.077 51.863-51.862zM87.468 166.56l149.919 149.922-11.784 11.78L75.684 178.348l11.784-11.788zM195.662 132.491h29.356v28.017h-29.356zM195.662 200.693h29.356v28.009h-29.356zM256.69 132.491h173.056v28.017H256.69zM256.69 200.693h173.056v28.009H256.69zM288.598 268.894h141.148v28.01H288.598z" />
            <path d="M429.817 11.059H195.582c-45.32 0-82.182 36.858-82.182 82.179v32.726l30.427 30.435V93.238c0-28.586 23.178-51.752 51.755-51.752h234.235c28.594 0 51.756 23.166 51.756 51.752V347.28h-80.097c-23.822 0-43.124 19.318-43.124 43.132v80.101h-162.77c-28.578 0-51.755-23.166-51.755-51.752v-37.072l-6.234-1.587 6.234-6.235v-22.202L113.4 321.239v97.522c0 45.313 36.862 82.179 82.182 82.179H370.95l8.917-8.913 123.224-123.224 8.909-8.912V93.238c0-45.321-36.862-82.179-82.183-82.179z" />
        </svg>
    );
}

/**
 * @see https://icooon-mono.com/12046/
 */
export function CheckSheet() {
    return (
        <svg x={0} y={0} viewBox="0 0 512 512" xmlSpace="preserve" opacity={1}>
            <path d="M378.405 0H133.594C86.229 0 47.703 38.526 47.703 85.891v340.217c0 47.358 38.526 85.892 85.892 85.892h183.284l9.319-9.311L454.986 373.9l9.311-9.318V85.891C464.297 38.526 425.771 0 378.405 0zm54.09 351.402h-83.71c-24.898 0-45.072 20.189-45.072 45.078v83.718h-170.12c-29.868 0-54.09-24.215-54.09-54.09V85.891c0-29.875 24.223-54.09 54.09-54.09h244.811c29.883 0 54.09 24.215 54.09 54.09v265.511z" />
            <path d="M133.677 126.915h30.684v29.282h-30.684zM133.677 198.195h30.684v29.276h-30.684zM133.677 269.476h30.684v29.276h-30.684zM133.677 340.756h30.684v29.275h-30.684zM197.461 126.915h180.87v29.282h-180.87zM197.461 198.195h180.87v29.276h-180.87zM197.461 269.476h180.87v29.276h-180.87zM197.461 340.756H271.8v29.275h-74.339z" />
        </svg>
    );
}

export function PaperReject() {
    return (
        <svg xmlSpace="preserve" viewBox="0 0 512 512" y={0} x={0} opacity={1}>
            <g transform="translate(48)">
                <path d="M49.387 453.707C63.727 481.192 100.504 512 133.595 512h183.284l9.319-9.311L454.986 373.9l9.311-9.318V85.891C464.297 38.526 425.771 0 378.405 0H133.594C86.229 0 47.703 38.526 47.703 85.891v83.305s10.497-3.62 15.952-4.494c5.221-.837 15.848-.684 15.848-.684V85.891c0-29.875 24.223-54.09 54.09-54.09h244.811c29.883 0 54.09 24.215 54.09 54.09v265.511h.001-83.71c-24.898 0-45.072 20.189-45.072 45.078v83.718h-170.12c-11.65 0-30.44-15.684-39.27-21.95 0 0-16.079 1.706-24.055.836-7.145-.78-20.88-5.377-20.88-5.377z" />
                <path d="M133.677 126.915h30.684v29.282h-30.684z" />
                <path d="M197.461 126.915h180.87v29.282h-180.87z" />
                <path d="M207.241 198.195h171.09v29.276h-171.09z" />
                <path d="M234.279 269.476h144.052v29.276H234.279z" />
                <path d="M229.101 340.756H271.8v29.275h-42.699z" />
            </g>
            <g transform="matrix(.5146 0 0 .5146 12.656 178.912)" opacity={1}>
                <g>
                    <path d="M413.704 74.977c-99.984-99.969-262.063-99.969-362.047 0-99.969 99.984-99.969 262.063 0 362.047 99.97 99.969 262.078 99.969 362.047 0 99.97-99.969 99.97-262.079 0-362.047zm-299.812 62.234c54.391-54.391 137.016-63.453 201.016-27.531L86.361 338.227c-35.922-64-26.86-146.625 27.531-201.016zm237.594 237.578c-54.39 54.391-137.03 63.469-201.03 27.547l228.562-228.563c35.921 64 26.843 146.641-27.532 201.016z" />
                </g>
            </g>
        </svg>
    );
}

export function PaperReturn() {
    return (
        <svg xmlSpace="preserve" viewBox="0 0 512 512" y={0} x={0} opacity={1}>
            <g transform="translate(48)">
                <path d="M47.839 430.965a86.19 86.19 0 00.248 3.302C52.21 477.813 88.981 512 133.595 512h183.284l9.319-9.311L454.986 373.9l9.311-9.318V85.891C464.297 38.526 425.771 0 378.405 0H133.594C86.229 0 47.703 38.526 47.703 85.891V167l16.937-13.854L79.503 167V85.891c0-29.875 24.223-54.09 54.09-54.09h244.811c29.883 0 54.09 24.215 54.09 54.09v265.511h.001-83.71c-24.898 0-45.072 20.189-45.072 45.078v83.718h-170.12c-28.236 0-53.165-21.542-53.876-49.247z" />
                <path d="M133.677 126.915h30.684v29.282h-30.684z" />
                <path d="M133.677 340.756h30.684v29.275h-30.684z" />
                <path d="M197.461 126.915h180.87v29.282h-180.87z" />
                <path d="M197.461 198.195h180.87v29.276h-180.87z" />
                <path d="M225.572 269.476h152.759v29.276H225.572z" />
                <path d="M224.72 340.756h47.08v29.275h-47.08z" />
            </g>
            <g transform="translate(.602 162.907) scale(.50588)" opacity={1}>
                <g>
                    <path d="M452.421 155.539c-36.6-36.713-87.877-59.612-143.839-59.579h-87.179V9.203L5.513 228.805h303.068c19.702.033 36.924 7.8 49.898 20.659 12.876 12.99 20.644 30.212 20.676 49.914-.032 19.703-7.8 36.924-20.676 49.898-12.974 12.876-30.196 20.642-49.898 20.676H0v132.844h308.582c55.962.033 107.239-22.866 143.839-59.579 36.715-36.6 59.612-87.877 59.579-143.84.033-55.961-22.864-107.237-59.579-143.838z" />
                </g>
            </g>
        </svg>
    );
}

/**
 * @see https://icooon-mono.com/15551/
 */
export function Interviewer() {
    return (
        <svg x={0} y={0} viewBox="0 0 512 512" xmlSpace="preserve" opacity={1}>
            <path d="M315.656 149.531l-14.166 35.403 10.236-1.259c35.35-4.424 69.181-17.656 85.251-24.591 15.266-5.455 28.445-14.772 37.922-26.874 9.56-12.185 15.372-27.337 15.348-43.703.023-10.698-2.481-20.926-6.859-30.099-6.616-13.778-17.466-25.205-30.865-33.21-13.422-8.005-29.47-12.61-46.677-12.626-22.929.016-43.817 8.187-59.174 21.73-7.671 6.775-13.976 14.909-18.362 24.105-4.4 9.173-6.866 19.401-6.866 30.099-.015 13.658 4.021 26.495 10.926 37.436 5.903 9.355 13.885 17.344 23.286 23.589zm-16.95-84.918c5.311-11.137 14.386-20.865 26.017-27.808 11.632-6.942 25.813-11.07 41.124-11.062 20.44-.008 38.801 7.33 51.866 18.885 6.526 5.774 11.738 12.565 15.281 19.984 3.544 7.428 5.47 15.456 5.478 23.893-.008 12.868-4.484 24.857-12.436 35.031-7.936 10.159-19.4 18.369-32.96 23.134l-.189.053-.182.076c-13.9 6.047-42.041 17.018-71.936 22.215l10.137-25.288-4.97-2.838c-10.053-5.743-18.27-13.558-23.93-22.519-5.66-9.006-8.763-19.097-8.778-29.864 0-8.436 1.927-16.464 5.478-23.892z" />
            <path d="M332.553 100.6c5.069 0 9.173-4.583 9.173-10.243 0-5.63-4.104-10.22-9.173-10.22-5.046 0-9.158 4.59-9.158 10.22 0 5.66 4.113 10.243 9.158 10.243zM367.918 100.6c5.053 0 9.166-4.583 9.166-10.243 0-5.63-4.112-10.22-9.166-10.22-5.069 0-9.181 4.59-9.181 10.22 0 5.66 4.112 10.243 9.181 10.243zM403.259 100.6c5.068 0 9.166-4.583 9.166-10.243 0-5.63-4.098-10.22-9.166-10.22-5.068 0-9.166 4.59-9.166 10.22.001 5.66 4.098 10.243 9.166 10.243zM511.099 484.064l-81.428-189.175a11.024 11.024 0 00-10.122-6.662H92.46a11.03 11.03 0 00-10.122 6.654L.895 484.064a11.047 11.047 0 00.91 10.41 11.046 11.046 0 009.211 4.954h153.462c13.096-22.148 35.691-38.218 67.391-43.415 7.299 2.42 15.084 3.786 23.187 3.786 8.103 0 15.888-1.366 23.187-3.786 31.685 5.197 54.28 21.267 67.376 43.415h155.373c3.703 0 7.163-1.866 9.196-4.954a11.009 11.009 0 00.911-10.41zm-199.153-98.005c0 31.419-25.478 56.89-56.89 56.89-31.427 0-56.89-25.472-56.89-56.89v-16.146c0-31.42 25.463-56.89 56.89-56.89 31.412 0 56.89 25.471 56.89 56.89v16.146zM316.718 264.335c0-27.626-16.214-50.388-47.186-55.47a42.81 42.81 0 01-13.536 2.215c-4.742 0-9.287-.796-13.551-2.215-30.971 5.083-47.178 27.845-47.178 55.47h121.451zM255.997 201.231c18.346 0 33.232-14.878 33.232-33.232v-9.431c0-18.362-14.886-33.24-33.232-33.24-18.361 0-33.248 14.878-33.248 33.24v9.431c0 18.353 14.887 33.232 33.248 33.232z" />
        </svg>
    );
}

/**
 * @see https://icooon-mono.com/15551/
 */
export function DollarBag() {
    return (
        <svg x={0} y={0} viewBox="0 0 512 512" xmlSpace="preserve" opacity={1}>
            <path d="M152.226 109.296h207.563L400.32 33.14c3.203-5.625 4.313-16.891-12.594-24.406C363.835-1.891 318.257 44.422 258.163 20 194.898-5.704 149.242-2.517 128.585 6.858c-20.656 9.391-16.906 26.281-16.906 26.281l40.547 76.157z" />
            <path d="M466.977 182.202l-47.969-28.906 47.969-28.875c9.469-5.719 12.531-18 6.828-27.469s-17.984-12.5-27.453-6.813l-71.672 43.156H129.632c-11.047 0-20 8.969-20 20 0 11.063 8.953 20 20 20h245.047l71.672 43.156c9.469 5.719 21.75 2.656 27.453-6.813s2.641-21.748-6.827-27.436z" />
            <path d="M466.039 356.14c-13.906-21.438-65.219-104.781-98.407-158.844H144.367c-33.188 54.063-84.5 137.406-98.406 158.844C.882 425.608-.993 511.999 85.398 511.999h341.219c86.375 0 84.5-86.391 39.422-155.859zm-151.844 43.781c-3.25 6.094-7.719 11.188-13.375 15.344-5.672 4.156-12.469 7.25-20.359 9.281-3.453.875-7.063 1.484-10.734 1.984v22.984h-27.438v-22.531c-7.813-.656-15.281-1.875-22.313-3.813-10.75-2.938-24.813-14.813-24.813-14.813-1.203-.703-2-1.938-2.172-3.313s.297-2.766 1.281-3.75l13.75-13.75a4.537 4.537 0 015.563-.656s10.297 8.938 18.094 11.063 15.531 3.188 23.25 3.188c9.734 0 17.781-1.719 24.156-5.156 6.391-3.469 9.578-8.813 9.578-16.125 0-5.266-1.563-9.422-4.719-12.469-3.141-3.031-8.453-4.938-15.953-5.766l-24.625-2.125c-14.578-1.422-25.828-5.484-33.734-12.156-7.922-6.688-11.859-16.828-11.859-30.391 0-7.5 1.516-14.188 4.563-20.063s7.188-10.844 12.469-14.891c5.266-4.047 11.406-7.094 18.391-9.109 2.922-.844 5.984-1.406 9.094-1.906v-19.813h27.438v19.406c6.406.625 12.5 1.656 18.172 3.219 9.625 2.625 19.734 10.531 19.734 10.531 1.266.656 2.125 1.891 2.344 3.297.219 1.422-.25 2.828-1.25 3.859l-12.891 13.094a4.486 4.486 0 01-5.25.844s-7.641-5.438-14.234-7.156-13.516-2.594-20.813-2.594c-9.531 0-16.578 1.828-21.125 5.469-4.563 3.656-6.828 8.422-6.828 14.281 0 5.281 1.594 9.328 4.859 12.156 3.234 2.844 8.703 4.688 16.406 5.469l21.578 1.844c16 1.406 28.109 5.656 36.313 12.75 8.219 7.094 12.313 17.438 12.313 31-.002 8.127-1.627 15.205-4.861 21.283z" />
        </svg>
    );
}

export function ClipboardPlusOutline() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14a2 2 0 002 2h14c1.11 0 2-.89 2-2V5a2 2 0 00-2-2m-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1M7 7h10V5h2v14H5V5h2v2m6 5h3v2h-3v3h-2v-3H8v-2h3V9h2v3z" />
        </svg>
    );
}

export function ClipboardEditOutline() {
    return (
        <svg viewBox="0 0 24 24">
            <svg viewBox="0 0 24 24">
                <path d="M21.04 12.13c.14 0 .27.06.38.17l1.28 1.28c.22.21.22.56 0 .77l-1 1-2.05-2.05 1-1c.11-.11.25-.17.39-.17m-1.97 1.75l2.05 2.05L15.06 22H13v-2.06l6.07-6.06M11 19l-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h4.18C9.6 1.84 10.7 1 12 1c1.3 0 2.4.84 2.82 2H19c1.1 0 2 .9 2 2v4l-2 2V5h-2v2H7V5H5v14h6m1-16c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" />
            </svg>
        </svg>
    );
}

export function ClipboardCheckOutline() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2m-7 0a1 1 0 011 1 1 1 0 01-1 1 1 1 0 01-1-1 1 1 0 011-1M7 7h10V5h2v14H5V5h2v2m.5 6.5L9 12l2 2 4.5-4.5L17 11l-6 6-3.5-3.5z" />
        </svg>
    );
}

export function ClipboardDeleteOutline() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M12 1c-1.3 0-2.4.84-2.82 2H5a2 2 0 00-2 2v14a2 2 0 002 2h7.412v-2H5V5h2v2h10V5h2v2.879h1.016c.375-.002.704.115.984.326V5a2 2 0 00-2-2h-4.18C14.4 1.84 13.3 1 12 1zm0 2a1 1 0 110 2 1 1 0 010-2zm4.514 6.344a.18.18 0 00-.127.052l-.649.649h-2.383a.18.18 0 00-.18.18v1.404a.18.18 0 00.18.18h9.827a.18.18 0 00.18-.18v-1.404a.18.18 0 00-.18-.18h-2.383l-.649-.649a.18.18 0 00-.127-.052h-3.51zm-2.457 3.203a.18.18 0 00-.18.18v8.421c0 .415.172.826.465 1.12a1.6 1.6 0 001.119.464h5.615a1.6 1.6 0 001.12-.464 1.6 1.6 0 00.464-1.12v-8.421a.18.18 0 00-.18-.18h-8.423zm1.584 1.762h5.255v6.66h-5.255v-6.66z" />
        </svg>
    );
}

export function AccountTie() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M12 3a4 4 0 014 4 4 4 0 01-4 4 4 4 0 01-4-4 4 4 0 014-4m4 10.54c0 1.06-.28 3.53-2.19 6.29L13 15l.94-1.88c-.62-.07-1.27-.12-1.94-.12-.67 0-1.32.05-1.94.12L11 15l-.81 4.83C8.28 17.07 8 14.6 8 13.54c-2.39.7-4 1.96-4 3.46v4h16v-4c0-1.5-1.6-2.76-4-3.46z" />
        </svg>
    );
}

export function PaySalary() {
    return (
        <svg x={0} y={0} viewBox="0 0 512 512" xmlSpace="preserve" opacity={1}>
            <path d="M0,116v280h512v-8V116H0z M496,380H16V132h480V380z"></path>
            <path
                d="M80.578,357.281h350.844c4.984-20.375,21.453-36.219,42.172-40.234V194.953
		c-20.719-4.031-37.188-19.875-42.172-40.234H80.578c-4.984,20.359-21.453,36.203-42.172,40.234v122.094
		C59.125,321.063,75.594,336.906,80.578,357.281z M400.344,235.984c11.031,0,20,8.953,20,20s-8.969,20-20,20
		c-11.063,0-20-8.953-20-20S389.281,235.984,400.344,235.984z M256,171.656c46.594,0,84.344,37.766,84.344,84.328
		c0,46.594-37.75,84.359-84.344,84.359c-46.563,0-84.344-37.766-84.344-84.359C171.656,209.422,209.438,171.656,256,171.656z
        M111.656,235.984c11.063,0,20,8.953,20,20s-8.938,20-20,20c-11.031,0-20-8.953-20-20S100.625,235.984,111.656,235.984z"
            ></path>
            <path
                d="M219.969,290.375c0,0,8.328,7.031,14.688,8.781c4.156,1.125,8.594,1.844,13.219,2.25v13.344h16.25v-13.625
		c2.172-0.281,4.313-0.656,6.344-1.156c4.688-1.219,8.719-3.063,12.063-5.5c3.344-2.469,6-5.5,7.938-9.094
		c1.906-3.594,2.875-7.813,2.875-12.625c0-8.031-2.438-14.156-7.297-18.344c-4.859-4.219-12.031-6.719-21.516-7.563l-12.781-1.094
		c-4.563-0.438-7.797-1.531-9.719-3.219s-2.875-4.094-2.875-7.219c0-3.469,1.344-6.281,4.063-8.438
		c2.688-2.188,6.859-3.25,12.5-3.25c4.313,0,8.406,0.5,12.328,1.531c3.906,1,8.422,4.25,8.422,4.25
		c1.047,0.531,2.313,0.313,3.125-0.5l7.625-7.75c0.594-0.625,0.875-1.469,0.75-2.313c-0.125-0.813-0.641-1.563-1.391-1.938
		c0,0-5.984-4.688-11.703-6.25c-3.344-0.906-6.969-1.531-10.75-1.906v-11.5h-16.25V209c-1.844,0.281-3.656,0.625-5.406,1.125
		c-4.125,1.188-7.75,3-10.875,5.406c-3.125,2.375-5.578,5.344-7.375,8.813c-1.813,3.469-2.719,7.438-2.719,11.875
		c0,8.031,2.344,14.031,7.031,18c4.672,3.969,11.344,6.375,19.969,7.219l14.594,1.25c4.438,0.5,7.594,1.625,9.453,3.406
		c1.859,1.813,2.797,4.281,2.797,7.406c0,4.313-1.891,7.5-5.688,9.531c-3.781,2.031-8.531,3.063-14.313,3.063
		c-4.563,0-9.141-0.625-13.75-1.875c-4.625-1.281-10.719-6.563-10.719-6.563c-1.063-0.656-2.422-0.5-3.297,0.375l-8.141,8.156
		c-0.594,0.594-0.859,1.406-0.766,2.219C218.781,289.219,219.25,289.938,219.969,290.375z"
            ></path>
        </svg>
    );
}

export function MoneyTransfer() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M8.331 8.508H1.263a.88.88 0 00-.883.883v5.301c0 .49.398.883.883.883h7.068a.88.88 0 00.884-.883v-5.3a.883.883 0 00-.884-.884m-2.209 2.65H4.356v.442H5.68c.243 0 .441.199.441.442v1.325a.443.443 0 01-.441.442h-.442v.441h-.883v-.441h-.884v-.884H5.24v-.442H3.914a.443.443 0 01-.442-.441v-1.326c0-.242.199-.441.442-.441h.442v-.442h.883v.442h.883zM22.758 8.508H15.69a.88.88 0 00-.883.883v5.301c0 .49.398.883.883.883h7.068a.88.88 0 00.884-.883v-5.3a.883.883 0 00-.884-.884m-2.209 2.65h-1.766v.442h1.325c.243 0 .441.199.441.442v1.325a.443.443 0 01-.441.442h-.442v.441h-.883v-.441h-.884v-.884h1.767v-.442h-1.325a.443.443 0 01-.442-.441v-1.326c0-.242.199-.441.442-.441h.442v-.442h.883v.442h.883zM17.597 3.233c-1.559-1.348-3.58-2.19-5.814-2.19-3.918 0-7.229 2.553-8.392 6.083l1.989.657a6.742 6.742 0 016.403-4.634c1.643 0 3.143.607 4.314 1.584l-3.05 3.05h7.583V.2zM6.424 20.85c1.56 1.348 3.581 2.19 5.814 2.19 3.918 0 7.23-2.553 8.392-6.083l-1.988-.657a6.742 6.742 0 01-6.404 4.634 6.718 6.718 0 01-4.314-1.584l3.05-3.05H3.391v7.583z" />
        </svg>
    );
}

/**
 * @see https://remixicon.com/
 */
export function RiDollarBookFill() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M5.95 2A3 3 0 003 5v13.5A3.5 3.5 0 006.5 22H20a1 1 0 001-1V3a1 1 0 00-1-1H6a3 3 0 00-.05 0zm5.161 2.363h1.774v1.75h2.217v1.75h-4.875c-.591 0-.591.875 0 .875h3.544c2.957.002 2.957 4.379 0 4.377h-.886v1.75H11.11v-1.75H8.896v-1.75h4.875c.591 0 .591-.875 0-.875h-3.544c-2.954.002-2.954-4.375 0-4.377h.884v-1.75zM6.5 17H19v3H6.5a1.5 1.5 0 010-3z" />
        </svg>
    );
}

export function PLGrid() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M12.87 2v2H14v3.18h2V4h4v4h-3.13v2H20v4h-4v-1.04h-2V14h-4v-1.04H8V14H4v-1.04H2V20c0 1.1.92 2 2 2h16a2 2 0 002-2V4a2 2 0 00-2-2h-7.13zM7.087 8.627c-.48.211-1.01.334-1.57.334h-.11V10h1.68V8.627zM4 16h4v4H4v-4zm6 0h4v4h-4v-4zm6 0h4v4h-4v-4zM1.518 1.07v10h2v-4h2a2 2 0 002-2v-2a2 2 0 00-2-2h-4m2 2h2v2h-2z" />
            <path d="M8.98 1.07v10h6v-2h-4v-8z" />
        </svg>
    );
}

export function MoneyExchange() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M5.877 2.057V4.19c-1.784.57-3.142 1.925-3.142 3.635 0 2.796 2.659 3.457 4.566 3.933 1.957.472 2.62.767 2.62 1.458 0 .69-.977 1.417-2.317 1.417-1.34 0-2.315-.727-2.315-1.417v-.38H2.735v.38c0 1.71 1.358 3.064 3.142 3.634v2.135h3.454V16.85c1.118-.357 2.068-1.022 2.623-1.89v-.621h.306c.135-.353.215-.727.215-1.123 0-.331-.039-.63-.107-.905l-.551-1.125c-.91-1.154-2.588-1.574-3.908-1.904-1.957-.471-2.62-.767-2.62-1.457 0-.691.975-1.418 2.315-1.418 1.34 0 2.316.727 2.316 1.418v.379h2.555v-.38c0-1.71-1.36-3.063-3.144-3.634V2.057zm6.213 8.655l1.994 4.082h-1.677v1.771h2.54l.276.56h-2.816v1.772h2.916v2.914h1.771v-2.914h2.914v-1.771h-2.814l.273-.56h2.541v-1.772h-1.677l1.994-4.082H18.35l-2.143 4.394-2.142-4.394z" />
        </svg>
    );
}

export function WorkFlow() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M5.979 1.473a4.109 4.109 0 00-4.122 4.12 4.093 4.093 0 002.805 3.89L5.98 8.165l1.316 1.316A4.092 4.092 0 009.789 7.16H8.471V4.027h1.318a4.105 4.105 0 00-3.81-2.554zm12.304.129L15.566 4.32l1.274 1.274-1.274 1.273L17.1 8.402h2.367l2.808-2.808-3.992-3.992zm-5.246.992v2h-4v2h4v2l3-3-3-3zM5.98 8.969l-3 3h2v4h2v-4h2l-3-3zm11.304 0v4h-2l3 3 3-3h-2v-4h-2zM1.857 14.232v8.243H10.1v-8.243H7.545v2.303H4.412v-2.303H1.857zm14.422.536a4.093 4.093 0 00-2.117 3.586 4.109 4.109 0 004.121 4.12 4.109 4.109 0 004.121-4.12 4.093 4.093 0 00-2.117-3.586l-2.004 2.003-2.004-2.003z" />
        </svg>
    );
}

export function ClockHome() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M11.98 2C6.465 2.01 2 6.485 2 12c0 5.5 4.47 10 10 10 .16 0 .32-.004.479-.012v-2.002a8 8 0 117.361-6.389l1.668 1.5A10 10 0 0012 2zM11 7v6l2.693 1.615 1.11-.998L12.5 12.25V7zm6.332 5.104l-6.125 5.511h1.838v4.9h3.062v-3.673h2.45v3.674h3.062v-4.9h1.838z" />
        </svg>
    );
}

export function ClockOffice() {
    return (
        <svg viewBox="0 0 24 24">
            <path d="M11.98 2A10 10 0 002 12c0 5.5 4.47 10 10 10a10 10 0 002.164-.236V19.7A8 8 0 0112 20a8 8 0 01-8-8 8 8 0 018-8 8 8 0 018 7.984h2A10 10 0 0012 2a10 10 0 00-.02 0zM11 7v6l3.164 1.898v-1.66L12.5 12.25V7H11zm3.73 5.55v10.214h3.405v-1.987h1.135v1.987h3.404V12.55H14.73zm1.135 1.136H17v1.134h-1.135v-1.134zm2.27 0h1.135v1.134h-1.135v-1.134zm2.27 0h1.134v1.134h-1.135v-1.134zm.566.566v.002h.002v-.002h-.002zm-5.106 1.703H17v1.135h-1.135v-1.135zm2.27 0h1.135v1.135h-1.135v-1.135zm2.27 0h1.134v1.135h-1.135v-1.135zm-1.704.566v.002h.002v-.002h-.002zm-2.836 1.704H17v1.134h-1.135v-1.134zm2.27 0h1.135v1.134h-1.135v-1.134zm2.27 0h1.134v1.134h-1.135v-1.134zm-3.973.566v.002h.002v-.002h-.002zm2.27 0v.002h.001v-.002h-.002zm-2.837 1.703H17v1.135h-1.135v-1.135zm4.54 0h1.134v1.135h-1.135v-1.135z" />
        </svg>
    );
}
