import React from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    ModalProps,
    ButtonProps,
    SlideProps,
    Breakpoint,
} from "@mui/material";

export interface ConfirmDialogProps {
    title?: string;
    content: any;
    open: boolean;
    maxWidth?: false | Breakpoint;
    handleClose: ModalProps["onClose"];
    handleAgree?: () => void;
    handleDisagree?: () => void;
    agreeButtonProps?: ButtonProps;
    disagreeButtonProps?: ButtonProps;
}

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmDialog({
    title,
    content,
    open,
    handleClose,
    maxWidth = "md",
    handleAgree = () => {},
    handleDisagree = () => {},
    disagreeButtonProps = {},
    agreeButtonProps = {},
}: ConfirmDialogProps) {
    const { t } = useTranslation();
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                onClose={handleClose}
                maxWidth={maxWidth}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                    {typeof content === "string" ? (
                        <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>
                    ) : (
                        content
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        {...disagreeButtonProps}
                        onClick={(e) => {
                            handleDisagree();
                            handleClose && handleClose(e, "backdropClick");
                        }}
                    >
                        {t("c.cancel")}
                    </Button>
                    <Button
                        {...agreeButtonProps}
                        variant="contained"
                        onClick={(e) => {
                            handleAgree();
                            handleClose && handleClose(e, "backdropClick");
                        }}
                        color="primary"
                    >
                        {t("c.confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConfirmDialog;
