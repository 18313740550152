import React from "react";
// import { useHistory, useLocation } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import { Container, Typography, CssBaseline } from "@mui/material";

// const useStyles = makeStyles((theme) =>
//     createStyles({
//         paper: {
//             marginTop: theme.spacing(8),
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//         },
//         logo: {
//             width: theme.spacing(15),
//             marginBottom: theme.spacing(3),
//         },
//     })
// );

function Construction() {
    // const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div>
                <img alt="Mirai Logo" src="/images/logo/footer-logo-mirai.png" />
                <Typography component="h1" variant="h5">
                    Under construction
                </Typography>
            </div>
        </Container>
    );
}
export default Construction;
