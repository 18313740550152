import React from "react";
import { Column } from "@material-table/core";
import AuditStatus from "components/audit/AuditStatus";
import { TFunction } from "react-i18next";
import DateRenderer from "components/grid/DateRenderer";
import AuditRowData from "components/audit/AuditRowData";
import { LookupMaps } from "pages/audit/ColumnsFactory";

export interface ColumnsDesc {
    type: string;
    formAction: (rowId: string) => void;
}

function ColumnsFactory(columnsDesc: ColumnsDesc, t: TFunction, map: LookupMaps) {
    const { formLookupMap } = map;
    const formTypeData = t("audit.formType", { returnObjects: true }) as Record<string, string>;
    const defaultColumns: Column<AuditRowData>[] = [
        {
            title: t("audit.fd.no"),
            field: "no",
            type: "string",
        },
        {
            title: t("audit.fd.createdAt"),
            field: "createdAt",
            type: "date",
            filtering: false,
            render: (row) => DateRenderer(row.createdAt),
        },
        {
            title: t("audit.fd.type"),
            field: "formType",
            type: "string",
            lookup: formTypeData,
        },
        {
            title: t("e.form"),
            field: "formId",
            type: "string",
            lookup: formLookupMap,
        },
        {
            title: t("audit.fd.status"),
            field: "status",
            type: "string",
            filtering: false,
            render: (row) => <AuditStatus statusId={row.status} />,
        },
    ];

    return defaultColumns;
}

export default ColumnsFactory;
