import { DEF } from "Action";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { of, Subscription } from "rxjs";
import { RootState } from "layout/types";
import useReduxTempDataReloadFlag from "./useReduxTempDataReloadFlag";

const emptyFunction = () => {};

export interface EmployeeDepartment {
    employeeId: number;
    departmentId: number;
    workPositionId: number;
    department: {
        name: string;
    };
    workPosition: {
        name: string;
    };
}

export interface EmployeePickRow {
    id: number;
    employeeNo: string;
    name: string;
    enName: string;
    mainEmployeeDepartment?: EmployeeDepartment | null;
}

export const flagName = "employeeData";

/**
 * fetch employee data from backend (database).
 *
 * @param function for data processing after data loaded.
 * @param initVal inital data before data loaded.
 */
function useEmployeeData<DataType = any>(
    process?: ((data: EmployeePickRow[]) => DataType) | null,
    initVal?: DataType
): [DataType, () => void] {
    const dispatch = useDispatch();
    const data = useSelector((state: RootState) => state.employee);
    const flagData = useSelector((state: RootState) => state.tempReloadFlag);
    const { removeFlag } = useReduxTempDataReloadFlag(flagName);

    useEffect(() => {
        //console.log("GET_EMPLOYEE_DATA MOUNT");
        const flag = !!flagData[flagName];
        let subscribe: Subscription | null = null;
        if (!data || flag) {
            const source = of({ type: DEF.GET_EMPLOYEE_DATA });
            subscribe = source.subscribe((val) => dispatch(val));
            if (flag) {
                removeFlag();
            }
        }
        return () => {
            //console.log("GET_EMPLOYEE_DATA UNMOUNT");
            subscribe && subscribe.unsubscribe();
        };
        // eslint-disable-next-line
    }, []);
    if (!data) {
        return [initVal || ([] as any), emptyFunction];
    }
    return [process ? process(data) : (data as any), emptyFunction];
}
export default useEmployeeData;
