import React from "react";
import { ShowErrorFunc, showErrorOnChange } from "mui-rff";
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";
import pickerProviderWrapper from "./PickerProvider";

export interface DatePickerProps extends Partial<Omit<MuiDatePickerProps, "onChange">> {
    name: string;
    locale?: any;
    fieldProps?: Partial<FieldProps<any, any>>;
    required?: boolean;
    variant?: "standard" | "filled" | "outlined";
    showError?: ShowErrorFunc;
}

export function DatePicker(props: DatePickerProps) {
    const { name, fieldProps, required, ...rest } = props;
    return (
        <Field
            name={name}
            render={(fieldRenderProps) => <DatePickerWrapper required={required} {...fieldRenderProps} {...rest} />}
            {...fieldProps}
        />
    );
}

interface DatePickerWrapperProps extends FieldRenderProps<MuiDatePickerProps> {
    required?: boolean;
    variant?: "standard" | "filled" | "outlined";
    locale?: any;
}

function DatePickerWrapper(props: DatePickerWrapperProps) {
    const {
        input: { name, onChange, value, ...restInput },
        meta,
        locale,
        required,
        variant,
        showError = showErrorOnChange,
        ...rest
    } = props;

    const { error, submitError } = meta;
    const isError = showError({ meta });

    const { helperText, ...lessrest } = rest;
    return pickerProviderWrapper(
        <MuiDatePicker
            onChange={onChange}
            value={(value as any) === "" ? null : value}
            {...lessrest}
            renderInput={(props) => (
                <TextField
                    fullWidth={true}
                    helperText={isError ? error || submitError : helperText}
                    name={name}
                    variant={variant}
                    required={required}
                    {...restInput}
                    {...props}
                    error={isError}
                />
            )}
        />,
        locale
    );
}
