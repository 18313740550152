import React, { ChangeEvent, useState, useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import {
    InputAdornment,
    ListItem,
    ListItemText,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    List,
    DialogProps,
    ListItemIcon,
    Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useReduxTempData, EmployeePickRow } from "hooks";

export interface EmployeeMultiSelectorDialogProps extends DialogProps {
    onConfirm?: (ids: number[]) => void;
    title?: string;
    existedItems?: number[];
    filter?: (row: EmployeePickRow) => boolean;
}

function EmployeeMultiSelectorDialog({
    title,
    existedItems = [],
    filter,
    open,
    onClose,
    onConfirm,
    ...props
}: EmployeeMultiSelectorDialogProps) {
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState<string | null>(null);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const listData = useReduxTempData<EmployeePickRow[]>("/api/employee/picker", t("e.emp"));

    const selectableData = useMemo<EmployeePickRow[]>(() => {
        if (listData) {
            return listData.filter((item) => existedItems.indexOf(item.id) === -1);
        }
        return [];
    }, [listData, existedItems]);

    const handleItemSelect = (item: EmployeePickRow) => () => {
        const value = item.id;
        const currentIndex = selectedItems.indexOf(value);
        const newSelected = [...selectedItems];
        if (currentIndex === -1) {
            newSelected.push(value);
        } else {
            newSelected.splice(currentIndex, 1);
        }
        setSelectedItems(newSelected);
    };

    const listToShow = useMemo<EmployeePickRow[]>(() => {
        if (selectableData) {
            const mList = filter ? selectableData.filter(filter) : selectableData;
            if (searchString && searchString !== "") {
                return selectableData.filter((row) => row.name.includes(searchString));
            } else {
                return mList;
            }
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, searchString, selectableData]);

    const renderItem = (row: EmployeePickRow, idx: number) => {
        const labelId = `checkbox-employee-list-label-${row.id}`;

        return (
            <ListItem button key={idx} onClick={handleItemSelect(row)}>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={selectedItems.indexOf(row.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                    />
                </ListItemIcon>
                <ListItemText
                    id={labelId}
                    primary={`${row.employeeNo} ${row.name}`}
                    secondary={
                        <Typography component="span" variant="body2">
                            {row.mainEmployeeDepartment
                                ? `${row.mainEmployeeDepartment.department.name} ${row.mainEmployeeDepartment.workPosition.name}`
                                : ""}
                        </Typography>
                    }
                />
            </ListItem>
        );
    };

    const handleClose = (event?: MouseEvent) => {
        setSelectedItems([]);
        onClose && onClose(event || {}, "backdropClick");
    };
    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };
    const dataList = listToShow.map((row, index) => renderItem(row, index));
    const handleConfirm = () => {
        if (selectedItems.length > 0) {
            onConfirm && onConfirm([...selectedItems]);
        }
        handleClose();
        //console.log(selectedItems);
    };

    return (
        <Dialog {...props} open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle id="picker-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                <TextField
                    label={t("c.search")}
                    onChange={handleTextChange}
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
                <List>{dataList}</List>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleConfirm}>
                    {t("c.add")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EmployeeMultiSelectorDialog;
