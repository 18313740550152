import React, { useState, ChangeEvent, RefObject, MutableRefObject, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextField, TextFieldProps, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export type SearchTextFieldBaseProps = Omit<TextFieldProps, "label">;

export type SearchTextFieldProps = Omit<SearchTextFieldBaseProps, "value" | "onChange"> & {
    tableRef: RefObject<any> | MutableRefObject<undefined>;
};

function SearchTextField({ tableRef, ...props }: SearchTextFieldProps) {
    const [searchText, setSearchText] = useState<string | undefined>(undefined);
    const doSearch = useCallback(() => {
        if (tableRef.current) {
            const text = searchText || "";
            const tableProps = tableRef.current.props;
            const tableDataProp = tableProps.data;
            if (Array.isArray(tableDataProp)) {
                tableRef.current.dataManager.changeSearchText(text);
                tableRef.current.dataManager.searchData();
                const tableState = tableRef.current.dataManager.getRenderState();
                tableRef.current.setState({ ...tableState });
            }
            if (tableDataProp instanceof Function) {
                const query = tableRef.current.state.query;
                const mQuery = {
                    ...query,
                    search: text,
                };
                tableRef.current.onQueryChange(mQuery);
            }
        }
    }, [tableRef, searchText]);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };
    useEffect(() => {
        doSearch();
    }, [doSearch, searchText]);
    return <SearchTextFieldBase {...props} value={searchText} onChange={handleChange} />;
}

export function SearchTextFieldBase({ inputProps, InputProps, ...props }: SearchTextFieldBaseProps) {
    const { t } = useTranslation();
    return (
        <TextField
            {...props}
            label={t("c.search")}
            inputProps={{ ...inputProps, maxLength: 20 }}
            variant="outlined"
            InputProps={{
                ...InputProps,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            size="small"
            fullWidth
        />
    );
}

export default SearchTextField;
