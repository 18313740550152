import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from "@mui/material";
export default function SearchDialog({ tableRef, ...props }) {
    const [searchText, setSearchText] = useState("");
    const { t } = useTranslation();
    const handleTextChange = (e) => {
        setSearchText(e.target.value);
    };

    const doSearch = (text) => {
        if (tableRef.current) {
            const tableProps = tableRef.current.props;
            const tableDataProp = tableProps.data;
            if (Array.isArray(tableDataProp)) {
                tableRef.current.dataManager.changeSearchText(text);
                tableRef.current.dataManager.searchData();
                const tableState = tableRef.current.dataManager.getRenderState();
                tableRef.current.setState({ ...tableState });
            }
            if (tableDataProp instanceof Function) {
                const query = tableRef.current.state.query;
                const mQuery = {
                    ...query,
                    search: text,
                };
                tableRef.current.onQueryChange(mQuery);
            }
        }
        props.onClose();
    };

    const handleSearch = () => {
        doSearch(searchText);
    };

    const handleClear = () => {
        if (searchText === "") {
            props.onClose();
            return;
        }
        setSearchText("");
        doSearch("");
    };

    return (
        <Dialog {...props} aria-labelledby="search-dialog-title">
            <DialogTitle id="search-dialog-title">{t("c.search")}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label={t("ui.searchWords")}
                    value={searchText}
                    onChange={handleTextChange}
                    inputProps={{ maxLength: 20 }}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleClear}>
                    {t("c.clear")}
                </Button>
                <Button color="primary" onClick={handleSearch}>
                    {t("c.search")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SearchDialog.propTypes = {
    tableRef: PropTypes.shape({
        current: PropTypes.object,
    }),
    onClose: PropTypes.func,
};
