import React from "react";
import { Field } from "react-final-form";
import { Alert } from "@mui/material";

function BLAlertFields() {
    return (
        <Field name="assetTotal">
            {({ meta }) => {
                const error = meta.error;
                if (error) {
                    return <Alert severity="error">{error}</Alert>;
                }
                return <></>;
            }}
        </Field>
    );
}

export default BLAlertFields;
