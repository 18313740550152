import React from "react";
import { useTranslation } from "react-i18next";
import { EmployeeDepartment, TimestampRowData, useFormTable } from "hooks";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { Column } from "@material-table/core";
import { dateFormat } from "helpers";
interface EmployeeFlowSetting extends TimestampRowData {
    type: number;
}
export interface ApprovalRowData extends TimestampRowData {
    id: number;
    name: string; //員工姓名
    employeeNo?: string; //員編
    mainEmployeeDepartment: EmployeeDepartment;
    employeeFlowSetting: EmployeeFlowSetting;
}

function BasicApprovalTable({
    options = {},
    tableRef,
    ...props
}: Omit<CustomMaterialTableProps<ApprovalRowData>, "columns" | "data">) {
    const formTable = useFormTable<ApprovalRowData>("/api/employee/");
    const { t } = useTranslation();
    const flowType = t("approval.type", { returnObjects: true }) as Record<string, string>;
    const columns: Column<ApprovalRowData>[] = [
        {
            title: t("employee.fd.employeeNo"),
            field: "employeeNo",
            type: "string",
        },
        {
            title: t("employee.fd.name"),
            field: "name",
            type: "string",
        },
        {
            title: t("e.depart"),
            field: "mainEmployeeDepartment.department.name",
            type: "string",
        },
        {
            title: t("approval.fd.type"),
            field: "employeeFlowSetting.type",
            type: "string",
            lookup: flowType,
        },
        {
            title: t("c.fd.updatedAt"),
            field: "employeeFlowSetting.updatedAt",
            type: "string",
            filtering: false,
            render: (row) => {
                if (row.employeeFlowSetting !== null) {
                    return dateFormat(row.employeeFlowSetting.updatedAt, "YYYY/MM/DD");
                }
                return "";
            },
        },
    ];
    return (
        <MaterialTable<ApprovalRowData>
            {...props}
            tableRef={tableRef}
            columns={columns}
            data={formTable.fetch()}
            options={{
                pageSizeOptions: [5, 10, 20],
                toolbar: false,
                sorting: true,
                ...options,
            }}
        />
    );
}

export default BasicApprovalTable;
