import React, { useMemo } from "react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import MaterialTable, { CustomMaterialTableProps, Column } from "components/customMaterialTable/CustomMaterialTable";
import { mtFormatNumber as mfn } from "components/CommonNumberFormat";
import { TimestampRowData, useFormTable } from "hooks";
import { getSourceI18n } from "./sourceI18nMapping";

export interface AccountVoucherRowData extends TimestampRowData {
    id?: number;
    noPrefix: string;
    no: number;
    noString: string;
    date: Date | Moment;
    source?: string;
    sourceId?: number;
    status: number;
    totalDebit: number;
    totalCredit: number;
    remark?: string;
}

function AccountVoucherSearchTable({
    options = {},
    ...props
}: Partial<CustomMaterialTableProps<AccountVoucherRowData>>) {
    const { t } = useTranslation();
    const formTable = useFormTable<AccountVoucherRowData>("/api/accountVoucher");
    const formatDate = (date: moment.MomentInput) => moment(date).format("YYYY-MM-DD");
    const sourceMapping = useMemo<any>(() => getSourceI18n(t), [t]);
    const columns: Column<AccountVoucherRowData>[] = [
        {
            title: t("accountVoucher.fd.no"),
            field: "noString",
            long: true,
        },
        {
            title: t("c.fd.date"),
            field: "date",
            type: "date",
            render: (rowData) => rowData.date && formatDate(rowData.date),
        },
        {
            title: t("c.fd.rmk"),
            field: "remark",
        },
        {
            title: t("accountVoucher.fd.debit"),
            field: "totalDebit",
            type: "numeric",
            render: mfn("totalDebit"),
        },
        {
            title: t("accountVoucher.fd.credit"),
            field: "totalCredit",
            type: "numeric",
            render: mfn("totalCredit"),
        },
        {
            title: t("raData.fd.source"),
            field: "source",
            lookup: sourceMapping,
        },
        {
            title: t("c.fd.status"),
            field: "status",
            lookup: t("accountVoucher.status", { returnObjects: true }),
        },
    ];
    return (
        <>
            <MaterialTable<AccountVoucherRowData>
                {...props}
                columns={columns}
                data={formTable.fetch()}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    pageSize: 10,
                    toolbar: false,
                    ...options,
                }}
            />
        </>
    );
}

export default AccountVoucherSearchTable;
