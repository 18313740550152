import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import { Route, Switch, Link } from "react-router-dom";
import { TabPageProps } from "layout/types";
import BasicApprovalList from "./basic/BasicApprovalList";

function ApprovalPage({ match }: TabPageProps) {
    const { t } = useTranslation();
    const { path } = match;
    const hash = document.location.hash;
    const pagesNames = ["basic", "special"];
    const hushPathSplit = hash.split("/");
    const innerPath =
        hushPathSplit.length >= 3 && pagesNames.indexOf(hushPathSplit[2]) > -1 ? hushPathSplit[2] : "basic";
    return (
        <Box mx={-5}>
            <Tabs
                value={innerPath}
                //onChange={handleTabsChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
            >
                <Tab value="basic" label={t("approval.tab.basic")} component={Link} to={`${path}`} />
                <Tab
                    value="special"
                    label={t("approval.tab.special")}
                    component={Link}
                    to={`${path}/special`}
                    disabled={true}
                />
            </Tabs>
            <Divider />
            <Box mx={5}>
                <Switch>
                    <Route exact path={`${path}`} component={BasicApprovalList} />
                    {/* <Route path={`${path}/basic/add`} component={BasicLeaveTypeForm} /> */}
                    <Route path={`${path}`} component={BasicApprovalList} />
                </Switch>
            </Box>
        </Box>
    );
}

export default ApprovalPage;
