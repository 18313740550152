import React from "react";
import { Column } from "@material-table/core";
import { AttendanceRowData } from "./AttendanceSearchTable";
import { TFunction } from "react-i18next";
import DateRenderer from "components/grid/DateRenderer";
import AttendanceStatus from "components/attendance/AttendanceStatus";

export interface ColumnsDesc {
    type: string;
    formAction: (rowId: string) => void;
}

function ColumnsFactory(columnsDesc: ColumnsDesc, t: TFunction) {
    const defaultColumns: Column<AttendanceRowData>[] = [
        {
            title: t("employee.fd.employeeNo"),
            field: "employeeNo",
            type: "string",
        },
        {
            title: t("employee.fd.name"),
            field: "name",
            type: "string",
        },
        {
            title: t("c.fd.date"),
            field: "date",
            type: "date",
            render: ({ date }) => DateRenderer(date),
        },
        {
            title: t("shift.fd.time"),
            field: "workHours",
            type: "string",
        },
        {
            title: t("attendance.fd.clockInTime"),
            field: "startWorkTime",
            type: "string",
        },
        {
            title: t("attendance.fd.clockOffTime"),
            field: "endWorkTime",
            type: "string",
        },
        {
            title: t("c.fd.status"),
            field: "status",
            type: "string",
            render: ({ status }) => <AttendanceStatus statusId={status} />,
        },
    ];

    return defaultColumns;
}

export default ColumnsFactory;
