import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeValidate, Select, TextField } from "mui-rff";
import moment from "moment";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid, InputAdornment, MenuItem, Stack, Typography } from "@mui/material";
import { getUser } from "helpers";
import { StandardUser } from "common";
import FormBase from "components/final-form/FormBase";
import { DateTimePicker } from "components/final-form/Picks";
import { EmployeePickerField } from "components/final-form";
import useLeaveRemainData, { LeaveRemain } from "components/attendance/useLeaveRemainData";
import { LeaveRowData } from "pages/leave/basic/BasicLeaveTable";
import { RequestFormBaseProps } from "./RequestContent";
import EmployeeBlock from "./EmployeeBlock";
import { LeaveRequestFormValues } from "../formType";
import useLeaveRequestCalculator from "./useLeaveRequestCalculator";

type SelectableLeaveType = Pick<LeaveRowData, "id" | "name"> & Pick<LeaveRemain, "remain">;

export const isVVIP = (user: StandardUser) => {
    return user.employee?.shifts && user.employee.shifts.length > 0 && user.employee.shifts[0].shiftType === 4;
};

const defaultInitialValues: Partial<LeaveRequestFormValues> = {
    remain: 0,
    hours: 0,
    startTime: moment().format("YYYY-MM-DD 00:00:00"),
    endTime: moment().format("YYYY-MM-DD 23:00:00"),
};

function LeaveRequestForm(props: RequestFormBaseProps) {
    const { t } = useTranslation();
    const { leaveRemain } = useLeaveRemainData({
        onError: (error) => {
            if (error === "Employee shift has not been set") {
                console.log(t("form.error.shiftNotSet"));
            }
        },
    });
    const user = useMemo(() => getUser(), []);
    const leaveRequestCalculator = useLeaveRequestCalculator(leaveRemain);
    const schema: SchemaOf<LeaveRequestFormValues> = yup
        .object()
        .shape({
            leaveTypeId: yup.string().label(t("e.leaveType")).required(),
            agentId: yup.string().label(t("leave.fd.agent")).required(),
            startTime: yup.date().label(t("c.fd.dateStart")).required(),
            endTime: yup.date().label(t("c.fd.timeEnd")).min(yup.ref("startTime")).required(),
            hours: yup
                .number()
                .label(t("leave.fd.hours"))
                .min(0.5)
                .max(!isVVIP(user) ? yup.ref("remain") : yup.ref("hoursLimit"))
                .required(),
            reason: yup.string().label(t("c.fd.reason")).trim().required().max(500),
            remark: yup.string().label(t("c.fd.rmk")).trim().max(500),
        })
        .defined();
    const validate = makeValidate(schema);

    return (
        <FormBase<LeaveRequestFormValues>
            {...props}
            validate={validate}
            initialValues={defaultInitialValues}
            decorators={[leaveRequestCalculator]}
        >
            <FormContent leaveRemains={leaveRemain} />
        </FormBase>
    );
}

interface FormContentProps {
    leaveRemains: LeaveRemain[] | null;
}

function FormContent({ leaveRemains }: FormContentProps) {
    const { t } = useTranslation();
    const user = useMemo(() => getUser(), []);

    const selectableLeaveType = useMemo<SelectableLeaveType[]>(() => {
        if (leaveRemains && leaveRemains.length > 0) {
            return (
                leaveRemains
                    ?.map((row) => {
                        const { leaveTypeId, remain } = row;
                        return { id: leaveTypeId, name: row["leaveType.name"], remain };
                    })
                    .filter((row) => row.remain > 0) || []
            );
        }
        return [];
    }, [leaveRemains]);
    return (
        <Stack>
            <EmployeeBlock />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                        <Select name="leaveTypeId" label={t("e.leaveType")} required>
                            {selectableLeaveType.map((row) => (
                                <MenuItem key={row.id} value={row.id}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ width: "100%" }}
                                    >
                                        {row.name}
                                        <Typography variant="body2" color="primary">
                                            {t("c.units.vh", { value: row.remain })}
                                        </Typography>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                        <DateTimePicker
                            label={t("c.fd.timeStart")}
                            name="startTime"
                            minutesStep={30}
                            inputFormat="yyyy-MM-dd HH:mm"
                            required
                        />
                        <DateTimePicker
                            label={t("c.fd.timeEnd")}
                            name="endTime"
                            minutesStep={30}
                            inputFormat="yyyy-MM-dd HH:mm"
                            required
                        />
                        <TextField
                            label={t("leave.fd.hours")}
                            name="hours"
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{t("c.units.h")}</InputAdornment>,
                            }}
                            disabled={!isVVIP(user)}
                        />
                    </Stack>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                        <EmployeePickerField
                            required
                            label={t("leave.fd.agent")}
                            name="agentId"
                            filter={(row) => (user.employee ? row.id !== user.employee.id : true)}
                            fullWidth
                        />
                        <TextField
                            required
                            rows={5}
                            label={t("c.fd.reason")}
                            name="reason"
                            multiline
                            InputProps={{
                                rows: 3,
                            }}
                            inputProps={{ maxLength: 500 }}
                        />
                        <TextField rows={3} label={t("c.fd.rmk")} name="remark" inputProps={{ maxLength: 500 }} />
                    </Stack>
                </Grid>
            </Grid>
        </Stack>
    );
}

export default LeaveRequestForm;
