import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaFileImport, FaFileExcel } from "react-icons/fa";
import { Action } from "@material-table/core";
import { Button, Grid, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import MdiIcon from "@mdi/react";
import { mdiFileSearch } from "@mdi/js";
import { StandaloneConfirmDialog, StandaloneConfirmDialogRef, SearchTextField } from "components";
import { useDataCUD, useUserPermission } from "hooks";
import { PageHeader2 as PageHeader } from "layout";
import { download, generateFilename } from "helpers/fileHelper";
import EmployeeSearchTable, { EmployeeRowData } from "./EmployeeSearchTable";
import NewEmployeeDialog from "./NewEmployeeDialog";

function EmployeeList() {
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const tableRef = useRef<any>(null);
    const history = useHistory();
    const { t } = useTranslation();
    const crud = useDataCUD();
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("employee"), [isWrite]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };
    const handleAdd = async () => {
        setFormOpen(true);
    };
    const handleEdit = async (row: EmployeeRowData) => {
        history.push({ pathname: "/employee/" + row.id });
    };
    const handleFormClose = () => {
        setFormOpen(false);
    };

    const HandleExport = () => {
        const filename = generateFilename("employee_data_", "xlsx");
        download(`/api/employee/export`, filename);
    };

    const refreshTable = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };
    const actions: Action<EmployeeRowData>[] = [
        {
            icon: writable ? "edit" : () => <MdiIcon path={mdiFileSearch} size={1} />,
            tooltip: writable ? t("c.edit") : t("c.view"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    handleEdit(rowData);
                }
            },
        },
    ];
    const extraActions: Action<EmployeeRowData>[] = writable
        ? [
              {
                  icon: "delete",
                  tooltip: t("c.del"),
                  onClick: (e: any, rowData) => {
                      console.log(tableRef.current);
                      const action = () => {
                          if (!Array.isArray(rowData)) {
                              crud.handleDel({}, `/api/employee/${rowData.id}`).then(() => {
                                  tableRef.current.onPageChange(e, 0);
                              });
                          }
                      };
                      dialogRef.current && dialogRef.current.open(action);
                  },
              },
          ]
        : [];

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    <Stack spacing={1} direction="row">
                        {writable && (
                            <>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        handleAdd();
                                    }}
                                >
                                    {t("c.add")}
                                </Button>
                                <Button variant="outlined" startIcon={<FaFileImport />}>
                                    {t("c.import")}
                                </Button>
                            </>
                        )}
                        <Button variant="outlined" onClick={HandleExport} startIcon={<FaFileExcel />}>
                            {t("c.export")}
                        </Button>
                    </Stack>
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} startIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
            <EmployeeSearchTable
                tableRef={tableRef}
                actions={actions}
                extraActions={extraActions}
                options={{
                    filtering: showFilter,
                }}
            />
            <NewEmployeeDialog onClose={handleFormClose} onChange={refreshTable} open={formOpen} />
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
        </>
    );
}

export default EmployeeList;
