import React from "react";
import { useTranslation } from "react-i18next";
import { makeValidate, Radios, TextField } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Stack } from "@mui/material";
import FormBase from "components/final-form/FormBase";
import WorkType from "components/employee/WorkType";
import { DateTimePicker } from "components/final-form/Picks";
import { RequestFormBaseProps } from "./RequestContent";
import { ClockRequestFormValues } from "../formType";
import EmployeeBlock from "./EmployeeBlock";

function ClockRequestForm(props: RequestFormBaseProps) {
    const { t } = useTranslation();
    const schema: SchemaOf<ClockRequestFormValues> = yup
        .object()
        .shape({
            dutyType: yup.mixed<WorkType>().required(),
            clockTime: yup.date().label(t("attendance.fd.fixedClockInTime")).required(),
            reason: yup.string().label(t("c.fd.reason")).trim().required().max(500),
        })
        .defined();
    const validate = makeValidate(schema);

    return (
        <FormBase<ClockRequestFormValues> validate={validate} initialValues={{ dutyType: "1" }} {...props}>
            <FormContent />
        </FormBase>
    );
}
function FormContent() {
    const { t } = useTranslation();

    const goToTheWorkLabel = t("attendance.fd.clockIn");
    const getOfTheWorkLabel = t("attendance.fd.clockOff");

    return (
        <Stack spacing={3}>
            <EmployeeBlock />
            <Radios
                radioGroupProps={{ row: true }}
                name="dutyType"
                required
                data={[
                    { label: goToTheWorkLabel, value: WorkType.GO_TO_THE_WORK.toString() },
                    { label: getOfTheWorkLabel, value: WorkType.GET_OF_THE_WORK.toString() },
                ]}
            />
            <DateTimePicker
                label={t("attendance.fd.fixedClockInTime")}
                name="clockTime"
                inputFormat="yyyy-MM-dd HH:mm"
                required
            />
            <TextField rows={2} label={t("c.fd.reason")} name="reason" inputProps={{ maxLength: 500 }} required />
        </Stack>
    );
}

export default ClockRequestForm;
