import React from "react";
import { Box } from "@mui/material";
import LeaveBlockGrid from "./LeaveBlockGrid";
import { LeaveBlockProps } from "./LeaveBlock";

interface LeaveDetailsProps {
    leaveBlocks: Array<LeaveBlockProps>;
}

function LeaveGrid({ leaveBlocks }: LeaveDetailsProps) {
    return (
        <Box sx={{ p: 4 }}>
            <LeaveBlockGrid leaveBlocks={leaveBlocks} />
        </Box>
    );
}

export default LeaveGrid;
