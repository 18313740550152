import React, { useMemo } from "react";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import LeaveHoursUnitEnum from "./LeaveHoursUnitEnum";

export interface LeaveBlockProps {
    id: number;
    hours: number;
    name: string;
    unit?: LeaveHoursUnitEnum;
    onClick: (name: string) => void;
}

function LeaveBlock({ hours, name, unit = LeaveHoursUnitEnum.HOURS, onClick }: LeaveBlockProps) {
    const { t } = useTranslation();
    const value = unit === LeaveHoursUnitEnum.DAYS ? hours / 8 : hours;
    const unitsI18n = unit === LeaveHoursUnitEnum.DAYS ? "c.units.vd" : "c.units.vh";
    return (
        <Card sx={{ minWidth: "200px" }} onClick={() => onClick(name)}>
            <CardContent sx={{ p: 3 }}>
                <Grid container justifyContent="space-between">
                    <Grid item xs={8}>
                        <Stack>
                            <Typography variant="h5" component="div" fontWeight="400">
                                {t(unitsI18n, { value: value })}
                            </Typography>
                            <Typography variant="subtitle1" color="#00000099" component="div">
                                {name}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item container xs={4} justifyContent="flex-end" alignItems="center">
                        <InsertInvitationIcon color="primary" fontSize="large" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default LeaveBlock;
