import DEF from "../def";

const initialState = null;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.USER_UPDATE:
            return { ...action.value };
        case DEF.CLEAR_ALL:
        case DEF.USER_CLEAR:
            return {};
        default:
            return state;
    }
}
