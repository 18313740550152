import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import { EmployeePickRow, useEmployeeData } from "hooks";
import { getUser } from "helpers";

function EmployeeBlock() {
    const { t } = useTranslation();
    const user = useMemo(() => getUser(), []);
    const [employees] = useEmployeeData<EmployeePickRow[]>();
    const employee = useMemo<EmployeePickRow | null>(() => {
        if (user.employee) {
            const data = employees.find((row) => row.id === user.employee?.id);
            return data || null;
        }
        return null;
    }, [user, employees]);
    return (
        <>
            {employee && (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <TextField disabled label={t("employee.fd.employeeNo")} value={employee.employeeNo} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField disabled label={t("employee.fd.name")} value={employee.name} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            disabled
                            fullWidth
                            label={t("e.depart")}
                            value={employee.mainEmployeeDepartment?.department.name || ""}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default EmployeeBlock;
