import React from "react";
import { ButtonProps } from "@mui/material/Button/Button";
import ButtonOutlined from "./ButtonOutlined";
import { FaFileImport } from "react-icons/fa";

function ImportButton({ children, ...props }: ButtonProps) {
    return (
        <ButtonOutlined variant="outlined" startIcon={<FaFileImport color="#000" />} {...props}>
            {children}
        </ButtonOutlined>
    );
}

export default ImportButton;
