import React from "react";
import { /*Card, CardHeader,*/ Typography, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface DepartmentNode {
    name: string;
    status: number;
    dataId: string | number;
}

export interface NodeTemplateProp {
    nodeData: DepartmentNode;
}

function NodeTemplate({ nodeData }: NodeTemplateProp) {
    const { t } = useTranslation();
    return (
        <Typography variant="h6">
            {nodeData.name}
            {nodeData.status === 0 && <Chip size="small" color="secondary" label={t("department.closed")} />}
        </Typography>
        // <Card sx={{ minWidth: 275 }} elevation={3}>
        //     <CardHeader
        //         title={
        //             <Typography variant="h6">
        //                 {nodeData.name}
        //                 {nodeData.status === 0 && (
        //                     <Chip size="small" color="secondary" label={t("department.closed")} />
        //                 )}
        //             </Typography>
        //         }
        //     />
        // </Card>
    );
}

export default NodeTemplate;
