import React from "react";
import CashBlock, { CashBlockProps } from "./CashBlock";
import { useTranslation } from "react-i18next";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { orange } from "@mui/material/colors";
import { Avatar } from "@mui/material";

export type BankSavingsProps = Pick<CashBlockProps, "amount">;

function BankSavingsBlock({ amount }: BankSavingsProps) {
    const { t } = useTranslation();

    return (
        <CashBlock
            amount={amount}
            color={"#754700"}
            subtitle={t("accountTitle.settingData.bank")}
            icon={
                <Avatar variant="rounded" sx={{ background: orange[50] }}>
                    <AccountBalanceIcon sx={{ color: orange[300] }} />
                </Avatar>
            }
        />
    );
}

export default BankSavingsBlock;
