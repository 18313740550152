/* eslint-disable no-unused-vars */
export enum DutyType {
    GO_TO_WORK = 1,
    GET_OF_WORK = 2,
}

export default interface ClockInRow {
    id: number;
    date: string;
    time: string;
    dutyType: DutyType;
    ip: string;
    employeeId: number;
}
