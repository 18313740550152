import React from "react";
import { Alert, Container, CssBaseline } from "@mui/material";
import { useTranslation } from "react-i18next";

const EmployeeNotFound = () => {
    const { t } = useTranslation();
    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Alert severity="error">{t("c.msg.employeeNotFound")}</Alert>
            </Container>
        </>
    );
};
export default EmployeeNotFound;
