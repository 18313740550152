import React, { ReactNode } from "react";
import { Alert, AlertProps } from "@mui/material";
import AuthLink, { AuthLinkProps } from "components/AuthLink";

export type AlertWithAuthLinkProps = Omit<AlertProps, "action"> & {
    authLinkProps: Omit<AuthLinkProps, "children" | "inherit" | "size">;
    linkLabel: ReactNode;
};

function AlertWithAuthLink({ authLinkProps, linkLabel, children, ...props }: AlertWithAuthLinkProps) {
    return (
        <Alert
            {...props}
            action={
                <AuthLink {...authLinkProps} color="inherit" size="small">
                    {linkLabel}
                </AuthLink>
            }
        >
            {children}
        </Alert>
    );
}

export default AlertWithAuthLink;
