import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { TableBody, TableCell, Table, TableHead, TableRow } from "@mui/material";
import { AccountTitleRow } from "pages/accountSetting";
import ReportDataRow from "./ReportDataRow";

export interface ReportTableProps {
    data: AccountTitleRow[];
    isEditMode?: boolean;
    showHeader?: boolean;
    summaryCells?: ReactNode;
}

function ReportTable({ data, isEditMode = false, showHeader = true, summaryCells }: ReportTableProps) {
    const { t } = useTranslation();
    return (
        <Table>
            {showHeader && (
                <TableHead sx={{ background: "#FAFAFA", width: "100%" }}>
                    <TableRow>
                        <TableCell>{t("accountTitle.fd.code")}</TableCell>
                        <TableCell>{t("accountTitle.fd.name")}</TableCell>
                        <TableCell align="right" style={{ width: "200px" }}>
                            {t("c.fd.amount")}
                        </TableCell>
                    </TableRow>
                </TableHead>
            )}
            <TableBody>
                {data
                    .filter((row) => row.type !== 0)
                    .map((row) => (
                        <ReportDataRow key={row.id} rowData={row} isEditMode={isEditMode} />
                    ))}
                {summaryCells ? <TableRow>{summaryCells}</TableRow> : <></>}
            </TableBody>
        </Table>
    );
}

export default ReportTable;
