import React, { useEffect, useMemo } from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import WorkType from "components/employee/WorkType";
import { ClockRequestFormValues } from "components/audit/formType";
import CardReplacementStatusEnum from "components/attendance/CardReplacementStatusEnum";
import { EmployeePickRow, useFetchData2 as useFetchData } from "hooks";
import { glueQueryParams } from "helpers/urlHelper";
import { DateFilter, getDateParams } from "helpers/dateFilter";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { StdQueryListResult } from "common";
export interface ClockRequestRowData {
    id: string;
    employeeNo: string;
    name: string;
    date?: Date;
    direction: WorkType;
    checkInTime?: string;
    applicationTime?: Date;
    status: CardReplacementStatusEnum;
}

export interface ClockRequestSearchTableProps extends Partial<CustomMaterialTableProps<ClockRequestRowData>> {
    options?: Options<ClockRequestRowData>;
    columns: Column<ClockRequestRowData>[];
    statusFilter?: CardReplacementStatusEnum;
    dateFilter: DateFilter;
    header?: React.ReactNode;
}
interface ClockRequestRow {
    id: string;
    formId: number;
    formForeignId: string;
    status: number;
    createdAt: string;
    employee: Pick<EmployeePickRow, "name" | "employeeNo">;
    clockRequest: ClockRequestFormValues;
}

function WorkTypeFromNumber(workType: number) {
    if (workType === 1) {
        return WorkType.GO_TO_THE_WORK;
    }
    return WorkType.GET_OF_THE_WORK;
}

function StatusFromNumber(status: number) {
    if (status === 1) {
        return CardReplacementStatusEnum.PASS;
    }
    return CardReplacementStatusEnum.REJECTED;
}

function ClockRequestRowToClockRequestRowData(response: ClockRequestRow): ClockRequestRowData {
    const { id, clockRequest, employee, createdAt, status } = response;
    const { date, dutyType } = clockRequest;
    const { name, employeeNo } = employee;
    console.log(response);
    return {
        id,
        employeeNo,
        name,
        date: moment(date).toDate(),
        direction: WorkTypeFromNumber(Number(dutyType)),
        checkInTime: clockRequest.time as string,
        applicationTime: new Date(createdAt),
        status: StatusFromNumber(status),
    };
}

function ClockRequestSearchTable({
    options = {},
    columns,
    header,
    statusFilter,
    dateFilter,
    ...props
}: ClockRequestSearchTableProps) {
    const { t } = useTranslation();
    const [dataListResult, dataListFetch, dataListCleanup] = useFetchData<StdQueryListResult<ClockRequestRow>>(
        `/api/attendance/clockRequest/employees?${glueQueryParams(getDateParams(dateFilter))}`,
        t("e.attendanceData")
    );
    const processedData = useMemo<ClockRequestRowData[]>(() => {
        if (!dataListResult?.count) {
            return [];
        }

        return dataListResult.data.map(ClockRequestRowToClockRequestRowData);
    }, [dataListResult]);

    useEffect(() => {
        dataListFetch().catch((error) => {
            console.error(error);
        });

        return () => {
            dataListCleanup();
        };
    }, [dateFilter]);

    return (
        <>
            {header && header}
            <MaterialTable<ClockRequestRowData>
                {...props}
                columns={columns}
                data={processedData}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: false,
                    ...options,
                }}
            />
        </>
    );
}

export default ClockRequestSearchTable;
