import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LeaveInfo, { LeaveInfoProps } from "./LeaveInfo";
import { useTranslation } from "react-i18next";
import { DialogTitle, Typography } from "@mui/material";

export interface LeaveInfoDialogProps {
    open: boolean;
    onClose: () => void;
    leaveInfoProps: LeaveInfoProps;
}

function LeaveInfoDialog({ open, onClose, leaveInfoProps }: LeaveInfoDialogProps) {
    const { t } = useTranslation();
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="subtitle1" component="p" fontWeight="400" gutterBottom={true}>
                        {t("c.detailInfo")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <LeaveInfo {...leaveInfoProps} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{t("c.close")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default LeaveInfoDialog;
