import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import AuditAction, { AuditType } from "components/dashboard/AuditAction";
import RequestAction, { RequestType } from "components/dashboard/RequestAction";
import RemainingLeaveBlock from "components/dashboard/RemainingLeaveBlock";
import { ClockInBlock } from "components/clockIn";
import CalendarBlock from "components/dashboard/CalendarBlock";
import NewsList from "components/dashboard/NewsList";
import { useFetchData2 as useFetchData } from "hooks";
import { useHistory } from "react-router-dom";
import AuditTabs from "../audit/AuditTabs";
import FormPath from "../formRequest/FormPath";
import { DashboardInfo } from "./ManagerDashboard";

function EmployeeDashboard() {
    const { t } = useTranslation();
    const history = useHistory();
    const [dashboardInfo, dashboardInfoFetch, dashboardInfoCleanup] =
        useFetchData<DashboardInfo>(`/api/dashboard/info`);

    useEffect(() => {
        dashboardInfoFetch().catch((error) => {
            console.log(error);
        });
        return () => {
            dashboardInfoCleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [remainDays, remainHours] = useMemo<[number, number]>(() => {
        if (dashboardInfo) {
            const rawValue = dashboardInfo.hr.annualLeave;
            if (Number(rawValue)) {
                const days = Math.floor(rawValue / 8);
                const hours = rawValue % 8;
                return [days, hours];
            }
        }
        return [0, 0];
    }, [dashboardInfo]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <ClockInBlock />
            </Grid>
            <Grid item xs={12} md={4}>
                <CalendarBlock />
            </Grid>
            <Grid item xs={12} md={4}>
                <RemainingLeaveBlock
                    title={t("ui.annualLeave")}
                    days={
                        t("c.units.vd", { value: remainDays }) +
                        (remainHours ? t("c.units.vh", { value: remainHours }) : "")
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                    <Grid item xs={12} md={4}>
                        <AuditAction
                            count={dashboardInfo?.hr.myApply.pending || 0}
                            onClick={() => history.push(AuditTabs.getPath(AuditTabs.signOff))}
                            title={t("audit.tab.signOff")}
                            type={AuditType.SigningOff}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AuditAction
                            count={dashboardInfo?.hr.myApply.rejected || 0}
                            onClick={() => history.push(AuditTabs.getPath(AuditTabs.rejected))}
                            title={t("audit.tab.rejected")}
                            type={AuditType.Rejected}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AuditAction
                            count={dashboardInfo?.hr.myApply.accepted || 0}
                            onClick={() => history.push(AuditTabs.getPath(AuditTabs.accepted))}
                            title={t("audit.tab.accepted")}
                            type={AuditType.Accepted}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                    <Grid item xs={12} md={4}>
                        <RequestAction
                            onClick={() => history.push(FormPath.getPath(FormPath.leaveRequest))}
                            title={t("form.dashboard.leaveRequest")}
                            type={RequestType.Leave}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <RequestAction
                            onClick={() => history.push(FormPath.getPath(FormPath.overtimeRequest))}
                            title={t("form.dashboard.overtimeRequest")}
                            type={RequestType.Overtime}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <RequestAction
                            onClick={() => history.push(FormPath.getPath(FormPath.clockRequest))}
                            title={t("form.dashboard.clockRequest")}
                            type={RequestType.RefillCard}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <NewsList />
            </Grid>
        </Grid>
    );
}

export default EmployeeDashboard;
