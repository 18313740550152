import React from "react";
import { Grid, Typography } from "@mui/material";
import CommonNumberFormat, { CommonNumberFormatProps } from "components/CommonNumberFormat";
import Block from "./Block";

export interface CashBlockProps {
    icon: React.ReactNode;
    amount?: number | string | null;
    subtitle: string;
    color: string;
    formatProps?: Omit<CommonNumberFormatProps, "number">;
}

function CashBlock({ icon, amount, subtitle, color, formatProps }: CashBlockProps) {
    return (
        <Block>
            <Grid container alignItems="start" justifyContent="space-between" columnSpacing={2}>
                <Grid item xs={2}>
                    {icon}
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="h4" fontSize="34px" color={color} fontWeight="700" textAlign="start">
                        {"$"}
                        {amount ? <CommonNumberFormat {...formatProps} number={amount} /> : "0"}
                    </Typography>
                    <Typography variant="subtitle1" color={color} fontSize="14px" fontWeight="700" textAlign="start">
                        {subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Block>
    );
}

export default CashBlock;
