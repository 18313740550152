import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, useForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Button, IconButton, Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { EmployeePickerField } from "components/final-form";
import { EmployeePickRow } from "hooks";
import { BasicApprovalFormValues, StepsData, useFormContext } from "./BasicApprovalFormBase";

interface FlowStepsFieldsProps {
    name: keyof BasicApprovalFormValues;
}

function FlowStepsFields({ name }: FlowStepsFieldsProps) {
    return (
        <Field name="type">
            {(typeInputProps) => {
                const typeValue = typeInputProps.input.value;
                return <FlowStepper typeValue={typeValue} name={name} />;
            }}
        </Field>
    );
}

interface FlowStepperProps extends FlowStepsFieldsProps {
    typeValue: number | string;
}

function FlowStepper({ name, typeValue }: FlowStepperProps) {
    const [isStepDataChanged, setIsStepDataChanged] = useState<boolean>(false);
    const { t } = useTranslation();
    const nTypeValue = Number(typeValue);
    const minStepCount = nTypeValue === 2 ? 2 : 1;
    const form = useForm<BasicApprovalFormValues>();
    const { supervisorId } = useFormContext();
    useEffect(() => {
        const state = form.getState();
        if (state.dirty) {
            switch (nTypeValue) {
                case 1:
                    form.change(name, [
                        {
                            step: 1,
                            signerId: supervisorId,
                        },
                    ]);

                    break;
                case 2:
                    form.change(name, [
                        {
                            step: 1,
                            signerId: supervisorId,
                        },
                        {
                            step: 2,
                        },
                    ]);

                    break;
                case 3:
                    form.change(name, [
                        {
                            step: 1,
                        },
                    ]);
                    break;
                default:
                    form.change(name, []);
                    break;
            }
        }
        //
    }, [nTypeValue]);
    if (nTypeValue === 0) {
        return <></>;
    }
    return (
        <FieldArray<StepsData> name={name}>
            {({ fields }) => {
                const handleAdd = () => {
                    fields.push({
                        step: fields.value.length + 1,
                    });
                    setIsStepDataChanged(true);
                };
                const handleRemove = (index: number) => {
                    fields.remove(index);
                    setIsStepDataChanged(true);
                };
                const selectedSigners = fields.value
                    .filter((row) => row.signerId)
                    .map<number>((row) => row.signerId || 0);
                const isAddable = fields.value.length < 4 && (nTypeValue === 2 || nTypeValue === 3);
                return (
                    <Stack spacing={2}>
                        <StepValueResetter
                            fieldName={name}
                            values={fields.value}
                            isChanged={isStepDataChanged}
                            setIsChanged={setIsStepDataChanged}
                        />
                        {fields.map((name, index) => (
                            <StepField
                                key={name}
                                name={name}
                                index={index}
                                lockFirst={nTypeValue === 1 || nTypeValue === 2}
                                handleRemove={handleRemove}
                                removable={minStepCount < fields.value.length}
                                filter={(employee) => selectedSigners.indexOf(employee.id) === -1}
                            />
                        ))}
                        {isAddable && (
                            <Button onClick={handleAdd} variant="outlined" fullWidth>
                                {t("c.add")}
                            </Button>
                        )}
                    </Stack>
                );
            }}
        </FieldArray>
    );
}
export default FlowStepsFields;

interface StepFieldProp {
    name: string;
    index: number;
    //handleInsert?: (index: number) => void;
    handleRemove?: (index: number) => void;
    minStep?: number;
    disabled?: boolean;
    lockFirst?: boolean;
    removable?: boolean;
    filter?: (row: EmployeePickRow) => boolean;
}

function StepField({
    name,
    index,
    handleRemove,
    disabled = false,
    lockFirst = false,
    removable = true,
    filter,
}: StepFieldProp) {
    const isDisabled = disabled || (lockFirst && index === 0);
    const isRemovable = removable && !isDisabled;
    const { t } = useTranslation();
    const stepNames = t("c.orders", { returnObjects: true }) as Record<string, string>;
    const label = t("approval.fd.signerWithStep", { step: stepNames[(index + 1).toString()] });
    return (
        <Stack direction="row" justifyContent="center">
            <EmployeePickerField
                label={label}
                name={`${name}.signerId`}
                disabled={isDisabled}
                filter={filter}
                fullWidth
            />
            {isRemovable && (
                <IconButton color="inherit" onClick={() => handleRemove && handleRemove(index)} size="medium">
                    <CancelIcon color="error" />
                </IconButton>
            )}
        </Stack>
    );
}

interface StepValueResetterProp {
    fieldName: string;
    values: any[];
    isChanged: boolean;
    setIsChanged: (isChanged: boolean) => void;
}

function StepValueResetter({ fieldName, values, isChanged, setIsChanged }: StepValueResetterProp) {
    const form = useForm();
    useEffect(() => {
        if (isChanged) {
            //To avoid infinite loop
            const mValues = [...values];
            mValues.forEach((value, index) => {
                value.step = index + 1;
            });
            form.change(fieldName, mValues);
            setIsChanged(false);
        }
    }, [isChanged, values]);
    return <></>;
}
