import moment from "moment";
import { AccountTitleRow } from "../accountTitle";
import { InitialFormDetail, InitialFormValues } from "./InitialFormBase";

export interface ClassifiedTitles {
    assetTitles: AccountTitleRow[];
    liabilityTitles: AccountTitleRow[];
    equityTitles: AccountTitleRow[];
}

export interface ConsolidationData {
    yearMonth: string;
    currentPl: number;
    accumulated: number;
    undistributed: number;
    distributed: number;
    type: number;
    accountConsolidationDetail: InitialFormDetail[];
}

function classifyAccountTitles(data: AccountTitleRow[]): ClassifiedTitles {
    const assetTitles = data.filter((title) => title.catalog === 1);
    const liabilityTitles = data.filter((title) => title.catalog === 2);
    const equityTitles = data.filter((title) => title.catalog === 3);
    return { assetTitles, liabilityTitles, equityTitles };
}

function fromValueToInitialDetails(values: InitialFormValues, excludedZero = true): InitialFormDetail[] {
    const details = Object.keys(values)
        .filter((keyValue) => keyValue.startsWith("title"))
        .map((keyValue) => {
            const accountTitleId = Number(keyValue.substring(5));
            return {
                accountTitleId,
                amount: Number(values[keyValue]),
            };
        })
        .filter((row) => !excludedZero || row.amount !== 0);
    return details;
}

function createReportTableFormValues(data: AccountTitleRow[]): Record<string, number> {
    const values: Record<string, number> = {};
    data.forEach((title) => {
        values[`title${title.id}`] = 0;
    });
    return values;
}

function consolidationDataToFromValue(originalValues: ConsolidationData, accountTitles: AccountTitleRow[]) {
    const { yearMonth, currentPl, accumulated, undistributed, distributed, accountConsolidationDetail } =
        originalValues;
    const mYearMonth = moment(`${yearMonth}01 000000`, "YYYYMMDD hhmmss").toDate();
    const detailsValues: Record<string, number> = createReportTableFormValues(accountTitles);
    accountConsolidationDetail.forEach((row) => {
        detailsValues[`title${row.accountTitleId}`] = row.amount;
    });
    return {
        yearMonth: mYearMonth,
        currentPl,
        accumulated,
        undistributed,
        distributed,
        consolidationMethodType: 0, //這裡的Type是參數的格是設定，非月結資料欄欄位
        ...detailsValues,
    };
}

export { classifyAccountTitles, fromValueToInitialDetails, createReportTableFormValues, consolidationDataToFromValue };
