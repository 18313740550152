/**
 * Handling data manage function for material-table package
 */
export const createSimpleValidate = (schema, hookSetError) => (data) =>
    new Promise((resolve, reject) => {
        schema
            .validate(data, { abortEarly: false })
            .then(() => {
                resolve();
            })
            .catch((err) => {
                const mErrors = {};
                err.inner.forEach((vErr) => {
                    mErrors[vErr.path] = vErr.message;
                });
                hookSetError(mErrors);
                reject(err);
            });
    });

/**
 *
 * @param {Array} filters
 */
export function processFilters(filters) {
    if (filters && filters.length > 0) {
        const res = [];
        filters.forEach((f) => {
            res.push({
                f: f.column.field,
                v: typeof f.value === "string" ? f.value.trim() : f.value,
                //op: f.operator
            });
        });
        return res;
    }
    return null;
}

export function objectToFilters(obj) {
    const filters = [];
    Object.keys(obj).forEach((key) => {
        if (obj[key].length > 0) {
            filters.push({
                f: key,
                v: obj[key],
            });
        }
    });
    return filters;
}

export const changeRefFiltersFromValues = (table, formData) => {
    const state = table.state;
    let applyFilter = false;
    Object.keys(formData).forEach((key) => {
        const colId = state.columns.findIndex((cObj) => cObj.field === key);
        const val = formData[key];
        if (colId > 0) {
            applyFilter = true;
            table.onFilterChange(colId, val);
        }
    });
    table.dataManager.applyFilters = applyFilter;
};

/**
 *
 * @param {Array} data
 * @param {String} field
 * @returns {Object}
 */
export const createLookup = (data, field) => {
    const lookup = {};
    data.forEach((row) => {
        lookup[row.id] = row[field];
    });
    return lookup;
};
