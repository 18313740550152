import React, { useEffect, useState } from "react";
// import axios from "axios";
import moment from "moment";
import "moment/locale/zh-tw";
import "moment/locale/ja";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { HashRouter as Router, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material";
import "components/i18n";
import { getUser, yupLocaleInit } from "helpers";
import { Layout, layoutTheme } from "layout";
import { PageRoute as Route, AuthPageRoute as AccessRoute } from "components/route";
import Splash from "pages/splash";
import { DepartmentList } from "pages/department";
import { WorkPositionList } from "pages/workposition";
import SalaryItems from "pages/salaryItems";
import { EmployeeList, EmployeeForm } from "pages/employee";
import { CorporateForm } from "pages/corporate";
import Announce from "pages/announce";
import { MessageList } from "pages/message";
import { EmployeeNotFound } from "pages/errors";
// import { DEF } from "Action";
import Construction from "pages/construction";
import { checkLogin } from "helpers";
import PolicyForm from "pages/policy";
import { AccountVoucherList, AccountVoucherForm } from "pages/accountVoucher";
import { AccountConsolidationList } from "pages/accountConsolidation";
// import { BSReportPage, PLReportPage } from "pages/accountReport";
import { AccountSettingPage } from "pages/accountSetting";
import { UserList } from "pages/user";
import { PermissionRoleForm, PermissionRoleList } from "pages/permissionRole";
import { ClockInPage } from "pages/clockIn";
import { LeaveTypeForm } from "pages/leave";
import { ApprovalForm } from "pages/approval";
import { ShiftList, ShiftForm } from "pages/shift";
import { AppContext } from "components/AppContext";
import { RootState, UrlPageMapping } from "layout/types";
import { AuditList } from "pages/audit";
import { FormRequestList } from "pages/formRequest";
import { AttendanceList } from "pages/attendance";
import { AbsenceList } from "./pages/absence";
import { DashboardEntry as Dashboard } from "./pages/home";
import { GlobalLoginPage } from "pages/login";
import { RegisterPage } from "pages/register";

const theme = createTheme();
const loginTheme = createTheme({
    palette: {
        background: {
            default: "#EBF3FF",
        },
    },
});

const selectPageData = createSelector(
    (state: RootState) => state.user,
    (user) =>
        user && user.pages
            ? user.pages
                  .map((pageItem) => {
                      const urlPageMappings: UrlPageMapping[] = [];
                      if (pageItem.items)
                          pageItem.items
                              .filter((subPageItem) => subPageItem.url)
                              .forEach((subPageItem) => {
                                  if (subPageItem.url) {
                                      urlPageMappings.push({
                                          url: subPageItem.url,
                                          page: subPageItem.name,
                                      });
                                  }
                              });

                      if (pageItem.url) {
                          urlPageMappings.push({
                              url: pageItem.url,
                              page: pageItem.name,
                          });
                      }
                      return urlPageMappings;
                  })
                  .reduce((a, b) => {
                      return [...a, ...b];
                  }, [])
            : []
);
/**
 * 使用者登入時，檢查使用者登入狀態，透過API查詢，如果有就直接切首頁，
 * When user login, it would check the login status from API. if user has alreadly logined, it will jump to home page.
 * 沒有就強制倒到登入頁面
 * Otherwise, it will jump to login page.
 * @param {*} props
 */
function App() {
    const initLang = Cookies.get("lang");
    const { i18n, t } = useTranslation(initLang);
    /*const supportLangs = useReduxTempData<{ id: number; lang: string; name: string }[]>(
        "/api/language/",
        t("e.language"),
        false
    );*/
    const [splashMsgs, setSplashMsgs] = useState<string[]>([]); //載入頁面的訊息 load page information
    const user = getUser();

    const urlPageMapping = useSelector(selectPageData);
    // useReduxTempData(user && user.employeeId ? `/auth/access` : null, "", false);
    useEffect(() => {
        //console.log(initLang);
        function updateDefaultLang(browserLang: string) {
            if (user && user.lang) {
                const supportLangs = user.lang.list;
                const firstStep = supportLangs.find((a) => a === browserLang);
                if (firstStep) {
                    Cookies.set("lang", firstStep);
                    i18n.changeLanguage(firstStep);
                } else {
                    const defaultLang = user.lang.default;
                    Cookies.set("lang", defaultLang);
                    i18n.changeLanguage(defaultLang);
                }
            } else {
                Cookies.set("lang", browserLang);
                i18n.changeLanguage(browserLang);
            }
        }
        if (initLang !== undefined) {
            updateDefaultLang(initLang);
            // if (initLang !== i18n.language) {
            //     i18n.changeLanguage(initLang);
            // }
        } else {
            const browserLang: string = navigator.language; //|| navigator.userLanguage;
            updateDefaultLang(browserLang);
        }
        const locale = i18n.language === "ja-JP" ? "ja" : "zh-TW";
        moment.locale(locale);
        yupLocaleInit(i18n);
        checkLogin().catch((err) => {
            console.log(err);
            if ("toJSON" in err) {
                const msgs = [];
                const rowMsg = err.toJSON().message;
                msgs.push(rowMsg);
                if (rowMsg.includes(500)) {
                    msgs.push(t("err.serverErr"));
                }
                setSplashMsgs(msgs);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n]);
    //console.debug(user);

    if (user === null) {
        return (
            <ThemeProvider theme={theme}>
                <Splash messages={splashMsgs} />
            </ThemeProvider>
        );
    } else if (Object.keys(user).length !== 0) {
        if (user.userId) {
            const contextValue = {
                user,
                pageData: user.pages,
                locale: i18n.language === "ja-JP" ? "ja" : "zh-TW",
                urlPageMapping,
            };
            return (
                <ThemeProvider theme={layoutTheme}>
                    <AppContext.Provider value={contextValue}>
                        <FunctionPage />
                    </AppContext.Provider>
                </ThemeProvider>
            );
        } else {
            return (
                <ThemeProvider theme={theme}>
                    <EmployeeAbortPage />
                </ThemeProvider>
            );
        }
    } else {
        return (
            <ThemeProvider theme={loginTheme}>
                <LoginPage />
            </ThemeProvider>
        );
    }
}

const LoginPage = () => {
    return (
        <Router>
            <Switch>
                {/* <Route exact path="/protectionpolicy" component={PolicyForm} />
                <Route exact path="/login/:errcode" component={Login} />
                <Route exact path="/register" component={RegisterPage} /> */}
                <Route exact path="/login/:errcode" component={GlobalLoginPage} />
                <Route component={GlobalLoginPage} />
            </Switch>
        </Router>
    );
};

const EmployeeAbortPage = () => {
    return (
        <Router>
            <Layout>
                <EmployeeNotFound />
            </Layout>
        </Router>
    );
};

const FunctionPage = () => {
    const { t } = useTranslation();
    return (
        <Router>
            <Layout>
                <Switch>
                    <Route exact path="/protectionpolicy" component={PolicyForm} />
                    {/*** HR page ****/}
                    <AccessRoute exact title={t("m.dashboard")} path="/dashboard" component={Dashboard} />
                    <AccessRoute exact title={t("m.empManage")} path="/employee" component={EmployeeList} />
                    <AccessRoute title={t("m.empManage")} path="/employee/:id" component={EmployeeForm} />
                    <AccessRoute title={t("m.workPositionManage")} path="/workposition" component={WorkPositionList} />
                    <AccessRoute title={t("m.clockIn")} path="/clockin" component={ClockInPage} />
                    <AccessRoute title={t("m.leaveType")} path="/leaveType" component={LeaveTypeForm} />
                    <AccessRoute title={t("m.routeManage")} path="/approval" component={ApprovalForm} />
                    <AccessRoute exact title={t("m.audit")} path="/audit" component={AuditList} />
                    <AccessRoute title={t("m.audit")} path="/audit/:tabName" component={AuditList} />
                    <AccessRoute exact title={t("m.formRequest")} path="/formRequest" component={FormRequestList} />
                    <AccessRoute
                        title={t("m.formRequest")}
                        path="/formRequest/form/:formName"
                        component={FormRequestList}
                    />
                    <AccessRoute title={t("m.formRequest")} path="/formRequest/:tabName" component={FormRequestList} />
                    <AccessRoute exact title={t("m.attendance")} path="/attendance" component={AttendanceList} />
                    <AccessRoute title={t("m.attendance")} path="/attendance/:tabName" component={AttendanceList} />
                    <AccessRoute exact title={t("m.absence")} path="/absence" component={AbsenceList} />
                    <AccessRoute title={t("m.absence")} path="/absence/:tabName" component={AbsenceList} />
                    <AccessRoute exact title={t("m.shiftSetting")} path="/shift" component={ShiftList} />
                    <AccessRoute title={t("m.shiftSetting")} path="/shift/add" component={ShiftForm} />
                    <AccessRoute title={t("m.shiftSetting")} path="/shift/:id" component={ShiftForm} />
                    {/*** Account page ****/}
                    <AccessRoute title={t("m.accountSetting")} path="/accountSetting" component={AccountSettingPage} />
                    <AccessRoute
                        exact
                        title={t("m.accountVoucher")}
                        path="/accountVoucher"
                        component={AccountVoucherList}
                    />
                    <AccessRoute
                        title={t("m.accountVoucher")}
                        path="/accountVoucher/add"
                        component={AccountVoucherForm}
                    />
                    <AccessRoute
                        title={t("m.accountVoucher")}
                        path="/accountVoucher/adjust/:id"
                        component={AccountVoucherForm}
                    />
                    <AccessRoute
                        title={t("m.accountVoucher")}
                        path="/accountVoucher/:id"
                        component={AccountVoucherForm}
                    />
                    <AccessRoute
                        title={t("m.accountConsolidation")}
                        path="/accountConsolidation"
                        component={AccountConsolidationList}
                    />
                    {/*<AccessRoute path="/bsReport" component={BSReportPage} />
                    <AccessRoute path="/plReport" component={PLReportPage} /> */}
                    {/*** Old page ****/}
                    <AccessRoute exact path="/salaryItems" component={SalaryItems} />
                    <AccessRoute title={t("m.newsManage")} path="/announce" component={Announce} />

                    {/*** System page ****/}
                    <AccessRoute
                        exact
                        title={t("m.permissionRole")}
                        path="/permissionRole"
                        component={PermissionRoleList}
                    />
                    <AccessRoute
                        title={t("m.permissionRole")}
                        path="/permissionRole/add"
                        component={PermissionRoleForm}
                    />
                    <AccessRoute
                        title={t("m.permissionRole")}
                        path="/permissionRole/copy/:id"
                        component={PermissionRoleForm}
                    />
                    <AccessRoute
                        title={t("m.permissionRole")}
                        path="/permissionRole/:id"
                        component={PermissionRoleForm}
                    />
                    <AccessRoute title={t("m.user")} path="/user" component={UserList} />
                    <AccessRoute title={t("m.corpManage")} path="/corporate" component={CorporateForm} />
                    <AccessRoute title={t("m.departManage")} path="/department" component={DepartmentList} />
                    <Route title={t("ui.msg")} path="/message" component={MessageList} />
                    <Route exact path="/under/construction/" component={Construction} />
                    <Route exact title={t("m.dashboard")} path="/" component={Dashboard} />
                    <Route exact title={t("m.dashboard")} component={Dashboard} />
                </Switch>
            </Layout>
        </Router>
    );
};
export default App;
