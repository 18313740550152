import React, { useEffect, useState } from "react";
import { ButtonProps } from "@mui/material/Button/Button";
import ButtonOutlined from "./ButtonOutlined";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const StyledMenuItem = styled(MenuItem)<MenuItemProps>((theme) => ({
    color: "#00000099",
    "&:hover": {
        backgroundColor: "#EBF3FF",
    },
}));

export interface DownloadButtonAction {
    title: string;
    startIcon: React.ReactNode;
    onClick: () => void;
}

export interface DownloadButtonProps extends ButtonProps {
    actions: Array<DownloadButtonAction>;
}

// TODO: extract generic dropdown menu button
function DownloadButton({ children, actions, ...props }: DownloadButtonProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const iconDown = <ArrowDropDownIcon />;
    const iconUp = <ArrowDropUpIcon />;
    const [endIcon, setEndIcon] = useState<React.ReactElement>(iconDown);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const icon = open ? iconUp : iconDown;
        setEndIcon(icon);
    }, [open]);

    return (
        <>
            <ButtonOutlined
                variant="outlined"
                onClick={handleClick}
                startIcon={<CloudDownloadIcon fontSize="small" htmlColor="#000" />}
                endIcon={endIcon}
                {...props}
            >
                {children}
            </ButtonOutlined>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {actions.map(({ title, startIcon, onClick }) => {
                    return (
                        <StyledMenuItem key={title} onClick={onClick}>
                            {startIcon && startIcon}
                            <Typography fontSize={"14px"}>{title}</Typography>
                        </StyledMenuItem>
                    );
                })}
            </Menu>
        </>
    );
}

export default DownloadButton;
