import { Moment } from "moment";

export interface DateFilter {
    startDate: Moment;
    endDate: Moment;
}

const formatSearchDate = (date: Moment) => date.format("YYYY-MM-DD");

export function getDateParams(dateFilter: DateFilter) {
    const { startDate, endDate } = dateFilter;

    return {
        startDate: formatSearchDate(startDate),
        endDate: formatSearchDate(endDate),
    };
}
