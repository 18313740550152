/** @format */

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import SortIcon from "@mui/icons-material/Sort";
import { CustomIcon } from "components";
import MaterialTable from "components/customMaterialTable";
import { mtFormatNumber as mfn } from "components/CommonNumberFormat";
import { PageHeader } from "layout";
import { useFormTable } from "hooks";
import SortableDialog from "./SalaryItemsSortableDialog";
import FormDialog from "./SalaryItemsFormDialog";
import SalaryDialog from "./SalaryItemsPickerDialog";

function Salaryitem() {
    const { t } = useTranslation();
    const tableRef = useRef(null);
    const [showFilter, setShowFilter] = useState(false);
    const [sortableOpen, setSortableOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [formDataId, setFormDataId] = useState(null);
    const [salaryDialogOpen, setSalaryDialogOpen] = useState(false);
    const [salaryDataId, setSalaryDataId] = useState(null);
    const formTable = useFormTable("/api/salaryitem");

    const actions = [
        {
            icon: "edit",
            tooltip: t("c.edit"),
            onClick: (e, row) => {
                doEdit(row);
            },
        },
        /*(rowData) => ({
            icon: "delete_outline",
            tooltip: t("c.del"),
            disabled: true,
            onClick: (e, row) => {
                console.log("delete");
            },
        }),*/
        (rowData) => ({
            icon: () => (
                <CustomIcon
                    name="Promotion"
                    color={rowData.wl_only === 1 && rowData.unsetedCount > 0 ? "secondary" : "inherit"}
                />
            ),
            tooltip: t("salary.rank_dtl"),
            disabled: rowData.wl_only === 0,
            onClick: (e, row) => {
                openSalaryDialog(row);
            },
        }),
    ];

    const doEdit = async (row) => {
        setFormDataId(row.id);
        setFormOpen(true);
    };
    const handleAddBtnClick = () => {
        setFormDataId(null);
        setFormOpen(true);
    };

    const handleFormClose = () => {
        setFormOpen(false);
    };
    const handleFormChange = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };

    const openSalaryDialog = async (row) => {
        setSalaryDataId(row.id);
        setSalaryDialogOpen(true);
    };

    const handleSalaryDialogClose = () => {
        setSalaryDialogOpen(false);
    };

    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };
    const handleSortBtn = () => {
        setSortableOpen(true);
    };
    const handleSortableClose = () => {
        setSortableOpen(false);
    };
    const handleSortableChange = () => {
        tableRef.current.onQueryChange();
    };

    const typeData = t("salary.payment_type", { returnObjects: true });
    const taxData = t("salary.tax_type", { returnObjects: true });
    const workLevelData = t("salary.include_type", { returnObjects: true });
    const perdayData = t("salary.include_type", { returnObjects: true });
    const columns = [
        {
            title: "#",
            field: "id",
            type: "numeric",
            editable: "never",
            width: 80,

            long: true,
        },
        {
            title: t("salary.fd.name"),
            field: "name",
        },
        {
            title: t("c.fd.chname"),
            field: "chname",
        },
        {
            title: t("salary.fd.order"),
            field: "order",
            type: "numeric",
        },
        {
            title: t("salary.fd.default_amount"),
            field: "default_amount",
            type: "numeric",
            render: mfn("default_amount"),
        },

        {
            title: t("c.fd.type"),
            field: "payment_type",
            lookup: typeData,
        },
        {
            title: t("salary.fd.tax_type"),
            field: "tax_type",
            lookup: taxData,
        },
        {
            title: t("e.worklevel"),
            field: "wl_only",
            lookup: workLevelData,
        },
        {
            title: t("salary.fd.perday_use"),
            field: "perday_use",
            lookup: perdayData,
        },
    ];

    return (
        <>
            <PageHeader
                title={t("m.salaryitems")}
                subtitle={t("c.data_list")}
                toolview={
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} endIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleSortBtn} variant="contained" color="primary" endIcon={<SortIcon />}>
                                {t("c.order")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddBtnClick}
                                endIcon={<AddIcon />}
                            >
                                {t("c.add")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
            <MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={formTable.fetch()}
                actions={actions}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    pageSize: 10,
                    toolbar: false,
                    filtering: showFilter,
                }}
                onCanceled={formTable.clearErrors}
            />
            <SortableDialog open={sortableOpen} onClose={handleSortableClose} onChange={handleSortableChange} />
            <FormDialog open={formOpen} id={formDataId} onClose={handleFormClose} onChange={handleFormChange} />
            <SalaryDialog open={salaryDialogOpen} id={salaryDataId} onClose={handleSalaryDialogClose} />
        </>
    );
}

export default Salaryitem;
