import { store, DEF } from "../Action";

const loadingControl = (show = true) => {
    // console.log('loadingControl',show);
    const dispatch = store.dispatch;
    if (show) {
        dispatch({ type: DEF.LOADING_SHOW });
    } else {
        dispatch({ type: DEF.LOADING_DISMISS });
    }
};
export default loadingControl;
