import React, { useState, useRef, useMemo, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { PageHeader2 as PageHeader } from "layout";
import { PreProcessCheckStatus } from "common/accounting";
import { AccountConsolidationAlert, useConsolidationCheck } from "components/accounting";
import { useUserPermission } from "hooks";
import AccountConsolidationSearchTable from "./AccountConsolidationSearchTable";
//import ACCreateInitDataView from "./ACCreateInitDataView"; //this component will be changed.
import ACProcessDataView from "./ACProcessDataView";
import { AlertWithAuthLink } from "components";

function AccountConsolidationList() {
    const { t } = useTranslation();
    const [showFilter, setShowFilter] = useState(false);
    const { checkResult, checkResultFetch } = useConsolidationCheck();
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("accountConsolidation"), [isWrite]);
    const tableRef = useRef<any>();
    const processable = useMemo<boolean>(() => {
        return (
            writable &&
            !!checkResult &&
            (checkResult.status === PreProcessCheckStatus.ok ||
                checkResult.status === PreProcessCheckStatus.no_initial_data)
        );
    }, [writable, checkResult]);

    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };

    const alertSettings: Record<PreProcessCheckStatus, ReactNode> = {
        [PreProcessCheckStatus.no_data]: (
            <AlertWithAuthLink
                severity="warning"
                authLinkProps={{
                    permissionPage: "accountSetting",
                    to: "/accountSetting/initial",
                }}
                linkLabel={t("c.goToSet")}
            >
                {t("accountConsolidation.msg.initDataRequire")}
            </AlertWithAuthLink>
        ),
        [PreProcessCheckStatus.ok]: undefined,
        [PreProcessCheckStatus.at_settings_error]: undefined,
        [PreProcessCheckStatus.no_initial_data]: undefined,
    };

    const handleProcessSuccess = () => {
        checkResultFetch();
        tableRef.current && tableRef.current.onQueryChange();
    };

    return (
        <Stack spacing={2}>
            <PageHeader
                title=""
                leftToolView={
                    <Grid container spacing={1}>
                        {processable && (
                            <>
                                <Grid item>
                                    <ACProcessDataView checkResult={checkResult} onSuccess={handleProcessSuccess} />
                                </Grid>
                                <Grid item>
                                    <ACProcessDataView
                                        type="delete"
                                        checkResult={checkResult}
                                        onSuccess={handleProcessSuccess}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                }
                rightToolView={
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} endIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
            <AccountConsolidationAlert checkResult={checkResult} alertSettings={alertSettings} />
            <AccountConsolidationSearchTable
                tableRef={tableRef}
                options={{
                    filtering: showFilter,
                }}
            />
        </Stack>
    );
}

export default AccountConsolidationList;
