import React, { ReactNode } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Box, Typography, TypographyProps } from "@mui/material";
export interface TextItemProps<T = any> extends TypographyProps {
    name: string;
    label?: ReactNode;
    render?: (value?: T) => ReactNode;
}

function TextItem<T = any>({ name, label, render, ...props }: TextItemProps<T>) {
    const { t } = useTranslation();
    return (
        <Field<T> name={name}>
            {({ input }) => {
                const value = input.value;
                return (
                    <Typography variant="body1" {...props}>
                        {label && (
                            <Box component="span" fontWeight="bold">
                                {label}
                                {t("c.colon")}
                            </Box>
                        )}
                        <Box component="span">{render ? render(value) : value}</Box>
                    </Typography>
                );
            }}
        </Field>
    );
}

export default TextItem;
