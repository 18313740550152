import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Field } from "react-final-form";

export interface ToggleButtonsProps {
    items: Record<string, string>;
    name: string;
    disabled?: boolean;
}

function ToggleButtons({ items, name, disabled }: ToggleButtonsProps) {
    return (
        <Field name={name}>
            {(props) => (
                <ToggleButtonGroup
                    color="primary"
                    value={props.input.value.toString()}
                    onChange={props.input.onChange}
                    disabled={disabled}
                    exclusive
                >
                    {Object.keys(items).map((key) => (
                        <ToggleButton key={key} value={key}>
                            {items[key]}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            )}
        </Field>
    );
}

export default ToggleButtons;
