import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Action } from "@material-table/core";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDataCUD, useUserPermission } from "hooks";
import { TimestampRowData } from "hooks";
import { PageHeader2 as PageHeader } from "layout";
import PositionDialog from "./PositionDialog";
import PositionTable from "./PositionTable";
import { useParams } from "react-router-dom";

export interface PositionRowData extends TimestampRowData {
    departmentId: number;
    workPositionId: number;
    flag?: boolean;
    createdAt?: string;
    updatedAt?: string;
}

function PositionForm() {
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const crud = useDataCUD();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const tableRef = useRef<any>(null);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("employee"), [isWrite]);

    const handleAdd = async () => {
        setFormOpen(true);
    };

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const refreshTable = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };
    const actions: Action<PositionRowData>[] = writable
        ? [
              {
                  icon: "delete",
                  tooltip: t("c.edit"),
                  onClick: (e: any, rowData) => {
                      if (!Array.isArray(rowData)) {
                          crud.handleDel(
                              {
                                  departmentId: rowData.departmentId,
                              },
                              `/api/employee/department/${id}`
                          )
                              .then(() => {
                                  refreshTable();
                              })
                              .catch((err) => {
                                  console.log(err);
                              });
                      }
                  },
              },
          ]
        : [];
    return (
        <>
            {writable && (
                <PageHeader
                    title=""
                    leftToolView={
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        handleAdd();
                                    }}
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                />
            )}
            <PositionTable actions={actions} tableRef={tableRef} />
            <PositionDialog onClose={handleFormClose} onChange={refreshTable} open={formOpen} />
        </>
    );
}

export default PositionForm;
