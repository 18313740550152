import React from "react";
import TextItem, { TextItemProps } from "./TextItem";
import CommonNumberFormat, { CommonNumberFormatProps } from "components/CommonNumberFormat";

export interface TextNumberItemProps extends Omit<TextItemProps<number | string>, "render"> {
    formatProps?: Omit<CommonNumberFormatProps, "number">;
}

function TextNumberItem({ formatProps, ...props }: TextNumberItemProps) {
    return (
        <TextItem<number | string>
            {...props}
            render={(value) => {
                const nValue = Number(value);
                if (isNaN(nValue)) {
                    return "";
                } else {
                    return <CommonNumberFormat {...formatProps} number={nValue} />;
                }
            }}
        />
    );
}

export default TextNumberItem;
