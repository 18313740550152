import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import { Route, Switch, Link } from "react-router-dom";
import { BasicAccountTitleList } from "./accountTitle";
import { AccountTitleSettingList } from "./setting";
import { AccountTitleGroupMain } from "./group";
import { BankAccountList } from "./bankAccount";
import { TabPageProps } from "layout/types";
import { AccountInitialForm } from "./initial";

function AccountSettingPage({ match }: TabPageProps) {
    const { t } = useTranslation();
    const { path } = match;
    const hash = document.location.hash;
    const pagesNames = ["basic", "setting", "group", "bankAccount", "initial"];
    const hushPathSplit = hash.split("/");
    const innerPath =
        hushPathSplit.length >= 3 && pagesNames.indexOf(hushPathSplit[2]) > -1 ? hushPathSplit[2] : "basic";
    return (
        <Box mx={-5}>
            <Tabs
                value={innerPath}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs"
            >
                <Tab value="basic" label={t("accountSetting.tab.basic")} component={Link} to={`${path}`} />
                <Tab value="setting" label={t("accountSetting.tab.setting")} component={Link} to={`${path}/setting`} />
                <Tab value="group" label={t("accountSetting.tab.group")} component={Link} to={`${path}/group`} />
                <Tab
                    value="bankAccount"
                    label={t("accountSetting.tab.bankAccount")}
                    component={Link}
                    to={`${path}/bankAccount`}
                />
                <Tab value="initial" label={t("accountSetting.tab.initial")} component={Link} to={`${path}/initial`} />
            </Tabs>
            <Divider />
            <Box mx={5} sx={{ height: "100%" }}>
                <Switch>
                    <Route exact path={`${path}`} component={BasicAccountTitleList} />
                    <Route path={`${path}/setting`} component={AccountTitleSettingList} />
                    <Route path={`${path}/group`} component={AccountTitleGroupMain} />
                    <Route path={`${path}/bankAccount`} component={BankAccountList} />
                    <Route path={`${path}/initial`} component={AccountInitialForm} />
                    <Route path={`${path}`} component={BasicAccountTitleList} />
                </Switch>
            </Box>
        </Box>
    );
}

export default AccountSettingPage;
