/* eslint-disable react/prop-types */
import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import mt_zh from "i18n/material-table.zh";
import mt_ja from "i18n/material-table.ja";
import { useMediaQuery, Box, Checkbox, TableRow, Grid, TableHead, TableCell, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MaterialTable, { MTableBodyRow, MTableActions, MTableEditRow } from "@material-table/core";
import { getRenderValue } from "@material-table/core/dist/components/MTableCell/cellUtils";
import { setObjectByKey, selectFromObject } from "@material-table/core/dist/utils";
import GridWrapper from "./ECGridWrapper";
import MobileFilterRow from "./MobileFilterRow";
import MobileToolbar from "./MobileToolbar";
import CustomActions from "./CustomActions";
import CustomAction from "./CustomAction";

import "../../css/material_Icons.css";

const Context = createContext({
    errors: {},
});

export const useCMTContext = () => useContext(Context);

export default function CustomMaterialTable({
    extraActions = [],
    enableMobile = true,
    formTableErrors = null,
    components = {},
    ...props
}) {
    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("lg"));
    const parentProps = props;
    const { i18n } = useTranslation();
    const mComponents = {
        ...components,
        Row:
            ("Row" in components && components.Row) ||
            ((props) => {
                return <MTableBodyRow className="MuiTableRow-hover" {...props} />;
            }),
        EditRow:
            ("EditRow" in components && components.EditRow) ||
            ((props) => {
                return (
                    <MTableEditRow
                        {...props}
                        onEditingCanceled={() => {
                            props.onEditingCanceled(props.mode, props.data);
                            if ("onCanceled" in parentProps && parentProps.onCanceled) {
                                parentProps.onCanceled();
                            }
                        }}
                    />
                );
            }),
        Actions:
            ("Actions" in components && components.Actions) ||
            ((props) => {
                return <CustomActions {...props} extraActions={extraActions} />;
            }),
        Action:
            ("Action" in components && components.Action) ||
            ((props) => {
                return <CustomAction {...props} />;
            }),
    };
    let defaultOption = {
        pageSizeOptions: [5, 10, 20],
        pageSize: 10,
        actionsColumnIndex: -1,
        headerStyle: {
            whiteSpace: "nowrap",
        },
    };
    if (props.options) {
        defaultOption = {
            ...defaultOption,
            ...props.options,
        };
    }
    if (isMobileSize && enableMobile) {
        defaultOption.search = false;
        defaultOption.pageSizeOptions = [];
        mComponents.Header = (props) => <MobileTableHeader {...props} />;
        mComponents.Row =
            ("MobileRow" in components && components.MobileRow) || ((props) => <MobileBodyRow {...props} />);
        mComponents.EditRow =
            ("MobileEditRow" in components && components.MobileEditRow) ||
            ((props) => <MobileEditRow {...props} onCanceled={parentProps.onCanceled} />);
        mComponents.FilterRow =
            ("MobileFilterRow" in components && components.MobileFilterRow) ||
            ((props) => <MobileFilterRow {...props} />);
        mComponents.Toolbar =
            ("MobileToolbar" in components && components.MobileToolbar) || ((props) => <MobileToolbar {...props} />);
    }
    const doEditingCanceled = () => {
        const table = props.tableRef.current;
        if (table.dataManager.lastEditingRow) {
            table.dataManager.changeRowEditing(table.dataManager.lastEditingRow);
            table.setState(table.dataManager.getRenderState());
        }
    };

    const handleDataChange = (func) => {
        return (...params) => {
            if (props.tableRef) {
                doEditingCanceled();
            }
            if (func) {
                func(...params);
            }
        };
    };
    // console.log(defaultOption);
    return (
        <Context.Provider value={{ errors: formTableErrors }}>
            <MaterialTable
                localization={i18n.language === "ja-JP" ? mt_ja : mt_zh}
                components={mComponents}
                onPageChange={handleDataChange(props.onPageChange)}
                onRowsPerPageChange={handleDataChange(props.onRowsPerPageChange)}
                onChangeColumnHidden={handleDataChange(props.onChangeColumnHidden)}
                onColumnDragged={handleDataChange(props.onColumnDragged)}
                onGroupRemoved={handleDataChange(props.onGroupRemoved)}
                onOrderChange={handleDataChange(props.onOrderChange)}
                onSearchChange={handleDataChange(props.onSearchChange)}
                {...props}
                options={defaultOption}
            />
        </Context.Provider>
    );
}
CustomMaterialTable.propTypes = {
    enableMobile: PropTypes.bool,
    extraActions: PropTypes.array,
    formTableErrors: PropTypes.object,
    components: PropTypes.object,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onChangeColumnHidden: PropTypes.func,
    onColumnDragged: PropTypes.func,
    onGroupRemoved: PropTypes.func,
    onOrderChange: PropTypes.func,
    onSearchChange: PropTypes.func,
    onEditingCanceled: PropTypes.func,
    data: PropTypes.any,
    mode: PropTypes.any,
    tableRef: PropTypes.any,
};

function MobileBodyRow(props) {
    let style = {
        transition: "all ease 300ms",
    };
    if (typeof props.options.rowStyle === "function") {
        style = {
            ...style,
            ...props.options.rowStyle(props.data, props.index, props.level),
        };
    } else if (props.options.rowStyle) {
        style = {
            ...style,
            ...props.options.rowStyle,
        };
    }
    if (props.onRowClick) {
        style.cursor = "pointer";
    }

    if (props.hasAnyEditingRow) {
        style.opacity = 0.2;
    }

    const handleRowClick = (event) => {
        if (props.onRowClick) {
            props.onRowClick(event, props.data);
        }
    };

    // console.log(props);
    const tableContents = props.columns.map((col) => {
        if (col.hidden) {
            return <></>;
        }
        const value = props.getFieldValue(props.data, col);
        return (
            <Grid item key={col.field} xs={12} sm={(col.long && 12) || 6}>
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={(col.long && 2) || 4}>
                        <Typography style={{ fontWeight: 900, textAlign: "right" }}>{col.title}</Typography>
                    </Grid>
                    <Grid item xs={8} sm={(col.long && 10) || 8}>
                        {(col.render && col.render(props.data)) ||
                            getRenderValue({
                                columnDef: col,
                                rowData: props.data,
                                icons: props.icons,
                                value,
                            })}
                    </Grid>
                </Grid>
            </Grid>
        );
    });
    let checkboxProps = props.options.selectionProps || {};
    if (typeof checkboxProps === "function") {
        checkboxProps = checkboxProps(props.data);
    }
    return (
        <TableRow hover style={style} selected={props.hasAnyEditingRow} onClick={handleRowClick}>
            <TableCell>
                {props.options.selection && (
                    <Box m={1}>
                        <Checkbox
                            checked={props.data.tableData.checked === true}
                            onClick={(e) => e.stopPropagation()}
                            value={props.data.tableData.id.toString()}
                            onChange={(event) => props.onRowSelected(event, props.path, props.data)}
                            {...checkboxProps}
                        />
                    </Box>
                )}
                <Grid container spacing={2}>
                    {tableContents}
                </Grid>
                <props.components.Actions
                    data={props.data}
                    actions={props.actions.filter((item) => {
                        // console.log(item);
                        if (typeof item === "object") {
                            return !item.isFreeAction && (!item.position || (item.position && item.position === "row"));
                        }
                        if (typeof item === "function") {
                            return true;
                        }
                        return false;
                    })}
                    components={props.components}
                    size={props.size}
                    disabled={props.hasAnyEditingRow}
                />
            </TableCell>
        </TableRow>
    );
}

function MobileTableHeader(props) {
    if (props.hasSelection && props.showSelectAllCheckbox) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox
                            indeterminate={props.selectedCount > 0 && props.selectedCount < props.dataCount}
                            checked={props.dataCount > 0 && props.selectedCount === props.dataCount}
                            onChange={(event, checked) => props.onAllSelected && props.onAllSelected(checked)}
                            {...props.options.headerSelectionProps}
                        />
                        {props.localization.selectAll || "select all"}
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    } else {
        return <></>;
    }
}

function MobileEditRow(props) {
    const [state, setState] = useState({
        data: props.data ? JSON.parse(JSON.stringify(props.data)) : createRowData(),
    });
    function createRowData() {
        return props.columns
            .filter((column) => (column.initialEditValue || column.initialEditValue === 0) && column.field)
            .reduce((prev, column) => {
                prev[column.field] = column.initialEditValue;
                return prev;
            }, {});
    }
    const tableContents = props.columns.map((col) => (
        <MobileEditContents
            key={col.field}
            col={col}
            mode={props.mode}
            data={props.data}
            localization={props.localization}
            onChange={(data) => {
                setState({ data: data });
            }}
            newData={state.data}
            components={props.components}
        />
    ));
    const actions = [
        {
            icon: props.icons.Check,
            tooltip: props.localization.saveTooltip,
            onClick: () => {
                const newData = state.data;
                delete newData.tableData;
                props.onEditingApproved(props.mode, state.data, props.data);
            },
        },
        {
            icon: props.icons.Clear,
            tooltip: props.localization.cancelTooltip,
            onClick: () => {
                if ("onCanceled" in props && props.onCanceled) {
                    props.onCanceled();
                }
                props.onEditingCanceled(props.mode, props.data);
            },
        },
    ];
    return (
        <TableRow hover>
            <TableCell>
                <Grid container spacing={2}>
                    {tableContents}
                </Grid>
                <MTableActions {...props} actions={actions} />
            </TableCell>
        </TableRow>
    );
}

function MobileEditContents({ col, data, mode, newData, onChange, localization, components }) {
    let allowEditing = false;
    if (col.editable === undefined) {
        allowEditing = true;
    }
    if (col.editable === "always") {
        allowEditing = true;
    }
    if (col.editable === "onAdd" && mode === "add") {
        allowEditing = true;
    }
    if (col.editable === "onUpdate" && mode === "update") {
        allowEditing = true;
    }
    if (typeof col.editable == "function") {
        allowEditing = col.editable(col, data);
    }
    const value = typeof newData[col.field] !== "undefined" ? newData[col.field] : selectFromObject(newData, col.field);
    const { editComponent, ...cellProps } = col;
    const EditComponent = editComponent || components.EditField;
    return (
        <GridWrapper col={col}>
            {!col.field || !allowEditing ? (
                (data && data[col.field]) || ""
            ) : (
                <EditComponent
                    fullWidth
                    columnDef={cellProps}
                    value={value || ""}
                    locale={localization.dateTimePickerLocalization}
                    rowData={data}
                    onChange={(value) => {
                        const data = { ...newData };
                        setObjectByKey(data, col.field, value);
                        onChange(data);
                    }}
                    onRowDataChange={(data) => {
                        onChange(data);
                    }}
                />
            )}
        </GridWrapper>
    );
}
