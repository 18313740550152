import user from "./user";
import employee from "./employee";
import leftmenu from "./leftmenu";
import loading from "./loading";
import formlist from "./formlist";
import temp from "./temp";
import toast from "./toast";
import message from "./message";
import mainTitle from "./mainTitle";
import tempReloadFlag from "./tempReloadFlag";
import { combineReducers } from "redux";

export default combineReducers({
    user,
    leftmenu,
    loading,
    employee,
    formlist,
    temp,
    toast,
    message,
    mainTitle,
    tempReloadFlag,
});
