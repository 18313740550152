import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Tooltip, Box, Chip, IconButton, styled } from "@mui/material";
import TreeItem, { TreeItemProps } from "@mui/lab/TreeItem";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
//import DeleteIcon from "@mui/icons-material/Delete";
import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "layout/MenuIcons";
import { useAccountTitleTreeContext } from "./AccountTitleTreeView";
import useItemCheck from "./useItemCheck";
import ItemCheckBox from "./ItemCheckBox";

export interface AccountTitleTreeItemProps extends Omit<TreeItemProps, "nodeId" | "label"> {
    row: AccountTitleRow;
}

export interface AccountTitleRow {
    id: number;
    code: string;
    dc: number;
    l0: number;
    l1: number;
    l2: number;
    l3: number;
    locked: boolean;
    negative: boolean;
    catalog: number;
    type: number;
    name: string;
    children?: AccountTitleRow[];
}

const DC_COLORS = ["primary", "secondary"];
const TYPE_ICONS = ["RiArchiveFill", "RiBookMarkFill", "RiBookFill", "RiFileListFill", "RiRulerFill"];

const ItemRoot = styled(Box)(({ theme }) => ({
    "& > *": {
        margin: theme.spacing(0.5),
    },
}));

function AccountTitleTreeItem({ row, ...props }: AccountTitleTreeItemProps) {
    const { t } = useTranslation();
    const { isItemLocked } = useItemCheck();
    const { selectable, editable, showToolbar, onAdd, onEdit, onI18nEdit } = useAccountTitleTreeContext();
    const dcData = t("accountTitle.dc", { returnObjects: true }) as Record<string, string>;
    const catData = t("accountTitle.cats", { returnObjects: true }) as Record<string, string>;
    const typeData = t("accountTitle.type", { returnObjects: true }) as Record<string, string>;
    const isLocked: boolean = isItemLocked(row);
    const isAddable: boolean = row.type === 2;
    return (
        <TreeItem
            /*onLabelClick={(event) => {
                event.preventDefault();
            }}*/
            {...props}
            nodeId={row.id.toString()}
            label={
                <ItemRoot p={1} display="flex" flexDirection="row" alignItems="center">
                    <Tooltip title={typeData[row.type.toString()]}>
                        <IconButton size="small">
                            <MenuIcon lib="ri" name={TYPE_ICONS[row.type]} />
                        </IconButton>
                    </Tooltip>
                    {selectable && <ItemCheckBox row={row} />}
                    <Typography>
                        <Box color="red" component="span" mr={1}>
                            {row.code}
                        </Box>
                        {row.name}
                    </Typography>
                    {row.type !== 0 && (
                        <ItemRoot ml={1}>
                            <Chip color={DC_COLORS[row.dc] as any} size="small" label={dcData[row.dc.toString()]} />
                            <Chip color="default" size="small" label={catData[row.catalog.toString()]} />
                        </ItemRoot>
                    )}
                    {showToolbar && (
                        <Box ml={1}>
                            {editable && isAddable && (
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        onAdd && onAdd(row);
                                        event.preventDefault();
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            )}
                            <IconButton
                                size="small"
                                onClick={(event) => {
                                    onEdit && onEdit(row);
                                    event.preventDefault();
                                }}
                            >
                                {!editable || isLocked ? <MenuIcon lib="ri" name="RiFileSearchLine" /> : <EditIcon />}
                            </IconButton>
                            {onI18nEdit && (
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        onI18nEdit(row.id);
                                        event.preventDefault();
                                    }}
                                >
                                    <LanguageIcon />
                                </IconButton>
                            )}
                        </Box>
                    )}
                </ItemRoot>
            }
        />
    );
}

export default AccountTitleTreeItem;
