import React from "react";
import { Box, Grid } from "@mui/material";

export interface ToolbarProps {
    children?: React.ReactNode;
    justifyContent: "space-between" | "flex-end" | "flex-start" | "center";
}

function Toolbar({ children, justifyContent }: ToolbarProps) {
    const renderChildren = () => {
        if (Array.isArray(children)) {
            return children.map((child: React.ReactNode, key) => (
                <Grid item key={key}>
                    {child}
                </Grid>
            ));
        }
        return children;
    };

    return (
        <Box my={3.5}>
            <Grid container direction={{ xs: "column", md: "row" }} justifyContent={justifyContent} alignItems="center">
                {renderChildren()}
            </Grid>
        </Box>
    );
}

Toolbar.defaultProps = {
    justifyContent: "space-between",
};

export default Toolbar;
