import React from "react";
import PropTypes from "prop-types";
import moment, { Moment } from "moment";
import { Box, IconButton, Paper, TextField, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { MobileDatePicker } from "@mui/lab";
import { ParseableDate } from "@mui/lab/internal/pickers/constants/prop-types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: theme.spacing(2),
            width: 400,
        },
        datePicker: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
    })
);

export interface YearMonthPickerProps {
    onChange: (changedDate: Moment) => void;
    value: ParseableDate<Moment>;
}

function YearMonthPicker({ onChange, value }: YearMonthPickerProps) {
    const classes = useStyles();
    const handleDateBack = () => {
        const changedDate = moment(value).subtract(1, "M");
        onChange(changedDate);
    };

    const handleDateForward = () => {
        const changedDate = moment(value).add(1, "M");
        onChange(changedDate);
    };

    return (
        <Box display="flex" justifyContent="center">
            <Paper elevation={3} className={classes.paper}>
                <IconButton className={classes.iconButton} onClick={handleDateBack} size="large">
                    <ArrowBackIosIcon fontSize="small" />
                </IconButton>
                <MobileDatePicker
                    className={classes.datePicker}
                    views={["year", "month"]}
                    value={value}
                    onChange={(date) => {
                        if (date !== null) {
                            onChange(date);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    "& .MuiInput-input": {
                                        textAlign: "center",
                                        color: "#00000099",
                                        cursor: "pointer",
                                    },
                                },
                            }}
                        />
                    )}
                    disableMaskedInput={true}
                />
                <IconButton className={classes.iconButton} onClick={handleDateForward} size="large">
                    <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
            </Paper>
        </Box>
    );
}

YearMonthPicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.shape({
        subtract: PropTypes.func,
        add: PropTypes.func,
    }),
};

export default YearMonthPicker;
