import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { Switches, TextField } from "mui-rff";
import { Grid } from "@mui/material";

export interface PasswordFieldProps {
    passwordFieldName: string;
    confirmPasswordFieldName: string;
    label?: string;
}

function PasswordField({ passwordFieldName, confirmPasswordFieldName, label }: PasswordFieldProps) {
    const { t } = useTranslation();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Switches
                    name="autoGenerate"
                    color="primary"
                    data={{ label: t("user.func.generatePassword"), value: false }}
                />
            </Grid>
            <Field name="autoGenerate">
                {(agInputProps) => {
                    //ag = autoGenerate
                    const agValue = agInputProps.input.value;
                    if (agValue) {
                        return <></>;
                    }
                    return (
                        <>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="password"
                                        label={label || t("user.fd.newPass")}
                                        name={passwordFieldName}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="password"
                                    label={t("user.fd.confirmPass")}
                                    name={confirmPasswordFieldName}
                                    required={true}
                                />
                            </Grid>
                        </>
                    );
                }}
            </Field>
            <Grid item xs={12}>
                <Switches
                    name="resetPassword"
                    color="primary"
                    data={{ label: t("user.func.resetPasswordRequire"), value: false }}
                />
            </Grid>
        </Grid>
    );
}

export default PasswordField;
