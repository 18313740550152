import React from "react";
import { useTranslation } from "react-i18next";
import { Checkboxes, makeValidate, Select, TextField } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid, MenuItem, Stack } from "@mui/material";
import FormBase from "components/final-form/FormBase";
import { DateTimePicker } from "components/final-form/Picks";
import { RequestFormBaseProps } from "./RequestContent";
import EmployeeBlock from "./EmployeeBlock";
import { OvertimeRequestFormValues } from "../formType";

export const defaultInitialValues: Partial<OvertimeRequestFormValues> = {};

function OvertimeRequestForm(props: RequestFormBaseProps) {
    const { t } = useTranslation();
    const schema: SchemaOf<OvertimeRequestFormValues> = yup
        .object()
        .shape({
            startTime: yup.date().label(t("c.fd.dateStart")).required(),
            endTime: yup.date().label(t("c.fd.dateEnd")).min(yup.ref("startTime")).required(),
            type: yup.string().label(t("overtime.fd.type")).required(),
            reason: yup.string().label(t("c.fd.reason")).trim().required().max(500),
            remark: yup.string().label(t("c.fd.rmk")).trim().max(500),
            confirmation: yup.boolean().oneOf([true], t("c.msg.checkRequired")).required(t("c.msg.checkRequired")),
        })
        .defined();
    const validate = makeValidate(schema);

    return (
        <FormBase<OvertimeRequestFormValues> validate={validate} initialValues={defaultInitialValues} {...props}>
            <FormContent />
        </FormBase>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const confirmationCheckboxLabel = t("overtime.fd.confirmation");
    const typeData = t("overtime.type", { returnObjects: true }) as Record<string, string>;
    return (
        <Stack>
            <EmployeeBlock />
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                        <DateTimePicker
                            label={t("c.fd.dateStart")}
                            name="startTime"
                            minutesStep={30}
                            inputFormat="yyyy-MM-dd HH:mm"
                        />
                        <DateTimePicker
                            label={t("c.fd.dateEnd")}
                            name="endTime"
                            minutesStep={30}
                            inputFormat="yyyy-MM-dd HH:mm"
                        />
                        <Select name="type" label={t("overtime.fd.type")}>
                            {Object.keys(typeData).map((value) => (
                                <MenuItem value={value} key={value} selected={true}>
                                    {typeData[value]}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                        <TextField
                            rows={5}
                            multiline
                            InputProps={{
                                rows: 3,
                            }}
                            label={t("c.fd.reason")}
                            name="reason"
                            inputProps={{ maxLength: 500 }}
                        />
                        <TextField rows={3} label={t("c.fd.rmk")} name="remark" inputProps={{ maxLength: 500 }} />
                    </Stack>
                </Grid>
            </Grid>
            <Checkboxes
                name="confirmation"
                label=""
                formGroupProps={{ row: true }}
                required
                data={{ label: confirmationCheckboxLabel, value: true }}
            />
        </Stack>
    );
}

export default OvertimeRequestForm;
