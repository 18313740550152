import React from "react";
import { Radios, TextField } from "mui-rff";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { Card, CardHeader, CardContent, Divider, Grid, InputAdornment, Typography } from "@mui/material";
import { TimePicker } from "components/final-form/Picks";
import { WrappedComponentProps } from "./ShiftForm";

function SecFlexibility({ shiftType, disabled = false }: WrappedComponentProps) {
    const { t } = useTranslation();
    const statusData = t("shift.flexibilityTypes", { returnObjects: true }) as Record<string, string>;
    return (
        <Card>
            <CardHeader title={t("shift.fd.flexibility")} titleTypographyProps={{ variant: "h6" }} />
            <Divider />
            <CardContent>
                {shiftType === 3 || shiftType === 4 ? (
                    <Typography variant="body1" sx={{ color: "#E31B0C", fontSize: 18 }} gutterBottom>
                        *{t("shift.shiftTypes.3")}
                    </Typography>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TimePicker
                                label={t("shift.fd.allowClockTime")}
                                name="allowClockTime"
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ color: "#E31B0C", fontSize: 12 }} gutterBottom>
                                {t("shift.alertMsg.clockInDependsOnTime")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Radios
                                radioGroupProps={{ row: true }}
                                name="flexibilityType"
                                required
                                disabled={disabled}
                                data={Object.keys(statusData).map((key) => ({
                                    label: statusData[key as keyof typeof statusData],
                                    value: key,
                                }))}
                            />
                        </Grid>
                        <Field name="flexibilityType">
                            {({ input }) => {
                                const prValue = input.value;
                                if (prValue === "0") {
                                    return <></>;
                                }
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                            }}
                                        >
                                            <TextField
                                                required={true}
                                                label={prValue === "2" ? t("shift.fd.flexTime1") : t("shift.fd.beLate")}
                                                name="flexTime1"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {t("c.units.mm")}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{ flexGrow: 1 }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                            }}
                                        >
                                            <TextField
                                                required={true}
                                                label={
                                                    prValue === "2" ? t("shift.fd.flexTime2") : t("shift.fd.leaveEarly")
                                                }
                                                name="flexTime2"
                                                fullWidth
                                                disabled={disabled}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {t("c.units.mm")}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={{ flexGrow: 1 }}
                                            />
                                        </Grid>
                                    </>
                                );
                            }}
                        </Field>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
}

export default SecFlexibility;
