import React from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import mockFilterStatus from "components/mock/absenceCardReplacementListMock";
import WorkType from "components/employee/WorkType";
import CardReplacementStatusEnum from "components/attendance/CardReplacementStatusEnum";

// Or two row data
export interface CardReplacementRowData {
    id: string;
    date?: Date;
    direction: WorkType;
    checkInTime: string;
    applicationTime?: Date;
    status: CardReplacementStatusEnum;
}

export interface AttendanceSearchTableProps extends Partial<CustomMaterialTableProps<CardReplacementRowData>> {
    options?: Options<CardReplacementRowData>;
    columns: Column<CardReplacementRowData>[];
    statusFilter?: CardReplacementStatusEnum;
    header?: React.ReactNode;
}

function CardReplacementSearchTable({
    options = {},
    columns,
    header,
    statusFilter,
    ...props
}: AttendanceSearchTableProps) {
    // TODO: retrieve real data from api
    const formTable = {
        fetch: () => mockFilterStatus(statusFilter),
    };

    return (
        <>
            {header && header}
            <MaterialTable<CardReplacementRowData>
                {...props}
                columns={columns}
                data={formTable.fetch()}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: false,
                    ...options,
                }}
            />
        </>
    );
}

export default CardReplacementSearchTable;
