import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { useTranslation } from "react-i18next";
import arrayMove from "array-move";
import { useFetchData } from "hooks";
import useDataCUD from "hooks/useDataCUD";
import {
    Avatar,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    ListItemIcon,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { addToast } from "helpers";

const useStyles = makeStyles((theme) =>
    createStyles({
        ordAvatarPrimary: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        ordAvatarSecondary: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.getContrastText(theme.palette.secondary.main),
        },
        wrapper: {
            position: "relative",
            top: "50%",
            left: "50%",
            marginLeft: -32,
        },
    })
);

function SortableDialog({
    dataFetchPath,
    dataUpdatePath,
    alreadyFetch,
    orderField,
    sortableItemTexts,
    open,
    title,
    onClose,
    onChange,
}) {
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const { handleEdit } = useDataCUD();
    const [dataList, setDataList] = useState([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [initDataList, dataListFetch] = useFetchData(dataFetchPath, "", (mData) => {
        mData.forEach((row) => {
            row._oldOrd = row[orderField];
        });
        return mData;
    });

    const fetchData = useCallback(async () => {
        setLoading(true);
        if (alreadyFetch) {
            await dataListFetch().then((result) => {
                setDataList([...result]);
            });
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFetchPath]);

    useEffect(() => {
        if (open) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFetchPath, open]);
    const handleSave = () => {
        const dirtyData = dataList
            .filter((row) => row[orderField] !== row._oldOrd)
            .map((row) => ({
                id: row.id,
                [orderField]: row[orderField],
            }));

        if (dirtyData.length > 0) {
            handleEdit({ list: dirtyData }, dataUpdatePath)
                .then(() => {
                    if (onChange) {
                        onChange();
                    }
                    fetchData();
                    onClose();
                })
                .catch(() => {});
            //handleOnSave;
        } else {
            addToast(t("c.msg.orderUnchanged"), { appearance: "warning" });
            onClose();
        }
    };
    const handleSortEnd = ({ oldIndex, newIndex }) => {
        const mDataList = arrayMove(dataList, oldIndex, newIndex);
        mDataList.forEach((row, i) => {
            row[orderField] = i + 1;
        });
        setDataList(mDataList);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle id="emp-picker-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                {loading ? (
                    <div className={classes.wrapper}>
                        <ReactLoading type="spinningBubbles" color="black" />
                    </div>
                ) : (
                    <SortableContainer onSortEnd={handleSortEnd}>
                        {dataList.map((value, index) => (
                            <SortableItem
                                key={`item-${index}`}
                                index={index}
                                row={value}
                                orderField={orderField}
                                sortableItemTexts={sortableItemTexts}
                            />
                        ))}
                    </SortableContainer>
                )}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleSave} disabled={loading}>
                    {t("c.save")}
                </Button>
                <Button autoFocus onClick={onClose}>
                    {t("c.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const SortableItem = sortableElement(({ row, orderField, sortableItemTexts }) => {
    const classes = useStyles();
    return (
        <ListItem style={{ zIndex: 2000 }}>
            <ListItemIcon>
                <Avatar
                    className={row[orderField] === row._oldOrd ? classes.ordAvatarPrimary : classes.ordAvatarSecondary}
                >
                    {row[orderField]}
                </Avatar>
            </ListItemIcon>
            {sortableItemTexts(row)}
        </ListItem>
    );
});

const SortableContainer = sortableContainer(({ children }) => {
    return <List>{children}</List>;
});

SortableDialog.defaultProps = {
    open: false,
    title: "",
    alreadyFetch: true,
};

SortableDialog.propTypes = {
    dataFetchPath: PropTypes.string.isRequired,
    dataUpdatePath: PropTypes.string.isRequired,
    alreadyFetch: PropTypes.bool,
    orderField: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    sortableItemTexts: PropTypes.func.isRequired,
};
export default SortableDialog;
