import { io } from "socket.io-client";

const uri =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_WEBSOCKET || window.location.origin
        : window.location.origin;

const socket = io(uri, {
    //path: "/socket.io",
    transports: ["websocket"],
    //secure: true,
});

export default socket;
