import React from "react";
import { useTranslation } from "react-i18next";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { TimestampRowData, useFormTable, useTimestampColumns } from "hooks";
import { dateFormat } from "helpers";

export interface EmployeeRowData extends TimestampRowData {
    id: string;
    name: string;
    hireDate?: Date;
    departmentName?: string;
    workPositionName?: string;
    status?: number;
    createdAt?: string;
    updatedAt?: string;
}

export interface EmployeeSearchTableProps extends Partial<CustomMaterialTableProps<EmployeeRowData>> {
    options?: Options<EmployeeRowData>;
}

/*
READY: foreign key field setting for sorting
const foreignKeyFields = {
    WorkPosition: "workPosition",
    Department: "department",
};
*/

function EmployeeSearchTable({ options = {}, ...props }: EmployeeSearchTableProps) {
    const formTable = useFormTable<EmployeeRowData>("/api/employee");
    const { t } = useTranslation();
    const timestampColumns = useTimestampColumns<EmployeeRowData>({ createdAt: false });
    const columns: Column<EmployeeRowData>[] = [
        {
            title: "#",
            field: "id",
            type: "string",
            filtering: false,
        },
        {
            title: t("employee.fd.employeeNo"),
            field: "employeeNo",
            type: "string",
        },
        {
            title: t("employee.fd.name"),
            field: "name",
            type: "string",
        },
        {
            title: t("employee.fd.hireDate"),
            field: "hireDate",
            type: "date",
            filtering: false,
            render: (row) => {
                if (row.hireDate) {
                    return dateFormat(row.hireDate, "YYYY/MM/DD");
                }
                return "";
            },
        },
        {
            title: t("e.depart"),
            field: "mainEmployeeDepartment.department.name",
            type: "string",
        },
        {
            title: t("e.workPosition"),
            field: "mainEmployeeDepartment.workPosition.name",
            type: "string",
        },
        {
            title: t("employee.fd.status"),
            field: "status",
            lookup: t("employee.status", { returnObjects: true }),
        },
        ...timestampColumns,
    ];
    return (
        <MaterialTable<EmployeeRowData>
            {...props}
            columns={columns}
            data={formTable.fetch()}
            options={{
                pageSizeOptions: [5, 10, 20],
                toolbar: false,
                ...options,
            }}
        />
    );
}

export default EmployeeSearchTable;
