import React, { useContext, createContext } from "react";
import { useTranslation } from "react-i18next";
import { TextField, makeValidate } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid } from "@mui/material";
import FormBase, { FormBaseProps } from "components/final-form/FormBase";

import { modeDef, ModeDefineType } from "hooks/useFormData";

export interface WorkPositionFormValues {
    id?: string;
    order?: number;
    name: string;
    jpName?: string;
    enName?: string;
    remark: string;
}

export const defaultInitialValues: Partial<WorkPositionFormValues> = {
    name: "",
    remark: "",
};

export interface WorkPositionFormBaseProps extends FormBaseProps<WorkPositionFormValues> {
    initialValues: Partial<WorkPositionFormValues>;
}

interface FormContextValue {
    formMode: ModeDefineType;
}

const FormContext = createContext<FormContextValue>({
    formMode: modeDef.ADD,
});

const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

function WorkPositionFormBase({
    initialValues = defaultInitialValues,
    onSubmit,
    formMode = modeDef.ADD,
    ...props
}: WorkPositionFormBaseProps) {
    const { t } = useTranslation();
    const schema: SchemaOf<WorkPositionFormValues> = yup
        .object()
        .shape({
            name: yup.string().label(t("workPosition.fd.name")).required(),
            remark: yup.string().label(t("c.fd.rmk")),
        })
        .defined();

    const validate = makeValidate(schema);

    return (
        <FormBase<WorkPositionFormValues>
            validate={validate}
            initialValues={initialValues}
            onSubmit={onSubmit}
            {...props}
        >
            <FormContext.Provider value={{ formMode }}>
                <FormContent />
            </FormContext.Provider>
        </FormBase>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const { formMode } = useFormContext();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField label={t("workPosition.fd.name")} name="name" required />
            </Grid>
            {formMode === modeDef.EDIT && (
                <Grid item xs={12}>
                    <TextField
                        label={t("workPosition.fd.order")}
                        name="order"
                        type="number"
                        inputProps={{ maxLength: 3, min: 0, max: 999 }}
                        required
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <TextField label={t("c.fd.rmk")} defaultValue="" name="remark" />
            </Grid>
        </Grid>
    );
}

export default WorkPositionFormBase;
