import React from "react";
import { Box, BoxProps } from "@mui/material";

export default function PathItemHeader(props: BoxProps) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                textAlign: "center",
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "500",
                ...sx,
            }}
            {...other}
        />
    );
}
