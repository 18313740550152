import React from "react";
import { Field } from "react-final-form";
import TotalInfoBlack from "./TotalInfoBlack";

interface TotalInfoFormBlackProps {
    title: string;
    name: string;
}

function TotalInfoFormBlack({ title, name }: TotalInfoFormBlackProps) {
    return (
        <Field name={name}>
            {({ input }) => {
                const value = input.value;
                return <TotalInfoBlack title={title} value={value || ""} />;
            }}
        </Field>
    );
}

export default TotalInfoFormBlack;
