import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ImportPreviewSearchTable from "./ImportPreviewSearchTable";
import ImportPreviewColumnsFactory from "./ImportPreviewColumnsFactory";
import ImportPreviewTabs from "./ImportPreviewTabs";

function ImportPreview() {
    const { t } = useTranslation();
    const tableRef = useRef<any>(null);
    // TODO: overflow dialog
    const employeeTab1 = <ImportPreviewSearchTable tableRef={tableRef} columns={ImportPreviewColumnsFactory(t)} />;
    const tabs = [
        { index: 0, label: t("米開朗基羅"), children: employeeTab1 },
        { index: 1, label: t("愛迪生"), children: employeeTab1 },
        { index: 2, label: t("貝多芬"), children: employeeTab1 },
    ];

    return <ImportPreviewTabs tabs={tabs} />;
}

export default ImportPreview;
