import React, { Fragment, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Box, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Action } from "@material-table/core";
import ActionIcon from "./ActionIcon";

export interface ExtraActionsProps<RowData extends object> {
    extraActions?: (Action<RowData> | ((rowData: RowData) => Action<RowData>))[];
    data: RowData | RowData[];
    disabled: boolean;
    size: string;
    //components: Components;
}

type ButtonSize = "small" | "medium";

function ExtraActions<RowData extends object>({ extraActions = [], data, disabled, size }: ExtraActionsProps<RowData>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const menuItems = extraActions.map((action, index) => {
        let mAction: Action<RowData> | null = null;
        if (typeof action === "function") {
            if (!Array.isArray(data)) {
                mAction = action(data);
            }
        } else {
            mAction = action;
        }
        if (!mAction) {
            return <Fragment key={index}></Fragment>;
        }
        const mDisabled = mAction.disabled || disabled;
        return (
            <MenuItem
                key={index}
                onClick={(event) => {
                    mAction && mAction.onClick(event, data);
                    handleClose();
                }}
                disabled={mDisabled}
            >
                {mAction.icon && (
                    <Box mr={1} component="span" display="flex" alignItems="center" color="primary.main">
                        <ActionIcon icon={mAction.icon} iconProps={mAction.iconProps} disabled={mDisabled} />
                    </Box>
                )}
                <Box component="span">{mAction.tooltip}</Box>
            </MenuItem>
        );
    });

    return (
        <>
            <Tooltip title={t("c.other").toString()}>
                <IconButton size={size as ButtonSize} color="inherit" disabled={disabled} onClick={handleOnClick}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems}
            </Menu>
        </>
    );
}

export default ExtraActions;
