import React, { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import axios, { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { Button, ButtonProps, DialogProps, Grid, TextField } from "@mui/material";
import { ConfirmDialog } from "components";
import { addToast, loadControl as LC } from "helpers";
import { ResponseHandler } from "common";
import { PreProcessCheckResult } from "common/accounting";

export interface ACProcessDataViewProps {
    type?: "create" | "delete";
    checkResult: PreProcessCheckResult | null;
    ButtonProps?: ButtonProps;
    DialogProps?: Omit<DialogProps, "open">;
    onSuccess?: () => void;
}

function ACProcessDataView({
    type = "create",
    checkResult,
    ButtonProps = {},
    DialogProps = {},
    onSuccess = () => {},
}: ACProcessDataViewProps) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [yearMonth, setYearMonth] = useState<Moment | null>(null);
    const [processable, setProcessable] = useState(false);
    const ymFmt = t("c.f.yymm");
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const processSuccess = (resp: AxiosResponse<ResponseHandler>) => {
        if ("status" in resp.data && resp.data.status === "ok") {
            addToast(t("c.msg.processSuccess"));
            if (onSuccess) {
                onSuccess();
            }
        } else {
            addToast(t("c.msg.formDataError"), { appearance: "error" });
        }
    };

    const processFailed = (err: any) => {
        console.log(err);
        addToast(t("c.msg.processFailed"), { appearance: "error" });
    };

    const handleAgreeButtonClick = async () => {
        if (type === "create") {
            LC();
            await axios
                .post("/api/accountConsolidation/")
                .then(processSuccess)
                .catch(processFailed)
                .finally(() => {
                    setLoading(false);
                    LC(false);
                });
        }
        if (type === "delete") {
            LC();
            await axios
                .delete("/api/accountConsolidation/")
                .then(processSuccess)
                .catch(processFailed)
                .finally(() => {
                    setLoading(false);
                    LC(false);
                });
        }
    };

    const renderContent = () => {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label={t("c.fd.yearMonth")}
                        InputProps={{
                            readOnly: true,
                        }}
                        value={yearMonth && yearMonth.format(ymFmt)}
                    />
                </Grid>
            </Grid>
        );
    };

    const renderIcon = () => {
        if (type === "create") {
            return <FaLock />;
        }
        if (type === "delete") {
            return <FaLockOpen />;
        }
        return <></>;
    };

    const renderTitle = () => {
        if (type === "create") {
            return t("accountConsolidation.create");
        }
        if (type === "delete") {
            return t("accountConsolidation.delete");
        }
        return "";
    };

    const renderButtonLabel = () => {
        if (type === "create") {
            return t("accountConsolidation.create");
        }
        if (type === "delete") {
            return t("accountConsolidation.delete");
        }
    };

    useEffect(() => {
        if (checkResult && checkResult.date) {
            const lastDataDate = moment(checkResult.date);
            if (type === "create") {
                const mYearMonth = lastDataDate.add(1, "month");
                setYearMonth(mYearMonth);
                setProcessable(checkResult.status === "ok");
            }
            if (type === "delete") {
                setYearMonth(lastDataDate);
                setProcessable(checkResult.status !== "no_initial_data");
            }
        }
    }, [checkResult, type]);

    return (
        <>
            <Button
                variant="contained"
                color={type === "delete" ? "error" : "primary"}
                endIcon={renderIcon()}
                disabled={loading && !processable}
                onClick={handleOpen}
                {...ButtonProps}
            >
                {renderButtonLabel()}
            </Button>
            <ConfirmDialog
                open={open}
                title={renderTitle()}
                content={renderContent()}
                handleClose={handleClose}
                handleAgree={handleAgreeButtonClick}
                handleDisagree={handleClose}
                maxWidth="md"
                agreeButtonProps={{
                    disabled: !processable,
                }}
                {...DialogProps}
            ></ConfirmDialog>
        </>
    );
}

export default ACProcessDataView;
