import React, { useMemo } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LiveClock from "react-live-clock";
import moment from "moment";
import { Stack, Typography, Grid, Button, styled } from "@mui/material";
import { addToast, getUser, loadControl as LC } from "helpers";
import { DutyType } from "./ClockInRow";

const StyledLiveClock = styled(LiveClock)(({ theme }) => ({
    fontSize: "96px",
    color: "#006699",
    [theme.breakpoints.down("sm")]: {
        fontSize: "56px",
    },
}));

const DateTypography = styled(Typography)(({ theme }) => ({
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
    },
}));

const ClockInButton = styled(Button)(() => ({
    width: "217px",
    fontSize: "20px",
}));

export interface ClockInContentProps {
    onClockInFinished?: () => void;
}

function ClockInContent({ onClockInFinished }: ClockInContentProps) {
    const { t } = useTranslation();
    const user = useMemo(() => getUser(), []);
    const date = moment().format("LL (dd)");
    const handleClockIn = (buttonStatus: DutyType) => {
        const inputs = {
            dutyType: buttonStatus,
        };
        LC();
        axios
            .post("/api/attendance", inputs)
            .then(function (response) {
                const data = response.data;
                if ("status" in data) {
                    if (data.status === "ok") {
                        //fetchData();
                        onClockInFinished && onClockInFinished();
                        addToast(t("attendance.fd.clockInSuccess"), { appearance: "success" });
                    } else {
                        addToast(t("c.msg.severError"), { appearance: "error" });
                    }
                }
            })
            .catch((err) => {
                addToast(t("c.msg.severError"), { appearance: "error" });
                console.log(err);
            })
            .finally(() => {
                LC(false);
            });
    };
    return (
        <Stack>
            <DateTypography color="textSecondary" align="center">
                {date}
            </DateTypography>
            <Typography align="center" variant="h1">
                <StyledLiveClock format="HH:mm:ss" ticking={true} />
            </Typography>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>
                    <ClockInButton
                        variant="contained"
                        color="primary"
                        onClick={() => handleClockIn(DutyType.GO_TO_WORK)}
                        disabled={!user.employee}
                    >
                        {t("attendance.fd.clockIn")}
                    </ClockInButton>
                </Grid>
                <Grid item>
                    <ClockInButton
                        variant="contained"
                        color="primary"
                        onClick={() => handleClockIn(DutyType.GET_OF_WORK)}
                        disabled={!user.employee}
                    >
                        {t("attendance.fd.clockOff")}
                    </ClockInButton>
                </Grid>
            </Grid>
        </Stack>
    );
}

export default ClockInContent;
