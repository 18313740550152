import React, { useRef, useEffect, useCallback, useState, MouseEvent, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from "@mui/material";
import { SimpleLoading } from "components";
import { FormBaseRefProps } from "components/final-form/FormBase";
import useFormData, { modeDef } from "hooks/useFormData";
import UserFormBase, { UserFormValues, defaultInitialValues } from "./UserFormBase";
import { PasswordViewerDialogRef } from "./PasswordViewerDialog";

export interface UserFormDialogProps extends DialogProps {
    dataId: string | null;
    onChange?: () => void;
    passwordViewerDialogRef: RefObject<PasswordViewerDialogRef>;
}

function UserFormDialog({ dataId, onChange, onClose, passwordViewerDialogRef, ...props }: UserFormDialogProps) {
    const formRef = useRef<FormBaseRefProps<UserFormValues>>(null);
    const { t } = useTranslation();
    const [formDataLoading, setFormDataLoading] = useState(false);
    const formData = useFormData<UserFormValues>("/api/user", defaultInitialValues, dataId, (data) => {
        if (data) {
            data.loginType = data.loginType.toString();
        }
    });

    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const handleSubmit = async (values: UserFormValues) => {
        //Check if data has been changed or not
        const mValue = { ...values };
        if (Number(mValue.loginType) === 0) {
            delete mValue.email;
            if (mValue.autoGenerate) {
                delete mValue.password;
            }
        } else if (Number(mValue.loginType) === 1) {
            mValue.autoGenerate = false;
            mValue.resetPassword = false;
            mValue.account = mValue.email || "";
            delete mValue.password;
        }
        if (mValue.employeeId === 0) {
            delete mValue.employeeId;
        }
        await formData
            .save(mValue)
            .then(async (resp) => {
                onChange && onChange();
                fetchData();
                if (values.autoGenerate && resp && resp.result) {
                    const newPassword = resp.result.password;
                    newPassword &&
                        passwordViewerDialogRef.current &&
                        passwordViewerDialogRef.current.show(newPassword, `#${resp.result.id} ${resp.result.name}`);
                }
                handleClose();
            })
            .catch(() => {});
    };

    const fetchData = useCallback(async () => {
        setFormDataLoading(true);
        await formData
            .fetch()
            .catch((error) => {
                console.log(error);
                handleClose();
            })
            .finally(() => {
                setFormDataLoading(false);
            });
    }, [dataId]);

    useEffect(() => {
        fetchData();
        return () => {
            formData.cleanup();
        };
    }, [fetchData, dataId]);

    return (
        <Dialog {...props} aria-labelledby="user-dialog-title">
            <DialogTitle id="user-dialog-title">{formData.mode === modeDef.ADD ? t("c.add") : t("c.edit")}</DialogTitle>
            <DialogContent>
                {formDataLoading ? (
                    <SimpleLoading />
                ) : (
                    <UserFormBase
                        onSubmit={handleSubmit}
                        initialValues={formData.data}
                        formMode={formData.mode}
                        formRef={formRef}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserFormDialog;
