import React, { MouseEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Typography,
    Tooltip,
    Popover,
    Box,
    Menu,
    PopoverProps,
    MenuProps,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TranslateIcon from "@mui/icons-material/Translate";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getUserName, getAvatarColor, getAbbr } from "helpers/userHelper";
import onLogout from "helpers/logout";
import { getUser } from "helpers";
import { DEF } from "Action";
import { MobileViewWrapper, AvatarImg, PcViewWrapper, TopToolbar, TopAppBar, MenuButton } from "./layoutComponents";
import TopBarDrawer from "./TopBarDrawer";
import PageFinder from "./PageFinder";
import LangList from "./LangList";
import MessageView from "./MessageView";
import { RootState } from "./types";

const UserPopover = (props: PopoverProps) => {
    const user = useMemo(() => getUser(), []);
    const { t } = useTranslation();
    return (
        <>
            <Popover {...props}>
                <Box p={2}>
                    <Typography variant="h6">{getUserName(user)}</Typography>
                    <Typography variant="body2">Group</Typography>
                </Box>
                <Divider />
                <List component="nav">
                    <ListItem button onClick={onLogout}>
                        <ListItemText primary={t("c.logout")} />
                    </ListItem>
                </List>
            </Popover>
        </>
    );
};

function PageFinderPopOver({ onClose, ...props }: PopoverProps) {
    return (
        <Popover onClose={onClose} {...props}>
            <PageFinder
                onClose={(e) => {
                    onClose && onClose(e, "backdropClick");
                }}
            />
        </Popover>
    );
}

function LangMenu({ onClose, ...props }: MenuProps) {
    const langList = LangList({
        onParentClose: (e) => {
            onClose && onClose(e, "backdropClick");
        },
    });
    return (
        <Menu onClose={onClose} {...props}>
            {langList}
        </Menu>
    );
}

function TopBar() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useMemo(() => getUser(), []);
    const history = useHistory();
    const open = useSelector((state: RootState) => state.leftmenu);
    const [userPopEl, setUserPopEl] = React.useState<HTMLButtonElement | null>(null);
    const [langMenuEl, setLangMenuEl] = React.useState<HTMLButtonElement | null>(null);
    const [searchPopEl, setSearchPopEl] = React.useState<HTMLButtonElement | null>(null);
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const handleLangMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setLangMenuEl(event.currentTarget);
    };
    const handleLangMenuClose = () => {
        setLangMenuEl(null);
    };
    const handleUserPopOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setUserPopEl(event.currentTarget);
    };
    const handleUserPopClose = () => {
        setUserPopEl(null);
    };
    const handleSearchPopOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setSearchPopEl(event.currentTarget);
    };
    const handleSearchPopClose = () => {
        setSearchPopEl(null);
    };
    const handleDrawerOpen = () => {
        dispatch({ type: DEF.LEFT_MENU_OPEN });
    };
    const handleRightDrawerOpen = () => {
        setDrawerOpen(true);
    };
    const handleRightDrawerClose = () => {
        setDrawerOpen(false);
    };
    const handleClick = () => {
        history.push("/");
    };
    return (
        <>
            <TopAppBar position="absolute" open={open}>
                <TopToolbar>
                    <MenuButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        open={open}
                        size="large"
                    >
                        <MenuIcon />
                    </MenuButton>
                    <Typography
                        onClick={handleClick}
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, cursor: "pointer" }}
                    >
                        Future Manager - {user.company.name}
                    </Typography>
                    <PcViewWrapper>
                        <Tooltip title={t("ui.search").toString()} aria-label="search">
                            <IconButton color="inherit" onClick={handleSearchPopOpen} size="large">
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("ui.lang").toString()} aria-label="language">
                            <IconButton color="inherit" onClick={handleLangMenuOpen} size="large">
                                <TranslateIcon />
                            </IconButton>
                        </Tooltip>
                        <MessageView />
                        <IconButton color="inherit" onClick={handleUserPopOpen} size="large">
                            {user.picture ? (
                                <Avatar>
                                    <AvatarImg src={user.picture} alt="" />
                                </Avatar>
                            ) : (
                                <Avatar style={{ background: getAvatarColor(user) }}>{getAbbr(user)}</Avatar>
                            )}
                        </IconButton>
                    </PcViewWrapper>
                    <MobileViewWrapper>
                        <IconButton color="inherit" onClick={handleRightDrawerOpen} size="large">
                            <MoreVertIcon />
                        </IconButton>
                    </MobileViewWrapper>
                </TopToolbar>
            </TopAppBar>
            <LangMenu
                open={Boolean(langMenuEl)}
                anchorEl={langMenuEl}
                //keepMounted
                onClose={handleLangMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            />
            <UserPopover
                open={Boolean(userPopEl)}
                anchorEl={userPopEl}
                onClose={handleUserPopClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            />
            <PageFinderPopOver
                open={Boolean(searchPopEl)}
                anchorEl={searchPopEl}
                onClose={handleSearchPopClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            />
            <TopBarDrawer open={drawerOpen} onClose={handleRightDrawerClose} />
        </>
    );
}

export default TopBar;
