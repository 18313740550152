import React, { useRef, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { TextField, makeValidate } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogProps,
    Grid,
    InputAdornment,
} from "@mui/material";
import FormBase, { FormBaseRefProps } from "components/final-form/FormBase";
import { useFormData } from "hooks";
import { ResponseHandler } from "common";
import { AccountTitleFormValues } from "../accountTitle/ItemEditorDialog";
import { useBankAccountFormContext } from "./BankAccountList";
import { AccountTitleRow } from "../accountTitle";

export type NewAccountTitleFormValue = Pick<AccountTitleFormValues, "code" | "name"> & { type?: number };

export const defaultInitialValues: Partial<NewAccountTitleFormValue> = {
    code: "",
    name: "",
};
export interface CreateAccountTitleDialogProps extends DialogProps {
    type: number;
    onFinished?: (resp?: void | ResponseHandler<AccountTitleRow>) => void;
}

function CreateAccountTitleDialog({ type, onFinished, onClose, ...props }: CreateAccountTitleDialogProps) {
    const { t } = useTranslation();
    const { accountTitleSettings } = useBankAccountFormContext();
    const parentAccountTitle = accountTitleSettings[type === 0 ? "bank" : "cash"];
    const formRef = useRef<FormBaseRefProps<NewAccountTitleFormValue>>(null);
    const formData = useFormData<NewAccountTitleFormValue>("/api/bankAccount/createAccountTitle", defaultInitialValues);
    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const handleSubmit = async (values: NewAccountTitleFormValue) => {
        //Check if data has been changed or not
        const mValue = {
            type,
            code: (parentAccountTitle?.code || "") + values.code,
            name: (parentAccountTitle?.name || "") + "-" + values.name,
        };
        //addDataKey(values, "remark");
        await formData
            .save(mValue)
            .then(async (resp) => {
                onFinished && onFinished(resp as any);
                handleClose();
            })
            .catch(() => {});
    };
    const schema: SchemaOf<AccountTitleFormValues> = yup
        .object()
        .shape({
            code: yup.number().label(t("accountTitle.fd.code")).required(),
            name: yup.string().label(t("accountTitle.fd.name")).required(),
        })
        .defined();

    const validate = makeValidate(schema);
    return (
        <Dialog {...props} maxWidth="md" fullWidth aria-labelledby="create-account-dialog-title">
            <DialogTitle id="create-account-dialog-title">{t("bankAccount.addAccountTitle")}</DialogTitle>
            <DialogContent>
                <FormBase<NewAccountTitleFormValue>
                    validate={validate}
                    initialValues={defaultInitialValues}
                    onSubmit={handleSubmit as any}
                    formRef={formRef}
                    {...props}
                >
                    <FormContent parentAccountTitle={parentAccountTitle} />
                </FormBase>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface FormContentProps {
    parentAccountTitle?: AccountTitleRow;
}

function FormContent({ parentAccountTitle }: FormContentProps) {
    const { t } = useTranslation();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <TextField
                    label={t("accountTitle.fd.code")}
                    name="code"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">{parentAccountTitle?.code || ""}</InputAdornment>
                        ),
                    }}
                    inputProps={{ maxLength: 2 }}
                    required
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    label={t("accountTitle.fd.name")}
                    name="name"
                    defaultValue=""
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">{parentAccountTitle?.name || ""}-</InputAdornment>
                        ),
                    }}
                    inputProps={{ maxLength: 50 }}
                />
            </Grid>
        </Grid>
    );
}

export default CreateAccountTitleDialog;
