import React from "react";
import { useTranslation } from "react-i18next";
import { makeValidate, Select } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid, MenuItem } from "@mui/material";
import FormBase, { FormBaseProps } from "components/final-form/FormBase";
import { useReduxTempData } from "hooks";
import FormRow from "components/audit/FormRow";
import { Field } from "react-final-form";

export interface ApplicationSelectFormValues {
    formType: string;
    formName: string;
}

// TODO: buttons in the form dialog
function ApplicationSelectForm({ onSubmit, ...props }: FormBaseProps<ApplicationSelectFormValues>) {
    const { t } = useTranslation();

    const schema: SchemaOf<ApplicationSelectFormValues> = yup
        .object()
        .shape({
            formName: yup.string().label(t("e.form")).required(),
        })
        .defined();
    const validate = makeValidate(schema);

    return (
        <FormBase<ApplicationSelectFormValues>
            validate={validate}
            initialValues={{ formType: "0" }}
            onSubmit={onSubmit}
            {...props}
        >
            <FormContent />
        </FormBase>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const formRowData = useReduxTempData<FormRow[]>(`api/form`, t("e.form"));
    const formTypeData = t("audit.formType", { returnObjects: true }) as Record<string, string>;
    return (
        <Grid container rowSpacing={2} sx={{ minWidth: 300 }}>
            <Grid item xs={12}>
                <Select name="formType" label={t("audit.fd.type")}>
                    {Object.keys(formTypeData).map((key) => (
                        <MenuItem value={key} key={key} selected={true}>
                            {formTypeData[key]}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Field name="formType">
                    {({ input }) => {
                        const value = input.value;
                        return (
                            <Select name="formName" label={t("e.form")}>
                                {(formRowData &&
                                    formRowData
                                        .filter((item) => Number(item.type) === Number(value))
                                        .map((item) => {
                                            const { name } = item;
                                            return (
                                                <MenuItem key={name} value={name}>
                                                    {t(`form.title.${name}`)}
                                                </MenuItem>
                                            );
                                        })) ||
                                    []}
                            </Select>
                        );
                    }}
                </Field>
            </Grid>
        </Grid>
    );
}

export default ApplicationSelectForm;
