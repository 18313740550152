import DEF from "../def";

export default function reducer(state = [], action) {
    switch (action.type) {
        case DEF.FORMLIST_UPDATE:
            return [...action.value];
        case DEF.CLEAR_ALL:
        case DEF.FORMLIST_CLEAR:
            return [];
        default:
            return state;
    }
}
