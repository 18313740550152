import React, {
    useRef,
    useState,
    useContext,
    createContext,
    RefObject,
    useCallback,
    useEffect,
    createRef,
    useMemo,
} from "react";
import nl2br from "react-nl2br";
import { useTranslation } from "react-i18next";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { Field } from "react-final-form";
import { Select, TextField, makeValidate } from "mui-rff";
import moment, { Moment } from "moment";
import { FaFilePdf } from "react-icons/fa";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid, Button, MenuItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { PageHeader2 as PageHeader } from "layout";
import { ExpendableCard, ExpandableContent, ExpendableCardRef } from "components";
import FormBase, { FormBaseRefProps } from "components/final-form/FormBase";
import { TextItem, DatePicker, TextDateItem, TextLookupItem, LookupRadios } from "components/final-form";
import { download } from "helpers/fileHelper";
import { useEmployeeDataReloadFlag, useFormData, useUserPermission } from "hooks";
export interface EmployeeFormValues {
    name: string;
    jpName?: string;
    enName?: string;
    employeeNo?: string;
    status: number | string;
    nationality?: string;
    birthday?: string | Date | Moment;
    personId?: string;
    gender: number | string;
    residentialAddress?: string;
    contactAddress?: string;
    tel?: string;
    mobile?: string;
    email?: string;
    privateEmail?: string;
    dependents?: number;
    bankAccount?: string;
    emergencyName?: string;
    emergencyRelation?: string;
    emergencyTel?: string;
    highestEdu?: string;
    graduatedSchool?: string;
    major?: string;
    hireDate?: string | Date;
    probationStart?: string | Date | Moment;
    probationEnd?: string | Date | Moment;
    leaveDate?: string | Date | Moment;
    remark?: string;
}

interface FormContextValue {
    isEditMode: boolean;
    formRef: RefObject<FormBaseRefProps<EmployeeFormValues>>;
    secRefs: RefObject<ExpendableCardRef>[];
}

const FormContext = createContext<FormContextValue>({
    isEditMode: false,
    formRef: {
        current: null,
    },
    secRefs: [],
});

const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

function MainForm() {
    const { id } = useParams<{ id: string }>();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const formRef = useRef<FormBaseRefProps<EmployeeFormValues>>(null);
    const { t } = useTranslation();
    const history = useHistory();
    const secRefs = useRef<RefObject<ExpendableCardRef>[]>([]);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("employee"), [isWrite]);
    const { addFlag } = useEmployeeDataReloadFlag();
    for (let i = 0; i < 5; i++) {
        secRefs.current[i] = secRefs.current[i] ?? createRef<ExpendableCardRef>();
    }
    const formData = useFormData<EmployeeFormValues>("/api/employee", {}, id, (data) => {
        if (data) {
            data.status = data.status.toString(); //handle for option button
            if (data.gender) {
                data.gender = data.gender && data.gender.toString();
            }
            ["hireDate", "probationStart", "probationEnd", "leaveDate", "birthday"].forEach((field) => {
                const mField = field as keyof typeof data;
                if (data[mField]) {
                    data[mField] = moment.utc(data[mField]) as never;
                }
            });
        }
    });

    const expendAllSection = () => {
        secRefs.current.forEach((refObject) => {
            console.log(refObject);
            refObject.current && refObject.current.setExpanded(true);
        });
    };

    /*const expendDefault = () => {
        secRefs.current.forEach((refObject, index) => {
            refObject.current && refObject.current.setExpanded(index === 0);
        });
    };*/

    const handleSubmit = async (values: EmployeeFormValues) => {
        //
        await formData.save(values).then((resp) => {
            //formData.fetch();
            addFlag();
            handleCancel();
        });
    };
    const handleEdit = async () => {
        if (isEditMode) {
            formRef.current && formRef.current.onSubmit();
        } else {
            setIsEditMode(true);
        }
    };

    const handleCancel = async () => {
        formData.fetch();
        setIsEditMode(false);
    };

    const handleProfileDownload = async () => {
        download(`/api/employee/pdf/${id}`, `employee_data_${id}.pdf`);
    };

    const goBack = () => {
        history.replace({ pathname: "/employee" }); //
    };

    const fetchData = useCallback(async () => {
        if (id) {
            await formData
                .fetch()
                //.then(() => {})
                .catch((err) => {
                    if (!formData.isCancel) {
                        goBack(); //if data not found
                        console.log(err);
                    }
                });
        } else {
            goBack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        fetchData();
        return () => {
            formData.cleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData, id]);

    useEffect(() => {
        if (isEditMode) {
            expendAllSection();
        }
    }, [isEditMode]);

    const schema: SchemaOf<EmployeeFormValues> = yup
        .object()
        .shape({
            name: yup.string().label(t("employee.fd.name")).trim().max(50).required(),
            jpName: yup.string().label(t("employee.fd.jpName")).trim().max(50).nullable(),
            enName: yup.string().label(t("employee.fd.enName")).trim().max(50).nullable(),
            employeeNo: yup.string().label(t("employee.fd.employeeNo")).trim().max(10).required(),
            birthday: yup.date().label(t("employee.fd.birthday")).required(),
            status: yup.string().label(t("employee.fd.status")).oneOf(["0", "1", "2"]).required(),
            gender: yup
                .string()
                .label(t("employee.fd.gender"))
                .oneOf(["1", "2"], t("employee.msg.genderError"))
                .required(),
            nationality: yup.string().label(t("employee.fd.nationality")).oneOf(["TW", "JP", "O", ""]).nullable(),
            personId: yup.string().label(t("employee.fd.personId")).trim().max(10).required(),
            residentialAddress: yup.string().nullable(),
            contactAddress: yup.string().nullable(),
            tel: yup.string().label(t("employee.fd.tel")).trim().nullable(),
            mobile: yup.string().label(t("employee.fd.mobile")).trim().nullable(),
            email: yup.string().label(t("employee.fd.compEmail")).trim().email().nullable(),
            privateEmail: yup.string().label(t("employee.fd.privateEmail")).trim().email().nullable(),
            emergencyName: yup.string().nullable(),
            emergencyRelation: yup.string().nullable(),
            emergencyTel: yup.string().nullable(),
            highestEdu: yup.string().nullable(),
            graduatedSchool: yup.string().nullable(),
            major: yup.string().nullable(),
            hireDate: yup.date().label(t("employee.fd.hireDate")).required(),
            probationStart: yup
                .date()
                .label(t("employee.fd.probStart"))
                .when("probationEnd", (val, schema) =>
                    val
                        ? schema.max(yup.ref("probationEnd")).min(yup.ref("hireDate")).required()
                        : schema.min(yup.ref("hireDate")).nullable()
                ),
            probationEnd: yup.date().label(t("employee.fd.probEnd")).nullable(),
            leaveDate: yup
                .date()
                .label(t("employee.fd.leaveDate"))
                .when("status", (val, schema) =>
                    val === "2" ? schema.min(yup.ref("hireDate")).required() : schema.nullable(true)
                ),
        })
        .defined();
    const validate = makeValidate(schema);

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={handleProfileDownload}
                                color="primary"
                                startIcon={<FaFilePdf />}
                            >
                                {t("employee.printSheet")}
                            </Button>
                        </Grid>
                    </Grid>
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        {!isEditMode && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    component={NavLink}
                                    to={"/employee"}
                                    startIcon={<ArrowBackIcon />}
                                >
                                    {t("c.backpage")}
                                </Button>
                            </Grid>
                        )}
                        {isEditMode && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancel}
                                    startIcon={<ClearIcon />}
                                >
                                    {t("c.cancel")}
                                </Button>
                            </Grid>
                        )}
                        {writable && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleEdit}
                                    startIcon={isEditMode ? <SaveIcon /> : <EditIcon />}
                                >
                                    {isEditMode ? t("c.save") : t("c.edit")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
            />
            <FormBase<EmployeeFormValues>
                validate={validate}
                onSubmit={handleSubmit}
                initialValues={formData.data}
                formRef={formRef}
            >
                <FormContext.Provider value={{ isEditMode, formRef, secRefs: secRefs.current ?? secRefs.current }}>
                    <FormContent />
                </FormContext.Provider>
            </FormBase>
        </>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const { secRefs } = useFormContext();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <ExpendableCard title={t("c.fd.secBase")} initialExpended={true} ref={secRefs[0]}>
                    <SecBasic />
                </ExpendableCard>
            </Grid>
            <Grid item xs={12}>
                <ExpendableCard title={t("employee.fd.secPersonal")} ref={secRefs[1]}>
                    <SecPersonal />
                </ExpendableCard>
            </Grid>
            <Grid item xs={12}>
                <ExpendableCard title={t("employee.fd.secEmergency")} ref={secRefs[2]}>
                    <SecEmergency />
                </ExpendableCard>
            </Grid>
            <Grid item xs={12}>
                <ExpendableCard title={t("employee.fd.secEdu")} ref={secRefs[3]}>
                    <SecEdu />
                </ExpendableCard>
            </Grid>
            <Grid item xs={12}>
                <ExpendableCard title={t("c.fd.rmk")} ref={secRefs[4]}>
                    <SecRemark />
                </ExpendableCard>
            </Grid>
        </Grid>
    );
}

function SecBasic() {
    const { isEditMode, formRef } = useFormContext();
    const { t } = useTranslation();
    const statusData = t("employee.status", { returnObjects: true }) as Record<string, string>;
    return (
        <ExpandableContent>
            {isEditMode ? (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                required
                                label={t("employee.fd.name")}
                                name="name"
                                inputProps={{ maxLength: 50 }}
                                autoComplete="name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                label={t("employee.fd.enName")}
                                name="enName"
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                label={t("employee.fd.jpName")}
                                name="jpName"
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="standard"
                                name="employeeNo"
                                required
                                label={t("employee.fd.employeeNo")}
                                inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LookupRadios label={t("employee.fd.status")} name="status" lookup={statusData} />
                        </Grid>
                        <Field name="status">
                            {({ input }) => (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            variant="standard"
                                            label={t("employee.fd.probStart")}
                                            name="probationStart"
                                            disabled={input.value === "0"}
                                            formRef={formRef}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            variant="standard"
                                            label={t("employee.fd.probEnd")}
                                            name="probationEnd"
                                            disabled={input.value === "0"}
                                            formRef={formRef}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DatePicker
                                            variant="standard"
                                            label={t("employee.fd.hireDate")}
                                            name="hireDate"
                                            required
                                            formRef={formRef}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DatePicker
                                            variant="standard"
                                            label={t("employee.fd.leaveDate")}
                                            name="leaveDate"
                                            disabled={input.value !== "2"}
                                            required={input.value === "2"}
                                            formRef={formRef}
                                            clearable
                                        />
                                    </Grid>
                                </>
                            )}
                        </Field>
                    </Grid>
                </>
            ) : (
                <>
                    <TextItem name="name" label={t("employee.fd.name")} />
                    <TextItem name="jpName" label={t("employee.fd.jpName")} />
                    <TextItem name="enName" label={t("employee.fd.enName")} />
                    <TextItem name="employeeNo" label={t("employee.fd.employeeNo")} />
                    <TextLookupItem name="status" label={t("employee.fd.status")} lookup={statusData} />
                    <TextDateItem name="probationStart" label={t("employee.fd.probStart")} />
                    <TextDateItem name="probationEnd" label={t("employee.fd.probEnd")} />
                    <TextDateItem name="hireDate" label={t("employee.fd.hireDate")} />
                    <TextDateItem name="leaveDate" label={t("employee.fd.leaveDate")} />
                </>
            )}
        </ExpandableContent>
    );
}

function SecPersonal() {
    const { isEditMode, formRef } = useFormContext();
    const { t } = useTranslation();
    const genderData = t("employee.gender", { returnObjects: true }) as Record<string, string>;
    return (
        <ExpandableContent>
            {isEditMode ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Select variant="standard" name="nationality" label={t("employee.fd.nationality")}>
                            <MenuItem value="">{t("c.pleaseSelect")}</MenuItem>
                            <MenuItem value="TW">{t("employee.nationality.TW")}</MenuItem>
                            <MenuItem value="JP">{t("employee.nationality.JP")}</MenuItem>
                            <MenuItem value="O">{t("employee.nationality.O")}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            name="personId"
                            label={t("employee.fd.personId")}
                            inputProps={{ maxLength: 20 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            variant="standard"
                            label={t("employee.fd.birthday")}
                            name="birthday"
                            formRef={formRef}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LookupRadios label={t("employee.fd.gender")} name="gender" lookup={genderData} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            name="residentialAddress"
                            label={t("employee.fd.regAddr")}
                            inputProps={{ maxLength: 200 }}
                            autoComplete="address"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            name="dependents"
                            label={t("employee.fd.dependents")}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            name="bankCode"
                            label={t("employee.fd.bankCode")}
                            type="tel"
                            inputProps={{ maxLength: 3 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            name="bankAccount"
                            label={t("employee.fd.bankAccount")}
                            type="tel"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            name="contactAddress"
                            label={t("employee.fd.conAddr")}
                            autoComplete="address"
                            inputProps={{ maxLength: 200 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            name="tel"
                            label={t("employee.fd.tel")}
                            inputProps={{ maxLength: 12 }}
                            type="tel"
                            autoComplete="tel"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            name="mobile"
                            label={t("employee.fd.mobile")}
                            inputProps={{ maxLength: 12 }}
                            type="tel"
                            autoComplete="mobile"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            name="email"
                            label={t("employee.fd.compEmail")}
                            inputProps={{ maxLength: 50 }}
                            type="email"
                            autoComplete="email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="standard"
                            name="privateEmail"
                            label={t("employee.fd.privateEmail")}
                            inputProps={{ maxLength: 50 }}
                            type="email"
                            autoComplete="email"
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <TextItem
                        name="nationality"
                        label={t("employee.fd.nationality")}
                        render={(value) => {
                            if (value && ["TW", "JP", "O"].indexOf(value) > -1) {
                                return t(`employee.nationality.${value}`);
                            }
                            return "";
                        }}
                    />
                    <TextItem name="personId" label={t("employee.fd.personId")} />
                    <TextDateItem name="birthday" label={t("employee.fd.birthday")} />
                    <TextLookupItem name="gender" label={t("employee.fd.gender")} lookup={genderData} />
                    <TextItem name="residentialAddress" label={t("employee.fd.regAddr")} />
                    <TextItem name="bankAccount" label={t("employee.fd.bankAccount")} />
                    <TextItem name="contactAddress" label={t("employee.fd.conAddr")} />
                    <TextItem name="tel" label={t("employee.fd.tel")} />
                    <TextItem name="mobile" label={t("employee.fd.mobile")} />
                    <TextItem name="email" label={t("c.fd.email")} />
                    <TextItem name="privateEmail" label={t("employee.fd.privateEmail")} />
                </>
            )}
        </ExpandableContent>
    );
}

function SecEmergency() {
    const { isEditMode } = useFormContext();
    const { t } = useTranslation();
    return (
        <ExpandableContent>
            {isEditMode ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="standard"
                            label={t("employee.fd.name")}
                            name="emergencyName"
                            inputProps={{ maxLength: 50 }}
                            autoComplete="name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            variant="standard"
                            label={t("employee.fd.emergencyRelation")}
                            name="emergencyRelation"
                            inputProps={{ maxLength: 20 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={4}>
                        <TextField
                            variant="standard"
                            label={t("employee.fd.tel")}
                            name="emergencyTel"
                            inputProps={{ maxLength: 12 }}
                            type="tel"
                            autoComplete="tel"
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <TextItem name="emergencyName" label={t("employee.fd.name")} />
                    <TextItem name="emergencyRelation" label={t("employee.fd.emergencyRelation")} />
                    <TextItem name="emergencyTel" label={t("employee.fd.tel")} />
                </>
            )}
        </ExpandableContent>
    );
}

function SecEdu() {
    const { isEditMode } = useFormContext();
    const { t } = useTranslation();
    return (
        <ExpandableContent>
            {isEditMode ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label={t("employee.fd.highestEdu")}
                            name="highestEdu"
                            inputProps={{ maxLength: 50 }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t("employee.fd.graduatedSchool")}
                            name="graduatedSchool"
                            inputProps={{ maxLength: 50 }}
                            autoComplete="school"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            variant="standard"
                            label={t("employee.fd.major")}
                            name="major"
                            inputProps={{ maxLength: 50 }}
                            autoComplete="major"
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <TextItem name="highestEdu" label={t("employee.fd.highestEdu")} />
                    <TextItem name="graduatedSchool" label={t("employee.fd.graduatedSchool")} />
                    <TextItem name="major" label={t("employee.fd.major")} />
                </>
            )}
        </ExpandableContent>
    );
}

function SecRemark() {
    const { isEditMode } = useFormContext();
    const { t } = useTranslation();
    return (
        <ExpandableContent>
            {isEditMode ? (
                <>
                    <TextField
                        variant="standard"
                        name="remark"
                        label={t("c.fd.rmk")}
                        multiline
                        minRows={4}
                        inputProps={{ maxLength: 256 }}
                    />
                </>
            ) : (
                <>
                    <TextItem name="remark" render={(value) => (value ? nl2br(value) : t("c.na"))} />
                </>
            )}
        </ExpandableContent>
    );
}

export default MainForm;
