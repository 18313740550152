import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import en from '../en.json';
import ja from "../i18n/app.ja.json";
import zh from "../i18n/app.zh.json";

const resources = {
    /*'en-US': {
      translation: en,
    },*/
    "ja-JP": {
        translation: ja,
    },
    ja: {
        translation: ja,
    },
    "zh-TW": {
        translation: zh,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: navigator.language,
    fallbackLng: navigator.language,
    interpolation: {
        escapeValue: false,
    },
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    },
});

export default i18n;
