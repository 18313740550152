/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { Box, Grid } from "@mui/material";
import PathItemHeader from "./PathItemHeader";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PathItemDetails from "./PathItemDetails";
import { useTranslation } from "react-i18next";
import moment, { MomentInput } from "moment";
import { green, orange } from "@mui/material/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { EmployeePickRow, useReduxTempData } from "hooks";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export enum AuditPathStatusEnum {
    Neutral,
    Accepted,
    Rejected,
    Pending,
    Withdrawn,
    StandBy,
    Cancelled,
}

export interface AuditPathItemProps {
    position: number;
    date?: MomentInput;
    supervisor?: string;
    signerId?: number;
    message?: string;
    status: AuditPathStatusEnum;
}

const formatDate = (date: MomentInput) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

interface AuditPathColor {
    text: string;
    icon: string;
}

const colorMap: { [key in AuditPathStatusEnum]: AuditPathColor } = {
    [AuditPathStatusEnum.Neutral]: { text: "#000000", icon: "#005DEB" },
    [AuditPathStatusEnum.Accepted]: { text: green[600], icon: green[400] },
    [AuditPathStatusEnum.Rejected]: { text: "error", icon: "#E31B0C" },
    [AuditPathStatusEnum.Pending]: { text: orange[600], icon: orange[400] },
    [AuditPathStatusEnum.Withdrawn]: { text: "#00000099", icon: "#0000001F" },
    [AuditPathStatusEnum.StandBy]: { text: "#00000099", icon: "#0000001F" },
    [AuditPathStatusEnum.Cancelled]: { text: "#00000099", icon: "#0000001F" },
};

const i18nKeyMap: Record<AuditPathStatusEnum, string> = {
    [AuditPathStatusEnum.Neutral]: "audit.detailStatus.neutral",
    [AuditPathStatusEnum.Accepted]: "audit.status.accepted",
    [AuditPathStatusEnum.Rejected]: "audit.status.accepted",
    [AuditPathStatusEnum.Pending]: "audit.detailStatus.pending",
    [AuditPathStatusEnum.Withdrawn]: "audit.detailStatus.withdrawn",
    [AuditPathStatusEnum.StandBy]: "audit.detailStatus.standBy",
    [AuditPathStatusEnum.Cancelled]: "audit.detailStatus.cancelled",
};

function AuditIcon(status: AuditPathStatusEnum) {
    const { icon: iconColor } = colorMap[status];

    switch (status) {
        case AuditPathStatusEnum.Neutral:
            return <AssignmentIcon sx={{ mx: "auto", color: iconColor }} />;
        case AuditPathStatusEnum.Accepted:
            return <CheckCircleOutlineIcon sx={{ mx: "auto", color: iconColor }} />;
        case AuditPathStatusEnum.Rejected:
            return <CloseIcon sx={{ mx: "auto", color: iconColor }} />;
        case AuditPathStatusEnum.Pending:
            return <SwapHorizIcon sx={{ mx: "auto", color: iconColor }} />;
        case AuditPathStatusEnum.Withdrawn:
            return <CloseIcon sx={{ mx: "auto", color: iconColor }} />;
        case AuditPathStatusEnum.StandBy:
            return <HourglassEmptyIcon sx={{ mx: "auto", color: iconColor }} />;
        case AuditPathStatusEnum.Cancelled:
            return <RemoveCircleOutlineIcon sx={{ mx: "auto", color: iconColor }} />;
    }
}

function AuditPathItem({ position, date, supervisor, signerId, message, status }: AuditPathItemProps) {
    const { t } = useTranslation();
    const { text: textColor, icon: iconColor } = colorMap[status];
    const employeeData = useReduxTempData<EmployeePickRow[]>("/api/employee/picker", t("e.emp"));
    const singerName = useMemo<string>(() => {
        if (employeeData && signerId) {
            const employee = employeeData.find((item) => item.id === signerId);
            if (employee) {
                const { name, mainEmployeeDepartment } = employee;
                return `${mainEmployeeDepartment?.workPosition.name || ""} ${name}`.trim();
            }
        }
        return supervisor || "";
    }, [signerId, employeeData]);

    const renderDetails = () => {
        if (status === AuditPathStatusEnum.Cancelled) {
            return <PathItemDetails color="#00000099">{t(i18nKeyMap[status])}</PathItemDetails>;
        }
        if (date) {
            return <PathItemDetails color="#00000099">{formatDate(date)}</PathItemDetails>;
        }

        return <PathItemDetails color={textColor}>{singerName}</PathItemDetails>;
    };
    return (
        <>
            {position > 0 && (
                <Grid item container alignItems="center" justifyContent="center">
                    <ChevronRightIcon sx={{ color: iconColor }} />
                </Grid>
            )}
            <Grid
                item
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={4}
                columnSpacing={1}
            >
                <PathItemHeader sx={{ color: textColor }}>
                    {date || status === AuditPathStatusEnum.Cancelled ? singerName : t(i18nKeyMap[status])}
                </PathItemHeader>
                <Box marginY="0.5rem">{AuditIcon(status)}</Box>
                {renderDetails()}
            </Grid>
        </>
    );
}

export default AuditPathItem;
