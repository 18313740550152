import React from "react";
import { useTranslation } from "react-i18next";
import AlertWithAuthLink from "./AlertWithAuthLink";

function EmployeeNotSetAlert() {
    const { t } = useTranslation();
    return (
        <AlertWithAuthLink
            severity="warning"
            authLinkProps={{
                permissionPage: "user",
                to: `/user`,
            }}
            linkLabel={t("c.goToSet")}
        >
            {t("attendance.msg.employeeNotSet")}
        </AlertWithAuthLink>
    );
}

export default EmployeeNotSetAlert;
