import axios from "axios";
import { getErrorDescByCode, ResponseHandler } from "common";
import { store, DEF } from "../Action";
import { AxiosRequestConfig } from "axios";

const clearUserData = { type: DEF.USER_UPDATE, value: {} };

const defaultAxiosOptions: AxiosRequestConfig = {
    method: "get",
    url: "/users",
    headers: {
        "Content-Type": "application/json",
    },
};

export const commonAxios = (options: AxiosRequestConfig = {}) => {
    const useOptions = {
        ...defaultAxiosOptions,
        ...options,
    };
    return new Promise((resolve, reject) => {
        const dispatch = store.dispatch;
        axios(useOptions)
            .then((result) => {
                console.debug(result);
                resolve(result.data);
            })
            .catch((err) => {
                console.debug(err);
                reject();
                dispatch(clearUserData); //導回登入頁面
            });
    });
};

export function getErrorByCode(data: ResponseHandler) {
    if ("code" in data) {
        return getErrorDescByCode(data.code as any);
    }
    return "";
}

export function addDataKey(data: Record<string, any>, key: string | string[]) {
    const addKey = (key: string) => {
        if (!(key in data)) {
            //如果data沒有該 key 補空字串放到data裡
            data[key] = "";
        }
    };
    if (Array.isArray(key)) {
        key.forEach((val) => {
            addKey(val);
        });
    } else {
        addKey(key);
    }
}
