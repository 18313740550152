import React, { useMemo } from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import AuditStatusEnum from "components/audit/AuditStatusEnum";
import AuditRowData from "components/audit/AuditRowData";

export interface AuditSearchTableProps extends Partial<CustomMaterialTableProps<AuditRowData>> {
    options?: Options<AuditRowData>;
    columns: Column<AuditRowData>[];
    statusFilter?: AuditStatusEnum;
    data?: AuditRowData[];
}

function AuditSearchTable({ options = {}, columns, statusFilter, data = [], ...props }: AuditSearchTableProps) {
    const mData = useMemo(() => {
        if (statusFilter === null || statusFilter === undefined) {
            return data;
        }
        return data.filter(({ status }) => status === statusFilter);
    }, [data, statusFilter]);

    return (
        <MaterialTable<AuditRowData>
            {...props}
            columns={columns}
            data={mData}
            options={{
                pageSizeOptions: [5, 10, 20],
                toolbar: false,
                ...options,
            }}
        />
    );
}

export default AuditSearchTable;
