const initValue: Record<string, boolean> = {};
export default function reducer(state = initValue, action: TempReloadFlagAction) {
    switch (action.type) {
        case "UPDATE_TEMP_RELOAD_FLAG":
            return {
                ...state,
                [action.apiPath]: action.flag,
            };
        default:
            return state;
    }
}

export type TempReloadFlagActionType = "UPDATE_TEMP_RELOAD_FLAG";

export interface TempReloadFlagAction {
    type: TempReloadFlagActionType;
    apiPath: string;
    flag: boolean;
}
