import React, { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import * as yup from "yup";
import { SchemaOf } from "yup";
import axios from "axios";
import { Box } from "@mui/system";
import {
    Alert,
    Container,
    Typography,
    Button,
    TextField,
    CssBaseline,
    Checkbox,
    FormControlLabel,
    Divider,
    Paper,
} from "@mui/material";
import { GoogleLoginButton } from "components";
import { checkLogin } from "helpers";
const targetUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_GOOGLE_AUTH : "/auth/google";
interface LoginFormValues {
    account: string;
    pass: string;
    taxIdNumber: string;
}

interface RememberFormValues {
    account: boolean;
    taxIdNumber: boolean;
}

function GlobalLoginPage() {
    const initAccount = Cookies.get("account");
    const initTaxIdNumber = Cookies.get("taxIdNumber");
    const [rememberValuesChecked, setRememberValuesChecked] = useState<RememberFormValues>({
        account: !!initAccount,
        taxIdNumber: !!initTaxIdNumber,
    });
    //const { account, taxIdNumber } = checked;
    const { errcode } = useParams<{ errcode: string }>();
    const { t } = useTranslation();
    let defaultAlertMessage = "";
    if (errcode) {
        if (errcode === "401") {
            defaultAlertMessage = t("login.err.denied");
        }
    }
    const [inputs, setInputs] = useState<LoginFormValues>({
        account: initAccount || "",
        pass: "",
        taxIdNumber: initTaxIdNumber || "",
    });
    const [alertMsg, setAlertMsg] = useState(defaultAlertMessage);

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
    };

    const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
        setRememberValuesChecked({ ...rememberValuesChecked, [e.target.name]: e.target.checked });
        if (e.target.checked) {
            Cookies.set(e.target.name, inputs[e.target.name as keyof LoginFormValues]);
        } else {
            Cookies.remove(e.target.name);
        }
    };
    const commonShape = {
        taxIdNumber: yup
            .string()
            .label(t("corporate.fd.taxIdNumber"))
            .matches(/^\d{8}$/, t("login.err.taxIdInvalid"))
            .required(),
    };

    const normalLoginSchema: SchemaOf<LoginFormValues> = yup
        .object()
        .shape({
            ...commonShape,
            account: yup.string().label(t("login.fd.user")).required(),
            pass: yup.string().label(t("login.fd.pass")).required(),
        })
        .defined();
    const thirdPartyLoginSchema: SchemaOf<{ taxIdNumber: string }> = yup
        .object()
        .shape({
            ...commonShape,
        })
        .defined();

    const doLogin = () => {
        normalLoginSchema
            .validate(inputs)
            .then(() => {
                axios
                    .post("/auth/login", inputs)
                    .then(function (response) {
                        //console.debug(response);
                        const data = response.data;
                        if ("status" in data) {
                            if (data.status === "ok") {
                                checkLogin();
                            } else {
                                if ("code" in data) {
                                    switch (data.code) {
                                        case 101:
                                            setAlertMsg(t("login.err.empty"));
                                            break;
                                        case 102:
                                            setAlertMsg(t("login.err.wrong"));
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        if (err.response) {
                            if (err.response.status === 401) {
                                setAlertMsg(t("login.err.denied"));
                            }
                        }
                        console.log(err);
                    });
            })
            .catch((err) => {
                setAlertMsg(err.errors);
            });
    };

    const doGoogleLogin = () => {
        thirdPartyLoginSchema
            .validate({ taxIdNumber: inputs.taxIdNumber })
            .then(() => {
                window.location.href = `${targetUrl}?taxId=${inputs.taxIdNumber}`;
            })
            .catch((err) => {
                setAlertMsg(err.errors);
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Paper elevation={0} sx={{ marginY: (theme) => theme.spacing(8), padding: 5 }}>
                <Box
                    component="div"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="img"
                        alt="Mirai Logo"
                        src="/images/logo/footer-logo-mirai.png"
                        sx={{ width: (theme) => theme.spacing(15), marginBottom: (theme) => theme.spacing(3) }}
                    />
                    {alertMsg && (
                        <>
                            <Alert severity="error">{alertMsg}</Alert>
                            <br />
                        </>
                    )}
                    <Typography component="h1" variant="h5">
                        Mirai W/F
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            width: "100%", // Fix IE 11 issue.
                            marginTop: (theme) => theme.spacing(1),
                        }}
                        noValidate
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="taxIdNumber"
                            label={t("corporate.fd.taxIdNumber")}
                            name="taxIdNumber"
                            autoComplete="taxIdNumber"
                            autoFocus
                            onChange={handleInput}
                            defaultValue={inputs.taxIdNumber}
                            inputProps={{
                                maxLength: 8,
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="account"
                            label={t("login.fd.user")}
                            name="account"
                            autoComplete="account"
                            autoFocus
                            onChange={handleInput}
                            defaultValue={inputs.account}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="pass"
                            label={t("login.fd.pass")}
                            type="password"
                            id="pass"
                            autoComplete="current-password"
                            onChange={handleInput}
                        />
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberValuesChecked.taxIdNumber}
                                        onChange={handleCheck}
                                        name="taxIdNumber"
                                    />
                                }
                                label={t("login.fd.rTaxIdNumber").toString()}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberValuesChecked.account}
                                        onChange={handleCheck}
                                        name="account"
                                    />
                                }
                                label={t("login.fd.rAccount").toString()}
                            />
                        </Box>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{
                                margin: (theme) => theme.spacing(3, 0, 2),
                            }}
                            onClick={doLogin}
                        >
                            {t("login.signin")}
                        </Button>
                        <Divider>或</Divider>
                        <GoogleLoginButton onClick={doGoogleLogin} />
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}
export default GlobalLoginPage;
