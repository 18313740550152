import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Box
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
}
TabPanel.propTypes = {
    children: PropTypes.any,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    index: PropTypes.string,
};
