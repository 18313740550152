const basePath = "formRequest";
// Audit tabs' names which can be used for external routing
const AuditTabs = {
    main: "",
    pending: "pending",
    rejected: "rejected",
    accepted: "accepted",
    signOff: "signOff",

    getPath: function (path: string) {
        if (path === "pending") {
            return "audit/pending";
        }
        return `${basePath}/${path}`;
    },
};

export default AuditTabs;
