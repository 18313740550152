import React, { useCallback, useEffect, MouseEvent, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    DialogProps,
    Skeleton,
} from "@mui/material";
import { useFetchData2 as useFetchData } from "hooks";
import { NewsRowData } from "./NewsSearchTable";

export interface AnnounceData extends NewsRowData {
    content: string;
}

export interface AnnounceDialog extends DialogProps {
    dataId: string | null;
}

function AnnounceDialog({ open, onClose, dataId, ...props }: AnnounceDialog) {
    const { t } = useTranslation();
    const [announceData, announceFetch] = useFetchData(
        `/api/dashboard/announce/${dataId || 0}`,
        t("e.announce"),
        undefined,
        {
            id: 0,
            title: "",
            content: "",
            createdAt: "",
            createdUser: 0,
        }
    );
    const [dataLoaded, setDataLoaded] = useState(false);
    const fetchData = useCallback(async () => {
        await announceFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataId]);
    const formatTime = (time: moment.MomentInput) => moment(time).format("LLL");
    const handleClose = (event?: MouseEvent) => {
        setDataLoaded(false);
        onClose && onClose(event || {}, "backdropClick");
    };
    useEffect(() => {
        if (dataId) {
            fetchData().then(() => {
                setDataLoaded(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataId]);

    //const getCont = (text, props = {}) => (isLoaded ? text : <Skeleton animation="wave" {...props} />);
    return (
        <Dialog
            {...props}
            open={open}
            onClose={(event, reason) => {
                setDataLoaded(false);
                onClose && onClose(event, reason);
            }}
            fullWidth
            aria-labelledby="announce-dialog-title"
        >
            <DialogTitle id="depart-dialog-title">{t("e.announce")}</DialogTitle>
            <DialogContent>
                {dataLoaded && announceData ? (
                    <>
                        <Typography variant="h3">{announceData.title}</Typography>
                        <Box p={1}></Box>
                        <Typography variant="subtitle1">{formatTime(announceData.createdAt)}</Typography>
                        <Box display="flex" flexWrap="nowrap" alignItems="flex-end" pb={2}>
                            <Typography variant="h6"></Typography>
                        </Box>
                        <Typography variant="body1">
                            <div className="ql-snow">
                                <div
                                    className="ql-editor"
                                    style={{ padding: 0 }}
                                    dangerouslySetInnerHTML={{ __html: announceData.content }}
                                ></div>
                            </div>
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="h3">
                            <Skeleton animation="wave" />
                        </Typography>
                        <Box p={1} />
                        <Typography variant="subtitle1">
                            <Skeleton animation="wave" height="1rem" width={200} />
                        </Typography>
                        <Box display="flex" flexWrap="nowrap" alignItems="flex-end" pb={2}>
                            <Typography variant="h6"></Typography>
                        </Box>
                        <Typography variant="body1">
                            <Skeleton animation="wave" height="1rem" style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height="1rem" width="80%" />
                        </Typography>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t("c.close")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AnnounceDialog;
