import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider } from "@mui/material";
import BasicApprovalPreview from "./BasicApprovalPreview";

export interface BasicApprovalPreviewDialogProps extends DialogProps {
    dataId: null | number;
}
function BasicApprovalPreviewDialog({ dataId, onClose, ...props }: BasicApprovalPreviewDialogProps) {
    const { t } = useTranslation();
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    return (
        <Dialog aria-labelledby="basic-approval-preview-dialog-title" maxWidth="sm" fullWidth {...props}>
            <DialogTitle id="basic-approval-preview-dialog-title">{t("approval.preview")}</DialogTitle>
            <Divider />
            <DialogContent>
                <BasicApprovalPreview dataId={dataId} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default BasicApprovalPreviewDialog;
