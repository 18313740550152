import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { EditComponentProps } from "@material-table/core";
//import { makeStyles } from "@mui/styles";
import { useReduxTempData } from "hooks";
import { AccountTitleRow } from "pages/accountSetting";
import { useCMTContext } from "./../CustomMaterialTable";
import { useTranslation } from "react-i18next";

export interface AccountTitleEditFieldProps<RowData extends object> extends EditComponentProps<RowData> {
    forceReload?: boolean;
    filter?: (value: AccountTitleRow, index: number, array: AccountTitleRow[]) => boolean;
}

function AccountTitleEditField<RowData extends object>({
    columnDef,
    filter,
    forceReload = false,
    rowData,
    onRowDataChange,
    value,
}: AccountTitleEditFieldProps<RowData>) {
    const { t } = useTranslation();
    const { errors } = useCMTContext();
    const mErrors = errors || {};
    const fieldName = columnDef.field.toString();
    const accountTitles = useReduxTempData<AccountTitleRow[]>(
        `/api/accountTitle/data`,
        t("e.accountTitle"),
        forceReload
    );
    const [mAccountTitles, setMAccountTitles] = useState(accountTitles);
    const changeValue = (value: number | null) => {
        onRowDataChange &&
            onRowDataChange({
                ...rowData,
                [fieldName]: value,
            });
    };
    useEffect(() => {
        if (accountTitles) {
            if (filter) {
                const filteredAccountTitles = accountTitles.filter(filter);
                setMAccountTitles([...filteredAccountTitles]);
                if (value) {
                    const check = filteredAccountTitles.find((a) => a.id === value);
                    if (!check) {
                        changeValue(null);
                    }
                }
            } else {
                setMAccountTitles([...accountTitles]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, accountTitles]);
    let mValue = null;
    if (mAccountTitles && value) {
        mValue = mAccountTitles && mAccountTitles.find((a) => a.id === value);
    }
    return (
        <Autocomplete<AccountTitleRow>
            value={mValue}
            onChange={(e, value) => {
                if (value) {
                    changeValue(value.id);
                } else {
                    changeValue(null);
                }
            }}
            options={mAccountTitles || []}
            autoHighlight
            getOptionLabel={(option) => {
                if (!option) {
                    return "";
                }
                return `${option.code}-${option.name}`.trim();
            }}
            isOptionEqualToValue={(option, value) => {
                if (option && value) {
                    return option.id === value.id;
                } else {
                    return false;
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "disabled", // disable autocomplete and autofill
                    }}
                    error={fieldName in mErrors}
                    helperText={fieldName in mErrors && mErrors[fieldName as keyof typeof mErrors]}
                />
            )}
        />
    );
}

export default AccountTitleEditField;
