import React, { useEffect, useMemo, useRef, useState } from "react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { Action } from "@material-table/core";
import { Box, Stack } from "@mui/material";
import { PageHeader2 as PageHeader } from "layout";
import { useFetchData2 as useFetchData, useReduxTempData } from "hooks";
import AuditDialog from "components/audit/AuditDialog";
import AuditStatusEnum from "components/audit/AuditStatusEnum";
import DateRangeFilter from "components/grid/DateRangeFilter";
import GridTabs from "components/grid/GridTabs";
import FormRow from "components/audit/FormRow";
import AuditRowData from "components/audit/AuditRowData";
import useAuditAction, { AuditSubmission } from "components/audit/useAuditAction";
import AuditSubmissionStatusEnum from "components/audit/AuditSubmissionStatusEnum";
import MessageFormDialog from "./MessageFormDialog";
import AuditSearchTable from "./AuditSearchTable";
import ColumnsFactory from "./ColumnsFactory";
import { TabPageProps } from "layout/types";
import AuditTabs from "./AuditTabs";
import { EmployeeNotSetAlert } from "components";
import { getUser } from "helpers";

interface AuditRowResult {
    unsignedData: AuditRowData[];
    signedData: AuditRowData[];
}

export type RowForAudit = Omit<AuditSubmission, "message"> & Pick<AuditRowData, "formId" | "no">;

function AuditList({ match }: TabPageProps) {
    const user = useMemo(() => getUser(), []);
    const tableRef = useRef<any>(null);
    const { t } = useTranslation();
    const [rowForAudit, setRowForAudit] = useState<RowForAudit | null>(null);
    const [activeRowId, setActiveRowId] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<Moment>(moment().startOf("month"));
    const [endDate, setEndDate] = useState<Moment>(moment().endOf("month"));
    const [dataListResult, dataListFetch, dataListCleanup] = useFetchData<AuditRowResult>(
        `/api/form/audit?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`,
        t("e.auditData")
    );
    const formRowData = useReduxTempData<FormRow[]>(`api/form`, t("e.form"));

    const formLookupMap = useMemo<Record<string, any>>(() => {
        const map: Record<string, any> = {};
        if (formRowData && Array.isArray(formRowData)) {
            formRowData.forEach((row) => {
                map[row.id.toString()] = t(`form.title.${row.name}`);
            });
        }
        return map;
    }, [formRowData]);

    useEffect(() => {
        if (user.employee) {
            dataListFetch();
        }
        return () => {
            dataListCleanup();
        };
    }, [startDate, endDate, user]);

    const unsignedList = useMemo<AuditRowData[]>(() => {
        return dataListResult?.unsignedData || [];
    }, [dataListResult]);

    const signedList = useMemo<AuditRowData[]>(() => {
        return dataListResult?.signedData || [];
    }, [dataListResult]);

    // eslint-disable-next-line no-unused-vars
    const [signingOffCount, acceptCount, withdrawnCount, rejectCount] = useMemo<number[]>(() => {
        return signedList.reduce(
            (previousValue, currentValue) => {
                if (currentValue.status >= 0 && currentValue.status < 4) {
                    previousValue[currentValue.status]++;
                }
                return previousValue;
            },
            [0, 0, 0, 0]
        );
    }, [signedList]);

    const onAccept = (rowData: AuditRowData) => {
        const { id, no, formId } = rowData;
        setRowForAudit({
            id: Number(id),
            no,
            formId,
            status: AuditSubmissionStatusEnum.Accepted,
        });
    };
    const onReject = (rowData: AuditRowData) => {
        const { id, no, formId } = rowData;
        setRowForAudit({
            id: Number(id),
            no,
            formId,
            status: AuditSubmissionStatusEnum.Rejected,
        });
    };

    const { submitAudit } = useAuditAction();

    const handleAudit = (data: AuditSubmission) => {
        submitAudit(data)
            .then(() => {
                handleFormClose();
                dataListFetch(); //refresh table
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const actions: Action<AuditRowData>[] = [
        {
            icon: "check",
            iconProps: {
                color: "success",
            },
            tooltip: t("audit.accept"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    onAccept(rowData);
                }
            },
        },
        {
            icon: "close",
            iconProps: {
                color: "error",
            },
            tooltip: t("audit.reject"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    onReject(rowData);
                }
            },
        },
    ];

    const onRowClick = (rowId: string) => {
        setActiveRowId(rowId);
    };

    const auditSearchTable = (
        <AuditSearchTable
            tableRef={tableRef}
            actions={actions}
            columns={ColumnsFactory(
                {
                    type: "main",
                    formAction: onRowClick,
                },
                t,
                { formLookupMap }
            )}
            data={unsignedList}
            statusFilter={AuditStatusEnum.Pending}
        />
    );

    const signedSearchTable = (statusFilter?: AuditStatusEnum) => {
        return (
            <AuditSearchTable
                tableRef={tableRef}
                columns={ColumnsFactory({ type: "filtered", formAction: onRowClick }, t, { formLookupMap })}
                data={signedList}
                statusFilter={statusFilter}
            />
        );
    };

    const allDataSearchTable = (
        <AuditSearchTable
            tableRef={tableRef}
            columns={ColumnsFactory({ type: "filtered", formAction: onRowClick }, t, { formLookupMap })}
            data={[...unsignedList, ...signedList]}
        />
    );
    const handleFormClose = () => {
        setActiveRowId(null);
    };

    const handleMessageFormClose = () => {
        setRowForAudit(null);
    };

    return (
        <>
            {!user.employee && (
                <Box mt={2}>
                    <EmployeeNotSetAlert />
                </Box>
            )}
            <PageHeader
                title=""
                leftToolView={
                    <Stack spacing={1} direction="row">
                        <DateRangeFilter
                            initStartDate={startDate.toDate()}
                            initEndDate={endDate.toDate()}
                            onApply={(startDate, endDate) => {
                                setStartDate(moment(startDate));
                                setEndDate(moment(endDate));
                            }}
                            disabled={!user.employee}
                        />
                    </Stack>
                }
            />
            <GridTabs
                match={match}
                tabs={[
                    {
                        index: 0,
                        label: t("audit.tab.pending"),
                        counter: unsignedList.length,
                        children: auditSearchTable,
                        url: AuditTabs.pending,
                    },
                    {
                        index: 1,
                        label: `${t("audit.tab.rejected")} (${rejectCount})`,
                        //counter: rejectCount,
                        children: signedSearchTable(AuditStatusEnum.Rejected),
                        url: AuditTabs.rejected,
                    },
                    {
                        index: 2,
                        label: `${t("audit.tab.accepted")}(${acceptCount})`,
                        //counter: acceptCount,
                        children: signedSearchTable(AuditStatusEnum.Accepted),
                        url: AuditTabs.accepted,
                    },
                    {
                        index: 3,
                        label: `${t("audit.tab.signOff")}(${signingOffCount})`,
                        //counter: acceptCount,
                        children: signedSearchTable(AuditStatusEnum.Pending),
                        url: AuditTabs.signOff,
                    },
                    { index: 4, label: t("audit.tab.all"), children: allDataSearchTable, url: "" },
                ]}
            />
            <AuditDialog
                onClose={handleFormClose}
                open={!!activeRowId}
                onAudit={handleAudit}
                rowId={activeRowId}
                readonly={false}
            />
            <MessageFormDialog
                open={!!rowForAudit}
                onClose={handleMessageFormClose}
                data={rowForAudit}
                //statusType={rowForAudit?.status || null}
                //rowId={rowForAudit?.id || null}
                onAudit={handleAudit}
            />
        </>
    );
}

export default AuditList;
