/**
 * Edit Component Grid Wapper
 * For mobile used
 */
import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";

export default function ECGridWrapper({ col, titleBold = true, children }) {
    return (
        <Grid item xs={12} sm={(col.long && 12) || 6}>
            <Grid container spacing={2}>
                <Grid item xs={4} sm={(col.long && 2) || 4}>
                    <Typography style={{ fontWeight: titleBold ? 900 : 500, textAlign: "right" }}>
                        {col.title}
                    </Typography>
                </Grid>
                <Grid item xs={8} sm={(col.long && 10) || 8}>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
}

ECGridWrapper.propTypes = {
    col: PropTypes.shape({
        long: PropTypes.bool,
        title: PropTypes.string,
    }),
    titleBold: PropTypes.bool,
    children: PropTypes.node,
};
