import DEF from "../def";

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.LEFT_MENU_OPEN:
            return true;
        case DEF.LEFT_MENU_CLOSE:
            return false;
        default:
            return state;
    }
}
