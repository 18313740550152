import React, { useRef, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from "@mui/material";
import { FormBaseRefProps } from "components/final-form/FormBase";
import { FormData } from "hooks";
import { LeaveTypeFormValue } from "../basic";
import BaseDataFormBase from "./BaseDataFormBase";

export interface BaseDataFormDialogProps extends DialogProps {
    formData: FormData;
}

function BaseDataFormDialog({ formData, onClose, ...props }: BaseDataFormDialogProps) {
    const formRef = useRef<FormBaseRefProps<LeaveTypeFormValue>>(null);
    const { t } = useTranslation();
    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const handleSubmit = async (values: LeaveTypeFormValue) => {
        //Check if data has been changed or not
        const mValue = { ...values };
        await formData
            .save(mValue)
            .then(async (resp) => {
                await formData.fetch();
                handleClose();
            })
            .catch(() => {});
    };

    return (
        <Dialog {...props} aria-labelledby="department-dialog-title">
            <DialogTitle id="department-dialog-title">{t("c.fd.secBase")}</DialogTitle>
            <DialogContent>
                <BaseDataFormBase onSubmit={handleSubmit} initialValues={formData.data} formRef={formRef} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default BaseDataFormDialog;
