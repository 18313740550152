import React, { useState, useEffect, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogActions, DialogProps, DialogContent } from "@mui/material";
import { useFetchData2 as useFetchData, useDataCUD } from "hooks";
import { addToast } from "helpers";
import { SortableBase } from "components";

interface WorkPositionSortableRow {
    oldOrder?: number;
    order: number;
    id: number;
    name: string;
}

export interface WorkPositionSortableDialogProps extends DialogProps {
    onChange?: () => void;
}

function WorkPositionSortableDialog({ open, onClose, onChange }: WorkPositionSortableDialogProps) {
    const [loading, setLoading] = useState(false);
    const { handleEdit } = useDataCUD();
    const { t } = useTranslation();
    const [dataList, setDataList] = useState<WorkPositionSortableRow[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [initDataList, dataListFetch] = useFetchData<WorkPositionSortableRow[]>(
        "/api/workPosition/order",
        "",
        (mData) => {
            mData.forEach((row) => {
                row.oldOrder = row.order;
            });
        },
        []
    );

    useEffect(() => {
        if (open) {
            setLoading(true);
            dataListFetch().finally(() => {
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        console.log(initDataList);
        initDataList && setDataList([...initDataList]);
    }, [initDataList]);

    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };

    const handleSave = (event?: MouseEvent) => {
        const dirtyData = dataList
            .filter((row) => row.order !== row.oldOrder)
            .map((row) => ({
                id: row.id,
                order: row.order,
            }));

        if (dirtyData.length > 0) {
            handleEdit({ list: dirtyData }, "/api/workPosition/changeOrder")
                .then(() => {
                    onChange && onChange();
                    //tableRef.current.onQueryChange();
                    handleClose(event);
                })
                .catch(() => {});
        } else {
            addToast(t("c.msg.orderUnchanged"), { appearance: "warning" });
            handleClose(event);
        }
    };
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle id="emp-picker-dialog-title">{t("workPosition.order")}</DialogTitle>
            <DialogContent>
                <SortableBase<WorkPositionSortableRow>
                    dataList={dataList}
                    onChange={setDataList}
                    loading={loading}
                    orderKey="order"
                    oldOrderKey="oldOrder"
                    primaryText={(row) => `${row.name}`}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleSave} disabled={loading}>
                    {t("c.save")}
                </Button>
                <Button autoFocus onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default WorkPositionSortableDialog;
