import React, { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";

export interface DlItemProps {
    label: ReactNode;
    value: ReactNode;
}

function DlItem({ label, value }: DlItemProps) {
    return (
        <Grid item xs={12}>
            <Typography component="dt" variant="body1" style={{ fontWeight: "bold" }}>
                {label}
            </Typography>
            <Typography component="dd" variant="body1">
                {value}
            </Typography>
        </Grid>
    );
}

export default DlItem;
