import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowCircleUp } from "react-icons/fa";
import { Column } from "@material-table/core";
import FlagIcon from "@mui/icons-material/Flag";
import { Box, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import NewsSearchTable, { NewsRowData } from "./NewsSearchTable";
import { DateTimeRendererShort } from "../grid/DateRenderer";

function NewsSearchList() {
    const { t } = useTranslation();
    const tableRef = useRef<any>(null);
    const newsColumns: Column<NewsRowData>[] = [
        {
            title: t("c.fd.title"),
            field: "title",
            type: "string",
            render: (rowData) => (
                <>
                    {rowData.fixedTop ? <FaArrowCircleUp color={red[500]} /> : ""} {rowData.title}
                </>
            ),
        },
        {
            title: t("c.fd.createdAt"),
            field: "createdAt",
            type: "date",
            width: "200px",
            cellStyle: { minWidth: 200 },
            render: ({ createdAt }) => DateTimeRendererShort(createdAt),
        },
    ];

    const tableTitle = (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <FlagIcon />
            <Typography variant="h6" fontSize="18px" lineHeight="3" fontWeight="500">
                {t("e.announce")}
            </Typography>
        </Box>
    );

    return (
        <>
            <NewsSearchTable tableRef={tableRef} columns={newsColumns} title={tableTitle} />
        </>
    );
}

export default NewsSearchList;
