import { Icon, IconProps, SvgIcon } from "@mui/material";
import React from "react";

type SvgIconComponent = typeof SvgIcon;

export interface ActionIconProps {
    icon: string | ((props?: any) => React.ReactElement<any>) | SvgIconComponent;
    iconProps?: IconProps;
    disabled: boolean;
}

function ActionIcon({ icon, iconProps, disabled }: ActionIconProps) {
    if (typeof icon === "string") {
        return <Icon {...iconProps}>{icon}</Icon>;
    } else if (typeof icon === "function") {
        return <>{icon({ ...iconProps, disabled: disabled })}</>;
    } else {
        const IconTag = icon as SvgIconComponent;
        return <IconTag />;
    }
}

export default ActionIcon;
