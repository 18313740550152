import React, { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select, makeValidate, Checkboxes } from "mui-rff";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid, MenuItem, Box } from "@mui/material";
import FormBase, { FormBaseProps } from "components/final-form/FormBase";
import { useFetchData2 as useFetchData } from "hooks";
import { addToast } from "helpers";
import { EnabledStatusChip } from "components";
import { DepartmentRowData } from "pages/department/DepartmentSearchTable";
import { WorkPositionRowData } from "pages/workposition/WorkPositionSearchTable";
import { PositionRowData } from "./PositionForm";
import { StdQueryListResult } from "common";

export interface PositionFormValues {
    departmentId?: number;
    workPositionId?: number;
    flag: boolean;
}

interface FormContextValue {
    departmentData?: DepartmentRowData[];
    workPositonData?: WorkPositionRowData[];
    listData: PositionRowData[];
}

const FormContext = createContext<FormContextValue>({
    departmentData: [],
    workPositonData: [],
    listData: [],
});
const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

function PositionFormBase({ onSubmit, ...props }: FormBaseProps<PositionFormValues>) {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const schema: SchemaOf<PositionFormValues> = yup
        .object()
        .shape({
            departmentId: yup.number().label(t("e.depart")).required(),
            workPositionId: yup.number().label(t("e.workPosition")).required(),
        })
        .defined();
    const validate = makeValidate(schema);

    const [departmentData, departmentDataFetch, departmentDataCleanup] = useFetchData<
        StdQueryListResult<DepartmentRowData>
    >("/api/department/list?limit=100", t("e.depart"), undefined, { count: 0, data: [] }); //限制100筆部門名稱

    const [workPositonData, workPositonDataFetch, workPositonDataCleanup] = useFetchData<
        StdQueryListResult<WorkPositionRowData>
    >("/api/workPosition?limit=100", t("workPosition.fd.name"), undefined, { count: 0, data: [] }); //限制100筆職位名稱
    const [listData, listDataFetch, listDataCleanup] = useFetchData<StdQueryListResult<PositionRowData>>(
        "/api/employee/department/" + id,
        "列表資料",
        undefined,
        null
    );
    const mDepartmentData: DepartmentRowData[] =
        departmentData?.data && Array.isArray(departmentData?.data) ? departmentData?.data : [];

    const mWorkPositonData: WorkPositionRowData[] =
        workPositonData?.data && Array.isArray(workPositonData?.data) ? workPositonData?.data : [];

    const mListData: PositionRowData[] = listData?.data && Array.isArray(listData?.data) ? listData?.data : [];

    useEffect(() => {
        departmentDataFetch().catch((err) => {
            console.log(err);
            if ("code" in err && err.code === "202") {
                addToast(t("department.err.mag"), { appearance: "error" });
            }
        });
        return () => {
            departmentDataCleanup();
        };
    }, [0]);
    useEffect(() => {
        workPositonDataFetch().catch((err) => {
            console.log(err);
            if ("code" in err && err.code === "202") {
                addToast(t("department.err.mag"), { appearance: "error" });
            }
        });
        return () => {
            workPositonDataCleanup();
        };
    }, [0]);
    useEffect(() => {
        listDataFetch().catch((err) => {
            console.log(err);
            if ("code" in err && err.code === "202") {
                addToast(t("department.err.mag"), { appearance: "error" });
            }
        });
        return () => {
            listDataCleanup();
        };
    }, [0]);
    return (
        <FormBase<PositionFormValues>
            validate={validate}
            initialValues={{ flag: mListData.length === 0 }}
            onSubmit={onSubmit}
            {...props}
        >
            <FormContext.Provider
                value={{
                    departmentData: mDepartmentData,
                    workPositonData: mWorkPositonData,
                    listData: mListData,
                }}
            >
                <FormContent />
            </FormContext.Provider>
        </FormBase>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const { departmentData, workPositonData, listData } = useFormContext();
    const selectedDepartIds = listData.map((row) => row.departmentId);
    const selectableDeparts = departmentData?.filter((row) => selectedDepartIds.indexOf(Number(row.id)) === -1);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Select name="departmentId" label={t("e.depart")}>
                    {departmentData &&
                        selectableDeparts?.map((item, index) => (
                            <MenuItem key={index} value={item.id || 0}>
                                <Box display="flex" alignItems="center">
                                    {item.name}
                                    {item.id && (
                                        <Box ml={1}>
                                            <EnabledStatusChip value={item.status} />
                                        </Box>
                                    )}
                                </Box>
                            </MenuItem>
                        ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Select name="workPositionId" label={t("workPosition.fd.name")}>
                    {workPositonData &&
                        workPositonData.map((item: any, index: any) => (
                            <MenuItem key={index} value={item.id || 0}>
                                {item.name}
                            </MenuItem>
                        ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Checkboxes
                    formGroupProps={{ row: true }}
                    label={""}
                    name="flag"
                    data={{ label: t("employee.fd.setMainPosition"), value: listData.length === 0 }}
                    disabled={listData.length === 0}
                />
            </Grid>
        </Grid>
    );
}

export default PositionFormBase;
