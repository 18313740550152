import React, { useRef, useState } from "react";
import InitialLeaveColumnsFactory from "./InitialLeaveColumnsFactory";
import InitialLeaveSearchTable from "./InitialLeaveSearchTable";
import { useTranslation } from "react-i18next";
import { PageHeader2 as PageHeader } from "../../../layout";
import { Stack } from "@mui/material";
import ImportButton from "components/buttons/ImportButton";
import LockButtonPrimary from "components/buttons/LockButtonPrimary";
import DownloadButton from "./DownloadButton";
import ImportPreviewDialog from "./ImportPreviewDialog";

function InitialLeavePage() {
    const { t } = useTranslation();
    const tableRef = useRef<any>(null);
    const [importPreviewOpen, setImportPreviewOpen] = useState<boolean>(false);

    const handleImport = () => {
        setImportPreviewOpen(true);
    };

    const handleLock = () => {
        alert("Handle lock");
    };

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    <Stack spacing={1} direction="row">
                        <DownloadButton />
                        <ImportButton onClick={handleImport}>{t("匯入初始值")}</ImportButton>
                    </Stack>
                }
                rightToolView={
                    <Stack spacing={1} direction="row">
                        <LockButtonPrimary onClick={handleLock}>{t("鎖定匯入資料")}</LockButtonPrimary>
                    </Stack>
                }
            />
            <InitialLeaveSearchTable
                tableRef={tableRef}
                columns={InitialLeaveColumnsFactory(
                    {
                        type: "main",
                    },
                    t
                )}
            />
            <ImportPreviewDialog open={importPreviewOpen} onClose={() => setImportPreviewOpen(false)} />
        </>
    );
}

export default InitialLeavePage;
