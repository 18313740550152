const ja = {
    body: {
        emptyDataSourceMessage: "データがありません",
        addTooltip: "新規",
        deleteTooltip: "削除",
        editTooltip: "変更",
        filterRow: {
            filterTooltip: "フィルター",
        },
        editRow: {
            deleteText: "削除してよろしいですか?",
            cancelTooltip: "キャンセル",
            saveTooltip: "保存",
        },
    },
    grouping: {
        placeholder: "ここにカラムタイトルをドロップしてください。",
        groupedBy: "グループ化項目：",
    },
    header: {
        actions: "操作",
        selectAll: "全て選択",
    },
    pagination: {
        labelDisplayedRows: "{from}～{to}件，全{count}件",
        labelRowsSelect: "件を表示する",
        labelRowsPerPage: "ページあたりの表示件数",
        firstAriaLabel: "最初へ",
        firstTooltip: "最初へ",
        previousAriaLabel: "前へ",
        previousTooltip: "前へ",
        nextAriaLabel: "次へ",
        nextTooltip: "次へ",
        lastAriaLabel: "最後へ",
        lastTooltip: "最後へ",
    },
    toolbar: {
        addRemoveColumns: "カラム表示/非表示",
        nRowsSelected: "{0}件を選択しました。",
        showColumnsTitle: "カラム表示",
        showColumnsAriaLabel: "カラム表示",
        exportTitle: "エクスポート",
        exportAriaLabel: "エクスポート",
        exportName: "CSVエクスポート",
        searchTooltip: "検索",
        searchPlaceholder: "検索",
    },
};

export default ja;
