import React from "react";
import { Button, styled } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";

const StyledButton = styled(
    Button,
    {}
)<ButtonProps>(({ theme }) => ({
    padding: "10px, 16px, 10px, 14px",
    fontWeight: 500,
    "&.Mui-disabled": {
        color: "#fff",
        background: "#89B8FF",
    },
}));

function ButtonContained({ children, ...props }: ButtonProps) {
    return (
        <StyledButton variant="contained" {...props}>
            {children}
        </StyledButton>
    );
}

export default ButtonContained;
