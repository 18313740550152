import React, { useRef, useEffect, useCallback, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from "@mui/material";
import { SimpleLoading } from "components";
import { FormBaseRefProps } from "components/final-form/FormBase";
import { useFormData, useReduxTempDataReloadFlag, formModeDef, employeeDataFlagName } from "hooks";
import { addDataKey } from "helpers/formHelper";
import DepartmentFormBase, { DepartmentFormValues, defaultInitialValues } from "./DepartmentFormBase";

export interface DepartmentFormDialogProps extends DialogProps {
    dataId: string | null;
    onChange?: () => void;
}

function DepartmentFormDialog({ dataId, onChange, onClose, ...props }: DepartmentFormDialogProps) {
    const formRef = useRef<FormBaseRefProps<DepartmentFormValues>>(null);
    const { t } = useTranslation();
    const [formDataLoading, setFormDataLoading] = useState(false);
    const { addFlag } = useReduxTempDataReloadFlag([`/api/employee/picker`, employeeDataFlagName]);
    const formData = useFormData<DepartmentFormValues>("/api/department", defaultInitialValues, dataId, (data) => {
        if (data) {
            data.parentDepartmentId = data.parentDepartmentId ? data.parentDepartmentId : 0;
        }
    });

    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const handleSubmit = async (values: DepartmentFormValues) => {
        //Check if data has been changed or not
        const mValue = { ...values };
        if (mValue.parentDepartmentId === 0) {
            mValue.parentDepartmentId = null;
        }
        addDataKey(mValue, "remark");
        await formData
            .save(mValue)
            .then(async (resp) => {
                onChange && onChange();
                fetchData();
                addFlag();
                handleClose();
            })
            .catch(() => {});
    };

    const fetchData = useCallback(async () => {
        setFormDataLoading(true);
        await formData
            .fetch()
            .catch((error) => {
                console.log(error);
                handleClose();
            })
            .finally(() => {
                setFormDataLoading(false);
            });
    }, [dataId]);

    useEffect(() => {
        fetchData();
        return () => {
            formData.cleanup();
        };
    }, [fetchData, dataId]);

    return (
        <Dialog {...props} aria-labelledby="department-dialog-title">
            <DialogTitle id="department-dialog-title">
                {formData.mode === formModeDef.ADD ? t("c.add") : t("c.edit")}
            </DialogTitle>
            <DialogContent>
                {formDataLoading ? (
                    <SimpleLoading />
                ) : (
                    <DepartmentFormBase
                        onSubmit={handleSubmit}
                        initialValues={formData.data}
                        formMode={formData.mode}
                        formRef={formRef}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DepartmentFormDialog;
