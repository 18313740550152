/* eslint-disable no-unused-vars */
/**
 * 定義有哪些行為，統一觸發的類型
 */
enum Action {
    USER_UPDATE = "USER_UPDATE",
    USER_CLEAR = "USER_CLEAR",
    EMPLOYEE_UPDATE = "EMPLOYEE_UPDATE",
    EMPLOYEE_RESET = "EMPLOYEE_RESET",
    LEFT_MENU_OPEN = "LEFT_MENU_OPEN",
    LEFT_MENU_CLOSE = "LEFT_MENU_CLOSE",
    LOADING_SHOW = "LOADING_SHOW",
    LOADING_DISMISS = "LOADING_DISMISS",
    LOADING_CLEAR = "LOADING_CLEAR",
    FORMLIST_UPDATE = "FORMLIST_UPDATE",
    FORMLIST_CLEAR = "FORMLIST_CLEAR",
    GET_TEMPDATA = "GET_TEMPDATA",
    UPDATE_TEMPDATA = "UPDATE_TEMPDATA",
    CLEAR_TEMPDATA = "CLEAR_TEMPDATA",
    POP_TOAST_MESSAGE = "POP_TOAST_MESSAGE",
    GET_EMPLOYEE_DATA = "GET_EMPLOYEE_DATA",
    CLEAR_ALL = "CLEAR_ALL",
    GET_UNREAD_MESSAGE = "GET_UNREAD_MESSAGE",
    UNREAD_MESSAGE_UPDATE = "UNREAD_MESSAGE_UPDATE",
    READ_MESSAGE = "READ_MESSAGE",
    SET_MAIN_TITLE = "SET_MAIN_TITLE",
    UPDATE_TEMP_RELOAD_FLAG = "UPDATE_TEMP_RELOAD_FLAG",
}
export default Action;
