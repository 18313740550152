import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Action } from "@material-table/core";
import { PageHeader2 as PageHeader } from "layout";
import AbsenceSearchTable from "./AbsenceSearchTable";
import AuditDialog from "components/audit/AuditDialog";
import GridTabs from "components/grid/GridTabs";
import Toolbar from "components/grid/Toolbar";
import CardReplacementSearchTable, { CardReplacementRowData } from "./CardReplacementSearchTable";
import CardReplacementColumnsFactory from "./CardReplacementColumnsFactory";
import getRandomLeaveBlocks from "components/mock/leaveBlocksMock";
import LeaveInfoDialog from "components/leave/LeaveInfoDialog";
import LeaveGrid from "components/leave/LeaveGrid";
import AbsenceColumnsFactory from "./AbsenceColumnsFactory";
import LeaveColumnsFactory from "./LeaveColumnsFactory";
import LeaveSearchTable, { LeaveRowData } from "./LeaveSearchTable";
import { YearMonthPicker } from "components";
import moment, { Moment } from "moment";
import ClockRequestFormDialog from "./ClockRequestFormDialog";
import { TabPageProps } from "layout/types";
import AbsenceTabs from "./AbsenceTabs";

function AbsenceList({ match }: TabPageProps) {
    const tableRef = useRef<any>(null);
    const { t } = useTranslation();
    const [auditFormOpen, setAuditFormOpen] = useState<boolean>(false);
    const [leaveInfoOpen, setLeaveInfoOpen] = useState<boolean>(false);
    const [clockRequestOpen, setClockRequestOpen] = useState<boolean>(false);
    const [activeRowId, setActiveRowId] = useState<string>("");
    const [dateFilter, setDateFilter] = useState<Moment>(moment());

    // TODO: get from the api
    const onClockRequestOpen = (rowId: string) => {
        setActiveRowId(rowId);
        setClockRequestOpen(true);
    };

    const onYearMonthFilterChange = (date: Moment) => {
        setDateFilter(date);
    };

    const dateToolbar = (
        <Toolbar justifyContent="center">
            <YearMonthPicker value={dateFilter} onChange={onYearMonthFilterChange} />
        </Toolbar>
    );

    const absenceSearchTable = (
        <AbsenceSearchTable
            tableRef={tableRef}
            columns={AbsenceColumnsFactory(
                {
                    type: "main",
                    formAction: onClockRequestOpen,
                },
                t
            )}
            header={dateToolbar}
        />
    );
    // TODO: interface that inherits from Employee or IndexableRow and reuse these actions
    const leaveActions: Action<LeaveRowData>[] = [
        {
            icon: "assignment",
            iconProps: {
                color: "primary",
            },
            tooltip: t("預覽"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    onPreview(rowData.id);
                }
            },
        },
    ];

    const leaveSearchTable = (
        <LeaveSearchTable
            tableRef={tableRef}
            actions={leaveActions}
            columns={LeaveColumnsFactory(
                {
                    type: "main",
                    downloadAction: (rowId: string) => alert("TODO: implement"),
                },
                t
            )}
            header={dateToolbar}
        />
    );
    const onPreview = (rowId: string) => {
        // TODO: implement api
        setActiveRowId(rowId);
        setAuditFormOpen(true);
    };
    const cardReplacementActions: Action<CardReplacementRowData>[] = [
        {
            icon: "assignment",
            iconProps: {
                color: "primary",
            },
            tooltip: t("預覽"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    onPreview(rowData.id);
                }
            },
        },
    ];

    const onLeaveInfoPreview = (rowId: string) => {
        // TODO: get leaveInfo from the api
        setLeaveInfoOpen(true);
    };
    const cardReplacementSearchTable = (
        <CardReplacementSearchTable
            tableRef={tableRef}
            actions={cardReplacementActions}
            columns={CardReplacementColumnsFactory(
                {
                    type: "main",
                    formAction: onLeaveInfoPreview,
                },
                t
            )}
        />
    );
    const leaveDetails = <LeaveGrid leaveBlocks={getRandomLeaveBlocks()} />;

    const refreshTable = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };
    const handleFormClose = () => {
        setAuditFormOpen(false);
    };

    return (
        <>
            <PageHeader title="" />
            <GridTabs
                match={match}
                tabs={[
                    { index: 0, label: t("出缺勤紀錄"), children: absenceSearchTable, url: AbsenceTabs.main },
                    { index: 1, label: t("請假紀錄"), children: leaveSearchTable, url: AbsenceTabs.leaveSearch },
                    { index: 2, label: t("補卡紀錄"), children: cardReplacementSearchTable, url: AbsenceTabs.card },
                    { index: 3, label: t("休假剩餘天數"), children: leaveDetails, url: AbsenceTabs.leaveDetails },
                ]}
            />
            <AuditDialog
                onClose={handleFormClose}
                onChange={refreshTable}
                open={auditFormOpen}
                rowId={activeRowId}
                readonly={true}
            />
            {/*<LeaveInfoDialog open={leaveInfoOpen} onClose={() => setLeaveInfoOpen(false)} />*/}
            <ClockRequestFormDialog open={clockRequestOpen} onClose={() => setClockRequestOpen(false)} />
        </>
    );
}

export default AbsenceList;
