import React, { ReactNode } from "react";
import ReactLoading from "react-loading";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Avatar, AvatarProps, List, ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import { LoadingWrapper } from "components";

interface OrderAvatarProps extends AvatarProps {
    orderChanged: boolean;
}

const OrderAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== "orderChanged",
})<OrderAvatarProps>(({ orderChanged, theme }) =>
    orderChanged
        ? {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.getContrastText(theme.palette.secondary.main),
          }
        : {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.getContrastText(theme.palette.primary.main),
          }
);

interface useSortableItemProps<RowData> {
    primaryText?: ReactNode | ((rowData: RowData) => ReactNode);
    secondaryText?: ReactNode | ((rowData: RowData) => ReactNode);
    orderKey: keyof RowData;
    oldOrderKey: keyof RowData;
}

const SortableList = SortableContainer(({ children }: { children: ReactNode }) => {
    return <List>{children}</List>;
});

function useSortableItem<RowData>({
    primaryText,
    secondaryText,
    orderKey,
    oldOrderKey,
}: useSortableItemProps<RowData>) {
    return SortableElement(({ row }: { row: RowData }) => {
        let mPrimaryText: ReactNode = "";
        let mSecondaryText: ReactNode = "";
        if (primaryText) {
            mPrimaryText = typeof primaryText === "function" ? primaryText(row) : primaryText;
        }
        if (primaryText) {
            mSecondaryText = typeof secondaryText === "function" ? secondaryText(row) : secondaryText;
        }

        return (
            <ListItem style={{ zIndex: 2000 }}>
                <ListItemIcon>
                    <OrderAvatar orderChanged={row[orderKey] !== row[oldOrderKey]}>{row[orderKey]}</OrderAvatar>
                </ListItemIcon>
                <ListItemText primary={mPrimaryText} secondary={mSecondaryText} />
            </ListItem>
        );
    });
}

export interface SortableBaseProps<RowData> extends useSortableItemProps<RowData> {
    dataList: RowData[];
    onChange: (dataList: RowData[]) => void;
    loading: boolean;
    startIndex?: number;
}

function SortableBase<RowData>({
    dataList,
    onChange,
    loading = false,
    primaryText,
    secondaryText,
    orderKey,
    oldOrderKey,
    startIndex = 1,
}: SortableBaseProps<RowData>) {
    const SortableItem = useSortableItem<RowData>({ primaryText, secondaryText, orderKey, oldOrderKey });
    const handleSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        const mDataList = arrayMove(dataList, oldIndex, newIndex);
        mDataList.forEach((row, i) => {
            row[orderKey] = (i + startIndex) as any;
        });
        onChange(mDataList);
    };
    return (
        <>
            {loading ? (
                <LoadingWrapper>
                    <ReactLoading type="spinningBubbles" color="black" />
                </LoadingWrapper>
            ) : (
                <SortableList onSortEnd={handleSortEnd}>
                    {dataList.map((value, index) => (
                        <SortableItem key={`item-${index}`} index={index} row={value} />
                    ))}
                </SortableList>
            )}
        </>
    );
}

export default SortableBase;
