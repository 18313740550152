import * as yup from "yup";
import { SchemaOf } from "yup";
import { TextField, makeValidate } from "mui-rff";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { FormBase, FormBaseProps } from "components";
import React from "react";

export interface AuditMessageFormValues {
    message: string;
}

export type AuditMessageFormProps = Pick<FormBaseProps<AuditMessageFormValues>, "formRef" | "onSubmit">;

const initialValues: AuditMessageFormValues = {
    message: "",
};

function AuditMessageForm(props: AuditMessageFormProps) {
    const { t } = useTranslation();
    const schema: SchemaOf<AuditMessageFormValues> = yup
        .object()
        .shape({
            message: yup.string().label(t("audit.fd.message")).required(),
        })
        .defined();
    const validate = makeValidate(schema);
    return (
        <FormBase<AuditMessageFormValues> initialValues={initialValues} validate={validate} {...props}>
            <Stack spacing={3}>
                <TextField
                    required={true}
                    label={t("audit.fd.message")}
                    name="message"
                    inputProps={{ maxLength: 200 }}
                />
            </Stack>
        </FormBase>
    );
}

export default AuditMessageForm;
