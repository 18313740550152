import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSortAmountDownAlt } from "react-icons/fa";
import { Action } from "@material-table/core";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import { StandaloneConfirmDialog, StandaloneConfirmDialogRef, SearchTextField } from "components";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import { useDataCUD, useUserPermission } from "hooks";
import { PageHeader2 as PageHeader } from "layout";
import WorkPositionSearchTable, { WorkPositionRowData } from "./WorkPositionSearchTable";
import WorkPositionFormDialog from "./WorkPositionFormDialog";
import WorkPositionSortableDialog from "./WorkPositionSortableDialog";

function WorkPositionList() {
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const { t } = useTranslation();
    const crud = useDataCUD();
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("workposition"), [isWrite]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [formDataId, setFormDataId] = useState<string | null>(null);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [sortableOpen, setSortableOpen] = useState<boolean>(false);
    const tableRef = useRef<any>(null);

    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };
    const handleSortableBtn = () => {
        setSortableOpen(true);
    };
    const handleAdd = async () => {
        setFormDataId(null);
        setFormOpen(true);
    };
    const handleEdit = async (row: WorkPositionRowData) => {
        setFormDataId(row.id);
        setFormOpen(true);
    };
    const handleFormClose = () => {
        setFormOpen(false);
    };
    const handleSortableClose = () => {
        setSortableOpen(false);
    };

    const refreshTable = () => {
        tableRef.current && tableRef.current.onQueryChange();
    };

    const actions: ActionType<WorkPositionRowData>[] = writable
        ? [
              (rowData: WorkPositionRowData): Action<WorkPositionRowData> => {
                  return {
                      icon: "edit",
                      tooltip: t("c.edit"),
                      onClick: (e: any, rowData) => {
                          if (!Array.isArray(rowData)) {
                              handleEdit(rowData);
                          }
                      },
                  };
              },
          ]
        : [];

    const extraActions: ActionType<WorkPositionRowData>[] = writable
        ? [
              {
                  icon: "delete",
                  tooltip: t("c.del"),
                  onClick: (e: any, rowData) => {
                      const action = () => {
                          if (!Array.isArray(rowData)) {
                              crud.handleDel({}, `/api/workPosition/${rowData.id}`)
                                  .then(() => {
                                      tableRef.current.onPageChange(e, 0);
                                  })
                                  .catch(() => {});
                          }
                      };
                      dialogRef.current && dialogRef.current.open(action);
                  },
              },
          ]
        : [];

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    writable ? (
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        handleAdd();
                                    }}
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} startIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleSortableBtn} startIcon={<FaSortAmountDownAlt />}>
                                {t("c.order")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />

            <WorkPositionSearchTable
                tableRef={tableRef}
                actions={actions}
                extraActions={extraActions}
                options={{
                    filtering: showFilter,
                }}
            />
            <WorkPositionFormDialog
                dataId={formDataId}
                onClose={handleFormClose}
                onChange={refreshTable}
                open={formOpen}
            />
            <WorkPositionSortableDialog onClose={handleSortableClose} onChange={refreshTable} open={sortableOpen} />
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
        </>
    );
}

export default WorkPositionList;
