import { useEffect, useMemo } from "react";
import moment, { Moment } from "moment";
import { useFetchData2 as useFetchData } from "hooks";
import { PreProcessCheckResult } from "common/accounting";

function useConsolidationCheck() {
    const [checkResult, checkResultFetch, checkResultCleanup] = useFetchData<PreProcessCheckResult>(
        "/api/accountConsolidation/preprocessCheck"
    );

    const consolidationDate = useMemo<Moment | null>(() => {
        if (checkResult && checkResult.date) {
            return moment(checkResult.date).date(1).add(1, "month").add(-1, "day");
        }
        return null;
    }, [checkResult]);

    useEffect(() => {
        checkResultFetch().catch((err) => {
            console.log(err);
        });
        return () => {
            checkResultCleanup();
        };
    }, []);

    return { checkResult, checkResultFetch, consolidationDate };
}

export default useConsolidationCheck;
