import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReduxTempData } from "hooks";
import { AccountTitleRow } from "pages/accountSetting";

export type AccountTitleSettingKey =
    | "ap"
    | "ar"
    | "bank"
    | "cash"
    | "ex"
    | "ex_tax"
    | "fx_in"
    | "fx_out"
    | "in"
    | "in_tax"
    | "pl"
    | "pl_accu"
    | "tr_fee";

export type AccountTitleSettingData = Partial<Record<AccountTitleSettingKey, AccountTitleRow>>;

function useAccountTitleSetting() {
    const { t } = useTranslation();
    const [accountTitleSettingDataLoaded, setAccountTitleSettingDataLoaded] = useState<boolean>(false);
    const accountTitleSettingData = useReduxTempData<AccountTitleSettingData>(
        `/api/accountTitle/setting/all/`,
        t("accountTitle.tab.setting")
    );
    const checkAccountTitleIsSet = (keys: AccountTitleSettingKey[]): boolean => {
        return keys.reduce((previousValue, currentValue) => {
            const data = accountTitleSettingData && accountTitleSettingData[currentValue];
            return (previousValue && !!data) as any;
        }, true);
    };

    useEffect(() => {
        if (accountTitleSettingData !== undefined && accountTitleSettingData !== null) {
            setAccountTitleSettingDataLoaded(true);
        }
    }, [accountTitleSettingData]);
    return { accountTitleSettingData, checkAccountTitleIsSet, accountTitleSettingDataLoaded };
}

export default useAccountTitleSetting;
