import { createTheme } from "@mui/material/styles";

const layoutTheme = createTheme({
    typography: {
        fontFamily: [
            "Roboto",
            "Teko",
            "Noto Sans TC",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Microsoft JhengHei"',
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        h1: {
            fontSize: "2.5rem",
            fontWeight: 800,
        },
        h2: {
            fontSize: "2.2rem",
            fontWeight: 800,
        },
        h3: {
            fontSize: "2.0rem",
            fontWeight: 800,
        },
        h4: {
            fontSize: "1.8rem",
            fontWeight: 800,
        },
        h5: {
            fontSize: "1.5rem",
            fontWeight: 800,
        },
        h6: {
            fontSize: "1.2rem",
            fontWeight: 800,
        },
    },
    components: {
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: 10,
                },
                title: {
                    fontWeight: 800,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    height: "100%",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&$disabled": {
                        color: "black",
                        "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 0.1) !important",
                            "& legend span::after": {
                                content: '"🔒"',
                            },
                        },
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "rgba(0, 0, 0, 0.54) !important",
                    "&$disabled::after": {
                        content: '"🔒"',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontWeight: "unset",
                },
                head: {
                    fontWeight: "bold",
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    marginTop: "0px",
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    justifyContent: "center",
                },
                scroller: {
                    flexGrow: 0,
                },
            },
        },
    },
    palette: {
        primary: {
            main: "#005DEB",
            light: "#EBF3FF",
        },
    },
});

export default layoutTheme;
