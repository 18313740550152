import React from "react";
import { Field } from "react-final-form";
import { TextField, InputAdornment, BaseTextFieldProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export interface PickerTextFieldBaseProps extends BaseTextFieldProps {
    name: string;
    lookup: Record<string, string>;
    fullWidth?: boolean;
}

export default function PickerTextFieldBase({ name, lookup = {}, ...props }: PickerTextFieldBaseProps) {
    return (
        <Field
            name={name}
            type="number"
            render={({ input, meta }) => {
                let showStr: string = "";
                if (input.value !== 0 && input.value in lookup) {
                    showStr = lookup[input.value];
                }
                return (
                    <>
                        <TextField
                            {...props}
                            value={showStr}
                            error={Boolean(meta.error)}
                            helperText={meta.error}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <input hidden {...input} />
                    </>
                );
            }}
        />
    );
}
