import React, { useMemo, useRef, useState, createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Action } from "@material-table/core";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import { StandaloneConfirmDialog, StandaloneConfirmDialogRef, SearchTextField, AlertWithAuthLink } from "components";
import { ActionType } from "components/customMaterialTable/CustomMaterialTable";
import {
    useDataCUD,
    useReduxTempDataReloadFlag,
    useUserPermission,
    useAccountTitleSetting,
    AccountTitleSettingData,
} from "hooks";
import { PageHeader2 as PageHeader } from "layout";
import BankAccountTable, { BankAccountRowData } from "./BankAccountTable";
import BankAccountFormDialog from "./BankAccountFormDialog";
export interface BankAccountFormContextValues {
    accountTitleSettings: AccountTitleSettingData;
}

const BankAccountFormContext = createContext<BankAccountFormContextValues>({ accountTitleSettings: {} });

export function useBankAccountFormContext() {
    return useContext(BankAccountFormContext);
}

function BankAccountList() {
    const { t } = useTranslation();
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [formDataId, setFormDataId] = useState<string | null>(null);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const { addFlag } = useReduxTempDataReloadFlag(`/api/bankAccount/all`);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("accountSetting"), [isWrite]);
    const { accountTitleSettingData, checkAccountTitleIsSet, accountTitleSettingDataLoaded } = useAccountTitleSetting();
    const crud = useDataCUD();
    const tableRef = useRef<any>(null);

    const handleFilterBtn = () => {
        setShowFilter(!showFilter);
    };
    const handleAdd = async () => {
        setFormDataId(null);
        setFormOpen(true);
    };
    const handleEdit = async (row: BankAccountRowData) => {
        setFormDataId(row.id);
        setFormOpen(true);
    };
    const handleFormClose = () => {
        setFormOpen(false);
    };
    const refreshTable = () => {
        tableRef.current?.onQueryChange();
        addFlag();
    };

    const isAccountTitleSettingDataSet = useMemo(
        () => checkAccountTitleIsSet(["bank", "cash"]),
        [accountTitleSettingData]
    );

    const actions: ActionType<BankAccountRowData>[] = [
        (rowData: BankAccountRowData): Action<BankAccountRowData> => {
            return {
                icon: "edit",
                tooltip: t("c.edit"),
                onClick: (e: any, rowData) => {
                    if (!Array.isArray(rowData)) {
                        handleEdit(rowData);
                    }
                },
                disabled: !isAccountTitleSettingDataSet,
            };
        },
    ];

    const extraActions: ActionType<BankAccountRowData>[] = [
        (rowData: BankAccountRowData): Action<BankAccountRowData> => {
            return {
                icon: "delete",
                tooltip: t("c.del"),
                onClick: (e: any, rowData) => {
                    const action = () => {
                        if (!Array.isArray(rowData)) {
                            crud.handleDel({}, `/api/bankAccount/${rowData.id}`).then(() => {
                                tableRef.current.onPageChange(e, 0);
                            });
                        }
                    };
                    dialogRef.current?.open(action);
                },
            };
        },
    ];

    return (
        <>
            {accountTitleSettingDataLoaded && !isAccountTitleSettingDataSet && (
                <AlertWithAuthLink
                    severity="warning"
                    authLinkProps={{
                        permissionPage: "accountSetting",
                        to: "/accountSetting/setting",
                    }}
                    linkLabel={t("c.goToSet")}
                >
                    {t("bankAccount.msg.accountTitleNotSet")}
                </AlertWithAuthLink>
            )}
            <PageHeader
                title=""
                leftToolView={
                    writable && (
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        handleAdd();
                                    }}
                                    disabled={!isAccountTitleSettingDataSet}
                                >
                                    {t("c.add")}
                                </Button>
                            </Grid>
                        </Grid>
                    )
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={handleFilterBtn} startIcon={<FilterListIcon />}>
                                {t("c.filter")}
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
            <BankAccountTable
                tableRef={tableRef}
                actions={writable ? actions : undefined}
                extraActions={writable ? extraActions : undefined}
                options={{
                    filtering: showFilter,
                }}
                page={20}
            />
            <BankAccountFormContext.Provider value={{ accountTitleSettings: accountTitleSettingData || {} }}>
                <BankAccountFormDialog
                    dataId={formDataId}
                    onClose={handleFormClose}
                    onChange={refreshTable}
                    open={formOpen}
                />
            </BankAccountFormContext.Provider>
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />

            {/* <CreateAccountTitleDialog type={0} open={true} /> */}
        </>
    );
}

export default BankAccountList;
