import { AttendanceRowData } from "../../pages/attendance/AttendanceSearchTable";
import AttendanceStatusEnum from "../attendance/AttendanceStatusEnum";

const attendanceListMock: Array<AttendanceRowData> = [
    {
        id: 41,
        name: "波提且利",
        employeeNo: "A2001",
        date: new Date("2021-04-24"),
        workHours: "08:30~17:30",
        startWorkTime: "08:32",
        endWorkTime: "17:34",
        status: AttendanceStatusEnum.NORMAL,
    },
    {
        id: 42,
        name: "拉斐爾",
        employeeNo: "A2002",
        date: new Date("2021-04-24"),
        workHours: "08:30~17:30",
        startWorkTime: "08:32",
        endWorkTime: "17:34",
        status: AttendanceStatusEnum.NORMAL,
    },
    {
        id: 43,
        name: "貝多芬",
        employeeNo: "A2003",
        date: new Date("2021-04-24"),
        workHours: "08:30~17:30",
        startWorkTime: "08:32",
        endWorkTime: "17:34",
        status: AttendanceStatusEnum.ABNORMAL,
    },
    {
        id: 44,
        name: "安迪沃荷",
        employeeNo: "A2004",
        date: new Date("2021-04-24"),
        workHours: "08:30~17:30",
        startWorkTime: "未打卡",
        endWorkTime: "未打卡",
        status: AttendanceStatusEnum.ABNORMAL,
    },
];

export default function mockFilterStatus(filterStatus?: AttendanceStatusEnum) {
    if (filterStatus === undefined) {
        return attendanceListMock;
    }

    return attendanceListMock.filter(({ status }) => status === filterStatus);
}

export function getById(entryId: number): AttendanceRowData | undefined {
    return attendanceListMock.find(({ id }) => id === entryId);
}
