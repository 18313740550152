import React from "react";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import mockFilterStatus from "components/mock/importLeavePreviewListMock";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export interface ImportPreviewRowData {
    id: string;
    name: string;
    systemHours: number;
    remainHours: number;
    availableHours: number;
}

export interface ImportPreviewSearchTableProps extends Partial<CustomMaterialTableProps<ImportPreviewRowData>> {
    options?: Options<ImportPreviewRowData>;
    columns: Column<ImportPreviewRowData>[];
    header?: React.ReactNode;
}

function ImportPreviewSearchTable({ options = {}, columns, header, ...props }: ImportPreviewSearchTableProps) {
    const theme = useTheme();
    // TODO: retrieve real data from api
    const formTable = {
        fetch: () => mockFilterStatus(),
    };

    return (
        <>
            {header && header}
            <MaterialTable<ImportPreviewRowData>
                {...props}
                columns={columns}
                data={formTable.fetch()}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: false,
                    headerStyle: { height: theme.spacing(6.75) },
                    ...options,
                }}
                components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                }}
            />
        </>
    );
}

export default ImportPreviewSearchTable;
