import React, { useEffect, ReactNode } from "react";
import { ToastProvider } from "react-toast-notifications";
// import { useHistory } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { AppBarSpacer, LayoutContainer, LayoutContent } from "./layoutComponents";
import LoadingBackdrop from "./LoadingBackdrop";
import AlertToast from "./AlertToast";
import TopBar from "./TopBar";
import MainDrawer from "./MainDrawer";
import ReduxToast from "./ReduxToast";
import MainTitle from "./MainTitle";
import { useAppContent } from "components/AppContext";
import { localeDateFns } from "./commonValue";
export interface LayoutProps {
    children: ReactNode;
}
declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        [key: string]: any;
    }
}
function Layout({ children }: LayoutProps) {
    const { locale } = useAppContent();
    //console.log(process.env);
    useEffect(() => {
        if (process.env.REACT_APP_CRISP_WEBSITE_ID) {
            /*===== Crisp code start =====*/
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;
            (function () {
                const d = document;
                const s = d.createElement("script");
                s.src = "https://client.crisp.chat/l.js";
                s.async = true;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();
            /*===== Crisp code end =====*/
        }
    }, []);
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <StyledEngineProvider injectFirst>
                <TopBar />
                <MainDrawer />
                <LayoutContent>
                    <AppBarSpacer />
                    <MainTitle />
                    <LayoutContainer maxWidth={false}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale && localeDateFns[locale]}>
                            {children || <></>}
                        </LocalizationProvider>
                    </LayoutContainer>
                </LayoutContent>
                <LoadingBackdrop />
                <ToastProvider
                    placement="bottom-center"
                    autoDismiss
                    autoDismissTimeout={5000}
                    transitionDuration={300}
                    components={{ Toast: AlertToast }}
                >
                    <ReduxToast />
                </ToastProvider>
            </StyledEngineProvider>
        </Box>
    );
}

export default Layout;
