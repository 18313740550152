import React from "react";
import { useTranslation } from "react-i18next";
import { Chip, ChipProps, ChipPropsVariantOverrides, styled } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { red, lightBlue } from "@mui/material/colors";

interface StyledChipProps extends ChipProps {
    value?: boolean | string | number | null;
}

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== "value",
})<StyledChipProps>(({ value, theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: value ? lightBlue[50] : red[50],
    color: value ? lightBlue[800] : red[800],
}));
export interface EnabledStatusChipProps {
    value?: boolean | string | number | null;
    size?: "small" | "medium";
    variant?: OverridableStringUnion<"filled" | "outlined", ChipPropsVariantOverrides>;
}

function EnabledStatusChip({ value, size = "small", ...props }: EnabledStatusChipProps) {
    const { t } = useTranslation();
    const label = value ? t("c.enabled") : t("c.disabled");
    return <StyledChip label={label} size={size} value={value} {...props} />;
}

export default EnabledStatusChip;
