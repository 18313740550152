/* eslint-disable no-unused-vars */
import React from "react";
import RequestBlock from "./RequestBlock";
import { blue, green, pink } from "@mui/material/colors";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Avatar } from "@mui/material";

export interface RequestActionProps {
    type: RequestType;
    title: string;
    onClick: () => void;
}

export enum RequestType {
    Leave,
    Overtime,
    RefillCard,
}

interface IconProps {
    color: string;
    background: string;
}

const Icon = ({ color, background }: IconProps) => {
    return (
        <Avatar variant="rounded" sx={{ background: background }}>
            <AssignmentIcon sx={{ color: color }} />
        </Avatar>
    );
};

const Icons: Record<RequestType, React.ReactNode> = {
    [RequestType.Leave]: <Icon color={pink[500]} background={pink[50]} />,
    [RequestType.Overtime]: <Icon color={blue[500]} background={blue[50]} />,
    [RequestType.RefillCard]: <Icon color={green[500]} background={green[50]} />,
};

function RequestAction({ type, ...props }: RequestActionProps) {
    return <RequestBlock icon={Icons[type]} {...props} />;
}

export default RequestAction;
