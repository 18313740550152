import React, { useRef, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { makeValidate } from "mui-rff";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from "@mui/material";
import { addToast } from "helpers";
import FormBase, { FormBaseRefProps } from "components/final-form/FormBase";
import { AccountVoucherDetail, useAccountVoucherFormContext } from "../AccountVoucherFormBase";
import { TableData } from "components/customMaterialTable/CustomMaterialTable";
import VoucherDetailFormContent from "./VoucherDetailFormContent";
import { useUniformInvoiceSchema } from "./UniformInvoiceFormContent";
import useCheckUniformInvoice from "./useCheckUniformInvoice";

export type VoucherDetailFormValues = Partial<AccountVoucherDetail> & { tableData?: TableData };

export const initialValues: VoucherDetailFormValues = {
    debit: 0,
    credit: 0,
    particulars: "",
};

export interface VoucherDetailFormDialogProps extends DialogProps {
    //dataId: string | null;
    onSave: (values: VoucherDetailFormValues) => void;
    data?: VoucherDetailFormValues;
}

function VoucherDetailFormDialog({ data, onSave, onClose, ...props }: VoucherDetailFormDialogProps) {
    const formRef = useRef<FormBaseRefProps<any>>(null);
    const { editable, inTaxAccountTitle, exTaxAccountTitle } = useAccountVoucherFormContext();
    const { t } = useTranslation();
    const checkUniformInvoice = useCheckUniformInvoice();
    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };
    const handleSubmit = async (values: VoucherDetailFormValues) => {
        if (values.uniformInvoice) {
            const existedInvoice = await checkUniformInvoice(values.uniformInvoice.code);
            if (existedInvoice) {
                addToast(t("uniformInvoice.err.duplicateCode"), { appearance: "error" });
                return;
            }
        }
        onSave(values);
        handleClose();
    };
    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    const taxAccountTitleIds = [inTaxAccountTitle?.id, exTaxAccountTitle?.id];
    const uniformInvoiceSchema = useUniformInvoiceSchema();
    const schema = yup
        .object()
        .shape({
            accountTitleId: yup.number().label(t("e.accountTitle")).required(),
            debit: yup
                .number()
                .label(t("accountVoucher.fd.debit"))
                .when("credit", {
                    is: 0,
                    then: yup.number().min(1, t("accountVoucher.err.noZeroAtSameTime")),
                    otherwise: yup.number().equals([0], t("accountVoucher.err.cdOneOnly")),
                })
                .required(),
            credit: yup.number().label(t("accountVoucher.fd.credit")).min(0).required(),
            uniformInvoice: yup
                .object()
                .when("accountTitleId", (val: number | null | undefined, schema: ObjectSchema<any>) => {
                    if (val && taxAccountTitleIds.indexOf(val) > -1) {
                        return schema.shape(uniformInvoiceSchema).defined();
                    }
                    return schema.nullable();
                }),
        })
        .defined();
    const validate = makeValidate(schema);
    return (
        <Dialog {...props} maxWidth="md" fullWidth aria-labelledby="bankAccount-dialog-title">
            <DialogTitle id="bankAccount-dialog-title">{data ? t("c.edit") : t("c.add")}</DialogTitle>
            <DialogContent>
                <FormBase<VoucherDetailFormValues>
                    validate={validate}
                    initialValues={data || initialValues}
                    onSubmit={handleSubmit}
                    formRef={formRef}
                >
                    <VoucherDetailFormContent />
                </FormBase>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                {editable && (
                    <Button color="primary" onClick={handleSave}>
                        {t("c.save")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default VoucherDetailFormDialog;
