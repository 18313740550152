import React from "react";
import { Badge, BadgeProps, styled } from "@mui/material";

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    "& .MuiBadge-badge": {
        transform: "initial",
        padding: "0 10px",
        position: "initial",
        marginLeft: "1em",
    },
}));

export default function TabBadge(props: BadgeProps) {
    return <StyledBadge {...props} />;
}
