import DEF from "../def";
const initValue = { count: 0, data: [] };
export default function reducer(state = initValue, action) {
    switch (action.type) {
        case DEF.UNREAD_MESSAGE_UPDATE:
            return { ...action.value };
        case DEF.READ_MESSAGE:
            state.data.filter((a) => a.id === action.value.id).forEach((a) => (a.isRead = true));
            return { ...state };
        case DEF.CLEAR_ALL:
            return initValue;
        default:
            return state;
    }
}
