// eslint-disable-next-line
import { from, empty, of, throwError } from "rxjs";
// 引入combineEpics方法
import { combineEpics, ofType } from "redux-observable";
// eslint-disable-next-line
import { groupBy, mergeMap, switchMap, catchError, filter, map, takeUntil, finalize, tap } from "rxjs/operators";
import { UpdateTempDataAction, PopToastMessage } from "../Actions";
import { loadControl as LC } from "helpers";
import axios from "faxios";
import DEF from "../def";
const GetTempUsageDatas = (action$) => {
    return action$.pipe(
        filter((a) => a.type),
        ofType(DEF.GET_TEMPDATA),
        mergeMap((action) => {
            //console.log(action);
            if (!action.url) {
                return empty();
            }
            action.useLC && LC();
            const CancelToken = axios.CancelToken; //cancelToken
            const source = CancelToken.source(); //cancelToken

            return from(axios.get(action.url, { cancelToken: source.token })).pipe(
                // TODO Kevin
                // 如果有同樣的動作，就把目前動作取消，改天要找找看有沒有辦法保留先做的動作，取消後做的
                takeUntil(
                    action$.pipe(
                        ofType(DEF.GET_TEMPDATA),
                        filter((laterAction) => laterAction.url === action.url),
                        tap(() => {
                            console.debug(DEF.GET_TEMPDATA, action.url + " canceled", source);
                            source.cancel("canceled");
                        })
                    )
                ),
                mergeMap((response) => {
                    const data = response.data;
                    // console.log(data);
                    if (!Array.isArray(data) && "status" in data && data.status === "error") {
                        throw data;
                    }
                    const nextAction = UpdateTempDataAction(action.url, data);
                    return of(nextAction);
                }),
                catchError((e) => {
                    console.error(e);
                    if (e.code) {
                        return of(PopToastMessage({ e, name: action.name, type: { appearance: "error" } }));
                    } else if (e instanceof Error) {
                        return of(PopToastMessage({ e, name: action.name, type: { appearance: "error" } }));
                    } else {
                        return empty();
                    }
                }),
                finalize(() => {
                    action.useLC && LC(false);
                })
            );
        })
    );
};
const GetEmployeeDatas = (action$) => {
    // const { t } = useTranslation();
    return action$.pipe(
        ofType(DEF.GET_EMPLOYEE_DATA),
        switchMap((action) => {
            //console.log(action);
            LC();
            const CancelToken = axios.CancelToken; //cancelToken
            const source = CancelToken.source(); //cancelToken

            return from(axios.get("/api/employee/picker", { cancelToken: source.token })).pipe(
                // TODO Kevin
                // 如果有同樣的動作，就把目前動作取消，改天要找找看有沒有辦法保留先做的動作，取消後做的
                takeUntil(
                    action$.ofType(DEF.GET_EMPLOYEE_DATA).pipe(
                        tap(() => {
                            console.debug(DEF.GET_EMPLOYEE_DATA, DEF.GET_EMPLOYEE_DATA + " canceled", source);
                            source.cancel("canceled");
                        })
                    )
                ),
                mergeMap((response) => {
                    const data = response.data;
                    if (!Array.isArray(data) && "status" in data && data.status === "error") {
                        throw data;
                    }
                    const nextAction = { type: DEF.EMPLOYEE_UPDATE, value: data };
                    return of(nextAction);
                }),
                catchError((e) => {
                    console.error(e);
                    if (e.code) {
                        return of(PopToastMessage({ e, name: action.name, type: { appearance: "error" } }));
                    } else if (e instanceof Error) {
                        return of(PopToastMessage({ e, name: action.name, type: { appearance: "error" } }));
                    } else {
                        return empty();
                    }
                }),
                finalize(() => {
                    LC(false);
                })
            );
        })
    );
};
const GetUnreadMessage = (action$) => {
    // const { t } = useTranslation();
    return action$.pipe(
        ofType(DEF.GET_UNREAD_MESSAGE),
        switchMap((action) => {
            //console.log(action);
            LC();
            const CancelToken = axios.CancelToken; //cancelToken
            const source = CancelToken.source(); //cancelToken

            return from(axios.get("/api/message/unread", { cancelToken: source.token })).pipe(
                // TODO Kevin
                // 如果有同樣的動作，就把目前動作取消，改天要找找看有沒有辦法保留先做的動作，取消後做的
                takeUntil(
                    action$.ofType(DEF.GET_UNREAD_MESSAGE).pipe(
                        tap(() => {
                            console.debug(DEF.GET_UNREAD_MESSAGE, DEF.GET_UNREAD_MESSAGE + " canceled", source);
                            source.cancel("canceled");
                        })
                    )
                ),
                mergeMap((response) => {
                    const data = response.data;
                    // console.log(data);
                    if (!Array.isArray(data) && "status" in data && data.status === "error") {
                        throw data;
                    }
                    const nextAction = { type: DEF.UNREAD_MESSAGE_UPDATE, value: data };
                    return of(nextAction);
                }),
                catchError((e) => {
                    console.error(e);
                    if (e.code) {
                        return of(PopToastMessage({ e, name: action.name, type: { appearance: "error" } }));
                    } else if (e instanceof Error) {
                        return of(PopToastMessage({ e, name: action.name, type: { appearance: "error" } }));
                    } else {
                        return empty();
                    }
                }),
                finalize(() => {
                    LC(false);
                })
            );
        })
    );
};
// const Test = (action$) => {
//
//     // const { t } = useTranslation();
//     return action$.pipe(
//         filter((a) => a.type),
//         ofType("TTTT"),
//         mergeMap((action) => {
//             const CancelToken = axios.CancelToken; //cancelToken
//             const source = CancelToken.source(); //cancelToken
//             console.log("TTTT", action, source);
//             return from(axios.get("/auth/login", { cancelToken: source.token })).pipe(
//                 takeUntil(action$.ofType("TTTT").pipe(tap((ev) => source.cancel("canceled")))),
//                 map((response) => {
//                     console.log("TTTT", response);
//                     console.log(empty());
//                     return { type: "" };
//                 }),
//                 catchError((e) => {
//                     console.log("TTTT", e);
//                     return empty();
//                 })
//             );
//         })
//     );
// };
// combineEpics會將參數中的epic函數合併在一起
export default combineEpics(GetTempUsageDatas, GetEmployeeDatas, GetUnreadMessage);
