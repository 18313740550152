import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { LeaveRequestFormValues } from "components/audit/formType";
import DetailsBase, { DetailsBaseProps } from "./DetailsBase";
import { TextDateItem, TextItem } from "components/final-form";

function LeaveRequestDetail({ value }: DetailsBaseProps<LeaveRequestFormValues>) {
    const { t } = useTranslation();
    return (
        <DetailsBase<LeaveRequestFormValues> value={value}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextDateItem name="startTime" format="YYYY-MM-DD HH:mm" label={t("c.fd.dateStart")} />
                    <TextDateItem name="endTime" format="YYYY-MM-DD HH:mm" label={t("c.fd.dateEnd")} />
                    <TextItem
                        name="hours"
                        label={t("leave.fd.hours")}
                        render={(value) => (value ? t("c.units.vh", { value }) : "")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextItem name="leaveType.name" label={t("e.leaveType")} />
                    <TextItem name="reason" label={t("c.fd.applyReason")} />
                    <TextItem name="remark" label={t("c.fd.rmk")} />
                </Grid>
            </Grid>
        </DetailsBase>
    );
}

export default LeaveRequestDetail;
