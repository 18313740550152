import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";
import { Switches, SwitchProps } from "mui-rff";

export interface StatusSwitchProps extends Partial<SwitchProps> {
    name: string;
}

function StatusSwitch(props: StatusSwitchProps) {
    const { t } = useTranslation();
    return (
        <Field name={props.name}>
            {(inputProps) => {
                const value = inputProps.input.value;
                const label = value ? t("c.enabled") : t("c.disabled");
                return (
                    <Switches
                        {...props}
                        label={t("c.enabledStatus")}
                        color="primary"
                        data={{ label: label, value: true }}
                    />
                );
            }}
        </Field>
    );
}

export default StatusSwitch;
