import axios from "axios";
import { useTranslation } from "react-i18next";
import { addToast, loadControl as LC } from "helpers";
import AuditSubmissionStatusEnum from "./AuditSubmissionStatusEnum";

export interface AuditSubmission {
    id: number;
    message: string;
    status: AuditSubmissionStatusEnum;
}

function useAuditAction() {
    const { t } = useTranslation();
    const submitAudit = async (inputs: AuditSubmission) => {
        LC();
        await axios
            .post("/api/form/audit", inputs)
            .then(function (response) {
                const data = response.data;
                if ("status" in data) {
                    if (data.status === "ok") {
                        if (inputs.status === AuditSubmissionStatusEnum.Accepted) {
                            addToast(t("audit.msg.acceptSuccess"), { appearance: "success" });
                        } else {
                            addToast(t("audit.msg.rejectSuccess"), { appearance: "error" });
                        }
                    } else {
                        const extraMsg = "error" in data ? data.error : "";
                        addToast(t("c.msg.processFailed") + extraMsg, { appearance: "error" });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                addToast(t("c.msg.severError"), { appearance: "error" });

                throw err;
            })
            .finally(() => {
                LC(false);
            });
    };

    const withdrawAudit = async (id: number) => {
        LC();
        await axios
            .put("/api/form/request", { id })
            .then(function (response) {
                const data = response.data;
                if ("status" in data) {
                    if (data.status === "ok") {
                        addToast(t("audit.msg.withdrawSuccess"), { appearance: "success" });
                    } else {
                        const extraMsg = "error" in data ? data.error : "";
                        addToast(t("c.msg.processFailed") + extraMsg, { appearance: "error" });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                addToast(t("c.msg.severError"), { appearance: "error" });

                throw err;
            })
            .finally(() => {
                LC(false);
            });
    };

    return { submitAudit, withdrawAudit };
}

export default useAuditAction;
