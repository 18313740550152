import * as React from "react";
import TextField from "@mui/material/TextField";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { MobileDatePicker } from "@mui/lab";

export interface DateRangeFilterProps {
    initStartDate?: Date;
    initEndDate?: Date;
    dateFormat?: string;
    mask?: string;
    onApply: (startDate: Date, endDate: Date) => void;
    disabled?: boolean;
}

function DateRangeFilter({ initStartDate, initEndDate, dateFormat, mask, onApply, disabled }: DateRangeFilterProps) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = React.useState<Date | null>(initStartDate || moment().startOf("day").toDate());
    const [endDate, setEndDate] = React.useState<Date | null>(initEndDate || moment().endOf("day").toDate());

    const handleStartDateChange = (newValue: Date | null) => {
        setStartDate(newValue);
    };

    const handleEndDateChange = (newValue: Date | null) => {
        setEndDate(newValue);
    };

    const handleButtonClick = () => {
        if (startDate && endDate) {
            onApply(startDate, endDate);
        }
        // TODO: validate date start < end and return error
    };

    return (
        <Grid
            container
            direction={{ xs: "column", md: "row" }}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >
            <Grid item sx={{ display: { xs: "none", md: "initial" } }}>
                <DesktopDatePicker
                    label={t("c.fd.dateStart")}
                    inputFormat={dateFormat}
                    mask={mask}
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={disabled}
                />
            </Grid>
            <Grid item sx={{ display: { xs: "initial", md: "none" } }}>
                <MobileDatePicker
                    label={t("c.fd.dateStart")}
                    inputFormat={dateFormat}
                    mask={mask}
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={disabled}
                />
            </Grid>
            <Grid item>{t("c.dateToPre")}</Grid>
            <Grid item sx={{ display: { xs: "none", md: "initial" } }}>
                <DesktopDatePicker
                    label={t("c.fd.dateEnd")}
                    inputFormat={dateFormat}
                    mask={mask}
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={disabled}
                />
            </Grid>
            <Grid item sx={{ display: { xs: "initial", md: "none" } }}>
                <MobileDatePicker
                    label={t("c.fd.dateEnd")}
                    inputFormat={dateFormat}
                    mask={mask}
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={disabled}
                />
            </Grid>
            <Grid item>{t("c.dateToPost")}</Grid>
            <Grid item>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="medium"
                    onClick={handleButtonClick}
                    disabled={disabled}
                >
                    {t("c.search")}
                </Button>
            </Grid>
        </Grid>
    );
}

DateRangeFilter.defaultProps = {
    dateFormat: "yyyy-MM-dd",
    mask: "____-__-__",
};

export default DateRangeFilter;
