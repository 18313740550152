import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogTitle, Grid } from "@mui/material";
import AuditStatusEnum from "./AuditStatusEnum";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { blueGrey, green, orange } from "@mui/material/colors";

export interface AuditDialogTitleProps {
    status: AuditStatusEnum;
    formName: string;
    auditNo?: string;
}

function AuditDialogTitle({ status, formName, auditNo }: AuditDialogTitleProps) {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>("");
    const [icon, setIcon] = useState<React.ReactNode>(<></>);

    const titleMap = {
        [AuditStatusEnum.Rejected]: {
            title: t("audit.status.rejected"),
            icon: <CloseIcon sx={{ display: "block", color: "#E31B0C" }} />,
        },
        [AuditStatusEnum.Accepted]: {
            title: t("audit.status.accepted"),
            icon: <CheckIcon sx={{ display: "block", color: green[600] }} />,
        },
        [AuditStatusEnum.Pending]: {
            title: t("audit.status.pending"),
            icon: <SwapHorizIcon sx={{ display: "block", color: orange[400] }} />,
        },
        [AuditStatusEnum.SigningOff]: {
            title: t("audit.status.pending"),
            icon: <SwapHorizIcon sx={{ display: "block", color: orange[400] }} />,
        },
        [AuditStatusEnum.Withdrawn]: {
            title: t("audit.status.withdrawn"),
            icon: <CloseIcon sx={{ display: "block", color: blueGrey[700] }} />,
        },
    };

    useEffect(() => {
        const { title, icon } = titleMap[status];
        setTitle(title);
        setIcon(icon);
    }, [status]);

    return (
        <DialogTitle id="audit-dialog-title">
            <Grid container spacing={2} alignItems="center" sx={{ mt: -2, ml: -2 }}>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                    {icon}
                    {title}
                </Grid>
                <Grid item>{t(`form.title.${formName}`)}</Grid>
                <Grid item>{auditNo || ""}</Grid>
            </Grid>
        </DialogTitle>
    );
}

export default AuditDialogTitle;
