import React from "react";
import { Chip, ChipProps, styled } from "@mui/material";
import { green, red } from "@mui/material/colors";
import AttendanceStatusEnum from "./AttendanceStatusEnum";

const backgroundMap = {
    [AttendanceStatusEnum.NORMAL]: green[400],
    [AttendanceStatusEnum.ABNORMAL]: red[700],
};

interface StyledChipProps extends ChipProps {
    type: AttendanceStatusEnum;
}

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== "type",
})<StyledChipProps>(({ type, theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: backgroundMap[type],
    color: "#fff",
    fontWeight: 500,
    size: "small",
}));

export interface AttendanceStatusChipProps extends ChipProps {
    type: AttendanceStatusEnum;
}

export default function AttendanceStatusChip({ type, ...props }: AttendanceStatusChipProps) {
    return <StyledChip type={type} {...props} />;
}
