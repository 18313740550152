import axios, { AxiosResponse } from "axios";
import { ResponseHandler } from "common";
import { loadControl as LC } from "helpers";
import { UniformInvoiceRow } from "./UniformInvoiceFormContent";

function useCheckUniformInvoice() {
    const checkUniformInvoice = async (code: string): Promise<UniformInvoiceRow | null> => {
        LC();
        return await axios
            .post(`/api/accountVoucher/uniformInvoiceCheck`, { code })
            .then((response: AxiosResponse<ResponseHandler<UniformInvoiceRow>>) => {
                const data = response.data;
                if (data.status === "ok") {
                    return data.result || null;
                } else {
                    return null;
                }
            })
            .catch((error: any) => {
                console.log(error);
                return null;
            })
            .finally(() => {
                LC(false);
            });
    };
    return checkUniformInvoice;
}

export default useCheckUniformInvoice;
