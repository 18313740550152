import React, { useCallback, useEffect, useState, ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useFetchData2 as useFetchData } from "hooks";
import PickerDialog, { PickerDialogProps } from "components/PickerDialog";
import { StdQueryListResult } from "common";
export interface PermissionRolePickRow {
    id: number;
    name: string;
    description: string;
}

export interface PermissionRolePickerDialogProps extends Partial<PickerDialogProps<PermissionRolePickRow, number>> {
    onClose: () => void;
    title?: string;
    filter?: (row: PermissionRolePickRow) => boolean;
}

function PermissionRolePickerDialog({ title, filter, ...props }: PermissionRolePickerDialogProps) {
    const { t } = useTranslation();

    const [listData, listDataFetch, listDataCleanup] = useFetchData<StdQueryListResult<PermissionRolePickRow>>(
        "/api/permissionRole/picker",
        t("e.permissionRole"),
        undefined,
        { count: 0, data: [] }
    );
    const [searchString, setSearchString] = useState<string | null>(null);
    const fetchData = useCallback(
        async (params?) => {
            await listDataFetch(true, {
                params: params,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    useEffect(() => {
        fetchData();
        return () => {
            listDataCleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);
    const renderItem = (
        row: PermissionRolePickRow,
        onItemSelect: (item: PermissionRolePickRow) => void,
        idx: number
    ) => {
        return (
            <ListItem
                button
                key={idx}
                onClick={() => {
                    onItemSelect(row);
                }}
            >
                <ListItemText
                    primary={row.name}
                    secondary={
                        <Typography component="span" variant="body2">
                            {row.description}
                        </Typography>
                    }
                />
            </ListItem>
        );
    };
    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };
    const listToShow = useMemo<PermissionRolePickRow[]>(() => {
        if (listData) {
            const mList = filter ? listData.data.filter(filter) : listData.data;
            if (searchString && searchString !== "") {
                return mList.filter((row) => row.name.includes(searchString));
            } else {
                return mList;
            }
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, searchString, listData && listData.data]);
    return (
        <PickerDialog<PermissionRolePickRow>
            {...props}
            title={title || t("permissionRole.pickerTitle").toString()}
            data={listToShow}
            renderItem={renderItem}
            formView={
                <>
                    <TextField
                        label={t("c.search")}
                        onChange={handleTextChange}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                </>
            }
        />
    );
}

export default PermissionRolePickerDialog;
