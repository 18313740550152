import { ImportPreviewRowData } from "pages/leave/initial/ImportPreviewSearchTable";

const importLeavePreviewListMock: Array<ImportPreviewRowData> = [
    {
        id: "38",
        name: "特休假",
        systemHours: 112,
        remainHours: 70,
        availableHours: 40,
    },
    {
        id: "39",
        name: "病假",
        systemHours: 112,
        remainHours: 70,
        availableHours: 40,
    },
    {
        id: "40",
        name: "生理假",
        systemHours: 112,
        remainHours: 70,
        availableHours: 40,
    },
    {
        id: "41",
        name: "特休假",
        systemHours: 112,
        remainHours: 70,
        availableHours: 40,
    },
    {
        id: "42",
        name: "事假",
        systemHours: 112,
        remainHours: 70,
        availableHours: 40,
    },
    {
        id: "43",
        name: "家庭照顧假",
        systemHours: 112,
        remainHours: 70,
        availableHours: 40,
    },
    {
        id: "44",
        name: "公假",
        systemHours: 112,
        remainHours: 70,
        availableHours: 40,
    },
    {
        id: "45",
        name: "喪假",
        systemHours: 112,
        remainHours: 70,
        availableHours: 40,
    },
];

export default function mockFilterStatus() {
    return importLeavePreviewListMock;
}
