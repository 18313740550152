import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select, Chip, Input } from "@mui/material";
import { useTheme, createStyles, makeStyles } from "@mui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    })
);

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}
export default function MultiselectEditField(props) {
    const classes = useStyles();
    const theme = useTheme();
    let value = [];
    let fieldName = "";
    if (props.columnDef) {
        fieldName = props.columnDef.field;
    }
    if (props.rowData && props.rowData[fieldName]) {
        value = props.rowData[fieldName];
    } else if (props.value && Array.isArray(props.value)) {
        value = props.value;
    }
    const handleChange = (e) => {
        props.onRowDataChange &&
            props.onRowDataChange({
                ...props.rowData,
                [fieldName]: e.target.value,
            });
    };
    const { items } = props;

    return (
        <>
            <FormControl className={classes.formControl}>
                <InputLabel></InputLabel>
                <Select
                    multiple
                    value={value}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip key={value} label={items[value]} className={classes.chip} />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {Object.keys(items).map((key) => (
                        <MenuItem key={key} value={key} style={getStyles(items, value, theme)}>
                            {items[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}

MultiselectEditField.propTypes = {
    columnDef: PropTypes.object,
    rowData: PropTypes.object,
    value: PropTypes.array,
    onRowDataChange: PropTypes.func,
    items: PropTypes.object,
};
