const error = console.error;
const warn = console.warn;

//hide some annoying warning under react strict mode
function logError(...warnings) {
    let showWarning = true;
    warnings.forEach((warning) => {
        if (warning && typeof warning.includes === "function") {
            if (warning.includes("UNSAFE_componentWillMount")) showWarning = false;
            else if (warning.includes("findDOMNode")) showWarning = false;
            else if (warning.includes("Legacy context API")) showWarning = false;
            else if (warning.includes("Cannot update a component")) showWarning = false;
            /**
             * A bug form material-table, waiting for fix
             * @see https://github.com/mbrn/material-table/pull/2369
             */ else if (warning.includes("scrollWidth")) showWarning = false;
        }
    });
    if (showWarning) error(...warnings);
}

function logWarning(...warnings) {
    let showWarning = true;
    warnings.forEach((warning) => {
        if (warning && typeof warning.includes === "function") {
            if (warning.includes("The columns provided to material table are static")) showWarning = false;
        }
    });
    if (showWarning) warn(...warnings);
}

console.error = logError;
console.warn = logWarning;
