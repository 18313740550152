import { InitialLeaveRowData } from "pages/leave/initial/InitialLeaveSearchTable";

const initialLeaveListMock: Array<InitialLeaveRowData> = [
    {
        employeeId: "A2001",
        employeeName: "牛頓",
        hireDate: new Date("2019-03-05"),
        department: "社長室",
        mainPosition: "負責人",
        updatedAt: new Date("2021-11-11"),
        initialState: "已鎖定匯入資料",
        isLocked: true,
    },
    {
        employeeId: "A2002",
        employeeName: "米開朗基羅",
        hireDate: new Date("2019-03-05"),
        department: "社長室",
        mainPosition: "負責人",
        updatedAt: new Date("2021-11-11"),
        initialState: "已鎖定匯入資料",
        isLocked: true,
    },
    {
        employeeId: "A2003",
        employeeName: "愛迪生",
        hireDate: new Date("2019-03-05"),
        department: "社長室",
        mainPosition: "負責人",
        updatedAt: new Date("2021-11-11"),
        initialState: "已匯入初始值",
        isLocked: false,
    },
    {
        employeeId: "A2004",
        employeeName: "拉斐爾",
        hireDate: new Date("2020-04-21"),
        department: "PM部門",
        mainPosition: "經理",
        updatedAt: undefined,
        initialState: "未設定",
        isLocked: false,
    },
];

export default function mockFilterStatus() {
    return initialLeaveListMock;
}
