import React from "react";
import { useTranslation } from "react-i18next";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { useFormTable, useTimestampColumns, TimestampRowData, useEnabledStatusColumn } from "hooks";
import { getUser } from "helpers";

export interface DepartmentRowData extends TimestampRowData {
    id: string;
    name: string;
    parentDepartment?: {
        name: string;
    };
    memberCount: number;
    parentDepartmentId?: number;
    status?: boolean;
    remark: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface DepartmentSearchTableProps extends Partial<CustomMaterialTableProps<DepartmentRowData>> {
    options?: Options<DepartmentRowData>;
}

function DepartmentSearchTable({ options = {}, ...props }: DepartmentSearchTableProps) {
    const formTable = useFormTable<DepartmentRowData>("/api/department/list");
    const { t } = useTranslation();
    const timestampColumns = useTimestampColumns<DepartmentRowData>({});
    const enabledStatusColumn = useEnabledStatusColumn<DepartmentRowData>("status");
    const user = getUser();
    const companyName = user.company.name;

    const columns: Column<DepartmentRowData>[] = [
        {
            title: "#",
            field: "id",
            type: "string",
        },
        enabledStatusColumn,
        {
            title: t("department.fd.name"),
            field: "name",
            type: "string",
        },
        {
            title: t("department.fd.supervisor"),
            field: "supervisor.employee.name",
            type: "string",
        },
        {
            title: t("department.fd.parentDepartment"),
            field: "parentDepartment.name",
            type: "string",
            render: (row) => {
                if (row.parentDepartment) {
                    return row.parentDepartment.name;
                }
                return companyName;
            },
        },
        {
            title: t("department.fd.memberCount"),
            field: "memberCount",
            type: "numeric",
            filtering: false,
        },
        ...timestampColumns,
    ];
    return (
        <MaterialTable<DepartmentRowData>
            {...props}
            columns={columns}
            data={formTable.fetch()}
            options={{
                pageSizeOptions: [5, 10, 20],
                toolbar: false,
                ...options,
            }}
        />
    );
}

export default DepartmentSearchTable;
