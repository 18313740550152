import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
export const download = (path: string, filename: string, params?: any) => {
    axios.get(path, { responseType: "blob", params }).then((response) => {
        fileDownload(response.data, filename);
    });
};

export const generateFilename = (prefix?: string, ext?: string): string => {
    const timeString = moment().format("YYYYMMDDHHmmss");
    return `${prefix || ""}${timeString}${ext ? "." + ext : ""}`;
};
