import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useForm, useField } from "react-final-form";
import { FieldArray, useFieldArray } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";
import { MTableToolbar } from "@material-table/core";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MaterialTable from "components/customMaterialTable";
import { createSimpleValidate } from "helpers/mTableHelper";
import { addToast } from "helpers";
function FinalFormMaterialTableDef(
    { name, disabled, can, schema, initialFormData, onChange, editable = {}, components = {}, ...props },
    ref
) {
    const form = useForm();
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const mTableRef = useRef(null);
    const arrayRenderRef = useRef(false);
    const fieldArray = useFieldArray(name);
    const field = useField(name);
    useImperativeHandle(ref, () => ({
        ...mTableRef.current,
        arrayRenderRef,
        fieldArray,
    }));
    const validate = !schema ? () => new Promise((resolve) => resolve()) : createSimpleValidate(schema, setErrors);
    const initialFormDataDefaultValue = initialFormData ? { ...initialFormData } : {};
    const [mInitialFormData, setMInitialFormData] = useState(initialFormDataDefaultValue);
    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("md"));
    const useEditOptions = () => {
        const mOptions = {};
        if (disabled) return mOptions;
        if (can && can.includes("add")) {
            mOptions.onRowAdd = (newData) =>
                new Promise((resolve, reject) => {
                    validate(newData)
                        .then(() => {
                            form.mutators.push(`${name}`, newData);
                            clearErrors();
                            resolve();
                        })
                        .catch(() => {
                            setMInitialFormData(newData);
                            reject();
                            addToast(t("c.msg.formDataError"), { appearance: "error" });
                        });
                });
        }
        if (can && can.includes("edit")) {
            mOptions.onRowUpdate = (newData, oldData) =>
                new Promise((resolve, reject) => {
                    validate(newData)
                        .then(() => {
                            const index = oldData.tableData.id;
                            form.mutators.update(`${name}`, index, newData);
                            clearErrors();
                            resolve();
                        })
                        .catch(() => {
                            reject();
                            addToast(t("c.msg.formDataError"), { appearance: "error" });
                        });
                });
        }
        if (can && can.includes("delete")) {
            mOptions.onRowDelete = (oldData) =>
                new Promise((resolve) => {
                    const index = oldData.tableData.id;
                    form.mutators.remove(`${name}`, index);
                    resolve();
                });
        }
        return mOptions;
    };
    const editOptions = useEditOptions();
    const clearErrors = () => {
        if (Object.keys(errors).length > 0) {
            setErrors({});
        }
        setMInitialFormData(initialFormDataDefaultValue);
    };

    const renderMainError = () => {
        const errors = field.meta.error;
        if (errors) {
            if (Array.isArray(errors)) {
                return (
                    <Box px={3}>
                        {errors.map((item, i) => (
                            <Box key={i}>
                                <Typography color="secondary" variant="caption">
                                    {item}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                );
            } else {
                return (
                    <Typography color="secondary" variant="caption">
                        {errors}
                    </Typography>
                );
            }
        }
        return <></>;
    };

    ///dataRef
    useEffect(() => {
        if (mTableRef.current && fieldArray && fieldArray.fields.value) {
            const values = fieldArray.fields.value;
            values.forEach((item, i) => {
                if ("tableData" in item) {
                    item.tableData.id = i;
                } else {
                    item.tableData = {
                        id: i,
                        uuid: uuidv4(),
                    };
                }
            });
            mTableRef.current.dataManager.setData(values);
            onChange && onChange(values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldArray, fieldArray.fields.value, name, onChange]);

    useEffect(() => {
        if (mTableRef.current) {
            const mState = { ...mTableRef.current.dataManager.getRenderState() };
            mTableRef.current.setState(mState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mTableRef.current && mTableRef.current.dataManager.data, isMobileSize]);

    const renderArray = (obj) => {
        const { fields } = obj;
        const values = fields.value;
        if (values && Array.isArray(values) && arrayRenderRef) {
            arrayRenderRef.current = true;
        }
        return <></>;
    };
    return (
        <>
            <MaterialTable
                data={[]}
                editable={{ ...editable, ...editOptions }}
                formTableErrors={errors}
                onCanceled={clearErrors}
                components={{
                    Toolbar: (props) => (
                        <Box>
                            <MTableToolbar {...props} />
                            {renderMainError()}
                        </Box>
                    ),
                    ...components,
                }}
                {...props}
                initialFormData={mInitialFormData}
                tableRef={mTableRef}
            />
            <FieldArray name={name}>{renderArray}</FieldArray>
        </>
    );
}

const FinalFormMaterialTable = forwardRef(FinalFormMaterialTableDef);

export default FinalFormMaterialTable;

FinalFormMaterialTableDef.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    can: PropTypes.arrayOf(PropTypes.string),
    schema: PropTypes.object,
    initialFormData: PropTypes.object,
    onChange: PropTypes.func,
    editable: PropTypes.bool,
    tableRef: PropTypes.shape({
        current: PropTypes.object,
    }),
    components: PropTypes.object,
};

export const addRowMutator = (fieldname) => (args, state, utils) => {
    utils.changeValue(state, fieldname, (v) => [...v, args[0]]);
};

export const editRowMutator = (fieldname) => (args, state, utils) => {
    utils.changeValue(state, fieldname, (v) => {
        const dataUpdate = [...v];
        const index = args[1].tableData.id;
        dataUpdate[index] = args[0];
        return dataUpdate;
    });
};

export const deleteRowMutator = (fieldname) => (args, state, utils) => {
    utils.changeValue(state, fieldname, (v) => {
        const dataDelete = [...v];
        const index = args[0].tableData.id;
        dataDelete.splice(index, 1);
        return dataDelete;
    });
};

export const removeTableData = (values, field) => {
    const results = [];
    if (field in values && Array.isArray(values[field])) {
        values[field].forEach((row) => {
            const mRow = { ...row };
            if ("tableData" in mRow) {
                delete mRow.tableData;
            }
            results.push(mRow);
        });
    }
    return { ...values, [field]: results };
};
export const ACTIONS = {
    ADD: "add",
    EDIT: "edit",
    DELETE: "delete",
};
