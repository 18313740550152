import React from "react";
import ReactLoading from "react-loading";
import { LoadingWrapper } from "components";

function SimpleLoading() {
    return (
        // eslint-disable-next-line react/jsx-no-undef
        <LoadingWrapper>
            <ReactLoading type="spinningBubbles" color="black" />
        </LoadingWrapper>
    );
}

export default SimpleLoading;
