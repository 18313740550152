import React, { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Checkboxes, Select, makeValidate } from "mui-rff";
import * as yup from "yup";
import { SchemaOf } from "yup";
import { Grid, MenuItem } from "@mui/material";
import { FormBase, FormBaseProps } from "components";
import { EmployeePickerField } from "components/final-form";
import { formModeDef as modeDef, useFetchData2 as useFetchData, useReduxTempData } from "hooks";
import { WorkPositionRowData } from "pages/workposition/WorkPositionSearchTable";

export interface DepartmentMemberFormValues {
    employeeId: number;
    workPositionId: number;
    isSupervisor: boolean;
}

export interface DepartmentMemberFormBaseProps extends FormBaseProps<DepartmentMemberFormValues> {
    dataId: string | null;
}

interface FormContextValue {
    workPositionData?: WorkPositionRowData[];
    existedEmployeeIds?: number[];
}

const FormContext = createContext<FormContextValue>({
    workPositionData: [],
    existedEmployeeIds: [],
});

const useFormContext = () => {
    return useContext<FormContextValue>(FormContext);
};

function DepartmentMemberFormBase({
    onSubmit,
    formMode = modeDef.ADD,
    dataId,
    ...props
}: DepartmentMemberFormBaseProps) {
    const { t } = useTranslation();
    const workPositionData = useReduxTempData<WorkPositionRowData[]>(`/api/workPosition/all`, t("e.workPosition"));
    const [memberIdsData, memberIdsDataFetch, memberIdsDataCleanup] = useFetchData<number[]>(
        `/api/department/member/ids/${dataId}`,
        "",
        undefined,
        []
    );
    const schema: SchemaOf<WorkPositionRowData> = yup
        .object()
        .shape({
            employeeId: yup.number().label(t("e.emp")).required(),
            workPositionId: yup.number().label(t("e.workPosition")).required(),
            isSupervisor: yup.bool().nullable(),
        })
        .defined();
    const validate = makeValidate(schema);

    useEffect(() => {
        memberIdsDataFetch().catch((err) => {
            console.log(err);
        });
        return () => {
            memberIdsDataCleanup();
        };
    }, []);

    return (
        <FormBase<DepartmentMemberFormValues> validate={validate} onSubmit={onSubmit} {...props}>
            <FormContext.Provider
                value={{
                    workPositionData: workPositionData || [],
                    existedEmployeeIds: memberIdsData || [],
                }}
            >
                <FormContent />
            </FormContext.Provider>
        </FormBase>
    );
}

function FormContent() {
    const { workPositionData, existedEmployeeIds } = useFormContext();
    const { t } = useTranslation();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <EmployeePickerField
                    fullWidth
                    name="employeeId"
                    label={t("e.emp")}
                    filter={(row) => {
                        if (existedEmployeeIds) {
                            return existedEmployeeIds.indexOf(Number(row.id)) === -1;
                        } else {
                            return true;
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Select name="workPositionId" label={t("workPosition.fd.name")}>
                    {workPositionData &&
                        workPositionData.map((item: any, index: any) => (
                            <MenuItem key={index} value={item.id || 0}>
                                {item.name}
                            </MenuItem>
                        ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Checkboxes
                    formGroupProps={{ row: true }}
                    label=""
                    name="isSupervisor"
                    data={{ label: t("department.fd.assignAsSupervisor"), value: false }}
                />
            </Grid>
        </Grid>
    );
}

export default DepartmentMemberFormBase;
