import * as React from "react";
import { useLayoutEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { Grid, Tab } from "@mui/material";
import TabBadge from "./TabBadge";
import { Link, match, useParams } from "react-router-dom";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    label: string;
}

interface TabDesc {
    children?: React.ReactNode;
    index: number;
    label: string;
    counter?: number;
    url: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`grid-tabpanel-${index}`}
            aria-labelledby={`grid-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `grid-tab-${index}`,
        "aria-controls": `grid-tabpanel-${index}`,
    };
}

export interface GridTabsProps {
    tabs: Array<TabDesc>;
    onTabChange?: (tabId: string) => void;
    match: match;
}

export default function GridTabs({ tabs, onTabChange, match }: GridTabsProps) {
    const [value, setValue] = React.useState(0);
    const [innerPath, setInnerPath] = React.useState("");
    const { tabName } = useParams<{ tabName?: string }>();
    const { path } = match;
    const pagesNames = tabs.map(({ url }) => url);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (onTabChange) {
            onTabChange(newValue);
        }
    };

    const getCurrentPathWithoutLastPart = () => {
        return path.replace("/:tabName", "");
    };

    const getUrlPath = (url: string) => {
        const currentPath = getCurrentPathWithoutLastPart();

        return `${currentPath}/${url}`;
    };

    useLayoutEffect(() => {
        let valueIndex = 0;
        let tabPath = "";
        if (tabName !== undefined) {
            valueIndex = pagesNames.indexOf(tabName);
            tabPath = tabName.length > 2 && valueIndex > -1 ? tabName : pagesNames[0];
        }
        setValue(valueIndex);
        setInnerPath(tabPath);
    }, [tabName]);

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={innerPath}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                    aria-label="audit tabs"
                >
                    {tabs.map(({ index, label, url, counter }) => {
                        return (
                            <Tab
                                key={index}
                                value={url}
                                component={Link}
                                to={getUrlPath(url)}
                                label={
                                    <Grid container>
                                        <Grid item>{label}</Grid>
                                        <TabBadge badgeContent={counter} max={999} color="primary" />
                                    </Grid>
                                }
                                {...a11yProps(index)}
                            />
                        );
                    })}
                </Tabs>
            </Box>
            {tabs.map(({ index, label, children }) => {
                return (
                    <TabPanel key={index} value={value} index={index} label={label}>
                        {children}
                    </TabPanel>
                );
            })}
        </Box>
    );
}
