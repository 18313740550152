import React, { useState, useRef, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useForm } from "react-final-form";
import { FieldArray, useFieldArray } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";
import filesize from "filesize";
import { DropzoneArea } from "react-mui-dropzone";
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Button,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Avatar,
    IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";

import {
    FaDownload,
    FaUpload,
    FaFile,
    FaFileWord,
    FaFilePowerpoint,
    FaFilePdf,
    FaFileImage,
    FaFileExcel,
    FaFileArchive,
} from "react-icons/fa";
import { download } from "helpers/fileHelper";
import { loadControl as LC } from "helpers";

const useStyles = makeStyles(() => ({
    fileItemAction: {
        marginTop: 8,
        marginRight: 8,
    },
}));

function FileAttachSectionDef(
    { dataId = "", page, name = "attachFiles", auditActionType = null, recordId = "", disabled },
    ref
) {
    const dropzoneRef = useRef(null);
    const fileListViewRef = useRef(null);
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState([]);
    useImperativeHandle(
        ref,
        () => ({
            files,
            clear: () => {
                setFiles([]);
            },
        }),
        [files]
    );
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = async () => {
        if (dropzoneRef.current) {
            setFiles(dropzoneRef.current.state.fileObjects.map((a) => a.file));
            console.log(files);
            handleClose();
        }
    };
    const handleNewFileRemove = (idx) => {
        const mFiles = files.filter((mFile, mIdx) => idx !== mIdx);
        setFiles([...mFiles]);
    };
    const handleDownload = (data, idx) => {
        if (dataId === "" && recordId === "") {
            return;
        }
        if (auditActionType === null) {
            download(`/api/${page}/${dataId}/${name}/${idx}`, `${data.title}`);
        } else {
            //for audit
            download(
                `/api/audit/downloadauditfile?recordId=${recordId}&path=${data.path}&title=${data.title}`,
                `${data.title}`
            );
        }
    };

    const handleUndoDelete = () => {
        if (fileListViewRef.current && fileListViewRef.current.deletedItem.length > 0) {
            fileListViewRef.current.undo();
        }
    };
    return (
        <>
            <Card>
                <CardHeader
                    title={t("c.attachfiles")}
                    titleTypographyProps={{ variant: "h6" }}
                    action={
                        !disabled && (
                            <Grid container>
                                <Grid item>
                                    <IconButton
                                        aria-label="undo"
                                        onClick={() => {
                                            handleUndoDelete();
                                        }}
                                        size="large"
                                    >
                                        <ReplayIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            handleOpen();
                                        }}
                                        size="large"
                                    >
                                        <FaUpload />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    }
                />
                <Divider />
                {files.length > 0 && <NewUploadView files={files} onFileRemove={handleNewFileRemove} />}
                <FileListView name={name} onDownload={handleDownload} disabled={disabled} ref={fileListViewRef} />
            </Card>
            <FileUploadDialog
                dropzoneRef={dropzoneRef}
                open={open}
                onClose={handleClose}
                onSubmit={handleConfirm}
                files={files}
            />
        </>
    );
}
const FileAttachSection = forwardRef(FileAttachSectionDef);

FileAttachSectionDef.propTypes = {
    dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    page: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    auditActionType: PropTypes.number,
    dropzoneRef: PropTypes.any,
    disabled: PropTypes.bool,
};

/**
 *
 * @param {{ files: File[] }} prpos
 */
function NewUploadView({ files, onFileRemove }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const renderItem = () =>
        files.map((file, idx) => (
            <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <FileIcon fileName={file.name} />
                            </Avatar>
                        }
                        action={
                            <Box className={classes.fileItemAction}>
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        onFileRemove(idx);
                                    }}
                                    size="large"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        }
                        title={file.name}
                        subheader={filesize(file.size)}
                    />
                </Card>
            </Grid>
        ));
    return (
        <>
            <CardContent>
                <Typography variant="h6" color="secondary">
                    {t("c.fileForUploading")}
                </Typography>
                <Box p={1} />
                <Grid container spacing={2}>
                    {renderItem()}
                </Grid>
            </CardContent>
            <Divider />
        </>
    );
}

function FileListViewDef({ name, onDownload, disabled }, ref) {
    const { t } = useTranslation();
    const form = useForm();
    const fieldArray = useFieldArray(name);
    const [deletedItem, setDeletedItem] = useState([]);
    const classes = useStyles();

    const handleDelete = (index) => {
        new Promise((resolve) => {
            form.mutators.remove(`${name}`, index);
            resolve();
        });
    };
    useImperativeHandle(
        ref,
        () => ({
            undo: () => {
                form.change(name, [...fieldArray.fields.value, ...deletedItem]);
                setDeletedItem([]);
            },
            deletedItem,
        }),
        [deletedItem, fieldArray.fields.value, form, name]
    );
    const renderItem = (data) =>
        data.map((file, idx) => (
            <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar>
                                <FileIcon fileName={file.title} />
                            </Avatar>
                        }
                        action={
                            <Grid container spacing={1} className={classes.fileItemAction}>
                                <Grid item>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            onDownload(file, idx);
                                        }}
                                        size="large"
                                    >
                                        <FaDownload />
                                    </IconButton>
                                </Grid>
                                {!disabled && (
                                    <Grid item>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                handleDelete(idx);
                                                setDeletedItem([...deletedItem, file]);
                                            }}
                                            size="large"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        }
                        title={file.title}
                        subheader={filesize(file.size)}
                    />
                </Card>
            </Grid>
        ));

    return (
        <CardContent>
            <FieldArray name={name}>
                {(obj) => {
                    const { fields } = obj;
                    const values = fields.value;
                    if (values.length === 0) {
                        return (
                            <Box flexDirection="row" justifyContent="center" alignItems="center">
                                <Typography variant="subtitle2">{t("c.msg.nodata")}</Typography>
                            </Box>
                        );
                    }
                    return (
                        <Grid container spacing={2}>
                            {renderItem(values)}
                        </Grid>
                    );
                }}
            </FieldArray>
        </CardContent>
    );
}

const FileListView = forwardRef(FileListViewDef);

FileListViewDef.propTypes = {
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onDownload: PropTypes.func.isRequired,
};

/**
 *
 * @param {{ fileName:string }} props
 */
function FileIcon({ fileName }) {
    const regex = /^.*\.(\w+)$/m;
    const found = fileName.match(regex);
    if (found) {
        const subName = found[1].toLowerCase();
        if (subName === "pdf") {
            return <FaFilePdf />;
        }
        if (["doc", "docx", "rtf", "odt"].indexOf(subName) > -1) {
            return <FaFileWord />;
        }
        if (["xls", "xlsx", "ods"].indexOf(subName) > -1) {
            return <FaFileExcel />;
        }
        if (["ppt", "pptx", "ods"].indexOf(subName) > -1) {
            return <FaFilePowerpoint />;
        }
        if (["jpg", "jpeg", "png", "gif"].indexOf(subName) > -1) {
            return <FaFileImage />;
        }
        if (["zip", "rar", "7z"].indexOf(subName) > -1) {
            return <FaFileArchive />;
        }
    }
    return <FaFile />;
}

FileIcon.propTypes = {
    fileName: PropTypes.string.isRequired,
};

NewUploadView.propTypes = {
    files: PropTypes.arrayOf(PropTypes.any).isRequired,
    onFileRemove: PropTypes.func.isRequired,
};

function FileUploadDialog({ dropzoneRef, open, onClose, onSubmit, files }) {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="data-import-dialog-title">{t("c.import")}</DialogTitle>
            <DialogContent>
                <Box p={1} />
                <DropzoneArea
                    ref={dropzoneRef}
                    initialFiles={files}
                    dropzoneText={t("ui.dzText")}
                    previewText={t("ui.dzPreview")}
                    // acceptedFiles={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                    maxFileSize={20000000}
                    filesLimit={10}
                    showPreviewsInDropzone={false}
                    showPreviews
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onSubmit} color="primary">
                    {t("c.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

FileUploadDialog.propTypes = {
    dropzoneRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    files: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export const useAttachFilesUtils = (onSubmit, fileAttachSectionRef, field = "attachFiles", isAudit = false) => {
    const doSubmit = (values, form) => {
        const formData = new FormData();
        fileAttachSectionRef.current.files.forEach((file) => {
            formData.append("file", file); //required
        });
        LC();
        axios
            .post(`/api/upload/temp`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                const data = response.data;
                const filesData = values[field] ? [...values[field], ...data] : data;
                const newValues = {
                    ...values,
                    [field]: filesData,
                };
                if (isAudit && field !== "attachFiles") {
                    newValues.attachFiles = filesData;
                }
                onSubmit(newValues, form);
            })
            .finally(() => {
                fileAttachSectionRef.current.clear();
                LC(false);
            });
    };
    return { doSubmit };
};

export default FileAttachSection;
