import React from "react";
import { useTranslation } from "react-i18next";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { useFormTable } from "hooks";
import { dateFormat } from "helpers";

export interface PermissionRoleRowData {
    id: string;
    name: string;
    description: number;
    status: number;
    userCount: number;
    createdAt?: string;
    updatedAt?: string;
}

export interface PermissionRoleSearchTableProps extends Partial<CustomMaterialTableProps<PermissionRoleRowData>> {
    options?: Options<PermissionRoleRowData>;
}

function PermissionRoleSearchTable({ options = {}, ...props }: PermissionRoleSearchTableProps) {
    const formTable = useFormTable<PermissionRoleRowData>("/api/permissionRole");
    const { t } = useTranslation();
    const columns: Column<PermissionRoleRowData>[] = [
        {
            title: "#",
            field: "id",
            type: "string",
            headerStyle: { width: "10%" },
            cellStyle: {
                width: "10%",
            },
        },
        {
            title: t("permissionRole.fd.name"),
            field: "name",
            type: "string",
        },
        {
            title: t("permissionRole.fd.dashboard"),
            field: "dashboard",
            type: "string",
            lookup: t("permissionRole.dashboard", { returnObjects: true }),
        },
        {
            title: t("permissionRole.fd.description"),
            field: "description",
            type: "string",
        },
        {
            title: t("permissionRole.fd.userCount"),
            field: "userCount",
            type: "numeric",
        },
        {
            title: t("c.fd.createdAt"),
            field: "createdAt",
            type: "string",
            render: (row) => {
                if (row.createdAt) {
                    return dateFormat(row.createdAt, "YYYY/MM/DD");
                }
                return "";
            },
        },
        {
            title: t("c.fd.updatedAt"),
            field: "updatedAt",
            type: "string",
            render: (row) => {
                if (row.updatedAt) {
                    return dateFormat(row.updatedAt, "YYYY/MM/DD");
                }
                return "";
            },
        },
    ];

    return (
        <>
            <MaterialTable<PermissionRoleRowData>
                {...props}
                columns={columns}
                data={formTable.fetch()}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    toolbar: false,
                    ...options,
                }}
            />
        </>
    );
}

export default PermissionRoleSearchTable;
