import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import { ButtonProps } from "@mui/material/Button/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Typography } from "@mui/material";
import { AiFillFileExcel } from "react-icons/all";

const StyledButton = styled(Button)<ButtonProps>(({ "aria-expanded": open, theme }) => ({
    borderColor: open ? "#005DEB" : "#0000003B",
    color: open ? "#005DEB" : "#000",
    backgroundColor: "#fff",
    "&:hover": {
        borderColor: "#000",
        backgroundColor: "#fff",
    },
    "&:active": {
        color: "#005DEB",
        borderColor: "#005DEB",
        backgroundColor: "#fff",
    },
}));
const StyledMenuItem = styled(MenuItem)<MenuItemProps>((theme) => ({
    color: "#00000099",
    "&:hover": {
        backgroundColor: "#EBF3FF",
    },
}));

export interface ExportButtonAction {
    title: string;
    onClick: () => void;
}
export interface ExportButtonProps {
    title: string;
    actions: Array<ExportButtonAction>;
}

function ExportButton({ title, actions }: ExportButtonProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const iconDown = <ArrowDropDownIcon />;
    const iconUp = <ArrowDropUpIcon />;
    const [endIcon, setEndIcon] = useState<React.ReactElement>(iconDown);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const icon = open ? iconUp : iconDown;
        setEndIcon(icon);
    }, [open]);

    return (
        <div>
            <StyledButton
                id="fade-button"
                aria-controls="fade-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="outlined"
                onClick={handleClick}
                startIcon={<AiFillFileExcel fontSize="small" />}
                endIcon={endIcon}
            >
                {title}
            </StyledButton>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {actions.map(({ title, onClick }) => {
                    return (
                        <StyledMenuItem key={title} onClick={onClick}>
                            <Typography>{title}</Typography>
                        </StyledMenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}

export default ExportButton;
