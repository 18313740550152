import React from "react";
import { ButtonProps } from "@mui/material/Button/Button";
import LockIcon from "@mui/icons-material/Lock";
import ButtonContained from "./ButtonContained";

function LockButtonPrimary({ children, ...props }: ButtonProps) {
    return (
        <ButtonContained startIcon={<LockIcon fontSize="small" />} {...props}>
            {children}
        </ButtonContained>
    );
}

export default LockButtonPrimary;
