import React, { useState, forwardRef, useImperativeHandle, Ref } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogProps,
    InputAdornment,
    IconButton,
    Button,
    TextField,
    Typography,
    Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { addToast } from "helpers";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export interface PasswordViewerDialogRef {
    show: (passwordToShow: string, userInfoToShow?: string) => void;
}

function PasswordViewerDialogDef(props: Partial<DialogProps>, ref: Ref<PasswordViewerDialogRef>) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>("");
    const [userInfo, setUserInfo] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    useImperativeHandle(
        ref,
        () => ({
            show: (passwordToShow: string, userInfoToShow?: string) => {
                setShowPassword(false);
                setNewPassword(passwordToShow);
                setUserInfo(userInfoToShow || "");
                setOpen(true);
            },
        }),
        []
    );
    const handleShowPasswordButton = () => {
        setShowPassword(!showPassword);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Dialog {...props} open={open} onClose={handleClose} aria-labelledby="password-viewer-dialog-title">
            <DialogTitle id="password-viewer-dialog-title">
                {t("user.msg.generateSuccess")} <Typography variant="body1">{userInfo}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            type={showPassword ? "text" : "password"}
                            label={t("login.fd.pass")}
                            value={newPassword}
                            inputProps={{
                                readOnly: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleShowPasswordButton}
                                            //onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CopyToClipboard
                            text={newPassword}
                            onCopy={() => {
                                addToast(t("user.msg.passwordCopied"));
                            }}
                        >
                            <Button color="primary">{t("user.func.copyPassword")}</Button>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const PasswordViewerDialog = forwardRef(PasswordViewerDialogDef);

export default PasswordViewerDialog;
