import React from "react";
import { useTranslation } from "react-i18next";
import { Column, Options } from "@material-table/core";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { useFormTable, TimestampRowData, useEnabledStatusColumn } from "hooks";

export interface ShiftRowData extends TimestampRowData {
    id: number;
    name: string;
    shiftType: number;
    time?: string;
    clockIn: string;
    clockOut: string;
    memberCount: number;
    status?: boolean;
}

export interface DepartmentSearchTableProps extends Partial<CustomMaterialTableProps<ShiftRowData>> {
    options?: Options<ShiftRowData>;
}

function ShiftSearchTable({ options = {}, ...props }: DepartmentSearchTableProps) {
    const formTable = useFormTable<ShiftRowData>("/api/shift");
    const { t } = useTranslation();
    const shiftTypes = t("shift.shiftTypes", { returnObjects: true });
    const enabledStatusColumn = useEnabledStatusColumn<ShiftRowData>("status");

    const columns: Column<ShiftRowData>[] = [
        {
            title: "#",
            field: "id",
            type: "numeric",
        },
        {
            title: t("shift.fd.name"),
            field: "name",
            type: "string",
        },
        {
            title: t("shift.fd.time"),
            field: "time",
            type: "string",
            render: (row) => {
                if (!!row.clockIn && !!row.clockOut) {
                    return `${row.clockIn}~${row.clockOut}`;
                }
                return t("shift.err.msg");
            },
        },
        {
            title: t("shift.fd.shiftType"),
            field: "shiftType",
            type: "numeric",
            render: (row) => {
                return shiftTypes[row.shiftType];
            },
        },
        {
            title: t("shift.fd.memberCount"),
            field: "memberCount",
            type: "numeric",
            filtering: false,
        },
        enabledStatusColumn,
    ];
    return (
        <MaterialTable<ShiftRowData>
            {...props}
            columns={columns}
            data={formTable.fetch()}
            options={{
                pageSizeOptions: [5, 10, 20],
                toolbar: false,
                ...options,
            }}
        />
    );
}

export default ShiftSearchTable;
