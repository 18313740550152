import React, { useEffect, useState, useRef, useMemo, ChangeEvent } from "react";
import { FaFileImport } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Alert, Button, Grid, Card, CardContent } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PageHeader2 as PageHeader } from "layout";
import { useFetchData2 as useFetchData, useReduxTempDataReloadFlag, useUserPermission } from "hooks";
//import { download } from "helpers/fileHelper";
//import { loadControl as LC } from "helpers";
import { SearchTextFieldBase, StandaloneConfirmDialog } from "components";
import DataImportDialog from "./DataImportDialog";
import AccountTitleTreeView from "./AccountTitleTreeView";
import { AccountTitleRow } from "./AccountTitleTreeItem";
import ItemEditorDialog, { EditorDialogData } from "./ItemEditorDialog";
import AccountTitleI18nDialog from "./AccountTitleI18nDialog";
import { findParentItems } from "./utils";

function BasicAccountTitleList() {
    const { t, i18n } = useTranslation();
    const dialogRef = useRef(null);
    // const crud = useDataCUD();
    const [accountTitles, accountTitlesFetch, accountTitlesCleanup] = useFetchData<AccountTitleRow[]>(
        `/api/accountTitle/data`,
        t("e.accountTitle")
    );
    const [fileUploadDialogState, setFileUploadDialogState] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [editorOpen, setEditorOpen] = useState(false);
    const [editorData, setData] = useState<EditorDialogData>({ id: null });
    const [dataLoaded, setDataLoaded] = useState(false);
    const [i18nDialogId, setI18nDialogId] = useState<number | null>(null);
    const { addFlag } = useReduxTempDataReloadFlag(`/api/accountTitle/data`);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("accountSetting"), [isWrite]);

    useEffect(() => {
        accountTitlesFetch().then(() => {
            setDataLoaded(true);
        });
        return () => {
            accountTitlesCleanup();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const filteredData = useMemo<AccountTitleRow[]>(() => {
        if (searchText && searchText !== "" && accountTitles) {
            const idSet = new Set<number>();
            const filterResult = accountTitles.filter((row) => row.name.includes(searchText));
            filterResult.forEach((row) => {
                idSet.add(row.id);
                row.l0 && idSet.add(row.l0);
                row.l1 && idSet.add(row.l1);
                row.l2 && idSet.add(row.l2);
                row.l3 && idSet.add(row.l3);
            });
            return accountTitles.filter((row) => idSet.has(row.id));
        }
        return accountTitles || [];
    }, [accountTitles, searchText]);

    const allParentNodes = useMemo<string[]>(() => {
        return findParentItems(filteredData).map((value) => value.toString());
    }, [filteredData]);

    useEffect(() => {
        if (searchText && searchText !== "" && filteredData.length > 0) {
            setExpanded(allParentNodes);
        } else {
            //setExpanded([]);
        }
    }, [allParentNodes]);

    const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const fileUploadDialogOpen = () => {
        setFileUploadDialogState(true);
    };
    const fileUploadDialogClose = () => {
        setFileUploadDialogState(false);
    };
    const refreshData = () => {
        accountTitlesFetch();
        addFlag();
    };
    const itemEditorClose = () => {
        setEditorOpen(false);
    };

    const handleAdd = (parent: AccountTitleRow) => {
        setData({ id: null, parent });
        setEditorOpen(true);
    };

    const handleEdit = (row: AccountTitleRow) => {
        setData({ id: row.id });
        setEditorOpen(true);
    };

    const handleI18nDataEdit = (id: number) => {
        setI18nDialogId(id);
    };

    const handleI18nDialogClose = () => {
        setI18nDialogId(null);
    };

    const handleDelete = (row: AccountTitleRow) => {
        // const action = () => {
        //     LC();
        //     crud.handleDel({}, `/api/accountitem/${id}`).then(() => {
        //         accountTitlesFetch().then(() => {
        //             LC(false);
        //         });
        //     });
        // };
        // dialogRef.current.open(action);
    };

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleExpandClick = () => {
        setExpanded((oldExpanded) => (oldExpanded.length === 0 ? allParentNodes : []));
    };

    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="contained"
                                onClick={handleExpandClick}
                                startIcon={expanded.length === 0 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            >
                                {expanded.length === 0 ? t("c.expendOpenAll") : t("c.expendCloseAll")}
                            </Button>
                        </Grid>
                    </Grid>
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        {writable && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={fileUploadDialogOpen}
                                    startIcon={<FaFileImport />}
                                    color="primary"
                                >
                                    {t("c.import")}
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <SearchTextFieldBase onChange={handleSearchTextChange} value={searchText} />
                        </Grid>
                    </Grid>
                }
            />
            {filteredData.length > 0 ? (
                <Card>
                    <CardContent>
                        <AccountTitleTreeView
                            data={filteredData}
                            expanded={expanded}
                            onNodeToggle={handleToggle}
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            onI18nEdit={handleI18nDataEdit}
                            editable={writable}
                            disableSelection
                        />
                    </CardContent>
                </Card>
            ) : (
                dataLoaded && <Alert severity="warning">{t("c.msg.nodata")}</Alert>
            )}
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
            <ItemEditorDialog
                open={editorOpen}
                onI18nEdit={handleI18nDataEdit}
                onClose={itemEditorClose}
                data={editorData}
                editable={writable}
                onChange={refreshData}
            />
            <DataImportDialog open={fileUploadDialogState} onClose={fileUploadDialogClose} onFinished={refreshData} />
            <AccountTitleI18nDialog
                open={!!i18nDialogId}
                dataId={i18nDialogId}
                onClose={handleI18nDialogClose}
                onChange={refreshData}
                editable={writable}
            />
        </>
    );
}

export default BasicAccountTitleList;
