import DEF from "../def";

export default function reducer(state = null, action) {
    switch (action.type) {
        case DEF.EMPLOYEE_UPDATE:
            return [...action.value];
        case DEF.CLEAR_ALL:
        case DEF.EMPLOYEE_RESET:
            return null;
        default:
            return state;
    }
}
