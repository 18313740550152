import React, { ChangeEvent, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box, Typography, List, ListItem, ListItemText, TextField } from "@mui/material";
import { getPageList } from "helpers/pageHelper";
import { PageData } from "common";
export interface PageFinderProps {
    onClose?: (e: MouseEvent) => void;
    hideTitle?: boolean;
}
export default function PageFinder({ onClose = () => {}, hideTitle = false }: PageFinderProps) {
    const pageList = getPageList() as PageData[];
    const { t } = useTranslation();
    const [result, setResult] = useState<PageData[]>([]);
    const [searchText, setSearchText] = useState("");
    const history = useHistory();
    const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        setSearchText(val);
        handlePageSearch(val);
    };
    const handlePageSearch = (text: string) => {
        if (text !== "") {
            const mResult = pageList.filter((item) => item.url !== "#" && t(item.i18n).includes(text));
            setResult(mResult);
        } else {
            setResult([]);
        }
    };
    const handlePageChange = (url: string) => (e: MouseEvent<HTMLDivElement>) => {
        history.push(url);
        onClose && onClose(e);
    };
    return (
        <>
            <Box p={hideTitle ? 0 : 2}>
                {hideTitle ? <></> : <Typography variant="h6">{t("ui.search")}</Typography>}
                <TextField type="text" onChange={onTextChange} value={searchText} inputProps={{ maxLength: 15 }} />
            </Box>
            <List component="nav">
                {result.map((item, i) => (
                    <ListItem button key={i} onClick={handlePageChange(item.url || "#")}>
                        <ListItemText primary={t(item.i18n)} /*secondary={t(`cat.${item.cat}`)}*/ />
                    </ListItem>
                ))}
            </List>
        </>
    );
}
