import React, { ChangeEvent, useState } from "react";
import { AccountTitleRow, AccountTitleTreeView } from "../accountTitle";

export interface AccountTitleTreePickerProps {
    data: AccountTitleRow[];
    selectedItems?: number[];
    onItemSelected?: (item: AccountTitleRow, event: ChangeEvent<HTMLInputElement>, childrenId: number[]) => void;
}

function AccountTitleTreePicker({ data, selectedItems, onItemSelected }: AccountTitleTreePickerProps) {
    const [expanded, setExpanded] = useState<string[]>([]);
    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        if ((event.target as HTMLElement).tagName !== "INPUT") {
            setExpanded(nodeIds);
        }
    };
    return (
        <>
            <AccountTitleTreeView
                data={data}
                expanded={expanded}
                onNodeToggle={handleToggle}
                editable={false}
                showToolbar={false}
                selectable={true}
                selectedItems={selectedItems}
                onItemSelected={onItemSelected}
            />
        </>
    );
}

export default AccountTitleTreePicker;
