const basePath = "formRequest/form";
// Form Path names which can be used for external routing
const FormPath = {
    leaveRequest: "leaveRequest",
    overtimeRequest: "overtimeRequest",
    clockRequest: "clockRequest",

    getPath: function (path: string) {
        return `${basePath}/${path}`;
    },
};

export default FormPath;
