import React from "react";
import { Box, BoxProps } from "@mui/material";

export interface PathItemDetailsProps extends BoxProps {
    color: string;
}

export default function PathItemDetails(props: PathItemDetailsProps) {
    const { color, sx, ...other } = props;
    return (
        <Box
            sx={{
                color: color,
                textAlign: "center",
                fontSize: "12px",
                lineHeight: "19.92px",
                fontWeight: "400",
                whiteSpace: "nowrap",
                ...sx,
            }}
            {...other}
        />
    );
}
