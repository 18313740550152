import React from "react";
import { Chip, ChipProps, styled } from "@mui/material";
import { green, red } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CardReplacementStatusEnum from "./CardReplacementStatusEnum";

const colorMap = {
    [CardReplacementStatusEnum.PASS]: green[700],
    [CardReplacementStatusEnum.REJECTED]: red[700],
};

const backgroundMap = {
    [CardReplacementStatusEnum.PASS]: green[50],
    [CardReplacementStatusEnum.REJECTED]: red[50],
};

const iconMap = {
    [CardReplacementStatusEnum.PASS]: <CheckIcon fontSize="inherit" sx={{ color: green[600] }} />,
    [CardReplacementStatusEnum.REJECTED]: <CloseIcon fontSize="inherit" sx={{ color: red[600] }} />,
};

interface StyledChipProps extends ChipProps {
    type: CardReplacementStatusEnum;
}

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => prop !== "type",
})<StyledChipProps>(({ type, theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: backgroundMap[type],
    color: colorMap[type],
    fontWeight: 700,
    size: "small",
    "& .MuiChip-icon": {
        color: colorMap[type],
    },
}));

export interface CardReplacementStatusChipProps extends ChipProps {
    type: CardReplacementStatusEnum;
}

export default function CardReplacementStatusChip({ type, ...props }: CardReplacementStatusChipProps) {
    return <StyledChip type={type} icon={iconMap[type]} {...props} />;
}
