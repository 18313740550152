import React from "react";
import Block from "components/dashboard/Block";
import ClockInContent from "./ClockInContent";

function ClockInBlock() {
    return (
        <Block>
            <ClockInContent />
        </Block>
    );
}

export default ClockInBlock;
