import React, { ReactNode } from "react";
import { Box, Typography, Grid } from "@mui/material";

export interface PageHeaderProps {
    title: ReactNode;
    subtitle?: ReactNode;
    toolview?: ReactNode;
}

export default function PageHeader({ title, subtitle, toolview }: PageHeaderProps) {
    return (
        <Box mb={1}>
            <Grid container spacing={3} justifyContent="space-between" alignItems="flex-end">
                <Grid item>
                    <Typography variant="h4" component="h1">
                        {title || ""}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {subtitle || ""}
                    </Typography>
                </Grid>
                <Grid item>{toolview || ""}</Grid>
            </Grid>
        </Box>
    );
}
