/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect, useCallback, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import { Radios } from "mui-rff";
import { TreeView, TreeItem, TreeViewProps } from "@mui/lab";
import { Box, IconButton, Typography, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useReduxTempData } from "hooks";
import MenuIcons from "layout/MenuIcons";
import { IconLibrary } from "common";

export type AuthorizeValue = "0" | "1" | "2";
//if you use radios for this, it MUST be string
export interface PermissionRoleAuthorizeValues {
    page: string;
    read?: boolean;
    write?: boolean;
    alterValue?: AuthorizeValue;
}

export interface PageData {
    type: string;
    i18n: string;
    name: string;
    icon: {
        lib: IconLibrary;
        name: string;
    };
    items?: PageData[];
}

export function convertAuthorizeValuesToUI(data: PermissionRoleAuthorizeValues): AuthorizeValue {
    if (data.read) {
        if (data.write) {
            return "2";
        }
        return "1";
    }
    return "0";
}

export function convertAuthorizeValuesToRowData(value: AuthorizeValue): Partial<PermissionRoleAuthorizeValues> {
    const mValues = {
        read: false,
        write: false,
    };
    if (["1", "2"].indexOf(value) > -1) {
        mValues.read = true;
        if (value === "2") {
            mValues.write = true;
        }
    }
    return mValues;
}

interface PagePermissionViewProps {
    name: string;
    TreeViewProps?: TreeViewProps;
    disabled?: boolean;
}

function PagePermissionView({ name, disabled = false }: PagePermissionViewProps) {
    return (
        <FieldArray<PermissionRoleAuthorizeValues> name={name}>
            {(fieldArrayProps) => {
                return <PagePermissionTreeInner fieldArrayProps={fieldArrayProps} disabled={disabled} />;
            }}
        </FieldArray>
    );
}

//PRA = Permission Role Authorize
type PRAFieldArrayRenderProps = FieldArrayRenderProps<PermissionRoleAuthorizeValues, HTMLElement>;
interface PagePermissionTreeInnerProps {
    fieldArrayProps: PRAFieldArrayRenderProps;
    disabled: boolean;
}

function PagePermissionTreeInner({ fieldArrayProps, disabled }: PagePermissionTreeInnerProps) {
    const fields = fieldArrayProps.fields;
    const name = fields.name;
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<string[]>([]);
    const [treeNodes, setTreeNodes] = useState<JSX.Element[]>([]);
    const pagesData = useReduxTempData<PageData[]>("/api/page/", t("c.page"), false);
    const renderNode = useCallback(
        (row: PageData) => {
            if ("items" in row && row.items && row.items.length > 0) {
                return (
                    <PagePermissionViewItemBase itemData={row} key={row.name}>
                        {row.items.map((sub) => renderNode(sub))}
                    </PagePermissionViewItemBase>
                );
            } else {
                return (
                    <PagePermissionViewItem
                        itemData={row}
                        key={row.name}
                        fieldArrayProps={fieldArrayProps}
                        disabled={disabled}
                    />
                );
            }
        },
        [fieldArrayProps]
    );
    const renderTreeNode = useCallback(() => {
        if (pagesData) {
            return pagesData.map((row) => renderNode(row));
        }
        return [];
    }, [pagesData, renderNode]);
    useEffect(() => {
        if (pagesData) {
            const mTreeNode = renderTreeNode();
            setTreeNodes([...mTreeNode]);
        }
    }, [name, pagesData]);

    const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    useEffect(() => {
        if (pagesData && treeNodes.length > 0) {
            const expendsNodeIds = pagesData.map((row) => row.name);
            setExpanded(expendsNodeIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeNodes]);

    if (!treeNodes) {
        return <></>;
    }
    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon style={{ width: 32, height: 32 }} />}
            defaultExpandIcon={<ChevronRightIcon style={{ width: 32, height: 32 }} />}
            expanded={expanded}
            onNodeToggle={handleToggle}
        >
            {treeNodes}
        </TreeView>
    );
}

interface PagePermissionViewItemProps {
    itemData: PageData;
    fieldArrayProps: PRAFieldArrayRenderProps;
    disabled: boolean;
}

function PagePermissionViewItem({ itemData, fieldArrayProps, disabled }: PagePermissionViewItemProps) {
    const { t } = useTranslation();
    const fields = fieldArrayProps.fields;
    const fieldsName = fields.name;
    const fieldDataIndex = fields.value.findIndex((fieldValue) => fieldValue.page === itemData.name);
    const renderField = () => {
        if (fieldDataIndex > -1 && itemData.type !== "cat") {
            return (
                <Box>
                    <Radios
                        name={`${fieldsName}[${fieldDataIndex}].alterValue`}
                        required={true}
                        radioGroupProps={{
                            row: true,
                        }}
                        data={[
                            { label: t("permissionRole.alterValue.0"), value: "0" },
                            { label: t("permissionRole.alterValue.1"), value: "1" },
                            { label: t("permissionRole.alterValue.2"), value: "2" },
                        ]}
                        disabled={disabled}
                    />
                </Box>
            );
        } else {
            return <></>;
        }
    };
    return <PagePermissionViewItemBase itemData={itemData} contents={renderField()} />;
}

interface PagePermissionViewItemBaseProps {
    itemData: PageData;
    children?: ReactNode;
    contents?: ReactNode;
}

function PagePermissionViewItemBase({ itemData, contents, children }: PagePermissionViewItemBaseProps) {
    const { t } = useTranslation();
    return (
        <TreeItem
            nodeId={itemData.name}
            label={
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Box p={1} display="flex" flexDirection="row" alignItems="center">
                            <IconButton size="small">
                                <MenuIcons {...itemData.icon} />
                            </IconButton>
                            <Typography>{t(itemData.i18n)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {contents || <></>}
                    </Grid>
                </Grid>
            }
        >
            {children}
        </TreeItem>
    );
}

export default PagePermissionView;
