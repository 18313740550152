/* eslint-disable no-unused-vars */

//0:簽核中(Pending) 1:通過(Accepted) 2:撤回(Withdrawn) 3:駁回(Rejected)
enum AuditStatusEnum {
    Pending,
    Accepted,
    Withdrawn,
    Rejected,
    SigningOff, //may be not exist
}

export default AuditStatusEnum;
