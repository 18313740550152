/**
 * Modified from material-table/src/components/m-table-filter-row.js
 */
/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
//import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
//import Icon from "@mui/material/Icon";
//import Tooltip from "@mui/material/Tooltip";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { LocalizationProvider, TimePicker, DatePicker, DateTimePicker } from "@mui/lab";
import GridWrapper from "./ECGridWrapper";
import { Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class MobileFilterRow extends React.Component {
    renderFilterComponent = (columnDef) =>
        React.createElement(columnDef.filterComponent, {
            columnDef: columnDef,
            onFilterChanged: this.props.onFilterChanged,
        });

    renderLookupFilter = (columnDef) => (
        <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="select-multiple-checkbox">{columnDef.filterPlaceholder}</InputLabel>
            <Select
                multiple
                value={columnDef.tableData.filterValue || []}
                onChange={(event) => {
                    this.props.onFilterChanged(columnDef.tableData.id, event.target.value);
                }}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={(selecteds) => selecteds.map((selected) => columnDef.lookup[selected]).join(", ")}
                MenuProps={MenuProps}
                style={{ marginTop: 0 }}
            >
                {Object.keys(columnDef.lookup).map((key) => (
                    <MenuItem key={key} value={key}>
                        <Checkbox
                            checked={
                                columnDef.tableData.filterValue
                                    ? columnDef.tableData.filterValue.indexOf(key.toString()) > -1
                                    : false
                            }
                        />
                        <ListItemText primary={columnDef.lookup[key]} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    renderBooleanFilter = (columnDef) => (
        <Checkbox
            indeterminate={columnDef.tableData.filterValue === undefined}
            checked={columnDef.tableData.filterValue === "checked"}
            onChange={() => {
                let val;
                if (columnDef.tableData.filterValue === undefined) {
                    val = "checked";
                } else if (columnDef.tableData.filterValue === "checked") {
                    val = "unchecked";
                }

                this.props.onFilterChanged(columnDef.tableData.id, val);
            }}
        />
    );

    renderDefaultFilter = (columnDef) => {
        const localization = {
            ...MobileFilterRow.defaultProps.localization,
            ...this.props.localization,
        };
        return (
            <TextField
                fullWidth
                //style={columnDef.type === "numeric" ? { float: "right" } : {}}
                type={columnDef.type === "numeric" ? "number" : "search"}
                value={columnDef.tableData.filterValue || ""}
                placeholder={columnDef.filterPlaceholder || ""}
                onChange={(event) => {
                    this.props.onFilterChanged(columnDef.tableData.id, event.target.value);
                }}
                /*InputProps={
                    this.props.hideFilterIcons || columnDef.hideFilterIcon
                        ? undefined
                        : {
                              startAdornment: (
                                  <InputAdornment position="start">
                                      <Tooltip
                                          title={localization.filterTooltip}
                                      >
                                          <this.props.icons.Filter />
                                      </Tooltip>
                                  </InputAdornment>
                              ),
                          }
                }*/
            />
        );
    };

    renderDateTypeFilter = (columnDef) => {
        let dateInputElement = null;
        const onDateInputChange = (date) => this.props.onFilterChanged(columnDef.tableData.id, date);
        if (columnDef.type === "date") {
            dateInputElement = (
                <DatePicker value={columnDef.tableData.filterValue || null} onChange={onDateInputChange} clearable />
            );
        } else if (columnDef.type === "datetime") {
            dateInputElement = (
                <DateTimePicker
                    value={columnDef.tableData.filterValue || null}
                    onChange={onDateInputChange}
                    clearable
                />
            );
        } else if (columnDef.type === "time") {
            dateInputElement = (
                <TimePicker value={columnDef.tableData.filterValue || null} onChange={onDateInputChange} clearable />
            );
        }
        return (
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                locale={this.props.localization.dateTimePickerLocalization}
            >
                {dateInputElement}
            </LocalizationProvider>
        );
    };

    getComponentForColumn(columnDef) {
        if (columnDef.filtering === false) {
            return null;
        }

        if (columnDef.field || columnDef.customFilterAndSearch) {
            if (columnDef.filterComponent) {
                return this.renderFilterComponent(columnDef);
            } else if (columnDef.lookup) {
                return this.renderLookupFilter(columnDef);
            } else if (columnDef.type === "boolean") {
                return this.renderBooleanFilter(columnDef);
            } else if (["date", "datetime", "time"].includes(columnDef.type)) {
                return this.renderDateTypeFilter(columnDef);
            } else {
                return this.renderDefaultFilter(columnDef);
            }
        }
    }

    render() {
        const columns = this.props.columns
            .filter((columnDef) => !columnDef.hidden && !(columnDef.tableData.groupOrder > -1))
            .sort((a, b) => a.tableData.columnOrder - b.tableData.columnOrder)
            .map((columnDef) => (
                <GridWrapper
                    key={columnDef.tableData.id}
                    col={columnDef}
                    titleBold={false}
                    /*style={{
                        ...this.props.filterCellStyle,
                        ...columnDef.filterCellStyle,
                    }}*/
                >
                    {this.getComponentForColumn(columnDef)}
                </GridWrapper>
            ));

        if (this.props.selection) {
            columns.splice(0, 0, <React.Fragment key="key-selection-column" />);
        }

        if (this.props.hasActions) {
            if (this.props.actionsColumnIndex === -1) {
                columns.push(<React.Fragment key="key-action-column" />);
            } else {
                let endPos = 0;
                if (this.props.selection) {
                    endPos = 1;
                }
                columns.splice(this.props.actionsColumnIndex + endPos, 0, <React.Fragment key="key-action-column" />);
            }
        }

        if (this.props.hasDetailPanel) {
            columns.splice(0, 0, <React.Fragment padding="none" key="key-detail-panel-column" />);
        }

        if (this.props.isTreeData > 0) {
            columns.splice(0, 0, <React.Fragment padding="none" key={"key-tree-data-filter"} />);
        }

        this.props.columns
            .filter((columnDef) => columnDef.tableData.groupOrder > -1)
            .forEach((columnDef) => {
                columns.splice(
                    0,
                    0,
                    <React.Fragment padding="checkbox" key={"key-group-filter" + columnDef.tableData.id} />
                );
            });

        return (
            <TableRow style={{ height: 10 }}>
                <TableCell>
                    <Typography variant="h6">{this.props.localization.filterTooltip}</Typography>
                    <Grid container spacing={2}>
                        {columns}
                    </Grid>
                </TableCell>
            </TableRow>
        );
    }
}

MobileFilterRow.defaultProps = {
    columns: [],
    selection: false,
    hasActions: false,
    localization: {
        filterTooltip: "Filter",
    },
    hideFilterIcons: false,
};

MobileFilterRow.propTypes = {
    columns: PropTypes.array.isRequired,
    hasDetailPanel: PropTypes.bool.isRequired,
    isTreeData: PropTypes.bool.isRequired,
    onFilterChanged: PropTypes.func.isRequired,
    filterCellStyle: PropTypes.object,
    selection: PropTypes.bool.isRequired,
    actionsColumnIndex: PropTypes.number,
    hasActions: PropTypes.bool,
    localization: PropTypes.object,
    hideFilterIcons: PropTypes.bool,
};

export default MobileFilterRow;
