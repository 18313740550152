import React, { MutableRefObject, RefObject } from "react";
import { Button, Grid } from "@mui/material";
import { SearchTextField } from "../index";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslation } from "react-i18next";

export interface QuickSearchProps {
    tableRef: RefObject<any> | MutableRefObject<undefined>;
    onFilter: () => void;
}

export default function QuickSearch({ tableRef, onFilter }: QuickSearchProps) {
    const { t } = useTranslation();

    return (
        <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
            <Grid item>
                <SearchTextField tableRef={tableRef} />
            </Grid>
            <Grid item>
                <Button variant="outlined" onClick={onFilter} startIcon={<FilterListIcon />}>
                    {t("c.filter")}
                </Button>
            </Grid>
        </Grid>
    );
}
