import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import { EmployeePickRow, useEmployeeData } from "hooks";

export interface EmployeeInfoViewProps {
    employeeId: number | null;
    variant?: "standard" | "filled" | "outlined";
    employeeLabel?: ReactNode;
    departLabel?: ReactNode;
}

function EmployeeInfoView({ employeeId, employeeLabel, departLabel, variant }: EmployeeInfoViewProps) {
    const { t } = useTranslation();
    const [employees] = useEmployeeData<EmployeePickRow[]>();
    const employee = employeeId ? employees.find((row) => row.id === employeeId) : undefined;
    console.log(employee);
    return (
        <>
            {employee && (
                <Grid container spacing={3} alignItems="flex-start">
                    <Grid item>
                        <TextField
                            variant={variant}
                            label={employeeLabel || t("e.emp")}
                            value={`${employee.employeeNo} ${employee.name}`}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            variant={variant}
                            label={departLabel || t("e.depart")}
                            value={
                                employee.mainEmployeeDepartment
                                    ? `${employee.mainEmployeeDepartment.department.name} ${employee.mainEmployeeDepartment.workPosition.name}`
                                    : t("c.na")
                            }
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default EmployeeInfoView;
