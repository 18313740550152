import { useSelector } from "react-redux";
import { RootState } from "layout/types";

export interface PermissionData {
    page: string;
    read: boolean;
    write: boolean;
}

function useUserPermission() {
    const permissions: PermissionData[] = useSelector((state: RootState) => state.user.permissions);

    const getPermission = (page: string): PermissionData | undefined => {
        return permissions.find((permission) => page === permission.page);
    };

    const isRead = (page: string): boolean => {
        const permission = getPermission(page);
        return permission ? permission.read : false;
    };

    const isWrite = (page: string): boolean => {
        const permission = getPermission(page);
        return permission ? permission.write : false;
    };

    return { isRead, isWrite };
}

export default useUserPermission;
