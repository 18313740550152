import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import useFormData, { modeDef } from "hooks/useFormData";
import { loadControl as LC } from "helpers";
import AnnounceForm from "./AnnounceForm";

function AnnounceFormDialog({ id, onClose = () => {}, onChange = () => {}, writable = false, ...props }) {
    const formRef = useRef(null);
    const { t } = useTranslation();
    const formData = useFormData(
        "/api/announce",
        {
            visible: true,
            fixedTop: false,
        },
        id,
        () => {}
    );
    const fetchData = useCallback(async () => {
        await formData.fetch().catch(() => {
            onClose();
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const handleSave = (event) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };

    const handleSubmit = async (values) => {
        //Check if data has been changed or not
        LC();
        await formData
            .save(values)
            .then(async () => {
                if (formData.mode === modeDef.EDIT) {
                    await formData.fetch();
                }
                await onChange();
                onClose();
            })
            .catch(() => {});
        LC(false);
    };

    useEffect(() => {
        fetchData();
    }, [fetchData, id]);

    return (
        <Dialog {...props} aria-labelledby="announce-dialog-title" maxWidth="lg">
            <DialogTitle id="announce-dialog-title">
                {formData.mode === modeDef.ADD ? t("c.add") : t("c.edit")}
            </DialogTitle>
            <DialogContent>
                <AnnounceForm
                    initialValues={formData.data}
                    onSubmit={handleSubmit}
                    formRef={formRef}
                    writable={writable}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("c.cancel")}
                </Button>
                {writable && (
                    <Button color="primary" onClick={handleSave}>
                        {t("c.save")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

AnnounceFormDialog.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    writable: PropTypes.bool,
};

export default AnnounceFormDialog;
