import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, Divider, Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useFormData, useUserPermission } from "hooks";
import { DlList, DlItem } from "components";
import { LeaveTypeFormValue } from "../basic";
import BaseDataFormDialog from "./BaseDataFormDialog";

function BaseDataView() {
    const { t } = useTranslation();
    const [formDialogOpen, setFormDialogOpen] = useState<boolean>(false);
    const formData = useFormData<Partial<LeaveTypeFormValue>>("/api/leaveType", {}, "annualLeave", (data) => {
        if (data) {
            data.assignType = data.assignType?.toString();
        }
    });
    const baseData = formData.data;
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("leaveType"), [isWrite]);
    //const formatDate = (date: moment.MomentInput) => moment(date).format("MM-DD");
    const handleEdit = () => {
        setFormDialogOpen(true);
        //
    };
    const handleFormDialogClose = () => {
        setFormDialogOpen(false);
    };
    const assignTypeStr = useMemo(() => {
        let str = "";
        if (baseData && baseData.assignType !== undefined) {
            const assignTypeData = t("leaveType.assignType", { returnObjects: true }) as any;
            str = assignTypeData[baseData.assignType];
            if (baseData.assignType === "1" && baseData.annualStartDate) {
                const startDate = moment(baseData.annualStartDate).format("MM-DD");
                const endDate = moment(baseData.annualStartDate).add(1, "y").add(-1, "d").format("MM-DD");
                str += ` ${startDate} ~ ${endDate}`;
            }
        }
        return str;
    }, [baseData]);
    useEffect(() => {
        formData.fetch().catch((err) => {
            console.log(err);
        });
        return () => {
            formData.cleanup();
        };
    }, []);
    return (
        <Card>
            <CardHeader
                title={t("c.fd.secBase")}
                titleTypographyProps={{ variant: "h6" }}
                action={
                    writable ? (
                        <Grid container>
                            <Grid item>
                                <IconButton aria-label="edit" onClick={handleEdit} size="large">
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )
                }
            />
            <Divider />
            <CardContent>
                <DlList spacing={2}>
                    <DlItem label={t("leaveType.fd.name")} value={baseData?.name || ""} />
                    <DlItem label={t("leaveType.fd.description")} value={baseData?.description || ""} />
                    <DlItem label={t("leaveType.fd.assignType")} value={assignTypeStr} />
                </DlList>
            </CardContent>
            <BaseDataFormDialog formData={formData} onClose={handleFormDialogClose} open={formDialogOpen} />
        </Card>
    );
}

export default BaseDataView;
