import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { SearchTextField, StandaloneConfirmDialog, StandaloneConfirmDialogRef } from "components";
import MaterialTable from "components/customMaterialTable";
import { Column, TableData } from "components/customMaterialTable/CustomMaterialTable";
import { useFormTable, useUserPermission, useReduxTempData, useReduxTempDataReloadFlag } from "hooks";
import { addToast, getErrorByCode, loadControl as LC } from "helpers";
import { PageHeader2 as PageHeader } from "layout";
import { AccountTitleRow } from "../accountTitle";
import AccountTitleAddDialog from "./AccountTitleAddDialog";
import { ResponseHandler } from "common";

export interface AccountTitleGroupListProps {
    name: string;
    usableCatalog: number[];
}
export interface AccountTitleGroupRow {
    groupKey: string;
    accountTitleId: number;
    accountTitleName?: string;
}

function AccountTitleGroupList({ name, usableCatalog }: AccountTitleGroupListProps) {
    const { t } = useTranslation();
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
    const tableRef = useRef<any>(null);
    const dialogRef = React.useRef<StandaloneConfirmDialogRef>(null);
    const { isWrite } = useUserPermission();
    const writable = useMemo<boolean>(() => isWrite("accountSetting"), [isWrite]);
    const { addFlag } = useReduxTempDataReloadFlag(`/api/accountTitle/group/${name}`);
    const formTable = useFormTable<AccountTitleGroupRow>(`/api/accountTitle/group/${name}`, undefined, {
        allDataMode: true,
        //onEdit: preSubmitData,
    });
    const accountTitles = useReduxTempData<AccountTitleRow[]>(`/api/accountTitle/data`, t("e.accountTitle"), false);
    const processedData = useMemo<AccountTitleGroupRow[]>(() => {
        return formTable.data
            ? formTable.data.map((row) => {
                  let accountTitleName = "";
                  const value = row.accountTitleId;
                  if (accountTitles) {
                      const item = accountTitles.find((row) => row.id === value);
                      if (item) {
                          accountTitleName = `${item.code}-${item.name}`.trim();
                      }
                  }
                  return {
                      ...row,
                      accountTitleName,
                  };
              })
            : [];
    }, [accountTitles, formTable.data, t]);

    const columns: Column<AccountTitleGroupRow>[] = [
        {
            title: t("e.accountTitle"),
            field: "accountTitleName",
            long: true,
        },
    ];

    const handleItemAdd = () => {
        setAddDialogOpen(true);
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
    };
    const handleDataChange = useCallback(async (setFlag = true) => {
        await formTable.fetchOnly().catch(() => {});
        setFlag && addFlag();
    }, []);
    const handleBatchDelete = () => {
        const tableData: (AccountTitleGroupRow & { tableData: TableData })[] = tableRef.current.state.data;
        const checkedData = tableData.filter((row) => row.tableData.checked).map((row) => row.accountTitleId);
        console.log(checkedData);
        if (!checkedData || checkedData.length === 0) {
            addToast(t("c.msg.selectNothing"), { appearance: "warning" });
            return;
        }
        const action = () => {
            LC();
            axios
                .post(`/api/accountTitle/group/batchDelete/${name}`, { data: checkedData })
                .then((result: AxiosResponse<ResponseHandler<any>>) => {
                    if ("status" in result.data && result.data.status === "ok") {
                        addToast(t("c.msg.delSuccess"), { appearance: "success" });
                        handleDataChange();
                    } else {
                        const extraMsg = getErrorByCode(result.data as any);
                        addToast(t("c.msg.delFailed") + extraMsg, { appearance: "error" });
                        throw new Error(extraMsg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    addToast(t("c.msg.delFailed"), { appearance: "error" });
                })
                .finally(() => {
                    LC(false);
                });
        };
        dialogRef.current?.open(action);
    };
    useEffect(() => {
        handleDataChange(false);
        return () => {
            formTable.cleanup();
        };
    }, []);
    return (
        <>
            <PageHeader
                title=""
                leftToolView={
                    <Grid container spacing={1}>
                        {writable && (
                            <>
                                <Grid item>
                                    <Button variant="outlined" startIcon={<AddIcon />} onClick={handleItemAdd}>
                                        {t("c.add")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleBatchDelete}>
                                        {t("c.del")}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                }
                rightToolView={
                    <Grid container spacing={1} direction="row" justifyContent="center" alignContent="center">
                        <Grid item>
                            <SearchTextField tableRef={tableRef} />
                        </Grid>
                    </Grid>
                }
            />
            <MaterialTable
                enableMobile={false}
                tableRef={tableRef}
                columns={columns}
                data={processedData}
                options={{
                    paging: false,
                    toolbar: false,
                    selection: true,
                }}
                onCanceled={formTable.clearErrors}
                formTableErrors={formTable.errors}
            />
            <AccountTitleAddDialog
                open={addDialogOpen}
                onClose={handleAddDialogClose}
                groupKey={name}
                usableCatalog={usableCatalog}
                existedItems={processedData.map((item) => item.accountTitleId)}
                onFinished={() => {
                    handleDataChange();
                }}
            />
            <StandaloneConfirmDialog title={t("c.del")} content={t("c.msg.confirmDelete")} ref={dialogRef} />
        </>
    );
}

export default AccountTitleGroupList;
