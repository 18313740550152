import useReduxTempDataReloadFlag from "./useReduxTempDataReloadFlag";
import { flagName } from "./useEmployeeData";

function useEmployeeDataReloadFlag() {
    const employeeDataFlag = useReduxTempDataReloadFlag(flagName);
    const reduxTempDataFlag = useReduxTempDataReloadFlag("/api/employee/picker");

    function addFlag() {
        employeeDataFlag.addFlag();
        reduxTempDataFlag.addFlag();
    }

    return { addFlag };
}

export default useEmployeeDataReloadFlag;
