import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TextField, makeValidate, Select } from "mui-rff";
import * as yup from "yup";
import { Grid, MenuItem } from "@mui/material";
import { FormBase } from "components/final-form";

function SalaryItemsForm({ onSubmit, initialValues, formRef }) {
    const schema = yup.object().shape({
        name: yup.string().required(),
        chname: yup.string().required(),
        default_amount: yup.number().integer().required(),
        payment_type: yup.number().integer().min(0).max(2).required(),
        tax_type: yup.number().integer().min(0).max(1).required(),
        perday_use: yup.number().integer().min(0).max(1).required(),
    });
    const validate = makeValidate(schema);
    return (
        <FormBase onSubmit={onSubmit} validate={validate} initialValues={initialValues} formRef={formRef}>
            <FormContent />
        </FormBase>
    );
}

function FormContent() {
    const { t } = useTranslation();
    const typeData = t("salary.payment_type", { returnObjects: true });
    const taxData = t("salary.tax_type", { returnObjects: true });
    const workLevelData = t("salary.include_type", { returnObjects: true });
    const perdayData = t("salary.include_type", { returnObjects: true });
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                    required={true}
                    label={t("salary.fd.name")}
                    name="name"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required={true}
                    label={t("c.fd.chname")}
                    name="chname"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required={true}
                    type="number"
                    label={t("salary.fd.default_amount")}
                    name="default_amount"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 11 }}
                />
            </Grid>
            <Grid item xs={12}>
                <Select name="payment_type" label={t("c.fd.type")} variant="outlined" required={true}>
                    <MenuItem value="">{t("c.please_select")}</MenuItem>
                    {Object.keys(typeData).map((key) => (
                        <MenuItem key={key} value={key}>
                            {typeData[key]}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Select name="tax_type" label={t("salary.fd.tax_type")} variant="outlined" required={true}>
                    <MenuItem value="">{t("c.please_select")}</MenuItem>
                    {Object.keys(taxData).map((key) => (
                        <MenuItem key={key} value={key}>
                            {taxData[key]}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Select name="wl_only" label={t("e.worklevel")} variant="outlined" required={true}>
                    <MenuItem value="">{t("c.please_select")}</MenuItem>
                    {Object.keys(workLevelData).map((key) => (
                        <MenuItem key={key} value={key}>
                            {workLevelData[key]}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12}>
                <Select name="perday_use" label={t("salary.fd.perday_use")} variant="outlined" required={true}>
                    <MenuItem value="">{t("c.please_select")}</MenuItem>
                    {Object.keys(perdayData).map((key) => (
                        <MenuItem key={key} value={key}>
                            {perdayData[key]}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
    );
}

SalaryItemsForm.propTypes = {
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    formRef: PropTypes.shape({
        current: PropTypes.object,
    }),
};

export default SalaryItemsForm;
