import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FaUserCheck } from "react-icons/fa";
import { Column } from "@material-table/core";
import { IconButton } from "@mui/material";
import { useDataCUD } from "hooks";
import { useFormTable, useTimestampColumns } from "hooks";
import MaterialTable, { CustomMaterialTableProps } from "components/customMaterialTable/CustomMaterialTable";
import { PositionRowData } from "./PositionForm";

export type PositionTableProps = Omit<CustomMaterialTableProps<PositionRowData>, "columns" | "data"> & {
    writable?: boolean;
};

function PositionTable({ options = {}, tableRef, writable = false, ...props }: PositionTableProps) {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const formTable = useFormTable<PositionRowData>("/api/employee/department/" + id);
    const timestampColumns = useTimestampColumns<PositionRowData>({ updatedAt: false });
    const crud = useDataCUD();

    const handlePost = (departmentId: number) => {
        crud.handleEdit(
            {
                employeeId: id,
                departmentId: departmentId,
            },
            `/api/employee/department/${id}`
        )
            .then(() => {
                tableRef?.current?.onQueryChange();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const columns: Column<PositionRowData>[] = [
        {
            title: t("employee.fd.mainPosition"),
            field: "flag",
            render: (row) => {
                const iconColor = row.flag ? "primary" : "default";
                return (
                    <IconButton
                        color={iconColor}
                        size="medium"
                        onClick={() => {
                            if (!row.flag && writable) {
                                handlePost(row.departmentId);
                            }
                        }}
                    >
                        <FaUserCheck />
                    </IconButton>
                );
            },
        },
        {
            title: t("e.depart"),
            field: "department.name",
            type: "string",
        },
        {
            title: t("workPosition.fd.name"),
            field: "workPosition.name",
            type: "string",
        },
        ...timestampColumns,
    ];
    return (
        <MaterialTable<PositionRowData>
            {...props}
            tableRef={tableRef}
            columns={columns}
            data={formTable.fetch()}
            options={{
                //pageSizeOptions: [5, 10, 20],
                paging: false,
                toolbar: false,
                sorting: false,
                ...options,
            }}
        />
    );
}
export default PositionTable;
