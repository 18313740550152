import React from "react";
import { ResponseHandler } from "common";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Stack, styled, Typography } from "@mui/material";
import { ClockInRow } from "components/clockIn";
import AttendanceRowText from "./AttendanceRowText";

const DetailTypography = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
}));

export interface RecordAreaProps {
    data: ResponseHandler<ClockInRow[]>;
}

function RecordArea({ data }: RecordAreaProps) {
    const { t } = useTranslation();
    return (
        <Card>
            <CardContent>
                <Stack spacing={2}>
                    {!data.result ||
                        (data.result?.length === 0 && <DetailTypography>{t("c.msg.nodata")}</DetailTypography>)}
                    {data.result?.map((val, index, arr) => {
                        if (index === 0) {
                            return (
                                <React.Fragment key={val.id}>
                                    <DetailTypography sx={{ color: "red" }}>
                                        <AttendanceRowText row={val} latest />
                                    </DetailTypography>
                                    <DetailTypography>
                                        <AttendanceRowText row={val} />
                                    </DetailTypography>
                                </React.Fragment>
                            );
                        }
                        return (
                            <DetailTypography key={val.id}>
                                <AttendanceRowText row={val} />
                            </DetailTypography>
                        );
                    })}
                </Stack>
            </CardContent>
        </Card>
    );
}

export default RecordArea;
