import React from "react";
import { Box } from "@mui/material";
import CommonNumberFormat from "components/CommonNumberFormat";

interface TotalInfoBlackProps {
    title: string;
    value: number | string;
}

function TotalInfoBlack({ title, value }: TotalInfoBlackProps) {
    return (
        <Box
            sx={{
                borderBottom: "double 4px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold",
                p: 2,
            }}
        >
            <Box sx={{ fontWeight: "bold" }}>{title}</Box>
            <Box sx={{ fontWeight: "bold", fontSize: "24px" }}>
                <CommonNumberFormat number={value} />
            </Box>
        </Box>
    );
}

export default TotalInfoBlack;
