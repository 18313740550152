import { Column } from "@material-table/core";
import { TFunction } from "react-i18next";
import { ImportPreviewRowData } from "./ImportPreviewSearchTable";

function ImportPreviewColumnsFactory(t: TFunction) {
    const hoursRenderer = (hours: number) => t("{{ hours }} 小時", { hours });

    const defaultColumns: Column<ImportPreviewRowData>[] = [
        {
            title: t("假別名稱"),
            field: "name",
            type: "string",
        },
        {
            title: t("系統給假時數"),
            field: "systemHours",
            type: "string",
            render: ({ systemHours }) => hoursRenderer(systemHours),
        },
        {
            title: t("已休時數"),
            field: "remainHours",
            type: "string",
            render: ({ remainHours }) => hoursRenderer(remainHours),
        },
        {
            title: t("可休時數"),
            field: "availableHours",
            type: "string",
            render: ({ availableHours }) => hoursRenderer(availableHours),
        },
    ];

    return defaultColumns;
}

export default ImportPreviewColumnsFactory;
