import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React, { useMemo } from "react";
import { Field, useForm } from "react-final-form";
import * as _ from "lodash";
import { useReduxTempData } from "hooks";
import { LeaveRowData } from "pages/leave/basic/BasicLeaveTable";
import { useTranslation } from "react-i18next";

export interface ShiftTransferListFieldProps {
    name: string;
    disabled?: boolean;
}

function ShiftTransferListField({ name, disabled = false }: ShiftTransferListFieldProps) {
    const form = useForm();
    const handleChange = (values: number[]) => {
        form.change(name, values);
    };
    return (
        <Field name={name}>
            {({ input }) => {
                return <ShiftTransferList value={input.value} onChange={handleChange} />;
            }}
        </Field>
    );
}

export default ShiftTransferListField;

interface ShiftTransferListProps {
    readonly value?: number[];
    onChange: (value: number[]) => void;
}

function ShiftTransferList({ value = [], onChange }: ShiftTransferListProps) {
    const { t } = useTranslation();
    const [checked, setChecked] = React.useState<readonly number[]>([]);

    const leaveTypeRawData = useReduxTempData<LeaveRowData[]>(`api/leaveType/custom`, t("e.accountTitle"), false);

    const leaveTypeData = useMemo<LeaveRowData[]>(() => {
        if (leaveTypeRawData) {
            return leaveTypeRawData;
        }
        return [];
    }, [leaveTypeRawData]);

    const usableLeaveTypeIds = useMemo<number[]>(() => {
        return leaveTypeData.map((row) => row.id);
    }, [leaveTypeData]);

    const left = useMemo<number[]>(() => {
        return _.difference(usableLeaveTypeIds, value);
    }, [value, usableLeaveTypeIds]);

    const right = useMemo<number[]>(() => {
        return _.intersection(usableLeaveTypeIds, value);
    }, [value, usableLeaveTypeIds]);

    const leftChecked = _.intersection(checked, left);
    const rightChecked = _.intersection(checked, right);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const numberOfChecked = (items: readonly number[]) => _.intersection(checked, items).length;

    const handleToggleAll = (items: readonly number[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(_.difference(checked, items));
        } else {
            setChecked(_.union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        onChange([...right.concat(leftChecked)]);
        setChecked(_.difference(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        onChange([..._.difference(right, rightChecked)]);
        setChecked(_.difference(checked, rightChecked));
    };

    const getLeaveTypeName = (id: number): string => {
        const row = leaveTypeData.find((row) => row.id === id);
        if (row) {
            return row.name;
        } else {
            return "";
        }
    };

    const customList = (title: React.ReactNode, items: readonly number[]) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{
                            "aria-label": "all items selected",
                        }}
                    />
                }
                title={title}
                subheader={t("c.nSelected", { count: `${numberOfChecked(items)}/${items.length}` as any })}
            />
            <Divider />
            <List
                sx={{
                    width: 200,
                    height: 230,
                    bgcolor: "background.paper",
                    overflow: "auto",
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value: number) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem key={value} role="listItem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={getLeaveTypeName(value)} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList(t("shift.transferList.choice"), left)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList(t("shift.transferList.chosen"), right)}</Grid>
        </Grid>
    );
}
