import React from "react";
import CashBlock, { CashBlockProps } from "./CashBlock";
import { useTranslation } from "react-i18next";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { blue } from "@mui/material/colors";
import { Avatar } from "@mui/material";

export type BalanceProps = Pick<CashBlockProps, "amount">;

function BalanceBlock({ amount }: BalanceProps) {
    const { t } = useTranslation();

    return (
        <CashBlock
            amount={amount}
            color="#003689"
            icon={
                <Avatar variant="rounded" sx={{ bgcolor: blue[100] }}>
                    <AccountBalanceWalletIcon sx={{ color: blue[400] }} />
                </Avatar>
            }
            subtitle={t("accountTitle.settingData.cash")}
        />
    );
}

export default BalanceBlock;
