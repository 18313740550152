import React, { useRef, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogProps } from "@mui/material";
import { FormBaseRefProps } from "components/final-form/FormBase";
import { useFormData, useEmployeeDataReloadFlag } from "hooks";
import NewEmployeeFormBase, { NewEmployeeFormValues, defaultInitialValues } from "./NewEmployeeFormBase";

export interface NewEmployeeDialog extends DialogProps {
    onChange?: () => void;
}

function NewEmployeeDialog({ onChange, onClose, ...props }: NewEmployeeDialog) {
    const formRef = useRef<FormBaseRefProps<NewEmployeeFormValues>>(null);
    const { t } = useTranslation();
    const formData = useFormData<NewEmployeeFormValues>("/api/employee", defaultInitialValues, null);
    const { addFlag } = useEmployeeDataReloadFlag();

    const handleSave = (event: MouseEvent<HTMLButtonElement>) => {
        if (formRef.current) {
            formRef.current.onSubmit(event);
        }
    };

    const handleSubmit = async (values: NewEmployeeFormValues) => {
        await formData
            .save(values)
            .then(async (resp) => {
                onChange && onChange();
                addFlag();
                handleClose();
            })
            .catch(() => {});
    };

    const handleClose = (event?: MouseEvent) => {
        onClose && onClose(event || {}, "backdropClick");
    };
    return (
        <Dialog {...props} aria-labelledby="employee-dialog-title">
            <DialogTitle id="user-dialog-title">{t("c.add")}</DialogTitle>
            <DialogContent>
                <NewEmployeeFormBase onSubmit={handleSubmit} formRef={formRef} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    {t("c.cancel")}
                </Button>
                <Button color="primary" onClick={handleSave}>
                    {t("c.save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewEmployeeDialog;
