import useReduxTempDataBase from "./useReduxTempDataBase";

function useReduxTempData<DataValues = any>(
    apiPath: string = `api/hold`,
    name: string = "",
    force: boolean = false,
    process?: (values: DataValues) => void
) {
    const { data } = useReduxTempDataBase<DataValues>({ apiPath, name, force, process });
    return data;
}
export default useReduxTempData;
