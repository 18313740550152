/* eslint-disable no-unused-vars */
import React from "react";
import AuditBlock from "./AuditBlock";
import { green, indigo, orange, red } from "@mui/material/colors";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import IconAvatar from "./IconAvatar";

export interface AuditActionProps {
    type: AuditType;
    title: string;
    count: number;
    onClick: () => void;
}

export enum AuditType {
    Pending,
    SigningOff,
    Rejected,
    Accepted,
}

const Icons: Record<AuditType, React.ReactNode> = {
    [AuditType.Pending]: (
        <IconAvatar background={indigo[50]}>
            <PendingActionsIcon
                sx={{
                    color: indigo[400],
                }}
            />
        </IconAvatar>
    ),
    [AuditType.SigningOff]: (
        <IconAvatar background={orange[50]}>
            <SwapHorizIcon sx={{ color: orange[400] }} />
        </IconAvatar>
    ),
    [AuditType.Rejected]: (
        <IconAvatar background={red[50]}>
            <CloseIcon sx={{ color: red[500] }} />
        </IconAvatar>
    ),
    [AuditType.Accepted]: (
        <IconAvatar background={green[50]}>
            <CheckIcon sx={{ color: green[400] }} />
        </IconAvatar>
    ),
};

function AuditAction({ type, ...props }: AuditActionProps) {
    return <AuditBlock icon={Icons[type]} {...props} />;
}

export default AuditAction;
