import { useTranslation } from "react-i18next";
import { useReduxTempData, useEmployeeData, EmployeePickRow } from "hooks";
import FormRow from "components/audit/FormRow";

const transPrefix = "message.msg.";

export interface AuditMessageContent {
    formId: number;
    formType: number;
    userId: number;
    employeeId: number;
    auditId: number;
    no: string;
}

function useMessageUtil() {
    const { t } = useTranslation();
    //const formData = useReduxTempData("/api/form/list/all", "", false);
    const formRowData = useReduxTempData<FormRow[]>(`api/form`, t("e.form"));
    const [empData] = useEmployeeData<EmployeePickRow[]>(null, []);
    const getMsgFromData = (content: string | object, type: number, key?: string | null) => {
        console.log(content);
        if (type === 0) {
            return content;
        } else if (type === 1) {
            return getMsgByKey(content, key);
        }
        return "";
    };

    const getMsgByKey = (content: string | object, key?: string | null) => {
        const contentObj = typeof content === "string" ? JSON.parse(content) : content;
        if (key?.includes("audit", 0)) {
            const mContent = contentObj as AuditMessageContent;
            if (formRowData) {
                const form = formRowData.find((row) => row.id === Number(mContent.formId));
                if (form) {
                    const params: Record<string, any> = {
                        id: mContent.no,
                        formName: t(`form.title.${form.name}`),
                    };
                    if (key === "auditAwait") {
                        const auditor = empData.find((row) => row.id === Number(mContent.employeeId));
                        if (auditor) {
                            params.user = auditor.name;
                        }
                    }
                    return t(transPrefix + key, params);
                }
                return "";
            }
            return "";
        }
        return t(transPrefix + key, contentObj);
    };

    const getLink = (content: string | object, key?: string | null): string | null => {
        if (key === "auditAwait") {
            return "/audit/pending";
        }
        if (key === "auditApproved") {
            return "/formRequest/accepted";
        }
        if (key === "auditReject") {
            return "/formRequest/rejected";
        }
        return null;
    };
    return { getMsg: getMsgFromData, getLink };
}

export default useMessageUtil;
