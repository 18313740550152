import moment, { Moment } from "moment";

export function formatTime(time: moment.MomentInput, format: string = "LLL") {
    return moment(time).format(format);
}

export function removeSeconds(time: Moment) {
    return time.seconds(0).milliseconds(0);
}

export function isAfterEqual(time1: Moment, time2: Moment): boolean {
    return time1.diff(time2) >= 0;
}

export function isBeforeEqual(time1: Moment, time2: Moment): boolean {
    return time1.diff(time2) <= 0;
}

export const isValidPeriod = (startTime: moment.MomentInput, endTime: moment.MomentInput) => {
    const mStartTime = moment(startTime);
    const mEndTime = moment(endTime);
    return mStartTime.isBefore(mEndTime);
};
