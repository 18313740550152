import React, { ElementType, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import moment, { MomentInput } from "moment";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    List,
    ListItem,
    ListItemText,
    Divider,
    CardActions,
    Button,
    Badge,
    Popover,
    Tooltip,
    IconButton,
    PopoverProps,
    ListItemProps,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useEmployeeData, useMessageUtil } from "hooks";
import Axios from "faxios";
import { checkMessage } from "helpers";
import { ReadMessage } from "Action/Actions";
import socket from "components/socketIo";
import { RootState, MessageDataResult, MessageData } from "./types";

const formatDate = (time: MomentInput) => moment(time).format("LLL");

socket.on("connection", function () {
    console.log("websocket", "connection setup for socket.io");
});
socket.on("msgReceived", function (msg) {
    console.log("websocket", msg);
    checkMessage();
});

checkMessage();
// function test() {
//     socket.emit('message', 'test123' + Math.random());
// }
// test()

const selectMsg = createSelector(
    (state: RootState) => state.message,
    (message) => {
        if (message) {
            const unread = message.data.filter((a) => !a.isRead).length;
            return {
                count: message.count + unread,
                data: message.data,
            };
        } else {
            return { count: 0, data: [] };
        }
    }
);
function MessageView() {
    const { t } = useTranslation();
    //const [socket, setSocket] = useState(null);
    // const [msgData, msgFetch] = useFetchData("/api/message/unread", t("ui.msg"), null, { count: 0, data: [] });
    // const msgData = useSelector((state) => state.message);
    const msgData = useSelector(selectMsg);
    const [msgPopEl, setMsgPopEl] = React.useState<HTMLButtonElement | null>(null);
    const handleMsgPopOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setMsgPopEl(event.currentTarget);
    };
    const handleMsgPopClose = () => {
        setMsgPopEl(null);
    };
    return (
        <>
            <Tooltip title={t("ui.msg").toString()} aria-label="notification">
                <IconButton color="inherit" onClick={handleMsgPopOpen} size="large">
                    <Badge badgeContent={msgData.count} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <MessagePopOver
                open={Boolean(msgPopEl)}
                msgData={msgData}
                anchorEl={msgPopEl}
                onClose={handleMsgPopClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            />
        </>
    );
}

interface MessagePopOverProps extends PopoverProps {
    msgData: MessageDataResult;
}

function MessagePopOver({ msgData, ...props }: MessagePopOverProps) {
    const { t } = useTranslation();
    const [empData] = useEmployeeData(null, []);
    const items = empData
        ? msgData.data.map((item, i) => (
              <React.Fragment key={item.id}>
                  <MessageItem data={item} />
                  {i < msgData.count - 1 && <Divider component="li" />}
              </React.Fragment>
          ))
        : [];
    return (
        <Popover {...props}>
            <List>{items}</List>
            <CardActions>
                <Button component={NavLink} to="/message" size="small">
                    {t("ui.moreMsg")}
                </Button>
            </CardActions>
        </Popover>
    );
}

interface MessageItemBaseProps {
    data: MessageData;
}

export type MessageItemProps<C extends ElementType> = ListItemProps<C, { component?: C }> & MessageItemBaseProps;

function MessageItem({ data, ...props }: MessageItemBaseProps) {
    const history = useHistory();
    const dispatch = useDispatch();
    const messageUtil = useMessageUtil();
    const msg = messageUtil.getMsg(data.content, data.type, data.msgKey);
    const handleClick = () => {
        if (data.link) {
            history.push(data.link);
        } else if (data.type === 1) {
            const specLink = messageUtil.getLink(data.content, data.msgKey);
            if (specLink) {
                history.push(specLink);
            }
        }
    };
    // const [read, setRead] = React.useState(data.isRead);
    const onMouseEnter = data.isRead
        ? () => {}
        : () => {
              dispatch(ReadMessage({ id: data.id }));
              Axios.put(`/api/message`, { id: data.id });
          };
    return (
        <ListItem
            button
            onClick={handleClick}
            style={{ backgroundColor: data.isRead ? "#d3d3d375" : "white" }}
            {...props}
            onMouseEnter={onMouseEnter}
        >
            <ListItemText primary={msg} secondary={formatDate(data.createdAt)} />
        </ListItem>
    );
}

export default MessageView;
