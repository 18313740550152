import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MomentInput } from "moment";
import { Decorator } from "final-form";
import { makeValidate } from "mui-rff";
import { Stack } from "@mui/material";
import { FormBase, FormBaseProps } from "components";
import { useAccountTitleSetting, useReduxTempData } from "hooks";
import { AccountTitleRow } from "../accountTitle";
import InitialHeaderSection from "./InitialHeaderSection";
import InitialDetailTable from "./InitialDetailTable";
import { ClassifiedTitles, classifyAccountTitles } from "./helpers";
import InitialFormCalculatorFactory from "./InitialFormCalculatorFactory";
import YupSchemaFactory from "./YupSchemaFactory";
import BLAlertFields from "./BLAlertFields";

export interface InitialFormValues {
    yearMonth: MomentInput;
    consolidationMethodType: number;
    currentPl: number;
    accumulated: number;
    undistributed: number;
    distributed: number;
    [key: string]: any;
}

export interface InitialFormDetail {
    accountTitleId: number;
    amount: number;
}

export interface InitialFormContext {
    disabled: boolean;
    assetTitles: AccountTitleRow[];
    liabilityTitles: AccountTitleRow[];
    equityTitles: AccountTitleRow[];
    maxDate?: MomentInput;
}

const FormContext = createContext<InitialFormContext>({
    disabled: false,
    assetTitles: [],
    liabilityTitles: [],
    equityTitles: [],
});

export const useInitialFormContext = () => useContext(FormContext);

export type InitialFormBaseProps = Pick<FormBaseProps<InitialFormValues>, "onSubmit" | "formRef" | "initialValues"> &
    Pick<InitialFormContext, "disabled" | "maxDate">;

function InitialFormBase({ disabled, maxDate, ...props }: InitialFormBaseProps) {
    const { t } = useTranslation();
    //const decoratorsRef = useRef<Decorator<InitialFormValues>[]>([]);
    const accountTitles = useReduxTempData<AccountTitleRow[]>(`/api/accountTitle/data`, t("e.accountTitle"), false);
    const [classifiedTitles, setClassifiedTitles] = useState<ClassifiedTitles>({
        assetTitles: [],
        liabilityTitles: [],
        equityTitles: [],
    });
    const [allTitles, setAllTitles] = useState<AccountTitleRow[]>([]);
    const { accountTitleSettingData, accountTitleSettingDataLoaded } = useAccountTitleSetting();

    useEffect(() => {
        if (accountTitles && accountTitles.length > 0) {
            const mClassifiedTitles = classifyAccountTitles(accountTitles);
            setClassifiedTitles(mClassifiedTitles);
            const { assetTitles, liabilityTitles, equityTitles } = mClassifiedTitles;
            const allTitles = [...assetTitles, ...liabilityTitles, ...equityTitles].filter((row) => row.type > 0);
            setAllTitles(allTitles);
        }
    }, [accountTitles]);
    const decorators = useMemo<Decorator<InitialFormValues>[]>(() => {
        if (allTitles.length > 0) {
            const calculator = InitialFormCalculatorFactory(allTitles);
            return [calculator];
        }
        return [];
    }, [allTitles]);

    const validate = useMemo<any>(() => {
        if (allTitles.length > 0 && accountTitleSettingDataLoaded && accountTitleSettingData) {
            const schema = YupSchemaFactory(allTitles, t, accountTitleSettingData);
            return makeValidate(schema);
        }
        return null;
    }, [allTitles, accountTitleSettingData, accountTitleSettingDataLoaded]);

    const readyToRender = decorators.length > 0 && validate;

    return (
        <>
            {readyToRender && (
                <FormBase<InitialFormValues> validate={validate} decorators={decorators} {...props}>
                    <FormContext.Provider
                        value={{
                            disabled,
                            ...classifiedTitles,
                            maxDate,
                        }}
                    >
                        <FormContent />
                    </FormContext.Provider>
                </FormBase>
            )}
        </>
    );
}

function FormContent() {
    return (
        <Stack spacing={2}>
            <BLAlertFields />
            <InitialHeaderSection />
            <InitialDetailTable />
        </Stack>
    );
}

export default InitialFormBase;
