import React from "react";
import { Button, styled } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";

const StyledButton = styled(
    Button,
    {}
)<ButtonProps>(({ theme }) => ({
    color: "#000",
    borderColor: "#0000003B",
    padding: "10px, 16px, 10px, 14px",
    fontWeight: 500,
}));

function ButtonOutlined({ children, ...props }: ButtonProps) {
    return (
        <StyledButton variant="outlined" {...props}>
            {children}
        </StyledButton>
    );
}

export default ButtonOutlined;
