import React, { useState } from "react";
import { useForm } from "react-final-form";
import { v4 as uuidv4 } from "uuid";
import { FieldArrayRenderProps } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MdiIcon from "@mdi/react";
import { mdiFileSearch } from "@mdi/js";
import { ActionType, Column } from "components/customMaterialTable/CustomMaterialTable";
import { mtFormatNumber as mfn } from "components/CommonNumberFormat";
import MaterialTable from "components/FinalFormMaterialTable";
import { useReduxTempData } from "hooks";
import { modeDef } from "hooks/useFormData";
import { AccountVoucherDetail, useAccountVoucherFormContext } from "../AccountVoucherFormBase";
import { AccountTitleRow } from "pages/accountSetting";
import VoucherDetailFormDialog, { VoucherDetailFormValues } from "./VoucherDetailFormDialog";

function FSDetail() {
    const { t } = useTranslation();
    const { dtlTableRef, formMode, editable } = useAccountVoucherFormContext();
    const [formData, setFormData] = useState<VoucherDetailFormValues | undefined>(undefined);
    const [formOpen, setFormOpen] = useState<boolean>(false);
    const accountTitles = useReduxTempData<AccountTitleRow[]>(`/api/accountTitle/data`, t("e.accountTitle"), false);
    const isAddMode = formMode === modeDef.ADD;
    const initialFormData = {
        credit: 0,
        debit: 0,
    };
    const columns: Column<AccountVoucherDetail>[] = [
        {
            title: t("e.accountTitle"),
            field: "accountTitleId",
            type: "numeric",
            long: true,
            render: (rowData) => {
                let accountTitleName = "";
                const value = rowData.accountTitleId;
                if (accountTitles) {
                    const item = accountTitles.find((row) => row.id === value);
                    if (item) {
                        accountTitleName = `${item.code}-${item.name}`.trim();
                    }
                }
                return accountTitleName;
            },
        },
        {
            title: t("accountVoucher.fd.particulars"),
            field: "particulars",
            long: true,
        },
        {
            title: t("accountVoucher.fd.debit"),
            field: "debit",
            type: "numeric",
            render: mfn("debit"),
        },
        {
            title: t("accountVoucher.fd.credit"),
            field: "credit",
            type: "numeric",
            render: mfn("credit"),
        },
        {
            title: t("e.uniformInvoice"),
            field: "uniformInvoice.code",
        },
    ];

    const form = useForm();
    const handleFormClose = () => {
        setFormOpen(false);
    };
    const handleFormSave = (values: VoucherDetailFormValues) => {
        //const formState = form.getState();
        if (dtlTableRef?.current) {
            const fieldArray: FieldArrayRenderProps<VoucherDetailFormValues, any> = dtlTableRef.current.fieldArray;
            const data = fieldArray.fields.value;
            if (!values.tableData) {
                fieldArray.fields.push({
                    ...values,
                    tableData: {
                        id: data.length,
                        uuid: uuidv4(),
                    },
                });
            } else {
                const uuid = values.tableData.uuid;
                const rowIndex = data.findIndex((mRow) => mRow.tableData?.uuid === uuid);
                fieldArray.fields.update(rowIndex, values);
            }
        }
    };
    const actionsAddOnly: ActionType<VoucherDetailFormValues>[] = [
        {
            icon: "add",
            tooltip: t("c.add"),
            isFreeAction: true,
            onClick: () => {
                setFormData(undefined);
                setFormOpen(true);
            },
        },
        {
            icon: "edit",
            tooltip: t("c.edit"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    setFormData(rowData);
                    setFormOpen(true);
                }
            },
        },
        {
            icon: () => <FileCopyIcon />,
            tooltip: t("c.copy"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData) && dtlTableRef?.current && rowData.tableData) {
                    const fieldArray: FieldArrayRenderProps<VoucherDetailFormValues, any> =
                        dtlTableRef.current.fieldArray;
                    const data = fieldArray.fields.value;
                    const uuid = rowData.tableData.uuid;
                    const row = data.find((mRow) => mRow.tableData?.uuid === uuid);
                    fieldArray.fields.push({
                        ...row,
                        tableData: {
                            id: data.length,
                            uuid: uuidv4(),
                        },
                    });
                }
            },
        },
        {
            icon: "delete",
            tooltip: t("c.del"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    if (dtlTableRef?.current) {
                        const fieldArray: FieldArrayRenderProps<VoucherDetailFormValues, any> =
                            dtlTableRef.current.fieldArray;
                        const data = fieldArray.fields.value;
                        if (rowData.tableData) {
                            const uuid = rowData.tableData.uuid;
                            const rowIndex = data.findIndex((mRow) => mRow.tableData?.uuid === uuid);
                            fieldArray.fields.remove(rowIndex);
                        }
                    }
                }
            },
        },
    ];

    //isAddMode

    const actionCommon: ActionType<VoucherDetailFormValues>[] = [
        {
            icon: editable ? "edit" : () => <MdiIcon path={mdiFileSearch} size={1} />,
            tooltip: editable ? t("c.edit") : t("c.view"),
            onClick: (e: any, rowData) => {
                if (!Array.isArray(rowData)) {
                    setFormData(rowData);
                    setFormOpen(true);
                }
            },
        },
    ];

    const handleChange = (values: VoucherDetailFormValues[]) => {
        if (values && Array.isArray(values) && values.length > 0) {
            const totalCredit = values.reduce(
                (accumulator, currentItem) => accumulator + ("credit" in currentItem ? Number(currentItem.credit) : 0),
                0
            );
            const totalDebit = values.reduce(
                (accumulator, currentItem) => accumulator + ("debit" in currentItem ? Number(currentItem.debit) : 0),
                0
            );
            form.change("totalCredit", Number(totalCredit));
            form.change("totalDebit", Number(totalDebit));
        } else {
            form.change("totalCredit", 0);
            form.change("totalDebit", 0);
        }
    };
    return (
        <>
            <MaterialTable<AccountVoucherDetail>
                enableMobile={false}
                name="accountVoucherDetails"
                title={t("raData.fd.secBody")}
                columns={columns}
                actions={isAddMode ? actionsAddOnly : actionCommon}
                onChange={handleChange}
                options={{
                    pageSizeOptions: [5, 10, 20],
                    pageSize: 5,
                    search: false,
                    //sorting: false,
                }}
                initialFormData={initialFormData}
                ref={dtlTableRef}
            />
            <VoucherDetailFormDialog
                data={formData}
                onClose={handleFormClose}
                onSave={handleFormSave}
                open={formOpen}
            />
        </>
    );
}

export default FSDetail;
