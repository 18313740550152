import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Stack } from "@mui/material";
import { AlertProps } from "@mui/lab";
import RequiredFlagEnum from "./RequiredFlagEnum";

export interface FlagErrorAlertProps {
    errors: RequiredFlagEnum[];
}

function FlagErrorAlerts({ errors }: FlagErrorAlertProps) {
    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const errorContentMap: { [key in RequiredFlagEnum]: Pick<AlertProps, "children" | "severity"> } = {
        [RequiredFlagEnum.ShiftRequired]: { children: t("form.error.shiftNotSet"), severity: "error" },
    };

    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
            {errors.map((flag, index) => {
                const props = errorContentMap[flag];
                return <Alert key={index} {...props} />;
            })}
        </Stack>
    );
}

export default FlagErrorAlerts;
